import * as Yup from 'yup'

export interface ICompanyDetails {
  companyName: string
  cin: string
  industry?: string
  currentValuation?: number
  currentSharePrice?: number
  dba?: string
  incubationDate?: string
  commitmentAmount?: number
}

const editCompanyDetailSchema = Yup.object().shape({
  companyName: Yup.string()
    .required('Please enter a company name')
    .min(3, 'Company Name cannot be less than 3 characters')
    .label('Company Name'),
  cin: Yup.string().optional().min(3, 'CIN cannot be less than 3 characters').label('CIN'),
  industry: Yup.string().min(3, 'Industry name cannot be less than 3 characters').label('Industry'),
})

const editCompanyDetailInits: ICompanyDetails = {
  cin: '',
  companyName: '',
  industry: '',
  currentValuation: 0,
  currentSharePrice: 0,
}

// export interface IHissaCompanyDetails {
//   industry: string
//   dba: string
//   incubationDate: string
//   commitmentAmount: number
// }

// const editHissaCompanyDetailSchema = Yup.object().shape({
//   dba: Yup.string()
//     .optional()
//     .min(3, 'CIN cannot be less than 3 characters')
//     .label('Doing Business As'),
//   industry: Yup.string().min(3, 'Industry name cannot be less than 3 characters').label('Industry'),
// })

// const editHissaCompanyDetailInits: IHissaCompanyDetails = {
//   industry: '',
//   dba: '',
//   incubationDate: '',
//   commitmentAmount: 0,
// }

export {
  editCompanyDetailInits,
  editCompanyDetailSchema,
  // editHissaCompanyDetailInits,
  // editHissaCompanyDetailSchema,
}
