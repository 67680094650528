import { useContext } from 'react'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { getInitials } from '../../../../utils/Utils'
import { GlobalContext } from '../../store/context/globalContext'
import { formatNumber, getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'

type WatchlistHeaderCardProps = {
  companyName: string
  dba: string
  logo: string
  className: string
  initIndex: number
  valuation?: number
  sharePrice?: number
}
export function WatchlistHeaderCard(props: WatchlistHeaderCardProps) {
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  return (
    <div
      className={`card mh-175px mw-275px p-9 ${props.className}`}
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row'>
        <span className=''>
          {props?.logo && props?.logo !== '' && props?.logo !== 'NA' ? (
            <div className='symbol symbol-50px h-50px w-50px symbol-fixed position-relative mb-5'>
              <img src={props?.logo} alt='' />
            </div>
          ) : (
            <div className='mb-5 bg-secondary rounded h-50px w-50px fw-bold d-flex justify-content-center align-items-center'>
              <p
                className='mb-0 fs-1'
                style={{
                  color: getCSSVariableValue('--kt-donut-' + ((props.initIndex + 9) % 10)),
                }}
              >
                {getInitials(props.companyName)}
              </p>
            </div>
          )}
        </span>
        <span className='ps-2'>
          <h4
            className='pt-2 m-0 text-truncate'
            data-toggle='tooltip'
            data-placement='top'
            title={props.companyName}
            style={{ maxWidth: '160px' }}
          >
            {props.companyName.toUpperCase()}
          </h4>
          <span className='text-muted '>{props.dba}</span>
        </span>
      </div>

      <div className='row pt-4'>
        <div className='col-6'>
          <span className='text-gray-600'> Valuation</span>
          <h1
            data-toggle='tooltip'
            data-placement='top'
            title={Number(props.valuation).toLocaleString(userCurrencyFormat)}
          >
            {getSymbolOfCurrency(userCurrency)}{' '}
            {formatNumber({
              value: props.valuation,
              format: userCurrencyFormat,
            })}
          </h1>
        </div>
        <div className='col-6'>
          <span className='text-gray-600'> Share Price</span>
          <h1
            data-toggle='tooltip'
            data-placement='top'
            title={Number(props.sharePrice).toLocaleString(userCurrencyFormat)}
          >
            {getSymbolOfCurrency(userCurrency)}{' '}
            {formatNumber({
              value: props.sharePrice,
              format: userCurrencyFormat,
            })}
          </h1>
        </div>
      </div>
    </div>
  )
}
