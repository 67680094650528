/* eslint-disable */
import { create } from 'zustand'
import { configurePersist } from 'zustand-persist'
import { Role, UserInvestor } from '../app/pages/store/userRoleGrants'
import { UserPreferenceType } from '../types/UserPreferences'
import { CompanyDataInput } from '../generated/graphql'

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: 'root',
})

const persist = _persist as any

interface Authorization {
  userPreferences: UserPreferenceType
  userRoles: Role[]
  userInvestors: UserInvestor[]
  userCompanies: CompanyDataInput
  setUserRoles: (role: string) => void
  setUserCompanies: (companies: string) => void
  setUserPreferences: (preference: string) => void
  setUserInvestors: (investor: string) => void
  clearAuthorization: () => void
}

interface AuthorizationStore extends Authorization {
  setAuthorization: (auth: Authorization | ((auth: Authorization) => Authorization)) => void
  // clearAuthorization: () => void
}

export const useAuthorizationStore = create<AuthorizationStore>(
  persist(
    {
      key: 'authorization',
    },
    (set: any) => ({
      userPreferences: [],
      userRoles: [],
      userInvestors: [],
      userCompanies: [],
      setAuthorization: (auth: Authorization | ((auth: Authorization) => Authorization)) => {
        if (typeof auth === 'function') {
          set((_state: Authorization) => auth(_state))
        } else {
          set((_state: Authorization) => auth)
        }
      },
      setUserRoles: (userRole: string) => {
        set((state: any) => ({ userRoles: userRole }))
      },
      setUserPreferences: (userPreference: string) => {
        set((state: any) => ({ userPreferences: userPreference }))
      },
      setUserCompanies: (userCompanies: string) => {
        set((state: any) => ({ userRoles: userCompanies }))
      },
      setUserInvestors: (userInvestors: string) => {
        set((state: any) => ({ userRoles: userInvestors }))
      },
      clearAuthorization: () => {
        set((_state: any) => ({
          userPreferences: [],
          userRoles: [],
          userInvestors: [],
          userCompanies: [],
        }))
      },
    })
  )
)
