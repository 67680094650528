import { ToWords } from 'to-words'
export function numToWordsDeals(num: number, format: 'IN' | 'US'): string {
  const toWords = new ToWords({
    localeCode: `${format === 'IN' ? 'en-IN' : 'en-US'}`,
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      doNotAddOnly: false,
      ignoreZeroCurrency: false,
      currencyOptions: {
        name: `${format === 'IN' ? 'Rupee' : 'Dollar'}`,
        plural: `${format === 'IN' ? 'Rupees' : 'Dollars'}`,
        symbol: `${format === 'IN' ? '₹' : '$'}`,
        fractionalUnit: {
          name: `${format === 'IN' ? 'Paisa' : 'Cents'}`,
          plural: `${format === 'IN' ? 'Paise' : 'Cents'}`,
          symbol: '',
        },
      },
    },
  })
  if (toWords.isValidNumber(num)) {
    return toWords.convert(num)
  } else {
    return 'converting to words failed'
  }
}

export const currencyTypesNumToWords = {
  INR: 'en-IN',
  USD: 'en-US',
  EUR: 'en-GB',
  GBP: 'en-GB',
  AED: 'en-AE',
  AUD: 'en-US',
  CNY: 'en-US',
  JPY: 'en-US',
  SGD: 'en-US',
}

const currencyOptions = {
  INR: {
    name: 'Rupee',
    plural: 'Rupees',
    symbol: '₹',
    fractionalUnit: {
      name: 'Paisa',
      plural: 'Paise',
      symbol: '',
    },
  },
  USD: {
    name: 'Dollar',
    plural: 'Dollars',
    symbol: '$',
    fractionalUnit: {
      name: 'Cent',
      plural: 'Cents',
      symbol: '',
    },
  },
  EUR: {
    name: 'Euro',
    plural: 'Euros',
    symbol: '€',
    fractionalUnit: {
      name: 'Cent',
      plural: 'Cents',
      symbol: '',
    },
  },
  GBP: {
    name: 'Pound',
    plural: 'Pounds',
    symbol: '£',
    fractionalUnit: {
      name: 'Penny',
      plural: 'Pence',
      symbol: '',
    },
  },
  AED: {
    name: 'Dirham',
    plural: 'Dirhams',
    symbol: 'د.إ',
    fractionalUnit: {
      name: 'Fils',
      plural: 'Fils',
      symbol: '',
    },
  },
  AUD: {
    name: 'Dollar',
    plural: 'Dollars',
    symbol: 'A$',
    fractionalUnit: {
      name: 'Cent',
      plural: 'Cents',
      symbol: '',
    },
  },
  CNY: {
    name: 'Yuan',
    plural: 'Yuan',
    symbol: '¥',
    fractionalUnit: {
      name: 'Fen',
      plural: 'Fen',
      symbol: '',
    },
  },
  JPY: {
    name: 'Yen',
    plural: 'Yen',
    symbol: '¥',
    fractionalUnit: {
      name: 'Sen',
      plural: 'Sen',
      symbol: '',
    },
  },
  SGD: {
    name: 'Dollar',
    plural: 'Dollars',
    symbol: 'S$',
    fractionalUnit: {
      name: 'Cent',
      plural: 'Cents',
      symbol: '',
    },
  },
}

export function numToWords(num: number, currencyCode: string, isCurrency?: boolean): string {
  const localeCode =
    currencyTypesNumToWords[currencyCode as unknown as keyof typeof currencyTypesNumToWords]
  const currencyOption = currencyOptions[currencyCode as unknown as keyof typeof currencyOptions]

  if (!localeCode || !currencyOption) {
    return 'Invalid currency code'
  }

  const toWords = new ToWords({
    localeCode: localeCode,
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      doNotAddOnly: false,
      ignoreZeroCurrency: false,
      currencyOptions: {
        name: currencyOption.name,
        plural: currencyOption.plural,
        symbol: currencyOption.symbol,
        fractionalUnit: {
          name: currencyOption.fractionalUnit.name,
          plural: currencyOption.fractionalUnit.plural,
          symbol: '',
        },
      },
    },
  })
  if (!isCurrency) {
    toWords['options']['converterOptions'].currency = false
  }

  if (toWords.isValidNumber(num)) {
    return toWords.convert(num)
  } else {
    return 'Converting to words failed'
  }
}
