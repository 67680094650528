import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginRequest,
  LoginResponse,
  RegisterUserRequest,
  RegisterUserResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from '../types/Login'
import { capTableApi, investorApi } from './investorApi'

const API_URL = process.env.REACT_APP_API_URL
const HISSA_API_URL = process.env.REACT_APP_CAPTABLE_URL
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
// export const REGISTER_URL = `${API_URL}/register`
export const FORGOT_PASSWORD_URL = `${HISSA_API_URL}/v1/auth/forgotPassword`
export const RESET_PASSWORD_URL = `${HISSA_API_URL}/v1/auth/resetPassword`
export const CHANGE_PASSWORD_URL = `${HISSA_API_URL}/v1/auth/changePassword`

export async function login(credentials: LoginRequest): Promise<LoginResponse> {
  return investorApi.post(LOGIN_URL, credentials)
}

export async function forgotPassword(
  credentials: ForgotPasswordRequest
): Promise<ForgotPasswordResponse> {
  return capTableApi.post(FORGOT_PASSWORD_URL, credentials)
}
export async function resetPassword(
  credentials: ResetPasswordRequest
): Promise<ResetPasswordResponse> {
  return capTableApi.post(RESET_PASSWORD_URL, credentials, {
    headers: { authorization: credentials.jwt },
  })
}

export async function changePassword(
  credentials: ChangePasswordRequest
): Promise<ChangePasswordResponse> {
  return capTableApi.post(CHANGE_PASSWORD_URL, credentials, {
    headers: { authorization: credentials.jwt },
  })
}

export async function registration(
  credentials: RegisterUserRequest
): Promise<RegisterUserResponse> {
  return investorApi.post(REGISTER_URL, credentials)
}
