import { useEffect, useState } from 'react'
import Select from 'react-select'
import { formatNumber } from '../../../../../utils/CurrencyFormatter'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { AddTransactionRoundDetails } from './InvestmentModellingHelper'
import Toast, { ToastType } from '../../../../../utils/toast'
import { Link, useNavigate } from 'react-router-dom'
import {
  DeleteInvestorRoundModellingDataMutation,
  DeleteInvestorRoundModellingDataMutationVariables,
  useDeleteInvestorRoundModellingDataMutation,
  useGetAllInvestorRoundModellingDataQuery,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import { SelectOptionType } from '../../../../../utils/MultiSelect'
import { selectCustomStyles } from '../../../../../utils/Select'
import { TemplateCards } from '../../../deals/components/template-builder/TemplateViewer'

type DeleteInvModelProps = {
  hissaCompanyId: string
  modelId: number
  showModel: boolean
  setShowModel: Function
}

export function ModelNameRender(data: any) {
  return (
    <div className='pt-2'>
      <Link
        to={`/company/newmodelling/HISSA/${data.data.hissaCompanyId}/${data.data.modelId}`}
        className='fw-bold text-gray-900 text-hover-primary'
      >
        <p className='fw-bold'> {data.data.modelName}</p>
      </Link>
    </div>
  )
}

export function RoundNameRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.roundName}</p>
    </div>
  )
}

export function RoundSizeRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.roundSize,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function PostMoneyRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.postMoney,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function DilutionRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.dilution,
          format: data.data.userCurrencyFormat,
        }) + '%'}
      </p>
    </div>
  )
}

export function InvestmentModelActionRender(data: any) {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showTermsheetModal, setShowTermsheetModal] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedTemplate, setSelectedTemplate] = useState<any>(
    data.data.dealTemplate && data.data.dealTemplate[0]
  )
  const hissaCompanyId = data.data.hissaCompanyId

  const dealTemplateList: SelectOptionType[] = []
  data.data?.dealTemplate?.forEach((deal: any) => {
    if (deal.documentType === 'Termsheet')
      dealTemplateList.push({
        value: deal.id,
        label: deal.name,
      })
  })

  const [template, setTemplate] = useState<any>(
    dealTemplateList?.length > 0
      ? {
          value: dealTemplateList[0].value,
          label: dealTemplateList[0].label,
        }
      : {
          value: '',
          label: '',
        }
  )
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [data.data.transactionDetails])

  const mutateDeleteModel = useDeleteInvestorRoundModellingDataMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: DeleteInvestorRoundModellingDataMutation,
        _variables: DeleteInvestorRoundModellingDataMutationVariables,
        _context: unknown
      ) => {
        const compKey = {
          input: { hissaCompanyId: hissaCompanyId },
        }
        queryClient.invalidateQueries(useGetAllInvestorRoundModellingDataQuery.getKey(compKey))
        navigate(`/company/investmentmodelling/HISSA/${hissaCompanyId}`)
        Toast('Model Deleted Successfully!', ToastType.success)
        setShowDeleteModal(false)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
        setShowDeleteModal(false)
      },
    },
    {}
  )

  function handleCreateTermsheet() {
    const templateData = {
      templateId: selectedTemplate.id,
      hissaCompanyId: hissaCompanyId,
      pricedRound: 'Yes',
      isRoundModel: true,
      companyName: data.data.companyData?.name || '',
      cin: data.data.companyData?.cin || '',
      investmentAmount: data.data.investedAmount,
      ...data.data.modelData.investmentModellingData.roundData,
    }
    navigate('/deals/draftTermsheet', { state: templateData })
  }

  const options = ['Delete Model']
  if (data.data.modelData?.investmentModellingData?.investmentData?.length > 0) {
    options.push('Create Termsheet')
  }
  const actionMenu = () => {
    return options.map((option) => {
      return (
        <label
          key={option}
          className='form-check-custom py-3 bg-hover-primary text-hover-white'
          onClick={() => {
            if (option === 'Delete Model') {
              setShowDeleteModal(true)
            } else if (option === 'Create Termsheet') {
              setShowTermsheetModal(true)
            }
          }}
        >
          <span className='form-check-label fs-base ms-6 fw-normal cursor-pointer'>{option}</span>
        </label>
      )
    })
  }

  return (
    <div className='px-0'>
      {data.data.investorName !== 'Total' && data.data.dateOfInvestment !== null && (
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
      )}
      {showDeleteModal && (
        <DeleteInvestmentModel
          hissaCompanyId={hissaCompanyId}
          modelId={data.data.modelId}
          setShowModel={() => setShowDeleteModal(false)}
          showModel={showDeleteModal}
        />
      )}

      {showTermsheetModal && (
        <Modal
          id='kt_modal_add_inv'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-800px '
          show={showTermsheetModal}
          onHide={() => setShowTermsheetModal(false)}
        >
          <Modal.Title>
            <h2 className='fw-bolder text-gray-heading p-9 mb-0 '>Create Termsheet</h2>
            <div className='separator'></div>
          </Modal.Title>

          <Modal.Body className='p-0'>
            <div className='card p-0 '>
              <div className='d-flex row m-9'>
                <div className='row mx-auto p-0'>
                  <div className='fv-row p-0 col-12 col-md-6 pe-9'>
                    <div className='fv-row mb-10 col-12'>
                      <label className='form-label'>Select Template</label>

                      <Select
                        options={dealTemplateList}
                        value={template}
                        name='securityType'
                        onChange={(e: any) => {
                          setTemplate(e)
                          const currentTemplate = data.data.dealTemplate.filter(
                            (template: any) => template.name === e.label && template.id === e.value
                          )
                          if (currentTemplate.length > 0) setSelectedTemplate(currentTemplate[0])
                        }}
                        styles={selectCustomStyles}
                      />
                    </div>
                  </div>
                  {selectedTemplate && selectedTemplate?.id && (
                    <div className='fv-row p-0 col-12 col-md-6'>
                      <TemplateCards templateData={selectedTemplate} />
                    </div>
                  )}
                </div>
                <div className='d-flex col justify-content-between pt-10 px-0'>
                  <button
                    className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
                    onClick={() => setShowTermsheetModal(false)}
                  >
                    <span className='indicator-label'>Cancel</span>
                  </button>
                  <button
                    type='submit'
                    className={`${
                      !selectedTemplate ? 'disabled bg-primary-light' : 'bg-primary'
                    } px-4 rounded border border-0 border-primary  h-38px fw-normal text-white`}
                    disabled={!selectedTemplate}
                    onClick={() => handleCreateTermsheet()}
                  >
                    {'Create'}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
        data-kt-menu='true'
      >
        {actionMenu()}
      </div>
    </div>
  )
}

export function DeleteInvestmentModel(props: DeleteInvModelProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const mutateDeleteModel = useDeleteInvestorRoundModellingDataMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: DeleteInvestorRoundModellingDataMutation,
        _variables: DeleteInvestorRoundModellingDataMutationVariables,
        _context: unknown
      ) => {
        const compKey = {
          input: { hissaCompanyId: props.hissaCompanyId },
        }
        queryClient.invalidateQueries(useGetAllInvestorRoundModellingDataQuery.getKey(compKey))
        navigate(`/company/investmentmodelling/HISSA/${props.hissaCompanyId}`)
        Toast('Model Deleted Successfully!', ToastType.success)
        props.setShowModel()
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
        props.setShowModel()
      },
    },
    {}
  )

  function handleDeleteInvestment() {
    setLoading(true)
    mutateDeleteModel.mutate({
      input: {
        hissaCompanyId: props.hissaCompanyId,
        id: props.modelId,
      },
    })
  }

  return (
    <>
      {props.showModel && (
        <Modal
          id='kt_modal_add_inv'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-500px '
          show={props.showModel}
          onHide={() => props.setShowModel()}
        >
          <Modal.Body className='p-9'>
            <div className='card'>
              <div className='card-body p-0'>
                <h2 className='fw-bolder text-gray-heading pb-4'>Delete Model</h2>
                <div className='fs-5 pb-6'>
                  Are you sure you want to remove this model? Data will be permanently removed. This
                  action cannot be undone.
                </div>

                <div className='d-flex col justify-content-between pt-6'>
                  <button
                    className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
                    onClick={() => props.setShowModel()}
                  >
                    <span className='indicator-label'>Cancel</span>
                  </button>
                  <button
                    type='submit'
                    className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
                    disabled={loading}
                    onClick={() => handleDeleteInvestment()}
                  >
                    {'Delete'}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
