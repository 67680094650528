import { useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import AddEETheme from './AddEETheme'
import EditEETheme from './EditEETheme'
import RemoveEETheme from './RemoveEETheme'
import { str2num } from '../../../utils/Utils'
import _ from 'lodash'

type Props = {
  themes: any
  filters: any[]
}

const EEThemes = ({ themes, filters }: Props) => {
  const [showTable, setShowTable] = useState<boolean>(false)
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const toggleAddModal = () => setShowAddModal(!showAddModal)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const toggleEditModal = () => setShowEditModal(!showEditModal)
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
  const toggleRemoveModal = () => setShowRemoveModal(!showRemoveModal)
  const [theme, setTheme] = useState(null)
  const [themeFilter, setThemeFilter] = useState(null)

  const getFilter = (id: number) => filters.find((f: any) => f.id === id)

  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fs-3 mb-1'>Themes</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{themes ? Array.from(themes.keys()).length : 'No'} themes available</span>
          </h3>
          <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Click to add a theme'>
            <button
              className='btn btn-sm btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_add_theme'
              onClick={() => {
                setTheme(null)
                setThemeFilter(null)
                toggleAddModal()
              }}
            >
              <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-2' />
              New Theme
            </button>
          </div>
        </div>
        <div className='card-body p-0'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-5'>
              <thead>
                <tr>
                  <th className='p-0 min-w-150px'></th>
                  <th className='p-0 min-w-100px'></th>
                  <th className='p-0 min-w-40px'></th>
                </tr>
              </thead>
              <tbody>
                {themes &&
                  Array.from(themes.keys()).map((t: any, i: any) => {
                    return (
                      <tr
                        key={i}
                        className='bg-hover-light'
                        onClick={() => {
                          setTheme(t)
                          setShowTable(true)
                        }}
                      >
                        <td className='ps-5'>
                          <p className='text-dark text-hover-primary mb-1 fs-6'>{t}</p>
                        </td>
                        <td>
                          <p className='text-dark text-hover-primary mb-1 fs-6'>
                            {themes &&
                              filters.length > 0 &&
                              themes.get(t).map((m: any, j: any) => (
                                <span key={j} className='badge badge-light-info fw-semibold me-1'>
                                  {getFilter(m.filterId).name}
                                </span>
                              ))}
                          </p>
                        </td>
                        <td>
                          <div className='d-flex justify-content-end'>
                            <button
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-info mx-1'
                              onClick={() => {
                                setTheme(t)
                                setShowTable(true)
                              }}
                            >
                              <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-2' />
                            </button>
                            {/* <button
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-danger mx-1'
                              onClick={() => {
                                setThemeFilter(_.cloneDeep(t))
                                toggleRemoveModal()
                              }}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {themes && theme && showTable && (
        <div className='card mt-5'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fs-3 mb-1'>{theme}</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>{themes && themes.get(theme) ? themes.get(theme).length : 'No'} theme filters</span>
            </h3>
            <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Click to add a theme'>
              <button
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_add_theme'
                onClick={() => {
                  setThemeFilter(null)
                  toggleAddModal()
                }}
              >
                <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-2' />
                Add Theme Filter
              </button>
            </div>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-5'>
                <thead>
                  <tr>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-100px'></th>
                    <th className='p-0 min-w-40px'></th>
                  </tr>
                </thead>
                <tbody>
                  {themes.get(theme) &&
                    themes.get(theme).map((t: any, i: any) => {
                      const f = getFilter(t.filterId)
                      return (
                        <tr key={i}>
                          <td>
                            <p className='text-dark text-hover-primary mb-1 fs-6'>{f.name}</p>
                            <span className='text-muted fw-semibold d-block fs-7'>{f.group}</span>
                          </td>
                          <td className='text-center'>
                            {t.min && <span className='fw-semibold'>{`${str2num(t.min)} to ${str2num(t.max)}`}</span>}
                            {t.inList &&
                              t.inList.split(',').map((val: any, i: any) => (
                                <span key={i} className='badge badge-secondary fw-semibold me-1'>
                                  {val}
                                </span>
                              ))}
                          </td>
                          <td>
                            <div className='d-flex justify-content-end'>
                              <button
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-warning mx-1'
                                onClick={() => {
                                  setThemeFilter(_.cloneDeep(t))
                                  toggleEditModal()
                                }}
                              >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                              </button>
                              <button
                                className='btn btn-sm btn-icon btn-bg-light btn-active-color-danger mx-1'
                                onClick={() => {
                                  setThemeFilter(_.cloneDeep(t))
                                  toggleRemoveModal()
                                }}
                              >
                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <AddEETheme showAddModal={showAddModal} toggleAddModal={toggleAddModal} filters={filters} theme={theme!} />
      <EditEETheme showEditModal={showEditModal} toggleEditModal={toggleEditModal} filters={filters} theme={themeFilter} />
      <RemoveEETheme showRemoveModal={showRemoveModal} toggleRemoveModal={toggleRemoveModal} filters={filters} theme={themeFilter} />
    </>
  )
}

export { EEThemes }
