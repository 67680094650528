import { SetStateAction, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../utils/Select'
import { SelectOptionType } from '../../../../../utils/MultiSelect'
import { RequestSign, ResumeSignProcess, RevokeSign, SignStatus } from './SignatureComponents'
import { getAuth } from '../../../../modules/auth'
import axios from 'axios'
import Toast, { ToastType } from '../../../../../utils/toast'

type Props={
    showDialog:boolean
    handleClose:Function
    documentData:any
}
type SignerStatus = {
  [email: string]: string;
};
export type SignatoriesStatus ={
  esignStatus:string
  signers:SignerStatus
}
function SignAndUploadModal({showDialog,handleClose,documentData}:Props) {
    const jwt = getAuth()
    const esignApi = process.env.REACT_APP_ESIGN_API_URL
    const [showSignStatus, setShowSignStatus] = useState(true)
    const [showRequestSign, setShowRequestSign] = useState(false)
    const [showRevokeSign, setShowRevokeSign] = useState(false)
    const [signatoriesData,setSignatoriesData] = useState<SignatoriesStatus>()
    const [dropDownValue,setDropDownValue] = useState(documentData.eSignId === "" ? {label:'Request Sign',value:'Request Sign', disabled:false}: {label:'Sign Status',value:'Sign Status'})
    const dropdownItems: SelectOptionType[] = [
        {label:'Sign Status',value:'Sign Status', disabled: false},
        {label:'Request Sign',value:'Request Sign', disabled:false},
        {label:'Revoke Request',value:'Revoke Request',disabled:false}
    ]
    useEffect(() => {
      if (documentData.eSignId) {
        const headers = { Authorization: jwt !== undefined ? jwt : '' }
        axios
          .get(`${esignApi}/admin/status?id=${documentData.eSignId}`, { headers })
          .then(async (data) => {
            const details:SignatoriesStatus = data?.data?.data
            setSignatoriesData(details)
          })
          .catch((err) => {
            console.log(err)
            Toast(err.response.data.message, ToastType.error)
          })
      }
    }, [documentData.eSignId, esignApi, jwt])
    useEffect(()=>{
      if( (signatoriesData !== undefined && signatoriesData.esignStatus === 'draft') || documentData.eSignId === ""){
        dropdownItems.map((option)=>{
          if(option.label==='Revoke Request'){
            option.disabled = true
          }
        })
      }
      if(signatoriesData !== undefined &&  (signatoriesData.esignStatus === 'signature_requested'||  signatoriesData.esignStatus === 'completed')){
        dropdownItems.map((option)=>{
          if(option.label==='Request Sign'){
            option.disabled = true
          }
        })
      }
      if(documentData.eSignId === ""){
        dropdownItems.map((option)=>{
          if(option.label==='Sign Status'){
            option.disabled = true
            setShowSignStatus(false)
            setShowRequestSign(true)
            setShowRevokeSign(false)
          }
        })
      }
    },[dropdownItems, signatoriesData])
  return (
    <Modal show={showDialog} dialogClassName='modal-dialog modal-dialog-centered mw-700px' >
        <Modal.Body className='shadow-lg'>
            <div className='d-flex justify-content-between'>
                <h1 className='m-0 align-content-center'>Sign and Upload</h1>
                <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => {
              handleClose()
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
          </button>
            </div>
            <div className='py-3'>
                <Select 
                className='w-25'
                options={dropdownItems}
                isOptionDisabled = {(option:any) => option.disabled}
                styles={selectCustomStyles}
                value={dropDownValue}
                onChange={(e:any)=>{
                    setDropDownValue(e)
                    if(e.value === 'Sign Status'){
                        setShowSignStatus(true)
                        setShowRequestSign(false)
                        setShowRevokeSign(false)
                    }else if(e.value === 'Request Sign'){
                        setShowSignStatus(false)
                        setShowRequestSign(true)
                        setShowRevokeSign(false)
                    }else if(e.value === 'Revoke Request'){
                        setShowSignStatus(false)
                        setShowRequestSign(false)
                        setShowRevokeSign(true)
                    }
                }}
                />
            </div>
            <div className='py-5'>
              {showSignStatus && 
              <SignStatus documentData={documentData} handleClose={()=>{}} signatoriesData={signatoriesData}/>}
              {showRequestSign && 
              <RequestSign documentData={documentData} handleClose={()=>{handleClose()}} signatoriesData={signatoriesData}/>}
              {showRevokeSign &&
              <RevokeSign documentData={documentData} handleClose={()=>{handleClose()}}/>}
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default SignAndUploadModal