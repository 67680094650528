import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { AgGridReact } from 'ag-grid-react'
import { agConfigSideBar, tableCellStyle } from '../../../../utils/AgGridConfig'
import {
  ChartRef,
  ColDef,
  ChartOptionsChanged,
  ChartRangeSelectionChanged,
  ChartDestroyed,
} from 'ag-grid-community'
import { isoToDate } from '../../../../utils/FormatDate'
import { DynamicTableDataInputType } from '../wrappers/DynamicTablesPageWrapper'

type DynamicTableProps = {
  data: any[]
  savedCharts: any[]
  name: string
  tableData: DynamicTableDataInputType
  handleChartData: Function
  handleUpdate: Function
}

export function DynamicDataAGTable({
  data,
  savedCharts,
  name,
  tableData,
  handleChartData,
  handleUpdate,
}: DynamicTableProps) {
  const gridRef = useRef<any>(null)
  const [rowData, setRowData] = useState<any[]>()
  const { primary, secondary } = tableData.indexData
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([])
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), [])
  const gridStyle = useMemo(() => ({ height: '500px', width: '100%' }), [])
  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [chartData, setChartData] = useState<any[]>(savedCharts)
  function isNumber(value: any) {
    return Number(value)
  }

  function isDate(value: any): boolean {
    const date = new Date(value)
    return !isNaN(date.getTime())
  }

  function CustomRowData(data: any) {
    const currentKey = data.colDef.field
    return (
      <div className={`d-block fs-base ${data.data[`${currentKey}_secondary`] ? '' : ' pt-2'}`}>
        <h6 className='mb-0'>{data.data[currentKey]}</h6>
        {data.data[`${currentKey}_secondary`] && (
          <p className='text-muted mb-0 fs-8'>{data.data[`${currentKey}_secondary`]}</p>
        )}
      </div>
    )
  }

  useEffect(() => {
    handleChartData(chartData)
    if (chartData?.length > savedCharts?.length) {
      handleUpdate(true)
    }
  }, [chartData])

  function customHeaderComponent(value: string) {
    const currentIndex = primary.findIndex(
      (item: string) => item?.toLowerCase() === value?.toLowerCase()
    )

    let primaryValue = primary[currentIndex] || ''
    let secondaryValue = secondary[currentIndex] ? secondary[currentIndex] : ''
    return {
      menuIcon: 'fa-bars',
      template: `<div class="ag-cell-label-container" role="presentation">
                    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                    <span ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
                    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                        <span style="
                              padding-right: 10px; ">
                        <div>
                          <h6 style="
                              margin-bottom: 1px; font-size: 12px; font-weight: bold
                          ">${primaryValue}</h6>
                          <p className='text-muted mb-0 fs-8' style="
                              margin-bottom: 0px; font-size: 10px; font-weight: bold
                          ">${secondaryValue}</p>
                        </div>
                        </span>
                        <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden" style="color:#ff6666"></span>
                        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden" style="color:#ff6666"></span>
                        <span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                    </div>
                </div>`,
    }
  }

  function getFilterType(value: string, isPrimary: boolean) {
    const currentIndex = isPrimary
      ? primary.findIndex((item: string) => item?.toLowerCase() === value?.toLowerCase())
      : secondary.findIndex((item: string) => item?.toLowerCase() === value?.toLowerCase())

    const index = isPrimary
      ? primary[currentIndex] &&
        data[0].findIndex(
          (item: string) => item?.toLowerCase() === primary[currentIndex]?.toLowerCase()
        )
      : secondary[currentIndex] &&
        data[0].findIndex(
          (item: string) => item?.toLowerCase() === secondary[currentIndex]?.toLowerCase()
        )

    let filterType = 'agTextColumnFilter'
    if (isNumber(data[1][index])) {
      filterType = 'agNumberColumnFilter'
    } else if (isDate(data[1][index])) {
      filterType = 'agDateColumnFilter'
    } else {
      filterType = 'agMultiColumnFilter'
    }
    return filterType
  }

  function CustomFilter(value: string) {
    const currentIndex = primary.findIndex(
      (item: string) => item?.toLowerCase() === value?.toLowerCase()
    )

    let primaryValue = primary[currentIndex] || ''
    let secondaryValue = secondary[currentIndex] ? secondary[currentIndex] : ''

    let primaryFilterType = getFilterType(primaryValue, true)
    let secondaryFilterType = getFilterType(secondaryValue, false)

    const filter = {
      buttons: ['reset'],
      filters: [
        {
          title: primaryValue,
          display: 'subMenu',
          filter: primaryFilterType,
          buttons: ['reset'],
          filterValueGetter: (params: any) => params.value.name,
          filterParams: {
            buttons: ['reset'],
            valueGetter: (params: any) => params.getValue(primaryValue),
          },
        },
      ],
    }

    if (secondaryValue && secondaryValue !== '') {
      filter.filters.push({
        title: secondaryValue,
        display: 'subMenu',
        filter: secondaryFilterType,
        buttons: ['reset'],
        filterValueGetter: (params: any) => params.value.name,
        filterParams: {
          buttons: ['reset'],
          valueGetter: (params: any) => params.data[`${primaryValue}_secondary`],
        },
      })
    }
    return filter
  }

  useEffect(() => {
    const colDef =
      data.length > 1
        ? data[0].map((value: any, index: any) => {
            let filterType

            if (primary.map((item: string) => item.toLowerCase()).includes(value.toLowerCase())) {
              filterType = getFilterType(value, true)

              const currentIndex = primary.findIndex(
                (item: string) => item?.toLowerCase() === value?.toLowerCase()
              )

              let primaryValue = primary[currentIndex] || ''
              let secondaryValue = secondary[currentIndex] ? secondary[currentIndex] : ''

              return filterType === 'agDateColumnFilter'
                ? index === 0
                  ? {
                      field: value,
                      width: 150,
                      chartDataType: 'category',
                      filter: filterType,
                      minWidth: 300,
                      menuTabs: ['filterMenuTab'],
                      cellStyle: { 'padding-top': '10px', 'line-height': '20px' },
                      filterParams: {
                        comparator,
                      },
                      headerComponentParams: customHeaderComponent(value),
                      cellRenderer: CustomRowData,
                    }
                  : {
                      field: value,
                      chartDataType: 'series',
                      filter: filterType,
                      minWidth: 300,
                      menuTabs: ['filterMenuTab'],
                      cellStyle: { 'padding-top': '10px', 'line-height': '20px' },
                      filterParams: {
                        comparator,
                      },
                      headerComponentParams: customHeaderComponent(value),
                      cellRenderer: CustomRowData,
                    }
                : index === 0
                ? {
                    field: value,
                    width: 150,
                    chartDataType: 'category',
                    filter:
                      secondaryValue && secondaryValue !== '' ? 'agMultiColumnFilter' : filterType,
                    minWidth: 300,
                    filterParams: CustomFilter(value),
                    headerComponentParams: customHeaderComponent(value),
                    cellStyle: { 'padding-top': '10px', 'line-height': '20px' },
                    menuTabs: ['filterMenuTab'],
                    cellRenderer: CustomRowData,
                  }
                : {
                    field: value,
                    chartDataType: 'series',
                    filter:
                      secondaryValue && secondaryValue !== '' ? 'agMultiColumnFilter' : filterType,
                    minWidth: 300,

                    // getQuickFilterText: (params: any) => {
                    //   const name = params.value[value].toLowerCase()
                    //   const dba = params.value[value] ? params.value[value].toLowerCase() : '' // Check if dba exists
                    //   return `${name} ${dba}` // Concatenate name and dba
                    // },
                    filterParams: CustomFilter(value),
                    headerComponentParams: customHeaderComponent(value),
                    cellStyle: { 'padding-top': '10px', 'line-height': '20px' },
                    menuTabs: ['filterMenuTab'],
                    cellRenderer: CustomRowData,
                  }
            }
          })
        : []

    setColumnDefs(colDef.filter((item: any) => item !== undefined))
    const convertedData = convertMedalData(data)
    setRowData(convertedData)
  }, [data])

  function comparator(dateFromFilter: Date, cellValue: any) {
    if (cellValue == null) {
      return 0
    }
    const dateParts = cellValue.split('-')
    const day = Number(dateParts[2])
    const month = Number(dateParts[1]) - 1
    const year = Number(dateParts[0])
    const cellDate = new Date(year, month, day)
    if (cellDate < dateFromFilter) {
      return -1
    } else if (cellDate > dateFromFilter) {
      return 1
    }
    return 0
  }

  function convertMedalData(data: any) {
    const headers = data[0]
    const result = []

    for (let i = 1; i < data.length; i++) {
      const row = data[i]
      const rowData: any = {}

      for (let j = 0; j < headers.length; j++) {
        if (
          secondary
            .map((item: string, index: number) => item?.toLowerCase())
            .includes(headers[j].toLowerCase())
        ) {
          const currentIndex = secondary.findIndex(
            (item: string) => item?.toLowerCase() === headers[j].toLowerCase()
          )
          const primaryValue = primary[currentIndex]
          rowData[`${primaryValue}_secondary`] = isNumber(row[j]) ? Number(row[j]) : row[j]
        } else rowData[headers[j]] = isNumber(row[j]) ? Number(row[j]) : row[j]
      }

      result.push(rowData)
    }

    return result
  }
  const showNoRowsOverlay = () => (
    <div className='row justify-content-center  text-center py-8 m-6'>
      <img
        alt='no items'
        src={toAbsoluteUrl('/media/custom-images/emptyRecentCompanies.svg')}
        className='h-150px my-10 w-350 justify-content-center'
      />
      <p className='fs-base text-muted text-center'>
        No Data found. Please check your uploaded files and try again
      </p>
    </div>
  )

  const componentsRegistry = useMemo(() => {
    const headers = (rowData && rowData[0]) || []
    return Object.keys(headers).reduce((acc: any, key) => {
      acc[key] = memo(CustomRowData)
      return acc
    }, {})
  }, [])

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridRef) {
        if (!isColumnOpen) gridRef?.current?.api?.openToolPanel(key)
        else gridRef?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridRef) {
        if (!isFilterOpen) {
          gridRef?.current?.api?.openToolPanel(key)
        } else gridRef?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      filter: true,
      flex: 1,
      resizable: true,
    }),
    []
  )

  const createChartContainer = useCallback((chartRef: ChartRef): void => {
    const eChart = chartRef.chartElement
    const generatedCharts = gridRef.current.api.getChartModels() || []
    setChartData(generatedCharts)
    const themeName =
      document.documentElement?.getAttribute('data-default-theme') || 'ag-theme-material'
    const eParent = document.querySelector('#container') as HTMLElement
    if (!eParent) {
      console.error('Container not found!')
      return
    }

    function updateState() {
      const chartWrap = document.querySelectorAll('.chart-wrapper')
      if (chartWrap.length >= 1) {
        chartWrap.forEach((element) => {
          if (chartWrap.length === 1) {
            element.classList.remove('col-12', 'col-md-6')
          } else element.classList.add('col-12', 'col-md-6')
        })
      }
    }

    const chartWrapperHTML = `
        <div class="chart-wrapper ${themeName} pb-6 px-4 mb-3" >
            <div class="chart-wrapper-top d-flex justify-content-between align-items-center bg-white rounded-top p-6">
                <h2 class="chart-wrapper-title mb-0"> Chart created : ${isoToDate(
                  new Date().toISOString()
                )}</h2>
                <button class="chart-wrapper-close btn-close rounded-bottom" aria-label="Close"></button>
            </div>
            <div class="chart-wrapper-body rounded"></div>
        </div>
    `

    eParent.insertAdjacentHTML('beforeend', chartWrapperHTML)
    const eChartWrapper = eParent.lastElementChild as HTMLElement

    // Append the chart to the wrapper
    eChartWrapper.querySelector('.chart-wrapper-body')!.append(eChart)

    // Add event listener for close button
    eChartWrapper.querySelector('.chart-wrapper-close')!.addEventListener('click', () => {
      chartRef.destroyChart()
      eParent.removeChild(eChartWrapper)
      updateState()
    })

    updateState()
  }, [])

  const generateChart = useCallback((data: any) => {
    if (gridRef.current.api && data) gridRef.current.api.createRangeChart(data)
  }, [])

  useEffect(() => {
    chartData &&
      chartData.map((data: any) => {
        if (data) {
          return generateChart(data)
        }
      })
  }, [gridRef.current?.api])

  function onChartOptionsChanged(event: ChartOptionsChanged) {
    const currentChart = chartData.find((chart) => chart.chartId === event.chartId)

    if (currentChart) {
      const updatedState = chartData.map((chart) =>
        chart.chartId === event.chartId ? { ...chart, chartType: event.chartType } : chart
      )
      handleUpdate(true)
      setChartData(updatedState)
    }
  }

  function onChartRangeSelectionChanged(event: ChartRangeSelectionChanged) {
    const currentChart = chartData.find((chart) => chart.chartId === event.chartId)

    if (currentChart) {
      const updatedState = chartData.map((chart) =>
        chart.chartId === event.chartId ? { ...chart, cellRange: event.cellRange } : chart
      )
      handleUpdate(true)
      setChartData(updatedState)
    }
  }

  function onChartDestroyed(event: ChartDestroyed) {
    const updatedChartData = chartData.filter((chartData) => chartData.chartId !== event.chartId)
    handleUpdate(true)
    setChartData(updatedChartData)
  }

  return (
    <>
      <div id='container' className='row d-flex overflow-auto'></div>
      <div
        className='card p-0 h-700px '
        style={{
          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
          border: '1',
          borderColor: '#F1F1F2',
        }}
      >
        <div
          className='d-flex flex-row overflow-auto border-0 p-9 pb-2'
          style={{ overflowY: 'auto', overflowX: 'auto', scrollbarWidth: 'none' }}
        >
          <div className='card-title'>
            <h1 className='mb-0'>{name}</h1>
            <p className='fs-7 text-gray-400 mb-0'>
              {data.length > 0 ? `${data.length} Entries` : ''}
            </p>
          </div>
          <div className='col d-flex flex-row justify-content-end'>
            <input
              type='search'
              className='form-control custom-input fw-bold shadow-lg h-38px mw-250px '
              name='search'
              placeholder='Search...'
              onChange={(e: any) => {
                gridRef.current.api.setQuickFilter(e.target.value)
              }}
            />

            <button
              type='button'
              value={'Columns'}
              onClick={() => openToolPanel('columns')}
              className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
            >
              <KTSVG
                path='/media/icons/duotune/layouts/lay004.svg'
                className='svg-icon-3 text-secondary-txt'
              />{' '}
              Columns
            </button>
            <button
              value={'Filters'}
              onClick={() => openToolPanel('filters')}
              className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-3 text-secondary-txt'
              />{' '}
              Filter
            </button>
          </div>
        </div>
        <div className='card-body w-100 px-9 pt-0 overflow-auto h-100'>
          <div style={containerStyle} className='ag-theme-material  '>
            <div className='d-flex flex-wrap justify-content-between'>
              <div style={gridStyle} className=''>
                <AgGridReact
                  sideBar={agConfigSideBar}
                  onGridReady={(params) => {
                    gridRef.current = params
                  }}
                  ref={gridRef}
                  components={componentsRegistry}
                  alwaysShowHorizontalScroll
                  alwaysMultiSort
                  animateRows={true}
                  defaultColDef={defaultColDef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  pagination={false}
                  enableCharts={true}
                  enableRangeSelection={true}
                  suppressRowTransform={true}
                  suppressCopyRowsToClipboard={true}
                  suppressCopySingleCellRanges={true}
                  suppressCellFocus={true}
                  suppressMenuHide={false}
                  tooltipShowDelay={50}
                  tooltipInteraction={true}
                  noRowsOverlayComponent={showNoRowsOverlay}
                  createChartContainer={createChartContainer}
                  onChartOptionsChanged={onChartOptionsChanged}
                  onChartRangeSelectionChanged={onChartRangeSelectionChanged}
                  onChartDestroyed={onChartDestroyed}
                  rowClass={'border-right-0 border-left-0 border-dashed'}
                  overlayNoRowsTemplate={
                    '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
                  }
                  getRowStyle={(params) => {
                    if (params.rowIndex % 2 === 0) {
                      return { background: '#f8f8f8' }
                    } else {
                      return { background: '#ffffff' }
                    }
                  }}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
