import { KTSVG } from '../../../../../../_metronic/helpers'
import LoaderComponent from './LoaderComponent'

type Props = {
  schema: any
  response: string
  filledValues: string
  isMutationLoading: boolean
}

export interface MessageModel {
  user: number
  type: 'in' | 'out'
  text: string
  template?: boolean
  showButtons?: boolean
}

type filteredValue = {
  key: string
  value: string
}

export function HissaAIResponse(props: Props) {
  const values = props.filledValues.split(';')
  return (
    <>
      <div className='card-body'>
        <h2>{'Field Identifier'}</h2>
        <hr className='my-auto flex-grow-1 mt-2' />
        <LoaderComponent isLoading={props.isMutationLoading} />
        {!props.isMutationLoading && (
          <div className='card my-6'>
            {values.length > 1 && <h5 className='mb-6 fw-bold'>Fields generated by Hissa.ai </h5>}
            {values.length === 1 && props.filledValues === '' && (
              <h5 className='mb-6 fw-bold'>
                Sorry, no fields are detected by Hissa.ai please try again.{' '}
              </h5>
            )}

            <div
              className='card-body h-400px overflow-auto py-6'
              style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}
            >
              {values.map((value, index) => {
                if (index < values.length - 1) {
                  return (
                    <div className='mb-6'>
                      <span>{value.split(':')[0]} </span>
                      <span className='me-4'>:</span>
                      <span
                        className='border border-1 rounded border-gray-600 fw-bold p-2 px-4'
                        style={{ maxWidth: '100px' }}
                        data-toggle='tooltip'
                        data-placement='top'
                        title={value.split(':')[1]}
                      >
                        {value.length > 60
                          ? value.split(':')[1].substring(0, 60) + '...'
                          : value.split(':')[1]}
                      </span>
                    </div>
                  )
                }
              })}
            </div>
            <div className='text-center separator pt-2'>
              <div className='pt-4'>AI-generated content may be incorrect</div>
              <div className='text-muted d-flex justify-content-center fs-6 pt-2'>
                (Please click out at any point to exit from the AI Form generator)
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
