type Props = {
  handleClose: () => void
  enableChat: () => void
  clearChat: () => void
}
function HissaAiActionMenu(props: Props) {
  const clearNCloseChat = () => {
    props.clearChat()
    props.handleClose()
  }
  return (
    <div className='d-flex justify-content-end'>
      <button
        className='btn btn-sm btn-secondary text-growth-card me-3 my-1'
        type='button'
        onClick={() => props.enableChat()}
      >
        Continue chat
      </button>
      <button
        className='btn btn-sm border border-primary text-primary btn-active-light-primary my-1'
        type='button'
        onClick={() => clearNCloseChat()}
      >
        Show Document
      </button>
    </div>
  )
}

export default HissaAiActionMenu
