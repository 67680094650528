import { Modal } from 'react-bootstrap'
import { deleteTransactionDocuments } from '../../../../api/investorApi'
import {
  CompanyObject,
  useGetCompanyDataQuery,
  useGetCompanyWiseDataQuery,
} from '../../../../generated/graphql'
import Toast, { ToastType } from '../../../../utils/toast'
import { queryClient } from '../../../../queries/client'
import { useState } from 'react'

type DeleteProps = {
  showModal: boolean
  handleClose: () => void
  investmentId: number
  fileKey: string
  isIndirect: boolean
  companyData?: CompanyObject
}

export function DeleteTransactionDocModal(props: DeleteProps) {
  const [loading, setLoading] = useState(false)
  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px '
      show={props.showModal}
      onHide={() => props.handleClose()}
    >
      <Modal.Body className='shadow border border-primary rounded'>
        <div className='card'>
          <div className='card-body'>
            <h2 className='fw-bolder text-gray-heading pb-4'>Delete Transaction Document</h2>
            <div className='fs-3 pb-6'>Are you sure to delete transaction Document?</div>

            <div className='d-flex col justify-content-between'>
              <button className='btn btn-lg btn-secondary' onClick={() => props.handleClose()}>
                <span className='indicator-label'>Cancel</span>
              </button>
              <button
                type='submit'
                className='btn btn-lg btn-primary'
                // disabled={loading}

                onClick={() => {
                  setLoading(true)
                  deleteTransactionDocuments(
                    props.investmentId ? Number(props.investmentId) : props?.investmentId,
                    props.fileKey,
                    props.isIndirect
                  )
                  if (props.companyData) {
                    const compKey = {
                      input: {
                        type: props.companyData?.type,
                        hissaCompanyId: props.companyData?.hissaCompanyId || '',
                        id: Number(props.companyData?.id) || 0,
                      },
                    }
                    const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
                    queryClient.invalidateQueries(getCompanyWiseQuery)
                  }
                  const getcompanyDataQuery = useGetCompanyDataQuery.getKey()
                  queryClient.invalidateQueries(getcompanyDataQuery)
                  setLoading(false)
                  Toast('Documents Deleted Successfully!', ToastType.success)
                }}
              >
                {!loading && <span className='indicator-label'>Confirm</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
