import { CompanyTypes } from "../../../../generated/graphql"

export enum ActivityType {
  Investment = 'Investment',
  Sell = 'Sell',
  Create_Watchlist = 'Create_Watchlist',
  Round_Raised = 'Round_Raised',
  Unicorn = 'Unicorn'
}

export interface ActivityTypeObject {
  type: ActivityType
  value: number
  date: string
  companyName?: string
  companyType?: CompanyTypes
  hissaCompanyId?: string
  companyId?: number
  investorName?: string
  watchlistName?: string
}