import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import { memo, useMemo, useRef, useState } from 'react'
import { CompanyObject, MisReportDataType } from '../../../generated/graphql'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { agConfigSideBar, tableCellStyle } from '../../../utils/AgGridConfig'
import {
  ReportDateRender,
  ReportFileRender,
  ReportNameRender,
  ReportTagsRender,
  ReportsActionRender,
} from './ReportsAgTableComponents'
import ReportsModal from './ReportsModal'

type Props = {
  data: MisReportDataType[]
  companyData: CompanyObject
}

export function ReportsAgTable({ data, companyData }: Props) {
  const gridApi = useRef<any>(null)

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const componentsRegistry = useMemo(
    () => ({
      reportName: memo(ReportNameRender),
      reportDate: memo(ReportDateRender),
      reportFile: memo(ReportFileRender),
      tags: memo(ReportTagsRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <div className='row justify-content-center  text-center py-8 m-6'>
      <img
        alt='no items'
        src={toAbsoluteUrl('/media/icons/duotune/general/empty-watchlist.svg')}
        className='h-150px my-10 w-350 justify-content-center'
      />
      <p className='fs-base text-muted text-center'>
        No MIS Reports Found, Get started by adding new report.
      </p>
    </div>
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Report Name',
        field: 'reportName',
        cellRenderer: 'reportName',
        cellStyle: tableCellStyle,
        // pinned: 'left',
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Report Date',
        field: 'reportDate',
        filter: 'agTextColumnFilter',
        cellRenderer: 'reportDate',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Report File',
        field: 'reportFile',
        filter: 'agTextColumnFilter',
        cellRenderer: 'reportFile',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Tags',
        field: 'tags',
        filter: 'agTextColumnFilter',
        cellRenderer: 'tags',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        hide: false,
        width: 60,
        maxWidth: 60,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: {
          'padding-left': '9px',
          'padding-right': '4px',
          'padding-top': '3px',
          'line-height': '20px',
        },
        resizable: false,
        sortable: false,
        cellRenderer: ReportsActionRender,
      },
    ],
    []
  )

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const rowData = useMemo(
    () =>
      data?.map((report: MisReportDataType) => {
        return {
          hissaCompanyId: companyData.hissaCompanyId,
          reportId: report.id,
          companyId: companyData.id,
          reportName: report.name,
          reportDate: report.reportDate,
          reportFile: report.fileUrl,
          tags: report.tags,
          type: companyData.type,
          companyName: companyData.name,
          isLastRecord: data.length === 1 ? true : false,
          companyData: companyData,
        }
      }),
    [data]
  )

  return (
    <div
      className='card p-0 h-500px overflow-auto'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          <h1 className='m-0'>MIS Reports</h1>
          <span className='text-muted'></span>
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <button
            type='submit'
            value={'Columns'}
            onClick={() => {
              setShowModal(!showModal)
            }}
            className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
          >
            Add Report
          </button>
          {showModal && (
            <ReportsModal
              companyData={companyData}
              closeModal={() => setShowModal(false)}
              showModal={showModal}
              isEdit={false}
            />
          )}
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={false}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
