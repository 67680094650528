import { formatNumber, getSymbolOfCurrency } from '../../../../../utils/CurrencyFormatter'
import {
  AddTransactionRoundDetails,
  InvestmentModellingStepperType,
  InvestmentRoundDetails,
} from './InvestmentModellingHelper'
import { ModelProgressBar } from './ModelProgressBar'

type modellingDataProps = {
  roundData: InvestmentRoundDetails
  leadInvestor: string
  sharePrice: number
  totalInvestment: number
  userCurrencyFormat: string
  userCurrency: string
}
export function InvestmentModelHeader(props: modellingDataProps) {
  const data = {
    roundName: props.roundData?.roundName || '',
    leadInvestor: props.leadInvestor || '',
    sharePrice: props.sharePrice || 0,
    postMoneyValuation: props.roundData?.postMoneyValuation || 0,
    roundSize: props.roundData?.roundSize || 0,
    dilution:
      props.roundData?.postMoneyValuation > 0 && props.roundData?.roundSize > 0
        ? (props.roundData?.roundSize / props.roundData?.postMoneyValuation) * 100
        : 0,
  }

  const leftToRaise = props.roundData.roundSize - props.totalInvestment || 0
  const percentage = (props.totalInvestment / props.roundData.roundSize) * 100 || 0

  return (
    <div
      className='card h-md-85px h-full p-3 bg-growth-card text-white'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='row'>
        <div className='col-12 col-md-3 card h-full bg-growth-card pb-4 pt-1 pb-md-0'>
          <div className={'border-2 border-end border-gray-600 px-4 '}>
            <div className='d-flex justify-content-between my-2'>
              <p className='fs-5 p-0 m-0 '>
                Round Name
                {' : '}
              </p>
              <p
                className='fs-4 fw-bold p-0 m-0 text-truncate'
                data-toggle='tooltip'
                title={data.roundName}
                style={{ maxWidth: '160px' }}
              >
                {data.roundName}
              </p>
            </div>
            <div className='d-flex justify-content-between'>
              <p className='fs-5 d-flex align-items-center p-0 m-0 '>
                Lead Investor
                {' : '}
              </p>
              <p
                className='fs-4 fw-bold p-0 m-0 text-truncate'
                data-toggle='tooltip'
                title={data.leadInvestor}
                style={{ maxWidth: '160px' }}
              >
                {data.leadInvestor ? data.leadInvestor : '-'}
              </p>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-3 card h-full bg-growth-card pb-4 pt-1 pb-md-0'>
          <div className='border-2 border-end border-gray-600 pe-md-4 px-4 px-md-0'>
            <div className='d-flex justify-content-between my-2'>
              <p className='fs-5 d-flex align-items-center p-0 m-0 '>
                Round Size
                {' : '}
              </p>
              <p
                className='fs-4 fw-bold d-flex align-items-center p-0 m-0 '
                data-toggle='tooltip'
                title={Number(data.roundSize).toLocaleString(props.userCurrencyFormat)}
              >
                {getSymbolOfCurrency(props.userCurrency)}
                {formatNumber({
                  value: data.roundSize,
                  format: props.userCurrencyFormat,
                })}
              </p>
            </div>
            <div className='d-flex justify-content-between'>
              <p className='fs-5 d-flex align-items-center p-0 m-0 '>
                FDB Share Price
                {' : '}
              </p>
              <p
                className='fs-4 fw-bold d-flex align-items-center p-0 m-0 '
                data-toggle='tooltip'
                title={Number(data.sharePrice).toLocaleString(props.userCurrencyFormat)}
              >
                {getSymbolOfCurrency(props.userCurrency)}
                {formatNumber({
                  value: data.sharePrice,
                  format: props.userCurrencyFormat,
                })}
              </p>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-3 card h-full bg-growth-card pb-4 pt-1 pb-md-0'>
          <div className='border-2 border-end border-gray-600 pe-md-4 px-4 px-md-0'>
            <div className='d-flex justify-content-between my-2'>
              <p className='fs-5 d-flex align-items-center p-0 m-0 '>
                Post-money Valuation
                {' : '}
              </p>
              <p
                className='fs-4 fw-bold d-flex align-items-center p-0 m-0 '
                data-toggle='tooltip'
                data-placement='top'
                title={Number(data.postMoneyValuation).toLocaleString(props.userCurrencyFormat)}
              >
                {getSymbolOfCurrency(props.userCurrency)}
                {formatNumber({
                  value: data.postMoneyValuation,
                  format: props.userCurrencyFormat,
                })}
              </p>
            </div>
            <div className='d-flex justify-content-between'>
              <p className='fs-5 d-flex align-items-center p-0 m-0 '>
                Dilution
                {' : '}
              </p>
              <p
                className='fs-4 fw-bold d-flex align-items-center p-0 m-0 '
                data-toggle='tooltip'
                title={Number(data.dilution).toLocaleString(props.userCurrencyFormat)}
              >
                {formatNumber({
                  value: data.dilution,
                  format: props.userCurrencyFormat,
                }) + '%'}
              </p>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-3 card h-full bg-growth-card'>
          <div className='pe-4'>
            <ModelProgressBar
              leftToRaise={leftToRaise}
              percentage={percentage}
              raisedAmount={props.totalInvestment}
              userCurrencyFormat={props.userCurrencyFormat}
              userCurrency={props.userCurrency}
              bgColor='bg-growth-card'
            />
          </div>
        </div>
      </div>
    </div>
  )
}
