import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { IAddFundUnitData, addFundUnitInits, addFundUnitSchema } from './AddFundUnitHelper'
import _ from 'lodash'
import {
  AddFundUnitMutation,
  AddFundUnitMutationVariables,
  EditFundUnitMutation,
  EditFundUnitMutationVariables,
  FundStructure,
  FundUnit,
  PartnerGroup,
  useAddFundUnitMutation,
  useEditFundUnitMutation,
  useGetFundStructureQuery,
  useGetFundUnitQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { isoToDate } from '../../../../utils/FormatDate'
import { InputBox } from '../../../../utils/NumberInputBox'

type Props = {
  fundStructure: FundStructure
  investorId: number
  handleClose: VoidFunction
  isEdit: boolean
  data?: FundUnit
  partnerData: PartnerGroup[]
}

function AddFundUnitForm(props: Props) {
  const initialData: IAddFundUnitData = {
    noOfUnits: props.data?.noOfUnits,
    name: props.data?.name || '',
    navDate: props.data?.navDate?.split('T')[0],
    nav: props.data?.nav || 1,
    class: props.data?.class || 'Class A',
  }
  const [data, setData] = useState<IAddFundUnitData>(
    _.cloneDeep(props.isEdit ? initialData : addFundUnitInits)
  )
  const [loading, setLoading] = useState(false)
  const [dateError, setDateError] = useState(false)
  const [isDisableNAV, setIsDisableNAV] = useState(false)
  let maxDate = new Date().toISOString().split('T')[0]
  let minDate = new Date(props.fundStructure.inceptionDate).toISOString().split('T')[0]

  const mutateAddFundUnit = useAddFundUnitMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddFundUnitMutation,
        _variables: AddFundUnitMutationVariables,
        _context: unknown
      ) => {
        const queryKey = useGetFundStructureQuery.getKey({
          input: props.investorId,
        })

        queryClient.invalidateQueries(queryKey)
        queryClient.invalidateQueries(
          useGetFundUnitQuery.getKey({
            input: { investorId: props.investorId, fundStructureId: props.fundStructure.id },
          })
        )
        setData(_.cloneDeep(addFundUnitInits))
        Toast('Fund Unit Added Successfully!', ToastType.success)
        return console.log('Fund unit addition success', data)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const mutateEditFundUnit = useEditFundUnitMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: EditFundUnitMutation,
        _variables: EditFundUnitMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetFundStructureQuery.getKey({ input: props.investorId }))
        queryClient.invalidateQueries(
          useGetFundUnitQuery.getKey({
            input: { investorId: props.investorId, fundStructureId: props.fundStructure.id },
          })
        )
        setData(_.cloneDeep(addFundUnitInits))
        Toast('Fund Unit Edited Successfully!', ToastType.success)
        return console.log('Fund unit edited success', data)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const formik = useFormik<IAddFundUnitData>({
    initialValues: data,
    validationSchema: addFundUnitSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        props.isEdit
          ? mutateEditFundUnit.mutate({
              input: {
                name: data.name,
                class: data.class,
                fundUnitId: props.data?.id,
                noOfUnits: data.noOfUnits,
                date: new Date(data.navDate).toISOString(),
                fundStructureId: props.fundStructure.id,
                investorId: props.investorId,
              },
            })
          : mutateAddFundUnit.mutate({
              input: {
                name: data.name,
                class: data.class,
                nav: data.nav,
                noOfUnits: data.noOfUnits,
                navDate: new Date(data.navDate).toISOString(),
                fundStructureId: props.fundStructure.id,
                investorId: props.investorId,
              },
            })
        setLoading(false)
        props.handleClose()
      }, 1000)
    },
  })

  useEffect(() => {
    if (
      (minDate && formik.values.navDate && minDate > formik.values.navDate) ||
      maxDate < formik.values.navDate
    ) {
      setDateError(true)
    } else if (
      (minDate && formik.values.navDate && minDate < formik.values.navDate) ||
      maxDate > formik.values.navDate
    ) {
      setDateError(false)
    }
  }, [minDate, formik.values.navDate])

  useEffect(() => {
    const selectedIds: number[] = []
    props.partnerData?.forEach((partner: PartnerGroup) => {
      partner.fundUnits?.length! > 0 &&
        partner.fundUnits?.forEach((fundUnit: any) => {
          if (!selectedIds.includes(Number(fundUnit.fundUnitId)))
            selectedIds.push(Number(fundUnit.fundUnitId))
        })
    })
    if (selectedIds.includes(Number(props.data?.id))) {
      setIsDisableNAV(true)
    } else if (!selectedIds.includes(Number(props.data?.id))) {
      setIsDisableNAV(false)
    }
  }, [props.data, props.partnerData])

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='w-100 mx-auto'>
        <div className='row'>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Unit Name</label>

            <InputBox
              isDisabled={false}
              name={'name'}
              handleChange={(value: string) => {
                formik.setFieldValue('name', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('name', value)
              }}
              placeholder='Enter Unit Name'
              currency={''}
              value={formik.values.name}
              type='text'
            />
            <div className='text-danger mt-2'>
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label'>Unit Class</label>

            <InputBox
              isDisabled={false}
              name={'class'}
              handleChange={(value: string) => {
                formik.setFieldValue('class', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('class', value)
              }}
              placeholder='Enter Unit Class'
              currency={''}
              value={formik.values.class}
              type='text'
            />
            <div className='text-danger mt-2'>
              {formik.touched.class && formik.errors.class && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.class}</div>
                </div>
              )}
            </div>
          </div>

          {((props.isEdit && isDisableNAV === false) || !props.isEdit) && (
            <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
              <label className='form-label required'>Number of Units</label>
              <InputBox
                isDisabled={false}
                name={'noOfUnits'}
                handleChange={(value: number) => {
                  formik.setFieldValue('noOfUnits', value)
                }}
                setFieldValue={(value: number) => {
                  formik.setFieldValue('noOfUnits', value)
                }}
                placeholder='Enter Number of Units'
                currency={''}
                value={formik.values.noOfUnits}
                type='number'
              />
              <div className='text-danger mt-2'>
                {formik.touched.noOfUnits && formik.errors.noOfUnits && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-7'>{formik.errors.noOfUnits}</div>
                  </div>
                )}
              </div>
            </div>
          )}

          {!props.isEdit && (
            <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
              <label className='form-label required'>Unit NAV</label>

              <InputBox
                isDisabled={false}
                name={'nav'}
                handleChange={(value: number) => {
                  formik.setFieldValue('nav', value)
                }}
                setFieldValue={(value: number) => {
                  formik.setFieldValue('nav', value)
                }}
                placeholder='Enter NAV'
                currency={''}
                value={formik.values.nav}
                isCurrency={true}
                type='number'
              />
              <div className='text-danger mt-2'>
                {formik.touched.nav && formik.errors.nav && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-7'>{formik.errors.nav}</div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Unit Creation Date</label>

            <input
              type='date'
              placeholder='10/10/2010'
              max={maxDate}
              min={minDate}
              className='form-control custom-input fw-bold shadow-lg h-38px'
              {...formik.getFieldProps('navDate')}
            />
            <div className='text-danger mt-2'>
              {formik.touched && dateError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{`Select date between ${isoToDate(
                    minDate
                  )} & ${isoToDate(maxDate)}`}</div>
                </div>
              )}
            </div>
            <div className='text-danger mt-2'>
              {formik.touched.navDate && formik.errors.navDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.navDate}</div>
                </div>
              )}
            </div>
          </div>

          <div className='d-flex justify-content-between pt-15'>
            <button
              type='button'
              className='btn btn-lg btn-secondary me-3'
              onClick={props.handleClose}
            >
              Cancel
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading || dateError}>
              {!loading && (props.isEdit ? 'Edit Unit' : 'Add Unit')}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default AddFundUnitForm
