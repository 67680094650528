import { Modal } from 'react-bootstrap'
import { useEffect } from 'react'
import { useIsMutating } from 'react-query'
import { useFormik } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'
import Select, { MultiValue } from 'react-select'
import * as Yup from 'yup'
import { SelectOptionType, multiSelectCustomStyles } from '../../../utils/MultiSelect'
import { selectCustomStyles } from '../../../utils/Select'
import { graphqlRequestClient, queryClient } from '../../../queries/client'
import { EditEeMetricMutation, MetricType, useEditEeMetricMutation, useGetEeMetricsQuery } from '../../../generated/graphql'
import Toast, { ToastType } from '../../../utils/toast'
import { metricTagsLV, metricTypeLV } from '../../../types/exitEnabler'

type Props = {
  showEditModal: boolean
  toggleEditModal: () => void
  metric: any
}

function EditEEMetric({ showEditModal, toggleEditModal, metric }: Props) {
  const editMutate = useEditEeMetricMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (data: EditEeMetricMutation) => {
        queryClient.invalidateQueries(useGetEeMetricsQuery.getKey())
        Toast(data.editEEMetric.message, ToastType.success)
        toggleEditModal()
      },
      onError: (error: any) => Toast(error.response.errors[0].message, ToastType.error),
    },
    {}
  )

  const editForm = useFormik({
    initialValues: {
      id: -1,
      name: '',
      moniker: '',
      type: '',
      tags: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('Enter metric name').min(3, 'Name must be at least 3 characters'),
      moniker: Yup.string()
        .trim()
        .required('Enter metric moniker / acronym')
        .min(3, 'Moniker must be at least 3 characters')
        .matches(/^[aA-zZ0-9]+$/, { message: 'Only alplanumeric characters allowed.' }),
      type: Yup.string().typeError('Select metric type').required('Select metric type'),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: function (values) {
      const editData = {
        id: metric.id,
        name: values.name.trim(),
        moniker: values.moniker.trim(),
        type: values.type as MetricType,
        tags: Array.isArray(values.tags) ? values.tags.join() : values.tags,
      }
      editMutate.mutate({ input: editData })
    },
  })

  useEffect(() => {
    if (metric) {
      editForm.setFieldValue('name', metric.name)
      editForm.setFieldValue('moniker', metric.moniker)
      editForm.setFieldValue('type', metric.type)
      editForm.setFieldValue('tags', metric.tags)
    } else {
      editForm.setFieldValue('name', '')
      editForm.setFieldValue('moniker', '')
      editForm.setFieldValue('type', '')
      editForm.setFieldValue('tags', '')
    }
    setTimeout(() => editForm.validateForm(), 50)
  }, [metric])

  function selectTags(e: MultiValue<SelectOptionType>) {
    const arr = e.map((e) => e.value)
    editForm.setFieldValue('tags', arr.join())
  }

  return (
    <Modal id='kt_modal_edit_metric' aria-hidden='true' show={showEditModal} onHide={toggleEditModal}>
      <form onSubmit={editForm.handleSubmit}>
        <Modal.Header className='p-2'>
          <Modal.Title className='ps-5'>Edit Metric</Modal.Title>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={toggleEditModal}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='mh-400px scroll-y me-n7 pe-7'>
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>Name</label>
              <input type='text' className='form-control' placeholder='Enter name' {...editForm.getFieldProps('name')} />
              <div className='text-danger mt-2'>
                {editForm.touched.name && editForm.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{editForm.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>Moniker / Acronym</label>
              <input type='text' className='form-control' placeholder='Enter moniker / acronym' {...editForm.getFieldProps('moniker')} />
              <div className='text-danger mt-2'>
                {editForm.touched.moniker && editForm.errors.moniker && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{editForm.errors.moniker}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className='form-label '>Tags</label>
              <Select
                options={metricTagsLV}
                isMulti
                name='tags'
                onChange={selectTags}
                defaultValue={metric && metricTagsLV.filter((f: any) => metric.tags.includes(f.value))}
                styles={multiSelectCustomStyles}
              />
              <div className='text-danger mt-2'>
                {editForm.touched.tags && editForm.errors.tags && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{editForm.errors.tags}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className='form-label required'>Type</label>
              <Select
                isSearchable={false}
                className='text-primary'
                options={metricTypeLV}
                name='type'
                defaultValue={metric && metricTypeLV.find((f: any) => f.value === metric.type)}
                onChange={(e: any) => editForm.setFieldValue('type', e.value)}
                styles={selectCustomStyles}
              />
              <div className='text-danger mt-2'>
                {editForm.touched.type && editForm.errors.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{editForm.errors.type}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <div className='d-flex justify-content-between'>
            <button type='button' className='btn btn-sm btn-secondary me-3' onClick={toggleEditModal}>
              Cancel
            </button>
            <button type='submit' className='btn btn-sm btn-primary' disabled={useIsMutating() > 0}>
              {useIsMutating() === 0 && 'Save'}
              {useIsMutating() > 0 && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default EditEEMetric
