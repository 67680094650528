import * as Yup from 'yup'

export interface IValuationDetails {
  name: string
  valuationDate: string
  valuationType?: string
  valuationReport?: string
  methodology?: string
  tags?: string[]
  sharePrice?: number
  sharePricePerReport: number
  sharePriceBookValue?: number
  valuatorName?: string
  isDefault?: boolean
}
export interface EditValuationDetails {
  name: string
  valuationDate: string
  valuationType?: string
  valuationReport?: string
  methodology?: string
  tags?: string[]
  sharePrice?: number
  sharePricePerReport: number
  sharePriceBookValue?: number
  valuatorName?: string
  isDefault?: boolean
}

const addValuationDetailSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a valuation name')
    .min(3, 'Valuation Name cannot be less than 3 characters')
    .label('Valuation Name'),
  valuationDate: Yup.string().required().label('Valuation Date'),
  sharePrice: Yup.number().label('Share Price as per FMV'),
  sharePricePerReport: Yup.number().required().moreThan(0).label('Share Price as per Report'),
  sharePriceBookValue: Yup.number().label('Share Price as on Book Value'),
})

const editValuationDetailSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter a valuation name')
    .min(3, 'Valuation Name cannot be less than 3 characters')
    .label('Valuation Name'),
  valuationDate: Yup.string().required().label('Valuation Date'),
  sharePricePerReport: Yup.number().required().moreThan(0).label('Share Price as per Report'),
  sharePriceBookValue: Yup.number().label('Share Price as on Book Value'),
  sharePrice: Yup.number().when(['isDefault'], {
    is: (isDefault: boolean) => isDefault === true,
    then: Yup.number().positive().required().moreThan(0).label('Share price FMV'),
    otherwise: Yup.number().notRequired(),
  }),
})

const addValuationDetailInits: IValuationDetails = {
  name: '',
  valuationDate: '',
  valuationType: '',
  valuationReport: '',
  methodology: '',
  tags: [],
  sharePricePerReport: 0,
  valuatorName: '',
  sharePrice: 0,
}
const editValuationDetailInits: EditValuationDetails = {
  name: '',
  valuationDate: '',
  valuationType: '',
  valuationReport: '',
  methodology: '',
  tags: [],
  sharePricePerReport: 0,
  valuatorName: '',
  sharePrice: 0,
}

export {
  addValuationDetailInits,
  addValuationDetailSchema,
  editValuationDetailSchema,
  editValuationDetailInits,
}
