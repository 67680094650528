import React from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG'
import EditCompanyDetailsForm from './EditCompanyDetailsForm'
import { CompanyObject, InvestorObject } from '../../../../../generated/graphql'

type EditCompanyDetailProps = {
  onClosed: () => void
  showModal: boolean
  hissaVerified: boolean
  companyData: CompanyObject
  isIndirect: boolean
  investorData: InvestorObject[]
}

function EditCompanyDetailsModal(props: EditCompanyDetailProps) {
  var investorIds = props.investorData.map((investor) => parseInt(investor.id.toString()))
  return (
    <Modal
      onHide={props.onClosed}
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-550px '
      show={props.showModal}
    >
      <div className='col d-flex justify-content-between p-9'>
        <div className='pt-4'>
          <h2 className='fw-bolder text-dark'>
            {!props.hissaVerified ? 'Edit Company Details' : 'Update Company Details'}
          </h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={() => {
            props.onClosed()
          }}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>

      <div className='d-flex row mb-9 mx-9'>
        <EditCompanyDetailsForm
          closeModal={props.onClosed}
          investorIds={investorIds}
          hissaVerified={props.hissaVerified}
          companyData={props.companyData}
          investorData={props.investorData}
          isIndirect={props.isIndirect}
        />
      </div>
    </Modal>
  )
}

export default EditCompanyDetailsModal
