import React, { useEffect, useState } from 'react'
import DocumentIssuePageWrapper from './DocumentIssuePageWrapper'
import { useLocation } from 'react-router-dom'
import { getCurrentTemplate } from '../../../../utils/stepper'
import { GetDealTemplatesQuery, useGetDealTemplatesQuery } from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { getInits } from '../../open-deals/components/termsheet-issuer/DraftTermsheetView'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { hexToRGB } from '../../../../utils/HexToRgba'

function DocumentIssueDraftPageWrapper() {
    const location = useLocation()
    let props: any = location.state
    const [allowedSections, setAllowedSections] = useState<any[]>([])
  const [templateId, setTemplateId] = useState()
    const { data,isFetched,isLoading,error } = useGetDealTemplatesQuery<
    GetDealTemplatesQuery,
    Error
  >(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (props.templateId) {
      const template = data?.dealTemplates?.find((template) => template?.id === props.templateId)
      if (template) {
        setAllowedSections(template?.sections!)
      }
      setTemplateId(props.templateId)
    }
  }, [isFetched])
  if (isLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )

  if (error) return <p>{error.message}</p>
  const inits = isFetched && getInits(props, data, props.templateId)
    const templateData = isFetched && getCurrentTemplate(data, props.templateId)
  return (
    <React.Fragment>
        <div className='card card-body fw-bold p-5 shadow-sm mb-4'>
        <h1 className='mb-0'>
            Document
            <span
              className={`badge fs-5 fw-semibold ms-5`}
              style={{
                backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 0.2),
                color: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 1),
              }}
            >
              Draft
            </span>
          </h1>  
        </div>
        <div>
    {templateId && <DocumentIssuePageWrapper 
    genericData={templateData}
    initialValues={inits}
    allowedSections={allowedSections}
    templateId={props.templateId}
    draftTermsheetId={props.id}/>}
    </div>
    </React.Fragment>
  )
}

export default DocumentIssueDraftPageWrapper