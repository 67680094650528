const convertToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      const baseString = fileReader?.result?.toString()?.replace('data:','');
      let dataToReturn;
      try{
        dataToReturn = baseString?.replace(/^.+,/, '')
      }
      catch (e){
        console.error(e)
        dataToReturn = baseString?.split("base64,")[1]
      }
      finally{
        resolve(dataToReturn)
      }
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })

export default convertToBase64
