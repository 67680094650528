import * as React from 'react'
import { createContext, useReducer } from 'react'
import { Actions, ActionType, getInitialState, reducer, StateType } from './reducer'

export type GlobalContextType = {
  state: StateType
  dispatch: React.Dispatch<ActionType>
}

type Props = {
  ctxUserId: number
  ctxInvestorIds: number[]
  ctxGroupIds: number[]
  filterType: 'Fund' | 'Group' | 'All'
  includeExits: boolean
  showExits: boolean
  userCurrency: string
  children?: React.ReactNode
}

export const GlobalContext = createContext<GlobalContextType>({
  state: getInitialState(),
  dispatch: () => null,
})

function GlobalContextProvider(props: Props) {
  const [state, dispatch] = useReducer(reducer, getInitialState())

  React.useEffect(() => {
    const data = localStorage.getItem('userPreferences')
    if (data) {
      dispatch({
        type: Actions.setUserCurrency,
        payload: JSON.parse(data).userCurrency,
      })
    }
  }, [props])
  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
