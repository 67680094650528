import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useCanUserAccess } from '../../../pages/store/userRoleGrants'
import { useAuth } from '../../auth'
import { queryCache } from '../../../../queries/client'

const Error404: FC = () => {
  const canDashboard = useCanUserAccess('read', 'dashboard')
  const canPortfolio = useCanUserAccess('read', 'portfolio')
  const canWatchlist = useCanUserAccess('read', 'watchlist')
  const canDeals = useCanUserAccess('read', 'documentAssembler')
  const canFundMetrics = useCanUserAccess('read', 'fundMetrics')
  const { logout } = useAuth()

  const accessiblePage = canDashboard
    ? '/dashboard'
    : canPortfolio
    ? '/portfolio'
    : canWatchlist
    ? '/watchlist'
    : canDeals
    ? '/deals'
    : canFundMetrics
    ? '/fundMetrics'
    : '/noAccess'

  const accessiblePageName = canDashboard
    ? 'Go to Dashboard'
    : canPortfolio
    ? 'Go to Portfolios'
    : canWatchlist
    ? 'Go to Watchlist'
    : canDeals
    ? 'Go to Deals'
    : canFundMetrics
    ? 'Go to FundMetrics'
    : 'Sign Out'

  return (
    <div className='d-flex flex-column flex-root h-100'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        <img
          src={toAbsoluteUrl('/media/illustrations/sketchy-1/15.png')}
          alt=''
          className='mw-100 mb-10 h-lg-150px'
        />
        <h1 className='fw-bold mb-10'>Oops, somethings not right. </h1>
        <h3 className='mb-10 text-muted'>Lets start again!</h3>
        <Link
          to={accessiblePage}
          className='btn btn-primary'
          onClick={() => {
            if (accessiblePageName === 'Sign Out') logout()
            queryCache.clear()
          }}
        >
          {accessiblePageName}
        </Link>
      </div>
    </div>
  )
}

export { Error404 }
