import { useEffect, useState } from 'react'
import { MyCaptablesAGTable } from './components/MyCaptablesAGTable'
import {
  GetAllPrivateCaptablesQuery,
  PrivateCaptableType,
  useGetAllPrivateCaptablesQuery,
} from '../../../generated/graphql'
import { graphqlRequestClient } from '../../../queries/client'

function MyCaptablesPageWrapper() {
  const { data, isLoading } = useGetAllPrivateCaptablesQuery<GetAllPrivateCaptablesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  if (isLoading) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  return (
    <>
      {/* Captable AG Table */}
      <div className='row mb-9'>
        <div className='col-12'>
          <div className='row gx-9'>
            <div className='col-12 mb-md-0'>
              <MyCaptablesAGTable
                captables={data?.getAllPrivateCaptables as PrivateCaptableType[]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
  // }

  return <></>
}

export default MyCaptablesPageWrapper
