import { useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import AddEEFilter from './AddEEFilter'
import EditEEFilter from './EditEEFilter'
import RemoveEEFilter from './RemoveEEFilter'
import _ from 'lodash'
import { filterTypeLV } from '../../../types/exitEnabler'

type Props = { filters: any[] }

const EEFilters = ({ filters }: Props) => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const toggleAddModal = () => setShowAddModal(!showAddModal)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const toggleEditModal = () => setShowEditModal(!showEditModal)
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
  const toggleRemoveModal = () => setShowRemoveModal(!showRemoveModal)
  const [filter, setFilter] = useState(null)

  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fs-3 mb-1'>Filters</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{filters ? filters.length : 'No'} filters available</span>
          </h3>
          <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Click to add a filter'>
            <button
              className='btn btn-sm btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_add_filter'
              onClick={() => {
                setFilter(null)
                toggleAddModal()
              }}
            >
              <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-2' />
              Add Filter
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-5'>
              <thead>
                <tr>
                  <th className='p-0 min-w-150px'></th>
                  <th className='p-0 min-w-125px'></th>
                  <th className='p-0 min-w-125px'></th>
                  <th className='p-0 min-w-40px'></th>
                </tr>
              </thead>
              <tbody>
                {filters &&
                  filters.map((f: any, i: any) => (
                    <tr key={i}>
                      <td>
                        <p className='text-dark text-hover-primary mb-1 fs-6'>{f.name}</p>
                        <span className='text-muted fw-semibold d-block fs-7'>
                          {f.type && filterTypeLV.find((ft: any) => ft.value === f.type).label}
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-semibold'>{f.group}</span>
                      </td>
                      <td>{f.formula}</td>
                      <td>
                        <div className='d-flex justify-content-end'>
                          <button
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary mx-1'
                            onClick={() => {
                              setFilter(_.cloneDeep(f))
                              toggleEditModal()
                            }}
                          >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                          </button>
                          <button
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-danger mx-1'
                            onClick={() => {
                              setFilter(_.cloneDeep(f))
                              toggleRemoveModal()
                            }}
                          >
                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AddEEFilter showAddModal={showAddModal} toggleAddModal={toggleAddModal} />
      <EditEEFilter showEditModal={showEditModal} toggleEditModal={toggleEditModal} filter={filter} />
      <RemoveEEFilter showRemoveModal={showRemoveModal} toggleRemoveModal={toggleRemoveModal} filter={filter} />
    </>
  )
}

const math = /^\s*[a-zA-Z_.]*[a-zA-Z](\s*[-+*%/]\s*[a-zA-Z_.]*[a-zA-Z])*\s*$/
const inBrace = /[(]([^()]*)[)]/
export function validateFormula(expr: string) {
  while (expr.includes(')') || expr.includes('(')) {
    const m = expr.match(inBrace)
    if (m) {
      if (!math.test(m[1])) {
        return false
      }
      const ind = m.index || 0
      expr = expr.substring(0, ind) + ' abc ' + expr.substring(ind + m[0].length)
    } else {
      return false
    }
  }
  return math.test(expr)
}

export { EEFilters }
