import { useEffect, useRef, useState } from 'react'
import {
  AllAccountOfUserQuery,
  AllInvestorsQuery,
  GetAllInvestorComapniesQuery,
  useAddMultipleInvestorsToUserMutation,
  useAddUserToInvestorMutation,
  useAllAccountOfUserQuery,
  useAllInvestorsQuery,
  useGetAllInvestmentsQuery,
  useGetAllInvestorComapniesQuery,
  useGetInvestorsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../utils/Select'
import { useFormik } from 'formik'
import Toast, { ToastType } from '../../../../utils/toast'
import { useNavigate } from 'react-router'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import { addAnotherUserSchema } from '../AccountHelper'
import { roles } from '../utils'
import { multiSelectCustomStyles } from '../../../../utils/MultiSelect'
import makeAnimated from 'react-select/animated'

const initialValues = {
  emailId: '',
  accountName: '',
  companies: '',
  isAdmin: '',
}

type companyData = {
  companyName: string
  companyId: string
}
type options = {
  value: number
  label: string
  index?: number
}
type account = {
  name: string
  id: number
}
type fund = {
  name: string
  id: number
}
function AddAnotherUser() {
  const navigate = useNavigate()
  const [table, setTable] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [companyIndex, setCompanyIndex] = useState(0)
  const [accountName, setAccountName] = useState<account>()
  const [fund, setFund] = useState<fund>()
  const [investorList, setInvestorList] = useState<options[]>([])
  const [investorIdList, setInvestorIdList] = useState<number[]>([])
  const [investorName, setInvestorName] = useState<string>()
  const [access, setAccess] = useState<companyData[]>([])
  const mutateAddMultipleUser = useAddMultipleInvestorsToUserMutation<Error>(
    graphqlRequestClient,
    {},
    {}
  )
  const mutateAddAnotherUser = useAddUserToInvestorMutation<Error>(graphqlRequestClient, {}, {})
  const multiSelectRef = useRef<any>(null)
  const animatedComponents = makeAnimated()
  const formik = useFormik({
    initialValues,
    validationSchema: addAnotherUserSchema,
    onSubmit: async (values) => {
      setLoading(true)
      access?.forEach((m) => m.companyId !== '')
      const companies = {
        hissa: access?.filter((h) => isNaN(h.companyId as any)),
        manual: access?.filter((m) => !isNaN(m.companyId as any)),
      }
      const fundAdmin = values.isAdmin === 'Admin' ? true : false
      investorList.length === 1
        ? mutateAddAnotherUser.mutate(
            {
              input: {
                accountId: accountName?.id,
                userEmailId: values.emailId.trim(),
                companies: companies,
                isAdmin: fundAdmin,
                investorName: investorName?.trim() || '',
                role: roles.find((role) => role.label === values.isAdmin)?.value,
              },
            },
            {
              onSuccess: (result) => {
                setLoading(false)
                queryClient.invalidateQueries(useGetInvestorsQuery.getKey())
                queryClient.invalidateQueries(useGetAllInvestmentsQuery.getKey())
                navigate('/dashboard')
                Toast('user created Successfully', ToastType.success)
              },
              onError: (error: any) => {
                setLoading(false)
                Toast(`${error.response.errors[0].message}`, ToastType.error)
              },
            }
          )
        : mutateAddMultipleUser.mutate(
            {
              input: {
                accountId: accountName?.id,
                userEmailId: values.emailId.trim(),
                isAdmin: fundAdmin,
                investorIds: investorIdList,
                role: roles.find((role) => role.label === values.isAdmin)?.value,
              },
            },
            {
              onSuccess: (result) => {
                setLoading(false)
                queryClient.invalidateQueries(useGetInvestorsQuery.getKey())
                queryClient.invalidateQueries(useGetAllInvestmentsQuery.getKey())
                navigate('/dashboard')
                Toast('user created Successfully', ToastType.success)
              },
              onError: (error: any) => {
                setLoading(false)
                Toast(`${error.response.errors[0].message}`, ToastType.error)
              },
            }
          )
    },
  })

  const { isSuccess, data } = useAllInvestorsQuery<AllInvestorsQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        accountName: accountName?.name,
        accountId: accountName?.id,
      },
    },
    { enabled: accountName !== undefined }
  )

  const allAccounts = useAllAccountOfUserQuery<AllAccountOfUserQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )
  const allCompanies = useGetAllInvestorComapniesQuery<GetAllInvestorComapniesQuery>(
    graphqlRequestClient,
    { input: { investorId: fund?.id, investorName: fund?.name } },
    {
      enabled: fund !== undefined,
    }
  )
  const accountOptions = allAccounts.data?.getAllAccountOfUser.map((account: account) => {
    return { value: account.id, label: account.name }
  })
  const fundOptions: options[] = []
  if (formik.values.accountName && isSuccess) {
    data?.getAllInvestors.forEach((fund: fund, index: number) => {
      if (fund.name !== '') {
        fundOptions.push({ value: fund.id, label: fund.name, index: index })
      }
    })
  }
  const roleOptions = roles
  const companies: companyData[] = []
  useEffect(() => {
    if (
      allCompanies?.data &&
      allCompanies?.data &&
      allCompanies?.data.allInvestorCompanies &&
      allCompanies?.data.allInvestorCompanies.companies
    ) {
      allCompanies?.data?.allInvestorCompanies.companies.hissa.forEach((h: companyData) => {
        companies.push(h)
        setAccess((prevAccess) => [...prevAccess!, h])
      })
      allCompanies?.data?.allInvestorCompanies.companies.manual.forEach((m: companyData) => {
        companies.push(m)
        setAccess((prevAccess) => [...prevAccess!, m])
      })
    }
  }, [allCompanies.data])
  if (
    allCompanies?.data &&
    allCompanies?.data &&
    allCompanies?.data.allInvestorCompanies &&
    allCompanies?.data.allInvestorCompanies.companies
  ) {
    allCompanies?.data?.allInvestorCompanies.companies.hissa.forEach((h: companyData) => {
      companies.push(h)
    })
    allCompanies?.data?.allInvestorCompanies.companies.manual.forEach((m: companyData) => {
      companies.push(m)
    })
  }

  const tableData = companies.map((company: companyData, index: number) => {
    return (
      <tr key={`${company.companyName}`}>
        <td>
          {company.companyName}{' '}
          {company.companyId !== '' && (
            <KTSVG path='/media/icons/duotune/general/gen043.svg' className=' text-primary' />
          )}
        </td>
        <td>
          {' '}
          <Select
            options={[{ value: 'Admin', label: 'Admin' }]}
            styles={selectCustomStyles}
            defaultInputValue='Admin'
          />
        </td>
        <td>
          <Select
            styles={selectCustomStyles}
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' },
            ]}
            defaultValue={[{ value: 'Yes', label: 'Yes' }]}
            onChange={(e: any) => {
              setAccess((prevAccess) => {
                const newAccess = new Set(prevAccess)
                if (e.value === 'Yes') {
                  newAccess.add(company)
                } else if (e.value === 'No') {
                  newAccess.delete(company)
                }
                return Array.from(newAccess)
              })
            }}
          />
        </td>
      </tr>
    )
  })

  return (
    <div className='col d-flex justify-content-center'>
      <form className='card px-5 pt-5 w-50' onSubmit={formik.handleSubmit}>
        <div className='py-5'>
          <h3 className='required'>Select the Account Name :</h3>
          <Select
            options={accountOptions}
            styles={selectCustomStyles}
            name='accountName'
            onChange={(e: any) => {
              formik.setFieldValue('accountName', e.value)
              setAccountName({ name: e.label, id: e.value })
            }}
          />
          {formik.errors.accountName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.accountName}</div>
            </div>
          )}
          {/* <h3 className='ms-2 fw-bold'>{defaultAccount}</h3> */}
        </div>
        <div className='pb-5'>
          <h3 className='required'>Select the Investor/Fund :</h3>
          <Select
            ref={multiSelectRef}
            options={fundOptions}
            className='w-100 text-primary'
            onChange={(e: any) => {
              setInvestorList(e)
              setInvestorIdList(e.map((element: options) => Number(element.value)))
              setCompanyIndex(e.index)
              if (e.length === 1) {
                setInvestorName(e[0].label)
                setFund({ name: e[0].label, id: e[0].value })
              }
              if (e.length !== 1) {
                setInvestorName(undefined)
                setFund(undefined)
              }
              setTable(false)
              setAccess([])
            }}
            isMulti
            closeMenuOnSelect={false}
            styles={multiSelectCustomStyles}
            components={animatedComponents}
          />
          {accountName && investorList.length === 0 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{'Please select the Investor/Fund'}</div>
            </div>
          )}
        </div>
        <div className='pb-5'>
          <h3 className='required'>Email Address :</h3>
          <input
            className='form-control custom-input fw-bold shadow-lg h-38px'
            type='email'
            autoComplete='off'
            placeholder='Email Address'
            {...formik.getFieldProps('emailId')}
          />
          {formik.errors.emailId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.emailId}</div>
            </div>
          )}
        </div>
        <div className='pb-5'>
          <h3 className='required'>Role :</h3>
          <Select
            options={roleOptions}
            styles={selectCustomStyles}
            name='isAdmin'
            onChange={(e: any) => {
              formik.setFieldValue('isAdmin', e.label)
            }}
          />
          {formik.errors.isAdmin && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.isAdmin}</div>
            </div>
          )}
        </div>
        {investorList.length === 1 && (
          <h5
            onClick={() => {
              setTable(!table)
            }}
            className='text-primary cursor-pointer pb-5'
          >
            <KTSVG
              className='svg-icon-1 text-primary'
              path='/media/icons/duotune/coding/cod001.svg'
            />
            Advance Settings
          </h5>
        )}
        {table && (
          <div className='card border border-gray-300 p-3'>
            <table className='table table-row-bordered'>
              <thead>
                <tr>
                  <th className='fw-bold'>Company Name</th>
                  <th className='fw-bold'>Company Role</th>
                  <th className='fw-bold'>Access</th>
                </tr>
              </thead>
              <tbody>{tableData}</tbody>
            </table>
          </div>
        )}
        <div className='px-5 py-3 text-center'>
          <button className='btn btn-lg btn-primary' type='submit'>
            {!loading && <span className='indicator-label fw-bold'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddAnotherUser
