import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { MenuComponent, StickyComponent } from '../../../_metronic/assets/ts/components'
import { GetEeMetricsQuery, useGetEeMetricsQuery } from '../../../generated/graphql'
import { graphqlRequestClient } from '../../../queries/client'

const EEMenu: React.FC = () => {
  const location = useLocation()
  const [eeAuthAdmin, setEEAuthAdmin] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      StickyComponent.reInitialization()
    }, 50)
  }, [])

  const eeMetrics = useGetEeMetricsQuery<GetEeMetricsQuery, Error>(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (eeMetrics && eeMetrics.data && eeMetrics.data.eeMetrics) setEEAuthAdmin(true)
  }, [eeMetrics.data])

  return (
    <div className='d-flex overflow-auto px-10 header-bg z-index-3 w-100' data-kt-sticky='true'>
      <ul className='nav nav-stretch nav-line-tabs fs-5 fw-500 flex-nowrap border-bottom-transparent h-45px'>
        <li className='nav-item'>
          <Link
            className={`nav-link text-active-primary me-6 ` + (location.pathname === '/exitEnabler/eePortfolios' && 'active text-primary')}
            to='/exitEnabler/eePortfolios'
          >
            Portfolios
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={`nav-link text-active-primary me-6 ` + (location.pathname === '/exitEnabler/modelMyExit' && 'active text-primary')}
            to='/exitEnabler/modelMyExit'
          >
            Model My Exit
          </Link>
        </li>
        <li className='nav-item'>
          <Link
            className={`nav-link text-active-primary me-6 ` + (location.pathname === '/exitEnabler/modelWatchlist' && 'active text-primary')}
            to='/exitEnabler/modelWatchlist'
          >
            Model Watchlist Exit
          </Link>
        </li>{' '}
        {eeAuthAdmin && (
          <li className='nav-item'>
            <Link
              className={`nav-link text-active-primary me-6 ` + (location.pathname === '/exitEnabler/admin' && 'active text-primary')}
              to='/exitEnabler/admin'
            >
              RZ Admin
            </Link>
          </li>
        )}
      </ul>
    </div>
  )
}

export { EEMenu }
