import { Modal } from 'react-bootstrap'
import { useIsMutating } from 'react-query'
import { useFormik } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'
import Select from 'react-select'
import * as Yup from 'yup'
import { selectCustomStyles } from '../../../utils/Select'
import { graphqlRequestClient, queryClient } from '../../../queries/client'
import {
  AddEeCompanyMetricMutation,
  GetGlobalCompaniesQuery,
  GlobalCompanySearchType,
  useAddEeCompanyMetricMutation,
  useGetEeCompanyMetricsQuery,
  useGetGlobalCompaniesQuery,
} from '../../../generated/graphql'
import Toast, { ToastType } from '../../../utils/toast'
import { useEffect, useState } from 'react'
import { multiSelectCustomStyles } from '../../../utils/MultiSelect'
import _ from 'lodash'

type Props = {
  showAddModal: boolean
  toggleAddModal: () => void
  metrics: any[]
  compMetric: any
}

function AddEECompMetric({ showAddModal, toggleAddModal, metrics, compMetric }: Props) {
  const [metric, setMetric] = useState()
  const [loookupName, setLoookupName] = useState('')
  const [allCompanies, setAllCompanies] = useState<GlobalCompanySearchType[]>([])
  const [filteredCompanies, setFilteredCompanies] = useState<GlobalCompanySearchType[]>([])
  const [company, setCompany] = useState<any>(null)
  const [companyOptions, setCompanyOptions] = useState<any>([])
  const metricsList: any[] = []
  metrics && metrics.map((m) => metricsList.push({ label: m.name, value: m.id }))

  const addMutate = useAddEeCompanyMetricMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (data: AddEeCompanyMetricMutation) => {
        queryClient.invalidateQueries(useGetEeCompanyMetricsQuery.getKey())
        addForm.resetForm()
        Toast(data.addEECompanyMetric.message, ToastType.success)
        toggleAddModal()
      },
      onError: (error: any) => Toast(error.response.errors[0].message, ToastType.error),
    },
    {}
  )

  const addForm = useFormik({
    initialValues: {
      id: -1,
      name: '',
      cin: '',
      compId: '',
      metricId: '',
      value: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('Enter company name').min(3, 'Name must be at least 3 characters'),
      cin: Yup.string().trim().required('Enter company identification number').min(3, 'CIN must be at least 3 characters'),
      compId: Yup.string().trim().required('Enter company id'),
      metricId: Yup.string().required('Select metric').min(1, 'Select metric'),
      value: Yup.string()
        .required('Enter metric value')
        .when('metricId', {
          is: (metricId: any) => metricId > 0 && ['NUMBER', 'AMOUNT', 'PERCENT'].includes(metrics.find((f: any) => f.id == metricId).type),
          then: Yup.string().matches(/^[\d.]+$/, 'Metric value should be a number'),
          otherwise: Yup.string().notRequired(),
        }),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: function (values) {
      const addData = {
        name: values.name.trim(),
        cin: values.cin.trim(),
        compId: Number(values.compId.replace(/\D/g, '')),
        metricId: values.metricId,
        value: values.value,
      }
      console.log('~   AddEECompMetric  addData:', addData)

      addMutate.mutate({ input: addData })
    },
  })

  useEffect(() => {
    if (compMetric) {
      addForm.setFieldValue('name', compMetric.name)
      addForm.setFieldValue('cin', compMetric.cin)
      addForm.setFieldValue('compId', compMetric.compId)
      addForm.setFieldValue('metricId', compMetric.metricId)
      setMetric(metricsList.find((m: any) => m.value === compMetric.metricId))
    } else {
      addForm.setFieldValue('name', '')
      addForm.setFieldValue('cin', '')
      addForm.setFieldValue('compId', '')
      addForm.setFieldValue('metricId', '')
    }
    addForm.validateForm()
  }, [compMetric])

  useEffect(() => {
    if (company) {
      addForm.setFieldValue('name', company.name)
      addForm.setFieldValue('cin', company.cin)
      addForm.setFieldValue('compId', company.id)
    } else {
      addForm.setFieldValue('name', '')
      addForm.setFieldValue('cin', '')
      addForm.setFieldValue('compId', '')
    }
    addForm.validateForm()
  }, [company])

  function handleCancel() {
    addForm.resetForm()
    toggleAddModal()
  }

  const { data: globalCompanies } = useGetGlobalCompaniesQuery<
  GetGlobalCompaniesQuery,
  Error
>(graphqlRequestClient, {}, {})


useEffect(() => {
  if (globalCompanies && globalCompanies.globalCompaniesSearch) {
    setAllCompanies(
      _.cloneDeep(globalCompanies.globalCompaniesSearch) as GlobalCompanySearchType[]
    )
  }
}, [globalCompanies])

function getSearchedCompanies(search: string) {
  const startsWithCompanies = allCompanies.filter((company) => {
    return company.name?.toLowerCase().startsWith(search.toLowerCase())
  })
  const includesWithCompanies = allCompanies.filter((company) => {
    return (
      company.name?.toLowerCase().includes(search.toLowerCase()) &&
      !company.name.toLowerCase().startsWith(search.toLowerCase())
    )
  })
  const filteredResult = _.union(startsWithCompanies, includesWithCompanies)
  search === '' ? setFilteredCompanies([]) : setFilteredCompanies(filteredResult)
}

useEffect(() => {
 if(filteredCompanies && filteredCompanies.length > 0){
  let options =
  filteredCompanies.map((company: any) => {
      return {
      value: company.name,
      label: company.dba !== '' && company.dba !== 'NA' ? company.name + ' (' + company.dba + ')' : company.name,
      name: company.name,
      cin: company.cin,
      id: company.id,
    }
  })
  setCompanyOptions(options)
}else setCompanyOptions([])
}, [loookupName])

  return (
    <Modal id='kt_modal_add_compmetric' aria-hidden='true' show={showAddModal} onHide={handleCancel}>
      <form onSubmit={addForm.handleSubmit}>
        <Modal.Header className='p-2'>
          <Modal.Title className='ps-5'>Add Company Metric</Modal.Title>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={handleCancel}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='mh-400px scroll-y me-n7 pe-7'>
            {(compMetric === null || compMetric.name === null || compMetric.name === '') && (
              <div className='fv-row mb-6 col-12 pe-4'>
                <label className='form-label required'>Lookup Company</label>
                <Select
                  styles={multiSelectCustomStyles}
                  inputId='aria-company-input'
                  name='aria-company'
                  options={companyOptions}
                  isClearable={true}
                  onInputChange={(e) => {
                    setLoookupName(e)
                    getSearchedCompanies(e)                    
                  }}
                  onChange={(e: any) => {
                    var index = companyOptions.findIndex((c: any) => e && c.name == e.name)
                    if (index > -1) setCompany(companyOptions[index])
                    else setCompany(null)
                  }}
                />
              </div>
            )}
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>Name</label>
              <input type='text' className='form-control' placeholder='Enter company name' {...addForm.getFieldProps('name')} />
              <div className='text-danger mt-2'>
                {addForm.touched.name && addForm.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addForm.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>CIN</label>
              <input type='text' className='form-control' placeholder='Enter CIN' {...addForm.getFieldProps('cin')} />
              <div className='text-danger mt-2'>
                {addForm.touched.cin && addForm.errors.cin && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addForm.errors.cin}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>ID</label>
              <input type='text' className='form-control' placeholder='Enter ID' {...addForm.getFieldProps('compId')} />
              <div className='text-danger mt-2'>
                {addForm.touched.cin && addForm.errors.cin && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addForm.errors.cin}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>Metric</label>
              <Select
                options={metricsList}
                name='metric'
                value={metric}
                onChange={(e: any) => addForm.setFieldValue('metricId', e.value)}
                styles={selectCustomStyles}
              />
              <div className='text-danger mt-2'>
                {addForm.touched.metricId && addForm.errors.metricId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addForm.errors.metricId}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>Value</label>
              <input type='text' className='form-control' placeholder='Enter metric value' {...addForm.getFieldProps('value')} />
              <div className='text-danger mt-2'>
                {addForm.touched.value && addForm.errors.value && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addForm.errors.value}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <div className='d-flex justify-content-between'>
            <button type='button' className='btn btn-sm btn-secondary me-3' onClick={handleCancel}>
              Cancel
            </button>
            <button type='submit' className='btn btn-sm btn-primary' disabled={useIsMutating() > 0}>
              {useIsMutating() === 0 && 'Add Company Metric'}
              {useIsMutating() > 0 && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default AddEECompMetric
