import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetEePortfoliosQuery, useGetEePortfoliosQuery } from '../../../generated/graphql'
import { KTSVG } from '../../../_metronic/helpers'
import { graphqlRequestClient } from '../../../queries/client'
import Toast, { ToastType } from '../../../utils/toast'
import _ from 'lodash'
import { kFormatter, kFormatterRupee } from '../../../utils/CurrencyFormatter'
import ViewEEPortfolio from './ViewEEPortfolio'
import RemoveEEPortfolio from './RemoveEEPortfolio'
import PublishEEPortfolio from './PublishEEPortfolio'
import jwtDecode from 'jwt-decode'
import { getAuth, JwtPayload } from '../../modules/auth'

const EEPortfolios = () => {
  const navigate = useNavigate()
  const auth = getAuth()!
  const jwt = jwtDecode<JwtPayload>(auth)
  const [portfolios, setPortfolios] = useState([])
  const [showViewModal, setShowViewModal] = useState<boolean>(false)
  const toggleViewModal = () => setShowViewModal(!showViewModal)
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
  const toggleRemoveModal = () => setShowRemoveModal(!showRemoveModal)
  const [showPublishModal, setShowPublishModal] = useState<boolean>(false)
  const togglePublishModal = () => setShowPublishModal(!showPublishModal)
  const [portfolio, setPortfolio] = useState(null)

  const eePortfolios = useGetEePortfoliosQuery<GetEePortfoliosQuery, Error>(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (eePortfolios.data && eePortfolios.data.eePortfolios) setPortfolios(eePortfolios.data.eePortfolios)
    if (eePortfolios && eePortfolios.error)
      Toast(
        `Oops, something's broken. Error: ${eePortfolios.error?.message.substring(0, eePortfolios.error?.message?.indexOf(':'))}`,
        ToastType.error
      )
  }, [eePortfolios])

  return (
    <>
      {!eePortfolios.isLoading && portfolios.length === 0 && (
        <div className='card mb-5'>
          <div className='card-header'>
            <h3 className='card-title align-items-start flex-column'>
              Create your first portfolio
              <span className='text-muted mt-1 fw-semibold fs-7'>
                This is a invite only special module to empower you to plan exits from your funds
              </span>
            </h3>
          </div>
          <div className='card-body py-2'>
            <p>Step 1: Go to 'Model My Exit'</p>
            <p>Step 2: Apply your filters or Pick a theme, change filter range as per your need</p>
            <p>Step 3: Click 'Create Portfolio' and alter weightage and/or share-prices</p>
          </div>
        </div>
      )}
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fs-3 mb-1'>Portfolios</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {eePortfolios.isLoading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : portfolios.filter((p: any) => jwt.id === p.userId || (jwt.id !== p.userId && p.status === 'Published')) ? (
                portfolios.filter((p: any) => jwt.id === p.userId || (jwt.id !== p.userId && p.status === 'Published')).length
              ) : (
                'No'
              )}{' '}
              portfolio{portfolios.filter((p: any) => jwt.id === p.userId || (jwt.id !== p.userId && p.status === 'Published')).length !== 1 && 's'}
            </span>
          </h3>
        </div>
        <div className='card-body p-0'>
          {eePortfolios.isLoading ? (
            <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
              <span className='spinner-grow'></span>
            </div>
          ) : (
            <div className='table-responsive'>
              <table className='table align-middle table-row-dashed gs-0 gy-5'>
                <thead>
                  <tr>
                    <th className='ps-5'>Name</th>
                    <th className='text-end'>
                      Expected Amount (<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                    </th>
                    <th className='text-end'>
                      Min Amount (<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                    </th>
                    <th className='text-center'>Status</th>
                    <th className='text-center'>Type</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {portfolios &&
                    portfolios.map(
                      (p: any, i: any) =>
                        (jwt.id === p.userId || (jwt.id !== p.userId && p.status === 'Published')) && (
                          <tr
                            key={i}
                            className='bg-hover-light'
                            onClick={() => {
                              setPortfolio(_.cloneDeep(p))
                              toggleViewModal()
                            }}
                          >
                            <td className='ps-5'>
                              <p className='text-dark text-hover-primary mb-1 fs-6'>{p.name}</p>
                            </td>
                            <td className='text-end'>
                              <p className='mb-1'>{kFormatter(p.expAmount)}</p>
                              <span className='text-muted d-block fs-7'>
                                {p.expAmount > 999 && kFormatterRupee(Number(p.expAmount.toString().split('.')[0]))}
                              </span>
                            </td>
                            <td className='text-end'>
                              <p className='mb-1'>{kFormatter(p.minAmount)}</p>
                              <span className='text-muted d-block fs-7'>
                                {p.minAmount > 999 && kFormatterRupee(Number(p.minAmount.toString().split('.')[0]))}
                              </span>
                            </td>
                            <td className='text-center'>
                              <span key={i} className={`fw-semibold badge ${getStatusColor(p.status)}`}>
                                {p.status}
                              </span>
                            </td>
                            <td className='text-center'>
                              <span key={i} className={`fw-semibold badge ${getTypeColor(p.type)}`}>
                                {p.type}
                              </span>
                            </td>
                            <td className='text-center'>
                              {jwt.id === p.userId ? (
                                <div className='d-flex justify-content-center'>
                                  <button
                                    className={`btn btn-sm btn-icon btn-bg-light btn-color-primary mx-1 rotate ${
                                      p.status === 'Draft' ? '' : 'active'
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      setPortfolio(_.cloneDeep(p))
                                      togglePublishModal()
                                    }}
                                    title={p.status === 'Draft' ? 'Publish' : 'Unpublish'}
                                  >
                                    <KTSVG path='/media/icons/duotune/general/gen016.svg' className='svg-icon-3 rotate-180' />
                                  </button>
                                  <button
                                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-info mx-1'
                                    onClick={() => {
                                      setPortfolio(_.cloneDeep(p))
                                      toggleViewModal()
                                    }}
                                    title='View'
                                  >
                                    <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3' />
                                  </button>
                                  <button
                                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-warning mx-1'
                                    onClick={() => {
                                      navigate('/exitEnabler/editPortfolio', { state: { props: { portfolio: p } } })
                                    }}
                                    title='Edit'
                                  >
                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                                  </button>
                                  <button
                                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-danger mx-1'
                                    onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      setPortfolio(_.cloneDeep(p))
                                      toggleRemoveModal()
                                    }}
                                    title='Remove'
                                  >
                                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                  </button>
                                </div>
                              ) : (
                                <div className='d-flex justify-content-center'>Investor: {p.userName || 'NA'}</div>
                              )}
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <ViewEEPortfolio showViewModal={showViewModal} toggleViewModal={toggleViewModal} portfolio={portfolio} />
      <PublishEEPortfolio showPublishModal={showPublishModal} togglePublishModal={togglePublishModal} portfolio={portfolio} />
      <RemoveEEPortfolio showRemoveModal={showRemoveModal} toggleRemoveModal={toggleRemoveModal} portfolio={portfolio} />
    </>
  )
}

const math = /^\s*[a-z_\.]*[a-z](\s*[-+*%\/]\s*[a-z_\.]*[a-z])*\s*$/
const inBrace = /[(]([^()]*)[)]/
export function validateFormula(expr: string) {
  while (expr.includes(')') || expr.includes('(')) {
    const m = expr.match(inBrace)
    if (m) {
      if (!math.test(m[1])) {
        return false
      }
      const ind = m.index || 0
      expr = expr.substring(0, ind) + ' abc ' + expr.substring(ind + m[0].length)
    } else {
      return false
    }
  }
  return math.test(expr)
}

export const getStatusColor = (s: string) => {
  let color = 'badge-light-secondary'
  switch (s) {
    case 'Draft':
      color = 'badge-light-warning'
      break
    case 'Published':
      color = 'badge-light-success'
      break
    default:
      color = 'badge-light-secondary'
      break
  }
  return color
}

export const getTypeColor = (s: string) => {
  let color = 'badge-light-secondary'
  switch (s) {
    case 'Sell':
      color = 'badge-light-danger'
      break
    case 'Buy':
      color = 'badge-light-success'
      break
    default:
      color = 'badge-light-secondary'
      break
  }
  return color
}

export const randomWeights = (x: number): number[] => {
  let sum = 100
  let weights = []
  for (let i = 0; i < x - 1; i++) {
    const rn = randomWeight(60 / x, 130 / x)
    sum -= rn
    weights.push(rn)
  }
  weights.push(sum)
  if (sum < 1) weights = randomWeights(x)
  return weights
}

function randomWeight(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export { EEPortfolios }
