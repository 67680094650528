import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../../../../_metronic/helpers'

const useLoader = () => {
  const [loadingText, setLoadingText] = useState('Scanning Message...')
  const [percent, setPercent] = useState(5)
  const [isLoaderVisible, setIsLoaderVisible] = useState(false)

  const showLoader = () => {
    setIsLoaderVisible(true)
    setLoadingText('Scanning Message...')
    setPercent(5)

    const timer1 = setTimeout(() => {
      setLoadingText('Looking for Key Points...')
      setPercent(40)
    }, 1000) // After 1 second, change text to "Looking for Key Points..."

    const timer2 = setTimeout(() => {
      setLoadingText('Analyzing Data...')
      setPercent(60)
    }, 2000) // After another 1 second, change text to "Analyzing Data..."

    const timer3 = setTimeout(() => {
      setLoadingText('Generating Fields!')
      setPercent(80)
    }, 3000) // After another 1 second, change text to "Generating Fields!"

    // Cleanup function to clear timers
    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
      clearTimeout(timer3)
    }
  }

  return { loadingText, isLoaderVisible, showLoader, percent }
}

type Props = {
  isLoading: boolean
}

const LoaderComponent = ({ isLoading }: Props) => {
  const { loadingText, showLoader, percent } = useLoader()
  const [progress, setProgress] = useState(percent)
  const [message, setMessage] = useState(loadingText)

  useEffect(() => {
    let cleanup: () => void
    if (isLoading) {
      cleanup = showLoader()
    }
    return () => {
      if (cleanup) cleanup()
    }
  }, [isLoading])

  useEffect(() => {
    setMessage(loadingText)
    setProgress(percent)
  }, [loadingText, percent])

  if (!isLoading) {
    return null
  }

  return (
    <>
      <div className='card h-50px w-100 bg-termsheet-bg mt-4'>
        <div className='d-flex flex-row'>
          <span className='m-2 mx-4 d-flex align-items-centers'>
            <div className='symbol symbol-40px symbol-circle bg-secondary'>
              <KTSVG
                path='/media/icons/duotune/general/hissa-ai-user-logo.svg'
                className='svg-icon-3x'
              />
            </div>
          </span>
          <span className='d-flex align-items-center'>{message}</span>
        </div>
      </div>
      <div className='loader'>
        <div className='dropzone-progress'>
          <div className='progress mh-3px'>
            <div
              className={`${progress === 100 ? 'bg-success' : 'bg-primary'}`}
              role='progressbar'
              aria-valuemin={0}
              aria-valuemax={100}
              aria-valuenow={progress}
              data-dz-uploadprogress
              style={{ width: progress + '%' }}
            ></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoaderComponent
