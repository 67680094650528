/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { ResetPassword } from './components/ResetPassword'
import LoginPageContent from './components/LoginPageContent'
import { GeneratePassword } from './components/GeneratePassword'

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])
  return (
    <>
      <div className='d-flex h-100 row g-0'>
        <div className='col-lg-5 order-2 order-lg-1 p-5'>
          <Outlet />
        </div>
        <div className={'col-lg-7 order-1 order-lg-2 gradientHissa p-5'}>
          <LoginPageContent />
        </div>
      </div>
    </>
  )
}
const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      {/* <Route path='registration' element={<Registration />} /> */}
      <Route path='forgotPassword' element={<ForgotPassword />} />
      <Route path='resetPassword' element={<ResetPassword />} />
      <Route path='generatePassword' element={<GeneratePassword />} />
      <Route path='*' element={<Navigate to='/error/404' />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)
export { AuthPage }
