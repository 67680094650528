import { useState } from 'react'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useForgotPassword } from '../core/_requests'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Please enter valid email'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(undefined)
  const [error, setError] = useState(undefined)
  const forgot = useForgotPassword()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true)
      setError(undefined)
      setSuccess(undefined)
      forgot.mutate(
        {
          ...values,
          senderOrigin: 'invPortal',
        },
        {
          onSuccess: (result) => {
            setSuccess(result.data.message)
            setLoading(false)
          },
          onError: (errors: any) => {
            setError(
              errors.response.status === 404 || errors.response.status === 400
                ? errors.response.data.errorMessage
                : 'Server Unreachable Try After Sometime'
            )
            setLoading(false)
            setSubmitting(false)
          },
        }
      )
    },
  })

  return (
    <div className='d-flex flex-column justify-content-around align-items-center h-100'>
      <div className='pb-20 p-lg-0'>
        <img
          src={toAbsoluteUrl('/media/logos/default_dark.svg')}
          alt='Hissa_Logo'
          className='img-fluid h-60px'
        />
      </div>
      <div>
        <form
          className='form fv-plugins-bootstrap5 fv-plugins-framework w-300px w-md-400px w-lg-350px w-xl-400px p-5'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center'>
            <h1 className='fw-bold display-6'>Forgot Password ?</h1>
            <h6 className='text-muted my-5'>
              Enter the email you used when you joined and we'll send you a password reset link.
            </h6>
          </div>
          {success && (
            <div className='fv-plugins-message-container'>
              <h6 className='text-primary fw-bold mb-5' role='alert'>
                {success}
              </h6>
            </div>
          )}
          <div>
            <label className='form-label fw-bold'>Email</label>
            <input
              type='email'
              placeholder='Email Address'
              className='form-control custom-input fw-bold shadow-lg h-38px'
              autoComplete='off'
              {...formik.getFieldProps('email')}
            />
          </div>
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className='svg-icon-primary svg-icon-1 me-2'
                  />
                  {formik.errors.email}
                </span>
              </div>
            </div>
          )}
          {error && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block ' role='alert'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon-primary svg-icon-1 me-2'
                />
                <span>{error}</span>
              </div>
            </div>
          )}
          <div className='d-flex justify-content-evenly my-10'>
            <button className='btn btn-lg btn-primary' type='submit'>
              {!loading && <span className='indicator-label fw-bold'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                className='btn btn-lg fw-bold btn-secondary'
                onClick={() => {
                  setError(undefined)
                  setSuccess(undefined)
                }}
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
      <div className='h-60'></div>
    </div>
  )
}
