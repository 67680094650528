import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useGetUrlForS3ObjectQuery, GetUrlForS3ObjectQuery } from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { kFormatterRupee } from '../../../../utils/CurrencyFormatter'
import { isoToDate } from '../../../../utils/FormatDate'
import { hexToRGB } from '../../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { KTSVG } from '../../../../_metronic/helpers'
import { PDFModalViewer } from '../../company/components/TabViews/PDFModalViewer'
type card = {
  title: string
  value: any
}
export default function TermsheetView() {
  const location = useLocation()
  let data: any = location.state
  const [state, setState] = useState(false)
  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        key: data.props.fileURL,
        bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET,
        isUpload: false,
      },
    },
    {
      enabled: state,
    }
  )
  const additionalInvestors = () => {
    return (
      <tr className='fw-bold text-gray-600 '>
        <th className='fs-5 min-w-125px'>Investor Name</th>
        <th className='fs-5 min-w-125px'>Investment Amount</th>
      </tr>
    )
  }

  function DeCamalize(element: string) {
    let data = element.replace(/([A-Z])/g, ' $1')
    data = data.replace(/^./, function (str) {
      return str.toUpperCase()
    })
    return data
  }

  const sectionData: any = {}

  const sections = Object.keys(data.props.sections)
  const currency = data.props.sections.roundDetails?.currency?.split('-')[1] || 'IN'

  sections.forEach((section: string) => {
    const variablesList: any = []
    Object.keys(data.props.sections[section]).forEach((key: string, index: number) => {
      if (index === 0) {
        variablesList.push({
          title: 'Title',
          value: DeCamalize(section),
        })
      }
      variablesList.push({
        title: DeCamalize(key),
        value:
          typeof data.props.sections[section][key] === 'number' &&
          data.props.sections[section][key] > 100 ? (
            <>
              {currency === 'US' ? (
                <span style={{ fontFamily: 'arial' }}>$ </span>
              ) : (
                <span style={{ fontFamily: 'arial' }}>₹ </span>
              )}
              {kFormatterRupee(data.props.sections[section][key])}
            </>
          ) : typeof data.props.sections[section][key] === 'object' ? (
            data.props.sections[section][key] ? (
              <>{data.props.sections[section][key].join(' , ')}</>
            ) : (
              'none'
            )
          ) : typeof data.props.sections[section][key] === 'boolean' ? (
            data.props.sections[section][key] ? (
              'Yes'
            ) : (
              'No'
            )
          ) : (
            data.props.sections[section][key] || '-'
          ),
      })
    })
    sectionData[section] = variablesList
  })
  const mainSections = Object.keys(sectionData).map(function (key) {
    return ([key] = sectionData[key])
  })

  const mandatoryCards: any[] = mainSections.map((section: any, index: number) => (
    <div className='card shadow-sm p-5 pb-0 mb-5' key={index}>
      {section.map((item: card, index: number) => {
        if(item.value !== "-"){
        if (item.title === 'Title') {
          return (
            <h3 className='card-title' key={index}>
              {item.value}
            </h3>
          )
        }
        return (
          <div className='card-body row py-0' key={index}>
            <p className='col-md-3 col-sm-12'>{item.title}</p>
            <p className='col-md-9 col-sm-12'>{item.value}</p>
          </div>
        )
      }})}
    </div>
  ))

  return (
    <div className='card'>
      <div className='card-title fw-bold p-5 m-0 d-flex justify-content-between'>
        <h1 className='mb-0'>
          {data.props.termsheetName !== 'Unnamed' ? data.props.termsheetName : 'Term Sheet'}
          <span
            className={`badge fs-5 fw-semibold ms-5`}
            style={
              data.props.status === 'Active'
                ? {
                    backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.2),
                    color: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 1),
                  }
                : {
                    backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 0.2),
                    color: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 1),
                  }
            }
          >
            {data.props.status}
          </span>
        </h1>
        <button className='btn btn-sm btn-primary' type='button' onClick={() => setState(true)}>
          <KTSVG path='/media/svg/files/pdf.svg' className='svg-icon-muted svg-icon-2x' />
          <span className='fs-base fw-bold'>View PDF</span>
        </button>
      </div>
      <div className='card-body p-5'>
        {mandatoryCards}
        {/* {additionalCards} */}
        <Link to='/deals'>
          <button className='btn btn-secondary'>Back</button>
        </Link>
      </div>
      {state && (
        <PDFModalViewer
          url={query.data?.urlForS3Object || ''}
          documentName={`PDF View : ${data.props.termsheetName}`}
          handleClose={() => setState(false)}
          show={state}
        />
      )}
    </div>
  )
}
