import { useContext } from 'react'
import { AllTransactionsType, CompanyTypes, Transaction_Type } from '../../../../generated/graphql'
import { formatNumber, getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import { GainBadge, LossBadge } from '../../../../utils/GainOrLossBadges'
import { toFix } from '../../../../utils/ToFix'
import { GlobalContext } from '../../store/context/globalContext'
import { Link } from 'react-router-dom'
import { useCompanyNameStore } from '../../../../store/companyNameStore'

type Props = {
  allTransactions: AllTransactionsType[]
}

export function InvestmentGrowthCard(props: Props) {
  const companyNameStore = useCompanyNameStore()
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  const companyWiseData = getTopCompaniesData(props.allTransactions)
  const tableHeader = () => {
    return (
      <tr className='fw-bold text-gray-600'>
        <th className='min-w-125px'></th>
        <th className='min-w-50px text-center'>Investment</th>
        <th className='min-w-50px text-center'>MOIC</th>
      </tr>
    )
  }

  const investmentTableData = companyWiseData.map(
    (company: CompanyWiseGrowthType, index: number) => {
      return (
        <tr key={index}>
          <td className='min-w-100px mw-100px'>
            <Link
              className='fs-base fw-bold mb-0 text-gray-800 text-hover-primary '
              to={`/company/summary/${company.type}/${company.hissaCompanyId || company.id}`}
              onClick={() => {
                companyNameStore.setCompanyName(company.name || '')
              }}
            >
              <p
                className='text-truncate mb-0'
                data-toggle='tooltip'
                data-placement='top'
                title={company?.name?.toUpperCase()}
                style={{ maxWidth: '120px' }}
              >
                {company.name}
              </p>
            </Link>
          </td>
          <td className='min-w-100px'>
            <p
              data-toggle='tooltip'
              data-placement='top'
              title={Number(company.investedAmount).toLocaleString(userCurrencyFormat)}
              className='fs-base text-end fw-bold mb-0'
            >
              {getSymbolOfCurrency(userCurrency)}{' '}
              {formatNumber({
                value: company.investedAmount,
                format: userCurrencyFormat,
              })}
            </p>
          </td>
          <td className='min-w-100px'>
            <p className='fs-base text-end mb-0'>
              {company.moic >= 1 ? (
                <GainBadge badgeValue={company.moic} indicator='X' />
              ) : (
                <LossBadge badgeValue={company.moic} indicator='X' />
              )}
            </p>
          </td>
        </tr>
      )
    }
  )
  return (
    <div
      className='card h-425px m-0 p-0'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <span className='px-9 pt-8'>
        <h2 className='card-label fw-bold mb-1'>Investment vs Growth</h2>
        <span className='text-muted fw-semibold fs-7'>Top 5 Companies</span>
      </span>

      <div className='card px-9 overflow-auto my-8'>
        <div
          className='table-responsive px-0'
          style={{ overflowY: 'auto', overflowX: 'auto', scrollbarWidth: 'none' }}
        >
          <table className='table align-middle gs-0 gy-3 table-row-dashed border-gray-200 align-items-baseline'>
            <thead>{tableHeader()}</thead>
            <tbody>{investmentTableData}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

type CompanyWiseGrowthType = {
  name: string
  id?: number
  type: CompanyTypes
  investedAmount: number
  realizedValue: number
  unrealizedValue: number
  moic: number
  hissaCompanyId?: string
}

function getTopCompaniesData(allTransactions: AllTransactionsType[]) {
  const companyWiseData: CompanyWiseGrowthType[] = []
  allTransactions.forEach((company) => {
    const compData: CompanyWiseGrowthType = {
      name: company.companyData.name,
      id: company.companyData.type !== CompanyTypes.Hissa ? company.companyData.id : null,
      hissaCompanyId:
        company.companyData.type === CompanyTypes.Hissa
          ? company.companyData.hissaCompanyId || ''
          : undefined,
      type: company.companyData.type,
      investedAmount: 0,
      realizedValue: 0,
      unrealizedValue: 0,
      moic: 1,
    }
    let invAmount = 0
    let unRealizedValue = 0
    let realizedValue = 0
    const transactions = company.transactionData
    if (transactions && transactions.length > 0) {
      transactions.forEach((transaction) => {
        if (
          transaction?.transactionType === Transaction_Type.Buy ||
          transaction?.transactionType === Transaction_Type.SecondaryBuy
        ) {
          invAmount += transaction.amount
        } else if (transaction?.transactionType === Transaction_Type.Sell) {
          realizedValue += Math.abs(transaction.amount)
        }
      })
    }
    company.investorData?.forEach((investor) => {
      unRealizedValue += investor?.currentValue || 0
    })
    if (invAmount !== 0) {
      compData.moic = toFix((realizedValue + unRealizedValue) / invAmount)
      compData.investedAmount = invAmount
      compData.realizedValue = realizedValue
      compData.unrealizedValue = unRealizedValue
    }
    companyWiseData.push(compData)
  })
  return companyWiseData.length < 5
    ? companyWiseData.sort((a, b) => b.moic - a.moic)
    : companyWiseData.sort((a, b) => b.moic - a.moic).slice(0, 5)
}
