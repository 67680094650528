import { useLocation, useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers'
import { TableHeader } from './TableHeader'
import { DynamicDataAGTable } from './DynamicDataAGTable'
import {
  DynamicTableDataInputType,
  DynamicTableDataType,
  DynamicTableDataInput,
} from '../wrappers/DynamicTablesPageWrapper'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'

type DynamicTableDataProps = {
  data?: DynamicTableDataInputType
  existingData?: DynamicTableDataType
  isExisting: boolean
  handleBack: Function
  handleSubmit: Function
}

export function IndividualTablePage(props: DynamicTableDataProps) {
  const location = useLocation()
  let stateData: any = location.state || [props.data]
  const data = stateData?.length > 0 ? stateData?.length > 0 && stateData[0]?.data : []
  const [discard, setDiscard] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [chartData, setChartData] = useState<any[]>(
    props.isExisting ? props.existingData && props.existingData.chartData : undefined
  )
  const tableData = { data: data, indexData: stateData?.length > 0 && stateData[0] }
  const name = props.isExisting
    ? props.existingData?.name
    : (stateData?.length > 0 && stateData[0].indexData?.title.at(-1)) || ''
  const description = props.isExisting
    ? props.existingData?.description
    : (stateData?.length > 0 && stateData[0].indexData?.description.at(-1)) || ''
  function saveData() {
    const finalData: DynamicTableDataInput = {
      name: name,
      description: description,
      tableData: props.isExisting ? props.existingData?.tableData : tableData,
      chartData,
      file: props.isExisting ? undefined : props.data?.file!,
      isExisting: props.isExisting,
      id: props.isExisting ? props.existingData?.id : undefined,
    }
    props.handleSubmit(finalData)
  }

  return (
    <>
      <div className='col d-flex flex-row justify-content-between pb-4'>
        <button
          type='button'
          onClick={() => {
            isUpdated === false ? props.handleBack() : setDiscard(true)
          }}
          className='px-4 rounded border border-1 border-hover-primary border-secondary-txt bg-transparent h-38px fw-normal text-hover-primary'
        >
          <KTSVG
            path='/media/icons/duotune/arrows/arr021.svg'
            className='svg-icon-3 text-secondary-txt'
          />{' '}
          Back
        </button>
        <button
          type='button'
          onClick={() => {
            saveData()
            setIsUpdated(false)
          }}
          className='px-4 fs-7 rounded border border-0 border-primary bg-primary h-38px fw-normal ms-4 text-white'
        >
          <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-1 text-white' />{' '}
          Save
        </button>
      </div>

      {/* Table Header */}
      <div className='row mb-9'>
        <div className='col-12'>
          <TableHeader reportName={name} description={description} id='1' />
        </div>
      </div>
      {/* AG Table */}
      <div className='row mb-9'>
        <div className='col-12'>
          <DynamicDataAGTable
            data={props.isExisting ? props.existingData?.tableData.data : data}
            savedCharts={props.isExisting ? props.existingData?.chartData : []}
            name={name}
            tableData={
              props.isExisting
                ? props.existingData?.tableData.indexData
                : stateData?.length > 0 && stateData[0]
            }
            handleChartData={(values: any) => {
              setChartData(values)
            }}
            handleUpdate={(values: boolean) => {
              setIsUpdated(values)
            }}
          />
        </div>
      </div>
      {discard && isUpdated && (
        <Modal
          id='kt_modal_add_inv'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-500px '
          show={discard}
          onHide={() => setDiscard(false)}
        >
          <Modal.Body className='p-9'>
            <div className='card'>
              <div className='card-body p-0'>
                <div className='fs-5 pb-2 fw-bold'>
                  Are you sure you want to navigate back to the main table page?
                </div>
                <div className='fs-7 fw-normal pb-2'>
                  <b>Note : </b> Clicking back will remove this data. Please save your work before
                  proceeding.
                </div>

                <div className='d-flex col justify-content-between pt-6'>
                  <button
                    className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
                    onClick={() => setDiscard(false)}
                  >
                    <span className='indicator-label'>Cancel</span>
                  </button>
                  <button
                    type='submit'
                    className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
                    onClick={() => props.handleBack()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
