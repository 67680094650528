export function getNameForKey(key: string, json: string) {
  let name = ''
  const jsonData = JSON.parse(json)

  jsonData.forEach((item: any) => {
    if (item.groups) {
      item.groups.forEach((group: any) => {
        group.fields.forEach((field: any) => {
          if (field.key === key) {
            name = field.name
          }
        })
      })
    }
  })

  return name
}
