import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useEffect, useRef, useState } from 'react'

export function NameRender(data: any) {
  return (
    <>
      <div className='pt-3'>
        <div onClick={() => data.data.navigate(data.data.selectedData)}>
          <p className='fw-bold text-hover-primary'>{data.data.name}</p>
        </div>
      </div>
    </>
  )
}

export function UploadFileRender(data: any) {
  function handleClick(item: any) {
    window.open(item.file)
  }
  const fileName = data.data.fileName.split('/')
  return (
    <div className=''>
      <div
        className='d-flex align-items-center'
        onClick={() => {
          data.data.file !== '' && handleClick(data.data)
        }}
      >
        <div className='symbol symbol-35px me-5' style={{ cursor: 'pointer' }}>
          {data.data.file !== '' && (
            <img src={toAbsoluteUrl('/media/svg/files/excel.svg')} alt='' />
          )}
        </div>
        <span className='text-wrap fs-base cursor-pointer'>
          <p
            className='text-truncate mb-0'
            data-toggle='tooltip'
            data-placement='top'
            title={fileName[fileName.length - 1]}
            style={{ maxWidth: '190px' }}
          >
            {fileName[fileName.length - 1]}
          </p>
        </span>
      </div>
    </div>
  )
}

export function FileSizeRender(data: any) {
  return (
    <p className='d-block fs-base pt-2'>
      {data.data.size
        ? data.data > 1000000
          ? (data.data / 1048576).toFixed(2) + ' MB'
          : (data.data / 1024).toFixed(2) + ' KB'
        : '-'}
    </p>
  )
}

export function DeleteActionRender(data: any) {
  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)
  return (
    <div className='px-0'>
      <button
        ref={deleteBtnRef}
        className='btn align-center btn-icon btn-sm btn-secondary'
        onClick={(e) => {
          data.data.handleRemove(e, data.data.id)
        }}
      >
        <KTSVG
          path='media/icons/duotune/general/gen027.svg'
          className='svg-icon-2x   text-primary'
        />
      </button>
    </div>
  )
}
