import { differenceInDays } from 'date-fns'
import { KTSVG } from '../_metronic/helpers/components/KTSVG'
import { kFormatterRupee } from './CurrencyFormatter'

export function getInitials(name: string) {
  const n = name.trim()
  const i = n.indexOf(' ') > 0 ? n.charAt(0) + n.charAt(n.indexOf(' ') + 1) : n.charAt(0)
  return i.toUpperCase()
}

export type distinctiveSharesInput = { start: string; end: string; range: number }

export function setupInitials(allComp: any[]) {
  let initMap = new Map<string, any>()
  allComp.forEach((comp: any) => {
    if (!initMap.has(comp.name.trim())) {
      let count = 0
      const init = getInitials(comp.name)

      for (let [key, value] of Array.from(initMap.entries())) {
        if (value.initials === init && value.id !== comp.companyId) {
          count = initMap.get(key).count + 1
        }
      }
      initMap.set(comp.name, {
        initials: init,
        id: comp.companyId,
        count: count,
      })
    }
  })
  return initMap
}
export function calculateXirr(values: number[], dates: Date[], guess = 0) {
  // Calculates the resulting amount
  const irrResult = function (values: number[], dates: Date[], rate: number) {
    const r = rate + 1
    let result = values[0]
    values.forEach((value, i) => {
      if (i === 0) return
      result += value / Math.pow(r, differenceInDays(dates[i], dates[0]) / 365)
    })
    return result
  }

  // Calculates the first derivation
  const irrResultDeriv = function (values: number[], dates: Date[], rate: number) {
    const r = rate + 1
    let result = 0
    for (let i = 1; i < values.length; i++) {
      const frac = differenceInDays(dates[i], dates[0]) / 365
      result -= (frac * values[i]) / Math.pow(r, frac + 1)
    }
    return result
  }

  // Check that values contains at least one positive value and one negative value
  let positive = false
  let negative = false
  for (const element of values) {
    if (element > 0) positive = true
    if (element < 0) negative = true
  }

  // Return error if values does not contain at least one positive value and one negative value
  if (!positive || !negative) return 'NA'

  // Initialize guess and resultRate
  guess = typeof guess === 'undefined' ? 0.1 : guess
  let resultRate = guess

  // Set maximum epsilon for end of iteration
  let epsMax = 1e-10

  // Set maximum number of iterations
  let iterMax = 50

  // Implement Newton's method
  let newRate, epsRate, resultValue
  let iteration = 0
  let contLoop = true
  do {
    resultValue = irrResult(values, dates, resultRate)
    newRate = resultRate - resultValue / irrResultDeriv(values, dates, resultRate)
    epsRate = Math.abs(newRate - resultRate)
    resultRate = newRate
    contLoop = epsRate > epsMax && Math.abs(resultValue) > epsMax
  } while (contLoop && ++iteration < iterMax)

  if (contLoop) return 'NA'

  // Return internal rate of return
  return resultRate
}

export function getIndicatorSymbol(value: number, type: string): JSX.Element {
  if (isNaN(value)) {
    return <></>
  } else {
    if (type === 'irr') {
      if (value < 0) {
        return <KTSVG path='/media/icons/duotune/arrows/arr004.svg' className='svg-icon-1x text-danger' />
      } else if (value > 0) {
        return <KTSVG path='/media/icons/duotune/arrows/arr003.svg' className='svg-icon-1x text-success' />
      } else {
        return <KTSVG path='/media/icons/duotune/arrows/arr033.svg' className='svg-icon-1x' />
      }
    } else {
      if (value < 1) {
        return <KTSVG path='/media/icons/duotune/arrows/arr004.svg' className='svg-icon-1x text-danger' />
      } else if (value > 1) {
        return <KTSVG path='/media/icons/duotune/arrows/arr003.svg' className='svg-icon-1x text-success' />
      } else {
        return <KTSVG path='/media/icons/duotune/arrows/arr033.svg' className='svg-icon-1x' />
      }
    }
  }
}

export function isDateInCurrentQuarter(date: Date) {
  const today = new Date()
  const currentMonth = today.getMonth()
  const currentQuarterStartMonth = Math.floor(currentMonth / 3) * 3

  const quarterStartDate = new Date(today.getFullYear(), currentQuarterStartMonth, 1)
  const nextQuarterStartDate = new Date(today.getFullYear(), currentMonth + 1, 1)

  return date >= quarterStartDate && date < nextQuarterStartDate
}

export function isDateInCurrentFinancialYear(date: Date) {
  const currentYear = new Date().getFullYear()

  // Adjusting for a financial year that starts in April
  const financialYearStartMonth = 3 // April is 3 since months are zero-indexed
  const financialYearEndMonth = 2 // March is 2

  const financialYearStartDate = new Date(currentYear, financialYearStartMonth, 1)
  const financialYearEndDate = new Date(currentYear + 1, financialYearEndMonth + 1, 0)

  return date >= financialYearStartDate && date <= financialYearEndDate
}

export function getCurrentFinancialYear() {
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // Month is zero-based
  const currentYear = today.getFullYear();

  // Assuming the financial year starts on April 1st
  if (currentMonth >= 4) {
      // If the current month is April or later, consider the current year as the financial year
      return (currentYear + 1);
  } else {
      // If the current month is before April, consider the previous year as the start of the financial year
      return currentYear;
  }
}
export const str2num = (v: string) =>
  Number(v) > 999 && v.indexOf('.') > 0 ? `${kFormatterRupee(Number(v.split('.')[0]))}.${v.split('.')[1]}` : kFormatterRupee(Number(v.split('.')[0]))
