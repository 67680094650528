import { useState } from 'react'
import { Card, Stack } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import AddFundStructureModal from '../FundStructure/AddFundStructureModal'

type EmptyWatchListItemProps = {
  investorId: number
}

function EmptyMetricsItem(props: EmptyWatchListItemProps) {
  const handleClose = () => {
    setShowModal(!showModal)
  }
  const [showModal, setShowModal] = useState(false)

  return (
    <div className='row justify-content-center  text-center'>
      <Card className=''>
        <Stack className='text-center pt-4'>
          <h1>No Fund Structure Found</h1>
          <img
            alt='no items'
            src={toAbsoluteUrl('/media/icons/duotune/general/no-items.svg')}
            className='h-100px my-10'
          />
          <div className='col justify-content-between pb-6'>
            <button
              className='btn btn-primary mt-4 mx-8'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
              onClick={() => {
                setShowModal(!showModal)
              }}
            >
              Add Fund Structure +
            </button>
            {
              <AddFundStructureModal
                fundStructureId={1}
                investorId={props.investorId}
                handleClose={handleClose}
                showModal={showModal}
                isEdit={false}
              />
            }
          </div>
        </Stack>
      </Card>
    </div>
  )
}

export default EmptyMetricsItem
