import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import { InvestorListType } from '../../../company/views/HissaCompanySummaryPage'
import { AddDirectTransactionForm } from './AddDirectTransactionForm'
import { CompanyObject, TransactionObject } from '../../../../../generated/graphql'
import { getUserInvestors } from '../../../../../utils/LocalStorageUtils'

type Props = {
  showModal: boolean
  handleClose: () => void
  isCompanyExist: boolean
  companyData?: CompanyObject
  handleSubmit: () => void
  isEdit: boolean
  transactionData?: TransactionObject
}

const AddDirectTransactionModal = ({
  showModal,
  handleClose,
  isCompanyExist,
  companyData,
  handleSubmit,
  isEdit,
  transactionData,
}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const userInvestors = getUserInvestors('userInvestors')
  const investorList: InvestorListType[] = []
  userInvestors.forEach((item) => {
    investorList.push({
      investorName: item.investorName,
      investorId: item.investorId.toString(),
    })
  })

  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px '
      show={showModal}
      onHide={handleClose}
    >
      <div className='col d-flex justify-content-between px-9 pt-4'>
        <div className='pt-10'>
          <h2 className='fw-bolder text-dark'>{isEdit ? 'Edit Transaction' : 'Add Transaction'}</h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-6'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>

      <Modal.Title>
        <div className='card card-p-0 '>
          <div className='card-body'>
            <div
              ref={stepperRef}
              className='stepper stepper-links d-flex flex-column'
              id='kt_modal_add_inv_stepper'
            >
              <div className='d-flex row m-9'>
                <AddDirectTransactionForm
                  investorList={investorList}
                  handleClose={handleClose}
                  isCompanyExist={isCompanyExist}
                  companyData={companyData}
                  handleSubmit={handleSubmit}
                  isEdit={isEdit}
                  transactionData={transactionData}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Title>
    </Modal>
  )
}

export { AddDirectTransactionModal }
