export const toFix = (num: any) => {
  try {
    return num < 0
      ? '-' + Number(num.toFixed(2).match(/\d+(?:\.\d+)?/)[0]).toFixed(2)
      : Number(num.toFixed(2).match(/\d+(?:\.\d+)?/)[0]).toFixed(2)
  }
  catch (err) {
    return num.toFixed(2)
  }
}
