import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import {
  AddAccountAccessMutation,
  AddAccountAccessMutationVariables,
  useAddAccountAccessMutation,
  useCheckIsRequestSentQuery,
} from '../../../../../generated/graphql'
import Toast, { ToastType } from '../../../../../utils/toast'

type Props = {
  showModal: boolean
  handleClose: () => void
}

export const ContactModal = ({ showModal, handleClose }: Props) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<string>('')

  const mutateAddAccessRequest = useAddAccountAccessMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddAccountAccessMutation,
        _variables: AddAccountAccessMutationVariables,
        _context: unknown
      ) => {
        setLoading(false)
        const getCompanyWiseQuery = useCheckIsRequestSentQuery.getKey()
        queryClient.invalidateQueries(getCompanyWiseQuery)
        Toast('Request Sent Successfully!', ToastType.success)
        handleClose()
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
        handleClose()
      },
    },
    {}
  )

  function HandleSubmit() {
    setLoading(true)
    mutateAddAccessRequest.mutate({
      input: {
        message: message,
      },
    })
  }
  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px '
      show={showModal}
      onHide={handleClose}
    >
      <Modal.Body className='shadow border border-primary rounded p-9'>
        <div className='card p-0'>
          <div className='card-body p-0'>
            <h2 className='fw-bolder text-gray-heading'>Contact Us</h2>

            <div className='fs-7 fw-normal pb-6'>
              Please write us a message, our team will contact you
            </div>
            <div className='form-group'>
              <textarea
                className='form-control'
                rows={5}
                placeholder='Enter Your Message here'
                onChange={(e) => {
                  setMessage(e.target.value)
                }}
              />
            </div>

            <div className='d-flex col justify-content-between pt-10'>
              <button className='btn btn-lg btn-secondary' onClick={handleClose}>
                <span className='indicator-label'>Cancel</span>
              </button>
              <button
                type='submit'
                className='btn btn-lg btn-primary'
                disabled={loading || message === ''}
                onClick={() => {
                  HandleSubmit()
                }}
              >
                {!loading && <span className='indicator-label'>Confirm</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
