import { useNavigate, useParams } from 'react-router-dom'
import {
  AddRecentCompaniesMutation,
  AddRecentCompaniesMutationVariables,
  AllTransactionsType,
  CompanyDataInput,
  CompanyTypes,
  GetCompanyWiseDataQuery,
  useAddRecentCompaniesMutation,
  useGetCompanyWiseDataQuery,
  useGetRecentCompaniesQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import HissaCompanySummaryPage from '../views/HissaCompanySummaryPage'
import Toast, { ToastType } from '../../../../utils/toast'
import CompanyErrorPage from '../components/CompanyErrorPage'
import { HissaCompanyCurrentCaptablePage } from '../views/HissaCompanyCurrentCaptablePage'

function CompanyProfileWrapper() {
  const { companyType, id } = useParams()
  const navigate = useNavigate()

  const compType = companyType as CompanyTypes
  let hissaCompanyId = ''
  let companyId = 0
  compType === CompanyTypes.Hissa ? (hissaCompanyId = id || '') : (companyId = Number(id) || 0)

  const input: CompanyDataInput = {
    type: compType,
    hissaCompanyId: hissaCompanyId,
    id: companyId,
  }

  const mutateAddRecentCompanies = useAddRecentCompaniesMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddRecentCompaniesMutation,
        _variables: AddRecentCompaniesMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetRecentCompaniesQuery.getKey())
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const { data, isLoading, isError } = useGetCompanyWiseDataQuery<GetCompanyWiseDataQuery, Error>(
    graphqlRequestClient,
    {
      input: input,
    },
    {
      enabled:
        (hissaCompanyId !== '' || companyId !== 0) &&
        Object.values(CompanyTypes).includes(compType),
      onSuccess: (response) => {
        mutateAddRecentCompanies.mutate({
          input: {
            name: response.getCompanyData?.companyData.name,
            type: input.type,
            hissaCompanyId: input.hissaCompanyId,
            id: input.id,
            logo: response.getCompanyData?.companyData.logo,
            dba: response.getCompanyData?.companyData.dba,
            visitedDateTime: new Date().toISOString(),
          },
        })
      },
    }
  )

  if (!companyType || !id) {
    navigate('/error/404')
  }

  if (isLoading) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  if (isError) {
    Toast('Company details not found!', ToastType.error)
    return <CompanyErrorPage />
  }

  if (compType === CompanyTypes.Hissa) {
    if (data?.getCompanyData?.investorData && data?.getCompanyData?.investorData.length > 0) {
      return (
        <HissaCompanySummaryPage
          companyId={hissaCompanyId}
          companyData={data?.getCompanyData as AllTransactionsType}
        />
      )
    } else {
      return (
        <HissaCompanyCurrentCaptablePage
          companyId={hissaCompanyId}
          data={data?.getCompanyData as AllTransactionsType}
        />
      )
    }
  } else {
    return (
      <HissaCompanySummaryPage
        companyId={companyId.toString()}
        companyData={data?.getCompanyData as AllTransactionsType}
      />
    )
  }
}

export default CompanyProfileWrapper
