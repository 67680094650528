import { Modal } from 'react-bootstrap'
import { CompanyObject } from '../../../generated/graphql'
import { KTSVG } from '../../../_metronic/helpers'
import { AddReportsForm, MisReportType } from './AddReportsForm'

type ReportProps = {
  closeModal: () => void
  showModal: boolean
  companyData: CompanyObject
  reportData?: MisReportType
  reportId?: number
  isEdit: boolean
}

function ReportsModal(props: ReportProps) {
  return (
    <Modal
      onHide={props.closeModal}
      id='kt_modal_add_report'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-1000px '
      show={props.showModal}
    >
      <div className='col d-flex justify-content-between p-9 pb-2'>
        <div className='pt-4'>
          <h2 className='fw-bolder text-dark'>{props.isEdit ? 'Edit Report' : 'Add MIS Report'}</h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={() => {
            props.closeModal()
          }}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>
      <div className='separator my-4'></div>

      <div className='d-flex row mb-9 mx-9'>
        <AddReportsForm
          closeModal={props.closeModal}
          companyData={props.companyData}
          reportData={props.reportData}
          isEdit={props.isEdit}
          reportId={props.reportId}
        />
      </div>
    </Modal>
  )
}

export default ReportsModal
