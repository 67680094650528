import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { ThemeModeProvider } from '../partials'
import { MenuComponent } from '../assets/ts/components'
import { EEMenu } from '../../app/pages/exitEnabler/EEMenu'
import CompanyProfileNavigationMenu from '../../app/pages/company/wrappers/CompanyProfileNavigationMenu'
import DealsNavigationMenu from '../../app/pages/deals/wrappers/DealsNavigationMenu'
import HissaAiFloatingButton from '../../app/pages/deals/components/template-builder/HissaAi/HissaAiFloatingButton'

const MasterLayout = () => {
  const location = useLocation()
  const data: any = location.state

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='page d-flex flex-row flex-column-fluid h-100'>
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />
            {location && location.pathname && location.pathname.startsWith('/deals') && (
              <DealsNavigationMenu />
            )}
            {location && location.pathname && location.pathname.startsWith('/company') && (
              <CompanyProfileNavigationMenu />
            )}
            {location && location.pathname && location.pathname.startsWith('/exitEnabler') && (
              <EEMenu />
            )}

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid pt-5 pb-0'>
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>
        {/* {location && location.pathname && location.pathname.endsWith('sendtermsheet') && (
          <HissaAiFloatingButton />
        )}
        {location && location.pathname && !location.pathname.endsWith('sendtermsheet') && ( */}
        <ScrollTop />
        {/* )} */}
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export { MasterLayout }
