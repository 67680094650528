import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import { InvestorListType } from '../../../company/views/HissaCompanySummaryPage'
import { CompanyObject, TransactionObject } from '../../../../../generated/graphql'
import { getUserInvestors } from '../../../../../utils/LocalStorageUtils'
import { AddRoundTransactionForm } from './AddRoundTransactionForm'
import { SelectOptionType } from '../../../../../utils/MultiSelect'
import {
  AddTransactionRoundDetails,
  InvestmentRoundDetails,
} from '../../../company/components/InvestmentModelling/InvestmentModellingHelper'

type Props = {
  investments: AddTransactionRoundDetails[]
  showModal: boolean
  handleClose: () => void
  roundDetails: InvestmentRoundDetails
  setInvestmentData: Function
  removeInvestmentData: Function
  investmentData?: AddTransactionRoundDetails
  currentRoundSize: number
  shareHolderNameList: SelectOptionType[]
  sharePrice: number
  isEdit: boolean
  currentPercentage: number
}

const AddRoundTransactionModal = ({
  investments,
  showModal,
  roundDetails,
  handleClose,
  setInvestmentData,
  removeInvestmentData,
  investmentData,
  currentRoundSize,
  shareHolderNameList,
  sharePrice,
  isEdit,
  currentPercentage,
}: Props) => {
  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px '
      show={showModal}
      onHide={handleClose}
    >
      <div className='col d-flex justify-content-between px-9 pt-4'>
        <div className='pt-6'>
          <h2 className='fw-bolder text-dark'>{isEdit ? 'Edit Transaction' : 'Add Investment'}</h2>
        </div>

        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-4'
          type='button'
          title='Close'
          onClick={handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>
      <div className='separator my-2'></div>

      <Modal.Title>
        <div
          className='card p-0 h-auto'
          style={{
            boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
            border: '1',
            borderColor: '#F1F1F2',
          }}
        >
          <div className='card-body p-9'>
            <AddRoundTransactionForm
              handleClose={handleClose}
              setInvestmentData={setInvestmentData}
              investmentData={investmentData}
              currentRoundSize={currentRoundSize}
              shareHolderNameList={shareHolderNameList}
              sharePrice={sharePrice}
              isEdit={isEdit}
              removeInvestmentData={removeInvestmentData}
              currentPercentage={currentPercentage}
              investments={investments}
              roundDetails={roundDetails}
            />
          </div>
        </div>
      </Modal.Title>
    </Modal>
  )
}

export { AddRoundTransactionModal }
