import { FilterType, MetricType } from '../generated/graphql'

export const metricTagsLV: any[] = [
  { label: 'Advertising', value: 'Advertising' },
  { label: 'Enterprise', value: 'Enterprise' },
  { label: 'Subscription', value: 'Subscription' },
  { label: 'SaaS', value: 'SaaS' },
  { label: 'Marketplace', value: 'Marketplace' },
  { label: 'eCommerce', value: 'eCommerce' },
  { label: 'Usage-Based', value: 'Usage-Based' },
  { label: 'Hardware', value: 'Hardware' },
  { label: 'General', value: 'General' },
  { label: 'Transactional / Payment', value: 'Transactional / Payment' },
]

export const metricTypeLV: any[] = [
  { label: 'Number', value: MetricType.Number },
  { label: 'Percent', value: MetricType.Percent },
  { label: 'Amount', value: MetricType.Amount },
]

export const filterTypeLV: any[] = [
  { label: 'Number', value: FilterType.Number },
  { label: 'Percent', value: FilterType.Percent },
  { label: 'List', value: FilterType.List },
  { label: 'Nested List', value: FilterType.Nestedlist },
  { label: 'In Rupees', value: FilterType.Inrupees },
  { label: 'In Crores', value: FilterType.Incrores },
]

export const weightageLV: any[] = [
  { label: 'Equal Distribution', value: 'equal' },
  { label: 'Random Distribution', value: 'random' },
]
