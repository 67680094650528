import { MenuItem } from '../../../../_metronic/layout/components/header/MenuItem'
import { CompanyTypes } from '../../../../generated/graphql'

type Props = {
  companyId: string
  companyType: CompanyTypes
}
function ModellingMenu({ companyId, companyType }: Props) {
  const props: Props = {
    companyId: companyId,
    companyType: companyType,
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-title-gray-700 menu-state-title-primary w-150px p-4'
      data-kt-menu='true'
    >
      <MenuItem
        to={`/company/exitmodelling/${companyType}/${companyId}`}
        title='Exit Modelling'
        props={props}
      />
      <MenuItem
        to={`/company/investmentmodelling/${companyType}/${companyId}`}
        title='Investment Modelling'
        props={props}
      />
    </div>
  )
}

export default ModellingMenu
