import { AllTransactionsType, CompanyTypes } from '../../../../generated/graphql'
import { FilterLabel, FilterTagValue } from './FilterMenu'

export type PortfolioCompany = {
  hissaCompanyId: string
  companyType: CompanyTypes
  id: number
}

export function companyWiseData(
  allTransactions: AllTransactionsType[],
  investorIds: number[],
  companies: PortfolioCompany[]
) {
  let filteredCompanies = allTransactions
  if (companies.length > 0) {
    const hissaFilterCompanies = companies
      .filter((company) => company.companyType === CompanyTypes.Hissa)
      .map((c) => c.hissaCompanyId)
    const allHissaCompanies = allTransactions.filter(
      (company) => company.companyData.type === CompanyTypes.Hissa
    )
    const filteredHissaCompanies = allHissaCompanies.filter(
      (company) =>
        company.companyData.hissaCompanyId &&
        hissaFilterCompanies.includes(company.companyData.hissaCompanyId)
    )

    const nonHissaFilterCompanies = companies
      .filter((company) => company.companyType !== CompanyTypes.Hissa)
      .map((c) => c.id)
    const allNonHissaCompanies = allHissaCompanies.filter(
      (company) => company.companyData.type !== CompanyTypes.Hissa
    )
    const filteredNonHissaCompanies = allNonHissaCompanies.filter((company) =>
      nonHissaFilterCompanies.includes(company.companyData.id)
    )

    filteredCompanies = filteredNonHissaCompanies
    filteredCompanies.push(...filteredHissaCompanies)
  }
  if (investorIds.length > 0) {
    filteredCompanies = filteredCompanies.map((company) => {
      company.transactionData = company.transactionData?.filter((transaction) =>
        investorIds.includes(transaction?.investorId)
      )
      company.investorData = company.investorData?.filter((inv) => investorIds.includes(inv?.id))
      return company
    })
  }
  return filteredCompanies
}

export function filterData(allData: AllTransactionsType[], filterTagValues: FilterTagValue[]) {
  const dataFilteredBySector = filterBySector(allData, filterTagValues)
  const dataFilteredByFunds = filterByFunds(dataFilteredBySector, filterTagValues)
  const dataFilteredByCustomTags = filterByCustomTags(dataFilteredByFunds, filterTagValues)
  return dataFilteredByCustomTags
}

function filterBySector(allTransactions: AllTransactionsType[], filterTagValues: FilterTagValue[]) {
  filterTagValues = filterTagValues.filter((tag) => tag.group === FilterLabel.industry)
  let filteredData: AllTransactionsType[] = []
  if (filterTagValues.length > 0) {
    filteredData = allTransactions.filter((comp) =>
      filterTagValues.some((tag) => comp.companyData.industry?.toUpperCase() === tag.label)
    )
    if (filterTagValues.filter((tag) => tag.label === 'OTHERS').length > 0) {
      allTransactions.forEach((comp) => {
        if (
          comp.companyData.industry === null ||
          comp.companyData.industry === undefined ||
          comp.companyData.industry === ''
        ) {
          filteredData.push(comp)
        }
      })
    }
    return filteredData
  }
  return allTransactions
}

function filterByFunds(allTransactions: AllTransactionsType[], filterTagValues: FilterTagValue[]) {
  filterTagValues = filterTagValues.filter((tag) => tag.group === FilterLabel.fundVehicle)
  const filteredData: AllTransactionsType[] = []
  if (filterTagValues.length > 0) {
    allTransactions.forEach((comp) => {
      comp.investorData = comp.investorData?.filter((inv) =>
        filterTagValues.some((tag) => inv?.name === tag.label)
      )
      comp.transactionData = comp.transactionData?.filter((tx) =>
        filterTagValues.some((tag) => tx?.investorName === tag.label)
      )
      comp.indirectTransactionData = comp.indirectTransactionData?.filter((tx) =>
        filterTagValues.some((tag) => tx?.investorName === tag.label)
      )
      const newComp: AllTransactionsType = {
        companyData: comp.companyData,
        investorData: comp.investorData,
        transactionData: comp.transactionData,
        roundData: comp.roundData,
      }
      if (
        (newComp.investorData && newComp.investorData?.length > 0) ||
        (newComp.transactionData && newComp.transactionData?.length > 0)
      ) {
        filteredData.push(newComp)
      }
    })
    return filteredData
  }
  return allTransactions
}

function filterByCustomTags(
  allTransactions: AllTransactionsType[],
  filterTagValues: FilterTagValue[]
) {
  filterTagValues = filterTagValues.filter((tag) => tag.group === FilterLabel.tag)
  const filteredData: AllTransactionsType[] = []
  if (filterTagValues.length > 0) {
    if (filterTagValues.find((tag) => tag.label === 'All')) {
      return allTransactions
    }
    filterTagValues.forEach((tag) => {
      if (tag.label === 'Hissa') {
        filteredData.push(
          ...allTransactions.filter((comp) => comp.companyData.type === CompanyTypes.Hissa)
        )
      } else if (tag.label === 'Manual') {
        filteredData.push(
          ...allTransactions.filter((comp) => comp.companyData.type === CompanyTypes.Direct)
        )
      } else if (tag.label === 'Indirect') {
        filteredData.push(
          ...allTransactions.filter(
            (comp) => comp.indirectTransactionData && comp.indirectTransactionData.length > 0
          )
        )
      } else if (tag.label === 'Exited') {
        allTransactions.forEach((company) => {
          company.investorData = company.investorData?.filter((inv) => inv?.exited)
          company.transactionData = company.transactionData?.filter((tx) =>
            company.investorData?.map((inv) => inv?.name).includes(tx?.investorName!)
          )
          const newComp: AllTransactionsType = {
            companyData: company.companyData,
            investorData: company.investorData,
            transactionData: company.transactionData,
            indirectTransactionData: company.indirectTransactionData,
            roundData: company.roundData,
            valuationDetailsData: company.valuationDetailsData,
          }
          if (
            (newComp.investorData && newComp.investorData?.length > 0) ||
            (newComp.transactionData && newComp.transactionData?.length > 0)
          ) {
            filteredData.push(newComp)
          }
        })
      } else {
        allTransactions.forEach((comp) => {
          const tags = comp.companyData.tags || []
          if (tags.includes(tag.label)) {
            if (!filteredData.includes(comp)) filteredData.push(comp)
          }
        })
      }
    })
    return filteredData
  }
  return allTransactions
}
