import { useEffect } from 'react'
import { useIsMutating } from 'react-query'
import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'
import Select from 'react-select'
import * as Yup from 'yup'
import { selectCustomStyles } from '../../../utils/Select'
import { graphqlRequestClient, queryClient } from '../../../queries/client'
import { EditEeFilterMutation, FilterType, useEditEeFilterMutation, useGetEeFiltersQuery } from '../../../generated/graphql'
import Toast, { ToastType } from '../../../utils/toast'
import { filterTypeLV } from '../../../types/exitEnabler'
import { validateFormula } from './EEFilters'

type Props = {
  showEditModal: boolean
  toggleEditModal: () => void
  filter: any
}

function EditEEFilter({ showEditModal, toggleEditModal, filter }: Props) {
  const editMutate = useEditEeFilterMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (data: EditEeFilterMutation) => {
        queryClient.invalidateQueries(useGetEeFiltersQuery.getKey())
        Toast(data.editEEFilter.message, ToastType.success)
        toggleEditModal()
      },
      onError: (error: any) => Toast(error.response.errors[0].message, ToastType.error),
    },
    {}
  )

  const editForm = useFormik({
    initialValues: {
      id: -1,
      name: '',
      group: '',
      type: '',
      formula: '',
    },
    validationSchema: Yup.object().shape(
      {
        name: Yup.string().trim().required('Enter filter name').min(3, 'Name must be at least 3 characters'),
        group: Yup.string().trim().required('Enter filter group').min(3, 'Group must be at least 3 characters'),
        type: Yup.string().required('Select filter type'),
        formula: Yup.string()
          .required('Enter formula (bodmas rules apply)')
          .when(['formula'], {
            is: (f: string) => f && !validateFormula(f),
            then: Yup.string().min(100, 'Invalid formula, must follow bodmas rules'),
          }),
      },
      [['formula', 'formula']]
    ),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: function (values) {
      const editData = {
        id: filter.id,
        name: values.name.trim(),
        group: values.group.trim(),
        type: values.type as FilterType,
        formula: values.formula,
      }

      editMutate.mutate({ input: editData })
    },
  })

  useEffect(() => {
    if (filter) {
      editForm.setFieldValue('name', filter.name)
      editForm.setFieldValue('group', filter.group)
      editForm.setFieldValue('type', filter.type)
      editForm.setFieldValue('formula', filter.formula)
    } else {
      editForm.setFieldValue('name', '')
      editForm.setFieldValue('group', '')
      editForm.setFieldValue('type', null)
      editForm.setFieldValue('formula', '')
    }
    editForm.validateForm()
  }, [filter])

  return (
    <Modal id='kt_modal_edit_filter' aria-hidden='true' show={showEditModal} onHide={toggleEditModal}>
      <form onSubmit={editForm.handleSubmit}>
        <Modal.Header className='p-2'>
          <Modal.Title className='ps-5'>Edit Filter</Modal.Title>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={toggleEditModal}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='mh-400px scroll-y me-n7 pe-7'>
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>Name</label>
              <input type='text' className='form-control' placeholder='Enter name' {...editForm.getFieldProps('name')} />
              <div className='text-danger mt-2'>
                {editForm.touched.name && editForm.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{editForm.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>Group</label>
              <input type='text' className='form-control' placeholder='Enter group' {...editForm.getFieldProps('group')} />
              <div className='text-danger mt-2'>
                {editForm.touched.group && editForm.errors.group && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{editForm.errors.group}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className='form-label required'>Formula</label>
              <input type='text' className='form-control' placeholder='Enter formula' {...editForm.getFieldProps('formula')} />
              <div className='text-danger mt-2'>
                {editForm.touched.formula && editForm.errors.formula && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{editForm.errors.formula}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className='form-label required'>Type</label>
              <Select
                isSearchable={false}
                className='text-primary'
                options={filterTypeLV}
                name='type'
                defaultValue={filter && filterTypeLV.find((f: any) => f.value === filter.type)}
                onChange={(e: any) => editForm.setFieldValue('type', e.value)}
                styles={selectCustomStyles}
              />
              <div className='text-danger mt-2'>
                {editForm.touched.type && editForm.errors.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{editForm.errors.type}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <div className='d-flex justify-content-between'>
            <button type='button' className='btn btn-sm btn-secondary me-3' onClick={toggleEditModal}>
              Cancel
            </button>
            <button type='submit' className='btn btn-sm btn-primary' disabled={useIsMutating() > 0}>
              {useIsMutating() === 0 && 'Save'}
              {useIsMutating() > 0 && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default EditEEFilter
