import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import Dropzone, {
  IInputProps,
  ILayoutProps,
  IPreviewProps,
  IUploadParams,
} from 'react-dropzone-uploader'
import Toast, { ToastType } from '../../../../utils/toast'
import { getAuth } from '../../../modules/auth'
import {
  CompanyObject,
  StageCompanyType,
  UploadPrivateCaptableMutation,
  UploadPrivateCaptableMutationVariables,
  useGetAllPrivateCaptablesQuery,
  useGetAllTransactionsQuery,
  useUploadPrivateCaptableMutation,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { useState } from 'react'

type FileUploadProps = {
  showModal: boolean
  handleClose: () => void
  isStageThree: boolean
  handleSubmit: () => void
  companyData: CompanyObject
  companyType: StageCompanyType
}

export function CaptableFileUploadPopup(props: FileUploadProps) {
  const auth = getAuth()!
  const [errors, setErrors] = useState<String[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [fileMeta, setFileMeta] = useState<any>(null)

  const mutateCreatePrivateCaptable = useUploadPrivateCaptableMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: UploadPrivateCaptableMutation,
        _variables: UploadPrivateCaptableMutationVariables,
        _context: unknown
      ) => {
        setLoading(false)
        props.handleClose()
        props.handleSubmit()
        const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()
        queryClient.invalidateQueries(getAllTransactionQuery)
        queryClient.invalidateQueries(useGetAllPrivateCaptablesQuery.getKey())
        Toast('Documents Uploaded Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        setLoading(false)
        const validationErrorList =
          (error.response.errors[0].message && error.response.errors[0].message.split('\n')) || []
        setErrors(validationErrorList)
        if (validationErrorList.length > 0) {
          Toast('Error detected in the sheet. Please review and upload again', ToastType.error)
        } else Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const GetUploadParams = async ({ meta, file }: any) => {
    setFileMeta(meta)
    if (file.size > 10000000) {
      Toast('Documents size must be less than 10 MB', ToastType.error)
      const ret: IUploadParams = {
        url: '',
        body: '',
        method: 'put',
      }
      return ret
    } else {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', file)

      const result = await mutateCreatePrivateCaptable.mutateAsync({
        input: {
          companyType: props.companyType,
          file: file,
        },
      })

      const ret: IUploadParams = {
        url: '',
        body: formData,
        method: 'put',
      }
      return ret
    }
  }

  const handleChangeStatus = ({ meta, file }: any, status: any, xhr: any) => {
    const allowedExtensions = ['xlsx', 'xlsm', 'xls']
    const extension = file.name.split('.').pop().toLowerCase()

    if (status === 'rejected_file_type' || !allowedExtensions.includes(extension)) {
      Toast(
        `File type .${extension} is not allowed. Please upload a .xlsx or .xlsm file.`,
        ToastType.error
      )
    }

    if (meta.status === 'done') {
      Toast('Documents Uploaded Successfully!', ToastType.success)
    }
  }

  const Preview = ({ meta, fileWithMeta }: IPreviewProps) => {
    const { name, percent, status, size, validationError } = meta
    return (
      <div className='dropzone dropzone-queue p-9 pt-0'>
        <div className='dropzone-items'>
          <div className='dropzone-item p-4 dz-image-preview'>
            <div className='dropzone-file'>
              <div className='dropzone-filename' title={name}>
                <span className='fs-base fw-bold'>{name}</span>{' '}
                <strong>
                  (
                  <span>
                    {size > 1000000
                      ? (size / 1048576).toFixed(2) + ' MB'
                      : (size / 1024).toFixed(2) + ' KB'}
                  </span>
                  )
                </strong>
              </div>
              {/* <div className={`${percent === 100 ? 'text-success' : 'dropzone-error'}`}>
                {status}
              </div> */}
            </div>
            <div className='dropzone-progress'>
              <div className='progress'>
                <div
                  className={`${status === 'done' ? 'bg-success' : 'bg-primary'}`}
                  role='progressbar'
                  aria-valuemin={0}
                  aria-valuemax={100}
                  aria-valuenow={percent}
                  data-dz-uploadprogress
                  style={{ width: percent + '%' }}
                ></div>
              </div>
            </div>
            <div className='dropzone-toolbar'>
              {status != 'done' ? (
                <div
                  className='dropzone-delete btn btn-sm btn-icon btn-light-primary'
                  data-dz-remove
                  onClick={() => {
                    if (!loading) {
                      fileWithMeta.remove()
                      setErrors([])
                    }
                  }}
                >
                  {!loading && (
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                  )}
                  {loading && <div className='spinner-border-sm spinner-border' />}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  const Input = ({ accept, onFiles, files, getFilesFromEvent }: IInputProps) => {
    const text = files.length > 0 ? 'Add more files' : 'Choose files'

    const fileList = files.map((file) => (
      <h6 key={file.file.name}>
        {file.file.name} -{' '}
        {file.file.size > 1000000
          ? (file.file.size / 1000000).toFixed(2) + ' MB'
          : (file.file.size / 1000).toFixed(2) + ' KB'}
      </h6>
    ))

    return (
      <div className=''>
        <label
          className='btn btn-outline btn-outline-dashed btn-outline-default bg-opacity-10 py-12 d-flex justify-content-center m-9 mb-5'
          // onClick={() => {
          //   setFileMeta(null)
          //   setErrors([])
          // }}
        >
          <input
            style={{ display: 'none' }}
            type='file'
            disabled={loading}
            accept={accept}
            onChange={async (e) => {
              let file = await getFilesFromEvent(e)
              onFiles(file)
            }}
            multiple={true}
          />

          <div className='text-center'>
            <KTSVG
              path='/media/icons/duotune/general/gen041.svg'
              className={`svg-icon-3x me-5 text-gray-600 text-hover-primary`}
            />
            <h2 className='text-gray-600 my-2'>Drag & drop or click to choose file</h2>

            <h3 className='fw-bold text-gray-700'>
              {' '}
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className={`svg-icon-2x text-gray-700`}
              />
              Max file size: 10 MB
            </h3>
          </div>
        </label>
      </div>
    )
  }

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => {
    return (
      <div {...dropzoneProps} className=''>
        {input}

        {previews}
      </div>
    )
  }

  return (
    <Modal
      id='kt_modal_upload_captable'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered mw-750px px-6`}
      show={props.showModal}
      onHide={() => {
        props.handleClose()
      }}
    >
      <div className='col d-flex justify-content-between px-9 '>
        <div className='py-9'>
          <h2 className='card-header fw-bolder text-dark'>
            {props.isStageThree ? 'Upload Current Captable' : 'Upload Full Captable'}
          </h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-6'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={props.handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>

      <Modal.Body className='px-9 py-0'>
        <div className='card card-p-0 p-0 h-650px'>
          <div className='card pb-4'>
            <div
              className='card rounder shadow-sm mb-4'
              style={{
                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                border: '1',
                borderColor: '#F1F1F2',
              }}
            >
              <Dropzone
                getUploadParams={GetUploadParams}
                onChangeStatus={handleChangeStatus}
                multiple={false}
                accept={'.xlsx, .xlsm, .xls'}
                PreviewComponent={() => null}
                // PreviewComponent={fileMeta ? () => <Preview {...fileMeta} /> : () => null}
                maxSizeBytes={100000000}
                InputComponent={Input}
                autoUpload={true}
                LayoutComponent={Layout}
              />
              {fileMeta && (
                <div className='card border border-gray-300 shadow-sm rounded mx-9 p-4 mb-6 d-flex align-bottom'>
                  <div className='d-flex justify-content-between'>
                    <p
                      className='fw-bolder mb-0 text-truncate'
                      data-toggle='tooltip'
                      title={fileMeta?.name}
                      style={{ maxWidth: '450px' }}
                    >
                      {fileMeta?.name}
                    </p>

                    <div
                      className='cursor-pointer'
                      onClick={() => {
                        setFileMeta(null)
                        setErrors([])
                      }}
                    >
                      <KTSVG
                        className='svg-icon-1 text-primary'
                        path='/media/icons/duotune/arrows/arr061.svg'
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {loading && (
            <>
              <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
                <h3 className='text-primary px-4 pt-2 '>Processing your file...</h3>
                <div className='spinner-grow'></div>
              </div>
            </>
          )}
          {errors.length > 0 && !loading && (
            <div className='card border border-gray-300 rounded mb-4 p-5 h-250px d-flex align-bottom'>
              <div className='d-flex justify-content-between pb-2'>
                <p className='fw-bolder mb-1'>Excel Sheet Validation Errors</p>

                <div className='cursor-pointer' onClick={() => setErrors([])}>
                  <KTSVG
                    className='svg-icon-1 text-primary'
                    path='/media/icons/duotune/arrows/arr061.svg'
                  />
                </div>
              </div>
              <div className='separator my-1'></div>
              <div className='overflow-auto'>
                {errors.map((error, index) => (
                  <p>{error}</p>
                ))}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
