/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AllTransactionsType } from '../../../../../generated/graphql'
import { ChartData, getInvExitsChartData, getQuarters } from './utils'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../utils/Select'
import { Bar } from 'react-chartjs-2'
import { GlobalContext } from '../../../store/context/globalContext'
import {
  formatNumber,
  getCurrency,
  getSymbolOfCurrency,
} from '../../../../../utils/CurrencyFormatter'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { KTSVG } from '../../../../../_metronic/helpers'
ChartJS.register(ArcElement, Tooltip, Legend)

type Props = {
  className: string
  allTransactions: AllTransactionsType[]
  counter: number
}

type FilterType = {
  label: string
  value: number
}

const ColumnChart: React.FC<Props> = ({ className, allTransactions, counter }) => {
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  const chartRef = useRef<ChartJS<'bar', number[], string>>(null)
  const [barCount, setBarCount] = useState(0)

  const [selectedCategory, setSelectedCategory] = useState<FilterType>({
    label: 'Investment',
    value: 1,
  })

  const [quarters, setQuarters] = useState(getQuarters(allTransactions))

  const { fundSeriesData, industrySeriesData, investmentSeriesData } = useMemo(() => {
    return getInvExitsChartData(allTransactions, quarters)
  }, [quarters])

  useEffect(() => {
    setQuarters(getQuarters(allTransactions))
  }, [counter])

  useEffect(() => {
    calculateBarCounts(investmentSeriesData)
  }, [investmentSeriesData])

  const handleDropdownSelection = (e: any) => {
    setSelectedCategory(e)
  }

  const filtersList = ['Investment', 'Industries', 'Fund Vehicle']

  const categoryOptions: FilterType[] = filtersList.map((filter: string, index: number) => {
    return {
      value: index + 1,
      label: filter,
    }
  })

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx: any) =>
            isNaN(ctx?.raw)
              ? ''
              : ctx.dataset.label +
                ': ' +
                getSymbolOfCurrency(userCurrency) +
                ' ' +
                formatNumber({
                  value: ctx.raw,
                  format: userCurrencyFormat,
                }),
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Total Invested over companies',
      },
    },
    borderRadius: 5,
    responsive: false,
    borderSkipped: 'bottom',
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    barThickness: 20,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          callback: function (value: string | number) {
            return (
              getSymbolOfCurrency(userCurrency) +
              ' ' +
              formatNumber({
                value: Number(value),
                format: userCurrencyFormat,
              })
              // Intl.NumberFormat(userCurrencyFormat).format(Number(value))
            )
          },
        },
      },
    },
  }

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const canvasWidth = chartRef.current.ctx?.canvas.width || 0
      const container = document.getElementById('chart-container')
      if (container)
        container.scrollTo({
          left: canvasWidth,
          behavior: 'smooth',
        })
    }
  }, [chartRef])

  const generateChart = (options: any, category: FilterType) => {
    let chartData: ChartData[] = []

    if (selectedCategory.label === 'Investment') {
      chartData = investmentSeriesData
    } else if (selectedCategory.label === 'Industries') {
      chartData = industrySeriesData
    } else {
      chartData = fundSeriesData
    }
    const count = Math.max.apply(
      Math,
      chartData.map((el) => {
        return el.data.length
      })
    )
    const plugins = [
      {
        id: '0',
        beforeDraw(chart: ChartJS) {
          if (count < 6) chart.resize()
        },
      },
      {
        id: '1',
        afterDatasetUpdate(chart: ChartJS) {
          if (chart.tooltip && !chart.tooltip.getActiveElements().length) {
            const lastLabelIndex = chart.data.labels && chart.data.labels.length - 1
            if (!lastLabelIndex) return

            let lastIndex = -1

            for (let i = chart.data.datasets.length - 1; i >= 0; i--) {
              if (
                chart.data.datasets[i].data[lastLabelIndex] !== undefined &&
                chart.data.datasets[i].data[lastLabelIndex] !== 0
              ) {
                lastIndex = i
                break
              }
            }
            if (lastIndex === -1) return

            const meta = chart.getDatasetMeta(lastIndex)
            const x = meta.data[lastLabelIndex].x
            const y = meta.data[lastLabelIndex].y
            chart.tooltip.setActiveElements(
              [
                {
                  datasetIndex: lastIndex,
                  index: lastLabelIndex,
                },
              ],
              { x, y }
            )
            chart.update()
          }
        },
      },
    ]

    return count > 6 ? (
      <Bar
        data={{ datasets: chartData, labels: quarters }}
        options={options}
        width={1400 + (count - 10) * 50}
        height={300}
        redraw={true}
        id='bar-chart'
        plugins={plugins}
        ref={chartRef}
      />
    ) : (
      <Bar
        data={{ datasets: chartData, labels: quarters }}
        options={options}
        width={800}
        height={300}
        redraw={true}
        id='bar-chart'
        plugins={plugins}
        ref={chartRef}
      />
    )
  }

  const calculateBarCounts = (chartData: ChartData[]) => {
    const count = Math.max.apply(
      Math,
      chartData.map((el) => {
        return el.data.length
      })
    )
    setBarCount(count)
  }

  const scrollRight = (value: boolean) => {
    const container = document.getElementById('chart-container')
    const canvasWidth = chartRef.current?.ctx?.canvas.width || 0
    if (container) {
      if (!value) {
        if (container.scrollLeft > 0) {
          container.scrollTo({
            left: container.scrollLeft - container?.offsetWidth,
            behavior: 'smooth',
          })
        }
      } else {
        if (container.scrollLeft < canvasWidth) {
          container.scrollTo({
            left: container.scrollLeft + container?.offsetWidth,
            behavior: 'smooth',
          })
        }
      }
    }
  }

  return (
    <div
      className={`card ${className}`}
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <span className='card-header p-0 border-0 px-9'>
        <h3 className='col-7 card-title align-items-start flex-column pt-6'>
          <span className='card-label fw-bold fs-3 mb-1'>Portfolio Tracking</span>

          <span className='text-muted fw-semibold fs-7'>{`By ${selectedCategory.label}`}</span>
        </h3>
        <div className='col-4 pt-9'>
          <Select
            className=''
            options={categoryOptions}
            onChange={(e: any) => handleDropdownSelection(e)}
            value={selectedCategory}
            styles={selectCustomStyles}
          />
        </div>
      </span>

      <div className={`card d-flex flex-row ${barCount > 6 ? '' : 'px-9'}`}>
        {barCount > 6 && (
          <div
            className='menu-icon d-flex align-items-center mx-2 pb-10'
            data-kt-element='icon'
            onClick={() => {
              scrollRight(false)
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr022.svg'
              className='svg-icon-1 align-middle text-primary'
            />
          </div>
        )}
        <div
          className='card-body overflow-auto px-0'
          style={{ overflowY: 'hidden', overflowX: 'auto', scrollbarWidth: 'none' }}
          id='chart-container'
        >
          {selectedCategory && generateChart(options, selectedCategory)}
        </div>
        {barCount > 6 && (
          <div
            className='menu-icon d-flex align-items-center mx-2 pb-10'
            data-kt-element='icon'
            onClick={() => {
              scrollRight(true)
            }}
          >
            <KTSVG
              path='/media/icons/duotune/arrows/arr023.svg'
              className='svg-icon-1 align-middle text-primary'
            />
          </div>
        )}
      </div>
    </div>
  )
}

export { ColumnChart }
