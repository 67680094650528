import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'

type AddTransactionPopupType = {
  showModal: boolean
  handleClose: () => void
  setTransactionType: Function
  isOpen: Function
  transactionType: string
}
export function AddTransactionPopup(props: AddTransactionPopupType) {
  return (
    <Modal
      id='kt_modal_add_transaction'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered mw-450px px-6`}
      show={props.showModal}
      onHide={() => {
        props.handleClose()
      }}
    >
      <div className='col d-flex justify-content-between px-9 '>
        <div className='py-9'>
          <h2 className='card-header fw-bolder text-dark'>{'Select a Preferred Option'}</h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-6'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={props.handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>

      <Modal.Body className='px-9 py-0'>
        <div className='card card-p-0 p-0'>
          <div className='card-body'>
            <div className='stepper stepper-links d-flex flex-column' id='kt_modal_add_inv_stepper'>
              <div className='w-100 '>
                <div className='fv-row'>
                  <div className='row'>
                    <div className='col-lg-12 '>
                      <input
                        type='radio'
                        className='btn-check'
                        name='transactionType'
                        value='direct'
                        id='kt_select_direct_transcation_method_direct'
                        onClick={() => {
                          props.setTransactionType('direct')
                          props.isOpen(false)
                        }}
                      />
                      <label
                        className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10
              ${props.transactionType === 'direct' && 'bg-primary bg-opacity-10'}`}
                        htmlFor='kt_select_direct_transcation_method_direct'
                      >
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs009.svg'
                          className={`svg-icon-3x me-5 ${
                            props.transactionType === 'direct' ? 'text-primary' : ''
                          }`}
                        />

                        <span className='d-block fw-bold text-start'>
                          <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                            Add Direct Transaction
                          </span>
                          <span className='text-gray-400 fw-bold fs-6'>Add Direct Transaction</span>
                        </span>
                      </label>
                    </div>

                    <div className='col-lg-12 '>
                      <input
                        type='radio'
                        className='btn-check'
                        name='transactionType'
                        value='indirect'
                        id='kt_select_indirect_transaction_method_indirect'
                        onClick={() => {
                          props.setTransactionType('indirect')
                          props.isOpen(false)
                        }}
                      />
                      <label
                        className={`btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center 
              ${props.transactionType === 'indirect' && 'bg-primary bg-opacity-10'}`}
                        htmlFor='kt_select_indirect_transaction_method_indirect'
                      >
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs022.svg'
                          className={`svg-icon-3x me-5 ${
                            props.transactionType === 'indirect' ? 'text-primary' : ''
                          }`}
                        />

                        <span className='d-block fw-bold text-start'>
                          <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                            Add Indirect Transaction
                          </span>
                          <span className='text-gray-400 fw-bold fs-6'>
                            Add Indirect Transaction
                          </span>
                        </span>
                      </label>
                    </div>

                    {/* <div className='fs-6 mt-2'>
            <ErrorMessage name='transactionType' />
          </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className='border border-0 p-9'>
        <div className='d-flex flex-stack'>
          <div className='mr-2'>
            <button
              onClick={() => props.isOpen(true)}
              type='button'
              className='btn btn-lg btn-primary'
              data-kt-stepper-action='previous'
            >
              Next
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
