import { Navigate, Routes, Route, Outlet, useLocation } from 'react-router-dom'
import { EEHome } from './EEHome'
import { EEModelExit } from './EEModelExit'
import { EEWatchlistExit } from './EEWatchlistExit'
import { CreateEEPortfolio } from './CreateEEPortfolio'
import { EditEEPortfolio } from './EditEEPortfolio'
import { EEPortfolios } from './EEPortfolios'

const EEWrapper = () => {
  const location = useLocation()
  const myState: any = location.state
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='/eePortfolios' element={<EEPortfolios />} />
      </Route>
      <Route element={<Outlet />}>
        <Route path='/modelMyExit' element={<EEModelExit />} />
      </Route>
      <Route element={<Outlet />}>
        <Route path='/modelWatchlist' element={<EEWatchlistExit />} />
      </Route>
      <Route element={<Outlet />}>
        <Route
          path='/createPortfolio'
          element={
            <CreateEEPortfolio
              companies={myState && myState.props && myState.props.companies}
              type={myState && myState.props && myState.props.type}
            />
          }
        />
      </Route>
      <Route element={<Outlet />}>
        <Route path='/editPortfolio' element={<EditEEPortfolio portfolio={myState && myState.props && myState.props.portfolio} />} />
      </Route>
      <Route element={<Outlet />}>
        <Route path='/admin' element={<EEHome />} />
      </Route>
      <Route index element={<Navigate to='/exitEnabler/eePortfolios' />} />
    </Routes>
  )
}

export default EEWrapper
