import { ChangeEvent, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import {
  AddTagsToInvestmentMutation,
  AddTagsToInvestmentMutationVariables,
  CompanyObject,
  TransactionObject,
  useAddTagsToTransactionMutation,
  useGetCompanyWiseDataQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { selectCustomStyles } from '../../../../utils/Select'

type Props = {
  companyDetails: CompanyObject
  transactionDetails: TransactionObject
  hissaCompanyId: string
  investorId?: bigint
  tags?: string[]
  showModal: boolean
  setShow: Function
  investmentId?: bigint
  hissaRecordId?: string
  indirectInvestmentId?: bigint
  transactionCurrency?: string
  transactionAmount?: number
  noOfUnits?: number
}
type SelectOptionType = { label?: string; value?: string }

export default function ManageInvestmentTags(props: Props) {
  const tagsObjList: { value?: string; label?: string }[] = []
  const tagsList = props.tags
  tagsList?.map((tag: string) => {
    tagsObjList.push({ value: tag, label: tag })
  })

  const [values, setValues] = useState<any>(tagsObjList)
  const [inputText, setInputText] = useState<string>('')
  const [customTag, setCustomTag] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)
  const [isEqual, setIsEqual] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [removeBadge, setRemoveBadge] = useState<string>('')

  useEffect(() => {
    const tagsObjList: { value?: string; label?: string }[] = []
    const tagsList = props.tags
    tagsList?.forEach((tag: string) => {
      tagsObjList.push({ value: tag, label: tag })
    })
    setValues(tagsObjList)
  }, [props.tags])

  const [badgeList, setBadgeList] = useState<SelectOptionType[]>([
    { value: 'Add New', label: 'Add New' },
    { value: 'Incubation', label: 'Incubation' },
    { value: 'First cheque', label: 'First cheque' },
  ])

  const handleChange = (e: any) => {
    setValues(e)

    e?.map((ele: any) => {
      if (ele.value === 'Add New') {
        setValues((values: any) => {
          return values?.filter((element: any) => element?.value !== 'Add New')
        })
        setCustomTag((customTag) => {
          return !customTag
        })
      }
    })
  }
  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
    if (e.target.value && e.target.value.length > 0) {
      values.map((badge: any) => {
        if (badge.value === e.target.value) {
          setDisable(true)
          setErrorMessage('Multiple tags with the same name are not allowed ')
          setIsEqual(true)
        } else {
          setDisable(false)
          setErrorMessage('')
          setIsEqual(false)
        }
      })
    }
  }
  const compKey = {
    input: {
      type: props.companyDetails.type,
      hissaCompanyId: props.hissaCompanyId || '',
      id: Number(props.companyDetails.id) || 0,
    },
  }
  const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)

  const mutateAddTagsToTransaction = useAddTagsToTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddTagsToInvestmentMutation,
        _variables: AddTagsToInvestmentMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(getCompanyWiseQuery)
        if (values.length !== 0) {
          Toast('Tags Created/Updated Successfully!', ToastType.success)
          props.setShow(false)
        } else {
          Toast('All Tags Removed Successfully!', ToastType.success)
          props.setShow(false)
        }
      },
      onError: (error: any) => {
        Toast('Error updating tags', ToastType.error)
      },
    },
    {}
  )

  useEffect(() => {
    setValues((values: any) => {
      return values?.filter((element: any) => element?.value !== removeBadge)
    })
  }, [removeBadge])

  function addTagsToInvestment() {
    const tags: string[] = []

    values.map((element: any) => {
      tags.push(element.value)
    })
    mutateAddTagsToTransaction.mutate({
      input: {
        companyType: props.companyDetails.type,
        hissaCompanyId: props.hissaCompanyId,
        companyId: props.companyDetails.id,
        investmentId: props.investmentId,
        hissaRecordId: props.hissaRecordId,
        indirectInvestmentId: props.indirectInvestmentId,
        companyName: props.companyDetails.name,
        tags: tags,
        investorId: props.investorId,
        currency: props.transactionDetails.currency || props.transactionCurrency || 'INR - ₹',
        exchangeRate: 1,
        investedAmount: props.transactionDetails.amount || props.transactionAmount || 0,
        investmentDate: props.transactionDetails.transactionDate?.split('T')[0],
        noOfShares: props.transactionDetails.noOfShares || props.noOfUnits || 0,
        sharePrice: props.companyDetails.sharePrice || 1,
        jurisdiction: props.companyDetails.jurisdiction || 'India',
        securitySubType: props.transactionDetails.securitySubType || 'Others',
      },
    })
  }

  return (
    <Modal
      id='kt_header_search_modal'
      aria-hidden='true'
      tabIndex={-1}
      dialogClassName='modal-dialog modal-dialog-centered h-auto mw-800px '
      show={props.showModal}
      centered
      animation={true}
      backdrop={true}
    >
      <Modal.Header className='p-9 pb-2'>
        <Modal.Title className='fs-1 text-gray-heading'>
          <div>Manage tags</div>
          <div className='fs-6'>Add tags to investment Record</div>
        </Modal.Title>

        <button
          className='btn btn-sm btn-icon btn-active-light-primary '
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={() => {
            props.setShow(false)
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </button>
      </Modal.Header>
      <Modal.Body className='p-9'>
        <div className='col fv-md-row d-md-flex align-items-baseline'>
          <div>
            <label className='d-flex align-items-center fs-5 fw-medium mb-4'>
              Select/Create Tags
            </label>
          </div>
          <div className='col-12 col-md-8 px-9'>
            {customTag === false ? (
              <Select
                className=''
                options={badgeList}
                placeholder={'--Select--'}
                value={values}
                isMulti={true}
                onChange={(e: any) => handleChange(e)}
                styles={selectCustomStyles}
              />
            ) : (
              <div className='d-flex'>
                <div className='me-4'>
                  <input
                    className='form-control form-control-solid me-2'
                    placeholder='Enter Tag Name'
                    onChange={handleChangeValue}
                  />
                </div>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                  type='submit'
                  disabled={disable}
                  onClick={(e) => {
                    setValues((values: any) => [...values, { value: inputText, label: inputText }])
                    setCustomTag(false)
                  }}
                >
                  <KTSVG
                    className='svg-icon-1 text-primary'
                    path='/media/icons/duotune/arrows/arr012.svg'
                  />
                </button>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                  onClick={() => {
                    setCustomTag(false)
                    setErrorMessage('')
                  }}
                >
                  <KTSVG
                    className='svg-icon-1 text-primary'
                    path='/media/icons/duotune/arrows/arr061.svg'
                  />
                </button>
              </div>
            )}
            <div className='text-danger mt-2'>
              {isEqual && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{errorMessage}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='p-9 border-0'>
        <div className='row justify-content-end'>
          <button
            type='submit'
            onClick={() => {
              addTagsToInvestment()
            }}
            className='btn btn-lg btn-primary w-100 mt-3'
          >
            {!mutateAddTagsToTransaction.isLoading && 'Save'}
            {mutateAddTagsToTransaction.isLoading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Saving...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
