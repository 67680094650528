import { currencyTypes } from './CurrencyList'

// Create our number formatter.
export var currencyFormatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

export function kFormatter(num: number) {
  if (Math.abs(num) > 9999999999) {
    return Math.sign(num) * Math.round(Math.abs(num) / 10000000) + ' Cr'
  } else if (Math.abs(num) > 9999999) {
    return (Math.sign(num) * Math.round(Math.abs(num) / 1000000)) / 10 + ' Cr'
  } else if (Math.abs(num) > 99999) {
    return (Math.sign(num) * Math.round(Math.abs(num) / 1000)) / 100 + ' L'
  } else if (Math.abs(num) > 999) {
    return (Math.sign(num) * Math.round(Math.abs(num) / 10)) / 100 + ' k'
  } else {
    return Number((Math.sign(num) * Math.abs(num)).toFixed(2)) + ''
  }
}

export function kFormatterNumber(num: number) {
  if (Math.abs(num) > 9999999) {
    var value = (Math.sign(num) * Math.round(Math.abs(num) / 100000)) / 100

    return `${value > 999 ? value.toFixed(0) : value} Cr`
  } else if (Math.abs(num) > 99999) {
    value = (Math.sign(num) * Math.round(Math.abs(num) / 1000)) / 100
    return Number(value.toFixed(2)) + ' L'
  }
  if (Math.abs(num) > 999) {
    value = (Math.sign(num) * Math.round(Math.abs(num) / 10)) / 100
    return Number(value.toFixed(2)) + ' k'
  } else {
    value = Math.sign(num) * Math.abs(num)
    return Number(value.toFixed(2)) + ''
  }
}

export function kFormatterRupee(num: number) {
  var rupee = num.toString()
  var lastThree = rupee.substring(rupee.length - 3)
  var otherNumber = rupee.substring(0, rupee.length - 3)
  if (otherNumber !== '') lastThree = ',' + lastThree
  rupee = otherNumber.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree
  return rupee
}
// export function kFormatterUSD(num: number): string {
// if (Math.abs(num) > 999999999) {
//   return `${Math.sign(num) * Math.round(Math.abs(num) / 1000000000)}B`
// } else if (Math.abs(num) > 999999) {
//   return `${Math.sign(num) * Math.round(Math.abs(num) / 1000000)}M`
// } else if (Math.abs(num) > 999) {
//   return `${(Math.sign(num) * Math.round(Math.abs(num) / 1000)) / 1000}K`
// } else {
//   return `${Number((Math.sign(num) * Math.abs(num)).toFixed(2))}`
// }
// }

export function kUnformatterNumber(str: string) {
  str = str.replaceAll(',', '')
  return parseInt(str)
}
export function kFormatterUSD(num: number, currency: string): string {
  if (currency === 'IN') {
    if (Math.abs(num) > 9999999999) {
      return Math.sign(num) * Math.round(Math.abs(num) / 10000000) + ' Cr'
    } else if (Math.abs(num) > 9999999) {
      return (Math.sign(num) * Math.round(Math.abs(num) / 1000000)) / 10 + ' Cr'
    } else if (Math.abs(num) > 99999) {
      return (Math.sign(num) * Math.round(Math.abs(num) / 1000)) / 100 + ' L'
    } else if (Math.abs(num) > 999) {
      return (Math.sign(num) * Math.round(Math.abs(num) / 10)) / 100 + ' k'
    } else {
      return Number((Math.sign(num) * Math.abs(num)).toFixed(2)) + ''
    }
  } else {
    if (Math.abs(num) > 999999999) {
      return `${Math.sign(num) * Math.round(Math.abs(num) / 1000000000)}B`
    } else if (Math.abs(num) > 999999) {
      return `${Math.sign(num) * Math.round(Math.abs(num) / 1000000)}M`
    } else if (Math.abs(num) > 999) {
      return `${(Math.sign(num) * Math.round(Math.abs(num) / 1000)) / 1000}K`
    } else {
      return `${Number((Math.sign(num) * Math.abs(num)).toFixed(2))}`
    }
  }
}

export function formatCurrency(num: number, format: string) {
  let returnFormat = 'INR'
  for (const [key, value] of Object.entries(currencyTypes)) {
    if (value.toString() === format) {
      returnFormat = key.toString()
    }
  }
  if (returnFormat === 'SGD') {
    return `${'S$'}${num?.toLocaleString(format)}`
  } else {
    return num?.toLocaleString(format, {
      style: 'currency',
      currency: returnFormat,
    })
  }
}

export function getKeyByValue(object: any, value: string) {
  return Object.keys(object).find((key) => object[key] === value)
}

export function getCurrency(roundCurrency: string) {
  const res = roundCurrency?.split('-')[0]?.trim() || 'INR'
  const key = res as keyof typeof currencyTypes
  const currencyType = key != null ? currencyTypes[key] : window.navigator.language
  return currencyType
}

export function getSymbolOfCurrency(roundCurrency: string) {
  const res = roundCurrency?.split('-')[1]?.trim() || '₹'
  return res
}

export const checkVoid = (data: any) => data === undefined || data === '' || data === null

export function formatNumber({ value, format }: { value?: number; format?: string }) {
  if (checkVoid(value)) return '-'
  const formatCurrency = format || 'en-IN'
  const num = value || 0
  const numValue = num.toLocaleString(formatCurrency, {
    maximumFractionDigits: 2,
  })
  if (num >= 10000000000 && formatCurrency === 'en-IN') {
    return `${(num / 10000000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })}K Cr`
  } else if (num >= 10000000 && formatCurrency === 'en-IN') {
    return `${(num / 10000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} Cr`
  } else if (num >= 100000 && formatCurrency === 'en-IN') {
    return `${(num / 100000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} L`
  } else if (num >= 1000000000 && formatCurrency !== 'en-IN') {
    return `${(num / 1000000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} B`
  } else if (num >= 1000000 && formatCurrency !== 'en-IN') {
    return `${(num / 1000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} M`
  } else if (num <= -10000000000 && formatCurrency === 'en-IN') {
    return `${(num / 10000000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })}K Cr`
  } else if (num <= -10000000 && formatCurrency === 'en-IN') {
    return `${(num / 10000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} Cr`
  } else if (num <= -100000 && formatCurrency === 'en-IN') {
    return `${(num / 100000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} L`
  } else if (num <= -1000000000 && formatCurrency !== 'en-IN') {
    return `${(num / 1000000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} B`
  } else if (num <= -1000000 && formatCurrency !== 'en-IN') {
    return `${(num / 1000000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} M`
  } else if (num >= 1000 && formatCurrency === 'en-IN') {
    return `${(num / 1000).toLocaleString(formatCurrency, {
      maximumFractionDigits: 2,
    })} K`
  } else {
    return numValue
  }
}
