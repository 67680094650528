import React, { useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { MainDynamicAGTable } from '../components/MainDynamicAgTable'
import { downloadS3File } from '../../../../utils/ExportExcel'
import Toast, { ToastType } from '../../../../utils/toast'
import * as XLSX from 'xlsx'
import { IndividualTablePage } from '../components/IndividualTablePage'

export type DynamicTableDataInputType = {
  data: String[][]
  file: File
  id: number
  indexData: any
  primaryColumns?: number[]
  secondaryColumns?: number[]
}

export type DynamicTableDataInput = {
  name: string
  description: string
  tableData: any
  chartData: any
  file: File | undefined
  isExisting: boolean
  id?: number
}

export type DynamicTableDataType = {
  id: number
  userId: number
  name: string
  description: string
  tableData: any
  chartData: any
  fileUrl: string
  fileName: string
}

type DynamicTableProps = {
  data?: DynamicTableDataType[]
  handleSave: Function
  handleRemove: Function
}

function DynamicTablePageWrapper(props: DynamicTableProps) {
  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const [excelFile, setExcelFile] = useState<File>()
  const [excelData, setExcelData] = useState<String[][]>([])
  const [dynamicTableData, setDynamicTableData] = useState<DynamicTableDataInputType[]>([])
  const [showTable, setShowTable] = useState<boolean>(true)
  const [showDetailedTable, setShowDetailedTable] = useState<boolean>(false)
  const [showExistingTable, setShowExistingTable] = useState<boolean>(false)
  const [existingData, setExistingData] = useState<DynamicTableDataType>()
  let primaryColumns: number[] = []
  let secondaryColumns: number[] = []

  const indexData: any = {
    title: [],
    description: [],
    headerRow: [],
    dataRow: [],
    primary: [],
    primaryLabel: [],
    secondary: [],
    secondaryLabel: [],
  }

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = (event: any) => {
    const file = event.target.files[0]
    if (file) {
      setExcelFile(file)
    }
  }

  useEffect(() => {
    handleChange()
  }, [excelFile])

  useEffect(() => {
    if (dynamicTableData.length > 0) {
      setShowDetailedTable(true)
      setShowTable(false)
    }
  }, [dynamicTableData])

  function handleSaveTable(values: DynamicTableDataType) {
    props.handleSave(values)
  }

  async function handleChange() {
    if (!excelFile) return
    const allowedFileExtensions = ['xlsx', 'xls', 'xlsm']
    if (excelFile !== undefined) {
      if (allowedFileExtensions.some((extension) => excelFile.name.endsWith(extension))) {
        const formData = new FormData()
        formData.append('excelFile', excelFile)
        await parseExcel()
      } else {
        Toast(
          ` Invalid file type, allowed file types are ${allowedFileExtensions.join(', ')}`,
          ToastType.error
        )
      }
    }
    // fileInputRef.current.value = ''
  }

  const extractSheetData = (workbook: XLSX.WorkBook, sheetNo: number) => {
    const sheet = workbook.Sheets[workbook.SheetNames[sheetNo - 1]]
    const sheetData: string[][] = XLSX.utils.sheet_to_json(sheet, {
      header: 1,
      blankrows: true,
      defval: '',
    })
    if (sheetData.length > 1) {
      sheetData[0].forEach((value: string, index) => {
        const lowerValue = value.toLowerCase()

        if (
          indexData.primary?.some((primaryKey: string) => primaryKey.toLowerCase() === lowerValue)
        ) {
          primaryColumns.push(index)
        }

        if (
          indexData.secondary?.some((keyValue: string) => keyValue.toLowerCase() === lowerValue)
        ) {
          secondaryColumns.push(index)
        }
      })
    }
    let filterData: string[][] = []
    sheetData.forEach((row, i) => {
      if (indexData.headerRow.length > 0 && i + 1 === indexData.headerRow[0]) {
        filterData.push(row)
      }
      if (indexData.dataRow.length > 0 && i + 1 >= indexData.dataRow[0]) {
        filterData.push(row)
      }
    })
    const filteredData = filterData.filter((row) => row.some((cell) => cell !== ''))
    filteredData.forEach((row, i) => {
      row.forEach((cell, j) => {
        filteredData[i][j] = filteredData[i][j].toString().trim()
      })
    })
    return filteredData
  }
  const getIndexData = (workbook: XLSX.WorkBook) => {
    if (workbook.SheetNames[0].toLowerCase().includes('index')) {
      const indexSheetWorkbook = workbook.Sheets['Index']

      let rows = XLSX.utils.sheet_to_json(indexSheetWorkbook, { header: 1 })
      const filteredData =
        (rows.filter((row: any) => row?.length !== 0) as string[][]) || ([] as string[][])
      filteredData.forEach((row: string[], index) => {
        const updatedRow = row.slice(1)
        indexData[Object.keys(indexData)[index]] = updatedRow
      })
    }
  }

  const parseAndSaveTable = async (workbook: XLSX.WorkBook) => {
    getIndexData(workbook)
    const excelData: string[][] = extractSheetData(workbook, 2)
    setExcelData(excelData)
    setDynamicTableData([
      // ...dynamicTableData,
      {
        data: excelData,
        file: excelFile!,
        id: dynamicTableData.length + 1,
        indexData: indexData,
        primaryColumns,
        secondaryColumns,
      },
    ])
    // setFinalTableData({
    //     tableData: excelData,
    //     name: indexData.name,
    //     chartData: [],
    //     fileUrl:'',
    //     description: indexData.description,
    //     id:0,
    //     userId: 0,

    // })
  }

  const parseExcel = async () => {
    const data = await excelFile?.arrayBuffer()
    const workbook = XLSX.read(data)
    await parseAndSaveTable(workbook)
  }
  return (
    <>
      {showDetailedTable && (
        <IndividualTablePage
          data={dynamicTableData[0]}
          isExisting={false}
          handleBack={() => {
            setShowTable(true)
            setShowDetailedTable(false)
          }}
          handleSubmit={(values: DynamicTableDataType) => handleSaveTable(values)}
        />
      )}
      {showExistingTable && (
        <IndividualTablePage
          existingData={existingData}
          isExisting={true}
          handleBack={() => {
            setShowTable(true)
            setShowExistingTable(false)
          }}
          handleSubmit={(values: DynamicTableDataType) => handleSaveTable(values)}
        />
      )}
      {showTable && (
        <>
          {/* Captable AG Table */}
          <div className='row mb-9 px-3 mt-3'>
            <div className='col-12'>
              <div className='row gap-8'>
                <div
                  className='card p-6 col-2 cursor-pointer bg-gray-300 h-125px border border-gray-100 border-2 border-hover-primary-light'
                  style={{
                    transition: '500ms',
                  }}
                  onClick={() => handleButtonClick()}
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen035.svg'
                    className='svg-icon-3x text-primary pb-2'
                  />
                  <h3 className='fw-bold mb-0'>Create New Table</h3>
                  <p className='fs-8'>Start by uploading Excel file</p>
                </div>
                <input
                  type='file'
                  accept='.xlsx, .xls'
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <div
                  className='card p-6 col-2 cursor-pointer bg-gray-300 h-125px border border-gray-100 border-2 border-hover-success'
                  style={{
                    transition: '500ms',
                  }}
                  onClick={() =>
                    downloadS3File(
                      'https://equion-dev.s3.us-west-2.amazonaws.com/invp-templates/SampleTemplate.xlsx'
                    )
                  }
                >
                  <i
                    className='bi bi-download fs-2qx text-success-active pb-3 pt-1'
                    style={{ fontWeight: 'bold' }}
                  ></i>
                  <h3 className='fw-bold mb-0'>Download Template</h3>
                  <p className='fs-8'>Refer to sample template</p>
                </div>
              </div>
            </div>
          </div>
          {/* main Ag table */}
          <div className='row mb-9'>
            <div className='col-12'>
              <MainDynamicAGTable
                data={props.data as DynamicTableDataType[]}
                handleBack={() => {
                  setShowTable(true)
                  setShowDetailedTable(false)
                }}
                handleSubmit={(values: DynamicTableDataType) => handleSaveTable(values)}
                handleRemove={(event: React.MouseEvent, id: number) =>
                  props.handleRemove(event, id)
                }
                navigate={(values: DynamicTableDataType) => {
                  setExistingData(values)
                  setShowExistingTable(true)
                  setShowTable(false)
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
  // }

  return <></>
}

export default DynamicTablePageWrapper
