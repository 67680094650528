import * as Yup from 'yup'

export const addAnotherUserSchema = Yup.object().shape({
  emailId: Yup.string()
    .email('Wrong email format')
    .max(50, 'Maximum 50 symbols')
    .required('Please enter valid email'),
  isAdmin: Yup.string().required('Please select the Investor/Fund Role Type').label('isAdmin'),
  accountName: Yup.string().required('Please select the Account Name').label('accountName'),
})

export const AddAccountSchema = Yup.object().shape({
  accountName: Yup.string().min(3).required().label('Account Name'),
})

export const AddInvestorToAccountSchema = Yup.object().shape({
  accountName: Yup.string().required('Please select the Account Name'),
  fundname: Yup.string().required('Please enter Investor Name'),
  fundType: Yup.string().required('Please select the Investor/Fund Type'),
})
