import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import {
  RemoveInvestmentMutation,
  RemoveInvestmentMutationVariables,
  CompanyObject,
  useGetCompanyWiseDataQuery,
  useGetAllTransactionsQuery,
  useRemoveTransactionMutation,
  useRemoveIndirectTransactionMutation,
  RemoveIndirectTransactionMutation,
  RemoveIndirectTransactionMutationVariables,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import Toast, { ToastType } from '../../../../../utils/toast'

type RemoveTransactionType = {
  showModal: boolean
  handleClose: () => void
  investorId?: bigint
  investmentId?: bigint
  isLastInvestment?: boolean
  companyDetails: CompanyObject
  isIndirect: boolean
}
function RemoveTransactionModal(props: RemoveTransactionType) {
  const navigate = useNavigate()
  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)
  const mutateRemoveTransaction = useRemoveTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: RemoveInvestmentMutation,
        _variables: RemoveInvestmentMutationVariables,
        _context: unknown
      ) => {
        const compKey = {
          input: {
            type: props.companyDetails.type,
            hissaCompanyId: props.companyDetails?.hissaCompanyId || '',
            id: Number(props.companyDetails.id) || 0,
          },
        }
        const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
        queryClient.invalidateQueries(getCompanyWiseQuery)

        const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()
        queryClient.invalidateQueries(getAllTransactionQuery)

        Toast('Investment Deleted Successfully!', ToastType.success)
        props.handleClose()
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const mutateRemoveIndirectTransaction = useRemoveIndirectTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: RemoveIndirectTransactionMutation,
        _variables: RemoveIndirectTransactionMutationVariables,
        _context: unknown
      ) => {
        const compKey = {
          input: {
            type: props.companyDetails.type,
            hissaCompanyId: props.companyDetails?.hissaCompanyId || '',
            id: Number(props.companyDetails.id) || 0,
          },
        }
        const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
        queryClient.invalidateQueries(getCompanyWiseQuery)

        const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()
        queryClient.invalidateQueries(getAllTransactionQuery)

        Toast('Investment Deleted Successfully!', ToastType.success)
        props.handleClose()
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const loading = mutateRemoveTransaction.isLoading

  function handleRemoveInvestment(e: React.MouseEvent) {
    // Disable indicator after 3 seconds
    deleteBtnRef.current?.setAttribute('data-kt-indicator', 'on')
    setTimeout(() => {
      // Activate indicator
      deleteBtnRef.current?.removeAttribute('data-kt-indicator')
    }, 3000)

    e.preventDefault()

    props.isIndirect
      ? mutateRemoveIndirectTransaction.mutate({
          input: {
            id: props.investmentId,
            investorId: props.investorId,
            companyId: props.companyDetails.id,
          },
        })
      : mutateRemoveTransaction.mutate({
          input: {
            id: props.investmentId,
            investorId: props.investorId,
            companyId: props.companyDetails.id,
          },
        })

    if (props.isLastInvestment) {
      navigate(-1)
    }
  }

  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px '
      show={props.showModal}
      onHide={props.handleClose}
    >
      <Modal.Body className='p-9'>
        <div className='card'>
          <div className='card-body p-0'>
            <h2 className='fw-bolder text-gray-heading pb-4'>Remove Transaction</h2>
            <div className='fs-5 pb-10'>Are you sure you want to remove this Transaction?</div>
            {/* {!props.isLastInvestment && (
              <div className='fs-7 fw-normal pb-6'>
                <b>Note : </b> This remove transaction action will also remove the beneficiaries
                attached to it
              </div>
            )} */}
            {props.isLastInvestment && (
              <div className='fs-7 fw-normal pb-10'>
                <b>Note : </b> This remove transaction action will also remove company from your
                portfolio
              </div>
            )}
            <div className='d-flex col justify-content-between'>
              <button className='btn btn-lg btn-secondary' onClick={props.handleClose}>
                <span className='indicator-label'>Cancel</span>
              </button>
              <button
                type='submit'
                className='btn btn-lg btn-primary'
                disabled={loading}
                onClick={handleRemoveInvestment}
              >
                {!loading && <span className='indicator-label'>Remove Transaction</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Removing...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RemoveTransactionModal
