import { AgGridReact } from 'ag-grid-react'
import { agConfigSideBar, tableCellStyle } from '../../../../utils/AgGridConfig'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { ColDef } from 'ag-grid-community'

import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import {
  DeleteActionRender,
  FileSizeRender,
  NameRender,
  UploadFileRender,
} from './MainDynamicAGComponent'
import {
  DynamicTableDataInputType,
  DynamicTableDataType,
} from '../wrappers/DynamicTablesPageWrapper'

type DynamicDataProps = {
  data: DynamicTableDataType[]
  handleBack: Function
  handleSubmit: Function
  navigate: Function
  handleRemove: Function
}
export function MainDynamicAGTable(props: DynamicDataProps) {
  const gridApi = useRef<any>(null)

  const tableData: DynamicTableDataType[] = (props.data as DynamicTableDataType[]) || []

  const componentsRegistry = useMemo(
    () => ({
      name: memo(NameRender),
      file: memo(UploadFileRender),
      // size: memo(FileSizeRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <div className='row justify-content-center  text-center py-8 m-6'>
      <img
        alt='no items'
        src={toAbsoluteUrl('/media/custom-images/emptyRecentCompanies.svg')}
        className='h-150px my-10 w-350 justify-content-center'
      />
      <p className='fs-base text-muted text-center'>
        No Data found, Get started by adding new one.
      </p>
    </div>
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: `Name`,
        field: 'name',
        cellRenderer: 'name',
        cellStyle: tableCellStyle,
        sortable: true,
        pinned: 'left',
        // suppressSizeToFit: true,
        initialWidth: 350,
        // autoHeight: true,
        // wrapText: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipName',
      },
      {
        headerName: 'Uploaded File',
        field: 'file',
        cellRenderer: 'file',
        cellStyle: tableCellStyle,
        filter: 'agNumberColumnFilter',
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      // {
      //   headerName: `File Size`,
      //   field: 'size',
      //   cellRenderer: 'size',
      //   cellStyle: tableCellStyle,
      //   sortable: true,
      //   menuTabs: ['filterMenuTab'],
      // },
      {
        headerName: '',
        field: 'actions',
        hide: false,
        width: 90,
        maxWidth: 90,
        pinned: 'right',
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        // cellStyle: { 'padding-top': '10px', 'line-height': '20px' },
        resizable: true,
        sortable: false,
        // cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: DeleteActionRender,
      },
    ],
    [tableData]
  )
  const rowData = useMemo(
    () =>
      tableData?.map((item: DynamicTableDataType) => {
        return {
          name: item.name,
          id: item.id,
          file: item.fileUrl,
          fileName: item.fileName,
          selectedData: item,
          handleBack: props.handleBack,
          handleSubmit: props.handleBack,
          navigate: props.navigate,
          handleRemove: props.handleRemove,
        }
      }),
    [tableData]
  )

  return (
    <div
      className='card p-0 h-575px'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row border-0 p-9 pb-0'>
        <div className='col d-flex flex-row justify-content-end'>
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={false}
          enableRangeSelection={false}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
