export const nonFDBSubTypes = ['OCD', 'RPS', 'NCD', 'OCRPS']

export const securitySubTypes = [
  'Equity',
  'CCPS',
  'CCD',
  'OCD',
  'Note',
  'Warrant',
  'RPS',
  'NCD',
  'OCPS',
  'OCRPS',
  'Others',
]

export const investmentModellingSecurity = ['Equity', 'CCPS']
