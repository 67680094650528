import { CompanyObject, RoundDetails } from '../../../../../generated/graphql'

export function calculateSharePrice(roundDetails: RoundDetails, companyData: CompanyObject) {
  const existingEsopShares =
    (companyData.shareholders &&
      companyData.shareholders.find((sh) => sh?.type === 'Options')?.noOfShares) ||
    0
  if (!roundDetails.roundSize || !roundDetails.preMoneyValuation || !companyData.totalFdbShares)
    throw new Error('Round Size and Pre-Money Valuation are required')
  const dilution =
    roundDetails.roundSize / (roundDetails.roundSize + roundDetails.preMoneyValuation)
  let totalEsopShares = existingEsopShares
  if (!roundDetails.autoDiluteEsop) {
    if (!roundDetails.postRoundEsopPercentage)
      throw new Error('Post-Round ESOP Percentage is required when auto-dilute ESOP is disabled')
    totalEsopShares =
      ((companyData.totalFdbShares - existingEsopShares) * roundDetails.postRoundEsopPercentage) /
      100 /
      (1 - dilution - roundDetails.postRoundEsopPercentage / 100)
  }
  const preRoundFdbShares = totalEsopShares + companyData.totalFdbShares - existingEsopShares
  const sharePrice = roundDetails.preMoneyValuation / preRoundFdbShares

  return sharePrice
}
