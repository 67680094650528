import { useNavigate } from 'react-router-dom'
import DealsAGTable from '../components/dealsAG-table/DealsAGTable'

function DealsPageWrapper() {
  const navigate = useNavigate()
  return (
    <>
      <div className='d-flex justify-content-between'>
        <div>
          <h1>My Documents</h1>
          <div>Home</div>
        </div>
        <div>
          <button className='btn btn-md btn-primary fs-base fw-bold' onClick={()=>{
            navigate('/deals/templateViewer',{state:{ defaultOption: 'issue' }})
          }}>Add Document</button>
        </div>
      </div>
    <div className='card rounded-5 my-10'>
          <DealsAGTable />
    </div>
    </>
  )
}

export default DealsPageWrapper
