import axios from 'axios'
import { QueryFunctionContext } from 'react-query'
import { getAuth } from '../app/modules/auth'
import { ExitModellingDataModel } from '../app/pages/company/components/InvestmentModellingHelper'

export const investorApi = axios.create({
  baseURL: localStorage.getItem('apiUrl') || process.env.REACT_APP_API_URL,
})

export const investorGqlApi = axios.create({
  baseURL: localStorage.getItem('apiUrl') || process.env.REACT_APP_GQL_URL,
})

export const hissaApi = axios.create({
  baseURL: localStorage.getItem('apiUrl') || process.env.REACT_APP_HISSA_URL,
})

export const capTableApi = axios.create({
  baseURL: process.env.REACT_APP_CAPTABLE_URL,
})

investorApi.defaults.headers.post['Content-Type'] = 'application/json'
hissaApi.defaults.headers.post['Content-Type'] = 'application/json'

export async function getS3UrlForUpload(
  key: String,
  hissaVerified: boolean = true,
  companyName: string = '',
  investorId: number = 0,
  companyDocument?: boolean
): Promise<any> {
  const auth = getAuth()!
  return investorApi
    .get('/files/s3PutUrl', {
      params: {
        key: key,
        bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET,
        hissaVerified: hissaVerified,
        companyName: companyName,
        investorId: investorId,
        companyDocument: companyDocument,
      },
      headers: { 'Access-Control-Allow-Origin': '*', Authorization: auth },
    })
    .then((res) => res.data)
}

export async function checkCapTableData(cinNumber: String): Promise<any> {
  return capTableApi
    .get('/v1/company/events', {
      params: { cinNumber: cinNumber },
      headers: { 'Access-Control-Allow-Origin': '*' },
    })
    .then((res) => res)
}

export async function checkFinancialReportData(cinNumber: String): Promise<any> {
  return capTableApi
    .get('/financePage/cin/', {
      params: { cinNumber: cinNumber },
      headers: { 'Access-Control-Allow-Origin': '*' },
    })
    .then((res) => res)
}

export type invData = {
  cin: string
  investorId?: number
  key: string
  name: string
  size: number
  tags: string[]
  companyId?: number | string
  hissaVerified?: boolean
  companyName?: string
}[]

export async function postInvestorDocuments(data: invData): Promise<any> {
  return investorApi.post('/files/investorDocuments', data).then((res) => res)
}

type data = {
  cin: string
  key: string
  name: string
  size: number
  tags: string[]
  userId?: number
}[]

export async function postCompanyDocuments(cinNumber: String, data: data): Promise<any> {
  return capTableApi.post('/v1/investorPortal/postCompanyData', data).then((res) => res)
}

export async function postTransactionDocuments(accessToken: any, data: any): Promise<any> {
  return investorApi
    .post('/files/s3TransactionDocPutUrl', data, {
      headers: { 'Access-Control-Allow-Origin': '*', Authorization: accessToken },
    })
    .then((res) => res)
}

export async function deleteInvestorDocuments(
  cin: String,
  key: String,
  investorId: Number
): Promise<any> {
  return investorApi
    .post('/files/deleteInvestorDocuments', {
      cin: cin,
      key: key,
      investorId: investorId,
    })
    .then((res) => res)
}

export async function deleteCompanyDocuments(
  cin: String,
  key: String,
  userId: Number
): Promise<any> {
  return capTableApi
    .post('/v1/investorPortal/deleteCompanyDocuments', {
      cin: cin,
      key: key,
      userId: userId,
    })
    .then((res) => res)
}

export async function deleteTransactionDocuments(
  investmentId: number,
  key: String,
  isIndirect: boolean
): Promise<any> {
  return investorApi
    .post('/files/deleteTransactionDocuments', {
      investmentId: investmentId,
      key: key,
      isIndirect: isIndirect,
    })
    .then((res) => res)
}

export async function getCompanyDocuments(context: QueryFunctionContext) {
  const IframeToken = context.queryKey[1] as string
  return investorApi
    .get('/companyDocuments', {
      headers: { IframeToken: IframeToken },
    })
    .then((res) => res.data?.data)
}

export async function getCaptableIframeToken(context: QueryFunctionContext) {
  const companyId = context.queryKey[1] as string
  const auth = getAuth()!
  return investorApi
    .get('auth/getIframeToken', {
      params: { companyId: companyId },
      headers: { 'Access-Control-Allow-Origin': '*', Authorization: auth },
    })
    .then((res) => res.data)
}

export async function excelExport(data: string) {
  const auth = getAuth()!
  return investorApi
    .post('/excelExport', data, {
      headers: { 'Access-Control-Allow-Origin': '*', Authorization: auth },
    })
    .then((res) => res.data)
}

export async function storingInvestmentModellingDetails(
  investmentModellingDetails: any,
  investorIds: number[],
  hissaCompanyId: string,
  modelId: number
): Promise<any> {
  const auth = getAuth()!

  return investorApi
    .post(
      '/storeInvestmentModelling',
      { investmentModellingDetails, investorIds, hissaCompanyId, modelId },
      { headers: { 'Access-Control-Allow-Origin': '*', Authorization: auth } }
    )
    .then((res) => res.data.data)
}

export async function getInvestmentModellingDetails(
  context: QueryFunctionContext
): Promise<{ id: number; investmentModellingDetails: ExitModellingDataModel }> {
  const auth = getAuth()!
  const hissaCompanyId = context.queryKey[1] as string

  return investorApi
    .get('/investmentModelling', {
      params: { hissaCompanyId },
      headers: { 'Access-Control-Allow-Origin': '*', Authorization: auth },
    })
    .then((res) => res.data.data)
}

export async function postInvestmentModellingDetails(
  id: string,
  emailId: string,
  investorId: string
): Promise<any> {
  const auth = getAuth()!

  return investorApi
    .post(
      '/emailInvestmentModelling',
      {
        sharedModelId: id,
        emailId: emailId,
        investorId: investorId,
      },
      { headers: { 'Access-Control-Allow-Origin': '*', Authorization: auth } }
    )
    .then((res) => res)
}

export async function validateInvestmentModelling(token: string) {
  return investorApi
    .post('/validateJwtInvestmentModelling', { token: token })
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      console.log(err)
    })
}

export async function fillTermsheet(reqBody: {
  freeText: string
  templateSchema: JSON
  filledValues: JSON
}) {
  const auth = getAuth()!
  return investorApi
    .post(
      '/llms/fillTermsheet',
      {
        freeText: reqBody.freeText,
        templateSchema: reqBody.templateSchema,
        filledValues: reqBody.filledValues,
      },
      {
        headers: { 'Access-Control-Allow-Origin': '*', Authorization: auth },
      }
    )
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      console.log(err)
    })
}

export async function postCompanyLink(reqBody: {
  name: string
  link: string
  iframeToken: string
}) {
  return investorApi
    .post(
      '/postCompanyDocumentLink',
      {
        name: reqBody.name,
        link: reqBody.link,
      },
      {
        headers: { 'Access-Control-Allow-Origin': '*', IframeToken: reqBody.iframeToken },
      }
    )
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      console.log(err)
    })
}

export async function deleteCompanyLink(reqBody: {
  id: string
  link: string
  iframeToken: string
}) {
  return investorApi
    .post(
      '/deleteCompanyDocumentLink',
      {
        id: reqBody.id,
        link: reqBody.link,
      },
      {
        headers: { 'Access-Control-Allow-Origin': '*', IframeToken: reqBody.iframeToken },
      }
    )
    .then((res) => {
      return res.data.data
    })
    .catch((err) => {
      console.log(err)
    })
}
