import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

type Props = {
  showModal: boolean
  handleClose: () => void
}

export const RequestedModal = ({ showModal, handleClose }: Props) => {
  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px '
      show={showModal}
      onHide={handleClose}
    >
      <Modal.Body className='shadow border border-primary rounded p-9'>
        <div className='card p-0'>
          <div className='card-body p-0'>
            <div className='text-center'>
              <img
                src={toAbsoluteUrl('/media/icons/duotune/general/request-sent.svg')}
                className='h-200px w-300px mb-6'
                alt=''
              />
              <h3 className=''>
                Your Request for update has been sent, our representatives will contact you at the
                earliest!
              </h3>
            </div>

            <div className='d-flex col justify-content-start pt-10'>
              <button className='btn btn-lg btn-secondary' onClick={handleClose}>
                <span className='indicator-label'>Close</span>
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
