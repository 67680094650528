import * as Yup from 'yup'

export interface IUpdateFundNaV {
  nav: number
  navDate: string
}

const updateFundNavSchema = Yup.object().shape({
  nav: Yup.number()
    .positive()
    .moreThan(0, 'Should be greater than 0')
    .required()
    .label('Net Asset Value'),
  navDate: Yup.string().required('Select NAV date').label('NAV Date'),
})

const addFundUnitInits: IUpdateFundNaV = {
  nav: 1,
  navDate: '',
}

export { addFundUnitInits, updateFundNavSchema }
