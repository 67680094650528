import React, { useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import AddPartnerForm from './AddPartnerForm'
import { FundStructure, FundUnit, PartnerGroup } from '../../../../generated/graphql'

type Props = {
  showModal: boolean
  handleClose: VoidFunction
  investorId: number
  fundStructure: FundStructure
  isEdit: boolean
  data?: PartnerGroup
  fundUnitData: FundUnit[]
}

function AddPartnerModal({
  showModal,
  investorId,
  handleClose,
  fundStructure,
  isEdit,
  data,
  fundUnitData,
}: Props) {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-750px'
      show={showModal}
      onHide={handleClose}
    >
      <div className='col d-flex justify-content-between ps-10 pe-6 pb-6 '>
        <div className='pt-10'>
          <h2 className='fw-bolder text-dark'>
            {isEdit ? 'Edit Partner Group' : 'Add Partner Group'}
          </h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-6'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>
      <div className='separator '></div>

      <Modal.Title>
        <div className='card card-p-0 '>
          <div className='card-body'>
            <div
              ref={stepperRef}
              className='stepper stepper-links d-flex flex-column'
              id='kt_modal_add_inv_stepper'
            >
              <div className='d-flex row pt-6 pb-10 px-15 '>
                <AddPartnerForm
                  handleClose={handleClose}
                  investorId={investorId}
                  fundStructure={fundStructure}
                  isEdit={isEdit}
                  data={data}
                  fundUnitData={fundUnitData}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Title>
    </Modal>
  )
}

export default AddPartnerModal
