import { AgGridReact } from 'ag-grid-react'
import { agConfigSideBar, tableCellStyle } from '../../../../utils/AgGridConfig'
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CellClickedEvent, ColDef } from 'ag-grid-community'
import {
  AmountInvestedRender,
  MoicRender,
  UnrealizedValueRender,
  CompanyNameRender,
  FundRender,
  InvestedRoundRender,
  IrrRender,
  RoundRender,
  FdbPercentageRender,
  FdbSharesRender,
  TagsRender,
  UnrealizedCostRender,
  RealizedValueRender,
  JurisdictionRender,
  ActionRender,
  EsopHoldingRender,
} from './PortfolioAGComponent'
import { AllTransactionsType, Transaction_Type } from '../../../../generated/graphql'
import { RateInterval, convertRate, xirr } from 'node-irr'
import { calculateXirr } from '../../../../utils/Utils'
import { KTSVG } from '../../../../_metronic/helpers'
import { GlobalContext } from '../../store/context/globalContext'
import { getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { AddIndirectTransactionModal } from '../../manual-investments/components/AddAdditionalIndirectTransaction/AddIndirectTransactionModal'
import { AddDirectTransactionModal } from '../../manual-investments/components/AddAdditionalDirectTransaction/AddDirectTransactionModal'
import { InvestorListType } from '../../company/views/HissaCompanySummaryPage'
import { AddTransactionPopup } from './AddTransactionPopUp'

type PortfolioTableProps = {
  allTransaction?: AllTransactionsType[] //need to change types
  investors: InvestorListType[]
}
export function PortfolioAGTable(props: PortfolioTableProps) {
  const gridApi = useRef<any>(null)
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  const allTransaction = props.allTransaction?.sort((a, b) =>
    a.companyData.name.localeCompare(b.companyData.name)
  )

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [allTransaction])

  const [showModal, setShowModal] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [transactionType, setTransactionType] = useState<string>('direct')
  const [showDirectModal, setShowDirectModal] = useState<boolean>(false)
  const [showIndirectModal, setShowIndirectModal] = useState<boolean>(false)

  const componentsRegistry = useMemo(
    () => ({
      companyName: memo(CompanyNameRender),
      fund: memo(FundRender),
      investedRound: memo(InvestedRoundRender),
      amountInvested: memo(AmountInvestedRender),
      unrealizedValue: memo(UnrealizedValueRender),
      realizedValue: memo(RealizedValueRender),
      unrealizedCost: memo(UnrealizedCostRender),
      FDBPercentage: memo(FdbPercentageRender),
      FDBShares: memo(FdbSharesRender),
      irr: memo(IrrRender),
      moic: memo(MoicRender),
      round: memo(RoundRender),
      esopHolding: memo(EsopHoldingRender),
      jurisdiction: memo(JurisdictionRender),
      tags: memo(TagsRender),
    }),
    []
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState()
    return {}
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel()
    return {}
  }

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting()
    const filterState = await getFilterSetting()
    // postOnFilter({
    //   userId: `${userId}`,
    //   companyId,
    //   filterData: {
    //     ...agTableStore.state,
    //     transactionColumnModel: columnState,
    //     transactionFilterModel: filterState,
    //   },
    // });
  }

  useEffect(() => {
    if (transactionType === 'direct') {
      setShowDirectModal(true)
      setShowIndirectModal(false)
    } else if (transactionType === 'indirect') {
      setShowIndirectModal(true)
      setShowDirectModal(false)
    }
  }, [transactionType, isModalOpen])

  const [filteredRowData, setFilteredRowData] = useState<any>([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent()
    setIsFilterApplied(filtersApplied)

    const filteredData = grid.api.getModel().rowsToDisplay.map((node: any) => node.data)
    setFilteredRowData(filteredData)
    // setPinnedBottomRowData(grid);
    uploadFilterAndColumn()
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Company',
        field: 'companyName',
        cellRenderer: 'companyName',
        filter: 'agMultiColumnFilter',
        // getQuickFilterText: (params) => params.value.name,
        getQuickFilterText: (params) => {
          const name = params.value.name.toLowerCase()
          const dba = params.value.dba ? params.value.dba.toLowerCase() : '' // Check if dba exists
          return `${name} ${dba}` // Concatenate name and dba
        },
        comparator(valueA, valueB, ..._: any[]) {
          return valueA.name > valueB.name ? 1 : -1
        },

        filterParams: {
          maxNumConditions: 5,
          buttons: ['reset'],
          filters: [
            {
              title: 'Company Name',
              display: 'subMenu',
              filter: 'agTextColumnFilter',
              buttons: ['reset'],
              filterValueGetter: (params: any) => params.value.name,
              filterParams: {
                buttons: ['reset'],
                valueGetter: (params: any) => params.getValue('companyName').name,
              },
            },
            {
              title: 'Doing Business As',
              filter: 'agTextColumnFilter',
              display: 'subMenu',
              buttons: ['reset'],
              filterValueGetter: (params: any) => params.value.dba,
              filterParams: {
                buttons: ['reset'],
                valueGetter: (params: any) => params.getValue('companyName').dba,
              },
            },
            // {
            //   title: 'Doing Business As',
            //   filter: 'agTextColumnFilter',
            //   display: 'subMenu',
            //   buttons: ['reset'],
            //   filterParams: {
            //     buttons: ['reset'],
            //     keyCreator: (params: any) => {
            //       const transactionType = params.value.dba
            //       return transactionType
            //     },
            //     valueFormatter: (params: any) => {
            //       const transactionType = params.value.dba
            //       return transactionType
            //     },
            //   },
            // },
          ],
        },
        cellStyle: tableCellStyle,
        // pinned: 'left',
        minWidth: 350,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipTransactionName',
      },
      {
        headerName: 'Fund',
        field: 'fund',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'fund',
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ['filterMenuTab'],
      },
      // {
      //   headerName: 'Round Invested',
      //   field: 'investedRound',
      //   filter: 'agNumberColumnFilter',
      //   cellRenderer: 'investedRound',
      //   cellStyle: tableCellStyle,
      //   sortable: true,
      //   hide: true,
      //   menuTabs: ['filterMenuTab'],
      // },
      // {
      //   headerName: 'Investment Date',
      //   field: 'dateOfInvestment',
      //   filter: 'agDateColumnFilter',
      //   filterParams: {
      //     comparator: (dateFromFilter: Date, cellValue: any) => {
      //       if (cellValue == null) {
      //         return 0
      //       }
      //       const dateParts = cellValue.split('-')
      //       const day = Number(dateParts[2])
      //       const month = Number(dateParts[1]) - 1
      //       const year = Number(dateParts[0])
      //       const cellDate = new Date(year, month, day)
      //       if (cellDate < dateFromFilter) {
      //         return -1
      //       } else if (cellDate > dateFromFilter) {
      //         return 1
      //       }
      //       return 0
      //     },
      //   },
      //   cellRenderer: 'dateOfInvestment',
      //   cellStyle: tableCellStyle,
      //   sortable: true,
      //   menuTabs: ['filterMenuTab'],
      // },
      {
        headerName: `Amount Invested (${getSymbolOfCurrency(userCurrency)})`,
        field: 'amountInvested',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'amountInvested',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipAmountInvested',
      },
      {
        headerName: `Unrealized Value (${getSymbolOfCurrency(userCurrency)})`,
        field: 'unrealizedValue',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'unrealizedValue',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipUnrealizedValue',
      },
      {
        headerName: `Realized Value (${getSymbolOfCurrency(userCurrency)})`,
        field: 'realizedValue',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'realizedValue',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipRealizedValue',
      },
      // {
      //   headerName: 'Unrealized Cost',
      //   field: 'unrealizedCost',
      //   filter: 'agNumberColumnFilter',
      //   cellRenderer: 'unrealizedCost',
      //   cellStyle: tableCellStyle,
      //   sortable: true,
      //   hide: true,
      //   menuTabs: ['filterMenuTab'],
      // },
      {
        headerName: 'FDB %',
        field: 'FDBPercentage',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'FDBPercentage',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'FDB Shares',
        field: 'FDBShares',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'FDBShares',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipFdbShares',
      },
      {
        headerName: 'IRR',
        field: 'irr',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'irr',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'MOIC',
        field: 'moic',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'moic',
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'ESOP Holding',
        field: 'esopHolding',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'esopHolding',
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipEsopHolding',
      },
      {
        headerName: 'No. of Rounds',
        field: 'round',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'round',
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Jurisdiction',
        field: 'jurisdiction',
        cellRenderer: 'jurisdiction',
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Tags',
        field: 'tags',
        filter: 'agSetColumnFilter',
        cellRenderer: 'tags',
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        hide: false,
        width: 60,
        maxWidth: 60,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: {
          'padding-left': '9px',
          'padding-right': '4px',
          'padding-top': '5px',
          'line-height': '20px',
        },
        resizable: false,
        sortable: false,
        cellRenderer: ActionRender,
      },
    ],
    [userCurrencyFormat]
  )

  const rowData = useMemo(
    () =>
      allTransaction?.map((company: AllTransactionsType) => {
        const transactions = company.transactionData
        const investorData = company.investorData

        const irrData: { amount: number; date: Date }[] = []
        const investorNames: string[] = []
        let irr: number | null = 0
        let realizedValue = 0
        let moic = 1
        let totalInvested = 0
        let currentValue = 0
        let fdbShares = 0
        let fdbPercentage = 0

        if (transactions && transactions.length > 0) {
          transactions.forEach((transaction) => {
            if (
              transaction?.transactionType === Transaction_Type.Buy ||
              transaction?.transactionType === Transaction_Type.SecondaryBuy
            ) {
              totalInvested += transaction.amount
              irrData.push({
                amount: Math.abs(transaction.amount) * -1,
                date: new Date(transaction.transactionDate),
              })
            } else if (transaction?.transactionType === Transaction_Type.Sell) {
              irrData.push({
                amount: Math.abs(transaction.amount),
                date: new Date(transaction.transactionDate),
              })
              realizedValue += Math.abs(transaction.amount)
            }
          })
        }

        if (investorData && investorData.length > 0) {
          investorData.forEach((investor) => {
            if (investor?.currentValue) currentValue += investor?.currentValue
            if (investor?.fdbShares) fdbShares += investor?.fdbShares
            if (investor?.fdbHoldingPercentage) fdbPercentage += investor?.fdbHoldingPercentage
            if (investor?.fdbHoldingPercentage) investorNames.push(investor?.name)
          })
        }

        moic = totalInvested !== 0 ? (realizedValue + currentValue!) / totalInvested : 1
        if (irrData.length >= 1) {
          irrData.push({ amount: currentValue, date: new Date() })
          irr = null
          if (isNaN(convertRate(xirr(irrData).rate, RateInterval.Year))) {
            const values = irrData.map((value: any) => value.amount)
            const dates = irrData.map((date: any) => new Date(date.date))
            const irrValue = calculateXirr(values, dates)
            irr = irrValue !== 'NA' ? irrValue * 100 : null
          } else {
            irr = convertRate(xirr(irrData).rate, RateInterval.Year) * 100
          }
        }

        return {
          companyId: company.companyData.hissaCompanyId || company.companyData.id,

          companyName: {
            name: company.companyData.name,
            dba: company.companyData.dba,
          },
          fund: company.investorData
            ? company.investorData?.map((investor) => investor?.name).join(', ') || '-'
            : '-',
          investedRound: '-',
          amountInvested: totalInvested,
          tooltipAmountInvested: totalInvested.toLocaleString(userCurrencyFormat),
          unrealizedValue: currentValue,
          tooltipUnrealizedValue: currentValue.toLocaleString(userCurrencyFormat),
          realizedValue: realizedValue,
          tooltipRealizedValue: realizedValue.toLocaleString(userCurrencyFormat),
          unrealizedCost: 0,
          FDBPercentage: fdbPercentage * 100.0,
          FDBShares: fdbShares,
          tooltipFdbShares: fdbShares.toLocaleString(userCurrencyFormat),
          irr: irr,
          moic: moic,
          companyStage: company.companyData.companyStage,
          round: company.companyData.noOfRounds,
          jurisdiction: company.companyData.jurisdiction || 'India',
          tags: company.companyData.tags || [],
          userCurrencyFormat: userCurrencyFormat,
          userCurrency: userCurrency,
          type: company.companyData.type,
          cin: company.companyData.cin,
          id: company.companyData.id,
          esopHolding: company.companyData.esopHolding
            ? company.companyData.esopHolding * 100.0
            : 0,
          isIndirect:
            company.indirectTransactionData && company.indirectTransactionData?.length > 0
              ? true
              : false,
          companyDetails: company.companyData,
        }
      }),
    [allTransaction, userCurrencyFormat]
  )

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode
    const filteredData = data.childrenAfterAggFilter.map((element: any) => element.data)
    const totalInvested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amountInvested,
      0
    )
    const realizedValue = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.realizedValue,
      0
    )
    const unrealizedValue = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.unrealizedValue,
      0
    )
    const fdbShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.FDBShares,
      0
    )
    api.setPinnedBottomRowData([
      {
        companyId: null,
        companyName: {
          name: 'Total',
          dba: null,
        },
        fund: null,
        investedRound: '-',
        amountInvested: totalInvested,
        tooltipAmountInvested: totalInvested.toLocaleString(userCurrencyFormat),
        unrealizedValue: unrealizedValue,
        tooltipUnrealizedValue: unrealizedValue.toLocaleString(userCurrencyFormat),
        realizedValue: realizedValue,
        tooltipRealizedValue: realizedValue.toLocaleString(userCurrencyFormat),
        unrealizedCost: null,
        FDBPercentage: null,
        FDBShares: fdbShares,
        tooltipFdbShares: fdbShares.toLocaleString(userCurrencyFormat),
        irr: null,
        moic: null,
        round: null,
        jurisdiction: null,
        tags: null,
        userCurrencyFormat: userCurrencyFormat,
        userCurrency: userCurrency,
        type: null,
        cin: null,
        id: null,
        esopHolding: null,
        isIndirect: null,
        companyDetails: null,
      },
    ])
  }

  return (
    <div
      className='card p-0 h-800px'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div
        className='d-flex flex-row overflow-auto border-0 p-9'
        style={{ overflowY: 'auto', overflowX: 'auto', scrollbarWidth: 'none' }}
      >
        <div className='card-title'>
          <h1 className='mb-0'>Portfolio Companies</h1>
          <p className='fs-7 text-gray-400'>{allTransaction?.length} Companies</p>
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          {/* <input
            type='search'
            className='form-control h-38px w-auto mw-150-px form-control-solid me-2 fs-base'
            name='search'
            placeholder='Search by Company Name'
            onChange={(e: any) => {
              gridApi.current.api.setQuickFilter(e.target.value)
            }}
          /> */}

          <span
            className={`d-flex flex-column border border-1 border-secondary-txt bg-transparent h-38px items-center w-full rounded`}
            style={{
              boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
              border: '1',
              borderColor: '#F1F1F2',
              zIndex: 6,
            }}
          >
            <span className='d-flex flex-row'>
              <KTSVG
                path={'media/icons/duotune/general/gen021.svg'}
                className='ps-4 svg-icon-2x text-start fs-9 pt-2'
                svgClassName='text-gray-700'
              />
              <input
                type='search'
                placeholder='Search by Company Name'
                className='form-control w-auto mw-200-px border-0 h-35px rounded'
                onChange={(e: any) => {
                  // gridApi.current.api.setQuickFilter(e.target.value)
                  const searchTerm = e.target.value.toLowerCase()
                  gridApi.current.api.setQuickFilter(searchTerm, [
                    { field: 'companyName.name', filterType: 'text' },
                    { field: 'companyName.dba', filterType: 'text' },
                  ])
                }}
              />
            </span>
          </span>

          <button
            type='button'
            className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal ms-4 text-white'
            onClick={() => {
              setShowModal(true)
            }}
          >
            Add Transaction
          </button>

          {showModal && (
            <AddTransactionPopup
              transactionType={transactionType}
              showModal={showModal}
              handleClose={() => {
                setShowModal(false)
                setIsModalOpen(false)
              }}
              setTransactionType={(type: string) => setTransactionType(type)}
              isOpen={(isOpen: boolean) => setIsModalOpen(isOpen)}
            />
          )}

          {showIndirectModal && isModalOpen && (
            <AddIndirectTransactionModal
              showModal={showIndirectModal}
              handleClose={() => {
                setIsModalOpen(false)
                setShowIndirectModal(false)
              }}
              isCompanyExist={false}
              handleSubmit={() => setShowModal(false)}
              isEdit={false}
            />
          )}
          {showDirectModal && isModalOpen && (
            <AddDirectTransactionModal
              showModal={showDirectModal}
              handleClose={() => {
                setIsModalOpen(false)
                setShowDirectModal(false)
              }}
              isCompanyExist={false}
              handleSubmit={() => setShowModal(false)}
              isEdit={false}
            />
          )}

          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          onFilterChanged={onAgGridFilterChanged}
          onRowDataUpdated={setPinnedBottomRowData}
          onColumnEverythingChanged={uploadFilterAndColumn}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={true}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          suppressMenuHide={false}
          onColumnResized={uploadFilterAndColumn}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
