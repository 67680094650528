import { useFormik } from 'formik'
import { InvestmentRoundDetails, investmentRoundSchemas } from './InvestmentModellingHelper'
import { useContext, useEffect, useState } from 'react'
import Toast, { ToastType } from '../../../../../utils/toast'
import { Modal } from 'react-bootstrap'
import { getCurrency, getSymbolOfCurrency } from '../../../../../utils/CurrencyFormatter'
import { GlobalContext } from '../../../store/context/globalContext'
import { InputBox } from '../../../../../utils/NumberInputBox'

type Props = {
  setSetRoundData: Function
  handleNextStep: Function
  saveModel: Function
  resetInvestmentData: Function
  modelName: string
  roundData: InvestmentRoundDetails
}
export const InvestmentModelingForm = (props: Props) => {
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  const [isModified, setIsModified] = useState(false)
  const [showConfirmModel, setShowConfirmModel] = useState(false)

  const initialInvestmentRoundDetails: InvestmentRoundDetails = {
    roundName: props.roundData?.roundName || '',
    modelName: props.modelName || '',
    roundId: props.roundData?.roundId || '',
    preMoneyValuation: props.roundData?.preMoneyValuation || 0,
    postMoneyValuation: props.roundData?.postMoneyValuation || 0,
    roundSize: props.roundData?.roundSize || 0,
    closeDate: props.roundData?.closeDate?.split('T')[0] || '',
    postRoundEsopPercentage: props.roundData?.postRoundEsopPercentage || 0,
    autoDiluteEsop: props.roundData && props.roundData.autoDiluteEsop!,
  }
  const resetInvestmentRoundDetails: InvestmentRoundDetails = {
    roundName: '',
    modelName: '',
    roundId: '',
    preMoneyValuation: 0,
    postMoneyValuation: 0,
    roundSize: 0,
    closeDate: '',
    postRoundEsopPercentage: 0,
    autoDiluteEsop: true,
  }

  const formik = useFormik<InvestmentRoundDetails>({
    initialValues:
      Object.keys(props.roundData).length > 0
        ? initialInvestmentRoundDetails
        : resetInvestmentRoundDetails,
    validationSchema: investmentRoundSchemas,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      props.setSetRoundData(values)
      if (isModified && showConfirmModel) {
        props.resetInvestmentData(values)
        setShowConfirmModel(false)
      }
      setIsModified(false)
      // Toast('Round Details saved Successfully', ToastType.success)
    },
  })

  useEffect(() => {
    if (formik.values.autoDiluteEsop === true) formik.setFieldValue('postRoundEsopPercentage', 0)
  }, [formik.values.autoDiluteEsop])

  useEffect(() => {
    if (formik.values.roundSize > 0 && formik.values.preMoneyValuation > 0) {
      formik.setFieldValue(
        'postMoneyValuation',
        Number(formik.values.roundSize) + Number(formik.values.preMoneyValuation)
      )
    }
    if (
      formik.values.roundSize !== props.roundData.roundSize ||
      formik.values.preMoneyValuation !== props.roundData.preMoneyValuation ||
      formik.values.postRoundEsopPercentage !== props.roundData.postRoundEsopPercentage
    ) {
      setIsModified(true)
    }
  }, [
    formik.values.roundSize,
    formik.values.preMoneyValuation,
    formik.values.postRoundEsopPercentage,
  ])

  // const hasChanged = (formikValues: any, initialValues: any) => {
  //   return Object.keys(initialValues).some((key) => formikValues[key] !== initialValues[key])
  // }

  // useEffect(() => {
  //   if (hasChanged(formik.values, initialInvestmentRoundDetails)) {
  //     setIsUpdated(true)
  //   }
  // }, [formik.values])

  // useEffect(() => {
  //   formik.setFieldValue('postRoundEsopPercentage', 0)
  // }, [formik.values.autoDiluteEsop])

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form px-0'>
      <div className={`card p-9  mt-md-0 h-500px overflow-auto`}>
        <div className='row gx-9'>
          <div className='fv-row d-flex align-items-center pb-9'>
            <label className='form-label col-3 required'>Model Name</label>

            <div className=' col-4'>
              <InputBox
                isDisabled={false}
                name={'modelName'}
                handleChange={(value: string) => {
                  formik.setFieldValue('modelName', value)
                }}
                setFieldValue={(value: string) => {
                  formik.setFieldValue('modelName', value)
                }}
                placeholder='Enter Model Name'
                currency={''}
                value={formik.values.modelName}
                type='text'
              />
              <div className='text-danger mt-2'>
                {formik.touched.modelName && formik.errors.modelName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-7'>{formik.errors.modelName}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex mb-8'>
          <div className='fw-bold fs-2'>Round Details</div>
          <hr className='my-auto flex-grow-1' />
        </div>
        <div className='row gx-9'>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-3'>
            <label className='form-label required'>Round Name</label>

            <InputBox
              isDisabled={false}
              name={'roundName'}
              handleChange={(value: string) => {
                formik.setFieldValue('roundName', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('roundName', value)
              }}
              placeholder='Enter Round Name'
              currency={''}
              value={formik.values.roundName}
              type='text'
            />
            <div className='text-danger mt-2'>
              {formik.touched.roundName && formik.errors.roundName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.roundName}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-3'>
            <label className='form-label required'>Round Identifier</label>

            <InputBox
              isDisabled={false}
              name={'roundId'}
              handleChange={(value: string) => {
                formik.setFieldValue('roundId', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('roundId', value)
              }}
              placeholder='Enter Round Identifier'
              currency={''}
              value={formik.values.roundId}
              type='text'
            />
            <div className='text-danger mt-2'>
              {formik.touched.roundId && formik.errors.roundId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.roundId}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-3'>
            <label className='form-label required'>
              {`Round Size (${getSymbolOfCurrency(userCurrency)})`}
            </label>

            <InputBox
              isDisabled={false}
              name={'roundSize'}
              handleChange={(value: number) => {
                formik.setFieldValue('roundSize', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('roundSize', value)
              }}
              currency={userCurrency}
              placeholder='Enter roundSize'
              value={formik.values.roundSize}
              isCurrency={true}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.roundSize && formik.errors.roundSize && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.roundSize}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-3'>
            <label className='form-label required'>
              {`Pre-Money Valuation (${getSymbolOfCurrency(userCurrency)})`}
            </label>

            <InputBox
              isDisabled={false}
              name={'preMoneyValuation'}
              handleChange={(value: number) => {
                formik.setFieldValue('preMoneyValuation', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('preMoneyValuation', value)
              }}
              currency={userCurrency}
              placeholder='Enter Pre-Money Valuation'
              value={formik.values.preMoneyValuation}
              isCurrency={true}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.preMoneyValuation && formik.errors.preMoneyValuation && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.preMoneyValuation}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='row gx-9'>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-3'>
            <label className='form-label required'>Close Date</label>

            <input
              type='date'
              placeholder='10/10/2010'
              className='form-control custom-input fw-bold shadow-lg h-38px'
              {...formik.getFieldProps('closeDate')}
            />

            <div className='text-danger mt-2'>
              {formik.touched.closeDate && formik.errors.closeDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.closeDate}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-3 d-flex align-items-center'>
            <label className='form-label col-form-label me-8'>Auto Dilute ESOP</label>

            <div className='form-check form-switch form-check-custom form-check-solid me-10'>
              <input
                className='form-check-input h-20px w-40px'
                type='checkbox'
                checked={formik.values.autoDiluteEsop}
                {...formik.getFieldProps('autoDiluteEsop')}
              />
            </div>
          </div>

          {!formik.values.autoDiluteEsop && (
            <div className='fv-row mb-10 col-12 col-sm-6 col-md-3'>
              <label className='form-label required'>Post Round ESOP (%)</label>

              <InputBox
                isDisabled={false}
                name={'postRoundEsopPercentage'}
                handleChange={(value: number) => {
                  formik.setFieldValue('postRoundEsopPercentage', value)
                }}
                setFieldValue={(value: number) => {
                  formik.setFieldValue('postRoundEsopPercentage', value)
                }}
                currency={userCurrency}
                placeholder='Enter Post Round ESOP (%)'
                value={formik.values.postRoundEsopPercentage}
                type='number'
              />
              <div className='text-danger mt-2'>
                {formik.touched.postRoundEsopPercentage && formik.errors.postRoundEsopPercentage && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-7'>
                      {formik.errors.postRoundEsopPercentage}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='px-9 py-6 d-flex justify-content-end'>
        {showConfirmModel && Object.keys(props.roundData).length !== 0 && isModified && (
          <Modal
            id='kt_modal_add_inv'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px '
            show={showConfirmModel && Object.keys(props.roundData).length !== 0}
            onHide={() => setShowConfirmModel(false)}
          >
            <Modal.Body className='p-9'>
              <div className='card'>
                <div className='card-body p-0'>
                  <h2 className='fw-bolder text-gray-heading pb-4'>Save Round Details</h2>
                  <div className='fs-5 pb-6'>Are you sure you want to Update Round Details?</div>

                  <div className='fs-5 fw-normal'>
                    <b>Note : </b> This will reset your entered investment details.
                  </div>

                  <div className='d-flex col justify-content-between pt-6'>
                    <button
                      className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
                      onClick={() => {
                        setShowConfirmModel(false)
                      }}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </button>
                    <button
                      type='submit'
                      className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
                      onClick={() => {
                        formik.handleSubmit()
                      }}
                    >
                      {'Save'}
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        <button
          type={
            Object.keys(props.roundData).length === 0 ||
            (Object.keys(props.roundData).length !== 0 && !isModified)
              ? 'submit'
              : 'button'
          }
          className='px-4 rounded border border-0 border-primary bg-primary me-4 h-38px fw-normal text-white'
          disabled={false}
          onClick={() => {
            setShowConfirmModel(true)
          }}
        >
          {Object.keys(props.roundData).length === 0 ||
          (Object.keys(props.roundData).length !== 0 && isModified)
            ? 'Save & Continue'
            : 'Next'}
        </button>
      </div>
    </form>
  )
}
