import { RateInterval, convertRate, xirr } from 'node-irr'
import { CapitalLedgerType, FundStructure, LedgerTransaction } from '../../../../generated/graphql'
import { calculateXirr } from '../../../../utils/Utils'

export type Moic = {
  moic: number
  unrealized: number
  realized: number
}

export function getMoicObject(fundStructure: FundStructure) {
  const ledgerTransactions = fundStructure.capitalledgerTransactions as LedgerTransaction[]
  const capitalCalls: LedgerTransaction[] = fundStructure.capitalledgerTransactions?.filter(
    (t) => t?.type === CapitalLedgerType.CapitalCall
  ) as LedgerTransaction[]
  if (capitalCalls.length < 1) return { moic: 0, unrealized: 0, realized: 0 }
  const capitalDistributions: LedgerTransaction[] = fundStructure.capitalledgerTransactions?.filter(
    (t) => t?.type === CapitalLedgerType.CapitalDistribution
  ) as LedgerTransaction[]
  const realizedMoic =
    capitalDistributions?.reduce((acc, obj) => acc + obj?.finalAmount!, 0) /
    capitalCalls?.reduce((acc, obj) => acc + obj?.finalAmount!, 0)
  const unrealizedMoic = ledgerTransactions?.sort((a, b) => b.closeDate.toString().localeCompare(a.closeDate.toString()))[0].currentValue! / capitalCalls?.reduce((acc, obj) => acc + obj?.finalAmount!, 0)
  const moic = realizedMoic + unrealizedMoic
  const moicObject: Moic = {
    moic: moic,
    unrealized: unrealizedMoic,
    realized: realizedMoic,
  }
  return moicObject
}

export function getDPI(fundStructure: FundStructure): number {
  let dpi = 0
  const capitalCalls: LedgerTransaction[] = fundStructure.capitalledgerTransactions?.filter(
    (t) => t?.type === CapitalLedgerType.CapitalCall
  ) as LedgerTransaction[]
  const capitalDistributions: LedgerTransaction[] = fundStructure.capitalledgerTransactions?.filter(
    (t) => t?.type === CapitalLedgerType.CapitalDistribution
  ) as LedgerTransaction[]
  if (capitalCalls.length < 1 || capitalDistributions.length < 1) return 0
  dpi =
    capitalDistributions?.reduce((acc, obj) => acc + obj?.finalAmount!, 0) /
    capitalCalls?.reduce((acc, obj) => acc + obj?.finalAmount!, 0)
  return dpi
}
export function getRPI(fundStructure: FundStructure): number {
  let rpi = 0
  const ledgerTransactions = fundStructure.capitalledgerTransactions as LedgerTransaction[]
  const capitalCalls: LedgerTransaction[] = fundStructure.capitalledgerTransactions?.filter(
    (t) => t?.type === CapitalLedgerType.CapitalCall
  ) as LedgerTransaction[]
  if (capitalCalls.length < 1) return 0
  rpi = ledgerTransactions?.sort((a, b) => b.closeDate.toString().localeCompare(a.closeDate.toString()))[0].currentValue! / capitalCalls?.reduce((acc, obj) => acc + obj?.finalAmount!, 0)
  return rpi
}

export type Tvpi = {
  tvpi: number
  netTvpi: number
}

export function getTVPI(fundStructure: FundStructure) {
  let tvpi: Tvpi = {
    netTvpi: 0,
    tvpi: 0,
  }
  const ledgerTransactions = fundStructure.capitalledgerTransactions as LedgerTransaction[]
  const capitalCalls: LedgerTransaction[] = fundStructure.capitalledgerTransactions?.filter(
    (t) => t?.type === CapitalLedgerType.CapitalCall
  ) as LedgerTransaction[]
  const capitalDistributions: LedgerTransaction[] = fundStructure.capitalledgerTransactions?.filter(
    (t) => t?.type === CapitalLedgerType.CapitalDistribution
  ) as LedgerTransaction[]
  if (capitalCalls.length < 1) return tvpi

  tvpi.tvpi = (capitalDistributions?.reduce((acc, obj) => acc + obj?.finalAmount!, 0) + ledgerTransactions?.sort((a, b) => b.closeDate.toString().localeCompare(a.closeDate.toString()))[0].currentValue!) / capitalCalls?.reduce((acc, obj) => acc + obj?.finalAmount!, 0)
  return tvpi
}

export type Irr = {
  grossIrr: number
  netIrr: number
}

export function getIrr(fundStructure: FundStructure) {
  let irr: Irr = {
    grossIrr: 0,
    netIrr: 0
  }
  const ledgerTransactions = fundStructure.capitalledgerTransactions as LedgerTransaction[]
  const capitalCalls: LedgerTransaction[] = fundStructure.capitalledgerTransactions?.filter(
    (t) => t?.type === CapitalLedgerType.CapitalCall
  ) as LedgerTransaction[]
  const capitalDistributions: LedgerTransaction[] = fundStructure.capitalledgerTransactions?.filter(
    (t) => t?.type === CapitalLedgerType.CapitalDistribution
  ) as LedgerTransaction[]

  if (ledgerTransactions.length < 1) return irr
  const irrData: { amount: number, date: Date }[] = []
  capitalCalls.forEach(transaction => {
    irrData.push({
      date: new Date(transaction.closeDate),
      amount: -1 * transaction.finalAmount!
    })
  })
  capitalDistributions.forEach(transaction => {
        irrData.push({
          date: new Date(transaction.closeDate),
          amount: transaction.finalAmount!
        })
      })
    
  if (irrData.length > 1) {
    irrData.push({
      amount: ledgerTransactions?.sort((a, b) => b.closeDate.toString().localeCompare(a.closeDate.toString()))[0].currentValue!,
      date: new Date()
    })
    if (isNaN(convertRate(xirr(irrData).rate, RateInterval.Year))) {
      const values = irrData.map((value: any) => value.amount)
      const dates = irrData.map((date: any) => new Date(date.date))
      const irrValue = calculateXirr(values, dates)
      irr.grossIrr = irrValue !== 'NA' ? irrValue * 100 : 0
    } else {
      irr.grossIrr = convertRate(xirr(irrData).rate, RateInterval.Year) * 100
    }
  }

  return irr
}