import { create } from 'zustand'
import { configurePersist } from 'zustand-persist'
import { FilterTagValue } from '../app/pages/portfolio/components/FilterMenu'

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: 'root',
})

const persist = _persist as any

export type FilterValue = {
  selectedFilter: FilterTagValue[]
  setFilteredValue: (value: FilterTagValue[]) => Function
  reset: () => void
}

const initialState = {
  selectedFilter: [] as FilterTagValue[],
}

const portfolioFilterStore = create<FilterValue>(
  persist(
    {
      key: 'portfolioFilter',
    },
    (set: any) => ({
      ...initialState,
      setFilteredValue: (filter: FilterTagValue[]) => {
        set((state: any) => ({ selectedFilter: filter }))
      },

      reset: () => {
        set(() => initialState)
      },
    })
  )
)

export const usePortfolioStore = portfolioFilterStore
