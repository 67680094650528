import { useFormik } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import {
  CompanyObject,
  InvestorObject,
  useUpdateCompanyDetailsMutation,
  UpdateCompanyDetailsMutation,
  UpdateCompanyDetailsMutationVariables,
  useGetCompanyWiseDataQuery,
  useGetAllTransactionsQuery,
  CompanyTypes,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import Toast, { ToastType } from '../../../../../utils/toast'
import {
  editCompanyDetailInits,
  editCompanyDetailSchema,
  ICompanyDetails,
} from './EditCompanyDetailsHelper'
import { Modal } from 'react-bootstrap'
import { GlobalContext } from '../../../store/context/globalContext'
import { getCurrency, getSymbolOfCurrency } from '../../../../../utils/CurrencyFormatter'
import { InputBox } from '../../../../../utils/NumberInputBox'

type Props = {
  closeModal: () => void
  investorIds: number[]
  hissaVerified: boolean
  companyData: CompanyObject
  investorData: InvestorObject[]
  isIndirect: boolean
}

function EditCompanyDetailsForm(props: Props) {
  const [data, setData] = useState<ICompanyDetails>(editCompanyDetailInits)
  const [loading, setLoading] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  let date = new Date()
  let maxDate = date.toISOString().split('T')[0]
  const initValues = !props.hissaVerified
    ? {
        companyName: props.companyData.name.toUpperCase() || '',
        cin: props.companyData.cin || '',
        industry: props.companyData.industry || '',
        currentValuation: props.companyData.valuation || 0,
        currentSharePrice: props.companyData.sharePrice || 0,
        currentExchangeRate: 1,
        dba: props.companyData.dba || '',
      }
    : {
        companyName: props.companyData.name.toUpperCase() || '',
        cin: props.companyData.cin || '',
        industry: props.companyData.industry || '',
        dba: props.companyData.dba || '',
        currentValuation: props.companyData.valuation || 0,
        currentSharePrice: props.companyData.sharePrice || 0,
      }

  const formik = useFormik<ICompanyDetails>({
    initialValues: initValues,
    validationSchema: editCompanyDetailSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        props.hissaVerified
          ? mutateEditCompanyDetails.mutate({
              input: {
                cin: values.cin,
                companyName: values.companyName?.toUpperCase() || '',
                industry: values.industry,
                currentSharePrice: Number(values.currentSharePrice),
                currentValuation: Number(values.currentValuation),
                companyType: CompanyTypes.Hissa,
                companyId: Number(props.companyData.id),
                hissaCompanyId: props.companyData.hissaCompanyId,
                dba: values.dba,
                currency: props.companyData.companyCurrency,
                jurisdiction: props.companyData.jurisdiction,
                isIndirect: false,
              },
            })
          : mutateEditCompanyDetails.mutate({
              input: {
                cin: values.cin,
                companyName: values.companyName?.toUpperCase() || '',
                industry: values.industry,
                currentSharePrice: Number(values.currentSharePrice),
                currentValuation: Number(values.currentValuation),
                companyType: props.isIndirect ? CompanyTypes.Indirect : CompanyTypes.Direct,
                companyId: Number(props.companyData.id),
                dba: values.dba,
                currency: props.companyData.companyCurrency,
                jurisdiction: props.companyData.jurisdiction,
                isIndirect: props.isIndirect,
              },
            })
        setLoading(false)
      }, 1000)
    },
  })

  const mutateEditCompanyDetails = useUpdateCompanyDetailsMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: UpdateCompanyDetailsMutation,
        _variables: UpdateCompanyDetailsMutationVariables,
        _context: unknown
      ) => {
        if (props.companyData) {
          const compKey = {
            input: {
              type: props.companyData?.type,
              hissaCompanyId: props.companyData?.hissaCompanyId || '',
              id: Number(props.companyData?.id) || 0,
            },
          }
          const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
          queryClient.invalidateQueries(getCompanyWiseQuery)
        }
        const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()
        queryClient.invalidateQueries(getAllTransactionQuery)
        props.closeModal()
        Toast('Company Details Updated Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  useEffect(() => {
    if (formik.errors) setConfirmVisible(false)
  }, [formik.errors])

  return mutateEditCompanyDetails.isSuccess ? (
    <Navigate
      to='/company/summary'
      state={{
        props: {
          name: formik.values.companyName,
          cin: formik.values.cin,
          isVerified: props.hissaVerified,
        },
      }}
    />
  ) : (
    <form onSubmit={formik.handleSubmit} noValidate className='form p-0'>
      <div className='w-100'>
        {!props.hissaVerified && (
          <div className='row'>
            <div className='fv-row mb-6 '>
              <label className='form-label required'>Company Name</label>

              <InputBox
                isDisabled={false}
                name={'companyName'}
                handleChange={(value: number) => {
                  formik.setFieldValue('companyName', value)
                }}
                setFieldValue={(value: number) => {
                  formik.setFieldValue('companyName', value)
                }}
                placeholder='Enter Company Name'
                currency={userCurrency}
                value={formik.values.companyName}
                type='text'
              />
              <div className='text-danger mt-2'>
                {formik.touched.companyName && formik.errors.companyName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-7'>{formik.errors.companyName}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className='row'>
          <div className='fv-row mb-6 '>
            <label className='form-label'>Company CIN</label>

            <InputBox
              isDisabled={false}
              name={'cin'}
              handleChange={(value: string) => {
                formik.setFieldValue('cin', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('cin', value)
              }}
              placeholder='Enter CIN'
              currency={userCurrency}
              value={formik.values.cin}
              type='text'
            />
            <div className='text-danger mt-2'>
              {formik.touched.cin && formik.errors.cin && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.cin}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='fv-row mb-6 '>
            <label className='form-label '>Doing Business As</label>

            <InputBox
              isDisabled={false}
              name={'dba'}
              handleChange={(value: string) => {
                formik.setFieldValue('dba', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('dba', value)
              }}
              placeholder='Enter DBA'
              currency={userCurrency}
              value={formik.values.dba || ''}
              type='text'
            />
          </div>
        </div>

        <div className='row'>
          <div className='fv-row mb-6 '>
            <label className='form-label'>Industry</label>

            <InputBox
              isDisabled={false}
              name={'industry'}
              handleChange={(value: string) => {
                formik.setFieldValue('industry', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('industry', value)
              }}
              placeholder='Enter Industry Name'
              currency={userCurrency}
              value={formik.values.industry || ''}
              type='text'
            />
            <div className='text-danger mt-2'>
              {formik.touched.industry && formik.errors.industry && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.industry}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        {!props.hissaVerified && (
          <div className='row'>
            <div className='fv-row mb-6 '>
              <label className='form-label '>{`Current Valuation (${getSymbolOfCurrency(
                userCurrency
              )})`}</label>
              <InputBox
                isDisabled={false}
                name={'currentValuation'}
                handleChange={(value: number) => {
                  formik.setFieldValue('currentValuation', value)
                }}
                setFieldValue={(value: number) => {
                  formik.setFieldValue('currentValuation', value)
                }}
                placeholder='Enter Exchange Rate'
                currency={userCurrency}
                isCurrency={true}
                value={formik.values.currentValuation || 0}
                type='number'
              />
              <div className='text-danger mt-2'>
                {formik.touched.currentValuation && formik.errors.currentValuation && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-7'>{formik.errors.currentValuation}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!props.hissaVerified && (
          <div className='row'>
            <div className='fv-row mb-6 '>
              <label className='form-label'>{`Current Share Price (${getSymbolOfCurrency(
                userCurrency
              )})`}</label>

              <InputBox
                isDisabled={false}
                name={'currentSharePrice'}
                handleChange={(value: number) => {
                  formik.setFieldValue('currentSharePrice', value)
                }}
                setFieldValue={(value: number) => {
                  formik.setFieldValue('currentSharePrice', value)
                }}
                placeholder='Enter Current Valuation'
                currency={userCurrency}
                isCurrency={true}
                value={formik.values.currentSharePrice || 1}
                type='number'
              />
              <div className='text-danger mt-2'>
                {formik.touched.currentSharePrice && formik.errors.currentSharePrice && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-7'>{formik.errors.currentSharePrice}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {confirmVisible && (
          <Modal
            id='kt_modal_add_inv'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-450px'
            show={confirmVisible}
            onHide={() => setConfirmVisible(false)}
          >
            <Modal.Body className='shadow border border-primary rounded'>
              <div className='card'>
                <div className='card-body'>
                  <h2 className='fw-bolder text-gray-heading pb-4'>Edit Company Details</h2>
                  <div className='fs-3 pb-6'>Are you sure to edit company details?</div>

                  <div className='fs-7 fw-normal pb-6'>
                    <b>Note : </b> This wiil overwrite the default valuation attached to the company
                  </div>

                  <div className='d-flex col justify-content-between'>
                    <button
                      className='btn btn-lg btn-secondary'
                      onClick={() => setConfirmVisible(false)}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </button>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary'
                      disabled={loading}
                      onClick={() => {
                        formik.handleSubmit()
                      }}
                    >
                      {!loading && <span className='indicator-label'>Confirm</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        <div className='d-flex justify-content-between pt-15'>
          <button className='btn btn-lg btn-secondary me-3' onClick={props.closeModal}>
            Cancel
          </button>
          <button
            type={props.hissaVerified ? 'submit' : 'button'}
            className='btn btn-primary'
            onClick={() => {
              if (!props.hissaVerified && Object.keys(formik.errors).length === 0)
                setConfirmVisible(true)
            }}
          >
            {props.hissaVerified ? (!loading ? 'Update' : '') : 'Edit'}
            {loading && props.hissaVerified && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default EditCompanyDetailsForm
