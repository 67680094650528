import { AllTransactionsType, CompanyTypes, Transaction_Type, WatchlistObject } from "../../../../generated/graphql";
import { ActivityType, ActivityTypeObject } from "./ActivityType";

export function getLatestActivityUtils(allTransactions: AllTransactionsType[], watchlists: WatchlistObject[], startDate: string, endDate: string) {
  const newEndDate = new Date(endDate)
  newEndDate.setDate(newEndDate.getDate() + 1)
  endDate = newEndDate.toISOString()
  const activities: ActivityTypeObject[] = []
  allTransactions.forEach((company) => {
    let id: (string | number) = -1
    if (company.companyData.type === CompanyTypes.Hissa) id = company.companyData.hissaCompanyId || ''
    else id = company.companyData.id
    if (company.transactionData && company.transactionData.length > 0) {
      company.transactionData.forEach((transaction) => {
        if (transaction && transaction.transactionDate >= startDate && transaction.transactionDate <= endDate) {
          if (transaction.transactionType === Transaction_Type.Buy || transaction.transactionType === Transaction_Type.SecondaryBuy) {
            activities.push({
              type: ActivityType.Investment,
              value: transaction.amount,
              date: transaction?.transactionDate,
              companyName: company.companyData.name,
              companyType: company.companyData.type,
              hissaCompanyId: id.toString(),
              companyId: parseInt(id as string) || 0,
              investorName: transaction.investorName as string
            })
          }
          else if (transaction.transactionType === Transaction_Type.Sell) {
            activities.push({
              type: ActivityType.Sell,
              value: Math.abs(transaction.amount),
              date: transaction?.transactionDate,
              companyName: company.companyData.name,
              companyType: company.companyData.type,
              hissaCompanyId: id.toString(),
              companyId: parseInt(id as string) || 0,
              investorName: transaction.investorName as string
            })
          }

        }
      })
    }

    if (company.roundData && company.roundData.length > 0) {
      company.roundData.forEach((round) => {
        if (round && round.roundDate >= startDate && round.roundDate <= endDate) {
          activities.push({
            type: ActivityType.Round_Raised,
            value: round.roundSize,
            date: round.roundDate,
            companyName: company.companyData.name,
            companyType: company.companyData.type,
            hissaCompanyId: id.toString(),
            companyId: parseInt(id as string) || 0
          })
        }
      })
    }
  })

  watchlists.forEach((watchlist) => {
    if (watchlist.createdAt! <= endDate && watchlist.createdAt! >= startDate) {
      activities.push({
        type: ActivityType.Create_Watchlist,
        value: 1,
        date: watchlist.createdAt || new Date().toISOString(),
        watchlistName: watchlist.name || ''
      })
    }
    watchlist.companies?.forEach((company) => {
      if (!activities.map((comp) => comp.hissaCompanyId).includes(company?.companyData.hissaCompanyId || '') && company?.roundData && company?.roundData.length > 0) {
        company?.roundData.forEach((round) => {
          if (round && round?.roundDate >= startDate && round?.roundDate <= endDate) {
            activities.push({
              type: ActivityType.Round_Raised,
              value: round.roundSize,
              date: round.roundDate,
              companyName: company.companyData.name,
              companyType: company.companyData.type,
              hissaCompanyId: company.companyData.hissaCompanyId as string
            })
          }
        })
      }
    })

  })
  return activities.sort((a, b) => b.date.localeCompare(a.date))
}
