import { useContext } from 'react'
import { InvestorObject, TransactionObject } from '../../../../../generated/graphql'
import {
  formatNumber,
  getCurrency,
  getSymbolOfCurrency,
} from '../../../../../utils/CurrencyFormatter'
import { toFix } from '../../../../../utils/ToFix'
import { InvestorParameters } from '../../utils/investmentCalculations'
import { StatisticsWidget4 } from './StatsChart'
import { GlobalContext } from '../../../store/context/globalContext'
import { ShowInfoPopover } from '../../../../../utils/InfoPopover'

type CardType = {
  value: number
  name: string
  indicator: string
  isInteger: boolean
  description?: string
  info?: string
  isCurrency?: boolean
}

export const CompanyGrowthCard = (props: InvestorParameters) => {
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  const cards = generateGrowthCards(props)
  const chartData = generateCurrentValueGraph(
    props.transactionObjects || [],
    props.investorObjects || []
  )

  const rowData = (cards: CardType[]) => {
    return <div className='row p-0 gy-4'>{cardData(cards)}</div>
  }

  const cardData = (cards: CardType[]) => {
    return (
      <>
        <div className='col-6 ps-0 pe-4'>
          <div className='card h-125px p-6 card-rounded d-flex justify-content-start flex-column'>
            <span
              className='ps-2 fw-bold display-8'
              data-toggle='tooltip'
              data-placement='top'
              title={Number(cards[0].value).toLocaleString(userCurrencyFormat)}
            >
              {cards[0].isCurrency && (
                <span className='fs-1'>{getSymbolOfCurrency(userCurrency) + ' '}</span>
              )}
              {formatNumber({
                value: cards[0].value,
                format: userCurrencyFormat,
              })}
              {cards[0].indicator}
            </span>
            <h6 className='text-muted ps-2 pt-0'>
              {cards[0].name}
              {cards[0].info && cards[0].info !== '' && (
                <span>{ShowInfoPopover(cards[0].info)}</span>
              )}
            </h6>
            <p className='fs-8'>{cards[0].description}</p>
          </div>
        </div>
        <div className='col-6 pe-0 ps-4'>
          <div className='card h-125px p-6 card-rounded d-flex justify-content-start flex-column'>
            <span
              className='ps-2 fw-bold display-8'
              data-toggle='tooltip'
              data-placement='top'
              title={Number(cards[1].value).toLocaleString(userCurrencyFormat)}
            >
              {formatNumber({
                value: cards[1].value,
                format: userCurrencyFormat,
              })}
              {cards[1].indicator}
            </span>
            <h6 className='text-muted ps-2 pt-0'>
              {cards[1].name}
              {cards[1].info && cards[0].info !== '' && (
                <span>{ShowInfoPopover(cards[1].info)}</span>
              )}
            </h6>
            <p className='fs-8'>{cards[1].description}</p>
          </div>
        </div>
      </>
    )
  }

  return (
    <div
      className='card h-auto bg-growth-card mb-md-0 mb-9'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='card-title mx-9 mt-7 pb-0'>
        <div className='d-flex flex-row'>
          <span className='text-white fs-1 pt-3 align-bottom'>
            {getSymbolOfCurrency(userCurrency)}
          </span>{' '}
          <span
            className='text-white fw-bold display-6 pe-6 m-0'
            data-toggle='tooltip'
            data-placement='top'
            title={Number(props.unrealizedValue).toLocaleString(userCurrencyFormat)}
          >
            {formatNumber({
              value: props.unrealizedValue,
              format: userCurrencyFormat,
            })}
          </span>
          {/* {props.currentPercentage > 1 ? (
            <GainBadge badgeValue={props.currentPercentage} indicator='%' />
          ) : (
            <LossBadge badgeValue={props.currentPercentage} indicator='%' />
          )} */}
        </div>
        <div className='text-muted text-white fs-8 '>Holding Value</div>
      </div>
      <StatisticsWidget4
        className='card'
        svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
        color='info'
        description='Your Investment'
        change='+256'
        chartData={chartData}
      />
      <h2 className='text-white mx-9 pt-7'>Investment Growth</h2>
      <div className='row justify-content-center m-9 mt-0'>
        {rowData([cards[0], cards[1]])}
        {rowData([cards[2], cards[3]])}
      </div>
    </div>
  )
}

function generateGrowthCards(aggregateData: InvestorParameters) {
  const cardData: CardType[] = [
    {
      value: aggregateData.moic,
      name: 'MOIC',
      indicator: 'x',
      info: 'MOIC (Multiple on Invested Capital) is a metric that measures the total value of an investment, including all returns, divided by the initial investment amount.',
      isInteger: false,
      isCurrency: false,
    },
    {
      value: aggregateData.irr,
      name: 'IRR',
      indicator: '%',
      info: 'IRR (Internal Rate of Return) is a way to calculate the annualized return of an investment that has irregular cash flows over time, such as investments with varying amounts of money added or withdrawn at different times.',
      isInteger: false,
      isCurrency: false,
    },
    {
      value: aggregateData.realizedValue,
      name: 'Realized Value',
      indicator: '',
      info: 'Realized value on investment refers to the actual amount of money an investor has gained or lost from an investment after selling it or receiving dividends or interest payments.',
      isInteger: false,
      isCurrency: true,
    },
    {
      value: aggregateData.holdingPercentage,
      name: 'Current Holding',
      indicator: '%',
      info: "Current holding value of an investment is the market value of the investment at a specific point in time. If some of the investments are in a different currency from the user-selected currency, today's exchange rate will be used for the calculations.",
      isInteger: false,
      isCurrency: false,
    },
  ]

  return cardData
}

export type ChartDataType = {
  label: string
  value: number
  category: string
}

function generateCurrentValueGraph(
  transactionObjects: TransactionObject[],
  investorObjects: InvestorObject[]
) {
  const investorIds: number[] = []
  let chartData: ChartDataType[] = []
  const uniqueDataPointsLabel: { date: string }[] = []
  transactionObjects = transactionObjects.sort((a, b) =>
    a.transactionDate.localeCompare(b.transactionDate)
  )
  transactionObjects.forEach((tx) => {
    if (!uniqueDataPointsLabel.map((obj) => obj.date).includes(tx.transactionDate)) {
      uniqueDataPointsLabel.push({
        date: tx.transactionDate,
      })
      if (!investorIds.includes(Number(tx.investorId))) {
        investorIds.push(Number(tx.investorId))
      }
    }
  })
  uniqueDataPointsLabel.forEach((date) => {
    let value = 0
    const foundInvestorIds: number[] = []
    transactionObjects.forEach((tx) => {
      if (tx.transactionDate === date.date && !foundInvestorIds.includes(Number(tx.investorId))) {
        value += tx.investorsData?.currentValue || 0
        foundInvestorIds.push(Number(tx.investorId))
      }
    })
    const remainingInvestorIds = investorIds.filter((x) => !foundInvestorIds.includes(x))
    if (remainingInvestorIds.length > 0) {
      remainingInvestorIds.forEach((id) => {
        const getTransactionsBeforeDate = transactionObjects.filter(
          (transaction) =>
            transaction.transactionDate < date.date && Number(transaction.investorId) === id
        )
        if (getTransactionsBeforeDate.length > 0) {
          const lastTransaction = getTransactionsBeforeDate[getTransactionsBeforeDate.length - 1]
          value += lastTransaction?.investorsData?.currentValue || 0
        }
      })
    }
    chartData.push({
      label: convertISOToMonthYear(date.date),
      value: toFix(value),
      category: 'Holding Value',
    })
  })
  let currentValue = 0
  investorObjects.forEach((inv) => {
    currentValue += inv.currentValue || 0
  })
  chartData.push({
    category: 'Holding Value',
    value: toFix(currentValue),
    label: convertISOToMonthYear(new Date().toISOString()),
  })
  chartData = chartData.filter(
    (obj, index, self) => index === self.findIndex((o) => o.label === obj.label)
  )
  return chartData
}

function convertISOToMonthYear(isoDateString: string) {
  const date = new Date(isoDateString)
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.getFullYear()
  return `${month}-${year}`
}
