import React, { useEffect, useRef, useState } from 'react'
import Toast, { ToastType } from '../../../../utils/toast'
import {
  useSendNewTermsheetMutation,
  SendNewTermsheetMutation,
  SendNewTermsheetMutationVariables,
  useGetUrlForS3ObjectQuery,
  GetUrlForS3ObjectQuery,
  GetS3UrlForTermsheetQuery,
  useGetS3UrlForTermsheetQuery,
  useGetTermsheetsQuery,
  useGetInvestorsQuery,
  GetInvestorsQuery,
  Investor,
  useGetDealTemplatesQuery,
  GlobalCompanySearchType,
  useGetGlobalCompaniesQuery,
  GetGlobalCompaniesQuery,
  TermsheetInput,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import EmailTermsheet from '../../open-deals/components/emailTermsheet/EmailTermsheet'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useChatbotStore } from '../../../../store/termsheetChatbot'
import { getNameForKey } from '../../../../utils/TermsheetUtils'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { camelize } from '../utils/utils'
import { FieldsType, GroupsType, StepperType } from '../../../../utils/stepper'
import * as Yup from 'yup'
import { Form, Formik, FormikErrors } from 'formik'
import { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor'
import axios from 'axios'
import { useMutation } from 'react-query'
import { convertBase64ToBlob } from '../../../../utils/ExportExcel'
import { HissaAiModal } from '../components/template-builder/HissaAi/HissaAiModal'
import { getInits1 } from '../../open-deals/components/termsheet-issuer/DraftTermsheetView'
import {
  DraftNotes,
  GenerateTermsheet,
  GetCompanies,
  GetInvestors,
  RecommendedFields,
  TermSheetName,
} from '../../open-deals/components/termsheet-issuer/generateTermsheet'
import SyncfusionDocumentEditor from '../utils/SyncfusionDocumentEditor'
import LazyPdfDocument from '../../../../utils/LazyPdfDocument'
import SignatureIframe from '../components/signature-flow/SignatureIframe'
import { getAuth, JwtPayload } from '../../../modules/auth'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import { ImportInvestmentModel } from '../../open-deals/components/termsheet-issuer/ImportInvestmentModel'
import { Buffer } from 'buffer'

type Props = {
  allowedSections?: string[]
  wizardRef?: any
  templateId?: number
  initialValues?: any
  draftTermsheetId?: number
  genericData?: any
}
type MapSchemaTypes = {
  string: string
  boolean: boolean
  number: number
  stringArray: string[]
  objectArray: any[]
}
export type SignerData = {
  name: string
  designation: string
  email: string
  signatureType: string
}
function DocumentIssuePageWrapper({
  allowedSections,
  templateId,
  initialValues,
  draftTermsheetId,
  genericData,
}: Props) {
  const jwt = getAuth()
  const jwtDetails = jwtDecode<JwtPayload>(jwt!)
  const esignApi = process.env.REACT_APP_ESIGN_API_URL
  const invApi = process.env.REACT_APP_API_URL
  const location = useLocation()
  const useChatBot = useChatbotStore()
  const navigate = useNavigate()
  const [aiInits, setAiInits] = useState<any>()
  const [isStepperCollapsed, setIsStepperCollpased] = useState(false)
  const [termsheetId, setTermsheetId] = useState<any>()
  const stepper = useRef<StepperComponent | null>(null)
  const [formikValues, setFormikValues] = useState<any>('')
  const [showShareEmailModal, setShowShareEmailModal] = useState(false)
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const submitBtnRef = useRef<HTMLButtonElement | null>(null)
  const initialStepperErrors: StepperErrors = {}
  const [stepperErrors, setStepperErrors] = useState<StepperErrors>(initialStepperErrors)
  const [fieldValue, setFieldValue] = useState<any>('')
  const [recFields, setRecommendedFields] = useState<any>([])
  const [docType, setDocType] = useState('')
  const [loading, setLoading] = useState(false)
  const [hissaCompanyId, setHissaCompanyId] = useState<string>('')
  const [newPdf, setNewPdf] = useState<any>(' ')
  const [queryEnabled, setQueryEnabled] = useState<boolean>(false)
  const [companyObj, setCompanyObj] = useState<any>()
  const [showSign, setShowSign] = useState<boolean>(false)
  const [previewUrl, setPreviewUrl] = useState<any>(
    'UEsDBBQAAAAIAJtRQ1fyWXzmlgEAAOsDAAAEAAAAc2ZkdMRSy07DMBD8FbRcoypuSwu5IVBPqELiCBzsxE4s5SXHFdAq/86sHSKoxBHhQzK7tndnZn2irve2sUf9ZApPmXcHndCgc8qeT6TCNzeUncgMR8qEWKQJGSToTtZWOUsIcZgu02lxwij784SRZ7HRZ4n2LJMPPWXpIh0T6kP/2rYhkZCbkZmRGiYgAURANaOFWN/w2m7E1bVYLzkNmdj2oMTVbdDoa7Sjh04Wti0XcTGLf5U+vo6vCVXMYQxD6R3TqQrKVpvAZkb9G2UbsQywomx7E2DdAAbkZuRnpGZUGO6If6cjYBF8q2d/UXKIf92CCzwE5bhvv/46nitbmE63TiqbQ4lie/b67VGWmiAHmZIVBNd20yLW5j/iGEIZNKN95xpZo0b7/j2KA7HQLdLVahyTcEfEO/fayEPtLx6lk6WTfXWx61pP3LfwMGgyrZkeAFgw5xgFaWxB5Zs6IOkd7A4z55fEqrk3MIh+fzUvB8x+hTbcKP9lf/21jyLTPmJI139SNe8aHMKo4KxXECTGTwAAAP//AwBQSwECLQAUAAAACACbUUNX8ll85pYBAADrAwAABAAAAAAAAAAAACAAAAAAAAAAc2ZkdFBLBQYAAAAAAQABADIAAAC4AQAAAAA='
  )
  const [sfdt, setSfdt] = useState<any>(
    'UEsDBBQAAAAIAJtRQ1fyWXzmlgEAAOsDAAAEAAAAc2ZkdMRSy07DMBD8FbRcoypuSwu5IVBPqELiCBzsxE4s5SXHFdAq/86sHSKoxBHhQzK7tndnZn2irve2sUf9ZApPmXcHndCgc8qeT6TCNzeUncgMR8qEWKQJGSToTtZWOUsIcZgu02lxwij784SRZ7HRZ4n2LJMPPWXpIh0T6kP/2rYhkZCbkZmRGiYgAURANaOFWN/w2m7E1bVYLzkNmdj2oMTVbdDoa7Sjh04Wti0XcTGLf5U+vo6vCVXMYQxD6R3TqQrKVpvAZkb9G2UbsQywomx7E2DdAAbkZuRnpGZUGO6If6cjYBF8q2d/UXKIf92CCzwE5bhvv/46nitbmE63TiqbQ4lie/b67VGWmiAHmZIVBNd20yLW5j/iGEIZNKN95xpZo0b7/j2KA7HQLdLVahyTcEfEO/fayEPtLx6lk6WTfXWx61pP3LfwMGgyrZkeAFgw5xgFaWxB5Zs6IOkd7A4z55fEqrk3MIh+fzUvB8x+hTbcKP9lf/21jyLTPmJI139SNe8aHMKo4KxXECTGTwAAAP//AwBQSwECLQAUAAAACACbUUNX8ll85pYBAADrAwAABAAAAAAAAAAAACAAAAAAAAAAc2ZkdFBLBQYAAAAAAQABADIAAAC4AQAAAAA='
  )
  const [isEdit, setIsEdit] = useState(false)
  const [templateName, setTemplateName] = useState<string>('My Document')
  const [currentStepIndex, setCurrentStepIndex] = useState<number | undefined>()
  const [showFreeTextModal, setShowFreeTextModal] = useState<boolean>(useChatBot.isOpen || false)
  const [signDialog, setSignDialog] = useState<boolean>(false)
  const [eSignId, setESignId] = useState<string>('')
  const [iFrameToken, setIFrameToken] = useState<string>('')
  const [companyName, setCompanyName] = useState('')
  const [isImportModel, setIsImportModel] = useState(false)
  const [allCompanies, setAllCompanies] = useState<GlobalCompanySearchType[]>([])
  const stepperValues: string[] = []
  const initialObj: any = {}
  const errorObj: any = {}
  const allFields: any[] = []
  const init: TermsheetType = {}
  const validationSchema: any[] = []
  const recommendedFields: any[] = []
  const steppers: any = {}
  const mandatoryFields = [
    'termsheetName',
    'investorName',
    'investorId',
    'companyName',
    'companyId',
  ]
  let container = useRef<DocumentEditorContainerComponent | null>(null)
  const detailedObj: any = {}
  type MapSchema<T extends Record<string, keyof MapSchemaTypes>> = {
    -readonly [K in keyof T]: MapSchemaTypes[T[K]]
  }
  function asSchema<T extends Record<string, keyof MapSchemaTypes>>(template: T): T {
    return template
  }
  function isNumeric(n: any) {
    return !isNaN(n)
  }
  const personSchema = asSchema(initialObj)
  const errorSchema = asSchema(errorObj)
  type TermsheetType = MapSchema<typeof personSchema>
  type StepperErrors = MapSchema<typeof errorSchema>
  const templateData = genericData || (location.state as any)?.genericData
  const templateDetails = JSON.parse(templateData.jsonData)[0].templateDetails
  const filledValue: string[] = []
  if (aiInits !== undefined) {
    Object.keys(aiInits).map((obj, index) => {
      if (aiInits[obj] !== '' && aiInits[obj] != 0) {
        filledValue.push(obj)
      }
    })
  }
  const jsonData = JSON.parse(templateData.jsonData)
  useEffect(() => {
    setDocType(jsonData[0].type)
  }, [jsonData])
  useEffect(() => {
    const unloadCallback = (event: { preventDefault: () => void; returnValue: string }) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }
    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [])
  const { data: globalCompanies } = useGetGlobalCompaniesQuery<GetGlobalCompaniesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  useEffect(() => {
    if (globalCompanies && globalCompanies.globalCompaniesSearch) {
      setAllCompanies(
        _.cloneDeep(globalCompanies.globalCompaniesSearch) as GlobalCompanySearchType[]
      )
    }
  }, [globalCompanies])
  useEffect(() => {
    setTimeout(() => {
      !initialValues && setShowFreeTextModal(true)
    }, 1000)
  }, [])
  jsonData.forEach((sections: StepperType) => {
    const groupObj: any = {}
    const fieldList: string[] = []
    if (Number(sections.sequenceNumber) > 0) {
      allFields.push({
        stepName: sections.stepname,
        step: sections.sequenceNumber,
        fields: [...sections.groups],
      })
      sections.groups.forEach((group: GroupsType) => {
        group.fields.forEach((field: FieldsType) => {
          if (sections.condition !== undefined) {
            const conditionValue = sections.condition && sections.condition.value
            if ((formikValues !== '' && formikValues === conditionValue) || formikValues === '') {
              fieldList.push(field.key)
              if (field.key !== undefined) {
                if (field.value === 'boolean') {
                  init[field.key] = false
                  groupObj[field.key] = Yup.boolean().notRequired()
                }

                if (field.value === 'string' && field.type === 'radio') {
                  init[field.key] = field.values && field.values[0]
                  field.required === 'true'
                    ? (groupObj[field.key] = Yup.string().required().label(field.name))
                    : (groupObj[field.key] = Yup.string().notRequired())
                } else if (field.value === 'string') {
                  init[field.key] = ''
                  const name = field.condition && field.condition.name
                  const value = field.condition && field.condition.value
                  const actualValue = init[name]
                  field.required === 'true' && field.condition
                    ? (groupObj[field.key] = Yup.string().when(name, {
                        is: String(actualValue) !== String(value),
                        then: Yup.string().required().label(field.name),
                        otherwise: Yup.string().notRequired(),
                      }))
                    : field.required === 'true'
                    ? (groupObj[field.key] = Yup.string().required().label(field.name))
                    : (groupObj[field.key] = Yup.string().notRequired())
                }

                if (field.value === 'number') {
                  if (field.value === 'number' && field.type === 'numberINR') {
                    init[field.key] = '0.00'
                    const name = field.condition && field.condition.name
                    const value = field.condition && field.condition.value
                    const actualValue = init[name]
                    field.required === 'true' && field.condition
                      ? (groupObj[field.key] = Yup.number().when(name, {
                          is: String(actualValue) !== String(value),
                          then: Yup.number()
                            .required()
                            .label(field.name)
                            .moreThan(Number(field.min))
                            .max(
                              isNumeric(field.max) === true
                                ? field.max
                                : fieldValue[field.max || '']
                            ),
                          otherwise: Yup.number().notRequired(),
                        }))
                      : field.required === 'true'
                      ? (groupObj[field.key] = Yup.number()
                          .required()
                          .label(field.name)
                          .moreThan(Number(field.min))
                          .max(
                            isNumeric(field.max) === true ? field.max : fieldValue[field.max || '']
                          ))
                      : (groupObj[field.key] = Yup.number().notRequired())
                  } else {
                    init[field.key] = 0
                    const name = field.condition && field.condition.name
                    const value = field.condition && field.condition.value
                    const actualValue = init[name]
                    field.required === 'true' && field.condition
                      ? (groupObj[field.key] = Yup.number().when(name, {
                          is: String(actualValue) !== String(value),
                          then: Yup.number().required().label(field.name).moreThan(0),
                          otherwise: Yup.number().notRequired(),
                        }))
                      : field.required === 'true'
                      ? (groupObj[field.key] = Yup.number()
                          .required()
                          .label(field.name)
                          .moreThan(0))
                      : (groupObj[field.key] = Yup.number().notRequired())
                  }
                }

                if (field.value === 'stringArray') {
                  init[field.key] = [field.values && field.values[0]]
                  field.required === 'true'
                    ? (groupObj[field.key] = Yup.array()
                        .required()
                        .min(1, 'Select at least one option')
                        .label(field.name))
                    : (groupObj[field.key] = Yup.array().notRequired())
                }
                if (field.value === 'objectArray') init[field.key] = []
              }
            } else {
              if (Object.keys(init).includes(field.key)) {
                delete init[field.key]
              }
            }
          } else {
            if (sections.sequenceNumber == 1) {
              mandatoryFields.forEach((field: string) => {
                field === 'termsheetName'
                  ? (init['termsheetName'] =
                      jsonData[0].type === 'Termsheet'
                        ? 'My Termsheet'
                        : jsonData[0].type === 'NDA'
                        ? 'Non-Disclosure Agreement'
                        : 'My Document')
                  : (init[field] = '')
                if (!fieldList.includes(field)) fieldList.push(field)
              })
              groupObj['termsheetName'] = Yup.string()
                .required()
                .label('Document Name')
                .min(3, 'Template name must be greater than 3 characters')
              groupObj['investorId'] = Yup.string().label('Investor/Fund').optional()
              groupObj['companyName'] = Yup.string().label('Company Name').optional()
            }

            if (field.key !== undefined) {
              fieldList.push(field.key)
              if (field.value === 'boolean') {
                init[field.key] = false
                groupObj[field.key] = Yup.boolean().notRequired()
              }

              if (field.value === 'string' && field.type === 'radio') {
                init[field.key] = field.values && field.values[0]
                field.required === 'true'
                  ? (groupObj[field.key] = Yup.string().required().label(field.name))
                  : (groupObj[field.key] = Yup.string().notRequired())
              } else if (field.value === 'string') {
                init[field.key] = ''
                const name = field.condition && field.condition.name
                const value = field.condition && field.condition.value
                const actualValue = init[name]
                field.required === 'true' && field.condition
                  ? (groupObj[field.key] = Yup.string().when(name, {
                      is: String(actualValue) !== String(value),
                      then: Yup.string().required().label(field.name),
                      otherwise: Yup.string().notRequired(),
                    }))
                  : field.required === 'true'
                  ? (groupObj[field.key] = Yup.string().required().label(field.name))
                  : (groupObj[field.key] = Yup.string().notRequired())
              }

              if (field.value === 'number') {
                if (field.value === 'number' && field.type === 'numberINR') {
                  init[field.key] = '0.00'
                  const name = field.condition && field.condition.name
                  const value = field.condition && field.condition.value
                  const actualValue = init[name]
                  field.required === 'true' && field.condition
                    ? (groupObj[field.key] = Yup.number().when(name, {
                        is: String(actualValue) !== String(value),
                        then: Yup.number()
                          .required()
                          .label(field.name)
                          .moreThan(Number(field.min))
                          .max(
                            isNumeric(field.max) === true ? field.max : fieldValue[field.max || '']
                          ),
                        otherwise: Yup.number().notRequired(),
                      }))
                    : field.required === 'true'
                    ? (groupObj[field.key] = Yup.number()
                        .required()
                        .label(field.name)
                        .moreThan(Number(field.min))
                        .max(
                          isNumeric(field.max) === true ? field.max : fieldValue[field.max || '']
                        ))
                    : (groupObj[field.key] = Yup.number().notRequired())
                } else {
                  init[field.key] = 0
                  const name = field.condition && field.condition.name
                  const value = field.condition && field.condition.value
                  const actualValue = init[name]
                  field.required === 'true' && field.condition
                    ? (groupObj[field.key] = Yup.number().when(name, {
                        is: String(actualValue) !== String(value),
                        then: Yup.number().required().label(field.name).moreThan(0),
                        otherwise: Yup.number().notRequired(),
                      }))
                    : field.required === 'true'
                    ? (groupObj[field.key] = Yup.number().required().label(field.name).moreThan(0))
                    : (groupObj[field.key] = Yup.number().notRequired())
                }
              }

              if (field.value === 'stringArray') {
                init[field.key] = [field.values && field.values[0]]
                field.required === 'true'
                  ? (groupObj[field.key] = Yup.array()
                      .required()
                      .min(1, 'Select at least one option')
                      .label(field.name))
                  : (groupObj[field.key] = Yup.array().notRequired())
              }
              if (field.value === 'objectArray') init[field.key] = []
            }
          }
        })
      })
    }
    if (fieldList.length > 0) detailedObj[camelize(sections.stepname)] = fieldList
    if (Object.keys(groupObj).length > 0) validationSchema.push(Yup.object(groupObj))
  })
  const newSchema = [...validationSchema.slice(0, -1)]
  newSchema.push(validationSchema.slice(-1)[0])
  const [currentSchema, setCurrentSchema] = useState<any>(newSchema[0])
  const getTermsheetDraftData = (values: TermsheetType) => {
    const termsheetData: any = {
      investorId: parseInt(values?.investorId === null ? '0' : values?.investorId.toString()) || 0,
      investorName: values?.investorName,
      templateId: Number(templateData.id),
      termsheetName: values?.termsheetName,
      companyName: (companyObj && companyObj.companyName) || initialValues?.companyName || '',
      cin: (companyObj && companyObj.companyId) || values?.cin || initialValues?.cin || ' ',
      roundSize:
        values?.roundSize !== undefined ? Math.round(Number(values?.roundSize)).toString() : 0,
      valuation:
        values.isPriced !== undefined
          ? values?.isPriced === 'No'
            ? Math.round(Number(values?.floorValuation)).toString() || 0
            : Math.round(Number(values?.valuation)).toString() || 0
          : values?.valuation !== undefined
          ? Math.round(Number(values?.valuation)).toString()
          : 0,
      expiryDate: values?.expiryDate && values?.expiryDate !== '' ? values.expiryDate : '',
      investmentAmount:
        values?.investmentAmount !== undefined ? Math.round(values?.investmentAmount) : 0,
      sections: {},
      additionalSections: {},
      companyId:
        values.companyId ||
        (companyObj && companyObj.hissaCompanyId) ||
        initialValues?.hissaCompanyId ||
        '',
      type: docType,
    }

    jsonData.forEach((section: StepperType) => {
      if (Number(section.sequenceNumber) > 0) {
        const stepName = camelize(section.stepname)
        if (!Object.keys(termsheetData.sections).includes(stepName)) {
          termsheetData.sections[stepName] = {}
        }

        section.groups.forEach((group: GroupsType) => {
          group.fields.forEach((field: FieldsType) => {
            if (section.condition !== undefined) {
              if (values[section.condition.name] === section.condition.value) {
                if (field.condition !== undefined) {
                  if (String(values[field.condition.name]) === String(field.condition.value))
                    termsheetData.sections[stepName][field.key] = values[field.key]
                } else {
                  termsheetData.sections[stepName][field.key] = values[field.key]
                }
              }
            } else {
              if (section.sequenceNumber == 1) {
                mandatoryFields.forEach(
                  (element: string) => (termsheetData.sections[stepName][element] = values[element])
                )
              }
              if (field.condition !== undefined) {
                if (String(values[field.condition.name]) === String(field.condition.value))
                  termsheetData.sections[stepName][field.key] = values[field.key]
              } else {
                termsheetData.sections[stepName][field.key] = values[field.key]
              }
            }
          })
        })
      }
    })

    return termsheetData
  }
  const getTermsheetData = (values: TermsheetType) => {
    const termsheetData: any = {
      investorId: parseInt(values?.investorId === null ? '0' : values?.investorId.toString()),
      investorName: values?.investorName,
      templateId: Number(templateData.id),
      termsheetName: values?.termsheetName,
      companyName: (companyObj && companyObj.companyName) || initialValues?.companyName,
      cin: values?.cin || (companyObj && companyObj.companyId) || initialValues?.cin,
      roundSize:
        values?.roundSize !== undefined ? Math.round(Number(values?.roundSize)).toString() : 0,
      valuation:
        values.isPriced !== undefined
          ? values?.isPriced === 'No'
            ? Math.round(Number(values?.floorValuation)).toString() || 0
            : Math.round(Number(values?.valuation)).toString() || 0
          : values?.valuation !== undefined
          ? Math.round(Number(values?.valuation)).toString()
          : 0,
      expiryDate: values?.expiryDate && values?.expiryDate !== '' ? values.expiryDate : '',
      investmentAmount:
        values?.investmentAmount !== undefined ? Math.round(values?.investmentAmount) : 0,
      additionalSections: {},
      sections: {},
      companyId:
        values.companyId ||
        (companyObj && companyObj.hissaCompanyId) ||
        initialValues?.hissaCompanyId,
      type: docType,
      eSignId: eSignId,
    }

    jsonData.forEach((section: StepperType) => {
      if (Number(section.sequenceNumber) > 0) {
        if (section.stepname !== 'Details') {
          const stepName = camelize(section.stepname)
          if (!Object.keys(termsheetData.sections).includes(stepName)) {
            termsheetData.sections[stepName] = {}
          }

          section.groups.forEach((group: GroupsType) => {
            group.fields.forEach((field: FieldsType) => {
              if (section.condition !== undefined) {
                if (values[section.condition.name] === section.condition.value) {
                  if (field.condition !== undefined) {
                    if (String(values[field.condition.name]) === String(field.condition.value))
                      termsheetData.sections[stepName][field.key] = values[field.key]
                  } else {
                    termsheetData.sections[stepName][field.key] = values[field.key]
                  }
                }
              } else {
                if (section.sequenceNumber == 1) {
                  mandatoryFields.forEach(
                    (element: string) =>
                      (termsheetData.sections[stepName][element] = values[element])
                  )
                }
                if (field.condition !== undefined) {
                  if (String(values[field.condition.name]) === String(field.condition.value))
                    termsheetData.sections[stepName][field.key] = values[field.key]
                } else {
                  termsheetData.sections[stepName][field.key] = values[field.key]
                }
              }
            })
          })
        }
      }
    })

    return termsheetData
  }
  useEffect(() => {
    setCurrentStepIndex(stepper.current?.currentStepIndex)
  }, [stepper.current?.currentStepIndex])
  useEffect(() => {
    setShowFreeTextModal(useChatBot.isOpen)
  }, [useChatBot.isOpen])
  const reloadStepper = () => {
    if (StepperComponent.hasInstace(stepperRef.current as HTMLDivElement))
      StepperComponent.destroy(stepperRef.current as HTMLDivElement)
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  useEffect(() => {
    reloadStepper()
  }, [allowedSections])
  useEffect(() => {
    if (draftTermsheetId) setTermsheetId(draftTermsheetId)
  }, [draftTermsheetId])
  useEffect(() => {
    reloadStepper()
  }, [aiInits])
  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef, jsonData])
  const handleFreeTextModalClose = () => {
    setShowFreeTextModal(false)
    useChatBot.setIsOpen(false)
  }
  const NewInstance = axios.create({
    // Configuration
    baseURL: '',
    timeout: 8000,
    headers: {},
  })
  const convertEditedDocxToPdf = async () => {
    const docxBlob = await container.current?.documentEditor
      .saveAsBlob('Docx')
      .then((blob: Blob) => {
        return blob
      })

    if (docxBlob) {
      const uploadFile = await NewInstance.put(
        getTermsheetUrl.data?.getTermsheetUrlPath?.presignedDocUrl || '',
        docxBlob
      )
      const arrayBuffer = await docxBlob.arrayBuffer()
      const file = Buffer.from(arrayBuffer)
      const pdfB64 = await axios
        .post(`${process.env.REACT_APP_API_URL}/docToPdf`, { file: file }, {})
        .then((res) => {
          return res.data.base64
        })
        .catch((err) => {
          return err
        })
      const pdfBlob = await convertBase64ToBlob(pdfB64, 'application/pdf')
      if (pdfBlob) {
        const uploadFile = await NewInstance.put(
          getTermsheetUrl.data?.getTermsheetUrlPath?.presignedUrl || '',
          pdfBlob
        )
        let base64 = pdfB64
        if (!base64.startsWith('data:application/pdf;base64,'))
          base64 = `data:application/pdf;base64,${base64}`
        setNewPdf(base64)
      }
    }
  }
  const handleSendDraftTermsheet = (termsheet: TermsheetInput) => {
    mutateSendDraftTermsheet &&
      mutateSendDraftTermsheet?.mutate({ input: termsheet, sendImmediately: false })
  }
  const handleSendTermsheet = (termsheet: any) => {
    submitBtnRef.current?.setAttribute('data-kt-indicator', 'on')
    mutateSendTermsheet.mutate({ input: termsheet, sendImmediately: true })
  }
  const handleSignPopupClose = (values: any) => {
    setSignDialog(!signDialog)
    if (eSignId !== '') {
      const headers = { Authorization: jwt !== undefined ? jwt : '' }
      axios
        .get(`${esignApi}/admin/status?id=${eSignId}`, { headers })
        .then(async (data) => {
          if (Object.keys(data?.data?.data?.signers).length > 0) {
            const termsheet = getTermsheetData(values)
            termsheet.fileUrl = getTermsheetUrl.data?.getTermsheetUrlPath?.urlPath || ''
            termsheet.uploadPdfURL = getTermsheetUrl.data?.getTermsheetUrlPath?.urlPath || ''
            termsheet.docURL = getTermsheetUrl.data?.getTermsheetUrlPath?.docUrlPath || ''
            termsheet.status = 'Pending Signature'
            termsheet.eSignId = eSignId
            if (termsheetId) {
              termsheet.termsheetId = termsheetId
            }
            handleSendTermsheet(termsheet)
          }
        })
        .catch((err) => {
          setLoading(false)
          console.error(err)
        })
    }
    queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
  }
  const { data } = useGetInvestorsQuery<GetInvestorsQuery, Error>(graphqlRequestClient, {}, {})
  const investorList = data?.investors.map((investor: Investor) => {
    return {
      investorId: investor.id,
      investorName: investor.name,
    }
  })
  function getKeys(element: string) {
    let value = ''
    Object.keys(detailedObj).forEach((key: string) => {
      if (detailedObj[key].includes(element)) value = key
    })
    return value
  }
  const handleStepperErrors = (errors: FormikErrors<TermsheetType>) => {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((keyName: string) => {
        const field = getKeys(keyName)
        if (errors[keyName]) {
          const stepperError = stepperErrors
          stepperError[field] = true
          setStepperErrors(stepperError)
        }
      })
    }
    Object.keys(detailedObj).forEach((key: string) => {
      if (Object.keys(errors).length === 0) {
        const stepperError = stepperErrors
        stepperError[key] = false
        setStepperErrors(stepperError)
      }
    })
  }
  const onLoadDefault = () => {
    const defaultDocument = {
      sfdt: sfdt,
    }
    container.current?.documentEditor.open(JSON.stringify(defaultDocument))
  }
  useEffect(() => {
    onLoadDefault()
  }, [sfdt])

  const onFileChange = async (e: any) => {
    const ab = await convertBase64ToBlob(e, 'docx')
    let formData = new FormData()
    formData.append('files', ab)
    const fetchOptions = {
      method: 'POST',
      body: formData,
    }
    await fetch('https://syncfusion-api.hissa.com/api/documenteditor/Import', fetchOptions)
      .then((response) => {
        if (response.status === 200 || response.status === 304) {
          return response.json()
        } else {
          throw new Error('Request failed with status: ' + response.status)
        }
      })
      .then((data) => {
        setSfdt(data.sfdt)
      })
      .catch((error) => {
        console.error('Fetch error:', error)
      })
    onLoadDefault()
  }
  const goTo = (step: number) => {
    if (!stepper.current) {
      return
    }

    stepper.current.goto(step)
    setCurrentSchema(newSchema[stepper.current.currentStepIndex - 1])
  }
  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(newSchema[stepper.current.currentStepIndex - 1])

    setCurrentStepIndex(stepper.current.currentStepIndex)
  }
  const submitStep = async (values: TermsheetType) => {
    if (!stepper.current) {
      return
    }
    allFields.forEach((section: any) => {
      section.fields.forEach((fields: any) => {
        fields.fields.forEach((field: any) => {
          if (field?.recommended === 'true') {
            const value = values[field.key]
            if (
              (field.type === 'textfield' && (value === '' || value === 0)) ||
              (field.type === 'numberINR' && (value === '0.00' || value === 0 || value === ''))
            ) {
              recommendedFields.push({
                stepName: section.stepName,
                key: field.key,
                name: field.name,
                type: field.type,
              })
            }
          }
        })
      })
    })
    setRecommendedFields(recommendedFields)
    allFields.forEach((section: any) => {
      if (stepper.current?.currentStepIndex === Number(section.step)) {
        section.fields.forEach((fields: any) => {
          fields.fields.forEach((field: any) => {
            if (field?.recommended === 'true') {
              const value = values[field.key]
              if (
                (field.type === 'textfield' && (value === '' || value === 0)) ||
                (field.type === 'numberINR' && (value === '0.00' || value === 0 || value === ''))
              ) {
                Toast(`It is recommended to fill ${field.name}`, ToastType.warning)
              }
            }
          })
        })
      }
    })
    setCurrentSchema(newSchema[Number(stepper.current.currentStepIndex)])
    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 1) {
        setLoading(true)
        await convertEditedDocxToPdf()
        const termsheet = getTermsheetDraftData(values)
        termsheet.status = 'Draft'
        termsheet.fileUrl = getTermsheetUrl.data?.getTermsheetUrlPath?.urlPath || ''
        termsheet.docURL = getTermsheetUrl.data?.getTermsheetUrlPath?.docUrlPath || ''
        if (termsheetId) {
          termsheet.termsheetId = termsheetId
        }
        handleSendDraftTermsheet(termsheet)
        setShowSign(true)
        stepper.current.goNext()
        setIsStepperCollpased(true)
        setLoading(false)
      } else if (stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 2) {
        setLoading(true)
        setShowSign(false)
        const termsheet = getTermsheetDraftData(values)
        if (termsheetId) {
          termsheet.termsheetId = termsheetId
        }
        handleSendDraftTermsheet(termsheet)
        setPreviewUrl('')
        setHissaCompanyId(values.hissaCompanyId)
        setQueryEnabled(true)
        const variablesList: any = {}
        values['investorDetails'] = [
          {
            name: values.investorName,
            security: values.investor1SecurityType ? values.investor1SecurityType : undefined,
            conversionRatio: values.conversionRatio1 ? values.conversionRatio1 : undefined,
            email: values.investorEmail ? values.investorEmail : undefined,
            address: values.investorAddress ? values.investorAddress : undefined,
            nominalEquity: values?.nominalEquityShares1 ? values?.nominalEquityShares1 : 0,
            shares: [
              values?.investor1NoOfShares1 !== '' ? Math.round(values?.investor1NoOfShares1) : 0,
              values?.investor1NoOfShares2 !== '' ? Math.round(values?.investor1NoOfShares2) : 0,
            ],
            tranches: [
              values?.investor1tranche1 !== undefined ? Math.round(values?.investor1tranche1) : 0,
              values?.investor1tranche2 !== undefined ? Math.round(values?.investor1tranche2) : 0,
              values?.investor1tranche3 !== undefined ? Math.round(values?.investor1tranche3) : 0,
            ],
          },
          values?.investorName2 &&
          (values.investor2SecurityType ||
            values.conversionRatio2 ||
            values.investor2Email ||
            values.investor2Address ||
            values.investor2tranche1 ||
            values.investor2tranche2 ||
            values.investor2tranche3)
            ? {
                name: values?.investorName2 ? values.investorName2 : undefined,
                security: values.investor1SecurityType ? values.investor1SecurityType : undefined,
                conversionRatio: values.conversionRatio2 ? values.conversionRatio2 : undefined,
                email: values?.investor2Email ? values.investor2Email : undefined,
                address: values.investor2Address ? values.investor2Address : undefined,
                nominalEquity: values?.nominalEquityShares2 ? values?.nominalEquityShares2 : 0,
                shares: [
                  values?.investor2NoOfShares1 !== ''
                    ? Math.round(values?.investor2NoOfShares1)
                    : 0,
                  values?.investor2NoOfShares2 !== ''
                    ? Math.round(values?.investor2NoOfShares2)
                    : 0,
                ],
                tranches:
                  values?.investor2tranche1 !== '0.00'
                    ? [
                        values?.investor2tranche1 !== undefined
                          ? Math.round(values?.investor2tranche1)
                          : 0,
                        values?.investor2tranche2 !== undefined
                          ? Math.round(values?.investor2tranche2)
                          : 0,
                        values?.investor2tranche3 !== undefined
                          ? Math.round(values?.investor2tranche3)
                          : 0,
                      ]
                    : undefined,
              }
            : undefined,
          values?.investorName3 &&
          (values.investor3SecurityType ||
            values.conversionRatio3 ||
            values.investor3Email ||
            values.investor3Address ||
            values.investor3tranche1 ||
            values.investor3tranche2 ||
            values.investor3tranche3)
            ? {
                name: values?.investorName3 ? values.investorName3 : undefined,
                security: values.investor1SecurityType ? values.investor1SecurityType : undefined,
                conversionRatio: values.conversionRatio3 ? values.conversionRatio3 : undefined,
                email: values?.investor3Email ? values.investor3Email : undefined,
                address: values.investor3Address ? values.investor3Address : undefined,
                nominalEquity: values?.nominalEquityShares3 ? values?.nominalEquityShares3 : 0,
                shares: [
                  values?.investor3NoOfShares1 !== ''
                    ? Math.round(values?.investor3NoOfShares1)
                    : 0,
                  values?.investor3NoOfShares2 !== ''
                    ? Math.round(values?.investor3NoOfShares2)
                    : 0,
                ],
                tranches:
                  values?.investor3tranche1 !== '0.00'
                    ? [
                        values?.investor3tranche1 !== undefined
                          ? Math.round(values?.investor3tranche1)
                          : 0,
                        values?.investor3tranche2 !== undefined
                          ? Math.round(values?.investor3tranche2)
                          : 0,
                        values?.investor3tranche3 !== undefined
                          ? Math.round(values?.investor3tranche3)
                          : 0,
                      ]
                    : undefined,
              }
            : undefined,
          values?.investorName4 &&
          (values.investor4SecurityType ||
            values.conversionRatio4 ||
            values.investor4Email ||
            values.investor4Address ||
            values.investor4tranche1 ||
            values.investor4tranche2 ||
            values.investor4tranche3)
            ? {
                name: values?.investorName4 ? values.investorName4 : undefined,
                security: values.investor1SecurityType ? values.investor1SecurityType : undefined,
                conversionRatio: values.conversionRatio4 ? values.conversionRatio4 : undefined,
                email: values?.investor4Email ? values.investor4Email : undefined,
                address: values.investor4Address ? values.investor4Address : undefined,
                nominalEquity: values?.nominalEquityShares4 ? values?.nominalEquityShares4 : 0,
                shares: [
                  values?.investor4NoOfShares1 !== ''
                    ? Math.round(values?.investor4NoOfShares1)
                    : 0,
                  values?.investor4NoOfShares2 !== ''
                    ? Math.round(values?.investor4NoOfShares2)
                    : 0,
                ],
                tranches:
                  values?.investor4tranche1 !== '0.00'
                    ? [
                        values?.investor4tranche1 !== undefined
                          ? Math.round(values?.investor4tranche1)
                          : 0,
                        values?.investor4tranche2 !== undefined
                          ? Math.round(values?.investor4tranche2)
                          : 0,
                        values?.investor4tranche3 !== undefined
                          ? Math.round(values?.investor4tranche3)
                          : 0,
                      ]
                    : undefined,
              }
            : undefined,
          values?.investorName5 &&
          (values.investor5SecurityType ||
            values.conversionRatio5 ||
            values.investor5Email ||
            values.investor5Address ||
            values.investor5tranche1 ||
            values.investor5tranche2 ||
            values.investor5tranche3)
            ? {
                name: values?.investorName5 ? values.investorName5 : undefined,
                security: values.investor1SecurityType ? values.investor1SecurityType : undefined,
                conversionRatio: values.conversionRatio5 ? values.conversionRatio5 : undefined,
                email: values?.investor5Email ? values.investor5Email : undefined,
                address: values.investor5Address ? values.investor5Address : undefined,
                nominalEquity: values?.nominalEquityShares5 ? values?.nominalEquityShares5 : 0,
                tranches:
                  values?.investor5tranche1 !== '0.00'
                    ? [
                        values?.investor5tranche1 !== undefined
                          ? Math.round(values?.investor5tranche1)
                          : 0,
                        values?.investor5tranche2 !== undefined
                          ? Math.round(values?.investor5tranche2)
                          : 0,
                        values?.investor5tranche3 !== undefined
                          ? Math.round(values?.investor5tranche3)
                          : 0,
                      ]
                    : undefined,
              }
            : undefined,
        ]
        if (values['keyManInsurance'] !== 'at least INR ') {
          values['keyManInsuranceadditional'] = 0
        }
        jsonData.forEach((sections: StepperType) => {
          if (Number(sections.sequenceNumber) > 0) {
            sections.groups.forEach((group: GroupsType) => {
              group.fields.forEach((field: FieldsType) => {
                if (sections.condition !== undefined) {
                  if (values[sections.condition.name] === sections.condition.value) {
                    if (field.condition !== undefined) {
                      if (String(values[field.condition.name]) === String(field.condition.value))
                        variablesList[field.key] = values[field.key].toString()
                    } else {
                      variablesList[field.key] = values[field.key].toString()
                    }
                  }
                } else {
                  if (sections.sequenceNumber == 1) {
                    mandatoryFields.forEach((element: string) => {
                      if (
                        !Object.keys(variablesList).find(
                          (item: any) => item.variableName === element
                        )
                      )
                        variablesList[element] =
                          (values[element] && values[element].toString()) || ''
                    })
                  }
                  if (field.condition !== undefined) {
                    if (String(values[field.condition.name]) === String(field.condition.value))
                      variablesList[field.key] = values[field.key].toString()
                  } else {
                    variablesList[field.key] = values[field.key].toString()
                  }
                }
              })
            })
          }
        })
        variablesList['investorDetails'] = termsheet.investorDetails
        usePreviewTermsheetMutation.mutate({
          companyId: values.hissaCompanyId,
          templateName: 'Termsheet',
          contextVariables: [],
          investorId: parseInt(values?.investorId === null ? '0' : values?.investorId.toString()),
          variablesList: values,
          templateId: Number(templateData.id),
        })
        stepper.current.goNext()
        setIsStepperCollpased(true)
      } else if (
        stepper.current.currentStepIndex === stepper.current.totatStepsNumber - 3 &&
        (stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber + 1 ||
          stepper.current?.currentStepIndex === 0 ||
          stepper.current?.currentStepIndex === undefined)
      ) {
        setLoading(true)
        setShowSign(false)
        const termsheet = getTermsheetDraftData(values)
        termsheet.status = 'Draft'
        if (termsheetId) {
          termsheet.termsheetId = termsheetId
        }
        handleSendDraftTermsheet(termsheet)
        stepper.current.goNext()
        setLoading(false)
      } else if (
        stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber + 1 ||
        stepper.current?.currentStepIndex === 0 ||
        stepper.current?.currentStepIndex === undefined
      ) {
        setShowSign(false)
        const termsheet = getTermsheetDraftData(values)
        termsheet.status = 'Draft'
        if (termsheetId) {
          termsheet.termsheetId = termsheetId
        }
        handleSendDraftTermsheet(termsheet)
        stepper.current.goNext()
      }
    } else {
      setLoading(true)
      const termsheet = getTermsheetDraftData(values)
      const signerData: SignerData[] = [
        {
          name: values.investorName,
          email: '',
          designation: '',
          signatureType: '',
        },
      ]
      const investorNames = ['investorName2', 'investorName3', 'investorName4', 'investorName5']

      investorNames.forEach((name) => {
        if (values?.[name]) {
          signerData.push({
            name: values?.[name],
            email: '',
            designation: '',
            signatureType: '',
          })
        }
      })
      const founderCount = 5 // Number of founders

      for (let i = 1; i <= founderCount; i++) {
        const founderName = values[`founderName${i}`]
        const founderDesignation = values[`founderDesignation${i}`] || ""

        if (founderName) {
          signerData.push({
            name: founderName,
            email: '',
            designation: founderDesignation,
            signatureType: '',
          })
        }
      }

      let basepdf = ''
      if (newPdf.startsWith('data')) {
        basepdf = newPdf.split(';base64,')[1]
      }
      const headers = { Authorization: jwt !== undefined ? jwt : '' }
      axios
        .post(
          `${esignApi}/admin/uploadRequestDoc`,
          {
            file: basepdf,
            fileData: {
              fileName: templateName,
              fileType: templateData?.type ? templateData.type : 'Document',
            },
            requestParams: {
              requestedBy: jwtDetails.emailId,
              reason: 'Sign Please',
              responseUrl: `${invApi}/documentStatusUpdate`,
              companyName: (companyObj && companyObj.companyName) || initialValues?.companyName,
            },
            signerData: signerData,
          },
          { headers }
        )
        .then((data) => {
          setLoading(false)
          let token = data?.data?.token
          if (!token) {
            token = data?.data?.data?.token
          }
          setESignId(data?.data?.data?.esign_id)
          setIFrameToken(token)
          setSignDialog(!signDialog)
          termsheet.status = 'Pending Signature'
          termsheet.eSignId = data?.data?.data?.esign_id
          termsheet.uploadPdfURL = getTermsheetUrl.data?.getTermsheetUrlPath?.urlPath
          if (termsheetId) {
            termsheet.termsheetId = termsheetId
          }
          handleSendDraftTermsheet(termsheet)
        })
        .catch((err) => {
          setLoading(false)
          console.error(err)
        })
    }
  }
  const usePreviewTermsheetMutation = useMutation(
    ['previewTermsheet'],
    (object: any) => axios.post(`${process.env.REACT_APP_API_URL}/termsheetPreview`, object),
    {
      onSuccess: async (data) => {
        let base64File: string = data.data?.base64
        if (!base64File.startsWith('data:application/pdf;base64,'))
          base64File = `data:application/pdf;base64,${base64File}`
        setPreviewUrl(base64File)
        const blob = await convertBase64ToBlob(
          data.data?.wordFile,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        )
        const blobPdf = await convertBase64ToBlob(data.data?.base64, 'application/pdf')
        //setUpload(blob)
        // onFileChange(data.data?.base64)
        onFileChange(data.data?.wordFile)
        setLoading(false)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
        setLoading(false)
      },
    }
  )
  const mutateSendDraftTermsheet = useSendNewTermsheetMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SendNewTermsheetMutation,
        _variables: SendNewTermsheetMutationVariables,
        _context: unknown
      ) => {
        // setLoading(false)
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
        if (!termsheetId) setTermsheetId(data.addTermsheet.termsheetId!)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const getTermsheetUrl = useGetS3UrlForTermsheetQuery<GetS3UrlForTermsheetQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        companyId: hissaCompanyId,
        accountId: Number(templateData.accountId),
      },
    },
    {
      enabled: queryEnabled,
      onSuccess: () => {
        setQueryEnabled(false)
      },
    }
  )
  const mutateSendTermsheet = useSendNewTermsheetMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SendNewTermsheetMutation,
        _variables: SendNewTermsheetMutationVariables,
        _context: unknown
      ) => {
        setLoading(false)
        setTermsheetId(Number(data.addTermsheet.saved))
        submitBtnRef.current?.removeAttribute('data-kt-indicator')
        queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
        queryClient.invalidateQueries(useGetDealTemplatesQuery.getKey())
        if (!termsheetId) setTermsheetId(data.addTermsheet.id!)
        Toast('Document Saved Successfully!', ToastType.success)
        navigate('/deals')
      },
      onError: (error: any) => {
        setLoading(false)
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        key: templateData?.thumbnailURL,
        bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET || '',
      },
    },
    {
      enabled:
        templateData?.thumbnailURL !== undefined &&
        templateData?.thumbnailURL !== null &&
        templateData?.thumbnailURL !== '',
    }
  )
  return mutateSendTermsheet !== undefined && mutateSendTermsheet.isSuccess ? (
    <EmailTermsheet
      termsheetId={termsheetId}
      showModal={showShareEmailModal}
      type={docType}
      handleClose={() => setShowShareEmailModal(false)}
    />
  ) : (
    <React.Fragment>
      <div className='card card-body shadow-sm'>
        <div className='card shadow-sm p-2'>
          <div className='card-body d-flex'>
            <div className='w-30'>
              <h3>{templateData?.name}</h3>
              <div className='h-175px border overflow-hidden mb-3'>
                <img
                  alt=''
                  className='w-100'
                  src={
                    templateData?.thumbnailURL
                      ? query.data?.urlForS3Object!
                      : toAbsoluteUrl('/media/illustrations/misc/termsheet2.png')
                  }
                />
              </div>
              <div>
                <p className='fs-8'>Features</p>
                <p className='fs-9 h-25px'></p>
              </div>
            </div>
            <div className='w-70 ms-10 overflow-auto scrollable '>
              {templateDetails?.map((d: any) => (
                <div className='col flex-nowrap'>
                  <h3>{d.title}</h3>
                  <p className='fs-8'>{d.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {jsonData[0].type === 'Termsheet' && (
          <div
            className='border border-dashed border-primary border-1 bg-termsheet-bg rounded my-8 p-9'
            style={{ cursor: 'pointer' }}
          >
            <div
              className='d-flex flex-row '
              onClick={() => {
                useChatBot.setIsOpen(true)
              }}
            >
              <span className='me-6'>
                <img
                  src={'/media/icons/duotune/general/ai-bot.svg'}
                  alt=''
                  className='w-100px h-150px'
                />
              </span>

              <span className='fs-8'>
                <h3 className='fw-bold mb-0'>Newly Introducing! Hissa's new AI Powered Template</h3>
                <div className='text-gray-500 fs-7 pb-2'>
                  This Template is filled using AI, the following fields are detected from our
                  conversation
                </div>
                {aiInits && aiInits !== undefined && (
                  <>
                    <div>The Fields detected via our conversation are</div>

                    <div
                      className='row card-body overflow-auto flex-nowrap my-2 mx-0'
                      style={{ overflowX: 'auto', scrollbarWidth: 'none' }}
                    >
                      {filledValue.map((obj, index) => {
                        if (aiInits[obj] !== '' && aiInits[obj] != 0) {
                          return index <= 2 ? (
                            <span className='border border-dashed border-gray-400 rounded p-4 py-2 bg-white w-auto me-4'>
                              <h6
                                className='m-0 fw-bold pb-1'
                                data-toggle='tooltip'
                                data-placement='top'
                                title={aiInits[obj]}
                              >
                                {aiInits[obj].length > 20
                                  ? aiInits[obj].substring(0, 20) + '...'
                                  : aiInits[obj]}
                              </h6>
                              <h5 className='fw-bold m-0 text-primary pb-0'>
                                {getNameForKey(obj, templateData.jsonData)}

                                <span>
                                  <KTSVG
                                    path='/media/icons/duotune/general/stars.svg'
                                    className='svg-icon-6 ms-2 '
                                  />
                                </span>
                              </h5>
                            </span>
                          ) : (
                            <></>
                          )
                        }
                      })}
                      {filledValue.length > 3 && (
                        <span className='border border-dashed border-gray-400 rounded pt-6 px-6 bg-white w-auto me-3'>
                          <h5 className='text-primary fs-bold d-flex align-items-baseline'>
                            {'+' + (filledValue.length - 3)}
                          </h5>
                        </span>
                      )}
                    </div>

                    <div className='pt-6'>
                      Few of the below fields have not been detected by the AI, please go through
                      the document and fill in the necessary fields.{' '}
                    </div>
                  </>
                )}
              </span>

              <div className='col d-flex justify-content-end align-items-end'>
                <button
                  type='button'
                  className='px-4 rounded border border-1 border-hover-primary border-growth-card bg-transparent h-38px fw-bold text-growth-card cursor-pointer bg-hover-secondary text-hover-primary'
                  onClick={(event: any) => {
                    event.stopPropagation()
                    setIsImportModel(true)
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/files/fil021.svg'
                    className='svg-icon-2x text-growth-card'
                  />{' '}
                  Import from Modelling
                </button>
              </div>
            </div>
          </div>
        )}
        {isImportModel && (
          <ImportInvestmentModel
            handleClose={() => {
              setIsImportModel(false)
            }}
            templateData={templateData}
            showModal={isImportModel}
            allCompanies={allCompanies}
            initialValues={initialValues}
          />
        )}
        <h1 className='fs-2x fw-bold mt-3'>Issue Document</h1>
        <div
          ref={stepperRef}
          className='d-flex stepper stepper-pills stepper-column'
          id='kt_create_account_stepper'
        >
          <div
            className={`${
              isStepperCollapsed ? 'col-md-1' : 'col-md-3'
            } card card-body shadow-sm m-5 ms-0 h-750px`}
            style={{ transition: '700ms' }}
          >
            <div
              className='d-flex justify-content-end'
              onClick={() => {
                setIsStepperCollpased(!isStepperCollapsed)
              }}
            >
              <KTSVG
                className={`svg-icon-3x cursor-pointer`}
                path={
                  isStepperCollapsed
                    ? `/media/icons/duotune/arrows/arr080.svg`
                    : `/media/icons/duotune/arrows/arr079.svg`
                }
              />
            </div>
            <div className='overflow-scroll'>
              {jsonData.map((sections: any) => {
                if (Number(sections.sequenceNumber) > 0) {
                  if (!stepperValues.includes(sections.stepname)) {
                    stepperValues.push(sections.stepname)
                    return (
                      <div
                        className={`${
                          Number(sections.sequenceNumber) === 1 ? 'stepper-item' : 'stepper-item'
                        }`}
                        data-kt-stepper-element='nav'
                      >
                        <div className='stepper-wrapper cursor-pointer'>
                          <div
                            className='stepper-icon w-40px h-40px'
                            onClick={() => {
                              goTo(sections.sequenceNumber)
                            }}
                          >
                            {stepperErrors[camelize(sections.stepname)] ? (
                              <i className='stepper-check fas fa-exclamation'></i>
                            ) : (
                              <i className='stepper-check fas fa-check'></i>
                            )}{' '}
                            <span className='stepper-number'>{sections.sequenceNumber}</span>
                          </div>
                          {!isStepperCollapsed && (
                            <div
                              className='stepper-label'
                              onClick={() => {
                                goTo(sections.sequenceNumber)
                              }}
                            >
                              <h3 className='stepper-title'>{sections.stepname}</h3>

                              <div className='stepper-desc fw-semibold'>
                                Enter {sections.stepname}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='stepper-line h-40px'></div>
                      </div>
                    )
                  }
                }
              })}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-wrapper cursor-pointer'>
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>{newSchema.length + 1}</span>
                  </div>

                  {!isStepperCollapsed && (
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Edit {jsonData[0].type}</h3>
                      <div className='stepper-desc fw-semibold'>Preview and edit details</div>
                    </div>
                  )}
                </div>
                <div className='stepper-line h-40px'></div>
              </div>
              <div className='stepper-item' data-kt-stepper-element='nav'>
                <div className='stepper-wrapper cursor-pointer'>
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>{newSchema.length + 2}</span>
                  </div>

                  {!isStepperCollapsed && (
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Preview and Sign {jsonData[0].type}</h3>
                      <div className='stepper-desc fw-semibold'>Preview details before sending</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              isStepperCollapsed ? 'col-md-11' : 'col-md-9'
            } card card-body shadow-sm m-5 mx-0 h-750px`}
          >
            <div ref={stepperRef} className='overflow-auto'>
              <Formik
                initialValues={aiInits || initialValues || init}
                onSubmit={submitStep}
                validationSchema={currentSchema}
              >
                {({ errors, values, getFieldProps, resetForm }) => {
                  handleStepperErrors(errors)
                  setFieldValue(values)
                  const handleGenerateForm = (data: any) => {
                    const d: TermsheetType = {}
                    if (data && data.length > 0) {
                      data.forEach((item: any) => {
                        d[item.key] = item.value
                      })
                    }
                    const getAiInits = getInits1(data, JSON.parse(templateData.jsonData))

                    const mergedState = aiInits
                      ? {
                          ...Object.entries(values).reduce((acc: any, [key, value]) => {
                            if (value === '' || Number(value) === 0) {
                              acc[key] = value
                            }
                            return acc
                          }, {}),
                          ...Object.entries(aiInits).reduce((acc: any, [key, value]) => {
                            if (value !== '') {
                              acc[key] = value
                            }
                            return acc
                          }, {}),
                          ...Object.entries(getAiInits).reduce((acc: any, [key, value]) => {
                            if (value !== '') {
                              acc[key] = value
                            }
                            return acc
                          }, {}),
                          termsheetName:
                            jsonData[0].type === 'Termsheet'
                              ? 'My Termsheet'
                              : jsonData[0].type === 'NDA'
                              ? 'Non-Disclosure Agreement'
                              : 'My Document',
                        }
                      : {
                          ...Object.entries(values).reduce((acc: any, [key, value]) => {
                            if (value === '' || Number(value) === 0) {
                              acc[key] = value
                            }
                            return acc
                          }, {}),
                          ...Object.entries(getAiInits).reduce((acc: any, [key, value]) => {
                            if (value !== '') {
                              acc[key] = value
                            }
                            return acc
                          }, {}),
                          termsheetName:
                            jsonData[0].type === 'Termsheet'
                              ? 'My Termsheet'
                              : jsonData[0].type === 'NDA'
                              ? 'Non-Disclosure Agreement'
                              : 'My Document',
                        }
                    init['isFromModelling'] = undefined
                    resetForm({ values: mergedState })
                    setAiInits(mergedState)
                    reloadStepper()
                  }
                  if (values['investor1NoOfShares1'] || values['nominalEquityShares1']) {
                    values['preferenceShares1'] =
                      values['investor1NoOfShares1'] - values['nominalEquityShares1']
                  }
                  if (values['investor2NoOfShares1'] || values['nominalEquityShares2']) {
                    values['preferenceShares2'] =
                      values['investor2NoOfShares1'] - values['nominalEquityShares2']
                  }
                  if (values['investor3NoOfShares1'] || values['nominalEquityShares3']) {
                    values['preferenceShares3'] =
                      values['investor3NoOfShares1'] - values['nominalEquityShares3']
                  }
                  if (values['investor4NoOfShares1'] || values['nominalEquityShares4']) {
                    values['preferenceShares4'] =
                      values['investor4NoOfShares1'] - values['nominalEquityShares4']
                  }

                  return (
                    <div>
                      {(stepper.current?.currentStepIndex == 1 ||
                        stepper.current?.currentStepIndex == undefined) && (
                        <TermSheetName
                          isEdit={isEdit}
                          setTemplateName={setTemplateName}
                          templateName={templateName || ''}
                          setIsEdit={setIsEdit}
                          documentType={jsonData[0].type}
                        />
                      )}

                      {jsonData[0].type === 'Termsheet' &&
                        (stepper.current?.currentStepIndex == 1 ||
                          stepper.current?.currentStepIndex == undefined) && (
                          <GetInvestors investorList={investorList} values={values} />
                        )}

                      {jsonData[0].type === 'Termsheet' &&
                        (stepper.current?.currentStepIndex == 1 ||
                          stepper.current?.currentStepIndex == undefined) && (
                          <GetCompanies
                            stepper={stepper || {}}
                            setCompanyObj={setCompanyObj}
                            setCompanyName={setCompanyName}
                            values={values}
                            isFromModelling={initialValues?.isFromModelling ? true : false}
                            templateData={templateData}
                            resetForm={() => {
                              init['isFromModelling'] = undefined
                              resetForm({ values: init })
                            }}
                          />
                        )}

                      <Form noValidate id='kt_create_account_form'>
                        <div className='h-600px overflow-scroll'>
                          {jsonData.map((sections: StepperType) => {
                            if (Number(sections.sequenceNumber) > 0) {
                              const elements =
                                (sections.condition &&
                                  getFieldProps(sections.condition?.name).value ===
                                    sections.condition?.value &&
                                  (setFormikValues(getFieldProps(sections.condition?.name).value),
                                  (
                                    <div
                                      className={`${
                                        String(sections.sequenceNumber) === '1' ? 'current' : ''
                                      }`}
                                      data-kt-stepper-element='content'
                                    >
                                      <GenerateTermsheet sections={sections} aiInits={aiInits} />
                                    </div>
                                  ))) ||
                                (!sections.condition && (
                                  <div
                                    className={`${
                                      String(sections.sequenceNumber) === '1' ? 'current' : ''
                                    }`}
                                    data-kt-stepper-element='content'
                                  >
                                    <GenerateTermsheet sections={sections} aiInits={aiInits} />
                                  </div>
                                ))
                              steppers[sections.stepname] = elements
                              return elements
                            }
                          })}

                          <div data-kt-stepper-element='content'>
                            {usePreviewTermsheetMutation !== undefined &&
                            usePreviewTermsheetMutation.isSuccess &&
                            previewUrl ? (
                              ((fieldValue.status = 'Preview'),
                              (
                                <>
                                  <div className='flex-row col-12 pt-5'>
                                    <SyncfusionDocumentEditor
                                      documentName={templateName}
                                      documentReference={container}
                                    />
                                    <div className='mt-2 text-primary'>
                                      Note: Do not edit the words which are enclosed with #
                                      i.e(#investorName#)
                                    </div>
                                  </div>
                                </>
                              ))
                            ) : usePreviewTermsheetMutation !== undefined &&
                              usePreviewTermsheetMutation.isLoading ? (
                              <div className='col-12 h-550px d-flex justify-content-center'>
                                <div className='spinner-grow align-self-center' role='status'>
                                  <span className='visually-hidden'>Loading...</span>
                                </div>
                              </div>
                            ) : (
                              <div className='col-12 h-550px d-flex justify-content-center'>
                                <span className='align-self-center'>Failed to load preview </span>
                              </div>
                            )}
                          </div>
                          <div
                            data-kt-stepper-element='content'
                            className='d-flex justify-content-center'
                          >
                            {showSign && (
                              <div className='fv-row mb-4  overflow-scroll h-550px '>
                                <LazyPdfDocument url={newPdf} />
                              </div>
                            )}
                          </div>
                        </div>
                        {jsonData[0]?.draftNotes &&
                          (stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! - 2 ||
                            stepper.current?.currentStepIndex == undefined) && (
                            <DraftNotes jsonData={jsonData} />
                          )}
                        {(stepper.current?.currentStepIndex ===
                          stepper.current?.totatStepsNumber! - 2 ||
                          stepper.current?.currentStepIndex == undefined) && (
                          <RecommendedFields fields={recFields} values={values} />
                        )}
                        <div
                          className={`d-flex ${
                            stepper.current?.currentStepIndex !== 1
                              ? 'justify-content-between'
                              : 'justify-content-end'
                          } pt-5`}
                        >
                          <div className='btn-toolbar'>
                            <button
                              onClick={() => {
                                prevStep()
                              }}
                              type='button'
                              className='btn btn-secondary m-1'
                              data-kt-stepper-action='previous'
                            >
                              Back
                            </button>
                          </div>

                          {currentStepIndex && (
                            <div className='btn-toolbar'>
                              {stepper.current?.currentStepIndex ===
                                stepper.current?.totatStepsNumber! &&
                                stepper.current?.currentStepIndex !== undefined && (
                                  <button
                                    onClick={() => {
                                      queryClient.invalidateQueries(useGetTermsheetsQuery.getKey())
                                      navigate('/deals')
                                      Toast('Draft Created Successfully!', ToastType.success)
                                    }}
                                    type='button'
                                    className='btn btn-secondary m-1'
                                  >
                                    Save as Draft
                                  </button>
                                )}
                              <button
                                ref={submitBtnRef}
                                type='submit'
                                className='btn btn-primary m-1'
                                disabled={loading}
                              >
                                <span className='indicator-label'>
                                  {
                                    (!loading &&
                                      stepper.current?.currentStepIndex ===
                                        stepper.current?.totatStepsNumber! - 1 &&
                                      'Save & Preview') ||
                                      (!loading &&
                                        (stepper.current?.currentStepIndex !==
                                          stepper.current?.totatStepsNumber! ||
                                          stepper.current?.currentStepIndex === 0 ||
                                          stepper.current?.currentStepIndex === undefined) &&
                                        'Continue') ||
                                      (!loading &&
                                        stepper.current?.currentStepIndex ===
                                          stepper.current?.totatStepsNumber! &&
                                        stepper.current?.currentStepIndex !== undefined &&
                                        'Proceed to Sign') ||
                                      (!loading && mutateSendTermsheet.isSuccess) /*&& 'Sent'*/
                                  }
                                </span>
                                {loading && (
                                  <span className='indicator-progress' style={{ display: 'block' }}>
                                    Loading...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                  </span>
                                )}
                              </button>
                            </div>
                          )}
                        </div>
                      </Form>
                      {signDialog && (
                        <SignatureIframe
                          showDialog={signDialog}
                          token={iFrameToken}
                          handleClose={handleSignPopupClose}
                        />
                      )}
                      {jsonData[0].type === 'Termsheet' && (
                        <div className='card my-3 ps-5 d-flex'>
                          <HissaAiModal
                            schema={JSON.parse(templateData.jsonData)}
                            showModal={showFreeTextModal}
                            handleClose={handleFreeTextModalClose}
                            handleGenerateForm={handleGenerateForm}
                          />
                        </div>
                      )}
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DocumentIssuePageWrapper
