import { useContext, useEffect, useState } from 'react'
import { MultiSelectOptionType } from '../../../utils/MultiSelect'
import Select from 'react-select'
import EmptyMetricsItem from './Components/EmptyMetricsItem'
import { Actions, FilterType } from '../store/context/reducer'
import { selectCustomStyles } from '../../../utils/Select'
import makeAnimated from 'react-select/animated'
import {
  FundStructure,
  FundUnit,
  GetFundStructureQuery,
  LedgerTransaction,
  useGetFundStructureQuery,
} from '../../../generated/graphql'
import { graphqlRequestClient } from '../../../queries/client'
import { GlobalContext, GlobalContextType } from '../store/context/globalContext'
import FundStructureView from './FundStructure/FundStructureView'
import { getUserInvestors } from '../../../utils/LocalStorageUtils'

function FundMetricsWrapper() {
  const { state, dispatch } = useContext<GlobalContextType>(GlobalContext)
  const { ctxFundInvestorId } = state
  const [values, setValues] = useState<any>()
  const [fundStructure, setFundStructure] = useState<FundStructure>()
  const [fundUnitData, setFundUnitData] = useState<FundUnit[]>()
  const [capitalLedgerTransactions, setCapitalLedgerTransactions] = useState<LedgerTransaction[]>()
  const investorTypes = getUserInvestors('userInvestors')
  const [fundOptions] = useState<MultiSelectOptionType[]>(
    investorTypes.map((item) => {
      return { value: item.investorId, label: item.investorName, type: FilterType.fund }
    })
  )
  const [invId, setInvId] = useState<number>(
    investorTypes && investorTypes.length > 0 ? investorTypes[0].investorId : -1
  )

  const animatedComponents = makeAnimated()

  const fundStructureData = useGetFundStructureQuery<GetFundStructureQuery, Error>(
    graphqlRequestClient,
    { input: invId },
    { enabled: invId !== -1 },
    {}
  )

  useEffect(() => {
    if (ctxFundInvestorId === undefined) setValues({ value: -1, label: 'Select...', type: '' })

    if (ctxFundInvestorId?.length === 1 && fundOptions?.length > 0) {
      setInvId(Number(ctxFundInvestorId[0]))
      const selectedObject = fundOptions?.find(
        (element) => Number(element.value) === Number(ctxFundInvestorId[0])
      )
      if (selectedObject) setValues(selectedObject)
    }
  }, [ctxFundInvestorId, fundOptions])

  useEffect(() => {
    if (fundStructureData?.data?.getFundStructure) {
      setFundStructure(fundStructureData?.data?.getFundStructure as FundStructure)
      setFundUnitData(fundStructureData?.data?.getFundStructure.fundUnits as FundUnit[])
      setCapitalLedgerTransactions(
        fundStructureData?.data?.getFundStructure.capitalledgerTransactions as LedgerTransaction[]
      )
    }
  }, [fundStructureData?.data?.getFundStructure])
  return (
    <div>
      <div className='col-sm-12'>
        {fundOptions && fundOptions?.length > 0 && (
          <h1>Please choose the fund you would like to view</h1>
        )}
      </div>
      <div className='col-sm-6 col-xl-8 mt-0'>
        <div className='pb-5'>
          {fundOptions && fundOptions.length > 0 && (
            <Select
              className=''
              options={[{ label: 'Investors / Funds', options: fundOptions }]}
              backspaceRemovesValue
              onChange={(e: any) => {
                setInvId(Number(e.value))
                setValues(e)
                setFundStructure(undefined)
                dispatch({ type: Actions.setFundInvestorIds, payload: [Number(e.value)] })
              }}
              value={values}
              styles={selectCustomStyles}
              components={animatedComponents}
              defaultValue={fundOptions[0]}
            />
          )}
        </div>
      </div>
      {fundStructureData.isLoading && (
        <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
          <span className='spinner-grow'></span>
        </div>
      )}
      {invId > -1 && fundStructure === undefined && <EmptyMetricsItem investorId={invId} />}
      {fundStructure !== undefined && (
        <FundStructureView
          fundStructure={fundStructure!}
          investorId={invId}
          fundUnitData={fundUnitData!}
          capitalLedgerTransactions={capitalLedgerTransactions!}
        />
      )}
    </div>
  )
}

export { FundMetricsWrapper }
