import { useEffect, useState } from 'react'
import {
  AllTransactionsType,
  CompanyDataInput,
  GetAllTransactionsQuery,
  GetGlobalCompaniesQuery,
  GetNewWatchlistsQuery,
  GetRecentCompaniesQuery,
  GlobalCompanySearchType,
  RecentCompanyType,
  WatchlistObject,
  useGetAllTransactionsQuery,
  useGetGlobalCompaniesQuery,
  useGetNewWatchlistsQuery,
  useGetRecentCompaniesQuery,
} from '../../../generated/graphql'
import { graphqlRequestClient } from '../../../queries/client'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import _ from 'lodash'
import { RecentCompanies } from './components/RecentCompanies'
import { PortfolioCard } from './components/PortfolioCard'
import { WatchlistStarredCompanies } from './components/WatchlistStarredCompanies'
import LatestActivityComponent from './components/LatestActivityComponent'
import PageVisibility from 'react-page-visibility'
import Ticker from 'react-ticker'
import { WatchlistTickerCard } from '../watchlist/Components/WatchlistTickerCard'
import { globalCompaniesList } from '../../../utils/GlobalComapnies'

function DashboardPageWrapper() {
  const [allStarredCompanies, setAllStarredCompanies] = useState<string[]>([])
  const [allTransactions, setAllTransactions] = useState<AllTransactionsType[]>([])
  const [allCompanies, setAllCompanies] = useState<GlobalCompanySearchType[]>([])
  const [isPageVisible, setIsPageVisible] = useState(true)
  const [isPaused, setIsPaused] = useState(false)

  const { data, isFetched, isLoading } = useGetAllTransactionsQuery<GetAllTransactionsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const { data: recentUserCompanies } = useGetRecentCompaniesQuery<GetRecentCompaniesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const { data: watchlist } = useGetNewWatchlistsQuery<GetNewWatchlistsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const { data: globalCompanies } = useGetGlobalCompaniesQuery<GetGlobalCompaniesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  useEffect(() => {
    if (watchlist && watchlist.newWatchlists) {
      const starredCompanies: string[] = []

      watchlist.newWatchlists.forEach((watchlist) => {
        watchlist?.starredCompanies?.forEach((company) => {
          if (!starredCompanies.includes(company as string))
            starredCompanies.push(company as string)
        })
      })
      setAllStarredCompanies(starredCompanies)
    }
  }, [watchlist])

  useEffect(() => {
    if (globalCompanies && globalCompanies.globalCompaniesSearch) {
      setAllCompanies(
        _.cloneDeep(globalCompanies.globalCompaniesSearch) as GlobalCompanySearchType[]
      )
    }
  }, [globalCompanies])

  useEffect(() => {
    if (data && data.allTransactions) {
      setAllTransactions(_.cloneDeep(data.allTransactions) as AllTransactionsType[])
    }
    const companies: CompanyDataInput[] = []
    if (data && data.allTransactions) {
      const allTransactions = data?.allTransactions as AllTransactionsType[]
      allTransactions.forEach((company) => {
        companies.push({
          hissaCompanyId: company.companyData.hissaCompanyId,
          name: company.companyData.name,
          type: company.companyData.type,
          id: company.companyData.id,
        })
      })
      localStorage.setItem('userCompanies', JSON.stringify(companies))
    }

    MenuComponent.reinitialization()
  }, [data])

  if (isLoading) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  const handleVisibilityChange = (isVisible: boolean) => {
    setIsPageVisible(isVisible)
  }

  if (isFetched) {
    return (
      <>
        {/* Tickers */}
        {allCompanies.length > 0 && (
          <div className='row mb-8'>
            <div className='col-12'>
              <div
                className='card bg-transparent overflow-auto'
                style={{ overflowX: 'auto', scrollbarWidth: 'none' }}
              >
                <PageVisibility onChange={handleVisibilityChange}>
                  {isPageVisible && (
                    <Ticker speed={5} move={isPaused ? false : true}>
                      {({ index }) => (
                        <div
                          key={index}
                          className='mt-4 card-body p-0 row flex-nowrap mx-0 mb-1'
                          onMouseEnter={() => setIsPaused(true)}
                          onMouseLeave={() => setIsPaused(false)}
                        >
                          {globalCompaniesList.map((company, i) => (
                            <span
                              key={i}
                              style={{
                                display:
                                  index % globalCompaniesList.length === i ? 'block' : 'none',
                              }}
                            >
                              <WatchlistTickerCard
                                // companyId={company.id!}
                                companyName={company?.name!}
                                valuation={company?.valuation!}
                                index={i}
                                logo={company.logo}
                                year={company.year}
                              />
                            </span>
                          ))}
                        </div>
                      )}
                    </Ticker>
                  )}
                </PageVisibility>
              </div>
            </div>
          </div>
        )}
        {/* Dashboard Header Cards */}
        <div className='row mb-9'>
          <div className='col-12'>
            <div className='row gx-9'>
              <div className='col-12 col-md-4 mb-md-0 mb-9 mb-md-0'>
                {allTransactions && allTransactions.length > 0 && (
                  <RecentCompanies
                    allTransactions={allTransactions}
                    recentCompanyList={
                      recentUserCompanies?.getUserRecentCompanies as RecentCompanyType[]
                    }
                  />
                )}
              </div>
              <div className='col-12 col-md-4 mb-md-0 mb-9 mb-md-0'>
                {allTransactions && allTransactions.length > 0 && (
                  <PortfolioCard allTransactions={allTransactions} />
                )}
              </div>
              <div className='col-12 col-md-4 mb-md-0'>
                {allTransactions && allTransactions.length > 0 && (
                  <WatchlistStarredCompanies
                    starredCompanies={allStarredCompanies}
                    watchlist={watchlist?.newWatchlists as WatchlistObject[]}
                    allTransactions={allTransactions}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Dashboard Latest Activity */}
        <div className='row mb-9'>
          <div className='col-12'>
            <div className='row gx-9'>
              <div className='col-12 mb-md-0'>
                {allTransactions && allTransactions.length > 0 && watchlist && (
                  <LatestActivityComponent
                    watchlists={watchlist?.newWatchlists as WatchlistObject[]}
                    allTransactions={allTransactions}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return <></>
}

export default DashboardPageWrapper
