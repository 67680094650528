import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import {
  GetEeMetricsQuery,
  GetEeCompanyMetricsQuery,
  GetEeFiltersQuery,
  GetEeThemesQuery,
  useGetEeMetricsQuery,
  useGetEeCompanyMetricsQuery,
  useGetEeFiltersQuery,
  useGetEeThemesQuery,
} from '../../../generated/graphql'
import { graphqlRequestClient } from '../../../queries/client'
import Toast, { ToastType } from '../../../utils/toast'
import { EEMetrics } from './EEMetrics'
import { EECompMetrics } from './EECompMetrics'
import { EEFilters } from './EEFilters'
import { EEThemes } from './EEThemes'

const EEHome: React.FC = () => {
  const [tab, setTab] = useState('metrics')
  const [metrics, setMetrics] = useState([])
  const [compMetrics, setCompMetrics] = useState([])
  const [filters, setFilters] = useState([])
  const [themes, setThemes] = useState([])

  const eeMetrics = useGetEeMetricsQuery<GetEeMetricsQuery, Error>(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (eeMetrics && eeMetrics.data && eeMetrics.data.eeMetrics) setMetrics(eeMetrics.data.eeMetrics)

    if (eeMetrics && eeMetrics.error)
      Toast(`Oops, something's broken. Error: ${eeMetrics.error?.message.substring(0, eeMetrics.error?.message?.indexOf(':'))}`, ToastType.error)
  }, [eeMetrics.data, eeMetrics.error])

  const eeCompMetrics = useGetEeCompanyMetricsQuery<GetEeCompanyMetricsQuery, Error>(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (eeCompMetrics.data && eeCompMetrics.data.eeCompanyMetrics) setCompMetrics(eeCompMetrics.data.eeCompanyMetrics)

    if (eeCompMetrics && eeCompMetrics.error)
      Toast(
        `Oops, something's broken. Error: ${eeCompMetrics.error?.message.substring(0, eeCompMetrics.error?.message?.indexOf(':'))}`,
        ToastType.error
      )
  }, [eeCompMetrics.data, eeCompMetrics.error])

  const eeFilters = useGetEeFiltersQuery<GetEeFiltersQuery, Error>(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (eeFilters.data && eeFilters.data.eeFilters) setFilters(eeFilters.data.eeFilters)

    if (eeFilters && eeFilters.error)
      Toast(`Oops, something's broken. Error: ${eeFilters.error?.message.substring(0, eeFilters.error?.message?.indexOf(':'))}`, ToastType.error)
  }, [eeFilters.data, eeFilters.error])

  const eeThemes = useGetEeThemesQuery<GetEeThemesQuery, Error>(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (eeThemes.data && eeThemes.data.eeThemes) {
      const th = eeThemes.data.eeThemes.reduce((t: any, item: any) => {
        const group = t.get(item.name) || []
        group.push(item)
        t.set(item.name, group)
        return t
      }, new Map())
      setThemes(th)
    }
    if (eeThemes && eeThemes.error)
      Toast(`Oops, something's broken. Error: ${eeThemes.error?.message.substring(0, eeThemes.error?.message?.indexOf(':'))}`, ToastType.error)
  }, [eeThemes.data, eeThemes.error, filters])

  return (
    <div className='row'>
      <div className='card mb-5'>
        <div className='card-body pt-5 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <p className='text-gray-800 text-hover-primary fs-2 me-1 mb-0'>Hissa Fund's - Exit Enabler</p>
                    <KTSVG path='/media/icons/duotune/general/gen026.svg' className='svg-icon-1 svg-icon-primary' />
                  </div>
                  <div className='d-flex flex-wrap fs-6 mb-4 pe-2'>
                    <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                      This is a invite only special module to empower you to plan exits from your funds
                    </p>
                  </div>
                </div>
                {/* <div className='d-flex my-4'>
                  <button className='btn btn-sm btn-light me-2'>Refer Investor</button>
                </div> */}
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div
                      className='border border-gray-300 border-hover-primary rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer '
                      onClick={(e) => setTab('metrics')}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {eeMetrics.isLoading ? <span className='spinner-border spinner-border-sm align-middle ms-2'></span> : metrics.length}
                        </div>
                      </div>
                      <div className='fs-6 text-gray-400'>Metrics</div>
                    </div>
                    <div
                      className='border border-gray-300 border-hover-primary rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer'
                      onClick={(e) => setTab('compMetrics')}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {eeCompMetrics.isLoading ? (
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          ) : (
                            compMetrics.length
                          )}
                        </div>
                      </div>
                      <div className='fs-6 text-gray-400'>Company Metrics</div>
                    </div>
                    <div
                      className='border border-gray-300 border-hover-primary rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer'
                      onClick={(e) => setTab('filters')}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {eeFilters.isLoading ? <span className='spinner-border spinner-border-sm align-middle ms-2'></span> : filters.length}
                        </div>
                      </div>
                      <div className='fs-6 text-gray-400'>Filters</div>
                    </div>
                    <div
                      className='border border-gray-300 border-hover-primary rounded min-w-125px py-3 px-4 me-6 mb-3 cursor-pointer'
                      onClick={(e) => setTab('themes')}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bolder'>
                          {eeThemes.isLoading ? (
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          ) : (
                            Array.from(themes.keys()).length
                          )}
                        </div>
                      </div>
                      <div className='fs-6 text-gray-400'>Themes</div>
                    </div>
                  </div>
                </div>
                {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                  <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                    <span className='fs-6 text-gray-400'>Funds Utilized</span>
                    <span className='fw-bolder fs-6'>87%</span>
                  </div>
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div className='bg-success rounded h-5px' role='progressbar' style={{ width: '87%' }}></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='tab-content'>
          <div className={`tab-pane fade ${tab === 'metrics' && 'active show'}`} id='ee_home_tab1'>
            {!eeMetrics.isLoading && <EEMetrics metrics={metrics} />}
          </div>
          <div className={`tab-pane fade ${tab === 'compMetrics' && 'active show'}`} id='ee_home_tab2'>
            {!eeCompMetrics.isLoading && <EECompMetrics compMetrics={compMetrics} metrics={metrics} />}
          </div>
          <div className={`tab-pane fade ${tab === 'filters' && 'active show'}`} id='ee_home_tab3'>
            {!eeFilters.isLoading && <EEFilters filters={filters} />}
          </div>
          <div className={`tab-pane fade ${tab === 'themes' && 'active show'}`} id='ee_home_tab4'>
            {!eeThemes.isLoading && <EEThemes themes={themes} filters={filters} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export { EEHome }
