import { Bar, Line, Chart } from 'react-chartjs-2'
import { MisDataType } from '../../../types/misData'
import { useContext, useEffect, useRef, useState } from 'react'
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../_metronic/helpers'
import { Modal } from 'react-bootstrap'
import { hexToRGB } from '../../../utils/HexToRgba'
import pattern from 'patternomaly'
import { getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartType } from 'chart.js'
import _ from 'lodash'
import { formatNumber, getCurrency } from '../../../utils/CurrencyFormatter'
import { GlobalContext } from '../store/context/globalContext'

ChartJS.register(ArcElement, Tooltip, Legend)

type Props = {
  data: MisDataType
  chartType: ChartTypes
}

export enum ChartTypes {
  Line = 'line',
  Bar = 'bar',
}

type ChartDataType = {
  label: string
  data: number[]
  backgroundColor?: string | CanvasPattern
  borderColor?: string | CanvasPattern
  borderDash?: number[]
  type?: 'line' | 'bar'
  options?: any
  yAxisID?: string
}

type DataType = {
  labels: string[]
  datasets: ChartDataType[]
  groupName: string
}

function ChartComponent({ data, chartType }: Props) {
  const chartData: DataType[] = []
  // const percentageChartData: DataType[] = []
  const labels = data.timeline
  data.data.forEach((i) => {
    const subGroupDataSets: ChartDataType[] = []
    i.items.forEach((item, index) => {
      // Generate a base color for the item
      const actualBaseColor = hexToRGB(
        getCSSVariableValue('--kt-donut-' + `${index < 20 ? index : index % 20}`),
        1
      )
      if (item.type === 'percentage') {
        const projectedBaseColor = pattern.draw(
          'zigzag-vertical',
          hexToRGB(getCSSVariableValue('--kt-donut-' + `${index < 20 ? index : index % 20}`), 1),
          'white',
          10
        )
        subGroupDataSets.push({
          data: item.data.map((d) => d.actual * 100),
          label: `${item.subGroupItem} (Actual)`,
          borderColor: actualBaseColor, // Solid color for actual
          backgroundColor: actualBaseColor, // Light backgroundf for area fill
          borderDash: [], // Solid line for actual
          type: 'line',
          yAxisID: 'y1',
        })

        // Add projected data with a lighter shade or transparency
        subGroupDataSets.push({
          data: item.data.map((d) => d.projected * 100),
          label: `${item.subGroupItem} (Projected)`,
          borderColor: projectedBaseColor, // Adjusted color for projected
          backgroundColor: projectedBaseColor, // Even lighter background
          borderDash: [], // Dotted line for projected
          type: 'line',
          yAxisID: 'y1',
        })
      } else if (item.type === 'number') {
        const projectedBaseColor = pattern.draw(
          'diagonal-right-left',
          hexToRGB(getCSSVariableValue('--kt-donut-' + `${index < 20 ? index : index % 20}`), 1),
          'white',
          10
        )
        subGroupDataSets.push({
          data: item.data.map((d) => d.actual),
          label: `${item.subGroupItem} (Actual)`,
          borderColor: actualBaseColor, // Solid color for actual
          backgroundColor: actualBaseColor, // Light background for area fill
          borderDash: [], // Solid line for actual
          type: 'bar',
          yAxisID: 'y',
        })

        // Add projected data with a lighter shade or transparency
        subGroupDataSets.push({
          data: item.data.map((d) => d.projected),
          label: `${item.subGroupItem} (Projected)`,
          borderColor: projectedBaseColor, // Adjusted color for projected
          backgroundColor: projectedBaseColor, // Even lighter background
          borderDash: [8, 3], // Dotted line for projected
          type: 'bar',
          yAxisID: 'y',
        })
      }
    })

    const data = {
      labels: labels,
      datasets: subGroupDataSets,
      groupName: i.groupName,
    }
    const index = chartData.findIndex((chart) => chart.groupName === data.groupName)
    if (index !== -1) {
      chartData[index].datasets = [...chartData[index].datasets, ...data.datasets]
    } else {
      data.datasets.length > 0 && chartData.push(data)
    }
  })

  const charts: JSX.Element[] = []

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [chartType])

  for (let i = 0; i < chartData.length; i += 2) {
    const group = [chartData[i], chartData[i + 1]]
    const chartDiv = (
      <>
        <div className='row pb-5 pt-5' key={group[0].groupName}>
          <div className='col-12 col-md-6 border-2 border-end border-gray-400'>
            {group[0].datasets.length > 0 &&
              generateCharts(group[0].groupName, labels, group[0].datasets, chartType)}
          </div>
          {i + 1 < chartData.length && (
            <div className='col-12 col-md-6'>
              {group[1].datasets.length > 0 &&
                generateCharts(group[1].groupName, labels, group[1].datasets, chartType)}
            </div>
          )}
        </div>
        <div className='separator'></div>
      </>
    )
    charts.push(chartDiv)
  }
  return <div>{charts}</div>
}

function generateCharts(
  groupName: string,
  labels: string[],
  cData: ChartDataType[],
  chartType: ChartTypes
) {
  let chartData = cData
  return <MultipleChart chartData={chartData} groupName={groupName} labels={labels} />
}

type ChartProps = {
  groupName: string
  labels: string[]
  chartData: ChartDataType[]
}

type ModalProps = {
  showModal: boolean
  handleClose: () => void
  groupName: string
  labels: string[]
  chartData: ChartDataType[]
  chartType: 'bar' | 'line'
}

function ShowDetailedGraphModel(props: ModalProps) {
  const [cData, setCData] = useState(props.chartData)
  const [isAllSelected, setIsAllSelected] = useState<boolean>(true)
  const [isActual, setIsActual] = useState<boolean>(true)
  const [isProjected, setIsProjected] = useState<boolean>(true)
  const initialLegends = _.cloneDeep(
    props.chartData.map((d) => ({
      name: d.label,
      selected: true,
      backgroundColor: d.backgroundColor,
    }))
  )
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  const legends: LegendType[] = initialLegends
  const [newLegends, setNewLegends] = useState(legends)

  const reDrawChart = (legends: LegendType[]) => {
    const filteredNames = legends.filter((o) => !o.selected).map((i) => i.name) || []
    filteredNames.length !== props.chartData.length && setIsAllSelected(false)
    filteredNames.length === 0 && setIsAllSelected(true)

    filteredNames.filter((legend) => legend.toLowerCase()?.includes('actual'))?.length !==
      initialLegends.filter((legend) => legend.name?.toLowerCase()?.includes('actual'))?.length &&
      setIsActual(false)
    filteredNames.filter((legend) => legend?.toLowerCase()?.includes('actual'))?.length === 0 &&
      setIsActual(true)

    filteredNames.filter((legend) => legend?.toLowerCase()?.includes('projected'))?.length !==
      initialLegends.filter((legend) => legend.name?.toLowerCase()?.includes('projected'))
        ?.length && setIsProjected(false)
    filteredNames.filter((legend) => legend?.toLowerCase()?.includes('projected'))?.length === 0 &&
      setIsProjected(true)
    const others = props.chartData.filter((chart) => !filteredNames.includes(chart.label))
    setCData([...others])
  }

  const allLegend = (filter: string) => {
    switch (filter) {
      case 'actual': {
        if (isActual) {
          const actualData = newLegends.map((legend) => {
            if (legend.name?.toLowerCase()?.includes('actual')) {
              return { ...legend, selected: false }
            }
            return legend
          })
          setNewLegends(actualData)
          reDrawChart(actualData)
          setIsAllSelected(false)
        } else {
          const actualData = newLegends.map((legend) => {
            if (legend.name?.toLowerCase()?.includes('actual')) {
              return { ...legend, selected: true }
            }
            return legend
          })
          setNewLegends(actualData)
          reDrawChart(actualData)
        }
        return
      }
      case 'projected': {
        if (isProjected) {
          const projectedData = newLegends.map((legend) => {
            if (legend.name?.toLowerCase()?.includes('projected')) {
              return { ...legend, selected: false }
            }
            return legend
          })
          setNewLegends(projectedData)
          reDrawChart(projectedData)
          setIsAllSelected(false)
        } else {
          const projectedData = newLegends.map((legend) => {
            if (legend.name?.toLowerCase()?.includes('projected')) {
              return { ...legend, selected: true }
            }
            return legend
          })
          setNewLegends(projectedData)
          reDrawChart(projectedData)
        }
        return
      }
      case 'all': {
        if (!isAllSelected) {
          setNewLegends(legends)
          reDrawChart(legends)
          setIsActual(true)
          setIsProjected(true)
        } else {
          setNewLegends(newLegends.map((l) => ({ ...l, selected: false })))
          reDrawChart(newLegends.map((l) => ({ ...l, selected: false })))
          setIsActual(false)
          setIsProjected(false)
        }
      }
    }
  }

  return (
    <Modal
      id='kt_modal_show_graph'
      dialogClassName='modal-dialog modal-dialog-centered min-w-85'
      show={props.showModal}
      onHide={() => props.handleClose()}
    >
      <div className='col d-flex justify-content-between px-9 py-4'>
        <div className='pt-4'>
          <h2 className='fw-bolder text-dark'>{props.groupName}</h2>
        </div>

        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-2'
          type='button'
          title='Close'
          onClick={() => props.handleClose()}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>
      <div className='separator '></div>

      <Modal.Body className='shadow p-0'>
        <div className='card'>
          <div className='card-body p-9 pt-4 '>
            <div className='d-flex flex-column flex-md-row align-items-center'>
              <div className='col-12 col-md-8'>
                {cData && (
                  <Chart
                    type='bar'
                    data={{ labels: props.labels, datasets: cData }}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: { position: 'bottom', display: false },
                        title: { display: false, text: props.groupName || '', font: { size: 16 } },
                        tooltip: {
                          callbacks: {
                            label: function (tooltipItem) {
                              let label = tooltipItem.dataset.label || ''
                              if (label) {
                                label += ': '
                              }

                              return (
                                label + Number(tooltipItem.raw).toLocaleString(userCurrencyFormat)
                              )
                            },
                          },
                        },
                      },
                      scales: {
                        y: {
                          type: 'linear',
                          display: true,
                          position: 'left',

                          ticks: {
                            callback: function (value, index, ticks) {
                              return Number(value).toLocaleString(userCurrencyFormat)
                            },
                          },
                        },
                        y1: {
                          type: 'linear',
                          display: true,
                          position: 'right',
                          ticks: {
                            callback: function (value, index, ticks) {
                              return value + '%'
                            },
                          },
                          grid: {
                            drawOnChartArea: false,
                          },
                        },
                      },
                    }}
                  />
                )}
              </div>
              <div className='col-12 col-md-4 mx-4 border-2 border-start border-gray-400'>
                <div
                  className='card px-5 py-2'
                  style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'thin' }}
                >
                  <div className='d-flex justify-content-end mx-5 gap-4'>
                    <div className='d-flex align-items-center mb-2'>
                      <input
                        type='checkbox'
                        onClick={(e: any) => {
                          setIsActual(e.target.checked)
                          allLegend('actual')
                        }}
                        checked={isActual}
                        className='cursor-pointer me-1 col-1 form-check-input h-20px w-20px '
                      />
                      <p className='mb-0'> {'Actual'}</p>
                    </div>
                    <div className='d-flex align-items-center mb-2'>
                      <input
                        type='checkbox'
                        onClick={(e: any) => {
                          setIsProjected(e.target.checked)
                          allLegend('projected')
                        }}
                        checked={isProjected}
                        className='cursor-pointer me-1 col-1 form-check-input h-20px w-20px '
                      />
                      <p className='mb-0'> {'Projected'}</p>
                    </div>
                    <div className='d-flex align-items-center mb-2'>
                      <input
                        type='checkbox'
                        onClick={(e: any) => {
                          setIsAllSelected(e.target.checked)
                          allLegend('all')
                        }}
                        checked={isAllSelected}
                        className='cursor-pointer me-1 col-1 form-check-input h-20px w-20px '
                      />
                      <p className='mb-0'> {'Select All'}</p>
                    </div>
                  </div>
                  <div className='separator my-2'></div>
                  <div className='h-400px overflow-auto'>
                    {newLegends.map((legend: LegendType, index) => {
                      const patternBackground = `url(${generatePatternBackground(
                        legend.backgroundColor
                      )})`
                      return (
                        <>
                          <div key={index} className='d-flex flex-row align-items-center mb-4'>
                            <input
                              type='checkbox'
                              onClick={() => {
                                const updatedLegends = newLegends.map((l) =>
                                  l.name === legend.name ? { ...l, selected: !l.selected } : l
                                )
                                setNewLegends(updatedLegends)
                                reDrawChart(updatedLegends)
                              }}
                              checked={legend.selected}
                              className='cursor-pointer me-1 col-1 form-check-input h-20px w-20px rounded'
                            />

                            <div
                              className='icon-wrapper mx-1'
                              style={{
                                width: '18px',
                                height: '18px',
                                borderRadius: '50%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: `${
                                  typeof legend.backgroundColor === 'string'
                                    ? legend.backgroundColor
                                    : patternBackground
                                } `,
                              }}
                            />
                            <p
                              className='text-truncate mb-0'
                              data-toggle='tooltip'
                              data-placement='top'
                              style={{ maxWidth: '300px' }}
                              title={legend.name}
                            >
                              {' '}
                              {legend.name}
                            </p>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

function MultipleChart({ groupName, labels, chartData }: ChartProps) {
  const [cData, setCData] = useState(chartData)
  const [showModel, setShowModel] = useState<boolean>(false)
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const reDrawChart = (legends: LegendType[]) => {
    const filteredNames = legends?.filter((o) => !o.selected).map((i) => i.name) || []
    const others = chartData.filter((chart) => !filteredNames.includes(chart.label))
    setCData([...others])
  }

  return (
    <>
      <div className='d-flex flex-row justify-content-between align-items-end'>
        <div>
          <button
            className='btn align-center btn-icon btn-sm btn-secondary shadow-sm'
            type='button'
            data-bs-toggle='tooltip'
            title='Max view'
            onClick={() => setShowModel(!showModel)}
          >
            <KTSVG
              className='svg-icon-2x text-primary'
              path='/media/icons/duotune/general/expand-screen.svg'
            />
          </button>
        </div>
        <div>
          <h3 className='d-flex fs-4 fw-semibold'>{groupName}</h3>
        </div>
        <div>
          {
            <button
              type='button'
              className={`px-4 btn-sm rounded border border-1 border-gray-400 bg-transparent fs-4 p-1 text-gray-800`}
              data-kt-menu-trigger='click'
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              {'Legends'}
            </button>
          }
          <CategorySelectionView
            groupName={groupName}
            legends={chartData.map((d) => ({
              name: d.label,
              selected: cData.map((chart) => chart.label)?.includes(d.label) ? true : false,
              backgroundColor: d.backgroundColor,
            }))}
            legendData={chartData.map((d) => ({
              name: d.label,
              selected: true,
              backgroundColor: d.backgroundColor,
            }))}
            reDrawChart={reDrawChart}
          />
        </div>
      </div>
      {showModel && (
        <ShowDetailedGraphModel
          showModal={showModel}
          groupName={groupName}
          chartData={chartData}
          handleClose={() => setShowModel(false)}
          chartType='bar'
          labels={labels}
        />
      )}
      {cData && (
        <>
          <Chart
            type='bar'
            data={{ labels: labels, datasets: cData }}
            options={{
              responsive: true,
              plugins: {
                legend: { position: 'bottom', display: false },
                title: { display: false, text: groupName || '', font: { size: 16 } },
                tooltip: {
                  callbacks: {
                    label: function (tooltipItem) {
                      let label = tooltipItem.dataset.label || ''
                      if (label) {
                        label += ': '
                      }

                      return label + Number(tooltipItem.raw).toLocaleString(userCurrencyFormat)
                    },
                  },
                },
              },
              scales: {
                y: {
                  type: 'linear',
                  display: true,
                  position: 'left',
                  ticks: {
                    callback: function (value, index, ticks) {
                      return Number(value).toLocaleString(userCurrencyFormat)
                    },
                  },
                },
                y1: {
                  type: 'linear',
                  display: true,
                  position: 'right',
                  ticks: {
                    callback: function (value, index, ticks) {
                      return value + '%'
                    },
                  },
                  grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                  },
                },
              },
            }}
          />
          <LabelView
            groupName={groupName}
            legends={chartData.map((d) => ({
              name: d.label,
              selected: cData.map((chart) => chart.label)?.includes(d.label) ? true : false,
              backgroundColor: d.backgroundColor,
            }))}
            legendData={chartData.map((d) => ({
              name: d.label,
              selected: true,
              backgroundColor: d.backgroundColor,
            }))}
            reDrawChart={reDrawChart}
          />
        </>
      )}
    </>
  )
}

type CategoryViewProps = {
  legends: LegendType[]
  legendData: LegendType[]
  groupName: string
  reDrawChart: Function
}

type LegendType = {
  name: string
  selected: boolean
  backgroundColor?: string | CanvasPattern
}

function CategorySelectionView({ legends, legendData, reDrawChart, groupName }: CategoryViewProps) {
  const [newLegends, setNewLegends] = useState(legends)
  const [isAllSelected, setIsAllSelected] = useState<boolean>(true)
  const [isActual, setIsActual] = useState<boolean>(true)
  const [isProjected, setIsProjected] = useState<boolean>(true)

  function checkAllLegends(updatedValue: LegendType[]) {
    const filteredNames = updatedValue.filter((legend) => legend.selected === false) || []
    filteredNames.length !== legends.length && setIsAllSelected(false)
    filteredNames.length === 0 && setIsAllSelected(true)

    filteredNames.filter((legend) => legend.name?.toLowerCase()?.includes('actual'))?.length !==
      legends.filter((legend) => legend.name?.toLowerCase()?.includes('actual'))?.length &&
      setIsActual(false)
    filteredNames.filter((legend) => legend.name?.toLowerCase()?.includes('actual'))?.length ===
      0 && setIsActual(true)
    filteredNames.filter((legend) => legend.name?.toLowerCase()?.includes('projected'))?.length !==
      legends.filter((legend) => legend.name?.toLowerCase()?.includes('projected'))?.length &&
      setIsProjected(false)
    filteredNames.filter((legend) => legend.name?.toLowerCase()?.includes('projected'))?.length ===
      0 && setIsProjected(true)
  }

  useEffect(() => {
    setNewLegends(legends)
  }, [legends])

  const allLegend = (filter: string) => {
    switch (filter) {
      case 'actual': {
        if (isActual) {
          const actualData = newLegends.map((legend) => {
            if (legend.name?.toLowerCase()?.includes('actual')) {
              return { ...legend, selected: false }
            }
            return legend
          })
          setNewLegends(actualData)
          reDrawChart(actualData)
          checkAllLegends(actualData)
          setIsAllSelected(false)
        } else {
          const actualData = newLegends.map((legend) => {
            if (legend.name?.toLowerCase()?.includes('actual')) {
              return { ...legend, selected: true }
            }
            return legend
          })
          setNewLegends(actualData)
          reDrawChart(actualData)
          checkAllLegends(actualData)
        }
        return
      }
      case 'projected': {
        if (isProjected) {
          const projectedData = newLegends.map((legend) => {
            if (legend.name?.toLowerCase()?.includes('projected')) {
              return { ...legend, selected: false }
            }
            return legend
          })
          setNewLegends(projectedData)
          reDrawChart(projectedData)
          checkAllLegends(projectedData)
          setIsAllSelected(false)
        } else {
          const projectedData = newLegends.map((legend) => {
            if (legend.name?.toLowerCase()?.includes('projected')) {
              return { ...legend, selected: true }
            }
            return legend
          })
          setNewLegends(projectedData)
          reDrawChart(projectedData)
          checkAllLegends(projectedData)
        }
        return
      }
      case 'all': {
        if (!isAllSelected) {
          setNewLegends(legendData)
          reDrawChart(legendData)
          setIsActual(true)
          setIsProjected(true)
          checkAllLegends(legendData)
        } else {
          setNewLegends(newLegends.map((l) => ({ ...l, selected: false })))
          reDrawChart(newLegends.map((l) => ({ ...l, selected: false })))
          setIsActual(false)
          setIsProjected(false)
          checkAllLegends(newLegends.map((l) => ({ ...l, selected: false })))
        }
      }
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 border border-1 border-gray-400 menu-state-bg-light-primary fw-bold mw-400px h-250px py-3'
      data-kt-menu='true'
    >
      <div className='d-flex flex-row'>
        <div className='col-12'>
          <div className='d-flex justify-content-end mx-5 gap-4'>
            <div className='d-flex align-items-center mb-2'>
              <input
                type='checkbox'
                onClick={(e: any) => {
                  setIsActual(e.target.checked)
                  allLegend('actual')
                }}
                checked={isActual}
                className='cursor-pointer me-1 col-1 form-check-input h-20px w-20px '
              />
              <p className='mb-0'> {'Actual'}</p>
            </div>
            <div className='d-flex align-items-center mb-2'>
              <input
                type='checkbox'
                onClick={(e: any) => {
                  setIsProjected(e.target.checked)
                  allLegend('projected')
                }}
                checked={isProjected}
                className='cursor-pointer me-1 col-1 form-check-input h-20px w-20px '
              />
              <p className='mb-0'> {'Projected'}</p>
            </div>
            <div className='d-flex align-items-center mb-2'>
              <input
                type='checkbox'
                onClick={(e: any) => {
                  setIsAllSelected(e.target.checked)
                  allLegend('all')
                }}
                checked={isAllSelected}
                className='cursor-pointer me-1 col-1 form-check-input h-20px w-20px '
              />
              <p className='mb-0'> {'Select All'}</p>
            </div>
          </div>
          <div className='separator mb-1'></div>
          <div
            className='card h-200px px-5 py-2'
            style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'thin' }}
          >
            {newLegends.map((legend: LegendType, index) => {
              const patternBackground = `url(${generatePatternBackground(legend.backgroundColor)})`
              return (
                <div key={index} className='d-flex flex-row align-items-center mb-3'>
                  <input
                    type='checkbox'
                    onClick={() => {
                      const updatedValue = newLegends.map((l) =>
                        l.name === legend.name ? { ...l, selected: !l.selected } : l
                      )
                      setNewLegends(updatedValue)
                      reDrawChart(updatedValue)
                      checkAllLegends(updatedValue)
                    }}
                    checked={legend.selected}
                    className='cursor-pointer me-1 col-1 form-check-input h-20px w-20px '
                  />
                  <div
                    className='icon-wrapper px-1'
                    style={{
                      width: '18px',
                      height: '18px',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: `${
                        typeof legend.backgroundColor === 'string'
                          ? legend.backgroundColor
                          : patternBackground
                      } `,
                    }}
                  ></div>
                  <p
                    className='text-truncate mb-0'
                    data-toggle='tooltip'
                    data-placement='top'
                    style={{ maxWidth: '300px' }}
                    title={legend.name}
                  >
                    {' '}
                    {legend.name}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

function LabelView({ legends, reDrawChart, groupName }: CategoryViewProps) {
  const [newLegends, setNewLegends] = useState(legends)

  useEffect(() => {
    setNewLegends(legends)
  }, [legends])

  return (
    <div>
      <div
        className='card bg-transparent h-100px px-5 py-2 my-5'
        style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}
      >
        <div className='d-flex flex-wrap'>
          {newLegends.map((legend: LegendType, index) => {
            const patternBackground = `url(${generatePatternBackground(legend.backgroundColor)})`
            return (
              <div
                key={index}
                className='cursor-pointer d-flex flex-row align-items-center my-2'
                onClick={() => {
                  const updatedValue = newLegends.map((l) =>
                    l.name === legend.name ? { ...l, selected: !l.selected } : l
                  )
                  setNewLegends(updatedValue)
                  reDrawChart(updatedValue)
                }}
              >
                <div
                  className='icon-wrapper mx-1'
                  style={{
                    width: '18px',
                    height: '18px',
                    borderRadius: '50%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: `${
                      typeof legend.backgroundColor === 'string'
                        ? legend.backgroundColor
                        : patternBackground
                    } `,
                  }}
                />
                <p
                  className={`text-truncate mb-0 ${!legend.selected ? 'strike' : ''}`}
                  data-toggle='tooltip'
                  data-placement='top'
                  style={{ maxWidth: '275px' }}
                  title={legend.name}
                >
                  {' '}
                  {legend.name}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const generatePatternBackground = (pattern: any) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (ctx) {
    const patternCanvas = document.createElement('canvas')
    const patternCtx = patternCanvas.getContext('2d')

    patternCanvas.width = 100
    patternCanvas.height = 100

    // Draw the pattern
    if (patternCtx) {
      patternCtx.fillStyle = pattern
      patternCtx.fillRect(0, 0, patternCanvas.width, patternCanvas.height)
    }

    canvas.width = patternCanvas.width
    canvas.height = patternCanvas.height
    ctx.fillStyle = ctx.createPattern(patternCanvas, 'repeat') || ''
    ctx.fillRect(0, 0, canvas.width, canvas.height)

    return canvas.toDataURL() // Return the data URL
  }

  return ''
}

export default ChartComponent
