import { useFormik } from 'formik'
import { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import { useEmailTermsheetMutation } from '../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../queries/client'
import { KTSVG } from '../../../../../_metronic/helpers'
import { emailTermsheetSchema, inits } from './EmailTermsheetHelper'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../utils/Select'
import { MultiSelectOptionType } from '../../../../../utils/MultiSelect'
import Toast, { ToastType } from '../../../../../utils/toast'
import { useNavigate } from 'react-router-dom'

type Props = {
  showModal?: boolean
  handleClose: () => void
  termsheetId: number
  type:string
}

function EmailTermsheet({ handleClose, showModal,type, termsheetId }: Props) {
  const [showAdditionalField, setShowAdditionalField] = useState<boolean>(false)
  const [emailType, setEmailType] = useState<any>({ value: 1, label: 'Company' })
  const [emailType1, setEmailType1] = useState<any>({ value: 2, label: 'Co-investor' })
  const [emails1, setEmails1] = useState('')
  const [emails, setEmails] = useState('')
  const mutateEmailTermsheet = useEmailTermsheetMutation<Error>(graphqlRequestClient, {}, {})
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: inits,
    validationSchema: emailTermsheetSchema,
    onSubmit: async (values) => {
      let input = {}
      let docType = type === "noType" ? "Termsheet":"NBA"
      if (values.companyEmails !== '' && values.investorsEmails !== '') {
        input = {
          companyEmail: values.companyEmails?.split(';'),
          investorEmail: values.investorsEmails?.split(';'),
          termsheetId: termsheetId,
          type:docType

        }
      } else if (values.companyEmails === '') {
        input = {
          investorEmail: values.investorsEmails?.split(';'),
          termsheetId: termsheetId,
          type:docType
        }
      } else if (values.investorsEmails === '') {
        input = {
          companyEmail: values.companyEmails?.split(';'),
          termsheetId: termsheetId,
          type:docType
        }
      }
      mutateEmailTermsheet.mutate(
        {
          input: input,
        },
        {
          onSuccess: (result) => {
            handleClose()
            Toast(`${result.emailTermsheet.msg}`, ToastType.success)
            navigate('/deals')
          },
          onError: (error: any) => {
            Toast('Error While Sending Email', ToastType.error)
          },
        }
      )
    },
  })

  const emailOptions: MultiSelectOptionType[] = [
    { value: 1, label: 'Company' },
    { value: 2, label: 'Co-investor' },
  ]
  return (
    <Modal
      id='kt_modal_email_termsheet'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px '
      show={showModal}
      onHide={() => {
        handleClose()
        setShowAdditionalField(false)
      }}
    >
      <ModalHeader>
        <h2 className='fw-bolder text-dark'>Email Document</h2>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={() => {
            formik.resetForm()
            setEmails('')
            setEmails1('')
            setShowAdditionalField(false)
            handleClose()
          }}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          <div className='mb-5'>Enter Email ID’s to whom Document is to be sent</div>
          <div className='d-flex mb-5'>
            <div className='col-6 me-3'>
              <label className='form-label'>Email ID</label>
              <input
                type='text'
                className='form-control form-control-solid form-control-sm'
                placeholder='Enter Email ID'
                onChange={(e) => {
                  setEmails1(e.target.value)
                }}
              />
            </div>
            <div className='col-4'>
              <label className='form-label'>Receiver Type</label>
              <Select
                options={emailOptions}
                className='w-100'
                onChange={(e: any) => {
                  setEmailType(e)
                  if (e.value === 1) {
                    formik.setFieldValue('companyEmails', emails1)
                    formik.setFieldValue('investorsEmails', emails)
                    setEmailType1({ value: 2, label: 'Co-investor' })
                  } else if (e.value === 2) {
                    formik.setFieldValue('investorsEmails', emails1)
                    formik.setFieldValue('companyEmails', emails)
                    setEmailType1({ value: 1, label: 'Company' })
                  }
                }}
                placeholder='Select Receiver Type'
                styles={selectCustomStyles}
              />
              {emailType.value === emailType1.value ? (
                <div className='mt-1 text-danger'>Receiver type cannot be same</div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className='mb-5'>
            <KTSVG
              path='/media/icons/duotune/general/gen044.svg'
              className='svg-icon-primary svg-icon-2x me-2'
            />
            Separate multiple Email IDs with a semicolon
          </div>
          {showAdditionalField && (
            <>
              <div className='d-flex mb-5'>
                <div className='col-6 me-3'>
                  <label className='form-label'>Email ID</label>
                  <input
                    type='text'
                    placeholder='Enter Email ID'
                    className='form-control form-control-solid form-control-sm'
                    onChange={(e) => {
                      setEmails(e.target.value)
                    }}
                  />
                </div>
                <div className='col-4'>
                  <label className='form-label'>Receiver Type</label>
                  <Select
                    options={emailOptions}
                    placeholder='Select Receiver Type'
                    className='w-100'
                    styles={selectCustomStyles}
                    onChange={(e: any) => {
                      setEmailType1(e)
                      if (e.value === 1) {
                        formik.setFieldValue('companyEmails', emails)
                        formik.setFieldValue('investorsEmails', emails1)
                      } else if (e.value === 2) {
                        formik.setFieldValue('investorsEmails', emails)
                        formik.setFieldValue('companyEmails', emails1)
                      }
                    }}
                  />
                  {emailType.value === emailType1.value ? (
                    <div className='mt-1 text-danger'>Receiver type cannot be same</div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className='mb-5'>
                <KTSVG
                  path='/media/icons/duotune/general/gen044.svg'
                  className='svg-icon-primary svg-icon-2x me-2'
                />
                Separate multiple Email IDs with a semicolon
              </div>
            </>
          )}
          <div className='mb-5'>
            <button
              onClick={() => {
                setShowAdditionalField(!showAdditionalField)
              }}
              type='button'
              className='btn border border-primary text-primary'
            >
              {!showAdditionalField ? 'ADD +' : 'REMOVE'}
            </button>
          </div>
          <div className='d-flex justify-content-between'>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={() => {
                formik.resetForm()
                setEmails('')
                setEmails1('')
                setShowAdditionalField(!showAdditionalField)
                handleClose()
                navigate('/deals')
              }}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={emailType.value === emailType1.value || mutateEmailTermsheet.isLoading}
            >
              {!mutateEmailTermsheet.isLoading && 'Send'}
              {mutateEmailTermsheet.isLoading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Sending...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
              {mutateEmailTermsheet.isSuccess && mutateEmailTermsheet.isLoading && 'Sent'}
            </button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

export default EmailTermsheet
