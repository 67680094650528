import { PdfBitmap, PdfDocument, PdfPageOrientation, PdfPageSettings, PdfSection, SizeF } from '@syncfusion/ej2-pdf-export';
import { CustomToolbarItemModel, DocumentEditorContainerComponent, ImageFormat, Inject, Toolbar, ToolbarItem } from '@syncfusion/ej2-react-documenteditor'

type Props={
    documentReference:any,
    documentName:string
}
function SyncfusionDocumentEditor({documentName,documentReference}:Props) {
    let toolItem: CustomToolbarItemModel = {
        prefixIcon: "e-save icon",
        tooltipText: "Download the Document",
        text: "Download",
        id: "Download",
        cssClass:"ms-2"
      };
      let items: (CustomToolbarItemModel | ToolbarItem)[] = ['Redo', 'Undo', 'Find', 'RestrictEditing',toolItem]
      const handleToolbarClick =(e:any)=>{
        if(e.item.id === 'Download'){
          documentReference?.current!.documentEditor.save(documentName,'Docx')
        }
      }
  return (
    <DocumentEditorContainerComponent
        height='580px'
        enableToolbar={true}
        autoResizeOnVisibilityChange
        ref={documentReference}
        toolbarItems={items}
        showPropertiesPane={false}
        serviceUrl='https://syncfusion-api.hissa.com/api/documenteditor/'
        toolbarClick={(e)=>{handleToolbarClick(e)}}
      >
        <Inject services={[Toolbar]}></Inject>
      </DocumentEditorContainerComponent>
  )
}
export async function fetchDocumentFile(url:string){
  try{
    const blobFile = await fetch(url).then((response)=>{return response.blob()})
      let formData = new FormData()
      formData.append('files', blobFile)
      const fetchOptions = {
        method: 'POST',
        body: formData,
      }
      return await fetch('https://syncfusion-api.hissa.com/api/documenteditor/Import', fetchOptions)
        .then((response) => {
            if (response.status === 200 || response.status === 304) {
              return response.json()
            } else {
              throw new Error('Request failed with status: ' + response.status)
            }
          })
          .then((data) => {
            return {
              sfdt: data.sfdt,
            }
          })
        .catch((error) => {
          console.error('Fetch error:', error)
          return error
        })
  }catch(error){
    console.log(error)
  }
}
export function syncfusionDocumentToPDF(editorReference:DocumentEditorContainerComponent | any):Promise<Blob | null>{
  return new Promise(async (resolve) => {
    const pdfdocument: PdfDocument = new PdfDocument()
    const count: number = editorReference.current?.documentEditor.pageCount
    editorReference.current!.documentEditor.documentEditorSettings.printDevicePixelRatio = 2
    let loadedPage = 0
    for (let i = 1; i <= count; i++) {
      const format: ImageFormat = 'image/jpeg' as ImageFormat
      // Getting pages as image
      const image = editorReference.current?.documentEditor.exportAsImage(i, format)
      image.onload = async function () {
        const imageHeight = parseInt(image.style.height.toString().replace('px', ''))
        const imageWidth = parseInt(image.style.width.toString().replace('px', ''))
        const section: PdfSection = pdfdocument.sections.add() as PdfSection
        const settings: PdfPageSettings = new PdfPageSettings(0)
        if (imageWidth > imageHeight) {
          settings.orientation = PdfPageOrientation.Landscape
        }
        settings.size = new SizeF(imageWidth, imageHeight)
        ;(section as PdfSection).setPageSettings(settings)
        const page = section.pages.add()
        const graphics = page.graphics
        const imageStr = image.src.replace('data:image/jpeg;base64,', '')
        const pdfImage = new PdfBitmap(imageStr)
        graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight)
        loadedPage++
        if (loadedPage == count) {
          // Exporting the document as pdf
          const blobData = await pdfdocument.save().then(async (p: { blobData: Blob }) => {
            return p.blobData
          })
          resolve(blobData)
        }
      }
    }
  })
}
export default SyncfusionDocumentEditor