import { AgGridReact } from 'ag-grid-react'
import { memo, useContext, useMemo, useRef, useState } from 'react'
import { agConfigSideBar, tableCellStyle } from '../../../../../utils/AgGridConfig'
import { ColDef } from 'ag-grid-community'
import {
  AllTransactionsType,
  CompanyObject,
  IndirectTransactionObject,
  TransactionObject,
  Transaction_Type,
} from '../../../../../generated/graphql'
import {
  DrawdownRender,
  DateOfInvestmentRender,
  SchemeNameRender,
  NumOfUnitsRender,
  TagsRender,
  UnitTypeRender,
  InvestmentAmountRender,
  EstimatedFMVRender,
  IndirectTransactionActionRender,
} from './IndirectCompanyTransactionAGComponents'

import { GlobalContext } from '../../../store/context/globalContext'
import { getCurrency, getSymbolOfCurrency } from '../../../../../utils/CurrencyFormatter'
import { KTSVG } from '../../../../../_metronic/helpers'

type TransactionProps = {
  data: AllTransactionsType
}

export function IndirectCompanyTransactionAGTable(props: TransactionProps) {
  const gridApi = useRef<any>(null)
  const transactionData: IndirectTransactionObject[] = props.data
    .indirectTransactionData as IndirectTransactionObject[]

  let companyDetails: CompanyObject = props.data.companyData as CompanyObject

  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const componentsRegistry = useMemo(
    () => ({
      schemeName: memo(SchemeNameRender),
      unitType: memo(UnitTypeRender),
      noOfUnits: memo(NumOfUnitsRender),
      drawdown: memo(DrawdownRender),
      investmentAmount: memo(InvestmentAmountRender),
      estimatedFMV: memo(EstimatedFMVRender),
      dateOfInvestment: memo(DateOfInvestmentRender),
      tags: memo(TagsRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <p className='fs-base text-muted text-center'>No Transactions found in this company.</p>
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  // const [dataToggle, setDataToggle] = useState('investments')
  // const [showModal, setShowModal] = useState<boolean>(false)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Scheme Name',
        field: 'schemeName',
        cellRenderer: 'schemeName',
        cellStyle: tableCellStyle,
        pinned: 'left',
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        wrapText: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Unit Type',
        field: 'unitType',
        filter: 'agTextColumnFilter',
        cellRenderer: 'unitType',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'No. of Units',
        field: 'noOfUnits',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'noOfUnits',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipNoOfUnits',
      },
      {
        headerName: `Drawdown (${getSymbolOfCurrency(userCurrency)})`,
        field: 'drawdown',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'drawdown',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipDrawdown',
      },
      {
        headerName: `Investment (${getSymbolOfCurrency(userCurrency)})`,
        field: 'investmentAmount',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'investmentAmount',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipAmountInvested',
      },
      {
        headerName: `Estimated FMV	 (${getSymbolOfCurrency(userCurrency)})`,
        field: 'estimatedFMV',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'estimatedFMV',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipEstimatedFMV',
      },
      {
        headerName: 'Investment Date',
        field: 'dateOfInvestment',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (dateFromFilter: Date, cellValue: any) => {
            if (cellValue == null) {
              return 0
            }
            const dateParts = cellValue.split('-')
            const day = Number(dateParts[2]?.split('T')[0])
            const month = Number(dateParts[1]) - 1
            const year = Number(dateParts[0])
            const cellDate = new Date(year, month, day)
            if (cellDate < dateFromFilter) {
              return -1
            } else if (cellDate > dateFromFilter) {
              return 1
            }
            return 0
          },
        },
        cellRenderer: 'dateOfInvestment',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Tags',
        field: 'tags',
        filter: 'agTextColumnFilter',
        cellRenderer: 'tags',
        cellStyle: tableCellStyle,
        sortable: true,
        hide: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        hide: false,
        width: 60,
        maxWidth: 60,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: {
          'padding-left': '9px',
          'padding-right': '4px',
          'padding-top': '3px',
          'line-height': '20px',
        },
        resizable: false,
        sortable: false,
        cellRenderer: IndirectTransactionActionRender,
      },
    ],
    [userCurrencyFormat]
  )

  const rowData = useMemo(
    () =>
      transactionData?.map((transaction: IndirectTransactionObject) => {
        return {
          schemeName: transaction.indirectVehicleName,
          unitType: transaction.unitType,
          noOfUnits: transaction.noOfUnits,
          drawdown: Math.abs(transaction.drawDown || 0),
          tooltipDrawdown: Math.abs(transaction.drawDown || 0).toLocaleString(userCurrencyFormat),
          tooltipAmountInvested: Math.abs(transaction.transactionAmount).toLocaleString(
            userCurrencyFormat
          ),
          investmentAmount: transaction.transactionAmount,
          dateOfInvestment: transaction.transactionDate,
          estimatedFMV: transaction.currentValue,
          tooltipNoOfUnits: Math.abs(transaction.noOfUnits).toLocaleString(userCurrencyFormat),
          tooltipEstimatedFMV: Math.abs(transaction.currentValue).toLocaleString(
            userCurrencyFormat
          ),
          tags: transaction.tags || [],
          userCurrencyFormat: userCurrencyFormat,
          userCurrency: userCurrency,
          transactionDetails: transaction,
          companyDetails: companyDetails,
          isIndirect:
            props.data.indirectTransactionData && props.data.indirectTransactionData.length > 0
              ? true
              : false,
          isLastInvestment: transactionData.length === 1 ? true : false,
        }
      }),
    [transactionData, userCurrencyFormat]
  )

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode
    const filteredData = data.childrenAfterAggFilter.map((element: any) => element.data)
    const totalInvested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.investmentAmount,
      0
    )
    const totalFMV = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.estimatedFMV,
      0
    )

    const noOfUnits = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.noOfUnits,
      0
    )
    const totalDrawdown = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.drawdown,
      0
    )
    api.setPinnedBottomRowData([
      {
        schemeName: 'Total',
        unitType: null,
        noOfUnits: noOfUnits,
        drawdown: Math.abs(totalDrawdown || 0),
        tooltipDrawdown: Math.abs(totalDrawdown || 0).toLocaleString(userCurrencyFormat),
        tooltipAmountInvested: Math.abs(totalInvested).toLocaleString(userCurrencyFormat),
        investmentAmount: totalInvested,
        dateOfInvestment: null,
        estimatedFMV: totalFMV,
        tooltipNoOfUnits: Math.abs(noOfUnits).toLocaleString(userCurrencyFormat),
        tooltipEstimatedFMV: Math.abs(totalFMV).toLocaleString(userCurrencyFormat),
        tags: null,
        userCurrencyFormat: userCurrencyFormat,
        userCurrency: userCurrency,
        transactionDetails: null,
        companyDetails: companyDetails,
        isIndirect:
          props.data.indirectTransactionData && props.data.indirectTransactionData.length > 0
            ? true
            : false,
        isLastInvestment: transactionData.length === 1 ? true : false,
      },
    ])
  }

  return (
    <div
      className='card p-0 h-600px'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          <h1 className='m-0'>My Transactions</h1>
          <span className='text-muted'>
            {transactionData.length > 0 ? `${transactionData.length} Transactions` : ''}
          </span>
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          onRowDataUpdated={setPinnedBottomRowData}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={true}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
