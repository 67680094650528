import { useState } from 'react'
import {
  DealTemplate,
  GetDealTemplatesQuery,
  GetTemplateDocumentQuery,
  GetUrlForS3ObjectQuery,
  useGetDealTemplatesQuery,
  useGetTemplateDocumentQuery,
  useGetUrlForS3ObjectQuery,
} from '../../../../generated/graphql'
import _ from 'lodash'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { graphqlRequestClient } from '../../../../queries/client'
import { isoToDate } from '../../../../utils/FormatDate'
import { hexToRGB } from '../../../../utils/HexToRgba'
import { getInitials } from '../../../../utils/Utils'
import TemplateUploader from '../../open-deals/components/termsheet-builder/TemplateUploader'
import TemplateAGTable from '../components/template-builder/TemplateAGTable'
import { TemplateActionRender } from '../components/template-builder/TemplateAGComponents'
import { Link, useNavigate } from 'react-router-dom'

function TemplateCards({ templateData }: { templateData: DealTemplate | null }) {
  const navigate = useNavigate()
  const [showModel, setShowModel] = useState(false)
  const [download, setDownload] = useState<boolean>(false)
  const { data, isFetched } = useGetTemplateDocumentQuery<GetTemplateDocumentQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        templateId: templateData?.id,
        templateName: templateData?.name,
      },
    },
    { enabled: download }
  )
  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    {
      input: {
        key: templateData?.thumbnailURL,
        bucket: process.env.REACT_APP_S3_INVESTOR_BUCKET || '',
      },
    },
    {}
  )
  if (isFetched && download) {
    let a = document.createElement('a')
    a.href = data?.getTermsheetTemplate?.url
    a.download = 'testFile.docx'
    a.click()
    setDownload(false)
  }
  const tagMapping = [
    {
      name: 'Governance Rights',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 1),
    },
    {
      name: 'Investor Rights',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 1),
    },
    {
      name: 'Founder Restrictions',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 2), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 2), 1),
    },
    {
      name: 'Exit Rights',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 3), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 3), 1),
    },
    {
      name: 'Miscellaneous',
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 0.2),
      foregroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 1),
    },
  ]

  let tags = templateData?.sections?.map((section: any) => {
    let tag = tagMapping.find((tag) => tag.name === section)
    if (tag) {
      return (
        <span
          key={section}
          className={`badge fs-9 fw-semibold mb-1 me-2`}
          style={{ background: tag.backgroundColor, color: tag.foregroundColor }}
        >
          {tag.name}
        </span>
      )
    }
  })

  let uniqCompanies: any[] = _.uniq(templateData?.companiesIssuedTo!)
  let companyIssuedTo: any[] = []
  if (uniqCompanies.length > 5) {
    uniqCompanies = ['+ ' + (uniqCompanies.length - 5).toString()].concat(uniqCompanies.slice(0, 5))
    companyIssuedTo =
      uniqCompanies.length > 0
        ? uniqCompanies.map((company, index) => {
            return (
              <div className='symbol symbol-35px symbol-circle cursor-pointer'>
                <span
                  className={'symbol-label fw-bolder fs-8 bg-secondary cursor-pointer'}
                  style={{ color: getCSSVariableValue('--kt-donut-' + ((index + 9) % 10)) }}
                >
                  {getInitials(company)}
                </span>
              </div>
            )
          })
        : []
  }
  const state = {
    genericData:templateData,templateId:templateData?.id
  }
  return(
    <div className='card shadow-sm mw-375px h-405px mx-2 card-hover p-0'>
      <div className='card-body'>
        <div className='d-flex justify-content-between py-2'>
          <h3 className='mt-3 '>{templateData?.name}</h3>
          <TemplateActionRender data={templateData} />
        </div>
        <div className='card card-body border p-1 h-225px overflow-hidden' onClick={()=>{
      navigate('/deals/sendtermsheet',{state:state})
    }}>
          <img
            alt=''
            className='w-100'
            src={
              templateData?.thumbnailURL
                ? query.data?.urlForS3Object!
                : toAbsoluteUrl('/media/illustrations/misc/termsheet2.png')
            }
          />
        </div>
        <div className='mt-1'>
          <p className='fs-8'>Features</p>
          <p className='fs-9 h-25px'>
            {tags}{' '}
            {!tags || tags?.length === 0 ? (
              <span className={`badge fs-9 fw-semibold mb-1 me-2`}>
                <br />
              </span>
            ) : (
              <></>
            )}
          </p>
        </div>
        <div className='pt-2'>
          <span className='fs-8'>Last Issued On -</span>
          <span className='float-end fs-base'>
            {templateData?.lastIssuedDate !== null && templateData?.lastIssuedDate !== ''
              ? isoToDate(templateData?.lastIssuedDate!)
              : 'NA'}
          </span>
        </div>
        {/* <div className='mt-2'>
          <span className='fs-8 pt-5 mt-5'> Issued To -</span>

          {companyIssuedTo?.map((c) => (
            <span className='float-end fs-base'>{c}</span>
          ))}
        </div> */}
        {/* <div className='pt-2'> */}
        {/* <button
              className='float-end btn btn-lg btn-icon'
              onClick={() => {
                setDownload(true)
              }}
            >
              <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-1' />
            </button> */}
        {/* <button
            className='float-end btn btn-sm btn-icon'
            onClick={() => {
              setShowModel(true)
            }}
          >
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
          </button>
        </div> */}
      </div>
    </div>
  )
}

function TemplatePageWrapper() {
  const [showModel, setShowModel] = useState(false)
  const [listView, setListView] = useState<boolean>(false)
  const [gridView, setGridView] = useState<boolean>(true)
  const { data } = useGetDealTemplatesQuery<GetDealTemplatesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )
  const gridViewData:any[] = [];
  data?.dealTemplates?.forEach((template) => {
      if (template?.name === "Default Template") {
          gridViewData.unshift(template);
      } else {
          gridViewData.push(template);
      }
  });
  
  const types: any[] = []
  data?.dealTemplates?.forEach((template) => {
    const json = JSON.parse(template?.jsonData)
    types.push(json[0].type)
  })
  const ty = types.reduce(function (prev, cur) {
    prev[cur] = (prev[cur] || 0) + 1
    return prev
  }, {})
  return (
    <>
      <div className='d-flex justify-content-between'>
        <div>
          <h1>Template</h1>
          <div><Link to={'/deals'}>Home</Link> {`>`} Template</div>
        </div>
        <div>
          <button
            className='btn btn-primary'
            onClick={() => {
              setShowModel(true)
            }}
          >
            Add Template
          </button>
          {showModel && (
            <TemplateUploader
              showModal={true}
              handleClose={() => {
                setShowModel(false)
              }}
            />
          )}
        </div>
      </div>
      <div className='card rounded-5 mt-10 p-10'>
        <div className='d-flex justify-content-between   p-2'>
          <div>
            <h1>Template</h1>
            <div>
              {Object.keys(ty).map((t) => {
                if(data?.dealTemplates?.length === Object.keys(ty).length){
                  return ty[t] + ' ' + t + 'Type'
                }else{
                return ty[t] + ' ' + t + 'Type' + ','}
              })}
            </div>
          </div>
          <div className='d-flex'>
            <button
              className={`btn p-2 shadow-sm btn-icon me-2 ${
                listView ? 'btn-active-light-primary' : ''
              }`}
              onClick={() => {
                setGridView(false)
                setListView(true)
              }}
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
            </button>
            <button
              className={`btn p-2 shadow-sm btn-icon ${
                gridView ? 'btn-active-light-primary btn-light-primary ' : ''
              }`}
              onClick={() => {
                setGridView(true)
                setListView(false)
              }}
            >
              <KTSVG path='/media/icons/duotune/general/gen008.svg' className=' svg-icon-2' />
            </button>
          </div>
        </div>
        {listView && <div>
          <div
      className="w-full max-h-full  ag-theme-material h-500px "
    >
      <TemplateAGTable dealTemplates={data?.dealTemplates || []}/>
    </div></div>}
        {gridView && (
          <div className='card-body container'>
            <div className='row'>
              {data &&
                gridViewData &&
                gridViewData.map((data, index) => (
                  <div className='col-4 pt-4'>
                    <TemplateCards templateData={data} key={index} />
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default TemplatePageWrapper
