import { AllTransactionsType, CompanyTypes, RecentCompanyType } from '../../../../generated/graphql'
import { Link, useNavigate } from 'react-router-dom'
import { CompanyTypeBadge } from '../../../../utils/CompanyTypeBadge'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { getInitials } from '../../../../utils/Utils'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useCompanyNameStore } from '../../../../store/companyNameStore'

type RecentCompaniesProps = {
  allTransactions: AllTransactionsType[]
  recentCompanyList: RecentCompanyType[]
}

export function RecentCompanies(props: RecentCompaniesProps) {
  const companyWiseData = getTopCompaniesData(props.recentCompanyList)
  const navigate = useNavigate()
  const companyNameStore = useCompanyNameStore()

  const investmentTableData = companyWiseData.map(
    (company: CompanyWiseGrowthType, index: number) => {
      return (
        <tr key={index}>
          <td className='min-w-150px'>
            <div className='d-flex flex-row'>
              <span className=''>
                {company?.logo && company?.logo !== '' && company?.logo !== 'NA' ? (
                  <div className='symbol symbol-40px h-40px w-40px symbol-fixed position-relative'>
                    <img src={company?.logo} alt='' />
                  </div>
                ) : (
                  <div className='bg-secondary rounded h-40px w-40px fw-bold d-flex justify-content-center align-items-center'>
                    <p
                      className='mb-0 fs-1'
                      style={{
                        color: getCSSVariableValue('--kt-donut-' + ((index + 9) % 10)),
                      }}
                    >
                      {getInitials(company.name)}
                    </p>
                  </div>
                )}
              </span>
              <span className='ps-4'>
                <Link
                  className='fs-base fw-bold mb-0 text-gray-800 text-hover-primary'
                  to={`/company/summary/${company.type !== 'HISSA' ? 'DIRECT' : 'HISSA'}/${
                    company.hissaCompanyId || company.id
                  }`}
                  onClick={() => {
                    companyNameStore.setCompanyName(company?.name || '')
                  }}
                >
                  <h6
                    className='pt-3 m-0 text-truncate fw-bold text-hover-primary'
                    data-toggle='tooltip'
                    data-placement='top'
                    title={company.name?.toUpperCase()}
                    style={{ maxWidth: '160px' }}
                  >
                    {company.name?.toUpperCase()}
                  </h6>
                </Link>
                <span className='text-muted'>{company.dba}</span>
              </span>
            </div>
          </td>
          <td className='min-w-75px text-center pe-0'>
            <p className='fs-base text-end mb-0'>
              <CompanyTypeBadge companyType={company.type} />
            </p>
          </td>
        </tr>
      )
    }
  )
  return (
    <div
      className='card h-400px m-0 p-0'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <span className='px-9 pt-8'>
        <h2 className='card-label fw-bold mb-1'>Recently Visited Companies</h2>
        {/* <span className='text-muted fw-semibold fs-7'>Top 5 Companies</span> */}
      </span>

      <div className='card px-9 overflow-auto my-9'>
        {companyWiseData.length > 0 ? (
          <div
            className='table-responsive p-0 '
            style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}
          >
            <table className='table align-middle gs-0 gy-3 table-row-dashed border-gray-200 align-items-baseline'>
              <tbody>{investmentTableData}</tbody>
            </table>
          </div>
        ) : (
          <div className='text-center'>
            <img
              alt='no items'
              src={toAbsoluteUrl('/media/custom-images/emptyRecentCompanies.svg')}
              className='h-150px my-6 w-200 justify-content-center'
            />
            <h4>No companies have been viewed</h4>
            <div className='fw-semibold fs-6'>Click below to know more about companies</div>
            <button
              type='button'
              className='btn btn-primary mt-4'
              onClick={() => {
                navigate('/portfolio')
              }}
            >
              Go to Portfolio
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

type CompanyWiseGrowthType = {
  name: string
  id?: number
  type: string
  hissaCompanyId?: string
  logo?: string
  visitedDateTime?: string
  dba?: string
}

function getTopCompaniesData(recentCompanies: RecentCompanyType[]) {
  const companyWiseData: CompanyWiseGrowthType[] = []
  recentCompanies?.forEach((company) => {
    const compData: CompanyWiseGrowthType = {
      name: company.name || '',
      id: company.type !== CompanyTypes.Hissa ? company.id : null,
      hissaCompanyId:
        company.type === CompanyTypes.Hissa ? company.hissaCompanyId || '' : undefined,
      type: company.type === CompanyTypes.Hissa ? 'HISSA' : 'MANUAL',
      logo: company.logo || undefined,
      visitedDateTime: company.visitedDateTime || new Date().toISOString(),
    }
    companyWiseData.push(compData)
  })
  return companyWiseData
}
