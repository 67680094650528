import { Bar, Pie } from 'react-chartjs-2'
import pattern from 'patternomaly'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartType } from 'chart.js'
import _ from 'lodash'
import { hexToRGB } from '../../../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import {
  formatNumber,
  getCurrency,
  getSymbolOfCurrency,
} from '../../../../../utils/CurrencyFormatter'
import { useContext } from 'react'
import { GlobalContext } from '../../../store/context/globalContext'

ChartJS.register(ArcElement, Tooltip, Legend)

export default function PrePostPieChart({
  labels,
  values,
  grayedOutIndex,
  header,
}: {
  labels: string[]
  values: number[]
  grayedOutIndex?: number[]
  header: string
}) {
  // const currencyType = getCurrencyType();
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  let exitedTextList: number[] = []

  const options: any = {
    rotation: 0,
    circumference: 360,
    maintainAspectRatio: false,
    responsive: true,
    radius: '100%',
    plugins: {
      tooltip: {
        enabled: true,
        usePointStyle: true,
        bodyFont: {
          weight: 'bold',
          size: 14,
        },
        callbacks: {
          afterLabel: (context: any) =>
            grayedOutIndex?.includes(context.dataIndex) ? '(New Shareholder)' : '',
          label: (context: any) => {
            const label = context.label || ''
            const value = context.raw || 0
            // return grayedOutIndex?.includes(context.dataIndex)
            //   ? `${label}: ${formatNumber({
            //       value,
            //       format: userCurrencyFormat,
            //     })}`
            //   : `${label}: ${formatNumber({
            //       value,
            //       format: userCurrencyFormat,
            //     })}`;

            return `${label}: ${formatNumber({
              value,
              format: userCurrencyFormat,
            })}`
          },
        },
        titleColor: '#464E5F',
        bodyColor: '#464E5F',
        footerColor: '#464E5F',
        footerFont: {
          weight: 'bold',
          size: 10,
        },
        displayColors: true,
        backgroundColor: '#FFFFFF',
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          boxWidth: 10,
        },
      },
    },
  }

  let plugins = [
    {
      id: 'htmlLegend',
      afterUpdate(chart: ChartJS) {
        const items = chart.options.plugins?.legend?.labels?.generateLabels?.(chart)
        const ul = document.createElement('ul')
        // Set display property of ul to flex and flex-wrap to wrap
        ul.style.display = 'flex'
        ul.style.flexWrap = 'wrap'
        ul.style.listStyleType = 'none' // Remove default list styles

        items?.forEach((item, index) => {
          const li = document.createElement('li')
          li.style.alignItems = 'center'
          li.style.cursor = 'pointer'
          li.style.display = 'flex'
          li.style.flexDirection = 'row'
          li.style.paddingBottom = '5px'
          li.style.marginRight = '10px' // Add margin between list items
          li.style.marginBottom = '10px' // Add margin between rows

          const boxSpan = document.createElement('span')
          const fillColor = hexToRGB(
            getCSSVariableValue('--kt-donut-' + `${index < 20 ? index : index % 20}`),
            index < 20 ? 1 : 0.8
          )
          const backgroundColor = grayedOutIndex?.includes(index)
            ? pattern.draw('line', fillColor, 'white', 10)
            : fillColor

          if (typeof backgroundColor === 'string') {
            boxSpan.style.background = backgroundColor
          } else {
            boxSpan.style.background = fillColor
          }

          boxSpan.style.borderColor = item.strokeStyle?.toString()!
          boxSpan.style.borderWidth = item.lineWidth + 'px'
          boxSpan.style.borderRadius = '50px'
          boxSpan.style.display = 'inline-block'
          boxSpan.style.height = '15px'
          boxSpan.style.width = '15px'
          boxSpan.style.marginRight = '5px' // Add margin between box and text

          const textContainer = document.createElement('p')
          textContainer.style.color = item.fontColor?.toString()!
          textContainer.style.margin = '0'
          textContainer.style.padding = '0'
          textContainer.style.textDecoration = item.hidden ? 'line-through' : ''
          textContainer.appendChild(document.createTextNode(item.text))

          li.appendChild(boxSpan)
          li.appendChild(textContainer)

          li.onclick = () => {
            const i = (item as any).index
            const exitIndex = exitedTextList.indexOf(i)
            if (exitIndex !== -1) exitedTextList.splice(exitIndex, 1)
            else exitedTextList.push(i)
            const dataset = chart.data.datasets[0].data
            let exitedCount = 0
            exitedTextList.forEach((e) => {
              exitedCount += (dataset[e] as number) || 0
            })

            chart.toggleDataVisibility(index)
            item.hidden = !item.hidden
            chart.update()
          }

          ul.appendChild(li)
        })

        const jsLegend = document.getElementById('chart-legend-' + header.replaceAll(' ', ''))
        if (jsLegend) {
          jsLegend.innerHTML = ''
          jsLegend!.appendChild(ul)
        }
      },
    },
  ]

  const data = {
    maintainAspectRatio: false,
    responsive: true,
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: values.map((element, index) => {
          const fillColor = hexToRGB(
            getCSSVariableValue('--kt-donut-' + `${index < 20 ? index : index % 20}`),
            index < 20 ? 1 : 0.8
          )
          return grayedOutIndex?.includes(index)
            ? pattern.draw('line', fillColor, 'white', 10)
            : fillColor
        }),

        borderWidth: 0.8,
        borderRadius: 5,
      },
    ],
  }

  return (
    <div
      className={`card h-400px`}
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <h2 className='card-label fw-bold ps-9 pt-9'>{header}</h2>

      <div className={`card-body min-h-250px mh-250px`}>
        {options ? <Pie options={options} data={data} plugins={plugins} /> : <p></p>}
      </div>
      <div
        className='h-150px overflow-auto'
        id={'chart-legend-' + header.replaceAll(' ', '')}
        style={{ overflowY: 'auto', scrollbarWidth: 'none' }}
      ></div>
    </div>
  )
}
