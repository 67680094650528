import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import {
  AllTransactionsType,
  TransactionObject,
  Transaction_Type,
} from '../../../../../generated/graphql'

type InvestmentData = {
  type: Transaction_Type
  value: number
  quarter: string
  companyName: string
  roundName: string
  investorName: string
}
type IndustryData = {
  type: Transaction_Type
  value: number
  quarter: string
  companyName: string
  industry: string
}

type FundData = {
  type: Transaction_Type
  value: number
  quarter: string
  companyName: string
  investorName: string
}

export function getQuarters(allTransactions: AllTransactionsType[]) {
  const allCompanyTransactions: TransactionObject[] = []
  allTransactions.forEach((comp) => {
    if (comp.transactionData) {
      allCompanyTransactions.push(...(comp.transactionData as TransactionObject[]))
    }
  })
  let quarters: string[] = []
  const sortedTransactions = allCompanyTransactions.sort((a, b) =>
    a.transactionDate.localeCompare(b.transactionDate)
  )
  if (sortedTransactions.length > 0) {
    sortedTransactions.forEach((transaction) => {
      if (transaction.transactionType === Transaction_Type.Buy || transaction.transactionType === Transaction_Type.SecondaryBuy || transaction.transactionType === Transaction_Type.Sell) {
        const quarter = getQuarterYear(transaction.transactionDate)
        if (!quarters.includes(`${quarter.quarter} ${quarter.year}`)) {
          quarters.push(`${quarter.quarter} ${quarter.year}`)
        }
      }
    })
  }
  return quarters
}

function getQuarterYear(dateString: string) {
  const date = new Date(dateString)
  const quarter = `Q${Math.floor(date.getMonth() / 3) + 1}` // Quarters are 1-indexed
  const year = date.getFullYear()
  return { quarter, year }
}

export type ChartData = {
  label: string
  stack: string
  data: (undefined | number)[]
  backgroundColor?: string
}

export function getInvExitsChartData(allTransactions: AllTransactionsType[], quarters: string[]) {
  const investmentData: InvestmentData[] = []
  const industryData: IndustryData[] = []
  let finalIndustryData: IndustryData[] = []
  const fundData: FundData[] = []
  let finalFundData: FundData[] = []
  const quarterMapping: { [key: string]: number } = {}
  const allInvestors: string[] = []
  allTransactions.forEach((company) => {
    const investorData = company.investorData
    if (investorData && investorData.length > 0) {
      const investors = investorData.map((inv) => inv?.name)
      investors.forEach((inv) => {
        if (inv && !allInvestors.includes(inv)) {
          allInvestors.push(inv)
        }
      })
    }
  })

  allInvestors.length > 0 &&
    allInvestors.forEach((investor) => {
      quarters.forEach((currentQuarter) => {
        fundData.push(
          {
            type: Transaction_Type.Buy,
            value: 0,
            quarter: currentQuarter,
            companyName: '',
            investorName: investor,
          },
          {
            type: Transaction_Type.Sell,
            value: 0,
            quarter: currentQuarter,
            companyName: '',
            investorName: investor
          }
        )
        quarterMapping[currentQuarter] = 0
      })
    })

  allTransactions.forEach((company) => {
    company.transactionData?.forEach((transaction) => {
      if (transaction && transaction.transactionType === Transaction_Type.Sell) {
        const quarter = getQuarterYear(transaction.transactionDate)

        investmentData.push({
          type: transaction.transactionType,
          value: Math.abs(transaction.amount),
          quarter: `${quarter.quarter} ${quarter.year}`,
          companyName: company.companyData.name,
          roundName: 'Secondary',
          investorName: transaction.investorName || '',
        })
      } else if (
        transaction &&
        (transaction.transactionType === Transaction_Type.Buy ||
          transaction.transactionType === Transaction_Type.SecondaryBuy)
      ) {
        const quarter = getQuarterYear(transaction.transactionDate)
        investmentData.push({
          type: transaction.transactionType,
          value: Math.abs(transaction.amount),
          quarter: `${quarter.quarter} ${quarter.year}`,
          companyName: company.companyData.name,
          roundName: transaction.round || '',
          investorName: transaction.investorName || '',
        })
      }
    })

    quarters.forEach((currentQuarter) => {
      industryData.push(
        {
          type: Transaction_Type.Buy,
          value: 0,
          quarter: currentQuarter,
          companyName: company.companyData.name,
          industry: '',
        },
        {
          type: Transaction_Type.Sell,
          value: 0,
          quarter: currentQuarter,
          companyName: company.companyData.name,
          industry: '',
        }
      )
    })

    company.transactionData?.forEach((transaction) => {
      if (transaction) {
        const quarter = getQuarterYear(transaction.transactionDate!)
        if (transaction && transaction.transactionType === Transaction_Type.Sell) {
          const index = industryData.findIndex(
            (data) =>
              data.quarter === `${quarter.quarter} ${quarter.year}` &&
              data.type === Transaction_Type.Sell &&
              data.companyName === company.companyData.name
          )
          if (index > -1) {
            industryData[index].value += Math.abs(transaction.amount)
            industryData[index].industry = company.companyData.industry || ''
          }
        } else if (
          transaction.transactionType === Transaction_Type.Buy ||
          transaction.transactionType === Transaction_Type.SecondaryBuy
        ) {
          const index = industryData.findIndex(
            (data) =>
              data.quarter === `${quarter.quarter} ${quarter.year}` &&
              data.type === Transaction_Type.Buy &&
              data.companyName === company.companyData.name
          )
          if (index > -1) {
            industryData[index].value += Math.abs(transaction.amount)
            industryData[index].industry = company.companyData.industry || ''
          }
        }
      }
    })
    finalIndustryData = industryData.filter((industry) => industry.value > 0)

    company.transactionData?.forEach((transaction) => {
      if (transaction) {
        const quarter = getQuarterYear(transaction.transactionDate!)
        if (transaction && transaction.transactionType === Transaction_Type.Sell) {
          const index = fundData.findIndex(
            (data) =>
              data.quarter === `${quarter.quarter} ${quarter.year}` &&
              data.type === Transaction_Type.Sell &&
              data.investorName === transaction.investorName
          )

          if (index > -1) {
            fundData[index].value += Math.abs(transaction.amount)
            fundData[index].companyName = company.companyData.name
            fundData[index].investorName = transaction.investorName || ''
          }
        } else if (
          transaction.transactionType === Transaction_Type.Buy ||
          transaction.transactionType === Transaction_Type.SecondaryBuy
        ) {
          const index = fundData.findIndex(
            (data) =>
              data.quarter === `${quarter.quarter} ${quarter.year}` &&
              data.type === Transaction_Type.Buy &&
              data.investorName === transaction.investorName
          )

          if (index > -1) {
            fundData[index].value += Math.abs(transaction.amount)
            fundData[index].companyName = company.companyData.name
            fundData[index].investorName = transaction.investorName || ''
          }
        }
      }
    })

    finalFundData = fundData.filter((industry) => industry.value > 0)
  })

  const fillColors: string[] = []
  for (let i = 0; i < 10; i++) {
    const color = getCSSVariableValue('--kt-donut-' + i)
    fillColors.push(color)
  }

  let investmentSeriesData: ChartData[] = []
  investmentData.forEach((data, index) => {
    if (data.type === Transaction_Type.Buy || data.type === Transaction_Type.SecondaryBuy) {
      const quarterIndex = quarters.findIndex((quarter) => quarter === data.quarter)
      const dataArray = Array.from({ length: quarters.length }, () => 0)
      dataArray[quarterIndex] = data.value
      const seriesObj: ChartData = {
        label: `${data.companyName} ${data.roundName} ${data.investorName}`,
        stack: 'investment',
        data: dataArray,
        backgroundColor: fillColors[quarterMapping[data.quarter] % fillColors.length],
      }
      quarterMapping[data.quarter] += 1
      investmentSeriesData.push(seriesObj)
    } else if (data.type === Transaction_Type.Sell) {
      const quarterIndex = quarters.findIndex((quarter) => quarter === data.quarter)
      const dataArray = Array.from({ length: quarters.length }, () => 0)
      dataArray[quarterIndex] = data.value
      const seriesObj: ChartData = {
        label: `${data.investorName}
        ${data.companyName} Sale`,
        stack: 'exit',
        data: dataArray,
        backgroundColor: '#E45B5B',
      }
      investmentSeriesData.push(seriesObj)
    }
  })
  Object.keys(quarterMapping).forEach((key) => (quarterMapping[key] = 0))

  const industryColorsMapping: { [key: string]: string } = {}
  const fundColorsMapping: { [key: string]: string } = {}

  let industrySeriesData: ChartData[] = []
  finalIndustryData.forEach((data, index) => {
    if (data.type === Transaction_Type.Buy || data.type === Transaction_Type.SecondaryBuy) {
      const quarterIndex = quarters.findIndex((quarter) => quarter === data.quarter)
      const dataArray = Array.from({ length: quarters.length }, () => 0)
      dataArray[quarterIndex] = data.value
      if (!industryColorsMapping[data.industry]) {
        industryColorsMapping[data.industry] = fillColors[Object.keys(industryColorsMapping).length % fillColors.length]
      }
      const industryObj: ChartData = {
        label: `${data.industry || 'Others'}`,
        stack: 'investment',
        data: dataArray,
        backgroundColor: industryColorsMapping[data.industry],
      }
      quarterMapping[data.quarter] += 1
      industrySeriesData.push(industryObj)
    } else if (data.type === Transaction_Type.Sell) {
      const quarterIndex = quarters.findIndex((quarter) => quarter === data.quarter)
      const dataArray = Array.from({ length: quarters.length }, () => 0)
      dataArray[quarterIndex] = data.value
      const industryObj: ChartData = {
        label: `${data.industry || 'Others'} `,
        stack: 'exit',
        data: dataArray,
        backgroundColor: '#E45B5B',
      }
      industrySeriesData.push(industryObj)
    }
  })

  Object.keys(quarterMapping).forEach((key) => (quarterMapping[key] = 0))
  let fundSeriesData: ChartData[] = []
  finalFundData.forEach((data, index) => {
    if (data.type === Transaction_Type.Buy || data.type === Transaction_Type.SecondaryBuy) {
      const quarterIndex = quarters.findIndex((quarter) => quarter === data.quarter)
      const dataArray = Array.from({ length: quarters.length }, () => 0)
      dataArray[quarterIndex] = data.value
      if (!fundColorsMapping[data.investorName]) {
        fundColorsMapping[data.investorName] = fillColors[Object.keys(fundColorsMapping).length % fillColors.length]
      }
      const fundObj: ChartData = {
        label: `${data.investorName}`,
        stack: 'investment',
        data: dataArray,
        backgroundColor: fundColorsMapping[data.investorName],
      }
      quarterMapping[data.quarter] += 1
      fundSeriesData.push(fundObj)
    } else if (data.type === Transaction_Type.Sell) {
      const quarterIndex = quarters.findIndex((quarter) => quarter === data.quarter)
      const dataArray = Array.from({ length: quarters.length }, () => 0)
      dataArray[quarterIndex] = data.value
      const fundObj: ChartData = {
        label: `${data.investorName}`,
        stack: 'exit',
        data: dataArray,
        backgroundColor: '#E45B5B',
      }
      fundSeriesData.push(fundObj)
    }
  })
  investmentSeriesData = investmentSeriesData.map((obj) => {
    obj.data = obj.data.map((num) => (num === 0 ? undefined : num))
    return obj
  })
  industrySeriesData = industrySeriesData.map((obj) => {
    obj.data = obj.data.map((num) => (num === 0 ? undefined : num))
    return obj
  })
  fundSeriesData = fundSeriesData.map((obj) => {
    obj.data = obj.data.map((num) => (num === 0 ? undefined : num))
    return obj
  })

  const portfolioData = {
    investmentSeriesData: investmentSeriesData.sort((a, b) => b.stack.localeCompare(a.stack)),
    industrySeriesData: industrySeriesData.sort((a, b) => b.stack.localeCompare(a.stack)),
    fundSeriesData: fundSeriesData.sort((a, b) => b.stack.localeCompare(a.stack)),
  }

  return portfolioData
}
