import { create } from 'zustand'
import { io } from 'socket.io-client'

import { ExitModellingDataModel } from './InvestmentModellingHelper'

export type IMState = {
  IMData: ExitModellingDataModel
  setIMData: (data: ExitModellingDataModel) => void
  connect: () => void
  sendMessage: (message: ExitModellingDataModel) => void
  recieveMessage: (key: string) => void
  disconnect: () => void
  reset: (data: ExitModellingDataModel) => void
}

const exitModellinginitialState = {
  IMData: {
    finalHoldingPercentage: 0,
    numberOfRounds: 0,
    dilutionPerRound: 0,
    finalInvestmentAmount: 0,
    currentInvestmentDetails: {
      dilution: 0,
      valuation: 0,
      investment: 0,
      holdingPercentage: 0,
      date: '',
      roundSize: 0,
      revenue: 0,
      currentValue: 0,
    },
    tableDetails: [],
    heatMap: [],
    nonPriceRoundHeatMap: [],
    valuationCap: 0,
    companyFdbShares: 0,
    finalCurrentValue: 0,
  },
}

const URL = process.env.REACT_APP_SOCKET_URL || 'ws://localhost:4000/'
let socket = io(URL, {
  extraHeaders: {
    accesstoken: localStorage.getItem('accessToken') || '-',
    companyId: localStorage.getItem('companyId') || '-',
  },
})

const IMDataStore = create<IMState>((set: any) => ({
  ...exitModellinginitialState,
  setIMData: (data: any) => {
    set((state: any) => ({ IMData: data }))
  },
  connect: () => {
    socket = io(URL, {
      // extraHeaders: {
      //   accesstoken: useAuthStore.getState().accessToken || "-",
      //   companyId: useAuthStore.getState().companyId || "-",
      // },
    })
    socket.connect()
  },
  disconnect: () => {
    socket.disconnect()
  },
  sendMessage: (message: any) => {
    socket.emit('message', message)

    socket.on('message', (data: any) => {
      set((state: any) => ({ IMData: JSON.parse(data) }))
    })
  },
  recieveMessage: (key: any) => {
    socket.on(key, (data: any) => {
      set((state: any) => ({ IMData: JSON.parse(data) }))
    })
  },
  reset: (message: any) => {
    const discardData = {
      ...message,
      hasToDiscard: true,
    }
    socket.emit('message', discardData)
    set(() => exitModellinginitialState)
  },
}))

export const useIMDataStore = IMDataStore
