import { Col, Modal, Row } from 'react-bootstrap'
import { useIsMutating } from 'react-query'
import { KTSVG } from '../../../_metronic/helpers'
import { graphqlRequestClient, queryClient } from '../../../queries/client'
import { RemoveEeMetricMutation, useRemoveEeMetricMutation, useGetEeMetricsQuery, RemoveEeMetricMutationVariables } from '../../../generated/graphql'
import Toast, { ToastType } from '../../../utils/toast'

type Props = {
  showRemoveModal: boolean
  toggleRemoveModal: () => void
  metric: any
}

function RemoveEEMetric({ showRemoveModal, toggleRemoveModal, metric }: Props) {
  const removeMutate = useRemoveEeMetricMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (data: RemoveEeMetricMutation, _variables: RemoveEeMetricMutationVariables, _context: unknown) => {
        queryClient.invalidateQueries(useGetEeMetricsQuery.getKey())
        Toast(data.removeEEMetric.message, ToastType.success)
        toggleRemoveModal()
      },
      onError: (error: any) => Toast(error.response.errors[0].message, ToastType.error),
    },
    {}
  )

  function handleSubmit(e: any) {
    e.preventDefault()
    e.stopPropagation()
    removeMutate.mutate({ input: { id: metric.id } })
  }

  return (
    <Modal id='kt_modal_remove_metric' aria-hidden='true' show={showRemoveModal} onHide={toggleRemoveModal}>
      <form onSubmit={handleSubmit}>
        <Modal.Header className='p-2'>
          <Modal.Title className='ps-5'>Remove Metric</Modal.Title>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={toggleRemoveModal}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='me-n7 pe-7'>
            <div className='fs-3 pb-6'>Are you sure you want to remove this metric?</div>
            <Row>
              <Col>ID:</Col>
              <Col>{metric && metric.id}</Col>
            </Row>
            <Row>
              <Col>Name:</Col>
              <Col>{metric && metric.name}</Col>
            </Row>
            <Row>
              <Col>Type:</Col>
              <Col>{metric && metric.type.charAt(0) + metric.type.toLowerCase().substring(1)}</Col>
            </Row>
            <Row>
              <Col>Moniker / Acronym:</Col>
              <Col>{metric && metric.moniker}</Col>
            </Row>
            <Row>
              <Col>Tags:</Col>
              <Col>
                {metric &&
                  metric.tags.map((tag: any, i: any) => (
                    <span key={i} className='badge badge-light-info fw-semibold me-1'>
                      {tag}
                    </span>
                  ))}
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <div className='d-flex justify-content-between'>
            <button type='button' className='btn btn-sm btn-secondary me-3' onClick={toggleRemoveModal}>
              Cancel
            </button>
            <button type='submit' className='btn btn-sm btn-primary' disabled={useIsMutating() > 0}>
              {useIsMutating() === 0 && 'Remove Metric'}
              {useIsMutating() > 0 && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default RemoveEEMetric
