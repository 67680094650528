import {
  AllTransactionsType,
  CompanyTypes,
  IndirectTransactionObject,
  TransactionObject,
  Transaction_Type,
} from '../../../../generated/graphql'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils/DomHelpers'
import DonutWithCloseButton from '../../dashboard/components/DonutWithCloseButton'

type Props = {
  allTransactions: AllTransactionsType[]
  header: string
  type: DonutChartType
  className: string
  isIndirect?: boolean
}

export enum DonutChartType {
  Stage = 'Stage',
  Industry = 'Industry',
  Size = 'Size',
  Shareholders = 'Shareholders',
  Securities = 'Securities',
  Units = 'Units',
  Shares = 'Shares',
  Portfolio = 'Portfolio',
}

export function DonutChartWrapper(props: Props) {
  const chartData = getChartData(props.allTransactions, props.type)
  return (
    <DonutWithCloseButton
      header={props.header}
      data={chartData}
      initialCount={chartData.datasets[0].count}
      isCurrency={props.type === DonutChartType.Stage}
      className={props.className}
      isIndirect={props.isIndirect}
    />
  )
}

export type ChartDataType = {
  label: string
  value: number
  id?: string
  type?: CompanyTypes
  hissaCompanyId?: string
}

function getChartData(allTransactions: AllTransactionsType[], type: DonutChartType) {
  const allCompanyTransactions: TransactionObject[] = []
  const allCompanyIndirectTransactions: IndirectTransactionObject[] = []
  allTransactions.forEach((comp) => {
    if (comp.transactionData) {
      allCompanyTransactions.push(
        ...(comp.transactionData && (comp.transactionData as TransactionObject[]))
      )
      if (comp.indirectTransactionData)
        allCompanyIndirectTransactions.push(
          ...(comp.indirectTransactionData as IndirectTransactionObject[])
        )
    }
  })

  let data: ChartDataType[] = []

  if (type === DonutChartType.Industry) {
    allTransactions.forEach((comp) => {
      if (comp.companyData.industry) {
        const industryLabel = comp.companyData.industry.toUpperCase()
        const findIndustryIndex = data.findIndex((industry) => industry.label === industryLabel)
        if (findIndustryIndex !== -1) {
          data[findIndustryIndex].value += 1
        } else {
          data.push({
            value: 1,
            label: industryLabel,
          })
        }
      } else {
        const findIndex = data.findIndex((industry) => industry.label === 'UNCLASSIFIED')
        if (findIndex !== -1) {
          data[findIndex].value += 1
        } else {
          data.push({
            value: 1,
            label: 'UNCLASSIFIED',
          })
        }
      }
    })
    data = data
      .filter((d) => d.label !== 'UNCLASSIFIED')
      .concat(data.filter((d) => d.label === 'UNCLASSIFIED'))
  } else if (type === DonutChartType.Stage) {
    data.push(
      { value: 0, label: 'Primary Investments' },
      { value: 0, label: 'Secondary Investments' },
      { value: 0, label: 'Indirect Investments' }
    )
    allCompanyTransactions.forEach((transaction) => {
      if (
        transaction.transactionType === Transaction_Type.Buy &&
        transaction.companyType !== CompanyTypes.Indirect
      ) {
        const findIndex = data.findIndex((stage) => stage.label === 'Primary Investments')
        if (findIndex !== -1) {
          data[findIndex].value += transaction.amount || 0
        } else {
          data.push({
            value: transaction.amount || 0,
            label: 'Primary Investments',
          })
        }
      } else if (transaction.transactionType === Transaction_Type.SecondaryBuy) {
        const findIndex = data.findIndex((stage) => stage.label === 'Secondary Investments')
        if (findIndex !== -1) {
          data[findIndex].value += transaction.amount || 0
        } else {
          data.push({
            value: transaction.amount || 0,
            label: 'Secondary Investments',
          })
        }
      } else if (
        transaction.transactionType === Transaction_Type.Buy &&
        transaction.companyType === CompanyTypes.Indirect
      ) {
        const findIndex = data.findIndex((stage) => stage.label === 'Indirect Investments')
        if (findIndex !== -1) {
          data[findIndex].value += transaction.amount || 0
        } else {
          data.push({
            value: transaction.amount || 0,
            label: 'Indirect Investments',
          })
        }
      }
    })
  } else if (type === DonutChartType.Portfolio) {
    data = [
      { label: '< 1mn', value: 0 },
      { label: '1-3mn', value: 0 },
      { label: '3-10mn', value: 0 },
      { label: '10-25mn', value: 0 },
      { label: '> 25mn', value: 0 },
    ]
    // Static hardcoded values for INR
    // TODO: Update range based on user currency
    allTransactions.forEach((company) => {
      if (company.companyData.valuation && company.companyData.valuation < 1000000) {
        const invObj = data.find((company) => company.label === '< 1mn')
        if (invObj) invObj.value += 1
      } else if (
        company.companyData.valuation &&
        company.companyData.valuation >= 1000000 &&
        company.companyData.valuation < 3000000
      ) {
        const invObj = data.find((company) => company.label === '1-3mn')
        if (invObj) invObj.value += 1
      } else if (
        company.companyData.valuation &&
        company.companyData.valuation >= 3000000 &&
        company.companyData.valuation < 10000000
      ) {
        const invObj = data.find((company) => company.label === '3-10mn')
        if (invObj) invObj.value += 1
      } else if (
        company.companyData.valuation &&
        company.companyData.valuation >= 10000000 &&
        company.companyData.valuation < 25000000
      ) {
        const invObj = data.find((company) => company.label === '10-25mn')
        if (invObj) invObj.value += 1
      } else if (company.companyData.valuation && company.companyData.valuation >= 25000000) {
        const invObj = data.find((company) => company.label === '> 25mn')
        if (invObj) invObj.value += 1
      }
    })
  } else if (type === DonutChartType.Shareholders) {
    const shareholders = allTransactions[0].companyData.shareholders
    shareholders?.forEach((shareholder) => {
      if (shareholder?.type) {
        const findIndex = data.findIndex((type) => type.label === shareholder?.type?.toUpperCase())
        if (findIndex !== -1) {
          data[findIndex].value += 1
        } else {
          data.push({
            value: 1,
            label: shareholder?.type?.toUpperCase(),
          })
        }
      } else {
        const findIndex = data.findIndex((type) => type.label === 'OTHERS')
        if (findIndex !== -1) {
          data[findIndex].value += 1
        } else {
          data.push({
            value: 1,
            label: 'OTHERS',
          })
        }
      }
    })
  } else if (type === DonutChartType.Securities) {
    const securities = allTransactions[0].companyData.securities || []
    securities.forEach((security) => {
      if (security?.securityType) {
        const findIndex = data.findIndex(
          (type) => type.label === security.securityType?.toUpperCase()
        )
        if (findIndex !== -1) {
          data[findIndex].value = 1
        } else {
          data.push({
            value: 1,
            label: security?.securityType?.toUpperCase(),
          })
        }
      } else {
        const findIndex = data.findIndex((type) => type.label === 'OTHERS')
        if (findIndex !== -1) {
          data[findIndex].value = 1
        } else {
          data.push({
            value: 1,
            label: 'OTHERS',
          })
        }
      }
    })
  } else if (type === DonutChartType.Units) {
    allCompanyIndirectTransactions.forEach((transaction) => {
      const findIndex = data.findIndex((unit) => unit.label === transaction.unitType?.toUpperCase())
      if (findIndex !== -1) {
        data[findIndex].value += transaction.noOfUnits || 0
      } else {
        data.push({
          value: transaction.noOfUnits || 0,
          label: transaction.unitType?.toUpperCase() || '',
        })
      }
    })
  } else if (type === DonutChartType.Shares) {
    allCompanyTransactions.forEach((transaction) => {
      const findIndex = data.findIndex(
        (unit) => unit.label === transaction.securitySubType?.toUpperCase()
      )
      if (findIndex !== -1) {
        data[findIndex].value += transaction.noOfShares || 0
      } else {
        data.push({
          value: transaction.noOfShares || 0,
          label: transaction.securitySubType?.toUpperCase() || '',
        })
      }
    })
  }
  if (
    type !== DonutChartType.Portfolio &&
    type !== DonutChartType.Stage &&
    type !== DonutChartType.Industry
  )
    data = data.sort((a, b) => b.value - a.value)
  if (
    data.length > 5 &&
    type !== DonutChartType.Securities &&
    type !== DonutChartType.Units &&
    type !== DonutChartType.Portfolio &&
    type !== DonutChartType.Industry
  ) {
    const findOthersIndex = data.findIndex((label) => label.label === 'OTHERS')
    let othersCount = 0
    if (findOthersIndex !== -1) {
      othersCount = data[findOthersIndex].value
      data.splice(findOthersIndex, 1)
    }
    const othersData = data.slice(4, -1)
    const aggregateOthersData = othersData.reduce((acc, other) => acc + other.value, 0)
    data = data.slice(0, 4)
    data.push({
      value: aggregateOthersData + othersCount,
      label: 'OTHERS',
    })
  }
  let bgColors: string[] = []
  for (let i = 0; i < data.length; i++) {
    const color = getCSSVariableValue('--kt-donut-' + (i % 10))
    bgColors.push(color)
  }

  const chartData = {
    labels: data.map((industry) => industry.label),
    datasets: [
      {
        data: data.map((industry) => industry.value),
        backgroundColor: bgColors,
        borderColor: bgColors,
        count: data.reduce((a, b) => a + b.value, 0),
      },
    ],
  }
  return chartData
}
