import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { PortfolioView } from './ViewEEPortfolio'
import { useIsMutating } from 'react-query'
import Toast, { ToastType } from '../../../utils/toast'
import {
  EditEePortfolioMutation,
  EditEePortfolioMutationVariables,
  useGetEePortfoliosQuery,
  useEditEePortfolioMutation,
} from '../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../queries/client'

type Props = {
  showPublishModal: boolean
  togglePublishModal: () => void
  portfolio: any
}

function PublishEEPortfolio({ showPublishModal, togglePublishModal, portfolio: port }: Props) {
  const publishMutate = useEditEePortfolioMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (data: EditEePortfolioMutation, _variables: EditEePortfolioMutationVariables, _context: unknown) => {
        queryClient.invalidateQueries(useGetEePortfoliosQuery.getKey())
        Toast(data.editEEPortfolio.message, ToastType.success)
        togglePublishModal()
      },
      onError: (error: any) => Toast(error.response.errors[0].message, ToastType.error),
    },
    {}
  )

  function handleSubmit(e: any) {
    e.preventDefault()
    e.stopPropagation()
    publishMutate.mutate({ input: { id: port.id, status: port.status === 'Published' ? 'Draft' : 'Published' } })
  }

  return (
    <Modal
      id='kt_modal_publish_portfolio'
      aria-hidden='true'
      show={showPublishModal}
      onHide={togglePublishModal}
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header className='p-2'>
          <Modal.Title className='ps-5'>{`${port && port.status === 'Draft' ? 'Publish' : 'Unpublish'} Portfolio`}</Modal.Title>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={togglePublishModal}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <PortfolioView portfolio={port} />
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <div className='d-flex justify-content-start w-100'>
            <p className='mb-0'>{`Are you sure you want to ${port && port.status === 'Draft' ? 'publish' : 'unpublish'} this portfolio?`}</p>
          </div>
          <div className='d-flex justify-content-end'>
            <button type='button' className='btn btn-sm btn-secondary me-3' onClick={togglePublishModal}>
              Cancel
            </button>
            <button type='submit' className='btn btn-sm btn-primary' disabled={useIsMutating() > 0}>
              {useIsMutating() === 0 && `${port && port.status === 'Draft' ? 'Publish' : 'Unpublish'} Portfolio`}
              {useIsMutating() > 0 && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default PublishEEPortfolio
