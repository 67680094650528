import { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export default function LoginPageContent() {
  const headerObj = [
    {
      header: 'Organize your Investments',
      content:
        'Curate all your investments on a single platform. Gain insights on how your portfolio is performing and streamline all your investment data.',
    },
    {
      header: 'Monitor key performance indicators',
      content:
        'Monitor the key performance parameters of your portfolio companies and optimise your investment strategy with ease.',
    },
    {
      header: 'Make new investments',
      content:
        'Get rid of FOMO on investment opportunities. Add interested companies in your watchlist and get a jumpstart to investing by being the first to send term sheets.',
    },
    {
      header: 'Directly engage with founders',
      content:
        'Get connected to founders directly on the platform to analyse the investment opportunity on hand.',
    },
  ]
  const listObj = [
    {
      image: '/media/svg/login-page/Component 1.svg',
      title: 'Organize your Investments',
    },
    {
      image: '/media/svg/login-page/Component 2.svg',
      title: 'Monitor key performance indicators',
    },
    {
      image: '/media/svg/login-page/Component 3.svg',
      title: 'Make new investments',
    },
    {
      image: '/media/svg/login-page/Component 4.svg',
      title: 'Directly engage with founders',
    },
  ]
  const [headerContent, setHeaderContent] = useState(headerObj[0].header)
  const [content, setContent] = useState(headerObj[0].content)
  const [activeIndex, setActiveIndex] = useState(0)
  useEffect(() => {
    const intervalId = setInterval(() => {
      const random = Math.floor(Math.random() * headerObj.length)
      setActiveIndex(random)
      const selectedHeader = headerObj[random]
      setHeaderContent(selectedHeader.header)
      setContent(selectedHeader.content)
    }, 10000)
    return () => clearInterval(intervalId)
  }, [])
  return (
    <>
      <div className='d-flex flex-column h-100 justify-content-around'>
        <div className='text-center pb-20 p-lg-0'>
          <h1 className='fw-bold display-6'>{headerContent}</h1>
          <h2 className='fw-bold'>{content}</h2>
        </div>
        <div>
          {listObj.map((obj, index) => {
            if (index !== activeIndex) {
              return (
                <div className='d-flex mb-5' key={index}>
                  <div className='col-5 text-end pe-10'>
                    <img
                      alt='componentLogo'
                      src={toAbsoluteUrl(obj.image)}
                      className='img-fluid h-80px'
                    />
                  </div>
                  <div className='d-flex align-items-center col-7'>
                    <h1 className=''>{obj.title}</h1>
                  </div>
                </div>
              )
            }
          })}
        </div>
      </div>
    </>
  )
}
