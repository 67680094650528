import {
    DocumentEditorContainerComponent,
    Inject,
    Toolbar,
  } from '@syncfusion/ej2-react-documenteditor'
  import { useEffect, useRef, useState } from 'react'
  import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'
  import {
    AddDealTemplateMutation,
    DealTemplate,
    EditTemplateDocumentMutation,
    GetTemplateDocumentQuery,
    useAddDealTemplateMutation,
    useEditTemplateDocumentMutation,
    useGetDealTemplatesQuery,
    useGetTemplateDocumentQuery,
  } from '../../../../../generated/graphql'
  import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
  import Toast, { ToastType } from '../../../../../utils/toast'
  
  type Props = {
    templateData: DealTemplate | null
    showModel: boolean
    handleClose: () => void
  }
  function TemplateDocumentEditor({ templateData, showModel, handleClose }: Props) {
    const [editTemplateName, setEditTemplateName] = useState(false)
    const [templateName, setTemplateName] = useState('')
    const [loading, setLoading] = useState(false)
    const[fileBlob,setFileBlob]= useState<Blob>()
    const { data, isFetched } = useGetTemplateDocumentQuery<GetTemplateDocumentQuery, Error>(
      graphqlRequestClient,
      {
        input: {
          templateId: templateData?.id,
          templateName: templateData?.name,
        },
      },
      {}
    )
    const mutationEditTemplate = useEditTemplateDocumentMutation<EditTemplateDocumentMutation, Error>(
      graphqlRequestClient,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(useGetDealTemplatesQuery.getKey())
          queryClient.invalidateQueries(
            useGetTemplateDocumentQuery.getKey({
              input: {
                templateId: templateData?.id,
                templateName: templateData?.name,
              },
            })
          )
          documentRef.current?.documentEditor.destroy()
          handleClose()
        },
        onError: (error: any) => {
          console.log(error)
          Toast(error, ToastType.error)
        },
      },
      {}
    )
    const mutationAddTemplate = useAddDealTemplateMutation<AddDealTemplateMutation, Error>(
      graphqlRequestClient,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(useGetDealTemplatesQuery.getKey())
          queryClient.invalidateQueries(
            useGetTemplateDocumentQuery.getKey({
              input: {
                templateId: templateData?.id,
                templateName: templateData?.name,
              },
            })
          )
          handleClose()
        },
        onError: (error: any) => {
          console.log(error)
          Toast(error.response.errors[0].message, ToastType.error)
        },
      }
    )
    let documentRef = useRef<DocumentEditorContainerComponent | null>(null)
  
    const fetchFileFromUrl = async (url: string) => {
      return await fetch(url)
        .then((response) => {
          return response.blob()
        })
        .catch((error) => {
          console.error('Fetch error:', error)
          return null
        })
    }
  
    useEffect(() => {
      if (data) {
        fetchFileFromUrl(data?.getTermsheetTemplate?.url).then((data) => {
          if (data) onFileChange(data)
        })
      }
    }, [isFetched])
    const onFileChange = async (blob: Blob) => {
      let formData = new FormData()
      formData.append('files', blob)
      const fetchOptions = {
        method: 'POST',
        body: formData,
      }
      await fetch('https://syncfusion-api.hissa.com/api/documenteditor/Import', fetchOptions)
        .then((response) => {
          if (response.status === 200 || response.status === 304) {
            return response.json()
          } else {
            throw new Error('Request failed with status: ' + response.status)
          }
        })
        .then((data) => {
          onLoadDefault(data.sfdt)
        })
        .catch((error) => {
          console.error('Fetch error:', error)
        })
    }
    const onLoadDefault = (sfdt: any) => {
      // console.log(sfdt)
      let defaultDocument = {
        sfdt: sfdt,
      }
      // documentRef.current?.documentEditor.editor.insertTable(3, 3)
      documentRef.current?.documentEditor.open(JSON.stringify(defaultDocument))
      // documentRef.current?.documentEditor.save('dsa', 'Docx')
    }
    const save = () => {
      documentRef.current?.documentEditor.saveAsBlob('Docx').then((file: Blob) => {
        let fileToUpload: Blob = file
        mutationEditTemplate.mutate({
          input: {
            documentType: templateData?.documentType,
            fileName: fileToUpload,
            investorId: templateData?.investorId,
            jsonData: JSON.parse(templateData?.jsonData),
            name: templateData?.name,
            sections: templateData?.sections,
            templateId: templateData?.id,
          },
        })
      })
    }
    const saveAs = () => {
        if(!fileBlob){
          toastr.error("file not found")
          return
        }
        let fileToUpload: Blob = fileBlob
        mutationAddTemplate.mutate({
          input: {
            documentType: templateData?.documentType,
            fileName: fileToUpload,
            investorId: templateData?.investorId,
            jsonData: JSON.parse(templateData?.jsonData),
            name: templateName,
            sections: templateData?.sections,
          },
        })
    }
    return (
      <Modal
        dialogClassName={`modal-dialog modal-dialog-centered ${editTemplateName ? 'mw-45': 'mw-75'} `}
        show={showModel}
        onHide={() => {
          handleClose()
        }}
      >
        <ModalHeader>
          <div className='d-flex'>
            {editTemplateName ? <h1>Save New Template</h1>:<><h1 className={editTemplateName ? 'pt-2' : ''}>Template Editor : </h1><h1 className='ms-2'>{templateData?.name}</h1></>}
          </div>
        </ModalHeader>
        <ModalBody>
          {editTemplateName ? <div className='d-flex'>
            <label>Template Name :</label>
            <input
                  className='form-control form-control-solid form-control-sm mx-2'
                  placeholder='Enter Template sheet Name'
                  onChange={(e) => {
                    setTemplateName(e.target.value)
                  }}
                />
          </div>
          :
          <><DocumentEditorContainerComponent
            height='580px'
            enableToolbar={true}
            autoResizeOnVisibilityChange
            ref={documentRef}
            toolbarItems={['Redo', 'Undo', 'Find', 'RestrictEditing']}
            showPropertiesPane={false}
            serviceUrl='https://syncfusion-api.hissa.com/api/documenteditor/'
          >
            <Inject services={[Toolbar]}></Inject>
          </DocumentEditorContainerComponent><div className='mt-2 text-primary'>
              Note: Do not edit the words which are enclosed with # i.e(#investorName#)
            </div></>}
          <div>
            <button
              className='btn btn-secondary mt-5'
              onClick={() => {
                handleClose()
              }}
            >
              Cancel
            </button>
            {!editTemplateName && <button
              className='btn btn-primary mt-5 float-end'
              onClick={() => {
                save()
                setLoading(true)
              }}
              disabled={editTemplateName || loading || templateData?.name === "Default Template"}
            >
              Save
            </button>}
            <button
              className='btn btn-primary mt-5 me-5 float-end'
              onClick={() => {
                if(!editTemplateName){
                const blobFile =  documentRef.current?.documentEditor.saveAsBlob('Docx').then((file:Blob)=>{
                setFileBlob(file)
                setEditTemplateName(true)
                })
              }
                if(editTemplateName){
                saveAs()}
              }}
              disabled={loading}
            >
              {editTemplateName ? "Save":"Save As"}
            </button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
  
  export default TemplateDocumentEditor
  