import { useEffect, useState } from 'react'
import { useGetCaptableIframeToken } from '../../../../modules/auth/core/_requests'

type Props = {
  hissaCompanyId: string
  type: ViewType
}

export enum ViewType {
  CAPTABLE = 'CAPTABLE',
  RIGHTS = 'RIGHTS',
  SECURITIES = 'SECURITIES',
  TRANSACTIONS = 'TRANSACTIONS',
  FINANCIALS = 'FINANCIALS',
}

function GenericIframeView(props: Props) {
  const { data, isFetched } = useGetCaptableIframeToken(props.hissaCompanyId)
  const [token, setToken] = useState('')
  useEffect(() => {
    if (data && data.data) setToken(data.data)
  }, [data])

  let view = props.type.toLowerCase() as string

  if (props.type === ViewType.TRANSACTIONS) {
    view = 'transaction/allTransactions'
  } else if (props.type === ViewType.RIGHTS) {
    view = 'rights/overview'
  } else if (props.type === ViewType.SECURITIES) {
    view = 'security'
  } else if (props.type === ViewType.FINANCIALS) {
    view = 'finance'
  }

  if (!isFetched) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  return token && token.length > 0 ? (
    <div className='row'>
      <div className='card card-body'>
        <div className='row h-950px'>
          <iframe
            src={`${process.env.REACT_APP_CAPTABLE_CLIENT_URL}/inv/${view}/${props.hissaCompanyId}/${token}`}
            title='CapTable'
            loading='eager'
            width='100%'
          ></iframe>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default GenericIframeView
