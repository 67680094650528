import { create } from 'zustand'
import { configurePersist } from 'zustand-persist'

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: 'root',
})

const persist = _persist as any

type chatbotState = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  setIsClear: (value: boolean) => void
  isClear: boolean
  reset: () => void
}

const initialState = {
  isOpen: false,
  isClear: false,
}

const chatbotStore = create<chatbotState>(
  persist(
    {
      key: 'termsheetChatbot',
    },
    (set: any) => ({
      ...initialState,
      setIsOpen: (status: boolean) => {
        set((state: any) => ({ isOpen: status }))
      },
      setIsClear: (status: boolean) => {
        set((state: any) => ({ isClear: status }))
      },
      reset: () => {
        set(() => initialState)
      },
    })
  )
)

export const useChatbotStore = chatbotStore
