import { useState } from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import ReportsModal from './ReportsModal'
import { CompanyObject } from '../../../generated/graphql'

type Props = {
  companyData: CompanyObject
}
function EmptyReportsPage({ companyData }: Props) {
  const [showModal, setShowModal] = useState(false)

  return (
    <div
      className='card p-0 h-575px mb-9'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='card-body'>
        <div className='row justify-content-center  text-center py-8 m-6'>
          <img
            alt='no items'
            src={toAbsoluteUrl('/media/icons/duotune/general/empty-reports.svg')}
            className='h-250px my-10 w-350 justify-content-center'
          />
          <h1>No Reports added to this Company</h1>
          <div className='col justify-content-between pt-4'>
            <button
              className='btn btn-primary mt-4 mx-8'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
              onClick={() => {
                setShowModal(!showModal)
              }}
            >
              Add Reports
            </button>
            {showModal && (
              <ReportsModal
                companyData={companyData}
                closeModal={() => setShowModal(false)}
                showModal={showModal}
                isEdit={false}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmptyReportsPage
