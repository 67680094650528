import { Doughnut } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BubbleDataPoint,
  ScatterDataPoint,
} from 'chart.js'
import { ChartData } from 'chart.js'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { formatNumber, getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import { useContext } from 'react'
import { GlobalContext } from '../../store/context/globalContext'

ChartJS.register(ArcElement, Tooltip, Legend)

export const optionsSeries: Object = {
  rotation: 0,
  circumference: 360,
  maintainAspectRatio: false,
  responsive: true,
  radius: '100%',
  cutout: '80%',
  plugins: {
    tooltip: { displayColors: true },
    legend: { display: false },
    title: { display: false },
  },
}

type DonutWithCloseButtonProps = {
  header: string
  data: ChartData<'doughnut', (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown>
  handleClose?: () => void
  initialCount?: number
  isCurrency?: boolean
  className?: string
  isIndirect?: boolean
}

function DonutWithCloseButton(props: DonutWithCloseButtonProps) {
  let exitedTextList: number[] = []
  let updatedCount = props.initialCount ? props.initialCount : 0
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  var plugins = [
    {
      id: '1',
      afterDraw(chart: ChartJS) {
        const { width, height, ctx } = chart
        ctx.restore()
        const fontSize = (height / 100).toFixed(2)
        ctx.font = `${fontSize}em Inter`
        ctx.fillStyle = getCSSVariableValue('--kt-headings-color')
        ctx.textBaseline = 'top'
        let count = 0
        chart.data.datasets.forEach((item: any) => {
          if (exitedTextList.length === 0) count += item.count || 0
          else count = updatedCount
        })

        const text2 =
          (props.isCurrency ? getSymbolOfCurrency(userCurrency) : '') +
          formatNumber({
            value: count,
            format: userCurrencyFormat,
          })
        const textX = Math.round((width - ctx.measureText(text2).width) / 2)
        const textY = chart.chartArea.height / 2 - 10
        ctx.fillText(text2, textX, textY)
        ctx.save()
      },
    },
    {
      id: 'htmlLegend',
      afterUpdate(chart: ChartJS) {
        const items = chart.options.plugins?.legend?.labels?.generateLabels?.(chart)
        const ul = document.createElement('ul')
        // Set display property of ul to flex and flex-wrap to wrap
        ul.style.display = 'flex'
        ul.style.flexWrap = 'wrap'
        ul.style.listStyleType = 'none' // Remove default list styles

        items?.forEach((item, index) => {
          const li = document.createElement('li')
          li.style.alignItems = 'center'
          li.style.cursor = 'pointer'
          li.style.display = 'flex'
          li.style.flexDirection = 'row'
          li.style.paddingBottom = '5px'
          li.style.marginRight = '10px' // Add margin between list items
          li.style.marginBottom = '10px' // Add margin between rows

          const boxSpan = document.createElement('span')
          boxSpan.style.background = item.fillStyle?.toString()!
          boxSpan.style.borderColor = item.strokeStyle?.toString()!
          boxSpan.style.borderWidth = item.lineWidth + 'px'
          boxSpan.style.borderRadius = '10px'
          boxSpan.style.display = 'inline-block'
          boxSpan.style.height = '15px'
          boxSpan.style.width = '15px'
          boxSpan.style.marginRight = '5px' // Add margin between box and text

          const textContainer = document.createElement('p')
          textContainer.style.color = item.fontColor?.toString()!
          textContainer.style.margin = '0'
          textContainer.style.padding = '0'
          textContainer.style.textDecoration = item.hidden ? 'line-through' : ''
          textContainer.appendChild(document.createTextNode(item.text))

          li.appendChild(boxSpan)
          li.appendChild(textContainer)

          li.onclick = () => {
            const i = (item as any).index
            const exitIndex = exitedTextList.indexOf(i)
            if (exitIndex !== -1) exitedTextList.splice(exitIndex, 1)
            else exitedTextList.push(i)
            const dataset = chart.data.datasets[0].data
            let exitedCount = 0
            exitedTextList.forEach((e) => {
              exitedCount += (dataset[e] as number) || 0
            })

            updatedCount = (chart.data.datasets[0] as any).count - exitedCount
            if (updatedCount < 0) {
              updatedCount = 0
            }
            chart.toggleDataVisibility(index)
            item.hidden = !item.hidden
            chart.update()
          }

          ul.appendChild(li)
        })

        const jsLegend = document.getElementById('chart-legend-' + props.header.replaceAll(' ', ''))
        if (jsLegend) {
          jsLegend.innerHTML = ''
          jsLegend!.appendChild(ul)
        }
      },
    },
  ]
  return (
    <div
      className={`card ${props.isIndirect ? 'h-325px' : 'h-375px'}`}
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <h2 className='card-label fw-bold ps-9 pt-9'>{props.header}</h2>

      <div className={`card-body ${props.className}`}>
        <Doughnut options={optionsSeries} data={props.data} plugins={plugins} />
      </div>
      <div
        className='h-150px overflow-auto'
        id={'chart-legend-' + props.header.replaceAll(' ', '')}
        style={{ overflowY: 'auto', scrollbarWidth: 'none' }}
      ></div>
    </div>
  )
}

export default DonutWithCloseButton
