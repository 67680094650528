import LazyPdfDocument from '../../../../../utils/LazyPdfDocument'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'

type Props = {
  url: string
  documentName: string
  handleClose: () => void
  show: boolean
}

export const PDFModalViewer = (props: Props) => {
  return (
    <Modal
      id='kt_header_search_modal'
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered h-auto mw-900px'
      show={props.show}
      centered
      animation={true}
      backdrop={true}
      onHide={() => {
        props.handleClose()
      }}
    >
      <Modal.Header className='px-9'>
        <Modal.Title className='fs-1 text-gray-heading'>{props.documentName}</Modal.Title>
        <div className='row justify-content-end'>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => props.handleClose()}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className='px-15'>
        <div className='fv-row mb-4 justify'>
          <LazyPdfDocument url={props.url} />
        </div>
      </Modal.Body>
    </Modal>
  )
}
