import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import jwtDecode from 'jwt-decode'
import { JwtPayload } from '../core/_models'
import { ResetPasswordRequest } from '../../../../types/Login'
import { useResetPasswords } from '../core/_requests'

const initialValues: ResetPasswordRequest = {
  emailId: '',
  passwordNew: '',
  confirmPassword: '',
  jwt: '',
  terms: false,
  senderOrigin: 'invPortal',
}

const resetPasswordSchema = Yup.object().shape({
  passwordNew: Yup.string()
    .min(6, 'Password length should be atleast 6')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
      'Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Character'
    )
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Password confirmation is required')
    .when('passwordNew', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('passwordNew')],
        "Password and Confirm Password didn't match"
      ),
    }),
  senderOrigin: Yup.string(),
  terms: Yup.boolean().required(),
})
export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [error, setError] = useState<string>('')
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  const token = params.get('token')
  const jwt = token || ''
  var decode = jwtDecode<JwtPayload>(jwt)
  const resetPassword = useResetPasswords()
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      values.jwt = jwt
      values.emailId = decode.emailId!
      values.senderOrigin = 'invPortal'
      resetPassword.mutate(
        {
          ...values,
        },
        {
          onSuccess: () => {
            setIsSuccess(true)
            setLoading(false)
          },
          onError: (errors: any) => {
            setLoading(false)
            setSubmitting(false)
            if (errors.response) {
              if (errors.response.status === 404) {
                setError(errors.response.data.errorMessage)
              } else if (errors.response.status === 403) {
                setError(errors.response.data.errorMessage)
              } else {
                if (errors.response.data && errors.response.data.errorMessage) {
                  setError(errors.response.data.errorMessage)
                } else {
                  setError('Server Unreachable Try After Sometime')
                }
              }
            }
          },
        }
      )
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  return (
    <div className='d-flex flex-column justify-content-around align-items-center h-100'>
      <div className='pb-20 p-lg-0'>
        <img
          src={toAbsoluteUrl('/media/logos/default_dark.svg')}
          alt='Hissa_Logo'
          className='img-fluid h-60px'
        />
      </div>
      <div>
        <form
          className='form fv-plugins-bootstrap5 fv-plugins-framework w-300px w-md-400px w-lg-350px w-xl-400px p-5'
          onSubmit={formik.handleSubmit}
        >
          {!isSuccess && (
            <>
              <div className=' text-center'>
                <h1 className='fw-bold display-6'>Setup new password</h1>
                <h6 className='my-5'>
                  Have you already reset the password ?{' '}
                  <Link to='/auth/login' className='fw-bold'>
                    Sign in
                  </Link>
                </h6>
              </div>
              <div className='py-2 position-relative mb-3' data-kt-password-meter='true'>
                <label className='form-label fw-bold'>New Password</label>
                <input
                  placeholder='New password'
                  autoComplete='off'
                  className='form-control custom-input fw-bold shadow-lg h-38px'
                  {...formik.getFieldProps('passwordNew')}
                  type={`${showNewPassword ? 'text' : 'password'}`}
                />
                <span
                  className='btn btn-icon position-absolute translate-middle-y end-0 top-50'
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? (
                    <i className='bi bi-eye f-2'></i>
                  ) : (
                    <i className='bi bi-eye-slash f-2'></i>
                  )}
                </span>
                <div
                  className='d-flex align-items-center my-3'
                  data-kt-password-meter-control='highlight'
                >
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
              </div>
              {formik.touched.passwordNew && formik.errors.passwordNew && (
                <div className='fv-plugins-message-container mb-1'>
                  <div className='fv-help-block'>
                    <span role='alert'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-primary svg-icon-1 me-2'
                      />
                      {formik.errors.passwordNew}
                    </span>
                  </div>
                </div>
              )}
              <div className='py-2 position-relative mb-3'>
                <label className='form-label fw-bold'>Confirm password</label>
                <input
                  type={`${showConfirmPassword ? 'text' : 'password'}`}
                  placeholder='Confirm new password'
                  autoComplete='off'
                  className='form-control custom-input fw-bold shadow-lg h-38px'
                  {...formik.getFieldProps('confirmPassword')}
                />
                <span
                  className='btn btn-icon position-absolute translate-middle-y end-0 top-50 mt-4'
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <i className='bi bi-eye f-2'></i>
                  ) : (
                    <i className='bi bi-eye-slash f-2'></i>
                  )}
                </span>
              </div>
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-primary svg-icon-1 me-2'
                      />
                      {formik.errors.confirmPassword}
                    </span>
                  </div>
                </div>
              )}
              {error && error.length > 0 && (
                <div className='fv-plugins-message-container mb-3'>
                  <div className='fv-help-block'>
                    <span role='alert'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen045.svg'
                        className='svg-icon-primary svg-icon-1 me-2'
                      />
                      {error}
                    </span>
                  </div>
                </div>
              )}
              <div className='py-3 d-flex'>
                <input
                  type='checkbox'
                  {...formik.getFieldProps('terms')}
                  className='form-check-input me-2'
                />
                <h6 className='mt-1'>
                  I agree to the{' '}
                  <a
                    href='https://www.rulezero.com/terms-of-use'
                    target='_blank'
                    className='fw-bold'
                    rel='noreferrer'
                  >
                    Terms & Conditions
                  </a>
                </h6>
              </div>
              <div className='my-10 text-center'>
                <button
                  className='btn btn-lg btn-primary mb-5'
                  type='submit'
                  disabled={
                    formik.status ||
                    formik.isSubmitting ||
                    !formik.isValid ||
                    formik.values.terms === false ||
                    (formik.values.passwordNew === '' && formik.values.confirmPassword === '')
                  }
                >
                  {!loading && <span className='indicator-label fw-bold'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {formik.status?.includes('TokenExpiredError: jwt expired') ? (
                <div className='fv-plugins-message-container text-center'>
                  <div className='text-primary fw-bold mb-5' role='alert'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen044.svg'
                      className='svg-icon-primary svg-icon-1 me-2'
                    />
                    The Password reset link has expired, please click
                    <br /> on button below to send another reset link
                  </div>
                  <Link to='/auth/forgotPassword' className='fw-bold'>
                    {' '}
                    <button className='btn btn-lg btn-primary'>Reset Password</button>
                  </Link>
                </div>
              ) : (
                <> </>
              )}
            </>
          )}

          {isSuccess && (
            <div className='text-center'>
              <h1 className='fw-bold display-8 mb-4'>Password changed</h1>
              <h6 className='text-muted mb-10'>
                Your password is successfully changed. Please Sign in to your account.
              </h6>
              <Link to='/auth/login' className='fw-bold'>
                {' '}
                <button className='btn btn-lg btn-primary'>Sign In</button>
              </Link>
            </div>
          )}
        </form>
      </div>
      <div className='h-60'></div>
    </div>
  )
}
