import { Link, useNavigate } from 'react-router-dom'
import {
  CompanyDataInput,
  CompanyObject,
  GetCompanyMisReportsQuery,
  MisReportDataType,
  useGetCompanyMisReportsQuery,
} from '../../../generated/graphql'
import { graphqlRequestClient } from '../../../queries/client'
import { ReportsAgTable } from './ReportsAgTable'

type Props = {
  companyInput: CompanyDataInput
  companyData: CompanyObject
}

export function ManageReportsPage({ companyInput, companyData }: Props) {
  const { data, isLoading } = useGetCompanyMisReportsQuery<GetCompanyMisReportsQuery, Error>(
    graphqlRequestClient,
    {
      input: companyInput,
    },
    {}
  )
  if (isLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )

  return (
    <>
      <div className='row mb-5'>
        <div className='col-12 d-flex flex-row justify-content-end'>
          <span>
            <Link
              className='px-4 rounded btn btn-sm border border-1 border-primary bg-primary h-38px fw-normal text-white'
              to={`/company/misreport/${companyData?.type}/${
                companyData?.hissaCompanyId || companyData.id
              }`}
            >
              Show Chart
            </Link>
          </span>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card rounded'>
            <ReportsAgTable
              data={data?.getCompanyMisReports as MisReportDataType[]}
              companyData={companyData}
            />
          </div>
        </div>
      </div>
    </>
  )
}
