import { formatNumber, getSymbolOfCurrency } from '../../../../../utils/CurrencyFormatter'
import { HStack, VStack } from '../../../../../utils/Stack'

interface ProgressBarProps {
  percentage: number
  raisedAmount: number
  leftToRaise: number
  userCurrencyFormat: string
  userCurrency: string
  bgColor: string
}

export function ModelProgressBar(props: ProgressBarProps) {
  return (
    <>
      <VStack className='d-flex justify-content-between py-1 px-4 px-md-0'>
        <HStack className=' d-flex align-items-center justify-content-between'>
          <HStack>
            <div className='w-15px h-15px bg-success me-1 border border-success rounded mt-1'>
              {' '}
            </div>
            <p className={`${props.bgColor !== '' ? 'text-white fs-5' : 'fs-7'}  mb-0`}>
              Money Raised:
            </p>{' '}
          </HStack>
          <HStack
            className={`font-bold ${
              props.bgColor !== '' ? 'text-white fs-4' : 'fs-5'
            } items-baseline `}
          >
            <p
              className='fw-bold d-flex align-items-center p-0 m-0 '
              data-toggle='tooltip'
              data-placement='top'
              title={Number(props.raisedAmount).toLocaleString(props.userCurrencyFormat)}
            >
              {getSymbolOfCurrency(props.userCurrency)}
              {formatNumber({
                value: props.raisedAmount,
                format: props.userCurrencyFormat,
              })}
            </p>
          </HStack>
        </HStack>
        <HStack className=' d-flex align-items-center justify-content-between'>
          <HStack>
            <div className='w-15px h-15px bg-gray-200 me-1 border border-gray-200 rounded mt-1'>
              {' '}
            </div>
            <p className={`${props.bgColor !== '' ? 'text-white fs-5' : 'fs-7'} mb-1`}>
              Left to raise:
            </p>{' '}
          </HStack>
          <HStack
            className={`font-bold ${
              props.bgColor !== '' ? 'text-white fs-4' : 'fs-5'
            } items-baseline  `}
          >
            <p
              className='fw-bold d-flex align-items-center p-0 m-0 '
              data-toggle='tooltip'
              data-placement='top'
              title={Number(props.leftToRaise).toLocaleString(props.userCurrencyFormat)}
            >
              {getSymbolOfCurrency(props.userCurrency)}
              {formatNumber({
                value: props.leftToRaise,
                format: props.userCurrencyFormat,
              })}
            </p>
          </HStack>
        </HStack>

        <div className='bg-gray-200 rounded h-15px'>
          <HStack
            className='bg-success align-items-center h-15px rounded text-white fs-6 font-semibold text-center'
            style={{
              width: `${
                props.percentage >= 100 ? 100 : props.percentage > 15 ? props.percentage : 10
              }%`,
            }}
          >
            <HStack className='mx-auto pt-4'>
              {formatNumber({
                value: props.percentage,
                format: props.userCurrencyFormat,
              })}
              <p>%</p>
            </HStack>
          </HStack>
        </div>
      </VStack>
    </>
  )
}
