import {
  GetNewHissaCompaniesQuery,
  SyncHissaCompaniesMutation,
  SyncHissaCompaniesMutationVariables,
  useGetAllInvestmentsQuery,
  useGetInvestorsQuery,
  useGetNewHissaCompaniesQuery,
  useSyncHissaCompaniesMutation,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../utils/Select'
import { useEffect, useState } from 'react'
import Toast, { ToastType } from '../../../../utils/toast'
import { getUserInvestors } from '../../../../utils/LocalStorageUtils'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'

type Props = {
  show: boolean
  handleClose: Function
}

function SyncInvestorCompanies(props: Props) {
  const [selectedInvestorId, setSelectedInvestorId] = useState(-1)
  const investorTypes = getUserInvestors('userInvestors')

  const investorOptions = investorTypes.map((item) => {
    return { label: item.investorName, value: item.investorId }
  })

  const { data, isLoading } = useGetNewHissaCompaniesQuery<GetNewHissaCompaniesQuery, Error>(
    graphqlRequestClient,
    { input: selectedInvestorId },
    { enabled: selectedInvestorId !== -1 }
  )

  const mutateAddLedgerTransaction = useSyncHissaCompaniesMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: SyncHissaCompaniesMutation,
        _variables: SyncHissaCompaniesMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(
          useGetNewHissaCompaniesQuery.getKey({ input: selectedInvestorId })
        )
        queryClient.invalidateQueries(useGetAllInvestmentsQuery.getKey())

        queryClient.invalidateQueries(useGetInvestorsQuery.getKey())

        props.handleClose()

        Toast('Synced Companies Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  useEffect(() => {
    if (selectedInvestorId !== -1) {
      const getNewHissaCompaniesQueryKey = useGetNewHissaCompaniesQuery.getKey()
      queryClient.invalidateQueries(getNewHissaCompaniesQueryKey)
    }
  }, [selectedInvestorId])

  return (
    <Modal
      show={props.show}
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      backdrop={true}
      aria-hidden='true'
      id='kt_header_search_modal'
    >
      <ModalHeader className='justify-content-between'>
        <h1 className='fw-bolder'>Sync Investor Companies</h1>
        <button
          type='button'
          className='btn btn-transparent border-sm border border-gray-700 text-hover-primary border-hover-primary'
          onClick={() => {
            props.handleClose()
          }}
        >
          Close
        </button>
      </ModalHeader>
      <ModalBody>
        <div className='pb-5'>
          <h3 className='required'>Select Investor/Fund type :</h3>
          <Select
            options={investorOptions}
            styles={selectCustomStyles}
            name='fundType'
            onChange={(e: any) => {
              setSelectedInvestorId(e.value)
            }}
          />
        </div>
        <div className='d-flex justify-content-between py-0'>
          <h3>List of Companies that can be synced</h3>
        </div>
        <div className='pb-5'>
          {data &&
            data.getNewHissaCompaniesForInvestor &&
            data.getNewHissaCompaniesForInvestor.length > 0 &&
            data.getNewHissaCompaniesForInvestor?.map((item, index) => {
              return (
                <>
                  <div className='row justify-content-between mt-2'>
                    <div className='col-12'>
                      <p className='fs-base mb-0 fw-normal'>
                        {index + 1}. {item?.companyName} ({item?.companyId})
                      </p>
                    </div>
                  </div>
                </>
              )
            })}
        </div>
        {data &&
          data.getNewHissaCompaniesForInvestor &&
          data.getNewHissaCompaniesForInvestor.length === 0 && (
            <div className='pb-5'>
              <p className='fs-base mb-0 fw-normal'>No Companies Found for syncing</p>
            </div>
          )}
        {data &&
          data.getNewHissaCompaniesForInvestor &&
          data.getNewHissaCompaniesForInvestor.length > 0 && (
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  mutateAddLedgerTransaction.mutate({ input: selectedInvestorId })
                }}
              >
                Sync Companies
              </button>
            </div>
          )}
        {isLoading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            Loading... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </ModalBody>
    </Modal>
  )
}

export default SyncInvestorCompanies
