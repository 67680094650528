import * as Yup from 'yup'

export interface IndirectTransactionData {
  investorId: number
  indirectInvestorName: string
  unitType: string
  companyName: string
  cin: string
  industry: string
  noOfUnits: number
  drawDown: number
  investedAmount: number
  setupFee: number
  estimatedFMV: number
  investmentDate: string
  currentNAV: number
  hissaCompanyId: number
  transactionCurrency: string
  jurisdiction: string
  companyCurrency: string
  exchangeRate: string
  currentValuation: number
  currentSharePrice: number
}

const addIndirectTransactionSchemas = Yup.object().shape({
  investorId: Yup.number().required().moreThan(0).label('Investor Name'),

  indirectInvestorName: Yup.string().required().label('Vehicle Name'),

  industry: Yup.string().label('Industry'),

  companyName: Yup.string().required().label('Company Name'),

  unitType: Yup.string().optional().label('Unit Type'),

  cin: Yup.string().required().label('cin Name'),

  noOfUnits: Yup.number().moreThan(0).required().label('Number Of Units'),

  drawDown: Yup.number().required().moreThan(0).label('Drawdown'),

  investedAmount: Yup.number().required().moreThan(0).label('Invested Amount'),

  setupFee: Yup.number().required().label('Setup Fee'),

  estimatedFMV: Yup.number().required().moreThan(0).label('Estimated FMV'),

  currentSharePrice: Yup.number().required().moreThan(0).label('Current Share Price'),

  currentValuation: Yup.number().required().moreThan(0).label('Current Valuation'),

  currentNAV: Yup.number().required().moreThan(0).label('Net Asset value'),

  exchangeRate: Yup.number().when(['companyCurrency', 'transactionCurrency'], {
    is: (transactionCurrency: string, companyCurrency: string) =>
      transactionCurrency !== companyCurrency,
    then: Yup.number()
      .positive()
      .required()
      .moreThan(0, 'Should be greater than 0')
      .label('Exchange rate'),
    otherwise: Yup.number().notRequired(),
  }),

  investmentDate: Yup.string().required().label('Investment Date'),

  transactionCurrency: Yup.string().label('Transaction Currency'),

  country: Yup.string().label('Jurisdiction'),

  companyCurrency: Yup.string().label('Company Currency'),
})

export { addIndirectTransactionSchemas }
