export function CompanyTypeBadge({ companyType }: { companyType: string }) {
  return (
    <div>
      <span
        className={`badge badge-md w-65px h-30px fs-8 fw-bold mb-1 me-2 text-gray-600 bg-light justify-content-center `}
        style={{
          fontFamily: 'arial',
        }}
      >
        {companyType}
      </span>
    </div>
  )
}
