import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useGetDealTemplatesQuery, GetDealTemplatesQuery } from '../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../queries/client'
import { hexToRGB } from '../../../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { SendTermsheetWizard } from './SendTermsheetWizard'
import {
  FieldsType,
  GroupsType,
  StepperType,
  getCurrentTemplate,
} from '../../../../../utils/stepper'

function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export function getInits(data: any, genericData: any, templateId: number) {
  let inits: any = {
    termsheetName: data.termsheetName ? data.termsheetName : 'My Document',
    investorId: data.investorId || 0,
    companyName: data.companyName || '',
    investorName: data.investorName,
    hissaCompanyId: data.companyId,
  }

  function getTemplate(data: any) {
    let templateData: any[] = []
    data?.dealTemplates &&
      data?.dealTemplates.forEach((templates: any) => {
        if (templateId == templates.id) {
          templateData.push(templates)
        }
      })
    return templateData[0]
  }

  const templateData = getTemplate(genericData)
  const jsonData = JSON.parse(templateData.jsonData)

  data.isRoundModel
    ? jsonData.forEach((section: StepperType) => {
        if (section.sequenceNumber > 0) {
          if (section.sequenceNumber == 1) inits['isFromModelling'] = true
          const sectionName = camelize(section.stepname)
          section.groups.forEach((group: GroupsType) =>
            group.fields.forEach((field: FieldsType) => {
              if (data[field.key] !== undefined)
                if (field.value === 'number') inits[field.key] = data[field.key] || 0
              if (field.value === 'boolean') inits[field.key] = data[field.key] || false
              else inits[field.key] = data[field.key] || ''
            })
          )
        }
      })
    : jsonData.forEach((section: StepperType) => {
        if (section.sequenceNumber > 0) {
          const sectionName = camelize(section.stepname)
          section.groups.forEach((group: GroupsType) =>
            group.fields.forEach((field: FieldsType) => {
              if (data.sections[sectionName][field.key] !== undefined)
                if (field.value === 'number')
                  inits[field.key] = data.sections[sectionName][field.key] || 0
              if (field.value === 'boolean')
                inits[field.key] = data.sections[sectionName][field.key] || false
              else inits[field.key] = data.sections[sectionName][field.key] || ''
            })
          )
        }
      })

  return inits
}

export default function DraftTermsheetView() {
  const location = useLocation()
  let props: any = location.state
  const [allowedSections, setAllowedSections] = useState<any[]>([])
  const [templateId, setTemplateId] = useState()
  const { data, isLoading, error, isSuccess, isFetched } = useGetDealTemplatesQuery<
    GetDealTemplatesQuery,
    Error
  >(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (props.templateId) {
      const template = data?.dealTemplates?.find((template) => template?.id === props.templateId)
      if (template) {
        setAllowedSections(template?.sections!)
      }
      setTemplateId(props.templateId)
    }
  }, [isFetched])

  if (isLoading)
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )

  if (error) return <p>{error.message}</p>
  const inits = getInits(props, data, props.templateId)

  const templateData = getCurrentTemplate(data, props.templateId)

  return (
    <>
      <div className='card'>
        <div className='card-title fw-bold p-5 m-0 d-flex justify-content-between'>
          <h1 className='mb-0'>
            Document
            <span
              className={`badge fs-5 fw-semibold ms-5`}
              style={{
                backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 0.2),
                color: hexToRGB(getCSSVariableValue('--kt-donut-' + 4), 1),
              }}
            >
              Draft
            </span>
          </h1>
        </div>
      </div>
      <div className=''>
        {templateId ? (
          allowedSections.length > 0 &&
          templateData &&
          templateData['documentType'] === 'Termsheet' && (
            <SendTermsheetWizard
              genericData={templateData}
              initialValues={inits}
              allowedSections={allowedSections}
              templateId={props.templateId}
              draftTermsheetId={props.id}
            />
          )
        ) : (
          <SendTermsheetWizard
            genericData={templateData}
            initialValues={inits}
            allowedSections={allowedSections}
            templateId={props.templateId}
            draftTermsheetId={props.id}
          />
        )}
      </div>
    </>
  )
}

export function getInits1(data: { key: string; value: any }[], jsonData: any) {
  let inits: any = {}

  jsonData.forEach((section: StepperType) => {
    if (section.sequenceNumber > 0) {
      section.groups.forEach((group: GroupsType) =>
        group.fields.forEach((field: FieldsType) => {
          const dataKeyIndex = data.findIndex((obj) => obj.key === field.key)
          if (dataKeyIndex !== -1) {
            inits[field.key] = data[dataKeyIndex].value
          } else {
            if (field.value === 'number') inits[field.key] = 0
            if (field.value === 'boolean') inits[field.key] = false
            else inits[field.key] = ''
          }
        })
      )
    }
  })

  return inits
}
