import { useFormik } from 'formik'
import React, { ChangeEvent, useContext, useState } from 'react'
import {
  AddValuationDetailsMutation,
  AddValuationDetailsMutationVariables,
  CompanyObject,
  CompanyTypes,
  GetShareholdingDetailsByCompanyQuery,
  useAddValuationDetailsMutation,
  useGetAllInvestmentsQuery,
  useGetAllTransactionsQuery,
  useGetAllValuationOfCompanyQuery,
  useGetCompanyWiseDataQuery,
  useGetInvestorsQuery,
  useGetShareholdingDetailsByCompanyQuery,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import {
  ICompanyDetails,
  editCompanyDetailInits,
} from '../EditCompanyDetails/EditCompanyDetailsHelper'
import Select from 'react-select'
import { KTSVG } from '../../../../../_metronic/helpers'
import { SelectOptionType } from '../../../../../utils/MultiSelect'
import { selectCustomStyles } from '../../../../../utils/Select'
import {
  IHissaValuationDetails,
  addHissaValuationDetailInits,
  addHissaValuationDetailSchema,
} from './AddHissaValuationDetailsHelper'
import Toast, { ToastType } from '../../../../../utils/toast'
import { InputBox } from '../../../../../utils/NumberInputBox'
import { getCurrency, getSymbolOfCurrency } from '../../../../../utils/CurrencyFormatter'
import { GlobalContext } from '../../../store/context/globalContext'

type Props = {
  companyDetails: CompanyObject
  closeModal: () => void
}

function AddHissaValuationDetailsForm(props: Props) {
  const tagsObjList: { value?: string; label?: string }[] = []

  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const [data, setData] = useState<ICompanyDetails>(editCompanyDetailInits)
  const [loading, setLoading] = useState(false)
  const [customTag, setCustomTag] = useState<boolean>(false)
  const [values, setValues] = useState<any>(tagsObjList)
  const [inputText, setInputText] = useState<string>('')
  const [disable, setDisable] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [file, setFile] = useState<File>()
  const [fileError, setFileError] = useState<boolean>(false)
  const [fileErrorMessage, setFileErrorMessage] = useState<string>('')

  let date = new Date()
  let maxDate = date.toISOString().split('T')[0]

  const initValues = addHissaValuationDetailInits

  const [badgeList] = useState<SelectOptionType[]>([
    { value: 'Add New', label: 'Add New' },
    { value: 'DCF Method', label: 'DCF Method' },
    { value: 'Precedent Transaction Method', label: ' Precedent Transaction Method' },
  ])

  const handleChange = (e: any) => {
    setValues(e)

    e?.forEach((ele: any) => {
      if (ele.value === 'Add New') {
        setValues((values: any) => {
          return values?.filter((element: any) => element?.value !== 'Add New')
        })
        setCustomTag((customTag) => {
          return !customTag
        })
      }
    })
  }

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
    if (e.target.value && e.target.value.length > 0) {
      values.forEach((badge: any) => {
        if (badge.value === e.target.value) {
          setDisable(true)
          setErrorMessage('Multiple tags with the same name are not allowed ')
        } else {
          setDisable(false)
          setErrorMessage('')
        }
      })
    }
  }

  const changeHandler = async (event: any) => {
    const allowedTypes = ['pdf', 'docx']
    const filename = event.target.files[0].name
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename
    if (!allowedTypes.includes(extension)) {
      setFileError(true)
      formik.setFieldValue('valuationReport', '')
      setFileErrorMessage('File must be PDF or Document')
      setFile(undefined)
    } else if (event.target.files[0].size > 100e6) {
      setFileError(true)
      setFileErrorMessage('File must be lesser than 100 MB')
      setFile(undefined)
    } else if (event && event.target.files.length > 0 && event.target.files[0].size < 100e6) {
      setFile(event.target.files[0])
      formik.setFieldValue('valuationReport', event.target.value)
      setFileError(false)
      setFileErrorMessage('')
    }
  }

  const formik = useFormik<IHissaValuationDetails>({
    initialValues: initValues,
    validationSchema: addHissaValuationDetailSchema,
    // validateOnMount: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (finalValues) => {
      // uploadFiles()
      const tags: string[] = []

      values.map((element: any) => tags.push(element.value))

      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, finalValues)
        setData(updatedData)
        mutateAddValuationDetails.mutate({
          input: {
            valuationName: finalValues.name,
            valuationDate: new Date(finalValues.valuationDate).toISOString(),
            valuationType: finalValues.valuationType,
            methodology: finalValues.methodology,
            file: file,
            tags: tags,
            companyId: Number(props.companyDetails.id),
            hissaCompanyId: props.companyDetails.hissaCompanyId,
            companyName: props.companyDetails.name,
            sharePrice: Number(finalValues.sharePrice),
            sharePriceBookValue: Number(finalValues.sharePriceBookValue),
            sharePricePerReport: Number(finalValues.sharePricePerReport),
            valuatorName: finalValues.valuatorName,
            companyCurrency: props.companyDetails.companyCurrency,
            companyCurrentSharePrice: props.companyDetails.sharePrice || 1,
            companyType:
              props.companyDetails.type === CompanyTypes.Hissa
                ? CompanyTypes.Hissa
                : CompanyTypes.Direct,
            currency: '',
            jurisdiction: props.companyDetails.jurisdiction || 'India',
            valuationReport: finalValues.valuationReport,
          },
        })
        setLoading(false)
      }, 1000)
    },
  })
  const mutateAddValuationDetails = useAddValuationDetailsMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddValuationDetailsMutation,
        _variables: AddValuationDetailsMutationVariables,
        _context: unknown
      ) => {
        props.closeModal()
        if (props.companyDetails) {
          const compKey = {
            input: {
              type: props.companyDetails?.type,
              hissaCompanyId: props.companyDetails?.hissaCompanyId || '',
              id: Number(props.companyDetails?.id) || 0,
            },
          }
          const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
          queryClient.invalidateQueries(getCompanyWiseQuery)
        }

        const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()
        queryClient.invalidateQueries(getAllTransactionQuery)
        Toast('Valuation Details Created Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form card w-100 p-0'>
      <div className='w-100 mx-auto'>
        <div className='d-flex mb-8'>
          <div className='fs-4 fw-bold'>Valuation Details</div>
          <hr className='my-auto flex-grow-1' />
        </div>
        <div className='row'>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>Valuation Name</label>

            <InputBox
              isDisabled={false}
              name={'name'}
              handleChange={(value: string) => {
                formik.setFieldValue('name', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('name', value)
              }}
              placeholder='Enter Valuation Name'
              currency={userCurrency}
              value={formik.values.name}
              type='text'
            />
            <div className='text-danger mt-2'>
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>Date of Valuation</label>

            <input
              type='date'
              className='form-control custom-input fw-bold shadow-lg h-38px'
              placeholder='Enter Valuation Date'
              max={maxDate}
              {...formik.getFieldProps('valuationDate')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.valuationDate && formik.errors.valuationDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.valuationDate}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4'>
            <label className='form-label '>Valuation Methodology</label>

            <InputBox
              isDisabled={false}
              name={'methodology'}
              handleChange={(value: string) => {
                formik.setFieldValue('methodology', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('methodology', value)
              }}
              placeholder='Enter Valuation Methodology'
              currency={userCurrency}
              value={formik.values.methodology || ''}
              type='text'
            />
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label '>Valuation Type</label>
            <InputBox
              isDisabled={false}
              name={'valuationType'}
              handleChange={(value: string) => {
                formik.setFieldValue('valuationType', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('valuationType', value)
              }}
              placeholder='Enter Valuation Type'
              currency={userCurrency}
              value={formik.values.valuationType || ''}
              type='text'
            />
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label '>Valuator Name</label>
            <InputBox
              isDisabled={false}
              name={'valuatorName'}
              handleChange={(value: string) => {
                formik.setFieldValue('valuatorName', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('valuatorName', value)
              }}
              placeholder='Enter Valuator Name'
              currency={userCurrency}
              value={formik.values.valuatorName || ''}
              type='text'
            />
          </div>
        </div>

        <div className='row'>
          <div className='fv-row mb-6 col-12 col-sm-6 pe-4'>
            <label className='form-label '>Valuation Report</label>
            <div className='input-group'>
              <input
                type='file'
                className='form-control custom-input fw-bold shadow-lg h-38px py-2 text-primary'
                accept='.doc, .docx,.pdf,'
                {...formik.getFieldProps('valuationReport')}
                onChange={(e) => {
                  changeHandler(e)
                }}
              />
              {formik.values.valuationReport !== '' && (
                <button
                  className='btn btn-sm btn-outline p-0 ps-2 border border-gray-400'
                  type='button'
                  onClick={() => {
                    formik.setFieldValue('valuationReport', '')
                    setFileError(false)
                    setFile(undefined)
                    setFileErrorMessage('')
                  }}
                >
                  <KTSVG
                    path='/media/icons/duotune/abstract/abs012.svg'
                    className='svg-icon-1 svg-icon-gray-500'
                  />
                </button>
              )}
            </div>
            <div className='text-danger mt-2'>
              {fileError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{fileErrorMessage}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-6 col-12 col-sm-6'>
            <label className='form-label '>Tags</label>
            <div className=''>
              {customTag === false ? (
                <Select
                  className='form-control-solid'
                  options={badgeList}
                  placeholder={'--Select--'}
                  value={values}
                  isMulti={true}
                  onChange={(e: any) => handleChange(e)}
                  styles={selectCustomStyles}
                />
              ) : (
                <div className='d-flex'>
                  <div className='me-4'>
                    <input
                      className='form-control form-control-solid me-2'
                      placeholder='Enter Tag Name'
                      onChange={(e) => handleChangeValue(e)}
                    />
                    {errorMessage && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block fs-7'>{errorMessage}</div>
                      </div>
                    )}
                    {}
                  </div>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    type='submit'
                    disabled={disable}
                    onClick={(e) => {
                      setValues((values: any) => [
                        ...values,
                        { value: inputText, label: inputText },
                      ])
                      setCustomTag(false)
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr012.svg'
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    onClick={() => {
                      setCustomTag(false)
                      setErrorMessage('')
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr061.svg'
                    />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='d-flex mb-8 pt-4'>
          <div className=' fs-4 fw-bold'>Share Price Details </div>
          <hr className=' my-auto flex-grow-1' />
        </div>

        <div className='row'>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>
              Share Price as per Report{' '}
              <span style={{ fontFamily: 'arial' }}>({getSymbolOfCurrency(userCurrency)})</span>
            </label>

            <InputBox
              isDisabled={false}
              name={'sharePricePerReport'}
              handleChange={(value: number) => {
                formik.setFieldValue('sharePricePerReport', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('sharePricePerReport', value)
              }}
              placeholder='Enter Share Price'
              currency={userCurrency}
              isCurrency={true}
              value={formik.values.sharePricePerReport}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.sharePricePerReport && formik.errors.sharePricePerReport && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.sharePricePerReport}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label'>
              Share Price FMV{' '}
              <span style={{ fontFamily: 'arial' }}>({getSymbolOfCurrency(userCurrency)})</span>
            </label>
            <InputBox
              isDisabled={false}
              name={'sharePrice'}
              handleChange={(value: number) => {
                formik.setFieldValue('sharePrice', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('sharePrice', value)
              }}
              placeholder='Enter Exchange Rate'
              currency={userCurrency}
              isCurrency={true}
              value={formik.values.sharePrice || 0}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.sharePrice && formik.errors.sharePrice && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.sharePrice}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4'>
            <label className='form-label'>
              Share Price on Books{' '}
              <span style={{ fontFamily: 'arial' }}>({getSymbolOfCurrency(userCurrency)})</span>
            </label>

            <InputBox
              isDisabled={false}
              name={'sharePriceBookValue'}
              handleChange={(value: number) => {
                formik.setFieldValue('sharePriceBookValue', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('sharePriceBookValue', value)
              }}
              placeholder='Enter Exchange Rate'
              currency={userCurrency}
              isCurrency={true}
              value={formik.values.sharePriceBookValue || 0}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.sharePriceBookValue && formik.errors.sharePriceBookValue && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.sharePriceBookValue}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between pt-10'>
          <button className='btn btn-lg btn-secondary me-3' onClick={props.closeModal}>
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-primary'
            disabled={loading || mutateAddValuationDetails.isLoading}
          >
            {!loading && !mutateAddValuationDetails.isLoading && 'Save'}
            {mutateAddValuationDetails.isLoading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}

            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default AddHissaValuationDetailsForm
