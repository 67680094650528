import * as Yup from 'yup'
import { PartnerType } from '../../../../generated/graphql'

export interface IAddPartnerData {
  name: string
  partnerType: PartnerType
  country: string
  isGroup: boolean
  isAddPartner: boolean
  partnerName: string
  contribution: number
  isAddFundUnit: boolean
  unit: string
  noOfUnits: number
}

const addPartnerSchema = Yup.object().shape({
  name: Yup.string().required('Enter Name').label('Name'),
  PartnerType: Yup.mixed().oneOf(['LimitedPartner', 'GeneralPartner']).label('Partner Type'),
  country: Yup.string().label('Country'),
  partnerName: Yup.string()
    .min(3, 'Partner name must be greater than 3 characters')
    .label('Partner Name'),
  contribution: Yup.number().required().moreThan(0).label('Group Contribution'),
  noOfUnits: Yup.number().required().moreThan(0).label('Number of Units'),
})

const addPartnerInits: IAddPartnerData = {
  name: '',
  partnerName: '',
  country: 'India',
  isGroup: false,
  isAddPartner: false,
  isAddFundUnit: false,
  partnerType: PartnerType.LimitedPartner,
  contribution: 0,
  noOfUnits: 1,
  unit: '',
}

export { addPartnerInits, addPartnerSchema }
