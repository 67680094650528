import { create } from 'zustand'
import { configurePersist } from 'zustand-persist'

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: 'root',
})

const persist = _persist as any

type LatestActivityState = {
  startDate: Date
  endDate: Date
  setStartDate: (value: Date) => void
  setEndDate: (value: Date) => void
  reset: () => void
}

const today = new Date()
const initialState = {
  startDate: today.setDate(today.getDate() - 90),
  endDate: new Date(),
}

const latestActivityStore = create<LatestActivityState>(
  persist(
    {
      key: 'latestActivity',
    },
    (set: any) => ({
      ...initialState,
      setStartDate: (text: Date) => {
        set((state: any) => ({ startDate: text }))
      },
      setEndDate: (text: Date) => {
        set((state: any) => ({ endDate: text }))
      },
      reset: () => {
        set(() => initialState)
      },
    })
  )
)

export const useLatestActivityStore = latestActivityStore
