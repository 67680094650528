import * as Yup from 'yup'

export interface IAddFundStructureData {
  fundSize: string
  fundExpenses: string
  fundTenure: string
  inceptionDate: string
  investibleFundSize: number
  carryPercentage: string
  hurdlePercentage: string
  fundType: string
}

const addFundStructureSchema = Yup.object().shape({
  fundSize: Yup.number()
    .positive()
    .moreThan(0, 'Should be greater than 0')
    .required()
    .label('Fund Size'),
  fundExpenses: Yup.number().when(['fundSize'], {
    is: (fundSize: number) => fundSize > 0,
    then: Yup.number().required().lessThan(Yup.ref('fundSize')).label('Fund Expenses'),
    otherwise: Yup.number().notRequired().label('Fund Expenses'),
  }),
  fundTenure: Yup.number()
    .required()
    .integer('Fund Tenure should not be decimal')
    .moreThan(0)
    .label('Fund Tenure'),
  inceptionDate: Yup.string().required().label('Inception Date'),
  investibleFundSize: Yup.number().required().moreThan(0).label('Investible Fund Size'),
  carryPercentage: Yup.number().lessThan(100).moreThan(0).required().label('Carry Percentage'),
  hurdlePercentage: Yup.number().lessThan(100).moreThan(0).required().label('Hurdle Percentage'),
})

const addFundStructureInits: IAddFundStructureData = {
  fundSize: '',
  fundExpenses: '',
  fundTenure: '',
  inceptionDate: '',
  investibleFundSize: 0,
  carryPercentage: '',
  hurdlePercentage: '',
  fundType: '',
}

export { addFundStructureInits, addFundStructureSchema }
