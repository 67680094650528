import { SideBarDef } from 'ag-grid-community'

export const tableEntryStyle = 'text-left font-medium text-xs2 text-[#464E5F]'
export const tableCellStyle = { 'padding-top': '4px', 'line-height': '20px' }
export const tableFirstColStyle = 'text-left font-semibold text-xs2 text-[#464E5F]'

export const agConfigSideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      minWidth: 200,
      width: 250,
      maxWidth: 300,
      toolPanelParams: {
        hide: true,
        suppressRowGroups: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressValues: true,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      minWidth: 200,
      maxWidth: 300,
      width: 250,
    },
  ],
}
