import * as Yup from 'yup'

export interface IEmailTermsheet {
  companyEmails?: string
  investorsEmails?: string
}

const emailTermsheetSchema = Yup.object({
  companyEmails: Yup.string(),
  investorsEmails: Yup.string(),
})

const inits: IEmailTermsheet = {
  companyEmails: '',
  investorsEmails: '',
}

export { emailTermsheetSchema, inits }
