import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import TemplateView from './TemplateView'
import {
  GetDealTemplatesQuery,
  GetInvestorsQuery,
  Investor,
  useGetDealTemplatesQuery,
  useGetInvestorsQuery,
} from '../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../queries/client'
import { InvestorList } from '../../../../../types/InvestorList'
import TermsheetWrapper from '../TermsheetWrapper'
import { SendTermsheetWizard } from './SendTermsheetWizard'
import { getCurrentTemplate } from '../../../../../utils/stepper'

function IssueTermSheetWrapper() {
  const location = useLocation()
  const defaultOption = (location.state as any)?.defaultOption
  const { data } = useGetDealTemplatesQuery<GetDealTemplatesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const [isBuilderVisible, setIsBuilderVisible] = useState(
    defaultOption === 'builder' ? true : false
  )
  // const [isWizardVisible, setIsWizardVisible] = useState(defaultOption === 'builder' ? false : true)
  const [isWizardVisible, setIsWizardVisible] = useState(false)
  const [allowedSections, setAllowedSections] = useState([])
  const [templateId, setTemplateId] = useState(1)
  const wizardRef = useRef<any>(null)
  const executeScroll = () => wizardRef.current?.scrollIntoView({ behavior: 'smooth' })
  const getInvestorsQuery = useGetInvestorsQuery<GetInvestorsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  const investorList: InvestorList[] = getInvestorsQuery.data?.investors.map(
    (investor: Investor) => {
      return { investorId: investor.id, investorName: investor.name }
    }
  )

  const templateData = getCurrentTemplate(data, templateId)

  return (
    <div>
      <div>
        <TemplateView
          data={data}
          toggleBuilder={(value: boolean) => setIsBuilderVisible(value)}
          isBuilderVisible={isBuilderVisible}
          setAllowedSections={setAllowedSections}
          setTemplateIds={setTemplateId}
        />
      </div>
      <div>
        {isWizardVisible && templateData && templateData['documentType'] === 'Termsheet' && (
          <SendTermsheetWizard
            genericData={templateData}
            allowedSections={allowedSections}
            wizardRef={wizardRef}
            templateId={templateId}
          />
        )}
      </div>
    </div>
  )
}

export default IssueTermSheetWrapper
