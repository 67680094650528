import { useEffect, useRef, useState } from 'react'
import {
  AddDynamicTableDataMutation,
  AddDynamicTableDataMutationVariables,
  DeleteDynamicTableDataMutation,
  DeleteDynamicTableDataMutationVariables,
  GetAllDynamicTableDataQuery,
  useAddDynamicTableDataMutation,
  useDeleteDynamicTableDataMutation,
  useGetAllDynamicTableDataQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import DynamicTablePageWrapper, {
  DynamicTableDataInputType,
  DynamicTableDataType,
  DynamicTableDataInput,
} from './DynamicTablesPageWrapper'

export const DynamicTableMainWrapper = () => {
  const [tableData, setTableData] = useState<DynamicTableDataType[]>([])
  const { data, isFetched, isLoading } = useGetAllDynamicTableDataQuery<
    GetAllDynamicTableDataQuery,
    Error
  >(graphqlRequestClient, {}, {})

  const mutateSaveDynamicTableData = useAddDynamicTableDataMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddDynamicTableDataMutation,
        _variables: AddDynamicTableDataMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetAllDynamicTableDataQuery.getKey())

        Toast('Data Saved Successfully', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const mutateRemoveCompany = useDeleteDynamicTableDataMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: DeleteDynamicTableDataMutation,
        _variables: DeleteDynamicTableDataMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetAllDynamicTableDataQuery.getKey())
        Toast('Data Removed Successfully', ToastType.success)
      },
    },
    {}
  )
  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)

  function handleRemoveTableData(e: React.MouseEvent, currentId: number) {
    e.stopPropagation()
    // Disable indicator after 3 seconds
    deleteBtnRef.current?.setAttribute('data-kt-indicator', 'on')
    setTimeout(() => {
      // Activate indicator
      deleteBtnRef.current?.removeAttribute('data-kt-indicator')
    }, 3000)
    e.preventDefault()

    mutateRemoveCompany.mutate({ input: { id: currentId } })
  }

  useEffect(() => {
    data &&
      data.getDynamicTableData &&
      setTableData(data.getDynamicTableData as DynamicTableDataType[])
  }, [data])

  function handleSave(values: any) {
    mutateSaveDynamicTableData.mutate({
      input: {
        file: values.file,
        tableData: values.tableData,
        chartData: values.chartData,
        description: values.description,
        name: values.name,
        isExisting: values.isExisting,
        id: values.id && Number(values.id),
      },
    })
  }
  return (
    <div>
      <DynamicTablePageWrapper
        data={tableData}
        handleSave={(values: DynamicTableDataInput) => {
          handleSave(values)
        }}
        handleRemove={(event: React.MouseEvent, id: number) => {
          handleRemoveTableData(event, id)
        }}
      />
    </div>
  )
}
