import { useEffect, useState } from 'react'
import {
  FundStructure,
  FundUnit,
  GetPartnersGroupQuery,
  LedgerTransaction,
  PartnerGroup,
  useGetPartnersGroupQuery,
} from '../../../../generated/graphql'
import { kFormatter } from '../../../../utils/CurrencyFormatter'
import { toFix } from '../../../../utils/ToFix'
import { Moic, getDPI, getIrr, getMoicObject, getRPI, getTVPI } from './Utils'
import AddFundStructureModal from './AddFundStructureModal'
import { KTSVG } from '../../../../_metronic/helpers'
import CapitalTransactionView from '../CapitalTransactions/CapitalTransactionTableView'
import FundUnitView from '../FundUnit/FundUnitView'
import PartnerGroupTable from '../Partners/PartnerGroupView'
import Toast, { ToastType } from '../../../../utils/toast'
import { graphqlRequestClient } from '../../../../queries/client'
import AddLedgerTransactionModal from '../CapitalTransactions/AddLedgerTransactionModal'
import AddFundUnitModal from '../FundUnit/AddFunUnitModal'
import AddPartnerModal from '../Partners/AddPartnerModal'

type Props = {
  investorId: number
  fundStructure: FundStructure
  fundUnitData: FundUnit[]
  capitalLedgerTransactions: LedgerTransaction[]
}

function FundStructureView({
  fundStructure,
  investorId,
  fundUnitData,
  capitalLedgerTransactions,
}: Props) {
  const [showEditFundStructureModal, setShowEditFundStructureModal] = useState(false)

  const irr = getIrr(fundStructure)
  const grossIrr = toFix(irr.grossIrr)
  const netIrr = irr.netIrr
  const [showAddTransactionModal, setShowAddTransactionModal] = useState(false)
  const [showAddFundUnitModal, setShowAddFundUnitModal] = useState(false)
  const [showPartnerGrpModal, setShowPartnerGrpModal] = useState(false)
  const [partnersGroup, setPartnersGroup] = useState<PartnerGroup[]>()
  const [dataToggle, setDataToggle] = useState<string>('transactions')
  const [header, setHeader] = useState<string>('Transactions')
  const [view, setView] = useState<JSX.Element>(
    <CapitalTransactionView
      data={capitalLedgerTransactions}
      fundStructureId={fundStructure.id}
      investorId={investorId}
      fundStructure={fundStructure}
    />
  )
  const getPartnerGroups = useGetPartnersGroupQuery<GetPartnersGroupQuery, Error>(
    graphqlRequestClient,
    { input: { investorId: investorId, fundStructureId: fundStructure.id } },
    {}
  )

  const handleClose = () => {
    setShowEditFundStructureModal(false)
  }

  const handleCloseFundUnit = () => {
    setShowAddFundUnitModal(!showAddFundUnitModal)
  }
  const getFundStructureCard = (fundStructure: FundStructure) => {
    return (
      <>
        <div className=' col-sm-6'>
          <div className='col d-flex justify-content-between'>
            <h3>
              Fund Size <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </h3>
            <h3
              data-toggle='tooltip'
              data-placement='top'
              title={Number(fundStructure.fundSize).toLocaleString('en-IN')}
            >
              {kFormatter(fundStructure.fundSize!)}
            </h3>
          </div>
        </div>
        <div className=' col-sm-6'>
          <div className='col d-flex justify-content-between'>
            <h3>
              Investible Fund Size <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </h3>
            <h3
              data-toggle='tooltip'
              data-placement='top'
              title={Number(fundStructure.investibleFundSize).toLocaleString('en-IN')}
            >
              {kFormatter(fundStructure.investibleFundSize!)}
            </h3>
          </div>
        </div>
        <div className='col-sm-6'>
          <div className='col d-flex justify-content-between'>
            <h3>
              Fund Expenses <span style={{ fontFamily: 'arial' }}>(₹)</span>
            </h3>
            <h3
              data-toggle='tooltip'
              data-placement='top'
              title={Number(fundStructure.fundExpenses).toLocaleString('en-IN')}
            >
              {kFormatter(fundStructure.fundExpenses!)}
            </h3>
          </div>
        </div>
        <div className=' col-sm-6 '>
          <div className='col d-flex justify-content-between'>
            <h3>Fund Tenure</h3>
            <h3
              data-toggle='tooltip'
              data-placement='top'
              title={Number(fundStructure.fundTenure).toLocaleString('en-IN')}
            >
              {fundStructure.fundTenure! + ' Years'}
            </h3>
          </div>
        </div>
        <div className=' col-sm-6'>
          <div className='col d-flex justify-content-between'>
            <h3>Hurdle Percentage</h3>
            <h3
              data-toggle='tooltip'
              data-placement='top'
              title={Number(fundStructure.hurdlePercentage).toLocaleString('en-IN')}
            >
              {toFix(fundStructure.hurdlePercentage!) + '%'}
            </h3>
          </div>
        </div>
        <div className=' col-sm-6 '>
          <div className='col d-flex justify-content-between'>
            <h3>Carry Percentage</h3>
            <h3
              data-toggle='tooltip'
              data-placement='top'
              title={Number(fundStructure.carryPercentage).toLocaleString('en-IN')}
            >
              {fundStructure.carryPercentage! + '%'}
            </h3>
          </div>
        </div>
        {/* <div className='col-lg-4 col-md-4 col-sm-6'>
          <div className='col d-flex justify-content-between'>
            <h3>Fund Inception Date</h3>
            <h3
              data-toggle='tooltip'
              data-placement='top'
              title={Number(fundStructure.inceptionDate).toLocaleString('en-IN')}
            >
              {isoToDate(fundStructure.inceptionDate!)}
            </h3>
          </div>
        </div> */}
      </>
    )
  }

  const handleClosePartnerGrp = () => {
    setShowPartnerGrpModal(!showPartnerGrpModal)
  }

  const handleTransactionModalClose = () => {
    setShowAddTransactionModal(!showAddTransactionModal)
  }

  useEffect(() => {
    if (getPartnerGroups?.data?.getPartnersGroup) {
      setPartnersGroup(getPartnerGroups?.data?.getPartnersGroup as PartnerGroup[])
    }
  }, [getPartnerGroups?.data?.getPartnersGroup])

  const updateToggle = (type: string) => {
    setDataToggle(type)
    if (type === 'fundUnits') {
      setHeader('Fund Units')
      setView(
        <FundUnitView
          data={fundUnitData}
          fundStructure={fundStructure}
          investorId={Number(investorId)}
        />
      )
    }
    if (type === 'partners') {
      setHeader('Partner Groups')
      setView(<PartnerGroupTable fundStructure={fundStructure!} investorId={investorId} />)
    }
    if (type === 'transactions') {
      setHeader('Transactions')
      setView(
        <CapitalTransactionView
          data={capitalLedgerTransactions}
          fundStructureId={fundStructure.id}
          investorId={investorId}
          fundStructure={fundStructure}
        />
      )
    }
  }

  const fundStructureCard = getFundStructureCard(fundStructure)
  const metricsCard = getMetricsCards(fundStructure)

  return (
    <>
      <div className='row'>
        <div className='col-md-8'>
          <div className='card mb-5 shadow-sm '>
            <div className='card-body pt-3 pb-3'>
              <div className='d-flex justify-content-start'>
                <h1 className='pb-3 pt-2'>Fund Structure</h1>
                <div className='ps-2'>
                  <button
                    className='btn btn-sm btn-icon'
                    onClick={() => {
                      setShowEditFundStructureModal(true)
                    }}
                  >
                    <span className='svg-icon svg-icon-2'>
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
                    </span>
                  </button>
                  {
                    <AddFundStructureModal
                      showModal={showEditFundStructureModal}
                      handleClose={handleClose}
                      investorId={investorId}
                      fundStructureId={fundStructure.id}
                      isEdit={true}
                      data={fundStructure}
                    />
                  }
                </div>
              </div>
              <div className='row gy-8 px-0 py-1'>{fundStructureCard}</div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='card p-5 shadow-sm min-h-md-200px mh-md-200px'>
            <h3>IRR</h3>
            <div className='row'>
              <div className='col-6 border-gray-200 border-end border-2 pe-2'>
                <p className='text-center pb-2 '>GROSS</p>
                <p className='display-8 text-center mb-3 ps-2'>
                  {grossIrr}
                  <span className='fs-6'> %</span>
                </p>

                {/* <div className='d-flex justify-content-between '>
                  <div className='text-gray-500'>Gross Realized IRR </div> :
                  <div className='text-gray-500 ps-2'>{grossRealized}</div>
                </div> */}
              </div>
              <div className='col-6 pe-2'>
                <p className='text-center pb-2 '>NET</p>
                <p className='display-8 text-center mb-3 ps-2'>
                  {netIrr}
                  <span className='fs-6'> %</span>
                </p>

                {/* <div className='d-flex justify-content-between '>
                  <div className='text-gray-500'>Net Realized IRR</div>:
                  <div className='text-gray-500 ps-2'>{netRealized}</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>{metricsCard}</div>
      <div className='col-md-12 pt-6'>
        <div className='card shadow-sm'>
          <div className='row px-6'>
            <div className='col-md-6 pt-12 pb-0'>
              <h1 className=''>{header}</h1>
            </div>
            <div className='col-md-6 pt-10 pb-0'>
              <div className='btn-group mb-10 float-lg-end float-md-end'>
                <button
                  className={`btn btn-sm fs-base fw-bold ${
                    dataToggle === 'transactions'
                      ? 'btn-primary btn-active-primary'
                      : 'btn-gray-800 btn-bg-light'
                  }`}
                  onClick={() => updateToggle('transactions')}
                >
                  Transactions
                </button>
                <button
                  className={`btn btn-sm fs-base fw-bold ${
                    dataToggle === 'fundUnits'
                      ? 'btn-primary btn-active-primary'
                      : 'btn-gray-800 btn-bg-light'
                  }`}
                  onClick={() => updateToggle('fundUnits')}
                >
                  Fund Units
                </button>
                <button
                  className={`btn btn-sm fs-base fw-bold ${
                    dataToggle === 'partners'
                      ? 'btn-primary btn-active-primary'
                      : 'btn-gray-800 btn-bg-light'
                  }`}
                  onClick={() => updateToggle('partners')}
                >
                  Partner Groups
                </button>
              </div>
            </div>
          </div>
          <div className='p-2'>
            <div className='col-md-12 border-top border-gray-300 border-radius-0 p-0'>
              <div className={`card`}>
                <div className='card-body py-3'>
                  <div className='table-responsive min-h-300px'>
                    <table className='table align-middle gs-0 gy-4 table-row-dashed border-gray-200'></table>
                    <div className='card-body overflow-auto'>{view}</div>
                  </div>
                  {dataToggle === 'transactions' && (
                    <div className='col-md-3'>
                      <button
                        className='btn btn-sm btn-primary mt-4 fw-bold'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_1'
                        onClick={() => {
                          setShowAddTransactionModal(!showAddTransactionModal)
                          if (partnersGroup && partnersGroup?.length === 0) {
                            Toast(
                              'No Partner Groups found, Add partner group first and try again"',
                              ToastType.error
                            )
                          }
                        }}
                      >
                        Add Transaction
                      </button>
                      {partnersGroup && partnersGroup?.length > 0 && (
                        <AddLedgerTransactionModal
                          showModal={showAddTransactionModal}
                          handleClose={handleTransactionModalClose}
                          investorId={investorId}
                          fundStructureId={fundStructure.id}
                          partnerGroups={partnersGroup!}
                          fundStructure={fundStructure}
                          isEdit={false}
                        />
                      )}
                    </div>
                  )}
                  {dataToggle === 'fundUnits' && (
                    <div className='col-md-3'>
                      <button
                        className='btn btn-sm btn-primary mt-4 fw-bold'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_1'
                        onClick={() => {
                          setShowAddFundUnitModal(!showAddFundUnitModal)
                        }}
                      >
                        Add Fund Unit
                      </button>
                      <AddFundUnitModal
                        showModal={showAddFundUnitModal}
                        handleClose={handleCloseFundUnit}
                        investorId={investorId}
                        fundStructure={fundStructure}
                        partnerData={partnersGroup!}
                        isEdit={false}
                      />
                    </div>
                  )}

                  {dataToggle === 'partners' && (
                    <div className='col-md-3'>
                      <button
                        className='btn btn-sm btn-primary mt-4 fw-bold'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_1'
                        onClick={() => {
                          setShowPartnerGrpModal(!showPartnerGrpModal)
                          if (fundUnitData && fundUnitData?.length === 0) {
                            Toast(
                              'No Fund Units found, Add Fund Unit first and try again',
                              ToastType.error
                            )
                          }
                        }}
                      >
                        Add Partner Group
                      </button>
                      {fundUnitData && fundUnitData?.length > 0 && (
                        <AddPartnerModal
                          showModal={showPartnerGrpModal}
                          handleClose={handleClosePartnerGrp}
                          investorId={investorId}
                          fundStructure={fundStructure}
                          isEdit={false}
                          fundUnitData={fundUnitData!}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const getMetricsCards = (fundStructure: FundStructure) => {
  const moicObject: Moic = getMoicObject(fundStructure)
  const unrealizedMoic = { label: 'Unrealized MOIC', value: toFix(moicObject.unrealized) + 'x' }
  const realizedMoic = { label: 'Realized MOIC', value: toFix(moicObject.realized) + 'x' }

  const cardMOIC = createCard('MOIC', toFix(moicObject.moic) + 'x', true, [
    realizedMoic,
    unrealizedMoic,
  ])

  const cardRVPI = createCard('RVPI', toFix(getRPI(fundStructure)), false, [])

  const cardDPI = createCard('DPI', toFix(getDPI(fundStructure)), false, [])

  // const netTVPI = { label: 'Net TVPI', value:  toFix(moicObject.moic)}
  const cardTVPI = createCard('TVPI', getTVPI(fundStructure).tvpi.toFixed(2), false, [])

  return (
    <>
      {cardMOIC}
      {cardTVPI}
      {cardRVPI}
      {cardDPI}
    </>
  )
}

const createCard = (
  title: string,
  value: string | number,
  isLast: boolean,
  subObjs?: { label: string; value: string | number }[]
) => {
  const createSubObjCard = (label: string, value: number | string) => {
    return (
      <>
        <div className='d-flex justify-content-between py-0'>
          <div className='text-gray-500'>{label}:</div>
          <div className='text-gray-500'>{value}</div>
        </div>
      </>
    )
  }

  return (
    <div className='col-sm-6 col-md-3 pt-2 pt-md-0'>
      <div className='card p-4 shadow-sm min-h-125px mh-125px'>
        <div key={title} className='rounded-3'>
          <div className='col'>
            <h3>{title}</h3>
            <p className='display-8 text-center mb-3'>{value}</p>
            <div className='row'>
              {subObjs?.map((obj) => createSubObjCard(obj.label, obj.value))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FundStructureView
