import { Modal } from "react-bootstrap"
import { KTSVG } from "../../../../../_metronic/helpers"
import { useFormikContext } from "formik"
import { TermsheetType } from "../../../open-deals/components/TermsheetHelper"
import { useEffect, useState } from "react"

function SignatureIframe(props:{showDialog:boolean,token:string,handleClose:Function}) {
    const formik = useFormikContext<TermsheetType>()
    const [formikvalues,setFormikValues] = useState<any>('')
    const esignEndpoint = process.env.REACT_APP_ESIGN_URL
    useEffect(()=>{
        setFormikValues(formik?.values)
    },[formik?.values])
  return (
    <Modal
    id='kt_modal_sign_doc'
    aria-hidden='true'
    fullscreen={true}
    dialogClassName='modal-dialog modal-dialog-centered'
    show={props.showDialog}
    >
     <Modal.Header>
        <Modal.Title>Sign Document</Modal.Title>
        <div className='row justify-content-end'>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => {
              props.handleClose(formikvalues)
              // setSuccessMessage('')
              // setErrorMessage('')
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
          </button>
        </div>
    </Modal.Header> 
    <Modal.Body>
        <iframe title="Esign" width="100%" height="100%" src={`${esignEndpoint}/admin/requestSign?iframe=true&&logintoken=${props.token}`}></iframe>
    </Modal.Body>  
    </Modal>
  )
}

export default SignatureIframe