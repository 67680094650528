import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'

import {
  AddInvestmentInputMutation,
  AddInvestmentInputMutationVariables,
  CompanyObject,
  useAddDirectTransactionMutation,
  Transaction_Type,
  useGetCompanyWiseDataQuery,
  useGetAllTransactionsQuery,
  TransactionObject,
  useEditDirectTransactionMutation,
  EditDirectTransactionMutation,
  EditDirectTransactionMutationVariables,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import { countryData } from '../../../../../utils/CountryData'
import _ from 'lodash'
import Select from 'react-select'
import Toast, { ToastType } from '../../../../../utils/toast'
import { selectCustomStyles } from '../../../../../utils/Select'
import { SelectOptionType, MultiSelectOptionType } from '../../../../../utils/MultiSelect'
import { InvestorListType } from '../../../company/views/HissaCompanySummaryPage'

import { DirectTransactionData, addDirectTransactionSchemas } from './directTransactionHelper'
import { currencies, currencyTypes } from '../../../../../utils/CurrencyList'
import { securitySubTypes } from '../../../company/utils/nonFdbSubTypes'
import {
  getCurrency,
  getKeyByValue,
  getSymbolOfCurrency,
} from '../../../../../utils/CurrencyFormatter'
import { InputBox } from '../../../../../utils/NumberInputBox'

type Props = {
  investorList: InvestorListType[]
  handleClose: () => void
  isCompanyExist: boolean
  companyData?: CompanyObject
  handleSubmit: () => void
  isEdit: boolean
  transactionData?: TransactionObject
}

export const AddDirectTransactionForm = (props: Props) => {
  const companyDetails = props.companyData
  const DirectTransactionInits: DirectTransactionData = !props.isEdit
    ? {
        country: 'India',
        currency: props.companyData?.companyCurrency || 'INR - ₹',
        investorId: 0,
        sharePrice: 1,
        currentSharePrice: props.companyData?.sharePrice || 1,
        exchangeRate: '',
        currentExchangeRate: 1,
        investmentDate: '', //new Date(10, 10, 2022),
        noOfShares: '',
        valuation: '',
        roundDetails: '',
        isAddShareNumber: false,
        transactionCurrency: props.companyData?.companyCurrency || 'INR - ₹',
        shareDistinctiveRange: '',
        industry: props.companyData?.industry || '',
        companyName: props.companyData?.name || '',
        cin: props.companyData?.cin || '',
        currentValuation: props.companyData?.valuation || 0,
        securitySubType: 'Equity',
      }
    : {
        country: companyDetails?.jurisdiction || 'India',
        currency: companyDetails?.companyCurrency || 'INR - ₹',
        investorId: props.transactionData?.investorId,
        sharePrice: props.transactionData?.sharePrice || 1,
        currentSharePrice: companyDetails?.sharePrice || 1,
        exchangeRate: props.transactionData?.exchangeRate?.toString() || '',
        currentExchangeRate: 1,
        investmentDate: props.transactionData?.transactionDate?.split('T')[0] || '', //new Date(10, 10, 2022),
        noOfShares: props.transactionData?.noOfShares.toString() || '',
        valuation: props.transactionData?.transactionValuation?.toString() || '',
        roundDetails: props.transactionData?.round || '',
        isAddShareNumber: props.transactionData?.shareDistinctiveRange !== null ? true : false,
        transactionCurrency: props.transactionData?.currency || 'INR - ₹',
        shareDistinctiveRange: props.transactionData?.shareDistinctiveRange || '',
        industry: companyDetails?.industry || '',
        companyName: companyDetails?.name || '',
        cin: companyDetails?.cin || '',
        currentValuation: companyDetails?.valuation || 0,
        securitySubType: props.transactionData?.securitySubType || '',
      }

  const [data, setData] = useState<DirectTransactionData>(_.cloneDeep(DirectTransactionInits))
  const [loading, setLoading] = useState(false)
  const [isChange, setIsChange] = useState(false)
  const [testState, setTestState] = useState<string>('')
  const [country, setCountry] = useState<string>('')
  const [transactionCurrency, setTransactionCurrency] = useState<any>({
    value: props.transactionData?.currency || props.companyData?.companyCurrency || 'INR - ₹',
    label: props.transactionData?.currency || props.companyData?.companyCurrency || 'INR - ₹',
  })
  const [currency, setCurrency] = useState<any>({
    value: props.companyData?.companyCurrency || 'INR - ₹',
    label: props.companyData?.companyCurrency || 'INR - ₹',
  })

  const [security, setSecurity] = useState<any>(
    props.isEdit
      ? {
          value: props.transactionData?.securitySubType,
          label: props.transactionData?.securitySubType,
        }
      : {
          value: 'Equity',
          label: 'Equity',
        }
  )

  let date = new Date()
  let maxDate = date.toISOString().split('T')[0]

  const companyCurrency = props.companyData?.companyCurrency
  const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()

  const mutateAddTransaction = useAddDirectTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddInvestmentInputMutation,
        _variables: AddInvestmentInputMutationVariables,
        _context: unknown
      ) => {
        if (props.companyData) {
          const compKey = {
            input: {
              type: props.companyData?.type,
              hissaCompanyId: props.companyData?.hissaCompanyId || '',
              id: Number(props.companyData?.id) || 0,
            },
          }
          const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
          queryClient.invalidateQueries(getCompanyWiseQuery)
        }
        queryClient.invalidateQueries(getAllTransactionQuery)

        setData(_.cloneDeep(DirectTransactionInits))
        Toast('Transaction Added Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const mutateEditTransaction = useEditDirectTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: EditDirectTransactionMutation,
        _variables: EditDirectTransactionMutationVariables,
        _context: unknown
      ) => {
        props.handleClose()
        if (props.companyData) {
          const compKey = {
            input: {
              type: props.companyData?.type,
              hissaCompanyId: props.companyData?.hissaCompanyId || '',
              id: Number(props.companyData?.id) || 0,
            },
          }
          const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
          queryClient.invalidateQueries(getCompanyWiseQuery)
        }

        queryClient.invalidateQueries(getAllTransactionQuery)
        Toast('Transaction Edited successfully!', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const formik = useFormik<DirectTransactionData>({
    initialValues: DirectTransactionInits,
    validationSchema: addDirectTransactionSchemas,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        props.isEdit
          ? mutateEditTransaction.mutate({
              input: {
                id: props.transactionData?.id,
                transactionCurrency: data.transactionCurrency || 'INR - ₹',
                sharePrice: data.sharePrice,
                transactionDate: new Date(data.investmentDate).toISOString(),
                transactionAmount: Number(data.sharePrice) * Number(data.noOfShares),
                transactionType: Transaction_Type.Buy,
                investorId: data.investorId,
                noOfShares: parseInt(data.noOfShares),
                roundName: data.roundDetails,
                valuation: parseInt(data.valuation),
                shareDistinctiveRange: formik.values.isAddShareNumber
                  ? data.shareDistinctiveRange
                  : '',
                transactionExchangeRate: parseInt(data.exchangeRate) || 1,
                securitySubType: data.securitySubType,
              },
            })
          : mutateAddTransaction.mutate({
              input: {
                cin: data.cin,
                transactionCurrency: data.transactionCurrency || 'INR - ₹',
                sharePrice: data.sharePrice,
                currentSharePrice: data.currentSharePrice || 1,
                industry: data.industry,
                transactionDate: new Date(data.investmentDate).toISOString(),
                transactionAmount: Number(data.sharePrice) * Number(data.noOfShares),
                transactionType: Transaction_Type.Buy,
                investorId: data.investorId,
                name: data.companyName || '',
                noOfShares: parseInt(data.noOfShares),
                roundName: data.roundDetails,
                valuation: parseInt(data.valuation),
                shareDistinctiveRange: formik.values.isAddShareNumber
                  ? data.shareDistinctiveRange
                  : '',
                companyValuation: data.currentValuation,
                transactionExchangeRate: parseInt(data.exchangeRate) || 1,
                jurisdiction: data.country || 'India',
                companyCurrency: data.currency || 'INR - ₹',
                securitySubType: data.securitySubType,
              },
            })
        setLoading(false)
        props.handleClose()
        props.handleSubmit()
      }, 1000)
    },
  })

  useEffect(() => {
    setCountry(formik.values.country)
    setIsChange(true)
  }, [formik.values.country])

  const countryList: MultiSelectOptionType[] = []

  countryData.forEach((country, index) => {
    if (!countryList.find((nameObj) => nameObj.label === country.name))
      countryList.push({ value: index, label: country.name })
  })
  const currencyList: SelectOptionType[] = []
  currencies.forEach((currency: string) => {
    currencyList.push({
      value: currency,
      label: currency,
    })
  })
  const securitiesList: SelectOptionType[] = []
  securitySubTypes.forEach((security: string) => {
    securitiesList.push({
      value: security,
      label: security,
    })
  })
  const investorName: MultiSelectOptionType[] = []

  props.investorList.map((investor: any) =>
    investorName.push({
      value: investor.investorId,
      label: investor.investorName,
    })
  )
  const handleInputChange = (event: any) => {
    const amount = event.target.value

    if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
      setTestState(amount)
    }
    formik.setFieldValue('currentSharePrice', Number(amount))
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form px-0'>
      <div className='w-100 mx-auto'>
        {!props.isCompanyExist && (
          <>
            <div className='d-flex mb-8'>
              <div className='fw-bold'>Company Details</div>
              <hr className='my-auto flex-grow-1' />
            </div>

            <div className='row'>
              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>Jurisdiction</label>

                <Select
                  className=''
                  options={countryList}
                  name='country'
                  placeholder={'India'}
                  styles={selectCustomStyles}
                  onChange={(e: any) => {
                    // handleChange(e.label)
                    formik.setFieldValue('country', e.label)
                  }}
                />
                <div className='text-danger fs-7 mt-1'>
                  {formik.touched.country && formik.errors.country && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.country}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>Company Name</label>

                <InputBox
                  isDisabled={false}
                  name={'companyName'}
                  handleChange={(value: string) => {
                    formik.setFieldValue('companyName', value)
                  }}
                  setFieldValue={(value: string) => {
                    formik.setFieldValue('companyName', value)
                  }}
                  placeholder={'Enter Company Name'}
                  currency={transactionCurrency.label}
                  value={formik.values.companyName}
                  type='text'
                />
                <div className='text-danger fs-7 mt-1'>
                  {formik.touched.companyName && formik.errors.companyName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.companyName}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4'>
                <label className='form-label'>Company ID</label>

                <InputBox
                  isDisabled={false}
                  name={'cin'}
                  handleChange={(value: number) => {
                    formik.setFieldValue('cin', value)
                  }}
                  setFieldValue={(value: number) => {
                    formik.setFieldValue('cin', value)
                  }}
                  placeholder={'Enter Company ID'}
                  currency={transactionCurrency.label}
                  value={formik.values.cin}
                  type='text'
                />
                <div className='text-danger fs-7 mt-1'>
                  {formik.touched.cin && formik.errors.cin && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.cin}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>Company Currency</label>

                <Select
                  options={currencyList.filter((value) => Object.keys(value).length !== 0)}
                  value={currency}
                  name='currency'
                  onChange={(e: any) => {
                    setCurrency(e)
                    formik.setFieldValue('currency', e.value)
                  }}
                  styles={selectCustomStyles}
                />
                <div className='text-danger fs-7 mt-1'>
                  {formik.touched.currency && formik.errors.currency && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.currency}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>
                  Current Share Price{' '}
                  <span style={{ fontFamily: 'arial' }}>
                    ({getSymbolOfCurrency(currency.label)})
                  </span>
                </label>

                <InputBox
                  isDisabled={false}
                  name={'currentSharePrice'}
                  handleChange={handleInputChange}
                  setFieldValue={(value: number) => {
                    formik.setFieldValue('currentSharePrice', value)
                  }}
                  currency={currency.label}
                  placeholder='Enter Current Share Price'
                  value={formik.values.currentSharePrice}
                  isCurrency={true}
                  type='number'
                />

                <div className='text-danger fs-7 mt-1'>
                  {formik.touched.currentSharePrice && formik.errors.currentSharePrice && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.currentSharePrice}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>
                  Current Valuation{' '}
                  <span style={{ fontFamily: 'arial' }}>
                    ({getSymbolOfCurrency(currency.label)})
                  </span>
                </label>

                <InputBox
                  isDisabled={false}
                  name={'currentValuation'}
                  handleChange={(value: number) => {
                    formik.setFieldValue('currentValuation', value)
                  }}
                  setFieldValue={(value: number) => {
                    formik.setFieldValue('currentValuation', value)
                  }}
                  currency={currency.label}
                  value={formik.values.currentValuation}
                  isCurrency={true}
                  type='number'
                />
                <div className='text-danger fs-7 mt-1'>
                  {formik.touched.currentValuation && formik.errors.currentValuation && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.currentValuation}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4'>
                <label className='form-label required'>Industry Type</label>

                <InputBox
                  isDisabled={false}
                  name={'industry'}
                  handleChange={(value: string) => {
                    formik.setFieldValue('industry', value)
                  }}
                  setFieldValue={(value: string) => {
                    formik.setFieldValue('industry', value)
                  }}
                  placeholder='Enter Industry Type'
                  currency={transactionCurrency.label}
                  value={formik.values.industry}
                  type='text'
                />
                <div className='text-danger fs-7 mt-1'>
                  {formik.touched.industry && formik.errors.industry && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.industry}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>Current Exchange Rate</label>
                <input
                  type='number'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Enter Current Exchange Rate'
                  {...formik.getFieldProps('currentExchangeRate')}
                />
                <div className='text-danger fs-7 mt-1'>
                  {formik.touched.currentExchangeRate && formik.errors.currentExchangeRate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.currentExchangeRate}</div>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          </>
        )}

        {!props.isCompanyExist && (
          <div className='d-flex mb-8'>
            <div className='fw-bold'>Investment Details </div>
            <hr className='my-auto flex-grow-1' />
          </div>
        )}
        <div className='row'>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>Transaction Currency</label>
            <Select
              options={currencyList.filter((value) => Object.keys(value).length !== 0)}
              value={transactionCurrency}
              name='transactionCurrency'
              onChange={(e: any) => {
                setTransactionCurrency(e)
                formik.setFieldValue('transactionCurrency', e.value)
              }}
              styles={selectCustomStyles}
            />

            <div className='text-danger mt-2'>
              {formik.touched.transactionCurrency && formik.errors.transactionCurrency && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.transactionCurrency}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>Investor or Fund</label>
            {!props.isEdit ? (
              <Select
                className=''
                options={investorName}
                name='investorId'
                onChange={(e: any) => formik.setFieldValue('investorId', e.value)}
                styles={selectCustomStyles}
              />
            ) : (
              <InputBox
                isDisabled={true}
                name={'investorName'}
                handleChange={(value: string) => {
                  formik.setFieldValue('investorName', value)
                }}
                setFieldValue={(value: string) => {
                  formik.setFieldValue('investorName', value)
                }}
                currency={transactionCurrency.label}
                value={props.transactionData?.investorName || ''}
                type='text'
              />
            )}
            <div className='text-danger mt-2'>
              {formik.touched.investorId && formik.errors.investorId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.investorId}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4'>
            <label className='form-label required'>Number of Shares</label>

            <InputBox
              isDisabled={false}
              name={'noOfShares'}
              handleChange={(value: number) => {
                formik.setFieldValue('noOfShares', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('noOfShares', value)
              }}
              placeholder='Enter Number of Shares'
              currency={transactionCurrency.label}
              value={formik.values.noOfShares}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.noOfShares && formik.errors.noOfShares && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.noOfShares}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>
              Share Price{' '}
              <span style={{ fontFamily: 'arial' }}>
                ({getSymbolOfCurrency(transactionCurrency.label)})
              </span>
            </label>

            <InputBox
              isDisabled={false}
              name={'sharePrice'}
              handleChange={(value: number) => {
                formik.setFieldValue('sharePrice', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('sharePrice', value)
              }}
              placeholder='Enter Share Price'
              currency={transactionCurrency.label}
              value={formik.values.sharePrice}
              isCurrency={true}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.sharePrice && formik.errors.sharePrice && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.sharePrice}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>Investment Date</label>

            <input
              type='date'
              placeholder='10/10/2010'
              max={maxDate}
              className='form-control custom-input fw-bold shadow-lg h-38px'
              {...formik.getFieldProps('investmentDate')}
            />

            <div className='text-danger mt-2'>
              {formik.touched.investmentDate && formik.errors.investmentDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.investmentDate}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 '>
            <label className='form-label required'>
              Valuation{' '}
              <span style={{ fontFamily: 'arial' }}>
                ({getSymbolOfCurrency(transactionCurrency.label)})
              </span>
            </label>

            <InputBox
              isDisabled={false}
              name={'valuation'}
              handleChange={(value: number) => {
                formik.setFieldValue('valuation', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('valuation', value)
              }}
              currency={transactionCurrency.label}
              placeholder='Enter Valuation'
              value={formik.values.valuation}
              isCurrency={true}
              type='number'
            />
            <div className='text-danger fs-9'>
              {formik.touched.valuation && formik.errors.valuation && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.valuation}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>Round Details</label>

            <InputBox
              isDisabled={false}
              name={'roundDetails'}
              handleChange={(value: string) => {
                formik.setFieldValue('roundDetails', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('roundDetails', value)
              }}
              placeholder={'Enter Round Details'}
              currency={transactionCurrency.label}
              value={formik.values.roundDetails}
              type='text'
            />
            <div className='text-danger mt-1'>
              {formik.touched.roundDetails && formik.errors.roundDetails && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.roundDetails}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>Security Type</label>
            <Select
              options={securitiesList}
              value={security}
              name='security'
              onChange={(e: any) => {
                setSecurity(e)
                formik.setFieldValue('securitySubType', e.value)
              }}
              styles={selectCustomStyles}
            />

            <div className='text-danger mt-2'>
              {formik.touched.securitySubType && formik.errors.securitySubType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.securitySubType}</div>
                </div>
              )}
            </div>
          </div>
          {((formik.values.currency !== companyCurrency && companyCurrency !== undefined) ||
            formik.values.currency !== formik.values.transactionCurrency) && (
            <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
              <label className='form-label required'>
                Exchange Rate{' '}
                <span style={{ fontFamily: 'arial' }}>
                  ({getSymbolOfCurrency(transactionCurrency.label)})
                </span>
              </label>

              <InputBox
                isDisabled={false}
                name={'exchangeRate'}
                handleChange={(value: number) => {
                  formik.setFieldValue('exchangeRate', value)
                }}
                setFieldValue={(value: number) => {
                  formik.setFieldValue('exchangeRate', value)
                }}
                placeholder='Enter Exchange Rate'
                currency={transactionCurrency.label}
                isCurrency={true}
                value={formik.values.exchangeRate}
                type='number'
              />
              {!formik.errors?.exchangeRate && formik.values.exchangeRate && (
                <div className=' flex text-end fs-8 text-gray-500 '>
                  1 {getKeyByValue(currencyTypes, getCurrency(formik.values.transactionCurrency))} ={' '}
                  {formik.values.exchangeRate}{' '}
                  {getKeyByValue(currencyTypes, getCurrency(formik.values.currency))}
                </div>
              )}
              <div className='text-danger mt-2'>
                {formik.touched.exchangeRate && formik.errors.exchangeRate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-7'>{formik.errors.exchangeRate}</div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className='row align-md-items-baseline pb-4 pt-6'>
            <div className='col-6 col-md-6'>
              <label className='form-label'>Do You Want To Add Distinctive Share Numbers?</label>
            </div>
            <div className='col-6 col-md-5 '>
              <div className='form-check form-switch form-check-custom form-check-solid me-10'>
                <input
                  className='form-check-input h-20px w-40px'
                  type='checkbox'
                  {...formik.getFieldProps('isAddShareNumber')}
                />
              </div>
            </div>
          </div>

          {formik.values.isAddShareNumber === true && (
            <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
              <label className='form-label'>Share Distinctive Numbers</label>

              <InputBox
                isDisabled={false}
                name={'shareDistinctiveRange'}
                handleChange={(value: string) => {
                  formik.setFieldValue('shareDistinctiveRange', value)
                }}
                setFieldValue={(value: string) => {
                  formik.setFieldValue('shareDistinctiveRange', value)
                }}
                placeholder={'Enter Share Distinctive Numbers'}
                currency={transactionCurrency.label}
                value={formik.values.shareDistinctiveRange}
                type='text'
              />
            </div>
          )}

          <div className='d-flex justify-content-between pt-10'>
            <button className='btn btn-lg btn-secondary me-3' onClick={props.handleClose}>
              Cancel
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && (props.isEdit ? 'Edit Transaction' : 'Add Transaction')}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}
