import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIsMutating } from 'react-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import { selectCustomStyles } from '../../../utils/Select'
import { graphqlRequestClient, queryClient } from '../../../queries/client'
import { kFormatter, kFormatterRupee } from '../../../utils/CurrencyFormatter'
import { weightageLV } from '../../../types/exitEnabler'
import Toast, { ToastType } from '../../../utils/toast'
import { AddEePortfolioMutation, useAddEePortfolioMutation, useGetEePortfoliosQuery } from '../../../generated/graphql'
import { randomWeights } from './EEPortfolios'

type Props = { companies: any[]; type: string }

const CreateEEPortfolio = ({ companies: comp, type }: Props) => {
  const navigate = useNavigate()
  const [companies, setCompanies] = useState<Array<any>>([])
  const [showCalc, setShowCalc] = useState<boolean>(false)

  const goBack = () => navigate('/exitEnabler/eePortfolios')

  const cpMutate = useAddEePortfolioMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (data: AddEePortfolioMutation) => {
        queryClient.invalidateQueries(useGetEePortfoliosQuery.getKey())
        Toast(data.addEEPortfolio.message, ToastType.success)
        navigate('/exitEnabler/eePortfolios')
      },
      onError: (error: any) => Toast(error.response.errors[0].message, ToastType.error),
    },
    {}
  )

  useEffect(() => {
    if (comp) setCompanies(comp.map((c) => c.companyData || c))
  }, [comp])

  const nullArr: number[] = []

  const cpForm = useFormik({
    initialValues: {
      id: -1,
      expAmount: 100000000,
      minAmount: 10000000,
      numBuckets: 10,
      weightage: '',
      weights: nullArr,
      expPPS: nullArr,
      numShares: nullArr,
      name: '',
    },
    validationSchema: Yup.object().shape({
      expAmount: Yup.number().required('Enter the expected amount'),
      minAmount: Yup.number().required('Enter the minimum amount'),
      weightage: Yup.string().required('Select weightage'),
      name: Yup.string().required('Give your portfolio a name').min(3, 'Name must be at least 3 characters'),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: function (values) {
      const cpData = {
        name: values.name,
        type: type,
        expAmount: values.expAmount,
        minAmount: values.minAmount,
        numBuckets: values.numBuckets,
        companies: companies.map((c: any, i) => {
          const cd = {
            id: c.id,
            name: c.name,
            cin: c.cin,
            sharePrice: c.sharePrice,
            valuation: c.valuation,
            numShares: values.numShares && values.numShares[i],
            weight: values.weights && values.weights[i],
            expPPS: values.expPPS && values.expPPS[i],
          }
          return cd
        }),
      }
      if (Number(values.weights.reduce((s, c) => s + c, 0).toFixed(2)) !== 100) Toast('Total weightage must be exactly 100', ToastType.error)
      else {
        console.log('~  CreateEEPortfolio  cpData:', cpData)
        cpMutate.mutate({ input: cpData })
      }
    },
  })

  const handleWeight = (w: number, i: number) => {
    let oW = cpForm.values.weights
    oW[i] = w
    cpForm.setFieldValue('weights', oW)

    let oNS = cpForm.values.numShares
    const noSh = cpForm.values.expPPS[i] > 0 ? (cpForm.values.minAmount * (w / 100)) / cpForm.values.expPPS[i] : 0
    oNS[i] = Math.round(noSh)
    cpForm.setFieldValue('numShares', oNS)
  }

  const handleExpPPS = (sp: number, i: number) => {
    let oSP = cpForm.values.expPPS
    oSP[i] = sp
    cpForm.setFieldValue('expPPS', oSP)

    let oNS = cpForm.values.numShares
    const noSh = sp > 0 ? (cpForm.values.minAmount * (cpForm.values.weights[i] / 100)) / sp : 0
    oNS[i] = Math.round(noSh)
    cpForm.setFieldValue('numShares', oNS)
  }

  const handleExpAmount = (v: any) => {
    setShowCalc(false)
    const val = parseFloat(v.replaceAll(',', '')) || 0
    cpForm.setFieldValue('expAmount', val)
    cpForm.setFieldValue(
      'numBuckets',
      val % cpForm.values.minAmount > 0 ? Math.floor(val / cpForm.values.minAmount) + 1 : Math.floor(val / cpForm.values.minAmount)
    )
  }

  const handleMinAmount = (v: any) => {
    setShowCalc(false)
    const val = parseFloat(v.replaceAll(',', '')) || 0
    cpForm.setFieldValue('minAmount', val)
    cpForm.setFieldValue(
      'numBuckets',
      cpForm.values.expAmount % val > 0 ? Math.floor(cpForm.values.expAmount / val) + 1 : Math.floor(cpForm.values.expAmount / val)
    )
  }

  const handleWeightage = (v: any) => {
    setShowCalc(false)
    cpForm.setFieldValue('weightage', v)
    cpForm.setFieldValue(
      'weights',
      v === 'equal' ? Array(companies.length).fill(Number((100 / companies.length).toFixed(2))) : randomWeights(companies.length)
    )
    cpForm.setFieldValue(
      'expPPS',
      companies.map((c) => c.sharePrice)
    )
  }

  const calcBuckets = () => {
    if (cpForm.values.weightage) {
      let oNS = cpForm.values.numShares
      companies.map((c, i) => {
        const noSh = cpForm.values.expPPS[i] > 0 ? (cpForm.values.minAmount * (cpForm.values.weights[i] / 100)) / cpForm.values.expPPS[i] : 0
        oNS[i] = Math.round(noSh)
      })
      cpForm.setFieldValue('numShares', oNS)
      setShowCalc(true)
    } else cpForm.setTouched({ ...cpForm.touched, weightage: true })
  }

  return (
    <div className='row'>
      <form onSubmit={cpForm.handleSubmit}>
        <div className='card mb-5'>
          <div className='card-body'>
            <div className='d-flex align-items-center mb-2'>
              <p className='text-gray-800 text-hover-primary fs-2 me-1 mb-0'>Create Portfolio</p>
            </div>
            <div className='fs-6 pe-2'>
              <p className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-5'>
                This is a invite only special module to empower you to create an exit portfolios from your investments
              </p>
              {!companies || companies.length === 0 ? (
                <p className='d-flex align-items-center mb-1'>
                  First, filter companies from your investments under 'Model My Exit' or your watchlist companies under 'Model Watchlist Exit'
                  <br />
                  Then click 'Create Portfolio' to create your first portfolio for sale
                </p>
              ) : (
                <div className='row fv-row'>
                  <div className='col-3 px-2'>
                    <label className='form-label required'>Expected Amount</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter the expected amount'
                      {...cpForm.getFieldProps('expAmount')}
                      title={kFormatter(cpForm.values.expAmount)}
                      onChange={(e) => handleExpAmount(e.target.value)}
                      value={Intl.NumberFormat('en-IN').format(cpForm.values.expAmount)}
                    />
                    <span className='d-flex flex-end text-muted pe-5'>{kFormatter(cpForm.values.expAmount)}</span>
                    <div className='text-danger mt-2'>
                      {cpForm.touched.expAmount && cpForm.errors.expAmount && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{cpForm.errors.expAmount}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-3 px-2'>
                    <label className='form-label required'>Minimum Amount</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter the min amount'
                      {...cpForm.getFieldProps('minAmount')}
                      title={kFormatter(cpForm.values.minAmount)}
                      onChange={(e) => handleMinAmount(e.target.value)}
                      value={Intl.NumberFormat('en-IN').format(cpForm.values.minAmount)}
                    />
                    <span className='d-flex flex-end text-muted pe-5'>{kFormatter(cpForm.values.minAmount)}</span>
                    <div className='text-danger mt-2'>
                      {cpForm.touched.minAmount && cpForm.errors.minAmount && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{cpForm.errors.minAmount}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-3 px-2'>
                    <label className='form-label required'>Number of Buckets</label>
                    <input type='number' className='form-control' disabled {...cpForm.getFieldProps('numBuckets')} />
                    {cpForm.values.expAmount % cpForm.values.minAmount > 0 && (
                      <span className='d-flex flex-end text-muted pe-5'>
                        Last bucket: {kFormatter(cpForm.values.expAmount % cpForm.values.minAmount)}
                      </span>
                    )}
                    <div className='text-danger mt-2'>
                      {cpForm.touched.numBuckets && cpForm.errors.numBuckets && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{cpForm.errors.numBuckets}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-3 px-2'>
                    <label className='form-label required'>Weightage</label>
                    <Select
                      className='text-primary'
                      options={weightageLV}
                      name='weightage'
                      onChange={(e: any) => handleWeightage(e.value)}
                      styles={selectCustomStyles}
                    />
                    <span className='d-flex flex-end text-muted fs-8'>You can alter weightage in the next step</span>
                    <div className='text-danger mt-2'>
                      {cpForm.touched.weightage && cpForm.errors.weightage && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{cpForm.errors.weightage}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='col-4' />
                  <div className='col-4 d-flex flex-column justify-content-center align-items-center'>
                    <button type='button' className='btn btn-primary' onClick={() => calcBuckets()}>
                      Calculate
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='card p-0'>
          {companies && (
            <>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fs-3 mb-1'>Filtered Companies</span>
                  <span className='mt-1 fw-semibold fs-7 text-muted'>{companies.length} companies</span>
                </h3>
              </div>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table align-middle table-row-dashed dataTable no-footer'>
                    <thead>
                      <tr key='nofilter'>
                        <th className='min-w-300px'>
                          Company Name
                          <span className='text-muted d-block fs-7'>Business Name</span>
                        </th>
                        <th className='text-end'>
                          Valuation (<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                        </th>
                        {showCalc && (
                          <>
                            <th className='text-end'>Shares (#)</th>
                            <th className='text-end'>
                              Amount (<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                            </th>
                            <th className='text-end'>Weightage (%)</th>
                            <th className='text-end'>
                              Expected Share Price (<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                            </th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {companies.map((comp: any, i: any) => {
                        return (
                          <tr key={i}>
                            <td>
                              <p className='mb-1'>{comp.name}</p>
                              <span className='text-muted d-block fs-7'>{comp.DBA}</span>
                            </td>
                            <td className='text-end'>
                              <p className='mb-1'>{kFormatter(comp.valuation)}</p>
                              <span className='text-muted d-block fs-7'>
                                {comp.valuation > 999 && kFormatterRupee(Number(comp.valuation.toString().split('.')[0]))}
                              </span>
                            </td>
                            {showCalc && cpForm.values && (
                              <>
                                <td className='text-end'>
                                  <p className='mb-1'>{kFormatter(cpForm.values.numShares[i])}</p>
                                  <span className='text-muted d-block fs-7'>
                                    {cpForm.values.numShares[i] > 999 && kFormatterRupee(Number(cpForm.values.numShares[i]))}
                                  </span>
                                </td>
                                <td className='text-end'>
                                  <p className='mb-1'>{kFormatter(cpForm.values.expPPS[i] * cpForm.values.numShares[i])}</p>
                                  <span className='text-muted d-block fs-7'>
                                    {cpForm.values.expPPS[i] * cpForm.values.numShares[i] > 999 &&
                                      kFormatterRupee(Number((cpForm.values.expPPS[i] * cpForm.values.numShares[i]).toString().split('.')[0]))}
                                  </span>
                                </td>
                                <td className='text-end'>
                                  <input
                                    type='number'
                                    className='form-control text-end'
                                    onChange={(e) => handleWeight(Number(e.target.value), i)}
                                    value={cpForm.values.weights && cpForm.values.weights[i]}
                                  />
                                  {cpForm.values.weights && cpForm.values.weights[i] < 0 && (
                                    <div className='text-danger mt-2'>
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>Negative is invalid</div>
                                      </div>
                                    </div>
                                  )}
                                </td>
                                <td className='text-end'>
                                  <input
                                    type='text'
                                    className='form-control text-end'
                                    title={kFormatter(cpForm.values.expPPS[i])}
                                    onChange={(e) => handleExpPPS(Number(e.target.value.replaceAll(',', '')), i)}
                                    value={cpForm.values.expPPS && kFormatterRupee(Number(cpForm.values.expPPS[i].toString().split('.')[0]))}
                                  />
                                  {cpForm.values.expPPS && cpForm.values.expPPS[i] <= 0 && (
                                    <div className='text-danger mt-2'>
                                      <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>Invalid share price</div>
                                      </div>
                                    </div>
                                  )}
                                </td>
                              </>
                            )}
                          </tr>
                        )
                      })}
                      {showCalc && (
                        <tr key='totals'>
                          <td colSpan={3} className='text-end'>
                            <p className='mb-1'>
                              Total Amount per Bucket (<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                            </p>
                          </td>
                          <td className='text-end'>
                            <p className='mb-1'>
                              {kFormatter(companies.reduce((s, c, i) => s + cpForm.values.expPPS[i] * cpForm.values.numShares[i], 0))}
                            </p>
                            <span className='text-muted d-block fs-7'>
                              {kFormatterRupee(
                                Number(
                                  companies
                                    .reduce((s, c, i) => s + cpForm.values.expPPS[i] * cpForm.values.numShares[i], 0)
                                    .toString()
                                    .split('.')[0]
                                )
                              )}
                            </span>
                          </td>
                          <td className='text-end'>
                            <p className='mb-1'>{cpForm.values.weights.reduce((s, c) => s + c, 0).toFixed(2)}</p>
                            {Number(cpForm.values.weights.reduce((s, c) => s + c, 0).toFixed(2)) !== 100 && (
                              <span className='text-danger d-block fs-7'>Total must be 100.00</span>
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          <hr className='m-0 border-gray-500'></hr>
          <div className='p-5 d-flex flex-row justify-content-between align-items-end'>
            <div className='px-2'>
              <button type='button' className='btn btn-sm btn-secondary me-3' onClick={() => goBack()}>
                Cancel
              </button>
            </div>
            <div className='d-flex flex-row justify-content-end align-items-end'>
              <label className='form-label required mb-5'>Portfolio Name</label>
              <div className='px-2 w-250px'>
                <input type='text' className='form-control me-5' id='name' placeholder='Enter portfolio name' {...cpForm.getFieldProps('name')} />
                {cpForm.touched.name && cpForm.errors.name && (
                  <div className='text-danger mt-2'>
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{cpForm.errors.name}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className='px-2'>
                <button type='submit' className='btn btn-primary' disabled={useIsMutating() > 0 || !showCalc}>
                  {useIsMutating() === 0 && 'Create Portfolio'}
                  {useIsMutating() > 0 && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export { CreateEEPortfolio }
