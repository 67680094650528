import { formatNumber } from '../../../../utils/CurrencyFormatter'

export function ShareholderNameRender(data: any) {
  return (
    <div className='pt-2'>
      <p className='fw-bold'> {data.data.shareholder}</p>
    </div>
  )
}

export function ShareholderTypeRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.shareholderType}</p>
    </div>
  )
}

export function PercentageRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.percentage * 100,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function SharesRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.noOfShares,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function AmountInvestedRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.amountInvested,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
