import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG'
import AddHissaValuationDetailsForm from './AddHissaValuationDetailsForm'
import { CompanyObject } from '../../../../../generated/graphql'

type ValuationDetailProps = {
  handleClose: () => void
  showModal: boolean
  companyDetails: CompanyObject
}

function AddHissaValuationDetailModal(props: ValuationDetailProps) {
  return (
    <Modal
      onHide={props.handleClose}
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px'
      show={props.showModal}
    >
      <div className='col d-flex justify-content-between px-9 pt-6'>
        <div className='pt-4'>
          <h2 className='card-header fw-bolder text-dark'>{' Add Valuation'}</h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={props.handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>

      <Modal.Body className='p-0'>
        <div className='card card-p-0 '>
          <div className='card-body'>
            <div className='stepper stepper-links d-flex flex-column' id='kt_modal_add_inv_stepper'>
              <div className='d-flex row m-9 '>
                <AddHissaValuationDetailsForm
                  closeModal={props.handleClose}
                  companyDetails={props.companyDetails}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default AddHissaValuationDetailModal
