import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../_metronic/helpers'
import TemplateDocumentEditor from './TemplateDocumentEditor'

export function TemplateActionRender(data: any) {
  const [showTagsModal, setShowTagsModal] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [data.data])

  const setShowModalFunction = (state: boolean) => setShowTagsModal(state)
  const editOptions = ['Edit']
  const actionMenu = () => {
    let newOptions = editOptions
    return newOptions.map((option) => {
      return (
        <label
          key={option}
          className='form-check-custom py-3 bg-hover-primary text-hover-white'
          onClick={() => {
            setShowTagsModal(true)
          }}
        >
          <span className='form-check-label fs-base ms-6 fw-normal cursor-pointer'>{option}</span>
        </label>
      )
    })
  }

  return (
    <div className='px-0'>
      <button
        type='button'
        className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
      >
        <KTSVG
          path='/media/icons/duotune/general/gen052.svg'
          className='svg-icon-muted svg-icon-2x'
        />
      </button>
      {showTagsModal && (
        <TemplateDocumentEditor
          templateData={data.data}
          showModel={showTagsModal}
          handleClose={() => {
            setShowTagsModal(false)
          }}
        />
      )}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-100px py-3'
        data-kt-menu='true'
      >
        {actionMenu()}
      </div>
    </div>
  )
}
