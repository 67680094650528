import { useRef, useState } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import {
  RemoveWatchlistMutation,
  RemoveWatchlistMutationVariables,
  useGetNewWatchlistsQuery,
  useRemoveWatchlistMutation,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { AddCompany } from './AddCompany'

type DeletewatchlistProps = {
  watchlistId: number
  watchlistName: string
}
export function EmptyWatchlistCard(props: DeletewatchlistProps) {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)

  const mutateDeleteWatchlist = useRemoveWatchlistMutation<RemoveWatchlistMutation, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  function handleRemoveWatchlist() {
    setLoading(true)
    mutateDeleteWatchlist.mutate(
      {
        input: props.watchlistId,
      },
      {
        onSuccess: (
          data: RemoveWatchlistMutation,
          _variables: RemoveWatchlistMutationVariables,
          context: unknown
        ) => {
          queryClient.invalidateQueries(useGetNewWatchlistsQuery.getKey())
          Toast('Watchlist Deleted Successfully!', ToastType.success)
          setLoading(false)
        },
        onError: (error: any) => {
          setLoading(false)
          Toast(error.response.errors[0].message, ToastType.error)
        },
      }
    )
  }

  return (
    <div
      className='card p-0 h-575px'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='card-body'>
        <div className='row justify-content-center  text-center py-8 m-6'>
          <img
            alt='no items'
            src={toAbsoluteUrl('/media/icons/duotune/general/empty-watchlist.svg')}
            className='h-250px my-10 w-350 justify-content-center'
          />
          <h1>No companies added to this watchlist</h1>
          <div className='col justify-content-between pt-4'>
            <button
              className='btn btn-secondary mt-4 mx-8'
              ref={deleteBtnRef}
              onClick={handleRemoveWatchlist}
              id='kt_button_1'
              disabled={loading}
            >
              {!loading && <span className='text-gray-600 fw-bold'>Delete Watchlist</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <button
              className='btn btn-primary mt-4 mx-8'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
              onClick={() => {
                setShowModal(!showModal)
              }}
            >
              Add Company +
            </button>
            {showModal && (
              <AddCompany
                showModal={showModal}
                handleClose={() => setShowModal(false)}
                watchlistId={props.watchlistId}
                watchlistName={props.watchlistName}
                existingCompanies={[]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
