import { AgGridReact } from 'ag-grid-react'
import { agConfigSideBar, tableCellStyle } from '../../../../utils/AgGridConfig'
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CellClickedEvent, ColDef } from 'ag-grid-community'

import {
  AllTransactionsType,
  CompanyObject,
  Transaction_Type,
  WatchlistObject,
} from '../../../../generated/graphql'
import { KTSVG } from '../../../../_metronic/helpers'
import { GlobalContext } from '../../store/context/globalContext'
import { getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import {
  ActionRender,
  CompanyNameRender,
  EsopHoldingRender,
  FounderHoldingRender,
  LatestRaiseRender,
  TotalRaisedRender,
  TotalRoundsRoundRender,
  ValuationRender,
} from './WatchlistAGComponent'
import { AddCompany } from './AddCompany'

type WatchlistTableProps = {
  watchlistCompanies: AllTransactionsType[]
  watchlistId: number
  watchlistName: string
  starredCompanies: string[]
}
export function WatchlistAGTable(props: WatchlistTableProps) {
  const gridApi = useRef<any>(null)
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const [showModal, setShowModal] = useState<boolean>(false)
  const userCurrencyFormat = getCurrency(userCurrency)

  const existingCompanies: string[] = props.watchlistCompanies.map(
    (company) => company.companyData.hissaCompanyId || ''
  ) as string[]

  const componentsRegistry = useMemo(
    () => ({
      companyName: memo(CompanyNameRender),
      valuation: memo(ValuationRender),
      noOfRound: memo(TotalRoundsRoundRender),
      latestRaise: memo(LatestRaiseRender),
      totalRaise: memo(TotalRaisedRender),
      esopHolding: memo(EsopHoldingRender),
      founderHolding: memo(FounderHoldingRender),
    }),
    []
  )

  props.watchlistCompanies.map((company) => {})

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  function getColumnSetting() {
    if (gridApi.current) return gridApi.current.columnApi?.getColumnState()
    return {}
  }

  function setColumnSetting(model: any) {
    if (gridApi.current) gridApi.current.columnApi.applyColumnState({ state: model })
    return {}
  }

  // Gets filter model via the grid API
  const getFilterSetting = () => {
    if (gridApi.current) return gridApi.current.api?.getFilterModel()
    return {}
  }

  const setFilterSetting = (model: any) => {
    if (gridApi.current) return gridApi.current.api?.setFilterModel(model)
    return {}
  }

  const onClickTransactionName = (id: string) => null
  // ? cin
  //   ? navigate(`/inv/transaction/${transactionType}/${id}/${cin}`)
  //   : navigate(`/transactions/${transactionType}/${id}`)
  // : null;

  const handleCellClick = (cellParams: CellClickedEvent<any, any>) => {
    if (cellParams.column.getColId() !== 'action-column') {
      const template = cellParams.data
      if (cellParams.column.getColId() === 'transactionName') {
        onClickTransactionName(template.id)
      }
    }
  }

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const uploadFilterAndColumn = async () => {
    const columnState = await getColumnSetting()
    const filterState = await getFilterSetting()
    // postOnFilter({
    //   userId: `${userId}`,
    //   companyId,
    //   filterData: {
    //     ...agTableStore.state,
    //     transactionColumnModel: columnState,
    //     transactionFilterModel: filterState,
    //   },
    // });
  }

  const [filteredRowData, setFilteredRowData] = useState<any>([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const onAgGridFilterChanged = (grid: any) => {
    const filtersApplied = grid.api.isAnyFilterPresent()
    setIsFilterApplied(filtersApplied)

    const filteredData = grid.api.getModel().rowsToDisplay.map((node: any) => node.data)
    setFilteredRowData(filteredData)
    // setPinnedBottomRowData(grid);
    uploadFilterAndColumn()
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Company Name',
        field: 'companyName',
        cellRenderer: 'companyName',
        cellStyle: tableCellStyle,
        initialWidth: 200,
        minWidth: 350,
        sortable: true,
        suppressSizeToFit: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipTransactionName',
      },
      {
        headerName: `Valuation (${getSymbolOfCurrency(userCurrency)})`,
        field: 'valuation',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'valuation',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipValuation',
      },
      {
        headerName: 'No. of Rounds',
        field: 'noOfRound',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'noOfRound',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: `Latest Raise (${getSymbolOfCurrency(userCurrency)})`,
        field: 'latestRaise',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'latestRaise',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipLatestRaise',
      },
      {
        headerName: `Total Raised (${getSymbolOfCurrency(userCurrency)})`,
        field: 'totalRaise',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'totalRaise',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipTotalRaised',
      },
      {
        headerName: `ESOP Holding (%)`,
        field: 'esopHolding',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'esopHolding',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipEsopHolding',
      },
      {
        headerName: `Founder Holding (%)`,
        field: 'founderHolding',
        filter: 'agNumberColumnFilter',
        cellRenderer: 'founderHolding',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipFounderHolding',
      },
      {
        headerName: '',
        field: 'actions',
        hide: false,
        width: 90,
        pinned: 'right',
        maxWidth: 90,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        // cellStyle: { 'padding-top': '10px', 'line-height': '20px' },
        resizable: true,
        sortable: false,
        // cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: ActionRender,
      },
    ],
    [userCurrencyFormat]
  )

  const rowData = useMemo(
    () =>
      props.watchlistCompanies?.map((company) => {
        return {
          companyId: company.companyData.hissaCompanyId,
          companyName: company.companyData.name,
          valuation: company.companyData.valuation,
          tooltipValuation: company.companyData.valuation?.toLocaleString(userCurrencyFormat),
          esopHolding: company.companyData.esopHolding ? company.companyData.esopHolding * 100 : 0,
          tooltipEsopHolding: (company.companyData.esopHolding
            ? company.companyData.esopHolding * 100
            : 0
          )?.toLocaleString(userCurrencyFormat),
          founderHolding: company.companyData.founderHoldingPercentage
            ? company.companyData.founderHoldingPercentage * 100
            : 0,
          tooltipFounderHolding: (company.companyData.founderHoldingPercentage
            ? company.companyData.founderHoldingPercentage * 100
            : 0
          )?.toLocaleString(userCurrencyFormat),
          noOfRound: company.companyData.noOfRounds,
          latestRaise: company.companyData.latestRaise,
          tooltipLatestRaise: company.companyData.latestRaise?.toLocaleString(userCurrencyFormat),
          totalRaise: company.companyData.totalFunding,
          tooltipTotalRaised: company.companyData.totalFunding?.toLocaleString(userCurrencyFormat),
          dba: company.companyData.dba,
          userCurrencyFormat: userCurrencyFormat,
          userCurrency: userCurrency,
          watchlistId: props.watchlistId,
          starredCompanies: props.starredCompanies,
        }
      }),
    [props.watchlistCompanies, userCurrencyFormat, props.starredCompanies]
  )

  return (
    <div
      className='card p-0 h-575px'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          <h1>Watchlist</h1>
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <button
            type='submit'
            onClick={() => setShowModal(!showModal)}
            className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal ms-4 text-white'
          >
            <KTSVG
              path='media/icons/duotune/general/gen041.svg'
              className='svg-icon-3 text-white'
            />{' '}
            Add Company
          </button>
          {showModal && (
            <AddCompany
              showModal={showModal}
              handleClose={() => setShowModal(false)}
              watchlistId={props.watchlistId}
              watchlistName={props.watchlistName}
              existingCompanies={existingCompanies}
            />
          )}
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          onFilterChanged={onAgGridFilterChanged}
          onColumnEverythingChanged={uploadFilterAndColumn}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={true}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          suppressMenuHide={false}
          onColumnResized={uploadFilterAndColumn}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
