import { Link } from 'react-router-dom'
import { formatNumber } from '../../../../utils/CurrencyFormatter'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { getInitials } from '../../../../utils/Utils'
import {
  DeletePrivateCaptableMutation,
  DeletePrivateCaptableMutationVariables,
  GetUrlForS3ObjectQuery,
  RemoveCompanyMutation,
  RemoveCompanyMutationVariables,
  StageCompanyType,
  useAddOrRemoveStarredCompaniesMutation,
  useDeletePrivateCaptableMutation,
  useGetAllPrivateCaptablesQuery,
  useGetNewWatchlistsQuery,
  useGetUrlForS3ObjectQuery,
  useRemoveCompanyMutation,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useEffect, useRef, useState } from 'react'
import { hexToRGB } from '../../../../utils/HexToRgba'
import { Modal } from 'react-bootstrap'

export function CompanyNameRender(data: any) {
  return (
    <div className='pb-2'>
      {data.data.companyId ? (
        <div className='d-flex flex-row'>
          <Link
            className='fs-base fw-bold mb-0 text-gray-800 text-hover-primary pt-1'
            to={`/company/summary/HISSA/${data.data.companyId}`}
          >
            <h6
              className=' m-0 fw-bold text-hover-primary'
              data-toggle='tooltip'
              data-placement='top'
              title={data.data.companyName}
            >
              {data.data.companyType === StageCompanyType.Stage_4 ? (
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/general/stage-4.svg')}
                  alt=''
                  className={`fs-4 fw-bold p-0 m-0 me-5 text-truncate cursor-pointer border rounded-circle border-gray-400 hover-effect`}
                  style={{ maxWidth: '40px', maxHeight: '40px' }}
                />
              ) : (
                <img
                  src={toAbsoluteUrl('/media/icons/duotune/general/stage-3.svg')}
                  alt=''
                  className={`fs-4 fw-bold p-0 m-0 me-5 text-truncate cursor-pointer border rounded-circle border-gray-400 hover-effect`}
                  style={{ maxWidth: '40px', maxHeight: '40px' }}
                />
              )}
              {data.data.companyName}
            </h6>
          </Link>
          <span className='text-muted'>{data.data.dba}</span>
        </div>
      ) : (
        <p className='fw-bold'> {data.data.companyName}</p>
      )}
    </div>
  )
}

export function CinRender(data: any) {
  return (
    <div className='py-3'>
      <p className='m-0'>{data.data.cin}</p>
    </div>
  )
}

export function ReportFileRender(data: any) {
  const [state, setState] = useState(false)
  const [bucket, setBucket] = useState<any>()
  const [key, setKey] = useState('')

  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    { input: { key, bucket: bucket } },
    {
      enabled: state,
    }
  )

  if (query.isFetched && state === true) {
    window.open(query.data?.urlForS3Object || '')
    setState(false)
  }

  function handleClick(item: any) {
    setBucket(process.env.REACT_APP_S3_INVESTOR_BUCKET || '')
    setKey(item.file)
    setState(true)
  }
  const fileName = data.data.file.split('/')
  return (
    <div className='pt-2'>
      <div
        className='d-flex align-items-center'
        onClick={() => {
          data.data.file !== '' && handleClick(data.data)
        }}
      >
        <div className='symbol symbol-35px me-5' style={{ cursor: 'pointer' }}>
          {data.data.file !== '' && (
            <img src={toAbsoluteUrl('/media/svg/files/excel.svg')} alt='' />
          )}
        </div>
        <span className='text-wrap fs-base cursor-pointer'>
          <p
            className='text-truncate mb-0'
            data-toggle='tooltip'
            data-placement='top'
            title={fileName[fileName.length - 1]}
            style={{ maxWidth: '190px' }}
          >
            {fileName[fileName.length - 1]}
          </p>
        </span>
      </div>
    </div>
  )
}

export function CompanyTypeRender(data: any) {
  return (
    <div className='py-2'>
      {data.data.companyType === StageCompanyType.Stage_4 ? (
        <span
          className={`badge fs-9 fw-semibold mb-1 me-2 `}
          style={{
            background: hexToRGB(getCSSVariableValue('--kt-donut-' + (0 % 10)), 0.2),
            color: hexToRGB(getCSSVariableValue('--kt-donut-' + (0 % 10)), 1),
          }}
        >
          {'Full Captable'}
        </span>
      ) : (
        <span
          className={`badge fs-9 fw-semibold mb-1 me-2 `}
          style={{
            background: hexToRGB(getCSSVariableValue('--kt-donut-' + (4 % 10)), 0.2),
            color: hexToRGB(getCSSVariableValue('--kt-donut-' + (4 % 10)), 1),
          }}
        >
          {'Current Captable'}
        </span>
      )}
    </div>
  )
}

export function ActionRender(data: any) {
  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const mutateRemovePrivateCaptable = useDeletePrivateCaptableMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: DeletePrivateCaptableMutation,
        _variables: DeletePrivateCaptableMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetAllPrivateCaptablesQuery.getKey())
        Toast('Captable Removed Successfully', ToastType.success)
      },
    },
    {}
  )

  function handleRemoveCompany(e: React.MouseEvent, companyId: string) {
    e.stopPropagation()
    // Disable indicator after 3 seconds
    deleteBtnRef.current?.setAttribute('data-kt-indicator', 'on')
    setTimeout(() => {
      deleteBtnRef.current?.removeAttribute('data-kt-indicator')
    }, 3000)
    e.preventDefault()
    mutateRemovePrivateCaptable.mutate({ input: { hissaCompanyId: companyId } })
  }

  return (
    <div className='px-0'>
      <button
        ref={deleteBtnRef}
        className='btn align-center btn-icon btn-sm btn-secondary'
        onClick={() => {
          setShowDeleteModel(true)
        }}
      >
        {mutateRemovePrivateCaptable.isLoading ? (
          <span className='indicator-progress  '>
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <KTSVG
            path='media/icons/duotune/general/gen027.svg'
            className='svg-icon-2x   text-primary'
          />
        )}
      </button>
      {showDeleteModel && (
        <Modal
          id='kt_modal_add_inv'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-500px '
          show={showDeleteModel}
          onHide={() => setShowDeleteModel(false)}
        >
          <Modal.Body className='p-9'>
            <div className='card'>
              <div className='card-body p-0'>
                <h2 className='fw-bolder text-gray-heading pb-4'>Delete Cap table</h2>
                <div className='fs-5 pb-6'>Are you sure you want to remove this cap table?</div>
                <div className='fs-7 fw-normal pb-6'>
                  <b>Note : </b> This remove action will also remove the company from the Hissa
                  platform.
                </div>
                <div className='d-flex col justify-content-between pt-6'>
                  <button
                    className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
                    onClick={() => setShowDeleteModel(false)}
                  >
                    <span className='indicator-label'>Cancel</span>
                  </button>
                  <button
                    type='submit'
                    className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
                    disabled={loading}
                    onClick={(e) => {
                      setLoading(true)
                      handleRemoveCompany(e, data.data.companyId)
                    }}
                  >
                    {!loading && 'Delete'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}
