import {
  useAddAccountToUserMutation,
  useAllAccountOfUserQuery,
  useGetAllInvestmentsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { useFormik } from 'formik'
import Toast, { ToastType } from '../../../../utils/toast'
import { AddAccountSchema } from '../AccountHelper'
import { getAuth, JwtPayload } from '../../../../app/modules/auth'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router'
import { useState } from 'react'

const initialValues = {
  accountName: '',
}
function AddAccount() {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const mutateAddAccount = useAddAccountToUserMutation<Error>(graphqlRequestClient, {}, {})
  const auth = getAuth()!
  const jwt = jwtDecode<JwtPayload>(auth)
  const formik = useFormik({
    initialValues,
    validationSchema: AddAccountSchema,
    onSubmit: async (values) => {
      setLoading(true)
      mutateAddAccount.mutate(
        {
          input: {
            name: values.accountName.trim(),
            userEmailId: jwt.emailId!,
            isAdmin: true,
          },
        },
        {
          onSuccess: (result) => {
            setLoading(false)
            queryClient.invalidateQueries(useAllAccountOfUserQuery.getKey())
            queryClient.invalidateQueries(useGetAllInvestmentsQuery.getKey())
            Toast('Account created Successfully', ToastType.success)
            navigate('/addInvestor')
          },
          onError: (error: any) => {
            setLoading(false)
            Toast(`${error.response.errors[0].message}`, ToastType.error)
          },
        }
      )
    },
  })

  return (
    <div className='col d-flex justify-content-center'>
      <form className='card px-5 pt-5 w-50' onSubmit={formik.handleSubmit}>
        <div className='pb-5 pt-5'>
          <h3 className='required'>Account Name : </h3>
          <input
            className='form-control form-control-lg'
            type='text'
            autoComplete='off'
            placeholder='Enter Account Name'
            {...formik.getFieldProps('accountName')}
          />
          {formik.errors.accountName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.accountName}</div>
            </div>
          )}
        </div>
        <div className='px-5 py-3 text-center'>
          <button className='btn btn-lg btn-primary' type='submit'>
            {!loading && <span className='indicator-label fw-bold'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export default AddAccount
