import { Col, Modal, Row } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { kFormatter, kFormatterRupee } from '../../../utils/CurrencyFormatter'
import { getStatusColor } from './EEPortfolios'

type Props = {
  showViewModal: boolean
  toggleViewModal: () => void
  portfolio: any
}

export function PortfolioView({ portfolio: port }: any) {
  return (
    <div className='card mh-450px'>
      <div className='card-body card-scroll'>
        <Row className=''>
          <Col>
            <p className='text-muted fs-7 mb-0'>Name</p>
            <span>{port.name}</span>
          </Col>
          <Col className='text-end'>
            <p className='text-muted fs-7 mb-0'>Expected Amount</p>
            <span>{kFormatter(port.expAmount)}</span>
          </Col>
          <Col className='text-end'>
            <p className='text-muted fs-7 mb-0'>Min Amount</p>
            <span>{kFormatter(port.minAmount)}</span>
          </Col>
          <Col className='text-end'>
            <p className='text-muted fs-7 mb-0'># Buckets</p>
            <span>{port.numBuckets}</span>
          </Col>
          <Col className='text-end'>
            <p className='text-muted fs-7 mb-0'>Status</p>
            <span className={`fw-semibold badge ${getStatusColor(port.status)}`}>{port.status}</span>
          </Col>
        </Row>
        <hr className='mt-5 mb-0 border-gray-500 mx-n5'></hr>
        <div className='table-responsive'>
          <table className='table align-middle table-row-dashed dataTable no-footer'>
            <thead>
              <tr key='nofilter'>
                <th className='text-muted fs-7'>Company</th>
                <th className='text-muted fs-7 text-end'>Shares (#)</th>
                <th className='text-muted fs-7 text-end'>Weightage (%)</th>
                <th className='text-muted fs-7 text-end'>
                  Expected Share Price (<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                </th>
                <th className='text-muted fs-7 mb-0 text-end'>
                  Amount (<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                </th>
              </tr>
            </thead>
            <tbody>
              {port.companies.map((comp: any, i: any) => {
                return (
                  <tr key={i}>
                    <td>
                      <p className='mb-1'>{comp.name}</p>
                    </td>
                    <td className='text-end'>
                      <p className='mb-1'>{kFormatter(comp.numShares)}</p>
                      <span className='text-muted d-block fs-7'>{comp.numShares > 999 && kFormatterRupee(Number(comp.numShares))}</span>
                    </td>
                    <td className='text-end'>
                      <p className='mb-1'>{comp.weight}</p>
                    </td>
                    <td className='text-end'>
                      <p className='mb-1'>{kFormatter(comp.expPPS)}</p>
                      <span className='text-muted d-block fs-7'>{comp.expPPS > 999 && kFormatterRupee(Number(comp.expPPS))}</span>
                    </td>
                    <td className='text-end'>
                      <p className='mb-1'>{kFormatter(comp.expPPS * comp.numShares)}</p>
                      <span className='text-muted d-block fs-7'>
                        {comp.expPPS * comp.numShares > 999 && kFormatterRupee(Number((comp.expPPS * comp.numShares).toString().split('.')[0]))}
                      </span>
                    </td>
                  </tr>
                )
              })}
              <tr key='totals'>
                <td colSpan={4} className='text-end'>
                  <p className='mb-1'>
                    Total Amount per Bucket (<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                  </p>
                </td>
                <td className='text-end'>
                  <p className='mb-1'>{kFormatter(port.companies.reduce((s: number, c: any) => s + c.expPPS * c.numShares, 0))}</p>
                  <span className='text-muted d-block fs-7'>
                    {kFormatterRupee(
                      Number(
                        port.companies
                          .reduce((s: number, c: any) => s + c.expPPS * c.numShares, 0)
                          .toString()
                          .split('.')[0]
                      )
                    )}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function ViewEEPortfolio({ showViewModal, toggleViewModal, portfolio: port }: Props) {
  return (
    <Modal
      id='kt_modal_view_portfolio'
      aria-hidden='true'
      show={showViewModal}
      onHide={toggleViewModal}
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
    >
      <Modal.Header className='p-2'>
        <Modal.Title className='ps-5'>View Portfolio</Modal.Title>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={toggleViewModal}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </Modal.Header>
      <Modal.Body className='p-0'>
        <PortfolioView portfolio={port} />
      </Modal.Body>
      <Modal.Footer className='p-2'>
        <div className='d-flex justify-content-between'>
          <button type='button' className='btn btn-sm btn-secondary me-3' onClick={toggleViewModal}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ViewEEPortfolio
