import { Carousel } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useState } from 'react'
import { ContactModal } from './ContactModal'
import { RequestedModal } from './RequestedModal'

type Props = {
  handleClose: () => void
  isRequestSent: boolean
}

export const AddAccountAccessForm = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  return (
    <>
      <div className=' p-0 rounded'>
        <Carousel fade controls={false}>
          <Carousel.Item>
            <img
              className='h-500px w-100 rounded'
              src={toAbsoluteUrl('/media/icons/duotune/general/dashboard.png')}
              alt='...'
            />
            <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
              <div className='text-white p-9'>
                <h1 className='text-white'>Dashboard</h1>
                <p className='fs-6'>
                  Stay updated on the performance of your portfolio companies, track key actions,
                  and optimize your investment strategy effortlessly.
                </p>
              </div>
              <div className='d-flex align-items-end justify-content-center h-25'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={false}
                  onClick={() => setShowModal(true)}
                >
                  {'Contact for Upgrade'}
                </button>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='h-500px w-100 rounded'
              src={toAbsoluteUrl('/media/icons/duotune/general/portfolio.png')}
              alt='...'
            />
            <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
              <div className='text-white p-9'>
                <h1 className='text-white'>Portfolio</h1>
                <p className='fs-6'>
                  Monitor investment growth across size, industry, and type while keeping a close
                  eye on the valuation of your companies.
                </p>
              </div>
              <div className='d-flex align-items-end justify-content-center h-25'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={false}
                  onClick={() => setShowModal(true)}
                >
                  {'Contact for Upgrade'}
                </button>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='h-500px w-100 rounded'
              src={toAbsoluteUrl('/media/icons/duotune/general/watchlist.png')}
              alt='...'
            />
            <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
              <div className='text-white p-9'>
                <h1 className='text-white'>Watchlist</h1>
                <p className='fs-6'>
                  Stay informed by adding potential companies to your watchlist to ensure you never
                  miss out on investment opportunities.
                </p>
              </div>
              <div className='d-flex align-items-end justify-content-center h-25'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={false}
                  onClick={() => setShowModal(true)}
                >
                  {'Contact for Upgrade'}
                </button>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='h-500px w-100 rounded'
              src={toAbsoluteUrl('/media/icons/duotune/general/deals.png')}
              alt='...'
            />
            <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
              <div className='text-white p-9 pb-15'>
                <h1 className='text-white'>Deals</h1>
                <p className='fs-6'>
                  Take the lead by sending term sheets promptly and securing investment deals
                  swiftly.
                </p>
               
              </div>
              <div className='d-flex align-items-end justify-content-center h-25'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={false}
                  onClick={() => setShowModal(true)}
                >
                  {'Contact for Upgrade'}
                </button>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        {showModal && !props.isRequestSent && (
          <ContactModal showModal={showModal} handleClose={() => setShowModal(false)} />
        )}
        {showModal && props.isRequestSent && (
          <RequestedModal showModal={showModal} handleClose={() => setShowModal(false)} />
        )}
      </div>
    </>
  )
}
