import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Dropzone, {
  IInputProps,
  ILayoutProps,
  IPreviewProps,
  IUploadParams,
} from 'react-dropzone-uploader'
import {
  getS3UrlForUpload,
  postCompanyDocuments,
  postInvestorDocuments,
} from '../../../../api/investorApi'
import {
  useUploadLinksMutation,
  UploadLinksMutation,
  UploadLinksMutationVariables,
  Document_Vault,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { KTSVG } from '../../../../_metronic/helpers'
import axios from '../../../../api/axios'
import { usePostCompanyDocumentLink } from '../../../modules/auth/core/_requests'

type Props = {
  handleClose: Function
  show: boolean
  cin: string
  folder: String
  investorId?: number
  id?: number
  vaultType: Document_Vault
  companyName?: string
  cId?: number | string
  hissaVerified?: boolean
  iframeToken?: string
}

const FileUpload = (props: Props) => {
  const [fileUpload, setFileUpload] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [uploadType, setUploadType] = useState('link upload')
  const [linkUpload, setLinkUpload] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [name, setName] = useState('')
  const [link, setLink] = useState('')
  const mutateAddLinks = useUploadLinksMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: UploadLinksMutation,
        _variables: UploadLinksMutationVariables,
        _context: unknown
      ) => {
        Toast('Successfully Uploaded!', ToastType.success)
        setSuccessMessage('Successfully Uploaded')
        setIsLoading(false)
        props.handleClose()
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
        setIsLoading(false)
      },
    },
    {}
  )

  const { mutate: postCompanyDocumentLinkMutation } = usePostCompanyDocumentLink()
  const getUploadParams = async ({ meta }: any) => {
    let uploadUrl = ''
    if (props.id) {
      uploadUrl = await getS3UrlForUpload(
        props.companyName + '/' + props.id + '/' + props.folder + meta.name
      )
    } else {
      uploadUrl = await getS3UrlForUpload(
        'investor/' + props.investorId + '/' + props.cId + '/' + props.folder + meta.name,
        props.hissaVerified,
        props.companyName,
        props.investorId
      )
    }
    const ret: IUploadParams = {
      url: uploadUrl,
      method: 'put',
    }
    return ret
  }
  function handleLinkSubmit(vaultType: Document_Vault) {
    if (vaultType === 'INVESTOR') {
      mutateAddLinks.mutate({
        input: {
          cin: props.cin,
          investorId: props.investorId,
          name: name,
          link: link,
          folder: String(props.folder),
        },
      })
    } else if (vaultType === 'COMPANY') {
      postCompanyDocumentLinkMutation(
        {
          iframeToken: props.iframeToken || '',
          name: name,
          link: link,
        },
        {
          onSuccess(data) {
            Toast('Added link Successfully!', ToastType.success)
            queryClient.invalidateQueries('companyDocuments')
            props.handleClose()
            setIsLoading(false)
          },
          onError(error) {
            setIsLoading(false)
          },
        }
      )
    }
  }

  type data = {
    cin: string
    investorId?: number
    key: string
    name: string
    size: number
    tags: string[]
    userId?: number
    hissaVerified?: boolean
    companyId?: number | string
  }[]

  const upload = async (documents: data) => {
    if (props.vaultType === 'INVESTOR') {
      const data = await postInvestorDocuments(documents)
    } else if (props.vaultType === 'COMPANY') {
      const data = await postCompanyDocuments(props.cin, documents)
    }
  }

  const handleChangeStatus = ({ meta }: any, status: any) => {
    if (meta.status === 'done' && props.vaultType === 'INVESTOR') {
      const investorDoc = [
        {
          cin: props.cin,
          investorId: Number(props.investorId),
          key: props.folder + meta.name,
          name: props.folder + meta.name,
          size: meta.size,
          tags: [],
          companyId: props.cId,
          hissaVerified: props.hissaVerified,
          companyName: props.companyName,
        },
      ]

      upload(investorDoc)
      Toast('Documents Uploaded Successfully!', ToastType.success)
    }
    if (meta.status === 'done' && props.vaultType === 'COMPANY') {
      const companyDoc = [
        {
          cin: props.cin,
          userId: Number(props.investorId),
          key: props.folder + meta.name,
          name: props.folder + meta.name,
          size: meta.size,
          tags: [],
        },
      ]

      upload(companyDoc)
    }
  }

  const handleSubmit = (files: any[], allFiles: any[]) => {
    allFiles.forEach((f: { remove: () => any }) => f.remove())
  }

  const Preview = ({ meta, fileWithMeta }: IPreviewProps) => {
    const { name, percent, status, size, validationError } = meta
    return (
      <div className='dropzone dropzone-queue p-9 pt-0'>
        <div className='dropzone-items'>
          <div className='dropzone-item p-2 dz-image-preview'>
            <div className='dropzone-file'>
              <div className='dropzone-filename' title={name}>
                <span className='fs-base fw-bold'>{name}</span>{' '}
                <strong>
                  (
                  <span>
                    {size > 1000000
                      ? (size / 1048576).toFixed(2) + ' MB'
                      : (size / 1024).toFixed(2) + ' KB'}
                  </span>
                  )
                </strong>
              </div>
              <div className={`${status === 'done' ? 'text-success' : 'dropzone-error'}`}>
                {status}
              </div>
            </div>
            <div className='dropzone-progress'>
              <div className='progress'>
                <div
                  className={`${status === 'done' ? 'bg-success' : 'bg-primary'}`}
                  role='progressbar'
                  aria-valuemin={0}
                  aria-valuemax={100}
                  aria-valuenow={percent}
                  data-dz-uploadprogress
                  style={{ width: percent + '%' }}
                ></div>
              </div>
            </div>
            <div className='dropzone-toolbar'>
              {status != 'done' ? (
                <span className='dropzone-delete' data-dz-remove>
                  <span
                    className='btn btn-icon btn-sm btn-active-light-primary'
                    onClick={() => {
                      fileWithMeta.cancel()
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr061.svg'
                    />
                  </span>
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  const Input = ({ accept, onFiles, files, getFilesFromEvent }: IInputProps) => {
    const text = files.length > 0 ? 'Add more files' : 'Choose files'

    const fileList = files.map((file) => (
      <h6 key={file.file.name}>
        {file.file.name} -{' '}
        {file.file.size > 1000000
          ? (file.file.size / 1000000).toFixed(2) + ' MB'
          : (file.file.size / 1000).toFixed(2) + ' KB'}
      </h6>
    ))

    return (
      <div className=''>
        <label className='btn btn-outline btn-outline-dashed btn-outline-default bg-opacity-10 py-12 d-flex justify-content-center m-9'>
          <input
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            onChange={async (e) => {
              let file = await getFilesFromEvent(e)
              onFiles(file)
            }}
            multiple={true}
          />
          <div className='text-center'>
            <KTSVG
              path='/media/icons/duotune/general/gen041.svg'
              className={`svg-icon-3x me-5 text-gray-600 text-hover-primary`}
            />
            <h2 className='text-gray-600 my-2'>Drag & drop or click to choose files</h2>

            {/* <h3 className='fw-bold text-gray-700'>
              {' '}
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className={`svg-icon-2x text-gray-700`}
              />
              Max file size: 10 MB
            </h3> */}
          </div>
        </label>
      </div>
    )
  }

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => {
    return (
      <div {...dropzoneProps} className=''>
        <div className=''>{input}</div>

        {previews}

        {/* {files.length > 0 && submitButton} */}
      </div>
    )
  }
  return (
    <Modal
      id='kt_header_search_modal'
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered h-auto mw-1000px '
      show={props.show}
      centered
      animation={true}
      backdrop={true}
      onHide={() => {
        props.handleClose()
      }}
    >
      <Modal.Header>
        <Modal.Title className='fs-1 fw-bold px-8'>Select Upload Type</Modal.Title>
        <div className='row justify-content-end'>
          <button
            className='btn btn-sm btn-icon'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => {
              props.handleClose()
              setSuccessMessage('')
              setErrorMessage('')
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className='px-9'>
        {!props.id && (
          <div className='p-8 d-flex justify-content-center'>
            <input
              className='form-check-input'
              type='radio'
              checked={uploadType === 'file upload'}
              value='file upload'
              onChange={(e) => {
                setUploadType(e.target.value)
                setLinkUpload(false)
                setFileUpload(true)
                setErrorMessage('')
                setSuccessMessage('')
                setLink('')
                setName('')
              }}
            />
            <h2 className='form-check-label mx-3 fw-bold'>File Upload</h2>
            <input
              className='form-check-input'
              type='radio'
              value='link upload'
              checked={uploadType === 'link upload'}
              onChange={(e) => {
                setUploadType(e.target.value)
                setFileUpload(false)
                setLinkUpload(true)
                setErrorMessage('')
                setSuccessMessage('')
              }}
            />
            <h2 className='form-check-label ms-3 fw-bold'>Link Upload</h2>
          </div>
        )}
        {fileUpload && (
          <div
            className='card rounder shadow-sm mb-4'
            style={{
              boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
              border: '1',
              borderColor: '#F1F1F2',
            }}
          >
            <Modal.Title className='fs-1 fw-bold p-9 pb-0'>Upload Files</Modal.Title>
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              autoUpload={true}
              styles={{ dropzone: { minHeight: 200 } }}
              accept={
                'application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf,.png,.jpeg,.jpg,'
              }
              PreviewComponent={Preview}
              maxSizeBytes={100000000}
              SubmitButtonComponent={() => <></>}
              InputComponent={Input}
              LayoutComponent={Layout}
            />
          </div>
        )}
        {linkUpload && (
          <>
            <Modal.Title className='fs-1 fw-bold px-8 mb-5'>Link Upload</Modal.Title>
            <div className='fv-row mb-4'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-4 form-label required'>
                <h4>Name</h4>
              </label>
              <input
                className='form-control custom-input fw-bold shadow-lg h-38px'
                onChange={(e) => {
                  setName(e.target.value)
                  if (e.target.value.trim().length === 0) {
                    setErrorMessage('Name cannot be empty')
                  } else {
                    setErrorMessage('')
                  }
                }}
              />
            </div>
            <div className='fv-row mb-4'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-4 form-label required'>
                <h4>Link</h4>
              </label>
              <input
                className='form-control custom-input fw-bold shadow-lg h-38px'
                onChange={(e) => {
                  setLink(e.target.value)
                  if (e.target.value.trim().length === 0) {
                    setErrorMessage('Link cannot be empty')
                  } else {
                    setErrorMessage('')
                  }
                }}
              />
            </div>
            <div className='text-danger mt-2'>{errorMessage}</div>
            <div className='text-success mt-2'>{successMessage}</div>
            <div className='d-grid d-md-flex justify-content-md-end'>
              <button
                className='btn btn-primary'
                type='button'
                onClick={() => {
                  setIsLoading(true)
                  handleLinkSubmit(props.vaultType)
                }}
                disabled={isLoading || name.trim().length === 0 || link.trim().length === 0}
              >
                {isLoading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
                {!isLoading && 'Submit'}
              </button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default FileUpload
