import { Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { HissaAi } from './HissaAI'
import { KTSVG } from '../../../../../../_metronic/helpers'
import { useEffect, useState } from 'react'
import { HissaAIResponse } from './HissaAIResponse'

type Props = {
  showModal: boolean
  handleClose: VoidFunction
  schema: any
  handleGenerateForm: Function
}

const HissaAiModal = ({ showModal, handleClose, schema, handleGenerateForm }: Props) => {
  const [overlayOpen, setOverlayOpen] = useState<boolean>(false)
  const [response, setResponse] = useState<string>()
  const [responseValues, setResponseValues] = useState<string>()
  const [responseOpen, setResponseOpen] = useState<boolean>(false)
  const [isMutationLoading, setIsMutationLoading] = useState<boolean>(false)
  const [initValues, setInitValues] = useState<JSON>(JSON.parse('[]'))

  useEffect(() => {
    setTimeout(() => {
      setOverlayOpen(true)
    }, 2000)
  }, [])

  const popover = (
    <Popover
      id='popover-basic'
      className='bg-growth-card border border-0 pt-4 ps-4 pe-4 min-w-375px'
    >
      <Popover.Body>
        <div className='text-popover '>
          <h3 className='text-white'>AI in Document Generation</h3>
          <span className='text-white'>
            Introducing Hissa.ai, your drafting assistant! Save valuable time and resources by
            relying on me for document creation. Simply input the keywords relevant to your
            document, and I'll effortlessly generate it.
          </span>
          <div className='d-flex justify-content-end'>
            <button
              className='rounded border border-1 border-primary btn-sm btn bg-transparent h-38px fw-normal text-primary '
              type='button'
              onClick={() => setOverlayOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  )
  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered ${
        responseOpen === false ? 'mw-400px' : 'mw-1000px'
      }`}
      show={showModal}
      onHide={handleClose}
    >
      <Modal.Body className='p-0'>
        <div className='row'>
          <div
            className={`card p-0 shadow-sm h-auto border border-end border-2 ${
              responseOpen === false ? 'col-12' : 'col-5'
            }`}
          >
            <OverlayTrigger
              placement='right-start'
              rootCloseEvent='mousedown'
              overlay={popover}
              show={overlayOpen}
            >
              <div className='col d-flex justify-content-center rounded-top bg-secondary-fill'>
                <div className=''>
                  <KTSVG path='/media/icons/duotune/general/hissa-ai.svg' className='svg-icon-5x' />
                </div>
              </div>
            </OverlayTrigger>

            <span className='text-muted d-flex justify-content-center fs-6 pt-2'>
              Chat Now with Hissa.ai
            </span>
            <div className='d-flex row m-6'>
              <HissaAi
                schema={schema}
                handleGenerateForm={handleGenerateForm}
                handleClose={handleClose}
                isDrawer={false}
                setResponse={(message: string) => {
                  setResponse(message)
                }}
                setResponseValues={(values: string) => {
                  setResponseValues(values)
                }}
                setResponseOpen={(value: boolean) => {
                  setResponseOpen(value)
                }}
                setIsMutationLoading={(value: boolean) => {
                  setIsMutationLoading(value)
                }}
                setInitValues={(value: JSON) => {
                  setInitValues(value)
                }}
                initValues={initValues}
              />
            </div>
          </div>
          {responseOpen === true && (
            <div className={`card card-p-0 h-auto col-7`}>
              <div className='d-flex row m-7 mx-4'>
                <HissaAIResponse
                  schema={schema}
                  response={response || ''}
                  filledValues={responseValues || ''}
                  isMutationLoading={isMutationLoading}
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { HissaAiModal }
