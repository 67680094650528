/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import ChangePassword from '../../../../app/modules/auth/components/ChangePassword'
import { queryCache } from '../../../../queries/client'
import { toAbsoluteUrl } from '../../../helpers'
import { Link } from 'react-router-dom'
import { useCanUserAccess } from '../../../../app/pages/store/userRoleGrants'
import SyncInvestorCompanies from '../../../../app/pages/accounts/components/SyncInvestorCompanies'

const HeaderUserMenu: FC<{ userName: string; userEmail: string }> = ({ userName, userEmail }) => {
  const { logout } = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [showSyncInvestors, setShowSyncInvestors] = useState(false)

  const isAdmin = useCanUserAccess('*', '*')

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {userName}
              {/* <span className='badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2'>Angel</span> */}
            </div>
            <p className='text-primary py-1 fs-base'>
              {userEmail.length < 22 ? userEmail : userEmail.substring(0, 22) + '...'}
            </p>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* {isAdmin && (
        <div className='menu-item px-5'>
          <Link to='/addAccount' className='menu-link px-5'>
            Add Account
          </Link>
        </div>
      )} */}

      {isAdmin && (
        <div className='menu-item px-5'>
          <Link to='/addInvestor' className='menu-link px-5'>
            Add Investor/Fund
          </Link>
        </div>
      )}

      {isAdmin && (
        <div className='menu-item px-5'>
          <span
            className='menu-link px-5'
            onClick={() => setShowSyncInvestors(true)}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
          >
            Sync Investors
          </span>
        </div>
      )}

      {isAdmin && (
        <div className='menu-item px-5'>
          <Link to='/adduser' className='menu-link px-5'>
            Add Another User
          </Link>
        </div>
      )}

      {
        <div className='menu-item px-5'>
          <span
            className='menu-link px-5'
            onClick={() => setShowModal(true)}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
          >
            Change Password
          </span>
        </div>
      }

      <div className='menu-item px-5'>
        <a
          onClick={() => {
            logout()
            queryCache.clear()
          }}
          className='menu-link px-5'
        >
          Sign Out
        </a>
      </div>
      {showModal && (
        <ChangePassword
          show={showModal}
          handleClose={() => {
            setShowModal(false)
          }}
        />
      )}
      {showSyncInvestors && (
        <SyncInvestorCompanies
          show={showSyncInvestors}
          handleClose={() => setShowSyncInvestors(false)}
        />
      )}
    </div>
  )
}

export { HeaderUserMenu }
