export const globalCompaniesList = [
  {
    name: 'InMobi',
    valuation: 120000000000,
    year: 'Apr 2021',
    logo: '/media/company-logos/inmobi-logo.jpeg',
  },
  {
    name: 'Polygon Technology',
    valuation: 100000000000,
    year: 'Feb 2022',
    logo: '/media/company-logos/polygon-logo.png',
  },
  {
    name: 'OYO Rooms',
    valuation: 90000000000,
    year: 'Jul 2021',
    logo: '/media/company-logos/oyo-logo.jpeg',
  },
  {
    name: 'Dream11',
    valuation: 80000000000,
    year: 'Nov 2021',
    logo: '/media/company-logos/dream11-logo.png',
  },
  {
    name: 'ReNew Power',
    valuation: 80000000000,
    year: 'Mar 2021',
    logo: '/media/company-logos/renew-power-logo.jpeg',
  },
  {
    name: 'Mu Sigma',
    valuation: 1500000000,
    year: 'Jan 2013',
    logo: '/media/company-logos/mu-sigma-logo.jpeg',
  },
  {
    name: 'Ola',
    valuation: 65000000000,
    year: 'Oct 2020',
    logo: '/media/company-logos/ola-logo.png',
  },
  {
    name: 'PharmEasy',
    valuation: 56000000000,
    year: 'Oct 2021',
    logo: '/media/company-logos/pharm-easy-logo.png',
  },
  {
    name: 'Postman',
    valuation: 56000000000,
    year: 'Aug 2021',
    logo: '/media/company-logos/postman-logo.png',
  },
  {
    name: 'PhonePe',
    valuation: 55000000000,
    year: 'Dec 2020',
    logo: '/media/company-logos/phone-pay-logo.png',
  },
  {
    name: 'OfBusiness',
    valuation: 50000000000,
    year: 'Dec 2021',
    logo: '/media/company-logos/ofBusiness-logo.jpeg',
  },
  {
    name: 'VerSe Innovation',
    valuation: 50000000000,
    year: 'Apr 2021',
    logo: '/media/company-logos/verse-logo.jpeg',
  },
  {
    name: 'Ola Electric',
    valuation: 50000000000,
    year: 'Jan 2022',
    logo: '/media/company-logos/ola-electric-logo.png',
  },
  {
    name: 'Lenskart',
    valuation: 43200000000,
    year: 'Apr 2022',
    logo: '/media/company-logos/lenskart-logo.jpeg',
  },
  {
    name: 'Digit Insurance',
    valuation: 40000000000,
    year: 'May 2022',
    logo: '/media/company-logos/digit-logo.png',
  },
]
