import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'
import ManageTags from './ManageTags'
import { deleteCompanyDocuments } from '../../../../api/investorApi'
import { useParams } from 'react-router-dom'
import { Document_Vault } from '../../../../generated/graphql'
import { useDeleteCompanyDocumentLink } from '../../../modules/auth/core/_requests'
import Toast, { ToastType } from '../../../../utils/toast'
import { queryClient } from '../../../../queries/client'

function ActionMenu(props: any) {
  const { id, companyName, cin } = useParams()
  const options: any[] = []
  if (props.item.source === 'link') {
    options.push('Delete')
  } else if (props.item.size) {
    options.push(...['Show File', 'Download'])
    if (!props.isGeneric) options.push('Manage Tags')
    if (props.item.source != 'ROC' && !props.item.isTransactionDocument) options.push('Delete')
  } else if (props.item.count == 0) {
    if (props.item.source != 'ROC') options.push('Delete Folder')
  }
  const vaultType = props.vaultType as Document_Vault

  const { mutate: deleteCompanyDocumentLinkMutation } = useDeleteCompanyDocumentLink()

  const [show, setShow] = useState(false)

  function getFullPath() {
    let key: string = ''
    props.folderName.forEach((e: String) => {
      if (e != 'Home') {
        key += e + '/'
      }
    })
    return key
  }

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
        data-kt-menu='true'
      >
        {options.map((option) => {
          return (
            <label
              key={option}
              className='form-check-custom py-3 bg-hover-primary text-hover-white'
              onClick={async () => {
                if (option === 'Delete' || option === 'Delete Folder') {
                  if (vaultType === Document_Vault.Company && props.item.source === 'link') {
                    deleteCompanyDocumentLinkMutation(
                      {
                        id: props.item.id,
                        link: props.item.link,
                        iframeToken: props.iframeToken,
                      },
                      {
                        onSuccess(data) {
                          Toast('Deleted link Successfully!', ToastType.success)
                          queryClient.invalidateQueries('companyDocuments')
                        },
                      }
                    )
                  } else {
                    props.deleteItem(props.item)
                  }
                } else if (option === 'Show File') {
                  props.handleClick(props.item)
                } else if (option === 'Download') {
                  props.handleClick(props.item, true)
                } else {
                  setShow(true)
                }
              }}
            >
              <span className='form-check-label fs-base fw-normal ms-9 cursor-pointer '>
                {option}
              </span>
            </label>
          )
        })}
      </div>
      {show && (
        <ManageTags
          showModal={show}
          setShows={() => setShow(false)}
          item={props.item}
          companyName={props.companyName}
          cin={props.cin}
          investorId={props.investorId}
          id={props.id}
          isTransaction={props.item.isTransactionDocument}
          companyData={props.companyData}
        />
      )}
    </>
  )
}

export default ActionMenu
