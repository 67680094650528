import React, { useEffect, useMemo, useRef } from 'react'
import { DealTemplate } from '../../../../../generated/graphql'
import { AgGridReact } from 'ag-grid-react'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { ColDef } from 'ag-grid-community'
import { isoToDate } from '../../../../../utils/FormatDate'
import { TemplateActionRender } from './TemplateAGComponents'
type TempalteTableProps = {
  dealTemplates: DealTemplate[] | any[]
}
function TemplateAGTable(props: TempalteTableProps) {
  const gridApi = useRef<any>(null)
  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [props.dealTemplates])
  const rowData = useMemo(
    () =>
      props.dealTemplates.map((template) => {
        return {
          templateName: template?.name,
          features: '-',
          lastIssuedOn: template?.lastIssuedDate ? isoToDate(template?.lastIssuedDate) : '-',
          ...template,
        }
      }),
    [props.dealTemplates]
  )
  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'TEMPLATE NAME',
        field: 'templateName',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'FEATURES',
        field: 'features',
        sortable: true,
        filter: true,
      },
      {
        headerName: 'LAST ISSUED ON',
        field: 'lastIssuedOn',
        sortable: true,
        filter: true,
      },
      {
        headerName: '',
        field: 'actions',
        pinned: 'right',
        hide: false,
        width: 60,
        maxWidth: 60,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        cellStyle: {
          'padding-left': '9px',
          'padding-right': '4px',
          'padding-top': '5px',
          'line-height': '20px',
        },
        resizable: false,
        sortable: false,
        cellRenderer: TemplateActionRender,
      },
    ],
    []
  )
  return (
    <div className='card-body w-100 p-0 overflow-auto ag-theme-material h-100'>
      <AgGridReact
        onGridReady={(params) => {
          gridApi.current = params
        }}
        alwaysShowHorizontalScroll
        alwaysMultiSort
        animateRows={true}
        defaultColDef={defaultColDef}
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={false}
        enableCharts={true}
        enableRangeSelection={true}
        suppressRowTransform={true}
        suppressCopyRowsToClipboard={true}
        suppressCopySingleCellRanges={true}
        suppressCellFocus={true}
        suppressMenuHide={false}
        tooltipShowDelay={50}
        tooltipInteraction={true}
        rowClass={'border-right-0 border-left-0 border-dashed'}
        overlayNoRowsTemplate={
          '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
        }
        getRowStyle={(params) => {
          if (params.rowIndex % 2 === 0) {
            return { background: '#f8f8f8' }
          } else {
            return { background: '#ffffff' }
          }
        }}
      ></AgGridReact>
    </div>
  )
}

export default TemplateAGTable
