import { useContext } from 'react'
import { InvestorRoundModellingData } from '../../../../../generated/graphql'
import { GlobalContext } from '../../../store/context/globalContext'
import {
  formatNumber,
  getCurrency,
  getSymbolOfCurrency,
} from '../../../../../utils/CurrencyFormatter'
import { ModelProgressBar } from './ModelProgressBar'

type InvModelSummaryProps = {
  modelData: InvestorRoundModellingData
}

function InvModelSummaryCard(props: InvModelSummaryProps) {
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const roundDetails = props.modelData.investmentModellingData.roundData
  const investmentData = props.modelData.investmentModellingData.investmentData
  const data = {
    roundSize: roundDetails.roundSize,
    postMoney: roundDetails.postMoneyValuation,
    postMoneyEsop: roundDetails.postRoundEsopPercentage,
    preMoney: roundDetails.preMoneyValuation,
    dilution:
      roundDetails?.postMoneyValuation > 0 && roundDetails?.roundSize > 0
        ? (roundDetails?.roundSize / roundDetails?.postMoneyValuation) * 100
        : 0,
  }
  const totalInvestment = investmentData?.reduce(
    (acc: any, curr: any) => acc + curr.investmentAmount,
    0
  )
  const leftToRaise = roundDetails.roundSize - totalInvestment || 0
  const percentage = (totalInvestment / roundDetails.roundSize) * 100 || 0

  return (
    <div
      className='card p-5 overflow-auto shadow-sm border w-md-375px border-1 border-gray-300'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='card '>
        <h3 className='pb-2'>{props.modelData?.name}</h3>
        <div className='row '>
          <div className='col border-2 border-end border-gray-600 '>
            <p className='fs-7 p-0 m-0 '>Round Size</p>
            <p
              className='fs-5 fw-bold p-0 m-0 text-truncate'
              data-toggle='tooltip'
              title={Number(data.roundSize).toLocaleString(userCurrencyFormat)}
              style={{ maxWidth: '160px' }}
            >
              {getSymbolOfCurrency(userCurrency)}
              {formatNumber({
                value: data.roundSize,
                format: userCurrencyFormat,
              })}
            </p>
          </div>
          <div className='col border-2 border-end border-gray-600 text-center'>
            <p className='fs-7 p-0 m-0 '>Post Money</p>
            <p
              className='fs-5 fw-bold p-0 m-0 text-truncate'
              data-toggle='tooltip'
              title={Number(data.postMoney).toLocaleString(userCurrencyFormat)}
              style={{ maxWidth: '160px' }}
            >
              {getSymbolOfCurrency(userCurrency)}
              {formatNumber({
                value: data.postMoney,
                format: userCurrencyFormat,
              })}
            </p>
          </div>
          <div className='col text-center'>
            <p className='fs-7 p-0 m-0 '>Dilution</p>
            <p
              className='fs-5 fw-bold p-0 m-0 text-truncate'
              data-toggle='tooltip'
              title={Number(data.dilution).toLocaleString(userCurrencyFormat)}
              style={{ maxWidth: '160px' }}
            >
              {formatNumber({
                value: data.dilution,
                format: userCurrencyFormat,
              }) + '%'}
            </p>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='d-flex justify-content-between'>
        <p className='fs-7 d-flex align-items-center p-0 m-0 '>
          {`Pre Money (${getSymbolOfCurrency(userCurrency)})`}
        </p>
        <p
          className='fs-5 fw-bold d-flex align-items-center p-0 m-0 '
          data-toggle='tooltip'
          title={Number(data.preMoney).toLocaleString(userCurrencyFormat)}
        >
          {getSymbolOfCurrency(userCurrency)}
          {formatNumber({
            value: data.preMoney,
            format: userCurrencyFormat,
          })}
        </p>
      </div>
      {/* <div className='d-flex justify-content-between'>
        <p className='fs-7 d-flex align-items-center p-0 m-0 '>Post ESOP percentage (%)</p>
        <p
          className='fs-5 fw-bold d-flex align-items-center p-0 m-0 '
          data-toggle='tooltip'
          title={Number(data.postMoneyEsop).toLocaleString(userCurrencyFormat)}
        >
          {formatNumber({
            value: data.postMoneyEsop,
            format: userCurrencyFormat,
          }) + '%'}
        </p>
      </div> */}
      <div className='separator my-2'></div>
      <div className=''>
        <ModelProgressBar
          leftToRaise={leftToRaise}
          percentage={percentage}
          raisedAmount={totalInvestment}
          userCurrencyFormat={userCurrencyFormat}
          userCurrency={userCurrency}
          bgColor=''
        />
      </div>
    </div>
  )
}

export default InvModelSummaryCard
