import { useFormik } from 'formik'
import { Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import {
  AddWatchlistMutation,
  AddWatchlistMutationVariables,
  EditWatchlistNameMutation,
  EditWatchlistNameMutationVariables,
  useAddWatchlistMutation,
  useEditWatchlistNameMutation,
  useGetNewWatchlistsQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { InputBox } from '../../../../utils/NumberInputBox'

type WatchlistProps = {
  showModal: boolean
  handleClose: () => void
  isEdit: boolean
  watchListName?: string
  id?: number
}
export function AddWatchlist(props: WatchlistProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const initialValues = {
    watchListName: props.watchListName || '',
  }

  const AddWatchlistSchema = Yup.object().shape({
    watchListName: Yup.string().min(3).required().label('Watchlist Name'),
  })

  const mutateAddWatchlist = useAddWatchlistMutation<Error>(graphqlRequestClient, {}, {})
  const mutateEditWatchlist = useEditWatchlistNameMutation<Error>(graphqlRequestClient, {}, {})

  const formik = useFormik({
    initialValues,
    validationSchema: AddWatchlistSchema,
    onSubmit: async (values) => {
      setLoading(true)
      props.isEdit
        ? mutateEditWatchlist.mutate(
            {
              input: {
                name: values.watchListName.trim(),
                id: props.id,
              },
            },
            {
              onSuccess: (
                _data: EditWatchlistNameMutation,
                _variables: EditWatchlistNameMutationVariables,
                _context: unknown
              ) => {
                queryClient.invalidateQueries(useGetNewWatchlistsQuery.getKey())
                Toast('Watchlist Name Edited Successfully!', ToastType.success)
                setLoading(false)
                props.handleClose()
              },
              onError: (error: any) => {
                setLoading(false)
                Toast(error.response.errors[0].message, ToastType.error)
              },
            }
          )
        : mutateAddWatchlist.mutate(
            {
              input: {
                name: values.watchListName.trim(),
              },
            },
            {
              onSuccess: (
                data: AddWatchlistMutation,
                _variables: AddWatchlistMutationVariables,
                _context: unknown
              ) => {
                queryClient.invalidateQueries(useGetNewWatchlistsQuery.getKey())
                Toast('Watchlist Created Successfully!', ToastType.success)
                setLoading(false)
                props.handleClose()
              },
              onError: (error: any) => {
                setLoading(false)
                Toast(error.response.errors[0].message, ToastType.error)
              },
            }
          )
    },
  })

  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-500px'
      show={props.showModal}
      onHide={props.handleClose}
    >
      <div className='col d-flex justify-content-between px-9'>
        <div className='py-9'>
          <h2 className='card-header fw-bolder text-dark'>
            {props.isEdit ? 'Edit Watchlist' : 'Create New Watchlist'}
          </h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-6'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={props.handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>

      <Modal.Body className='p-9 pt-0'>
        {/* <div className='card'> */}
        <form className='card ' onSubmit={formik.handleSubmit}>
          <div className='card-body p-0'>
            <div className='fv-row mb-6 col-12 col-md-10 pb-8'>
              <label className='form-label required'>Watchlist Name</label>

              <InputBox
                isDisabled={false}
                name={'watchListName'}
                handleChange={(value: string) => {
                  formik.setFieldValue('watchListName', value)
                }}
                setFieldValue={(value: string) => {
                  formik.setFieldValue('watchListName', value)
                }}
                placeholder='Enter the Name of the Watchlist'
                currency={'INR - ₹'}
                value={formik.values.watchListName}
                type='text'
              />
              <div className='text-danger mt-2'>
                {formik.touched.watchListName && formik.errors.watchListName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.watchListName}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='d-flex col justify-content-between'>
              <button className='btn btn-lg btn-secondary' onClick={props.handleClose}>
                <span className='text-gray-800'>Cancel</span>
              </button>
              <button
                type='submit'
                className='btn btn-lg btn-primary'
                disabled={formik.errors.watchListName !== undefined || loading}
              >
                {!loading && (
                  <span className='indicator-label'>{props.isEdit ? 'Edit' : 'Save'}</span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
