import clsx from 'clsx'
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { useEffect, useRef, useState } from 'react'
import { useFillTermsheet } from '../../../../../modules/auth/core/_requests'
import { useUserNameStore } from '../../../../../../store/UserNameStore'
import HissaAiActionMenu from './HissaAiActionMenu'
import { useLocation } from 'react-router-dom'
import { useChatbotStore } from '../../../../../../store/termsheetChatbot'
import { getNameForKey } from '../../../../../../utils/TermsheetUtils'
import LoaderComponent from './LoaderComponent'

type Props = {
  schema: any
  handleGenerateForm: Function
  handleClose: VoidFunction
  isDrawer?: boolean
  setResponse: Function
  setResponseOpen: Function
  setResponseValues: Function
  setIsMutationLoading: Function
  setInitValues: Function
  initValues: JSON
}

export interface MessageModel {
  user: number
  type: 'in' | 'out'
  text: string
  template?: boolean
  showButtons?: boolean
}

export interface UserInfoModel {
  initials?: { label: string; state: 'warning' | 'danger' | 'primary' | 'success' | 'info' }
  name: string
  email: string
  position: string
}

const messageFromClient: MessageModel = {
  user: 1,
  type: 'in',
  text: 'Sorry, no fields are detected by Hissa.ai please try again.',
  showButtons: true,
}

let defaultMessages: Array<MessageModel> = [
  {
    user: 1,
    type: 'in',
    text: 'Hello! Welcome to Hissa.ai',
  },
  {
    user: 1,
    type: 'in',
    text: 'Please enter the details of the document you would like to build',
  },
]

type filteredValue = {
  key: string
  value: string
}

export function HissaAi({
  schema,
  handleGenerateForm,
  handleClose,
  isDrawer,
  setResponse,
  setResponseOpen,
  setResponseValues,
  setIsMutationLoading,
  initValues,
  setInitValues,
}: Props) {
  const userChatBotStore = useChatbotStore()
  const [message, setMessage] = useState<string>('')
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [chatEnable, setChatEnable] = useState<boolean>(true)
  const [messages, setMessages] = useState<MessageModel[]>(defaultMessages)
  const { mutate: fillTermsheetMutation, isLoading } = useFillTermsheet()
  const [filledValues, setFilledValues] = useState<JSON>(initValues)
  const modalBodyRef = useRef<HTMLDivElement>(null)
  const userNameStore = useUserNameStore()

  const scrollToBottom = () => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTo({
        top: modalBodyRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    setIsMutationLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    scrollToBottom()
  }, [filledValues])

  useEffect(() => {
    if (userChatBotStore.isClear) {
      defaultMessages = [
        {
          user: 1,
          type: 'in',
          text: 'Hello! Welcome to Hissa.ai',
        },
        {
          user: 1,
          type: 'in',
          text: 'Please enter the details of the document you would like to build',
        },
      ]
      setMessages(defaultMessages)
      userChatBotStore.setIsClear(false)
    }
  }, [userChatBotStore.isClear])

  const fillTermsheet = () => {
    fillTermsheetMutation(
      { freeText: message, templateSchema: schema as JSON, filledValues: filledValues },
      {
        onSuccess(data) {
          if (data?.filledValues.length > 0) {
            let response = 'The Document has been generated with the fields.\n'
            let filledValue = ''
            data.filledValues.forEach((obj: filteredValue) => {
              response +=
                '\n' + getNameForKey(obj.key, JSON.stringify(schema)) + ' : ' + obj.value + '; \n'
              filledValue +=
                '\n' + getNameForKey(obj.key, JSON.stringify(schema)) + ' : ' + obj.value + '; \n'
            })

            const responseFromClient: MessageModel = {
              user: 1,
              type: 'in',
              text: response,
              showButtons: true,
            }
            setResponse(responseFromClient)
            setResponseValues(filledValue)
            // defaultMessages.push(responseFromClient)
          } else {
            // defaultMessages.push(messageFromClient)
          }
          handleGenerateForm(data.filledValues)
          setFilledValues(data.filledValues)
          setInitValues(data.filledValues)
        },
      }
    )
  }

  const sendMessage = () => {
    const newMessage: MessageModel = {
      user: 2,
      type: 'out',
      text: message,
    }

    setResponseOpen(true)
    fillTermsheet()
    setChatEnable(false)
    defaultMessages.push(newMessage)
    setMessages(defaultMessages)
    toggleChatUpdateFlat(!chatUpdateFlag)
    setMessage('')
    setTimeout(() => {
      setMessages(() => defaultMessages)
      toggleChatUpdateFlat((flag) => !flag)
    }, 1000)
  }

  return (
    <div
      className='card-body p-0'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y me-n5 pe-5', {
          'h-400px ': !isDrawer,
        })}
        ref={modalBodyRef}
        style={{ overflowY: 'auto', overflowX: 'hidden', scrollbarWidth: 'none' }}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? '#kt_drawer_chat_messenger_body'
            : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >
        {messages.map((message, index) => {
          // const userInfo = userInfos[message.user]
          const state = message.type === 'in' ? 'info' : 'primary'
          const templateAttr = {}
          if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.type}`,
            })
          }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message.type === 'in' ? 'start' : 'end'
          } mb-5`
          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-5', { 'd-none': message.template })}
              {...templateAttr}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.type === 'in' ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.type === 'in' ? (
                    <>
                      <div className='symbol  symbol-40px symbol-circle bg-secondary'>
                        <KTSVG
                          path='/media/icons/duotune/general/hissa-ai-user-logo.svg'
                          className='svg-icon-3x'
                        />
                      </div>
                      <div className='ms-3'>
                        <span className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                          {'Hissa'}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <span className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                          {userNameStore.userName}
                        </span>
                      </div>
                      <div className='symbol symbol-35px symbol-circle bg-secondary'>
                        <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={clsx(
                    'p-5 rounded fs-6',
                    'text-gray-800 fw-bold mw-300px border border-gray-300',
                    `bg-${message.type === 'in' ? 'icon-bg' : 'white'}`,
                    `text-${message.type === 'in' ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                  dangerouslySetInnerHTML={{ __html: message.text }}
                ></div>
              </div>
            </div>
          )
        })}
        {messages &&
          messages.length > 0 &&
          messages[messages.length - 1].type === 'in' &&
          !chatEnable &&
          messages[messages.length - 1].showButtons && (
            <HissaAiActionMenu
              handleClose={handleClose}
              enableChat={() => setChatEnable(true)}
              clearChat={() => {
                setFilledValues(JSON.parse('[]'))
                setMessages(() => defaultMessages)
              }}
            />
          )}
      </div>

      <span className='text-muted d-flex justify-content-start fs-6 pt-2'>
        Chatting with the AI bot will overwrite the fields
      </span>
      <div
        className='card-footer border border-1 p-0'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <div className='d-flex flex-row justify-content-between'>
          <div className='d-flex align-items-center me-2 col'>
            <textarea
              className='form-control form-control-flush mb-3'
              rows={1}
              data-kt-element='input'
              placeholder='Type a message'
              value={message}
              // disabled={!chatEnable}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <button
            className='btn btn-sm btn-icon btn-secondary btn-active-light-primary mt-2 text-end'
            type='button'
            data-kt-element='send'
            // disabled={message === '' || message === undefined}
            onClick={sendMessage}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/general/gen016.svg' />
          </button>
        </div>
      </div>
    </div>
  )
}
