export type FieldsType = {
  name: string
  type: string
  values?: string[]
  required: string
  min?: string | number
  max?: string | number
  key: string
  value: string
  condition?: ConditionType | any
  toolTip?:any[]
  recommended:string
}

export type GroupsType = { name: string; fields: FieldsType[]; toolTip?:any[] }

export type ConditionType = { name: string; value: string }

export type StepperType = {
  stepname: string
  sequenceNumber: number
  groups: GroupsType[]
  condition?: ConditionType
  toolTip?:any[]
}


export function getCurrentTemplate(data: any, templateId: number) {
  const templateData: any[] = []
  data?.dealTemplates &&
    data?.dealTemplates.forEach((templates: any) => {
      if (templateId === templates.id) {
        templateData.push(templates)
      }
    })
  return templateData[0]
}