import { FC } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG, toAbsoluteUrl, WithChildren } from '../../../helpers'
import { useLayout } from '../../core'
import SVG from 'react-inlinesvg'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  isHeader?: boolean
  disabled?: boolean
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  isHeader,
  disabled = false,
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { aside } = config

  const content = (
    <>
      {hasBullet && (
        <span className='menu-bullet'>
          <span className='bullet bullet-dot'></span>
        </span>
      )}
      {icon && aside.menuIcon === 'svg' && icon.endsWith('svg') && (
        <span className='menu-icon'>
          <KTSVG path={icon} className='svg-icon-2xs' />
        </span>
      )}
      {icon && aside.menuIcon === 'svg' && !icon.endsWith('svg') && (
        <span className='menu-icon'>
          <img
            className='rounded bg-white text-center w-38px h-40px '
            src={toAbsoluteUrl('/media/logos/favicon.png')}
            alt='rz-logo'
          />
        </span>
      )}
      {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
      {!isHeader && <span className='menu-title fs-4 fw-bold'>{title}</span>}
      {isHeader && (
        <span className='menu-title'>
          <SVG src={toAbsoluteUrl('/media/logos/default.svg')} className='p-1 mt-2' style={{height: '36px', width: '92px'}}/>
        </span>
      )}
    </>
  )

  if (disabled) {
    return (
      <div className='menu-item' style={{ pointerEvents: 'none' }}>
        <span className={clsx('menu-link without-sub')}>{content}</span>
        {children}
      </div>
    )
  }

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', { active: isActive })} style={{}} to={to}>
        {content}
      </Link>
      {children}
    </div>
  )
}

export { AsideMenuItem }
