import { formatISO, format, parseISO, fromUnixTime } from 'date-fns'

export function formatDateToISO(dateStr: string | Date | undefined) {
  if (!dateStr) {
    dateStr = new Date()
  }
  if (typeof dateStr === 'string') {
    return formatISO(new Date(dateStr))
  } else {
    return formatISO(dateStr)
  }
}

export function stringToDate(s: string) {
  let d = new Date()
  let date
  if (s.includes('/')) {
    date = s.split('/')
    d.setFullYear(parseInt(date[2]))
    d.setMonth(parseInt(date[1]) - 1)
    d.setDate(parseInt(date[0]))
  } else if (s.includes('-')) {
    date = s.split('-')

    if (date[1].length === 3) {
      if (date[1] == 'Jan') {
        d.setMonth(0)
      } else if (date[1] == 'Jan') {
        d.setMonth(0)
      } else if (date[1] == 'Feb') {
        d.setMonth(1)
      } else if (date[1] == 'Mar') {
        d.setMonth(2)
      } else if (date[1] == 'Apr') {
        d.setMonth(3)
      } else if (date[1] == 'May') {
        d.setMonth(4)
      } else if (date[1] == 'Jun') {
        d.setMonth(5)
      } else if (date[1] == 'Jul') {
        d.setMonth(6)
      } else if (date[1] == 'Aug') {
        d.setMonth(7)
      } else if (date[1] == 'Sep') {
        d.setMonth(8)
      } else if (date[1] == 'Oct') {
        d.setMonth(9)
      } else if (date[1] == 'Nov') {
        d.setMonth(10)
      } else if (date[1] == 'Dec') {
        d.setMonth(11)
      }
      d.setDate(parseInt(date[0]))
      d.setFullYear(parseInt(date[2]))
    } else {
      if (s.includes('Z')) {
        d = parseISO(s)
      } else {
        d.setFullYear(parseInt(date[0]))
        d.setMonth(parseInt(date[1]) - 1)
        d.setDate(parseInt(date[2]))
      }
    }
  } else {
    d = parseISO(s)
  }
  return new Date(d)
}

export function formatDate(s: string, dateFormat: string) {
  return format(stringToDate(s), dateFormat) || new Date().toDateString()
}

export function isoToDate(s: string) {
  let d = new Date()
  if (s.includes('/')) {
    let date = s.split('/')
    d.setFullYear(parseInt(date[2]))
    d.setMonth(parseInt(date[1]) - 1)
    d.setDate(parseInt(date[0]))
  } else if (s.includes('-')) {
    let date = s.split('-')
    d.setFullYear(parseInt(date[0]))
    d.setMonth(parseInt(date[1]) - 1)
    d.setDate(parseInt(date[2]))
  }
  return format(d, 'dd-MMM-yyyy')
}

export function uiDateTime(s: string) {
  return format(new Date(s), 'dd-MMM-yyyy hh:mm a')
}

export function dateToFieldDate(s: string) {
  let d = new Date()
  if (s.includes('/')) {
    let date = s.split('/')
    d.setFullYear(parseInt(date[2]))
    d.setMonth(parseInt(date[1]) - 1)
    d.setDate(parseInt(date[0]))
  } else if (s.includes('-')) {
    let date = s.split('-')
    d.setFullYear(parseInt(date[0]))
    d.setMonth(parseInt(date[1]) - 1)
    d.setDate(parseInt(date[2]))
  }
  return format(d, 'dd-MM-yyyy')
}
