import AddAnotherUser from './components/AddAnotherUser'

function AccountsWrapper() {
  return (
    <div>
      <AddAnotherUser />
    </div>
  )
}
export default AccountsWrapper
