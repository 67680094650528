import { Modal } from 'react-bootstrap'
import * as Yup from 'yup'
import {
  useAddDealTemplateMutation,
  useGetDealTemplatesQuery,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import { useFormik } from 'formik'
import Toast, { ToastType } from '../../../../../utils/toast'
import { useMutation } from 'react-query'
import { StepperType } from '../../../../../utils/stepper'
import { useState } from 'react'
import { InputBox } from '../../../../../utils/NumberInputBox'
type Props = {
  showModal: boolean
  handleClose: () => void
}
const initValues = {
  templateName: '',
  docxTemplate: null,
  jsonTemplate: null,
  description: '',
}
const addTemplateSchema = Yup.object().shape({
  templateName: Yup.string().required('Please Enter The Name'),
  docxTemplate: Yup.mixed().nullable().required('Please upload docx'),
  jsonTemplate: Yup.mixed().nullable().required('Please upload json'),
  description: Yup.string().optional(),
})
function TemplateUploader({ showModal, handleClose }: Props) {
  const [loading, setLoading] = useState(false)
  const mututeDealTemplate = useAddDealTemplateMutation<Error>(graphqlRequestClient, {}, {})
  function camelize(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase()
      })
      .replace(/\s+/g, '')
  }
  const formik = useFormik({
    initialValues: initValues,
    validationSchema: addTemplateSchema,
    onSubmit: async (values) => {
      setLoading(true)
      const jsonDetails: StepperType[] = await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (event: any) => {
          const content = event.target.result
          const jsonData = JSON.parse(content)
          resolve(jsonData)
        }

        reader.readAsText(values.jsonTemplate!)
      })
      const section: any[] = await new Promise((resolve, reject) => {
        const sections: string[] = []
        jsonDetails.forEach((section: StepperType) => {
          if (section.stepname !== 'Details') {
            if (!sections.includes(camelize(section.stepname))) {
              sections.push(camelize(section.stepname))
            }
          }
        })
        resolve(sections)
      })
      mututeDealTemplate.mutate(
        {
          input: {
            name: values.templateName,
            fileName: values.docxTemplate,
            jsonData: jsonDetails,
            sections: section,
            documentType: 'Termsheet',
            description: values.description,
          },
        },
        {
          onSuccess: (result) => {
            console.log(result)
            Toast('Template added Successfully', ToastType.success)
            handleClose()
            queryClient.invalidateQueries(useGetDealTemplatesQuery.getKey())
            setLoading(false)
          },
          onError: (error: any) => {
            setLoading(false)
            Toast(error.response.errors[0].message, ToastType.error)
            console.log(error.response.errors[0].message)
          },
        }
      )
    },
  })

  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={showModal}
      onHide={() => {
        handleClose()
      }}
    >
      <Modal.Header>
        <h1>Template Uploader</h1>
      </Modal.Header>
      <Modal.Body>
        <form className='form' onSubmit={formik.handleSubmit}>
          <div>
            <label className='form-label fw-bold'>Template Name</label>

            <InputBox
              isDisabled={false}
              name={'templateName'}
              handleChange={(value: string) => {
                formik.setFieldValue('templateName', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('templateName', value)
              }}
              placeholder='Enter Template Name'
              currency={''}
              value={formik.values.templateName}
              type='text'
            />
          </div>
          {(formik.touched.templateName || formik.errors.templateName) && (
            <div className='text-danger fs-7'>{formik.errors.templateName}</div>
          )}
          <div className='d-flex justify-content-between'>
            <div className='mt-8'>
              <label className='form-label fw-bold'>Upload Document Template</label>
              <input
                className='form-control custom-input fw-bold shadow-lg h-38px py-2 text-primary'
                type='file'
                placeholder='Upload Docx Template'
                accept='.docx'
                max={1}
                onChange={(e) => {
                  if(e?.target?.files![0].size > 15000000){
                    e.target.value = ""
                    formik.setFieldError('docxTemplate','File size should be less than 15MB')
                  }else{
                  formik.setFieldValue('docxTemplate', e?.target?.files![0])
                  }
                }}
              />
              {(formik.touched.docxTemplate || formik.errors.docxTemplate) && (
                <div className='text-danger fs-7'>{formik.errors.docxTemplate}</div>
              )}
            </div>

            <div className='mt-8 ms-3'>
              <label className='form-label fw-bold'>Upload Json Template</label>
              <input
                className='form-control custom-input fw-bold shadow-lg h-38px py-2 text-primary'
                type='file'
                placeholder='Upload Json Template'
                accept='.json'
                max={1}
                onChange={(e) => {
                  formik.setFieldValue('jsonTemplate', e.target.files![0])
                }}
              />
              {(formik.touched.jsonTemplate || formik.errors.jsonTemplate) && (
                <div className='text-danger fs-7'>{formik.errors.jsonTemplate}</div>
              )}
            </div>
          </div>
          <div className='my-5'>
            <label className='form-label fw-bold'>Description</label>
            <InputBox
              isDisabled={false}
              name={'description'}
              handleChange={(value: string) => {
                formik.setFieldValue('description', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('description', value)
              }}
              placeholder='Enter Description'
              currency={''}
              value={formik.values.description}
              type='text'
            />
          </div>
          <div className='d-flex justify-content-between mt-10'>
            <button className='btn btn-secondary' onClick={handleClose} type='button'>
              Cancel
            </button>
            <button
              className='btn btn-primary'
              type='submit'
              disabled={
                formik.values.templateName === '' ||
                formik.values.docxTemplate === null ||
                formik.values.jsonTemplate === null ||
                loading
              }
            >
              {!loading && 'Submit'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Loading...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default TemplateUploader
