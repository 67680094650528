/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef } from 'react'
import { useThemeMode } from '../../../../../_metronic/partials'
import { ChartDataType } from './CompanyGrowthCard'
import ApexCharts, { ApexOptions } from 'apexcharts'
import {
  formatNumber,
  getCurrency,
  getSymbolOfCurrency,
} from '../../../../../utils/CurrencyFormatter'
import { GlobalContext } from '../../../store/context/globalContext'

type Props = {
  className: string
  svgIcon: string
  color: string
  change: string
  description: string
  chartData: ChartDataType[]
}

const StatisticsWidget4: React.FC<Props> = ({ className, color, chartData }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { state } = useContext(GlobalContext)
  const { mode } = useThemeMode()
  const refreshChart = (userCurrencyFormat: string, userCurrency: string) => {
    if (!chartRef.current) {
      return
    }

    const height = 150

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, chartData, userCurrencyFormat, userCurrency)
    )
    if (chart) {
      chart.render()
      if (chartRef) chartRef.current.scrollLeft = chartRef?.current?.scrollWidth
    }

    return chart
  }

  useEffect(() => {
    const { userCurrency } = state
    const userCurrencyFormat = getCurrency(userCurrency)
    const chart = refreshChart(userCurrencyFormat, userCurrency)
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, color, mode, chartData])

  return (
    <div className={'card'}>
      <div className='card-body w-auto bg-growth-card p-0 overflow-auto'>
        <div
          ref={chartRef}
          className='statistics-widget-4-chart card-rounded-bottom overflow-auto'
          style={{
            height: 'mh-150px',
            overflowY: 'hidden',
            overflowX: 'auto',
            scrollbarWidth: 'none',
          }}
        ></div>
      </div>
    </div>
  )
}

export { StatisticsWidget4 }

function getChartOptions(
  height: number,
  chartData: ChartDataType[],
  userCurrencyFormat: string,
  userCurrency: string
): ApexOptions {
  return {
    series: [
      {
        name: '',
        data: chartData.map((data) => data.value),
      },
    ],
    fill: {
      colors: ['transparent'],
      // opacity: 100,
      type: 'solid',
    },
    chart: {
      background: '#0c224a',
      type: 'area',
      height: height,
      width: chartData.length > 5 ? 800 + (chartData.length - 10) * 50 : '100%',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    grid: {
      padding: {
        top: -20,
        left: 20,
        right: 0,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex }) {
        return `<div style="padding: 15px;">
        <h6>Holding Value :</h6>
        <b 
        class="tooltip-text"
        >${
          getSymbolOfCurrency(userCurrency) +
          formatNumber({
            value: series[seriesIndex][dataPointIndex],
            format: userCurrencyFormat,
          })
        }</b></div>`
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    xaxis: {
      categories: chartData.map((data) => data.label),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          colors: 'white',
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: true,
        position: 'front',
        stroke: {
          color: 'white',
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      // min: 0,
      // max: 60,
      labels: {
        show: false,
        style: {
          colors: 'white',
          fontSize: '12px',
        },
      },
    },
    colors: ['white'],
    markers: {
      size: [2, 3],
      hover: {
        size: undefined,
        sizeOffset: 0.2,
      },
    },
  }
}
