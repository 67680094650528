import { useEffect, useState } from 'react'
import Nouislider from 'nouislider-react'
import Select from 'react-select'
import { multiSelectCustomStyles, MultiSelectOptionType } from '../../../utils/MultiSelect'
import { kFormatter, kUnformatterNumber } from '../../../utils/CurrencyFormatter'

type Props = {
  filter: any
  min?: number
  max?: number
  list?: string[]
  updateFilters: (name: string, min: number, max: number, list: string[]) => void
  appliedFilter: any
}

const tooltipFns = {
  to(value: number) {
    return kFormatter(value)
  },
  from(value: string) {
    return kUnformatterNumber(value)
  },
}

function FilterSelector({ filter: f, min, max, list, updateFilters, appliedFilter: af }: Props) {
  const [sliderRef, setSliderRef] = useState<any>(null)
  const [minValue, setMinValue] = useState<any>((af && af.fMin) || min)
  const [maxValue, setMaxValue] = useState<any>((af && af.fMax) || max! * 0.8)
  const [listValues, setListValues] = useState<string[]>(
    list && af && af.fList && af.fList.length > 0 ? list.filter((l) => af.fList.includes(l)) : list || []
  )

  useEffect(() => {
    updateFilters(f.name, minValue, maxValue, listValues)
  }, [minValue, maxValue, listValues])

  return f && f.type.indexOf('LIST') < 0 ? (
    <div className='fs-base'>
      <Nouislider
        instanceRef={(instance) => {
          if (instance && !sliderRef) {
            setSliderRef(instance)
          }
        }}
        tooltips={[tooltipFns, tooltipFns]}
        start={[(af && af.fMin) || min, (af && af.fMax) || max! * 0.8]}
        range={{
          min: af && min && af.fMin < min ? af.fMin : min,
          max: af && max && af.fMax > max ? af.fMax : max,
        }}
        connect
        onChange={(e) => {
          setMinValue(parseFloat(e[0]))
          setMaxValue(parseFloat(e[1]))
        }}
      />
      <div className='row my-4 mx-0 justify-content-between'>
        <input
          title={kFormatter(minValue)}
          onChange={(e) => {
            const min = parseFloat(e.target.value.replaceAll(',', '')) || 0
            setMinValue(min)
            sliderRef.noUiSlider.set([min, sliderRef.noUiSlider.get()[1]])
          }}
          value={Intl.NumberFormat('en-IN').format(minValue)}
          className='form-control form-control-sm w-100px'
          type='text'
          placeholder='Min value'
        />
        <input
          title={kFormatter(maxValue)}
          onChange={(e) => {
            const max = parseFloat(e.target.value.replaceAll(',', '')) || 0
            setMaxValue(max)
            sliderRef.noUiSlider.set([sliderRef.noUiSlider.get()[0], max])
          }}
          value={Intl.NumberFormat('en-IN').format(maxValue)}
          className='form-control form-control-sm w-100px'
          placeholder='Max value'
          type='text'
        />
      </div>
    </div>
  ) : (
    <div className='fs-base'>
      <Select
        options={list?.map(
          (m) =>
            ({
              value: m,
              label: m.charAt(0).toUpperCase() + m.slice(1).toLowerCase(),
            } as unknown as MultiSelectOptionType)
        )}
        value={listValues.map(
          (m) =>
            ({
              value: m,
              label: m.charAt(0).toUpperCase() + m.slice(1).toLowerCase(),
            } as unknown as MultiSelectOptionType)
        )}
        isMulti={true}
        onChange={(e: any) => setListValues(e.map((v: any) => v.value))}
        styles={multiSelectCustomStyles}
      />
    </div>
  )
}

export default FilterSelector
