import { AgGridReact } from 'ag-grid-react'
import { agConfigSideBar, tableCellStyle } from '../../../../utils/AgGridConfig'
import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { CellClickedEvent, ColDef } from 'ag-grid-community'

import {
  AllTransactionsType,
  CompanyObject,
  PrivateCaptableType,
  StageCompanyType,
  Transaction_Type,
  WatchlistObject,
} from '../../../../generated/graphql'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { GlobalContext } from '../../store/context/globalContext'
import { getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import {
  ActionRender,
  CinRender,
  CompanyNameRender,
  CompanyTypeRender,
  ReportFileRender,
} from './MyCaptablesAGComponent'
import { UploadCaptablePopup } from './UploadCaptablePopup'
import { CaptableFileUploadPopup } from './CaptableFileUploadPopup'

type AllPrivateCaptablesProps = {
  captables: PrivateCaptableType[]
}
export function MyCaptablesAGTable(props: AllPrivateCaptablesProps) {
  const gridApi = useRef<any>(null)
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const privateCaptableData: PrivateCaptableType[] =
    (props.captables as PrivateCaptableType[]) || []
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isFileModal, setIsFileModal] = useState<boolean>(false)
  const [stageType, setTransactionType] = useState<string>('stage_4')

  const componentsRegistry = useMemo(
    () => ({
      companyName: memo(CompanyNameRender),
      cin: memo(CinRender),
      file: memo(ReportFileRender),
      companyType: memo(CompanyTypeRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <div className='row justify-content-center  text-center py-8 m-6'>
      <img
        alt='no items'
        src={toAbsoluteUrl('/media/icons/duotune/general/empty-watchlist.svg')}
        className='h-150px my-10 w-350 justify-content-center'
      />
      <p className='fs-base text-muted text-center'>
        No private captable found, Get started by adding new one.
      </p>
    </div>
  )

  useEffect(() => {
    if (stageType) {
      setIsFileModal(true)
    }
  }, [stageType, isModalOpen])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Company Name',
        field: 'companyName',
        cellRenderer: 'companyName',
        cellStyle: tableCellStyle,
        sortable: true,
        pinned: 'left',
        // suppressSizeToFit: true,
        initialWidth: 350,
        // autoHeight: true,
        // wrapText: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipTransactionName',
      },
      {
        headerName: `CIN`,
        field: 'cin',
        cellRenderer: 'cin',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'Uploaded File',
        field: 'file',
        cellRenderer: 'file',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: `Company Type`,
        field: 'companyType',
        cellRenderer: 'companyType',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        headerName: '',
        field: 'actions',
        hide: false,
        width: 90,
        maxWidth: 90,
        filter: false,
        menuTabs: [],
        colId: 'action-column',
        suppressNavigable: true,
        suppressColumnsToolPanel: true,
        // cellStyle: { 'padding-top': '10px', 'line-height': '20px' },
        resizable: true,
        sortable: false,
        // cellRendererParams: ({ value }: { value: any }) => value.props,
        cellRenderer: ActionRender,
      },
    ],
    [privateCaptableData, userCurrencyFormat]
  )

  const rowData = useMemo(
    () =>
      privateCaptableData?.map((company: any) => {
        return {
          cin: company.cin,
          companyName: company.companyName,
          companyId: company.hissaCompanyId,
          file: company.fileUrl,
          companyType: company.companyType,
          userCurrencyFormat: userCurrencyFormat,
          userCurrency: userCurrency,
        }
      }),
    [privateCaptableData, userCurrencyFormat, privateCaptableData]
  )

  return (
    <div
      className='card p-0 h-575px'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          <h1 className='mb-0'>Cap Table</h1>
          <p className='text-muted mb-0'>
            {privateCaptableData.length > 0 && `${privateCaptableData.length} Companies`}
          </p>
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <button
            type='submit'
            onClick={() => setShowModal(!showModal)}
            className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal ms-4 text-white'
          >
            <KTSVG path='media/icons/duotune/files/fil009.svg' className='svg-icon-1 text-white' />{' '}
            Add Captable
          </button>
          {showModal && (
            <UploadCaptablePopup
              showModal={showModal}
              handleClose={() => {
                setShowModal(false)
                setIsModalOpen(false)
              }}
              setTransactionType={(type: string) => setTransactionType(type)}
              isOpen={(isOpen: boolean) => setIsModalOpen(isOpen)}
              stageType={stageType}
            />
          )}
          {isFileModal && isModalOpen && (
            <CaptableFileUploadPopup
              showModal={isFileModal}
              handleClose={() => {
                setIsModalOpen(false)
                setShowModal(true)
                setIsFileModal(false)
              }}
              isStageThree={stageType === 'stage_4' ? false : true}
              handleSubmit={() => setShowModal(false)}
              companyData={{} as CompanyObject}
              companyType={
                stageType === 'stage_4' ? StageCompanyType.Stage_4 : StageCompanyType.Stage_3
              }
            />
          )}
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 pt-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={false}
          enableRangeSelection={false}
          suppressRowTransform={true}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
