import { useRef } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { MisDataType } from '../../../../generated/graphql'
import { isoToDate } from '../../../../utils/FormatDate'
import { ShowTags } from '../../../../utils/Tags'
import { DynamicTableDataInputType } from '../wrappers/DynamicTablesPageWrapper'

type Props = {
  id: string
  reportName: string
  description: string
}

export function TableHeader(props: Props) {
  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>

  return (
    <div
      className='card h-md-85px h-full p-3 bg-growth-card text-white pb-3'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='row'>
        <div className='col-12 card h-full bg-growth-card pb-4 pt-1 pb-md-0'>
          <div className={'px-4 d-flex justify-content-between'}>
            <div className='my-2 border-4 border-start border-primary'>
              <p className='fs-2 p-0 m-0 ps-4'>{props.reportName}</p>
              <p className='fs-7 d-flex align-items-center text-gray-500 p-0 m-0 ps-4'>
                {props.description}
              </p>
            </div>
            <div className='d-flex align-items-center'></div>
          </div>
        </div>
      </div>
    </div>
  )
}
