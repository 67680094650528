import { create } from 'zustand'
import { configurePersist } from 'zustand-persist'

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: 'user',
})

const persist = _persist as any

type UserNameState = {
  userName: string
  setUserName: (value: string) => void
  reset: () => void
}

const initialState = {
  userName: '',
}

const userNameStore = create<UserNameState>(
  persist(
    {
      key: 'userDetails',
    },
    (set: any) => ({
      ...initialState,
      setUserName: (name: string) => {
        set((state: any) => ({ userName: name }))
      },
      reset: () => {
        set(() => initialState)
      },
    })
  )
)

export const useUserNameStore = userNameStore
