import { useContext } from 'react'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import { formatNumber, getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import { GlobalContext } from '../../store/context/globalContext'
import { ShowInfoPopover } from '../../../../utils/InfoPopover'

type HeaderCardProps = {
  iconPath: string
  value: number
  name: string
  indicator: string
  isInteger: boolean
  className?: string
  info?: string
  isCurrency?: boolean
}

export function HeaderCard(props: HeaderCardProps) {
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  return (
    <div
      className={`card h-225px p-6 pt-8 ${props.className}`}
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <KTSVG
        path={props.iconPath}
        className='ps-2 svg-icon-2x text-start'
        svgClassName='text-gray-700'
      />
      <div className='d-flex justify-content-start flex-column'>
        <span
          className='ps-2 pt-10 fw-semibold display-7'
          data-toggle='tooltip'
          data-placement='top'
          title={Number(props.value).toLocaleString(userCurrencyFormat)}
        >
          {props.isCurrency && getSymbolOfCurrency(userCurrency)}
          {formatNumber({
            value: props.value,
            format: userCurrencyFormat,
          }) + props.indicator}
        </span>
        <h6 className='text-muted ps-2 pt-0'>
          {props.name}
          {props.info && props.info !== '' && <span>{ShowInfoPopover(props.info)}</span>}
        </h6>
        {/* <div className='ps-2 pt-6'>
          {props.gainOrLoss > 0 ? (
            <GainBadge badgeValue={props.gainOrLoss} />
          ) : (
            <LossBadge badgeValue={props.gainOrLoss} />
          )}
        </div> */}
      </div>
    </div>
  )
}
