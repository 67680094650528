import { useEffect, useState } from 'react'
import {
  DeletePartnersGroupMutation,
  DeletePartnersGroupMutationVariables,
  FundStructure,
  FundUnit,
  GetFundUnitQuery,
  GetPartnersGroupQuery,
  PartnerGroup,
  PartnerType,
  useDeletePartnersGroupMutation,
  useGetFundStructureQuery,
  useGetFundUnitQuery,
  useGetPartnersGroupQuery,
} from '../../../../generated/graphql'
import AddPartnerModal from './AddPartnerModal'
import { KTSVG } from '../../../../_metronic/helpers'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'

type Props = {
  investorId: number
  fundStructure: FundStructure
}

function PartnerGroupTable({ fundStructure, investorId }: Props) {
  const [partnersGroup, setPartnersGroup] = useState<PartnerGroup[]>()
  const [selectedData, setSelectedData] = useState<PartnerGroup>()
  const [fundUnitData, setFundUnitData] = useState<FundUnit[]>()
  const [showPartnerGrpModal, setShowPartnerGrpModal] = useState(false)
  const [showEditPartnerGrpModal, setShowEditPartnerGrpModal] = useState(false)

  const partnerGroupData = useGetPartnersGroupQuery<GetPartnersGroupQuery, Error>(
    graphqlRequestClient,
    { input: { investorId: investorId, fundStructureId: fundStructure.id } },
    {},
    {}
  )

  const fundUnitDatas = useGetFundUnitQuery<GetFundUnitQuery, Error>(
    graphqlRequestClient,
    { input: { investorId: investorId, fundStructureId: fundStructure.id } },
    {},
    {}
  )

  useEffect(() => {
    if (fundUnitDatas?.data?.getFundUnit) {
      setFundUnitData(fundUnitDatas?.data?.getFundUnit as FundUnit[])
    }
  }, [fundUnitDatas?.data?.getFundUnit])

  useEffect(() => {
    if (partnerGroupData?.data?.getPartnersGroup) {
      setPartnersGroup(partnerGroupData?.data?.getPartnersGroup as PartnerGroup[])
    }
  }, [partnerGroupData?.data?.getPartnersGroup])

  const handleCloseEditPartnerGrp = () => {
    setShowEditPartnerGrpModal(!showEditPartnerGrpModal)
  }

  const mutateRemovePartnersGroup = useDeletePartnersGroupMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: DeletePartnersGroupMutation,
        _variables: DeletePartnersGroupMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(
          useGetFundStructureQuery.getKey({ input: { input: investorId } })
        )
        queryClient.invalidateQueries(
          useGetPartnersGroupQuery.getKey({
            input: { investorId: investorId, fundStructureId: fundStructure.id },
          })
        )
        queryClient.invalidateQueries(
          useGetFundUnitQuery.getKey({
            input: { investorId: investorId, fundStructureId: fundStructure.id },
          })
        )
        Toast(data.deletePartnersGroup || 'Removed Partner Group details !', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  function deletePartnersGroup(group: any) {
    mutateRemovePartnersGroup.mutate({
      input: {
        groupId: group.id,
        groupName: group.name,
        investorId: investorId,
        fundStructureId: fundStructure.id,
      },
    })
  }

  const getPartnersGrpCard = (partnersGroup: PartnerGroup[]) => {
    return (
      <div className='overflow-auto'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4 table-row-dashed border-gray-200'>
            <thead>{partnerGroupHeader()}</thead>
            <tbody>
              {partnersGroup?.map((group: PartnerGroup, index: number) => (
                <>
                  {group && (
                    <tr key={index} className=''>
                      <td className=''>
                        <p className='fs-base mb-0 fw-bold'>{group.name}</p>
                      </td>
                      <td>
                        <p className='fs-base mb-0 text-center'>
                          {PartnerType.LimitedPartner === group.groupType
                            ? 'Limited Partner'
                            : 'General Partner'}
                        </p>
                      </td>

                      <td className='text-end'>
                        <button
                          className='btn btn-sm btn-icon btn-secondary ms-md-2 mt-1 mt-md-0'
                          onClick={() => {
                            setShowEditPartnerGrpModal(true)
                            setSelectedData(group)
                          }}
                        >
                          <span className='svg-icon svg-icon-2 text-center'>
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-2'
                            />
                          </span>
                        </button>
                        <button
                          className='btn btn-sm btn-icon btn-secondary ms-md-2 mt-1 mt-md-0'
                          onClick={() => {
                            deletePartnersGroup(group)
                          }}
                        >
                          <span className='svg-icon svg-icon-2 text-center'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-2'
                            />
                          </span>
                        </button>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
          {partnersGroup && partnersGroup?.length == 0 ? (
            <p className='fs-base text-muted pt-4 text-center'>
              No Partner Groups were found in this fund.
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  }

  const partnersGroupTable = getPartnersGrpCard(partnersGroup!)

  return (
    <div className=''>
      <div className=''>{partnersGroupTable}</div>
      {showEditPartnerGrpModal && (
        <AddPartnerModal
          showModal={showEditPartnerGrpModal}
          handleClose={handleCloseEditPartnerGrp}
          investorId={investorId}
          fundStructure={fundStructure}
          isEdit={true}
          data={selectedData}
          fundUnitData={fundUnitData!}
        />
      )}
    </div>
  )
}

const partnerGroupHeader = () => {
  return (
    <tr className='fw-bold text-gray-600'>
      <th className='min-w-125px'>Group Name</th>
      <th className='min-w-125px text-center'>Group Type</th>
      <th className='min-w-125px '></th>
    </tr>
  )
}

export default PartnerGroupTable
