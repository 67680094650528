import { AgGridReact } from 'ag-grid-react'
import { memo, useContext, useMemo, useRef, useState } from 'react'
import { agConfigSideBar, tableCellStyle } from '../../../../../utils/AgGridConfig'
import { ColDef } from 'ag-grid-community'
import {
  AllTransactionsType,
  CompanyObject,
  IndividualInvestorCapTable,
  TransactionObject,
  Transaction_Type,
} from '../../../../../generated/graphql'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { GlobalContext } from '../../../store/context/globalContext'
import { getCurrency, getSymbolOfCurrency } from '../../../../../utils/CurrencyFormatter'
import {
  AmountRender,
  PostHoldingPercentageRender,
  PostHoldingSharesRender,
  PreHoldingPercentageRender,
  PreHoldingSharesRender,
  ShareHolderNameRender,
} from './PrePostAGComponents'
import _ from 'lodash'

type TransactionProps = {
  data?: IndividualInvestorCapTable[]
}

export function PrePostAGTable(props: TransactionProps) {
  const gridApi = useRef<any>(null)
  const [finalCaptableData, setFinalCaptableData] =
    useState<IndividualInvestorCapTable[]>(
      _.cloneDeep(props.data) as IndividualInvestorCapTable[]
    ) || []
  // let companyDetails: CompanyObject = props.companyData as CompanyObject
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  const componentsRegistry = useMemo(
    () => ({
      shareholderName: memo(ShareHolderNameRender),
      preHoldingPercentage: memo(PreHoldingPercentageRender),
      postHoldingPercentage: memo(PostHoldingPercentageRender),
      preHoldingShares: memo(PreHoldingSharesRender),
      postHoldingShares: memo(PostHoldingSharesRender),
      amountInvested: memo(AmountRender),
    }),
    []
  )

  const showNoRowsOverlay = () => (
    <div className='row justify-content-center  text-center py-8 m-6'>
      <p className='fs-base text-muted text-center'>No data Found.</p>
    </div>
  )

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      autoHeight: true,
      wrapHeaderText: true,
      suppressColumnVirtualisation: true,
      columnsMenuParams: {
        suppressColumnFilter: true,
      },
      filterParams: {
        buttons: ['reset'],
        maxNumConditions: 5,
      },
      minWidth: 150,
      filter: true,
      resizable: true,
      flex: 1,
    }),
    []
  )

  const [isColumnOpen, setIsColumnOpen] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  // const [dataToggle, setDataToggle] = useState('investments')
  // const [showModal, setShowModal] = useState<boolean>(false)

  const openToolPanel = (key: any) => {
    if (key === 'columns') {
      if (gridApi) {
        if (!isColumnOpen) gridApi?.current?.api?.openToolPanel(key)
        else gridApi?.current?.api?.closeToolPanel()
        setIsColumnOpen((state) => !state)
        setIsFilterOpen(false)
      }
    } else if (key === 'filters') {
      if (gridApi) {
        if (!isFilterOpen) {
          gridApi?.current?.api?.openToolPanel(key)
        } else gridApi?.current?.api?.closeToolPanel()
        setIsFilterOpen((state) => !state)
        setIsColumnOpen(false)
      }
    }
  }

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: 'Shareholder Name',
        field: 'shareholderName',
        cellRenderer: 'shareholderName',
        initialWidth: 250,
        sortable: true,
        autoHeight: true,
        cellStyle: tableCellStyle,
        wrapText: true,
        suppressSizeToFit: true,
        filter: 'agSetColumnFilter',
        menuTabs: ['filterMenuTab'],
        pinned: 'left',
        tooltipField: 'tooltipTermsheetName',
      },
      {
        headerName: 'Pre Holding (%)',
        field: 'preHoldingPercentage',
        cellRenderer: 'preHoldingPercentage',
        initialWidth: 250,
        filter: 'agNumberColumnFilter',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipPreHoldingPercentage',
      },
      {
        headerName: 'Post Holding (%)',
        field: 'postHoldingPercentage',
        cellRenderer: 'postHoldingPercentage',
        initialWidth: 250,
        filter: 'agNumberColumnFilter',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipPostHoldingPercentage',
      },
      {
        headerName: 'Pre Holding FDB Shares',
        field: 'preHoldingShares',
        cellRenderer: 'preHoldingShares',
        initialWidth: 250,
        filter: 'agNumberColumnFilter',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipPreHoldingShares',
      },
      {
        headerName: 'Post Holding FDB Shares',
        field: 'postHoldingShares',
        cellRenderer: 'postHoldingShares',
        initialWidth: 250,
        filter: 'agNumberColumnFilter',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipPostHoldingShares',
      },
      {
        headerName: `Amount Invested (${getSymbolOfCurrency(userCurrency)})`,
        field: 'amountInvested',
        cellRenderer: 'amountInvested',
        initialWidth: 250,
        filter: 'agNumberColumnFilter',
        cellStyle: tableCellStyle,
        sortable: true,
        menuTabs: ['filterMenuTab'],
        tooltipField: 'tooltipAmountInvested',
      },
    ],
    []
  )

  const rowData = useMemo(
    () =>
      finalCaptableData
        ?.filter(
          (template) =>
            template.postHoldingFdbPercentage !== 0 && template.postHoldingFdbShares !== 0
        )
        ?.sort((a, b) => b.amountInvestedInRound - a.amountInvestedInRound)
        ?.map((template) => ({
          id: template.id,
          shareholderName: template.shareholderName.toLowerCase().includes('esop')
            ? template.shareholderName.replace(/esop/gi, 'ESOP')
            : template.shareholderName,
          preHoldingPercentage:
            template.preHoldingFdbPercentage && template.preHoldingFdbPercentage * 100,
          postHoldingPercentage:
            template.postHoldingFdbPercentage && template.postHoldingFdbPercentage * 100,
          preHoldingShares: template.preHoldingFdbShares,
          postHoldingShares: template.postHoldingFdbShares,
          amountInvested:
            Number(template.preHoldingFdbShares) === Number(template.postHoldingFdbShares)
              ? 0
              : template.amountInvestedInRound,
          tooltipAmountInvested:
            template.amountInvestedInRound &&
            template.amountInvestedInRound.toLocaleString(userCurrencyFormat),
          tooltipPreHoldingPercentage: (
            template.preHoldingFdbPercentage && template.preHoldingFdbPercentage * 100
          ).toLocaleString(userCurrencyFormat),
          tooltipPostHoldingPercentage: (
            template.postHoldingFdbPercentage && template.postHoldingFdbPercentage * 100
          ).toLocaleString(userCurrencyFormat),
          tooltipPreHoldingShares:
            template.preHoldingFdbShares &&
            Number(template.preHoldingFdbShares).toLocaleString(userCurrencyFormat),
          tooltipPostHoldingShares:
            template.postHoldingFdbShares &&
            Number(template.postHoldingFdbShares).toLocaleString(userCurrencyFormat),
          userCurrency,
          userCurrencyFormat,
        })),
    [finalCaptableData]
  )

  function setPinnedBottomRowData({ api }: { api: any }) {
    const data = api.rowModel.rootNode
    const filteredData = data.childrenAfterAggFilter.map((element: any) => element.data)

    const totalPreHoldingPercentage = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.preHoldingPercentage,
      0
    )
    const totalPostHoldingPercentage = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.postHoldingPercentage,
      0
    )
    const totalPreHoldingShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + Number(data.preHoldingShares),
      0
    )
    const totalPostHoldingShares = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + Number(data.postHoldingShares),
      0
    )
    const totalInvested = filteredData?.reduce(
      (accumulator: any, data: any) => accumulator + data.amountInvested,
      0
    )
    api.setPinnedBottomRowData([
      {
        shareholderName: 'Total',
        preHoldingPercentage: totalPreHoldingPercentage,
        postHoldingPercentage: totalPostHoldingPercentage,
        preHoldingShares: totalPreHoldingShares,
        postHoldingShares: totalPostHoldingShares,
        amountInvested: totalInvested,
        tooltipAmountInvested: totalInvested.toLocaleString(userCurrencyFormat),
        tooltipPreHoldingPercentage: totalPreHoldingPercentage.toLocaleString(userCurrencyFormat),
        tooltipPostHoldingPercentage: totalPostHoldingPercentage.toLocaleString(userCurrencyFormat),
        tooltipPreHoldingShares: totalPreHoldingShares.toLocaleString(userCurrencyFormat),
        tooltipPostHoldingShares: totalPostHoldingShares.toLocaleString(userCurrencyFormat),
        userCurrency,
        userCurrencyFormat,
      },
    ])
  }

  return (
    <div className='card h-525px mt-md-0 overflow-auto'>
      <div className='d-flex flex-row border-0 p-9'>
        <div className='card-title align-items-end flex-column'>
          <h1 className='m-0'>Cap Table</h1>
        </div>
        <div className='col d-flex flex-row justify-content-end'>
          <button
            type='submit'
            value={'Columns'}
            onClick={() => openToolPanel('columns')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/layouts/lay004.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Columns
          </button>
          <button
            value={'Filters'}
            onClick={() => openToolPanel('filters')}
            className='px-4 rounded border border-1 border-secondary-txt bg-transparent h-38px fw-normal ms-4 text-hover-primary'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen031.svg'
              className='svg-icon-3 text-secondary-txt'
            />{' '}
            Filter
          </button>
        </div>
      </div>
      <div className='card-body w-100 p-9 py-0 overflow-auto ag-theme-material h-100'>
        <AgGridReact
          sideBar={agConfigSideBar}
          onGridReady={(params) => {
            gridApi.current = params
          }}
          components={componentsRegistry}
          alwaysShowHorizontalScroll
          alwaysMultiSort
          animateRows={true}
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={false}
          enableCharts={true}
          enableRangeSelection={false}
          suppressRowTransform={true}
          onRowDataUpdated={setPinnedBottomRowData}
          suppressCopyRowsToClipboard={true}
          suppressCopySingleCellRanges={true}
          suppressCellFocus={true}
          noRowsOverlayComponent={showNoRowsOverlay}
          suppressMenuHide={false}
          tooltipShowDelay={50}
          tooltipInteraction={true}
          rowClass={'border-right-0 border-left-0 border-dashed'}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow; margin-top: 50px;">No Rows To Show</span>'
          }
          getRowStyle={(params) => {
            if (params.rowIndex % 2 === 0) {
              return { background: '#f8f8f8' }
            } else {
              return { background: '#ffffff' }
            }
          }}
        ></AgGridReact>
      </div>
    </div>
  )
}
