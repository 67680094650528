import { useEffect, useState } from 'react'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { formatNumber } from '../../../../utils/CurrencyFormatter'
import { isoToDate } from '../../../../utils/FormatDate'
import { hexToRGB } from '../../../../utils/HexToRgba'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../_metronic/helpers'
import ManageInvestmentTags from './ManageInvestmentTags'
import { CompanyTypes } from '../../../../generated/graphql'
import RemoveTransactionModal from '../../manual-investments/components/RemoveTransaction/RemoveTransactionModal'
import ManageDocumentModal from './TransactionDocumentsUpload'
import { AddDirectTransactionModal } from '../../manual-investments/components/AddAdditionalDirectTransaction/AddDirectTransactionModal'
import ManageTransactionDocumentModal from './ManageTransactionDocument'

export function InvestorNameRender(data: any) {
  return (
    <div className='pt-2'>
      <p className='fw-bold'> {data.data.investorName}</p>
    </div>
  )
}

export function TransactionTypeRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.transactionType}</p>
    </div>
  )
}

export function RoundNameRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.roundName}</p>
    </div>
  )
}
export function AmountInvestedRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.transactionAmount,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function SecuritiesRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.security}</p>
    </div>
  )
}
export function DateOfInvestmentRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.dateOfInvestment ? isoToDate(data.data.dateOfInvestment) : '-'}</p>
    </div>
  )
}
export function ShareDistinctiveRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.shareDistinctiveNumbers}</p>
    </div>
  )
}
export function NoOfSharesRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.noOfShares,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function TagsRender(data: any) {
  return (
    <div className='py-2'>
      {data.data.tags &&
        data.data.tags.map((tag: string, index: number) => {
          return (
            <span
              key={index}
              className={`badge fs-9 fw-semibold mb-1 me-2 `}
              style={{
                background: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 0.2),
                color: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 1),
              }}
            >
              {tag}
            </span>
          )
        })}
    </div>
  )
}

export function TransactionActionRender(data: any) {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDocumentModal, setShowDocumentModal] = useState(false)
  const [showTagsModal, setShowTagsModal] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [data.data.transactionDetails])

  const setShowModalFunction = (state: boolean) => setShowTagsModal(state)
  const manualOptions = [
    'Delete Transaction',
    'Edit Transaction',
    'Manage Tags',
    'Manage Documents',
  ]
  const hissaOptions = ['Manage Tags', 'Manage Documents']
  const actionMenu = () => {
    let newOptions =
      data.data.companyDetails.type === CompanyTypes.Hissa ? hissaOptions : manualOptions
    return newOptions.map((option) => {
      return (
        <label
          key={option}
          className='form-check-custom py-3 bg-hover-primary text-hover-white'
          onClick={() => {
            if (option === 'Manage Tags') {
              setShowTagsModal(true)
            } else if (option === 'Delete Transaction') {
              setShowDeleteModal(true)
            } else if (option === 'Edit Transaction') {
              setShowEditModal(true)
            } else if (option === 'Manage Documents') {
              setShowDocumentModal(true)
            }
          }}
        >
          <span className='form-check-label fs-base ms-6 fw-normal cursor-pointer'>{option}</span>
        </label>
      )
    })
  }

  return (
    <div className='px-0'>
      {data.data.investorName !== 'Total' && data.data.dateOfInvestment !== null && (
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
      )}
      {showTagsModal && (
        <ManageInvestmentTags
          companyDetails={data.data.companyDetails}
          hissaCompanyId={data.data.companyDetails.hissaCompanyId}
          investorId={data.data.transactionDetails.investorId}
          tags={data.data.tags}
          showModal={showTagsModal}
          setShow={setShowModalFunction}
          investmentId={data.data.transactionDetails.id}
          hissaRecordId={data.data.transactionDetails.hissTransactionId}
          transactionDetails={data.data.transactionDetails}
        />
      )}
      {showDeleteModal && (
        <RemoveTransactionModal
          showModal={showDeleteModal}
          handleClose={() => {
            setShowDeleteModal(false)
          }}
          investmentId={data.data.transactionDetails.id}
          investorId={data.data.transactionDetails.investorId}
          isLastInvestment={data.data.isLastInvestment}
          companyDetails={data.data.companyDetails}
          isIndirect={false}
        />
      )}
      {showEditModal && (
        <AddDirectTransactionModal
          showModal={showEditModal}
          handleClose={() => {
            setShowEditModal(false)
          }}
          isCompanyExist={true}
          companyData={data.data.companyDetails}
          handleSubmit={() => setShowEditModal(false)}
          isEdit={true}
          transactionData={data.data.transactionDetails}
        />
      )}
      {showDocumentModal && (
        <ManageTransactionDocumentModal
          show={showDocumentModal}
          handleClose={() => {
            setShowDocumentModal(false)
          }}
          hissaVerified={data.data.companyDetails?.type === CompanyTypes.Hissa ? true : false}
          folder={'Transaction'}
          isIndirect={false}
          transactionData={data.data.transactionDetails}
          companyData={data.data.companyDetails}
        />
      )}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
        data-kt-menu='true'
      >
        {actionMenu()}
      </div>
    </div>
  )
}
