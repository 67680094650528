import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  FundStructure,
  FundUnit,
  UpdateFundUnitNavMutation,
  UpdateFundUnitNavMutationVariables,
  useGetFundStructureQuery,
  useGetFundUnitQuery,
  useUpdateFundUnitNavMutation,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { IUpdateFundNaV, updateFundNavSchema } from './UpdateNavHelper'
import { isoToDate } from '../../../../utils/FormatDate'
import { InputBox } from '../../../../utils/NumberInputBox'

type Props = {
  fundStructure: FundStructure
  investorId: number
  handleClose: VoidFunction
  data?: FundUnit
}

function UpdateFundUnitForm(props: Props) {
  const initialData: IUpdateFundNaV = {
    navDate: props.data?.navDate?.split('T')[0],
    nav: props.data?.nav || 1,
  }
  const [data, setData] = useState<IUpdateFundNaV>(_.cloneDeep(initialData))
  const [loading, setLoading] = useState(false)
  const [navList, setNavList] = useState<any>([])
  const [dateError, setDateError] = useState(false)
  let maxDate = new Date().toISOString().split('T')[0]
  let minDate = new Date(props.fundStructure.inceptionDate).toISOString().split('T')[0]

  useEffect(() => {
    setNavList(props.data?.navTransactions)
  }, [props.data?.navTransactions])

  const mutateUpdateFundUnitNav = useUpdateFundUnitNavMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: UpdateFundUnitNavMutation,
        _variables: UpdateFundUnitNavMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(useGetFundStructureQuery.getKey({ input: props.investorId }))
        queryClient.invalidateQueries(
          useGetFundUnitQuery.getKey({
            input: { investorId: props.investorId, fundStructureId: props.fundStructure.id },
          })
        )
        Toast('Fund Unit NAV updated Successfully!', ToastType.success)
        return console.log('Fund Unit NAV updated Successfully', data)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  const formik = useFormik<IUpdateFundNaV>({
    initialValues: data,
    validationSchema: updateFundNavSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        mutateUpdateFundUnitNav.mutate({
          input: {
            fundUnitId: props.data?.id,
            date: new Date(data.navDate).toISOString(),
            fundStructureId: props.fundStructure.id,
            investorId: props.investorId,
            nav: data.nav,
          },
        })
        setLoading(false)
        props.handleClose()
      }, 1000)
    },
  })

  const NavRecordTableHeader = () => {
    return (
      <tr className='fw-bold text-gray-600 '>
        <th className='fs-5 min-w-150px'>NAV</th>
        <th className='fs-5 min-w-125px text-center'>NAV Date</th>
      </tr>
    )
  }

  useEffect(() => {
    if (
      (minDate && formik.values.navDate && minDate > formik.values.navDate) ||
      maxDate < formik.values.navDate
    ) {
      setDateError(true)
    } else if (
      (minDate && formik.values.navDate && minDate < formik.values.navDate) ||
      maxDate > formik.values.navDate
    ) {
      setDateError(false)
    }
  }, [minDate, formik.values.navDate])

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='w-100 mx-auto'>
        <div className='row'>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>Current NAV</label>
            <InputBox
              isDisabled={false}
              name={'nav'}
              handleChange={(value: number) => {
                formik.setFieldValue('nav', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('nav', value)
              }}
              placeholder='Enter NAV'
              currency={''}
              value={formik.values.nav}
              isCurrency={true}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.nav && formik.errors.nav && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.nav}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-6 col-12 col-sm-6 col-md-4 pe-4'>
            <label className='form-label required'>NAV Date</label>

            <input
              type='date'
              placeholder=''
              max={maxDate}
              className='form-control custom-input fw-bold shadow-lg h-38px'
              {...formik.getFieldProps('navDate')}
            />
            <div className='text-danger mt-2'>
              {formik.touched && dateError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{`Select date between ${isoToDate(
                    minDate
                  )} & ${isoToDate(maxDate)}`}</div>
                </div>
              )}
            </div>

            <div className='text-danger mt-2'>
              {formik.touched.navDate && formik.errors.navDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.navDate}</div>
                </div>
              )}
            </div>
          </div>

          {navList?.length > 1 && (
            <div>
              <label className='pb-2 pt-4 fw-bold'>NAV Update History</label>
              <div className='col-md-12 pe-10 d-flex justify-content-left pt-2 pb-4'>
                <div className='table-responsive'>
                  <div className='col card mh-350px border border-gray-300 p-4 overflow-auto'>
                    <table className='table align-middle gs-0 gy-4 table-row-dashed border-gray-200'>
                      <thead className='border-bottom'>{NavRecordTableHeader()}</thead>
                      <tbody>
                        {navList?.map((navData: any, index: number) => (
                          <>
                            {navData && (
                              <tr key={index} className=''>
                                <td className=''>
                                  <p className='fs-base mb-0 fw-bold'>{navData.nav}</p>
                                </td>
                                <td>
                                  <p className='fs-base mb-0 text-center'>
                                    {isoToDate(navData.date)}
                                  </p>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className='d-flex justify-content-between pt-10'>
            <button
              type='button'
              className='btn btn-lg btn-secondary me-3'
              onClick={props.handleClose}
            >
              Cancel
            </button>
            <button type='submit' className='btn btn-primary' disabled={loading || dateError}>
              {!loading && 'Update NAV'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default UpdateFundUnitForm
