import { useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers/AssetHelpers'

function CompanyErrorPage() {
  const navigate = useNavigate()
  return (
    <div className='overlay-layer bg-opacity-100 justify-content-center'>
      <div className='text-center px-10'>
        <img
          src={toAbsoluteUrl('/media/icons/duotune/general/no-files.svg')}
          className='h-100px my-10'
          alt=''
        />
        <h2>Error fetching company details</h2>
        <button
          onClick={() => navigate(-1)}
          type='button'
          className='btn btn-primary btn-sm m-1'
          data-kt-stepper-action='previous'
        >
          Back
        </button>
      </div>
    </div>
  )
}

export default CompanyErrorPage
