import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Dropzone, {
  IInputProps,
  ILayoutProps,
  IPreviewProps,
  IUploadParams,
} from 'react-dropzone-uploader'
import {
  postInvestorDocuments,
  postCompanyDocuments,
  postTransactionDocuments,
} from '../../../../api/investorApi'
import {
  CompanyObject,
  Document_Vault,
  DocumentsType,
  TransactionObject,
  useGetCompanyDataQuery,
  useGetCompanyWiseDataQuery,
} from '../../../../generated/graphql'
import { queryClient } from '../../../../queries/client'
import { KTSVG } from '../../../../_metronic/helpers'
import { DocumentsTable } from './TransactionDocumentsView'
import { getAuth } from '../../../modules/auth'
import Toast, { ToastType } from '../../../../utils/toast'

type Props = {
  handleClose: Function
  show: boolean
  hissaVerified: boolean
  folder: string
  vaultType?: Document_Vault
  isIndirect: boolean
  transactionData?: TransactionObject
  companyData?: CompanyObject
}

const ManageTransactionDocumentModal = (props: Props) => {
  const auth = getAuth()!
  const [fileUpload, setFileUpload] = useState(true)

  const transactionDocuments: DocumentsType[] | undefined =
    props.transactionData && (props.transactionData.documents as unknown as DocumentsType[])

  let investmentDocuments: any[] = []
  transactionDocuments?.map((document) => {
    if (document) {
      investmentDocuments.push(document)
    }
  })

  const getUploadParams = async ({ meta, file }: any) => {
    if (file.size > 10000000) {
      Toast('Documents size must be less than 10 MB', ToastType.error)
      const ret: IUploadParams = {
        url: '',
        body: '',
        method: 'put',
      }
      return ret
    } else {
      const formData = new FormData()
      formData.append('file', file)
      let tags: string[] = []
      tags.push(props.transactionData?.round || '')
      tags.push(props.isIndirect ? 'INDIRECT' : 'INVESTMENT')
      tags.push(props.companyData?.name || '')

      const uploadUrl = await postTransactionDocuments(auth, [
        {
          hissaVerified: props.hissaVerified,
          hissaCompanyId: props.companyData?.hissaCompanyId || props.companyData?.id,
          hissaRecordId: props.transactionData?.hissTransactionId || props.transactionData?.id,
          investmentId: props.transactionData?.id,
          companyName: props.companyData?.name,
          filename: file.name,
          cin: props.companyData?.cin,
          tags: tags,
          isIndirect: props.isIndirect,
          size: meta.size,
          transaction: true,
          investorId: Number(props.transactionData?.investorId),
        },
      ])

      const ret: IUploadParams = {
        url: uploadUrl.data,
        body: formData,
        method: 'put',
      }
      return ret
    }
  }

  type data = {
    cin: string
    investorId?: number
    key: string
    name: string
    size: number
    tags: string[]
    userId?: number
  }[]

  const upload = async (documents: data) => {
    if (props.vaultType === 'INVESTOR') {
      const data = await postInvestorDocuments(documents)
    } else if (props.vaultType === 'COMPANY') {
      const data = await postCompanyDocuments(props.companyData?.cin || '', documents)
    }
  }

  const handleChangeStatus = ({ meta }: any, status: any, xhr: any) => {
    if (meta.status === 'done') {
      if (props.companyData) {
        const compKey = {
          input: {
            type: props.companyData?.type,
            hissaCompanyId: props.companyData?.hissaCompanyId || '',
            id: Number(props.companyData?.id) || 0,
          },
        }
        const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
        queryClient.invalidateQueries(getCompanyWiseQuery)
      }
      const getcompanyDataQuery = useGetCompanyDataQuery.getKey()
      queryClient.invalidateQueries(getcompanyDataQuery)
      Toast('Documents Uploaded Successfully!', ToastType.success)
    }
  }

  const Preview = ({ meta, fileWithMeta }: IPreviewProps) => {
    const { name, percent, status, size, validationError } = meta
    return (
      <div className='dropzone dropzone-queue p-9 pt-0'>
        <div className='dropzone-items'>
          <div className='dropzone-item p-2 dz-image-preview'>
            <div className='dropzone-file'>
              <div className='dropzone-filename' title={name}>
                <span className='fs-base fw-bold'>{name}</span>{' '}
                <strong>
                  (
                  <span>
                    {size > 1000000
                      ? (size / 1048576).toFixed(2) + ' MB'
                      : (size / 1024).toFixed(2) + ' KB'}
                  </span>
                  )
                </strong>
              </div>
              <div className={`${percent === 100 ? 'text-success' : 'dropzone-error'}`}>
                {status}
              </div>
            </div>
            <div className='dropzone-progress'>
              <div className='progress'>
                <div
                  className={`${status === 'done' ? 'bg-success' : 'bg-primary'}`}
                  role='progressbar'
                  aria-valuemin={0}
                  aria-valuemax={100}
                  aria-valuenow={percent}
                  data-dz-uploadprogress
                  style={{ width: percent + '%' }}
                ></div>
              </div>
            </div>
            <div className='dropzone-toolbar'>
              {status != 'done' ? (
                <span className='dropzone-delete' data-dz-remove>
                  <span
                    className='btn btn-icon btn-sm btn-active-light-primary'
                    onClick={() => {
                      fileWithMeta.remove()
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr061.svg'
                    />
                  </span>
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
  const Input = ({ accept, onFiles, files, getFilesFromEvent }: IInputProps) => {
    const text = files.length > 0 ? 'Add more files' : 'Choose files'

    const fileList = files.map((file) => (
      <h6 key={file.file.name}>
        {file.file.name} -{' '}
        {file.file.size > 1000000
          ? (file.file.size / 1000000).toFixed(2) + ' MB'
          : (file.file.size / 1000).toFixed(2) + ' KB'}
      </h6>
    ))

    return (
      <div className=''>
        <label className='btn btn-outline btn-outline-dashed btn-outline-default bg-opacity-10 py-12 d-flex justify-content-center m-9'>
          <input
            style={{ display: 'none' }}
            type='file'
            accept={accept}
            onChange={async (e) => {
              let file = await getFilesFromEvent(e)
              onFiles(file)
            }}
            multiple={true}
          />

          <div className='text-center'>
            <KTSVG
              path='/media/icons/duotune/general/gen041.svg'
              className={`svg-icon-3x me-5 text-gray-600 text-hover-primary`}
            />
            <h2 className='text-gray-600 my-2'>Drag & drop or click to choose files</h2>

            <h3 className='fw-bold text-gray-700'>
              {' '}
              <KTSVG
                path='/media/icons/duotune/general/gen045.svg'
                className={`svg-icon-2x text-gray-700`}
              />
              Max file size: 10 MB
            </h3>
          </div>
        </label>
      </div>
    )
  }

  const Layout = ({
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  }: ILayoutProps) => {
    return (
      // <div className=''>
      <div {...dropzoneProps} className=''>
        {input}

        {previews}
      </div>
    )
  }
  return (
    <Modal
      id='kt_header_search_modal'
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered h-auto mw-800px '
      show={props.show}
      centered
      animation={true}
      backdrop={true}
      onHide={() => {
        props.handleClose()
      }}
    >
      <Modal.Header className='p-9'>
        <Modal.Title className='fs-1 fw-bold'>Manage Documents</Modal.Title>
        <div className='row justify-content-end'>
          <button
            className='btn btn-sm btn-icon'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => {
              props.handleClose()
              // setSuccessMessage('')
              // setErrorMessage('')
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className='px-9'>
        {fileUpload && (
          <div
            className='card rounder shadow-sm mb-4'
            style={{
              boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
              border: '1',
              borderColor: '#F1F1F2',
            }}
          >
            <Modal.Title className='fs-1 fw-bold p-9 pb-0'>Upload Files</Modal.Title>
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              multiple={true}
              accept={
                'application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf,.png,.jpeg,.jpg,'
              }
              PreviewComponent={Preview}
              maxSizeBytes={100000000}
              InputComponent={Input}
              autoUpload={true}
              LayoutComponent={Layout}
            />
          </div>
        )}
        <DocumentsTable
          companyName={props.companyData?.name}
          cin={props.companyData?.cin}
          companyId={props.companyData?.id}
          investmentId={Number(props?.transactionData?.id)}
          investorId={props?.transactionData?.investorId}
          investorName={props?.transactionData?.investorName}
          folder={props?.folder}
          tableData={investmentDocuments}
          handleClose={props?.handleClose}
          isIndirect={props.isIndirect}
          companyData={props.companyData}
        />
      </Modal.Body>
    </Modal>
  )
}

export default ManageTransactionDocumentModal
