// import { useFormik } from 'formik'
// import { useState } from 'react'
// import { Modal, ModalBody, ModalHeader } from 'react-bootstrap'

// import { KTSVG } from '../../../../_metronic/helpers'
// import { postInvestmentModellingDetails } from '../../../../api/investorApi'
// import { storingInvestmentModellingDetails } from '../../../../api/investorApi'

// import {
//   emailInvestmentModellingInits,
//   emailInvestmentModellingSchema,
// } from './EmailInvestmentModellingHelper'
// import Toast, { ToastType } from '../../../../utils/toast'

// type Props = {
//   showModal: boolean
//   handleClose: () => void
//   investmentModellingData: any
//   investorId: string
// }

// function EmailInvestmentModelling({
//   handleClose,
//   showModal,
//   investmentModellingData,
//   investorId,
// }: Props) {
//   const [showAdditionalField, setShowAdditionalField] = useState<boolean>(false)
//   const [email, setEmail] = useState('')

//   const formik = useFormik({
//     initialValues: emailInvestmentModellingInits,
//     validationSchema: emailInvestmentModellingSchema,
//     onSubmit: async (values) => {},
//   })

// async function handleClick() {
//   const sharedModelId = await storingInvestmentModellingDetails(
//     investmentModellingData,
//     investorId
//   )

//     await postInvestmentModellingDetails(sharedModelId, email, investorId)
//     Toast('mail sent Successfully', ToastType.success)
//   }
//   return (
//     <Modal
//       id='kt_modal_email_termsheet'
//       tabIndex={-1}
//       aria-hidden='true'
//       dialogClassName='modal-dialog modal-dialog-centered mw-700px '
//       show={showModal}
//       onHide={() => {
//         handleClose()
//         setShowAdditionalField(false)
//       }}
//     >
//       <ModalHeader>
//         <h2 className='fw-bolder text-dark'>Email Investment Modelling</h2>
//         <button
//           className='btn btn-sm btn-icon btn-active-light-primary'
//           type='button'
//           data-bs-toggle='tooltip'
//           title='Close'
//           onClick={() => {
//             formik.resetForm()
//             setEmail('')
//             setShowAdditionalField(false)
//             handleClose()
//           }}
//         >
//           <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
//         </button>
//       </ModalHeader>
//       <ModalBody>
//         <form onSubmit={formik.handleSubmit}>
//           <div className='mb-5'>Enter Email ID to whom it has to sent </div>
//           <div className='d-flex mb-5'>
//             <div className='col-6 me-3'>
//               <label className='form-label'>Email ID</label>
//               <input
//                 type='email'
//                 className='form-control form-control-solid form-control-sm'
//                 placeholder='Enter Email ID'
//                 onChange={(e) => {
//                   setEmail(e.target.value)
//                   formik.setFieldValue('email', e.target.value)
//                 }}
//               />
//               <div className='text-danger mt-2'>
//                 {formik.errors.email && formik.touched.email && (
//                   <div className='fv-plugins-message-container'>
//                     <div className='fv-help-block'>{formik.errors.email}</div>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//           <div className='mb-5'></div>
//           <div className='d-flex justify-content-between'>
//             <button
//               type='button'
//               className='btn btn-secondary'
//               onClick={() => {
//                 formik.resetForm()
//                 setEmail('')
//                 setShowAdditionalField(!showAdditionalField)
//                 handleClose()
//               }}
//             >
//               Cancel
//             </button>
//             <button
//               type='submit'
//               className='btn btn-primary'
//               disabled={formik.errors.email !== undefined}
//               onClick={(e) => {
//                 handleClick()
//                 handleClose()
//               }}
//             >
//               Send
//             </button>
//           </div>
//         </form>
//       </ModalBody>
//     </Modal>
//   )
// }

// export default EmailInvestmentModelling
import { ChangeEvent, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import {
  AddTagsToInvestmentMutation,
  AddTagsToInvestmentMutationVariables,
  CompanyObject,
  TransactionObject,
  useAddTagsToTransactionMutation,
  useGetCompanyWiseDataQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { selectCustomStyles } from '../../../../utils/Select'
import { SelectOptionType } from '../../../../utils/MultiSelect'

type Props = {
  showModal: boolean
  handleClose: () => void
  investmentModellingData: any
  investorId: string
}

function EmailInvestmentModelling({
  handleClose,
  showModal,
  investmentModellingData,
  investorId,
}: Props) {
  const [values, setValues] = useState<any>()
  const [inputText, setInputText] = useState<string>('')
  const [customEmail, setCustomEmail] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)
  const [isEqual, setIsEqual] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [removeBadge, setRemoveBadge] = useState<string>('')

  const [emailIdList, setEmailIdList] = useState<SelectOptionType[]>([
    { value: 'Add New', label: 'Add New' },
  ])

  const handleChange = (e: any) => {
    setValues(e)

    e?.map((ele: any) => {
      if (ele.value === 'Add New') {
        setValues((values: any) => {
          return values?.filter((element: any) => element?.value !== 'Add New')
        })
        setCustomEmail((customEmail) => {
          return !customEmail
        })
      }
    })
  }

  async function handleClick() {
    // const sharedModelId = await storingInvestmentModellingDetails(
    //   investmentModellingData,
    //   investorId
    // )
  }
  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
    if (e.target.value && e.target.value.length > 0) {
      values.map((badge: any) => {
        if (badge.value === e.target.value) {
          setDisable(true)
          setErrorMessage('Multiple tags with the same name are not allowed ')
          setIsEqual(true)
        } else {
          setDisable(false)
          setErrorMessage('')
          setIsEqual(false)
        }
      })
    }
  }

  return (
    <Modal
      id='kt_header_search_modal'
      aria-hidden='true'
      tabIndex={-1}
      dialogClassName='modal-dialog modal-dialog-centered h-auto mw-800px '
      show={showModal}
      centered
      animation={true}
      backdrop={true}
    >
      <Modal.Header className='p-9 pb-2'>
        <Modal.Title className='fs-1 text-gray-heading'>
          <div>Share Modelling </div>
        </Modal.Title>

        <button
          className='btn btn-sm btn-icon btn-active-light-primary '
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={() => {
            handleClose()
          }}
        >
          <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
        </button>
      </Modal.Header>
      <Modal.Body className='p-9'>
        <div className='col fv-md-row d-md-flex align-items-baseline'>
          <div>
            <label className='d-flex align-items-center fs-5 fw-medium mb-4'>Email-id</label>
          </div>
          <div className='col-12 col-md-8 px-9'>
            {customEmail === false ? (
              <Select
                className=''
                options={emailIdList}
                placeholder={'--Select--'}
                value={values}
                isMulti={true}
                onChange={(e: any) => handleChange(e)}
                styles={selectCustomStyles}
              />
            ) : (
              <div className='d-flex'>
                <div className='me-4'>
                  <input
                    className='form-control form-control-solid me-2'
                    placeholder='Enter Tag Name'
                    onChange={handleChangeValue}
                  />
                </div>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                  type='submit'
                  disabled={disable}
                  onClick={(e) => {
                    setValues((values: any) => [...values, { value: inputText, label: inputText }])
                    setCustomEmail(false)
                  }}
                >
                  <KTSVG
                    className='svg-icon-1 text-primary'
                    path='/media/icons/duotune/arrows/arr012.svg'
                  />
                </button>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                  onClick={() => {
                    setCustomEmail(false)
                    setErrorMessage('')
                  }}
                >
                  <KTSVG
                    className='svg-icon-1 text-primary'
                    path='/media/icons/duotune/arrows/arr061.svg'
                  />
                </button>
              </div>
            )}
            <div className='text-danger mt-2'>
              {isEqual && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{errorMessage}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='p-9 border-0'>
        <div className='d-flex justify-content-between w-100'>
          <div className='justify-content-start'>
            <button
              type='button'
              className='btn btn-secondary align-items-lg-start'
              onClick={() => {
                handleClose()
              }}
            >
              Cancel
            </button>
          </div>
          <div className='justify-content-end'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={false}
              onClick={(e) => {
                handleClick()
                handleClose()
              }}
            >
              Send
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default EmailInvestmentModelling
