import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'

import _ from 'lodash'
import Select from 'react-select'
import { selectCustomStyles } from '../../../../../utils/Select'
import {
  MultiSelectOptionType,
  SelectOptionType,
  multiSelectCustomStyles,
} from '../../../../../utils/MultiSelect'

import {
  AllAccountOfUserQuery,
  CompanyObject,
  GetGlobalCompaniesQuery,
  GlobalCompanySearchType,
  IndirectTransactionObject,
  SearchQuery,
  useAddIndirectTransactionMutation,
  useAllAccountOfUserQuery,
  useEditIndirectTransactionMutation,
  useGetAllTransactionsQuery,
  useGetCompanyWiseDataQuery,
  useGetGlobalCompaniesQuery,
  useSearchQuery,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import Toast, { ToastType } from '../../../../../utils/toast'
import { SearchCompanyResultType } from '../../../../../types/SearchCompanyResultType'
import CreatableSelect from 'react-select/creatable'

import { InvestorListType } from '../../../company/views/HissaCompanySummaryPage'
import { IndirectTransactionData, addIndirectTransactionSchemas } from './IndirectTransactionHelper'
import { currencies, currencyTypes } from '../../../../../utils/CurrencyList'
import { countryData } from '../../../../../utils/CountryData'
import {
  getCurrency,
  getKeyByValue,
  getSymbolOfCurrency,
} from '../../../../../utils/CurrencyFormatter'
import { InputBox } from '../../../../../utils/NumberInputBox'

type Props = {
  investorList: InvestorListType[]
  handleClose: () => void
  isCompanyExist: boolean
  companyName?: string
  cin?: string
  industry?: string
  companyCurrency?: string
  companyData?: CompanyObject
  handleSubmit: () => void
  isEdit: boolean
  transactionData?: IndirectTransactionObject
}

const AddIndirectTransactionForm = (props: Props) => {
  let date = new Date()
  let maxDate = date.toISOString().split('T')[0]
  const [loading, setLoading] = useState(false)
  const [companyOptions, setCompanyOptions] = useState<any>([])
  const [hissaCompanyId, setHissaCompanyId] = useState<string>()
  const [allCompanies, setAllCompanies] = useState<GlobalCompanySearchType[]>([])
  const [filteredCompanies, setFilteredCompanies] = useState<GlobalCompanySearchType[]>([])
  const [currency, setCurrency] = useState<any>({
    value: props.companyCurrency || 'INR - ₹',
    label: props.companyCurrency || 'INR - ₹',
  })
  const [invName, setInvName] = useState<any>({
    value: props.transactionData?.investorId || 'Select...',
    label: props.transactionData?.investorName || 'Select...',
  })

  const [companyCurrency, setCompanyCurrency] = useState<any>({
    value: props.companyCurrency || 'INR - ₹',
    label: props.companyCurrency || 'INR - ₹',
  })

  const countryList: MultiSelectOptionType[] = []
  countryData.forEach((country, index) => {
    if (!countryList.find((nameObj) => nameObj.label === country.name))
      countryList.push({ value: index, label: country.name })
  })

  const { data: globalCompanies } = useGetGlobalCompaniesQuery<GetGlobalCompaniesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  useEffect(() => {
    if (globalCompanies && globalCompanies.globalCompaniesSearch) {
      setAllCompanies(
        _.cloneDeep(globalCompanies.globalCompaniesSearch) as GlobalCompanySearchType[]
      )
    }
  }, [globalCompanies])

  function getSearchedCompanies(search: string) {
    const startsWithCompanies = allCompanies.filter((company) => {
      return company.name?.toLowerCase().startsWith(search.toLowerCase())
    })
    const includesWithCompanies = allCompanies.filter((company) => {
      return (
        company.name?.toLowerCase().includes(search.toLowerCase()) &&
        !company.name.toLowerCase().startsWith(search.toLowerCase())
      )
    })
    const filteredResult = _.union(startsWithCompanies, includesWithCompanies)
    search === '' ? setFilteredCompanies([]) : setFilteredCompanies(filteredResult)
  }

  useEffect(() => {
    var options = filteredCompanies.map((company) => {
      return {
        key: company.name,
        value: company.name,
        label: company.name,
        companyName: company.name,
        cin: company.cin,
        hissaCompanyId: company.id,
      }
    })
    setCompanyOptions(options)
  }, [filteredCompanies])

  const currencyList: SelectOptionType[] = []
  currencies.forEach((currency: string) => {
    currencyList.push({
      value: currency,
      label: currency,
    })
  })

  const indirectTransactionInits: IndirectTransactionData = props.isEdit
    ? {
        investorId: props.transactionData?.investorId || 0,
        indirectInvestorName: props.transactionData?.indirectVehicleName || '',
        companyName: props.isCompanyExist ? props.companyName || '' : '',
        unitType: props.transactionData?.unitType || '',
        cin: props.isCompanyExist ? props.cin || '' : '',
        industry: props.isCompanyExist ? props.industry || '' : '',
        noOfUnits: props.transactionData?.noOfUnits || 0,
        drawDown: props.transactionData?.drawDown || 0,
        investedAmount: props.transactionData?.transactionAmount || 0,
        setupFee: props.transactionData?.setupFee || 0,
        estimatedFMV: props.transactionData?.currentValue || 0,
        investmentDate: props.transactionData?.transactionDate.split('T')[0] || '',
        currentNAV: props.transactionData?.currentNav || 0,
        hissaCompanyId: Number(props.companyData?.hissaCompanyId) || 0,
        transactionCurrency: props.transactionData?.transactionCurrency || 'INR - ₹',
        jurisdiction: props.companyData?.jurisdiction || 'India',
        companyCurrency: props.companyData?.companyCurrency || 'INR - ₹',
        exchangeRate: props.transactionData?.exchangeRate?.toString() || '',
        currentValuation: props.companyData?.valuation || 1,
        currentSharePrice: props.companyData?.sharePrice || 1,
      }
    : {
        investorId: 0,
        indirectInvestorName: '',
        companyName: props.isCompanyExist ? props.companyName || '' : '',
        unitType: '',
        cin: props.isCompanyExist ? props.cin || '' : '',
        industry: props.isCompanyExist ? props.industry || '' : '',
        noOfUnits: 0,
        drawDown: 0,
        investedAmount: 0,
        setupFee: 0,
        estimatedFMV: 0,
        investmentDate: '',
        currentNAV: 0,
        hissaCompanyId: 0,
        transactionCurrency: 'INR - ₹',
        jurisdiction: 'India',
        companyCurrency: 'INR - ₹',
        exchangeRate: '',
        currentValuation: 1,
        currentSharePrice: 1,
      }

  const mutateAddIndirectTransaction = useAddIndirectTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: () => {
        if (props.isCompanyExist && props.companyData) {
          const compKey = {
            input: {
              type: props.companyData?.type || 0,
              hissaCompanyId: props.companyData?.hissaCompanyId || '',
              id: Number(props.companyData?.id) || 0,
            },
          }
          const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
          queryClient.invalidateQueries(getCompanyWiseQuery)
        }

        const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()
        queryClient.invalidateQueries(getAllTransactionQuery)

        Toast('Indirect Transaction Added Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        setLoading(false)
        Toast(`${error.response.errors[0].message}`, ToastType.error)
      },
    },
    {}
  )
  const mutateEditIndirectTransaction = useEditIndirectTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: () => {
        if (props.isCompanyExist && props.companyData) {
          const compKey = {
            input: {
              type: props.companyData?.type || 0,
              hissaCompanyId: props.companyData?.hissaCompanyId || '',
              id: Number(props.companyData?.id) || 0,
            },
          }
          const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
          queryClient.invalidateQueries(getCompanyWiseQuery)
        }

        const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()
        queryClient.invalidateQueries(getAllTransactionQuery)

        Toast('Indirect Transaction Edited Successfully!', ToastType.success)
      },
      onError: (error: any) => {
        setLoading(false)
        Toast(`${error.response.errors[0].message}`, ToastType.error)
      },
    },
    {}
  )

  const formik = useFormik({
    initialValues: indirectTransactionInits,
    validationSchema: addIndirectTransactionSchemas,
    onSubmit: async (values) => {
      setLoading(true)
      props.isEdit
        ? mutateEditIndirectTransaction.mutate({
            input: {
              investorId: values.investorId,
              noOfUnits: values.noOfUnits,
              drawDown: values.drawDown,
              transactionAmount: values.investedAmount,
              setupFee: values.setupFee,
              estimatedFMV: values.estimatedFMV,
              transactionDate: new Date(values.investmentDate).toISOString(),
              currentNAV: parseFloat(values.currentNAV.toString()),
              unitType: values.unitType,
              investmentNav: 1,
              id: props.transactionData?.id,
              exchangeRate: parseInt(values.exchangeRate) || 1,
              transactionCurrency: values.transactionCurrency,
            },
          })
        : mutateAddIndirectTransaction.mutate({
            input: {
              investorId: values.investorId,
              indirectInvestorName: values.indirectInvestorName,
              name: values.companyName,
              cin: values.cin,
              noOfUnits: values.noOfUnits,
              drawDown: values.drawDown,
              transactionAmount: values.investedAmount,
              setupFee: values.setupFee,
              hissaCompanyId: hissaCompanyId,
              estimatedFMV: values.estimatedFMV,
              transactionDate: new Date(values.investmentDate).toISOString(),
              currentNAV: parseFloat(values.currentNAV.toString()),
              unitType: values.unitType,
              industry: values.industry,
              companyCurrency: values.companyCurrency,
              jurisdiction: values.jurisdiction,
              investmentNav: 1,
              exchangeRate: parseInt(values.exchangeRate) || 1,
              transactionCurrency: values.transactionCurrency,
              currentSharePrice: values.currentSharePrice,
              companyValuation: values.currentValuation,
            },
          })
      props.handleClose()
      props.handleSubmit()
    },
  })

  const investorName: MultiSelectOptionType[] = []

  props.investorList.map((investor: any) =>
    !investorName.find((invObj) => invObj.value === investor.investorId)
      ? investorName.push({
          value: investor.investorId,
          label: investor.investorName,
        })
      : {}
  )

  useEffect(() => {
    formik.setFieldValue('investedAmount', Number(formik.values.drawDown - formik.values.setupFee))
  }, [formik.values.drawDown, formik.values.setupFee])

  useEffect(() => {
    if (Number(formik.values.estimatedFMV) > 0 && Number(formik.values.noOfUnits) > 0)
      formik.setFieldValue(
        'currentNAV',
        (Number(formik.values.estimatedFMV) / Number(formik.values.noOfUnits)).toFixed(2)
      )
  }, [formik.values.estimatedFMV, formik.values.noOfUnits])

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form card w-100 p-0'>
      <div className='w-100 mx-auto'>
        {!props.isCompanyExist && (
          <>
            <div className='d-flex mb-8'>
              <div className='fw-bold'>Investment Vehicle Details</div>
              <hr className='my-auto flex-grow-1' />
            </div>
            <div className='row '>
              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>Investor or Fund</label>

                <Select
                  className=''
                  options={investorName}
                  name='investorId'
                  placeholder='Select...'
                  isDisabled={props.isEdit}
                  value={invName}
                  onChange={(e: any) => {
                    formik.setFieldValue('investorId', e.value)
                    setInvName(e)
                  }}
                  styles={selectCustomStyles}
                />

                <div className='text-danger mt-2'>
                  {formik.touched.investorId && formik.errors.investorId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.investorId}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>Vehicle Name</label>

                <InputBox
                  isDisabled={props.isEdit}
                  name={'indirectInvestorName'}
                  handleChange={(value: string) => {
                    formik.setFieldValue('indirectInvestorName', value)
                  }}
                  setFieldValue={(value: string) => {
                    formik.setFieldValue('indirectInvestorName', value)
                  }}
                  placeholder='Enter Vehicle Name'
                  currency={currency.label}
                  value={formik.values.indirectInvestorName}
                  type='text'
                />
                <div className='text-danger mt-2'>
                  {formik.touched.indirectInvestorName && formik.errors.indirectInvestorName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.indirectInvestorName}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>Company Currency</label>

                <Select
                  options={currencyList.filter((value) => Object.keys(value).length !== 0)}
                  value={companyCurrency}
                  name='companyCurrency'
                  isDisabled={props.isEdit}
                  onChange={(e: any) => {
                    setCompanyCurrency(e)
                    formik.setFieldValue('companyCurrency', e.value)
                  }}
                  styles={selectCustomStyles}
                />

                <div className='text-danger mt-2'>
                  {formik.touched.companyCurrency && formik.errors.companyCurrency && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.companyCurrency}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>Company Name</label>
                {!props.isCompanyExist && (
                  <CreatableSelect
                    className=''
                    styles={selectCustomStyles}
                    inputId='aria-company-input'
                    name='aria-company'
                    options={companyOptions}
                    isClearable={true}
                    onInputChange={(e) => {
                      if (e.length >= 3) {
                        getSearchedCompanies(e)
                      }
                    }}
                    onChange={(e: any) => {
                      if (e === null) {
                        formik.setFieldValue('companyName', '')
                        formik.setFieldValue('cin', '')
                        setHissaCompanyId('')
                      }
                      var index = companyOptions.findIndex(
                        (company: any) => company.companyName == e?.companyName
                      )
                      if (index > -1) {
                        formik.setFieldValue('companyName', companyOptions[index].companyName)
                        formik.setFieldValue('cin', companyOptions[index].cin)
                        setHissaCompanyId(companyOptions[index].hissaCompanyId)
                      }
                      if (index == -1 && e) {
                        formik.setFieldValue('companyName', e?.label)
                      }
                    }}
                    formatCreateLabel={(inputText) => `Add "${inputText}"`}
                  />
                )}
                {props.isCompanyExist && (
                  <input
                    type='string'
                    className='form-control form-control-lg form-control-solid'
                    disabled={props.isCompanyExist}
                    {...formik.getFieldProps('companyName')}
                  />
                )}

                <div className='text-danger mt-2'>
                  {formik.touched.companyName && formik.errors.companyName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.companyName}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>CIN</label>
                <InputBox
                  isDisabled={props.isCompanyExist}
                  name={'cin'}
                  handleChange={(value: string) => {
                    formik.setFieldValue('cin', value)
                  }}
                  setFieldValue={(value: string) => {
                    formik.setFieldValue('cin', value)
                  }}
                  placeholder='Enter Industry Type'
                  currency={currency.label}
                  value={formik.values.cin}
                  type='text'
                />
                <div className='text-danger mt-2'>
                  {formik.touched.cin && formik.errors.cin && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.cin}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 '>
                <label className='form-label'>Industry</label>

                <InputBox
                  isDisabled={props.isCompanyExist}
                  name={'industry'}
                  handleChange={(value: string) => {
                    formik.setFieldValue('industry', value)
                  }}
                  setFieldValue={(value: string) => {
                    formik.setFieldValue('industry', value)
                  }}
                  placeholder='Enter Industry'
                  currency={currency.label}
                  value={formik.values.industry}
                  type='text'
                />
                <div className='text-danger mt-2'>
                  {formik.touched.industry && formik.errors.industry && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.industry}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>Jurisdiction</label>

                <Select
                  className=''
                  options={countryList}
                  name='jurisdiction'
                  placeholder={'India'}
                  isDisabled={props.isEdit}
                  styles={selectCustomStyles}
                  onChange={(e: any) => {
                    // handleChange(e.label)
                    formik.setFieldValue('jurisdiction', e.label)
                  }}
                />
                <div className='text-danger fs-7 mt-1'>
                  {formik.touched.jurisdiction && formik.errors.jurisdiction && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.jurisdiction}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>
                  Current Share Price{' '}
                  <span style={{ fontFamily: 'arial' }}>
                    ({getSymbolOfCurrency(companyCurrency.label)})
                  </span>
                </label>

                <InputBox
                  isDisabled={props.isCompanyExist}
                  name={'currentSharePrice'}
                  handleChange={(value: number) => {
                    formik.setFieldValue('currentSharePrice', value)
                  }}
                  setFieldValue={(value: number) => {
                    formik.setFieldValue('currentSharePrice', value)
                  }}
                  placeholder='Enter Current Share Price'
                  currency={currency.label}
                  value={formik.values.currentSharePrice}
                  isCurrency={true}
                  type='number'
                />

                <div className='text-danger fs-7 mt-1'>
                  {formik.touched.currentSharePrice && formik.errors.currentSharePrice && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.currentSharePrice}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
                <label className='form-label required'>
                  Current Valuation{' '}
                  <span style={{ fontFamily: 'arial' }}>
                    ({getSymbolOfCurrency(companyCurrency.label)})
                  </span>
                </label>

                <InputBox
                  isDisabled={props.isCompanyExist}
                  name={'currentValuation'}
                  handleChange={(value: number) => {
                    formik.setFieldValue('currentValuation', value)
                  }}
                  setFieldValue={(value: number) => {
                    formik.setFieldValue('currentValuation', value)
                  }}
                  placeholder='Enter Current Valuation'
                  currency={currency.label}
                  value={formik.values.currentValuation}
                  isCurrency={true}
                  type='number'
                />
                <div className='text-danger fs-7 mt-1'>
                  {formik.touched.currentValuation && formik.errors.currentValuation && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block fs-7'>{formik.errors.currentValuation}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        <div className='row'>
          <div className='d-flex mb-8'>
            <div className='fw-bold'>Transaction Details</div>
            <hr className='my-auto flex-grow-1' />
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>Transaction Currency</label>

            <Select
              options={currencyList.filter((value) => Object.keys(value).length !== 0)}
              value={currency}
              name='transactionCurrency'
              onChange={(e: any) => {
                setCurrency(e)
                formik.setFieldValue('transactionCurrency', e.value)
              }}
              styles={selectCustomStyles}
            />

            <div className='text-danger mt-2'>
              {formik.touched.transactionCurrency && formik.errors.transactionCurrency && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.transactionCurrency}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>Number of Units</label>

            <InputBox
              isDisabled={false}
              name={'noOfUnits'}
              handleChange={(value: number) => {
                formik.setFieldValue('noOfUnits', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('noOfUnits', value)
              }}
              placeholder='Enter Number of Units'
              currency={currency.label}
              value={formik.values.noOfUnits}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.noOfUnits && formik.errors.noOfUnits && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.noOfUnits}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label'>Unit Type</label>

            <InputBox
              isDisabled={false}
              name={'unitType'}
              handleChange={(value: string) => {
                formik.setFieldValue('unitType', value)
              }}
              setFieldValue={(value: string) => {
                formik.setFieldValue('unitType', value)
              }}
              placeholder='Enter Unit Type'
              currency={currency.label}
              value={formik.values.unitType}
              type='text'
            />
            <div className='text-danger mt-2'>
              {formik.touched.unitType && formik.errors.unitType && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.unitType}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>
              Drawdown{' '}
              <span style={{ fontFamily: 'arial' }}>({getSymbolOfCurrency(currency.label)})</span>
            </label>
            <InputBox
              isDisabled={false}
              name={'drawDown'}
              handleChange={(value: number) => {
                formik.setFieldValue('drawDown', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('drawDown', value)
              }}
              placeholder='Enter Drawdown Amount'
              currency={currency.label}
              value={formik.values.drawDown}
              isCurrency={true}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.drawDown && formik.errors.drawDown && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.drawDown}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>
              Setup Fee{' '}
              <span style={{ fontFamily: 'arial' }}>({getSymbolOfCurrency(currency.label)})</span>
            </label>

            <InputBox
              isDisabled={false}
              name={'setupFee'}
              handleChange={(value: number) => {
                formik.setFieldValue('setupFee', value)
              }}
              placeholder='Enter Setup Fee'
              setFieldValue={(value: number) => {
                formik.setFieldValue('setupFee', value)
              }}
              currency={currency.label}
              value={formik.values.setupFee}
              isCurrency={true}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.setupFee && formik.errors.setupFee && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.setupFee}</div>
                </div>
              )}
            </div>
          </div>

          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 '>
            <label className='form-label required'>
              Amount Invested{' '}
              <span style={{ fontFamily: 'arial' }}>({getSymbolOfCurrency(currency.label)})</span>
            </label>

            <InputBox
              isDisabled={false}
              name={'investedAmount'}
              handleChange={(value: number) => {
                formik.setFieldValue('investedAmount', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('investedAmount', value)
              }}
              placeholder='Enter Investment Amount'
              currency={currency.label}
              value={formik.values.investedAmount}
              isCurrency={true}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.investedAmount && formik.errors.investedAmount && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.investedAmount}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>
              Estimated FMV{' '}
              <span style={{ fontFamily: 'arial' }}>({getSymbolOfCurrency(currency.label)})</span>
            </label>

            <InputBox
              isDisabled={false}
              name={'estimatedFMV'}
              handleChange={(value: number) => {
                formik.setFieldValue('estimatedFMV', value)
              }}
              setFieldValue={(value: number) => {
                formik.setFieldValue('estimatedFMV', value)
              }}
              currency={currency.label}
              placeholder='Enter FMV Amount'
              value={formik.values.estimatedFMV}
              isCurrency={true}
              type='number'
            />
            <div className='text-danger mt-2'>
              {formik.touched.estimatedFMV && formik.errors.estimatedFMV && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.estimatedFMV}</div>
                </div>
              )}
            </div>
          </div>
          <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
            <label className='form-label required'>Date of Investment</label>

            <input
              type='date'
              max={maxDate}
              className='form-control custom-input fw-bold shadow-lg h-38px'
              {...formik.getFieldProps('investmentDate')}
            />
            <div className='text-danger mt-2'>
              {formik.touched.investmentDate && formik.errors.investmentDate && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block fs-7'>{formik.errors.investmentDate}</div>
                </div>
              )}
            </div>
          </div>

          {((formik.values.companyCurrency !== companyCurrency.label &&
            companyCurrency.label !== undefined) ||
            formik.values.companyCurrency !== formik.values.transactionCurrency) && (
            <div className='fv-row mb-10 col-12 col-sm-6 col-md-4 pe-6'>
              <label className='form-label required'>
                Exchange Rate{' '}
                <span style={{ fontFamily: 'arial' }}>({getSymbolOfCurrency(currency.label)})</span>
              </label>

              <InputBox
                isDisabled={false}
                name={'exchangeRate'}
                handleChange={(value: number) => {
                  formik.setFieldValue('exchangeRate', value)
                }}
                setFieldValue={(value: number) => {
                  formik.setFieldValue('exchangeRate', value)
                }}
                placeholder='Enter Exchange Rate'
                currency={currency.label}
                value={formik.values.exchangeRate}
                isCurrency={true}
                type='number'
              />
              {!formik.errors?.exchangeRate && formik.values.exchangeRate && (
                <div className=' flex text-end fs-8 text-gray-500 '>
                  1 {getKeyByValue(currencyTypes, getCurrency(formik.values.transactionCurrency))} ={' '}
                  {formik.values.exchangeRate}{' '}
                  {getKeyByValue(currencyTypes, getCurrency(formik.values.companyCurrency))}
                </div>
              )}
              <div className='text-danger mt-2'>
                {formik.touched.exchangeRate && formik.errors.exchangeRate && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block fs-7'>{formik.errors.exchangeRate}</div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='d-flex justify-content-between pt-15'>
        <button type='button' className='btn btn-lg btn-secondary me-3' onClick={props.handleClose}>
          Cancel
        </button>
        <button type='submit' className='btn btn-primary' disabled={loading}>
          {!loading && (props.isEdit ? 'Edit Transaction' : 'Add Transaction')}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

export { AddIndirectTransactionForm }
