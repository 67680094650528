import { Col, Modal, Row } from 'react-bootstrap'
import { useIsMutating } from 'react-query'
import { KTSVG } from '../../../_metronic/helpers'
import { graphqlRequestClient, queryClient } from '../../../queries/client'
import { RemoveEeFilterMutation, useRemoveEeFilterMutation, useGetEeFiltersQuery, RemoveEeFilterMutationVariables } from '../../../generated/graphql'
import Toast, { ToastType } from '../../../utils/toast'

type Props = {
  showRemoveModal: boolean
  toggleRemoveModal: () => void
  filter: any
}

function RemoveEEFilter({ showRemoveModal, toggleRemoveModal, filter }: Props) {
  const removeMutate = useRemoveEeFilterMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (data: RemoveEeFilterMutation, _variables: RemoveEeFilterMutationVariables, _context: unknown) => {
        queryClient.invalidateQueries(useGetEeFiltersQuery.getKey())
        Toast(data.removeEEFilter.message, ToastType.success)
        toggleRemoveModal()
      },
      onError: (error: any) => Toast(error.response.errors[0].message, ToastType.error),
    },
    {}
  )

  function handleSubmit(e: any) {
    e.preventDefault()
    e.stopPropagation()
    removeMutate.mutate({ input: { id: filter.id } })
  }

  return (
    <Modal id='kt_modal_remove_filter' aria-hidden='true' show={showRemoveModal} onHide={toggleRemoveModal}>
      <form onSubmit={handleSubmit}>
        <Modal.Header className='p-2'>
          <Modal.Title className='ps-5'>Remove Filter</Modal.Title>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={toggleRemoveModal}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='me-n7 pe-7'>
            <div className='fs-3 pb-6'>Are you sure you want to remove this filter?</div>
            <Row>
              <Col>ID:</Col>
              <Col>{filter && filter.id}</Col>
            </Row>
            <Row>
              <Col>Name:</Col>
              <Col>{filter && filter.name}</Col>
            </Row>
            <Row>
              <Col>Type:</Col>
              <Col>{filter && filter.type.charAt(0) + filter.type.toLowerCase().substring(1)}</Col>
            </Row>
            <Row>
              <Col>Group:</Col>
              <Col>{filter && filter.group}</Col>
            </Row>
            <Row>
              <Col>Formula:</Col>
              <Col>{filter && filter.formula}</Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <div className='d-flex justify-content-between'>
            <button type='button' className='btn btn-sm btn-secondary me-3' onClick={toggleRemoveModal}>
              Cancel
            </button>
            <button type='submit' className='btn btn-sm btn-primary' disabled={useIsMutating() > 0}>
              {useIsMutating() === 0 && 'Remove Filter'}
              {useIsMutating() > 0 && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default RemoveEEFilter
