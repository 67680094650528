import { useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import AddEEMetric from './AddEEMetric'
import EditEEMetric from './EditEEMetric'
import RemoveEEMetric from './RemoveEEMetric'
import _ from 'lodash'

type Props = { metrics: any[] }

const EEMetrics = ({ metrics }: Props) => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const toggleAddModal = () => setShowAddModal(!showAddModal)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const toggleEditModal = () => setShowEditModal(!showEditModal)
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)
  const toggleRemoveModal = () => setShowRemoveModal(!showRemoveModal)
  const [metric, setMetric] = useState(null)

  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fs-3 mb-1'>Metrics</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{metrics ? metrics.length : 'No'} metrics available</span>
          </h3>
          <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Click to add a metric'>
            <button
              className='btn btn-sm btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_add_metric'
              onClick={() => {
                setMetric(null)
                toggleAddModal()
              }}
            >
              <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-2' />
              Add Metric
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-5'>
              <thead>
                <tr>
                  <th className='p-0 min-w-150px'></th>
                  <th className='p-0 min-w-100px'></th>
                  <th className='p-0 min-w-100px'></th>
                  <th className='p-0 min-w-40px'></th>
                </tr>
              </thead>
              <tbody>
                {metrics &&
                  metrics.map((m: any, i: any) => (
                    <tr key={i}>
                      <td>
                        <p className='text-dark text-hover-primary mb-1 fs-6'>{m.name}</p>
                        <span className='text-muted fw-semibold d-block fs-7'>{m.moniker}</span>
                      </td>
                      <td>
                        <span className='text-muted fw-semibold'>{m.type.charAt(0) + m.type.toLowerCase().substring(1)}</span>
                      </td>
                      <td className='text-center'>
                        {m.tags &&
                          m.tags.map((tag: any, i: any) => (
                            <span key={i} className='badge badge-light-info fw-semibold me-1'>
                              {tag}
                            </span>
                          ))}
                      </td>
                      <td>
                        <div className='d-flex justify-content-end'>
                          <button
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary mx-1'
                            onClick={() => {
                              setMetric(_.cloneDeep(m))
                              toggleEditModal()
                            }}
                          >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                          </button>
                          <button
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-danger mx-1'
                            onClick={() => {
                              setMetric(_.cloneDeep(m))
                              toggleRemoveModal()
                            }}
                          >
                            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AddEEMetric showAddModal={showAddModal} toggleAddModal={toggleAddModal} />
      <EditEEMetric showEditModal={showEditModal} toggleEditModal={toggleEditModal} metric={metric} />
      <RemoveEEMetric showRemoveModal={showRemoveModal} toggleRemoveModal={toggleRemoveModal} metric={metric} />
    </>
  )
}

export { EEMetrics }
