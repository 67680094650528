import { create } from 'zustand'
import { configurePersist } from 'zustand-persist'

const { persist: _persist } = configurePersist({
  storage: localStorage,
  rootKey: 'user',
})

const persist = _persist as any

type CompanyStageState = {
  companyStage: string
  setCompanyStage: (value: string) => void
  reset: () => void
}

const initialState = {
  companyStage: 'Four',
}

const companyStageStore = create<CompanyStageState>(
  persist(
    {
      key: 'companyStageDetails',
    },
    (set: any) => ({
      ...initialState,
      setCompanyStage: (stage: string) => {
        set((state: any) => ({ companyStage: stage }))
      },
      reset: () => {
        set(() => initialState)
      },
    })
  )
)

export const useCompanyStageStore = companyStageStore
