import { useEffect, useState } from 'react'
import { formatNumber } from '../../../../../utils/CurrencyFormatter'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../_metronic/helpers'
import { AddRoundTransactionModal } from '../../../manual-investments/components/AddRoundTransaction/AddRoundTransactionModal'
import { Modal } from 'react-bootstrap'
import { AddTransactionRoundDetails } from './InvestmentModellingHelper'
import Toast, { ToastType } from '../../../../../utils/toast'

export function InvestorNameRender(data: any) {
  return (
    <div className='pt-2'>
      <p className='fw-bold'> {data.data.investorName}</p>
    </div>
  )
}

export function SecuritiesRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.security}</p>
    </div>
  )
}

export function NoOfSharesRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.numOfShares,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function SharePriceRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.sharePrice,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function AmountInvestedRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.amountInvested,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}
export function PercentageRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.percentage ? data.data.percentage.toFixed(2) + '%' : '-'}</p>
    </div>
  )
}

export function NominalEquitySharesRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.nominalEquityShares,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function InvestmentActionRender(data: any) {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [data.data.transactionDetails])

  function handleDeleteInvestment(transactionData: AddTransactionRoundDetails) {
    data.data.removeInvestmentData(transactionData)
    Toast('Investment Removed Successfully', ToastType.success)
  }

  const options = ['Delete Investment', 'Edit Investment']
  const actionMenu = () => {
    return options.map((option) => {
      return (
        <label
          key={option}
          className='form-check-custom py-3 bg-hover-primary text-hover-white'
          onClick={() => {
            if (option === 'Delete Investment') {
              setShowDeleteModal(true)
            } else if (option === 'Edit Investment') {
              setShowEditModal(true)
            }
          }}
        >
          <span className='form-check-label fs-base ms-6 fw-normal cursor-pointer'>{option}</span>
        </label>
      )
    })
  }

  return (
    <div className='px-0'>
      {data.data.investorName !== 'Total' && data.data.dateOfInvestment !== null && (
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
      )}
      {showEditModal && (
        <AddRoundTransactionModal
          handleClose={() => {
            setShowEditModal(false)
          }}
          showModal={showEditModal}
          roundDetails={data.data.roundDetails}
          setInvestmentData={data.data.setInvestmentData}
          currentRoundSize={data.data.currentRoundSize}
          shareHolderNameList={data.data.shareHolderNameList}
          sharePrice={data.data.currentSharePrice}
          isEdit={true}
          removeInvestmentData={data.data.removeInvestmentData}
          investmentData={data.data.transactionDetails}
          currentPercentage={data.data.currentPercentage}
          investments={data.data.transactionData}
        />
      )}
      {showDeleteModal && (
        <Modal
          id='kt_modal_add_inv'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-500px '
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
        >
          <Modal.Body className='p-9'>
            <div className='card'>
              <div className='card-body p-0'>
                <h2 className='fw-bolder text-gray-heading pb-4'>Remove Investment</h2>
                <div className='fs-5 pb-6'>Are you sure you want to remove this investment?</div>

                <div className='d-flex col justify-content-between pt-6'>
                  <button
                    className='px-4 rounded border border-1 border-primary bg-transparent h-38px fw-normal text-primary'
                    onClick={() => setShowDeleteModal(false)}
                  >
                    <span className='indicator-label'>Cancel</span>
                  </button>
                  <button
                    type='submit'
                    className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'
                    disabled={deleteLoading}
                    onClick={() => handleDeleteInvestment(data.data.transactionDetails)}
                  >
                    {'Remove'}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
        data-kt-menu='true'
      >
        {actionMenu()}
      </div>
    </div>
  )
}
