import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import { AddAccountAccessForm } from './AddAccountAccessForm'
import {
  CheckIsRequestSentQuery,
  useCheckIsRequestSentQuery,
} from '../../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../../queries/client'

type Props = {
  showModal: boolean
  handleClose: () => void
}

const AddAccountAccessModal = ({ showModal, handleClose }: Props) => {
  const { data } = useCheckIsRequestSentQuery<CheckIsRequestSentQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-800px rounded'
      show={showModal}
      onHide={handleClose}
    >
      <div className='col d-flex justify-content-between px-9 pb-4 '>
        <div className='pt-10'>
          <h2 className='fw-bolder text-dark'>{'Upgrade to Premium'}</h2>
          <div className='fs-6 text-gray-500'></div>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-6'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>

      <Modal.Title>
        <div className='p-9 pt-2 '>
          <div className=''>
            <AddAccountAccessForm
              handleClose={handleClose}
              isRequestSent={data?.checkIsRequestSent?.isEmailSent === undefined ? false : true}
            />
          </div>
        </div>
      </Modal.Title>
    </Modal>
  )
}

export { AddAccountAccessModal }
