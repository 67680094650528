import { useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import {
  AddCompanyMutation,
  AddCompanyMutationVariables,
  GlobalCompanySearchType,
  useAddCompanyMutation,
  useGetNewWatchlistsQuery,
  useGetWatchlistsQuery,
} from '../../../../generated/graphql'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'

type SearchCompaniesProps = {
  filteredCompanies: GlobalCompanySearchType[]
  watchlistId: number
}

export function CompanySearch(props: SearchCompaniesProps) {
  const getNewWatchlistQueryKey = useGetNewWatchlistsQuery.getKey()

  const mutateAddCompany = useAddCompanyMutation<Error>(graphqlRequestClient, {
    onSuccess: (
      data: AddCompanyMutation,
      _variables: AddCompanyMutationVariables,
      _context: unknown
    ) => {
      queryClient.invalidateQueries(getNewWatchlistQueryKey)
      Toast('Company Added To Watchlist Successfully', ToastType.success)
    },
    onError: (error: any) => {
      Toast(`${error.response.errors[0].message}`, ToastType.error)
    },
  })

  function handleAddCompany(e: React.MouseEvent, companyId: string) {
    e.stopPropagation()
    e.preventDefault()
    mutateAddCompany.mutate({
      input: {
        id: props.watchlistId,
        companyId: companyId,
      },
    })
  }

  const serachedResults = props.filteredCompanies?.map(
    (company: GlobalCompanySearchType, index: number) => {
      return (
        <tr key={index}>
          <td className='w-auto min-w-md-550px d-flex flex-row'>
            <button
              type='button'
              className=' bg-white border border-0'
              onClick={(e) => {
                handleAddCompany(e, company.id!)
              }}
            >
              <KTSVG
                path={'media/icons/duotune/general/gen041.svg'}
                className='ps-4 svg-icon-2x text-start fs-9 pt-3'
                svgClassName='text-gray-700 text-hover-primary'
              />
            </button>
            <div>
              <p className='mb-0'>{company.name}</p>
              <div className='fs-8 text-gray-500'>{company.dba}</div>
            </div>
          </td>
        </tr>
      )
    }
  )

  return (
    <>
      {props.filteredCompanies.length > 0 && (
        <>
          <div className='separator'></div>
          <div className='card mh-325px bg-white overflow-auto p-1'>
            <table className='table align-middle table-row-dashed align-items-baseline'>
              <tbody>{serachedResults}</tbody>
            </table>
          </div>
        </>
      )}
    </>
  )
}
