import { useContext } from 'react'
import { formatNumber, getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
// import { GainBadge, LossBadge } from '../../../../utils/GainOrLossBadges'
import { GlobalContext } from '../../store/context/globalContext'

export type CompanyCardType = {
  name: string
  value?: number | string
  gainOrLoss?: number
  hasCurrency?: boolean
}

type CompanyCardProps = {
  data: CompanyCardType[]
  className?: string
}
export const CompanyDataCard = (props: CompanyCardProps) => {
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  const investmentTableData = props.data.map((company: CompanyCardType, index: number) => {
    return (
      <tr key={index}>
        <td>
          <p className='fs-base fw-bold mb-0'>{company.name}</p>
        </td>
        <td>
          <p
            data-toggle='tooltip'
            data-placement='top'
            title={
              !isNaN(Number(company.value))
                ? Number(company.value)?.toLocaleString(userCurrencyFormat)
                : ''
            }
            className='fs-base text-end fw-bold mb-0'
          >
            {company.hasCurrency && getSymbolOfCurrency(userCurrency) + ' '}
            {typeof company.value === 'number' &&
              formatNumber({
                value: company.value,
                format: userCurrencyFormat,
              })}
            {typeof company.value === 'string' && company.value}
          </p>
        </td>
        {/* <td>
          <p className='fs-base text-center mb-0'>
            {company.gainOrLoss > 1 ? (
              <GainBadge badgeValue={company.gainOrLoss} indicator='X' />
            ) : (
              <LossBadge badgeValue={company.gainOrLoss} indicator='X' />
            )}
          </p>
        </td> */}
      </tr>
    )
  })

  return (
    <div
      className={`card  ${props.className}`}
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='card-body table-responsive pt-6 pb-0 mx-3'>
        <div className='card-title fw-bold flex-column'>
          <h2 className='m-0 text-gray-600'>Company Details</h2>
        </div>
        {/* <label className='form-label fw-bold'>Company Details</label> */}
        <div className='separator mb-4'></div>
        <table className='table align-middle gy-5 table-row-dashed border-gray-200 align-items-baseline'>
          <tbody>{investmentTableData}</tbody>
        </table>
      </div>
    </div>
  )
}
