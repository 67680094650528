import * as Yup from 'yup'
import { CapitalLedgerType } from '../../../../generated/graphql'

export interface IAddLedgerTransactionData {
  closeDate: string
  finalAmount: number
  type: CapitalLedgerType
  isAddGroupTransaction: boolean
  transactionAmount: string
  transactionDate: string
  groupName: string
  fundName: string
  currentValue: number
  note: string
  transactionName: string
}

const addLedgerTransactionSchema = Yup.object().shape({
  finalAmount: Yup.number()
    .positive()
    .moreThan(0, 'Should be greater than 0')
    .required()
    .label('Transaction Amount'),
  type: Yup.mixed().required().oneOf(['CapitalCall', 'CapitalDistribution']),
  closeDate: Yup.string().required().label('Transaction Close Date'),
  currentValue: Yup.number()
    .positive()
    .moreThan(0, 'Should be greater than 0')
    .required()
    .label('Current fund value'),
})

const addLedgerTransactionInits: IAddLedgerTransactionData = {
  closeDate: '',
  finalAmount: 0,
  type: CapitalLedgerType.CapitalCall,
  isAddGroupTransaction: false,
  transactionAmount: '',
  transactionDate: '',
  groupName: '',
  fundName: '',
  currentValue: 0,
  note: '',
  transactionName: '',
}

export { addLedgerTransactionInits, addLedgerTransactionSchema }
