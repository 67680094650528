import React, { useEffect, useState } from 'react'
import {
  useGetUrlForS3ObjectQuery,
  GetUrlForS3ObjectQuery,
  CompanyObject,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { formatDate } from '../../../../utils/FormatDate'
import { hexToRGB } from '../../../../utils/HexToRgba'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { toAbsoluteUrl, KTSVG } from '../../../../_metronic/helpers'
import { PDFModalViewer } from './TabViews/PDFModalViewer'
import ManageTags from './ManageTags'
import { DeleteTransactionDocModal } from './DeleteTransactionDocModal'

type Props = {
  folderData: any
  handleDirectoryClick: any
  showAddFolder: boolean
  handleAddFolderClose: Function
  handleCreateFolder: Function
  handleDeleteItem: Function
  companyId?: string
  companyName: string
  investorId?: number
  id?: number
  vaultType?: string
  folderName: string[]
  tableData?: string[]
  handleClose: Function
  cin: string
  isIndirect: boolean
  investmentId: number
  companyData?: CompanyObject
}

const DocumentsTable: React.FC<any> = (props: Props) => {
  const [state, setState] = useState(false)
  const [showTagsModal, setShowTagsModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [bucket, setBucket] = useState<any>()
  const [selectedRow, setSelectedRow] = useState<any>()
  const [showModal, setShowModal] = useState(false)
  const [key, setKey] = useState('')
  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    { input: { key, bucket: bucket } },
    {
      enabled: state,
    }
  )
  if (query.isFetched && state == true) {
    if (key.toLowerCase().endsWith('pdf')) {
      setState(false)
      setShowModal(true)
    } else {
      window.open(query.data?.urlForS3Object || '')
      setState(false)
    }
  }
  let i = 0

  function handleClick(item: any) {
    setBucket(process.env.REACT_APP_S3_INVESTOR_BUCKET || '')
    setKey(item.isTransaction ? item.name : item.key)
    setState(true)
  }
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [props.tableData])

  const tableData = props.tableData?.map((item: any) => {
    const fileName = item.key.split('/')
    let source = 'PRIVATE'
    item.source = 'PRIVATE'

    function getBadgeColor(tag: string) {
      if (tag === 'Round Documents')
        return {
          backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 0.1),
          color: hexToRGB(getCSSVariableValue('--kt-donut-' + 0), 0.8),
        }
      else if (tag === 'Financial Documents')
        return {
          backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.1),
          color: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.8),
        }
      else if (tag === 'Share Certificates')
        return {
          backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 2), 0.1),
          color: hexToRGB(getCSSVariableValue('--kt-donut-' + 2), 0.8),
        }
      else {
        return {
          backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 3), 0.1),
          color: hexToRGB(getCSSVariableValue('--kt-donut-' + 3), 0.8),
        }
      }
    }

    const fileOptions = ['Manage Tags', 'Delete']
    const actionMenu = (item: any) => {
      let newOptions = fileOptions
      return newOptions.map((option) => {
        return (
          <label
            key={option}
            className='form-check-custom py-3 bg-hover-primary text-hover-white'
            onClick={() => {
              setSelectedRow(item)
              if (option === 'Manage Tags') {
                setShowTagsModal(true)
              } else if (option === 'Delete') {
                setShowDeleteModal(true)
              }
            }}
          >
            <span className='form-check-label fs-base ms-6 fw-normal cursor-pointer'>{option}</span>
          </label>
        )
      })
    }

    return (
      <tr key={item.name + i++} className='bg-hover-primary-light'>
        <>
          <td onClick={() => handleClick(item)}>
            <div className='d-flex align-items-center'>
              <div className='symbol symbol-35px me-5 ps-2'>
                <img
                  src={
                    item.key.toLowerCase().endsWith('.pdf')
                      ? toAbsoluteUrl('/media/svg/files/pdf.svg')
                      : toAbsoluteUrl('/media/svg/files/doc.svg')
                  }
                  alt=''
                />
              </div>
              <span className='text-wrap fs-base cursor-pointer'>
                {fileName[fileName.length - 1]}
              </span>
            </div>
          </td>
        </>
        <td>
          <span
            key={item.name}
            className={`badge fs-9 fw-semibold mb-1 me-2`}
            style={{
              background: 'white',
              color: 'black',
              border: '1px solid black',
            }}
          >
            {source}
          </span>
        </td>
        <td>
          {item.tags?.map((tag: string) => (
            <span className='badge fs-9 fw-semibold mb-1 me-2' style={getBadgeColor(tag)}>
              {tag}
            </span>
          ))}
        </td>
        <td>
          <p className='d-block fs-base'>
            {item.size
              ? item?.size > 1000000
                ? (item?.size / 1048576).toFixed(2) + ' MB'
                : (item?.size / 1024).toFixed(2) + ' KB'
              : '-'}
          </p>
        </td>
        <td className=''>
          <p className='d-block fs-base'>
            {item.lastModified ? formatDate(item.lastModified, 'dd-MMM-yyyy').toString() : '-'}
          </p>
        </td>
        <td className=''>
          {
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen052.svg'
                className='svg-icon-muted svg-icon-2x'
              />
            </button>
          }

          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
            data-kt-menu='true'
          >
            {actionMenu(item)}
          </div>
        </td>
      </tr>
    )
  })

  const noFilesRow = () => {
    return (
      <div className='overlay-layer bg-opacity-100'>
        <div className='text-center px-10'>
          <img
            src={toAbsoluteUrl('/media/icons/duotune/general/no-files.svg')}
            className='h-100px my-10'
            alt=''
          />
          <h2>No files present. Click on Select Files to add files.</h2>
        </div>
      </div>
    )
  }

  return (
    <div className={`card`}>
      <div className='card-body p-0 py-9'>
        <div className='table-responsive h-350px overflow-scroll'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-gray-600'>
                <th className='min-w-100px text-wrap'>Name</th>
                <th className='min-w-100px text-wrap'>Source</th>
                <th className='min-w-100px'>Tags</th>
                <th className='min-w-140px'>Size</th>
                <th className='min-w-120px'>Last Modified</th>
                <th className='min-w-100px'>Actions</th>
              </tr>
            </thead>
            <tbody>{tableData}</tbody>
            {showTagsModal && (
              <ManageTags
                showModal={showTagsModal}
                setShows={() => setShowTagsModal(false)}
                item={selectedRow}
                companyName={props.companyName}
                cin={props.cin}
                investorId={1}
                id={props.id}
                isTransaction={true}
                isIndirect={props.isIndirect}
                companyData={props.companyData}
              />
            )}
            {showDeleteModal && (
              <DeleteTransactionDocModal
                showModal={showDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                investmentId={props.isIndirect ? selectedRow.id : selectedRow.investmentId}
                fileKey={selectedRow.key}
                isIndirect={props.isIndirect}
                companyData={props.companyData}
              />
            )}
          </table>
          {tableData?.length === 0 && noFilesRow()}
        </div>
      </div>
      <PDFModalViewer
        show={showModal}
        handleClose={() => {
          setShowModal(false)
        }}
        url={query.data?.urlForS3Object || ''}
        documentName={key.split('/')[key.split('/').length - 1]}
      />
    </div>
  )
}

export { DocumentsTable }
