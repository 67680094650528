import { Modal } from 'react-bootstrap'
import { useIsMutating } from 'react-query'
import { useFormik } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'
import Select, { MultiValue } from 'react-select'
import * as Yup from 'yup'
import { SelectOptionType, multiSelectCustomStyles } from '../../../utils/MultiSelect'
import { selectCustomStyles } from '../../../utils/Select'
import { graphqlRequestClient, queryClient } from '../../../queries/client'
import { AddEeMetricMutation, MetricType, useAddEeMetricMutation, useGetEeMetricsQuery } from '../../../generated/graphql'
import Toast, { ToastType } from '../../../utils/toast'
import { metricTagsLV, metricTypeLV } from '../../../types/exitEnabler'

type Props = {
  showAddModal: boolean
  toggleAddModal: () => void
}

function AddEEMetric({ showAddModal, toggleAddModal }: Props) {
  const addForm = useFormik({
    initialValues: {
      id: -1,
      name: '',
      moniker: '',
      type: '',
      tags: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('Enter metric name').min(3, 'Name must be at least 3 characters'),
      moniker: Yup.string()
        .trim()
        .required('Enter metric moniker / acronym')
        .min(3, 'Moniker must be at least 3 characters')
        .matches(/^[aA-zZ0-9]+$/, { message: 'Only alplanumeric characters allowed.' }),
      type: Yup.string().required('Select metric type').min(1, 'Select metric type'),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: function (values) {
      const addData = {
        name: values.name.trim(),
        moniker: values.moniker.trim(),
        type: values.type as MetricType,
        tags: Array.isArray(values.tags) ? values.tags.join() : values.tags,
      }

      addMutate.mutate({ input: addData })
    },
  })

  const addMutate = useAddEeMetricMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (data: AddEeMetricMutation) => {
        queryClient.invalidateQueries(useGetEeMetricsQuery.getKey())
        addForm.resetForm()
        Toast(data.addEEMetric.message, ToastType.success)
        toggleAddModal()
      },
      onError: (error: any) => Toast(error.response.errors[0].message, ToastType.error),
    },
    {}
  )

  function selectTags(e: MultiValue<SelectOptionType>) {
    const arr = e.map((e) => e.value)
    addForm.setFieldValue('tags', arr.join())
  }

  function handleCancel() {
    addForm.resetForm()
    toggleAddModal()
  }
  return (
    <Modal id='kt_modal_add_metric' aria-hidden='true' show={showAddModal} onHide={toggleAddModal}>
      <form onSubmit={addForm.handleSubmit}>
        <Modal.Header className='p-2'>
          <Modal.Title className='ps-5'>Add Metric</Modal.Title>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={toggleAddModal}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='mh-400px scroll-y me-n7 pe-7'>
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>Name</label>
              <input type='text' className='form-control' placeholder='Enter name' {...addForm.getFieldProps('name')} />
              <div className='text-danger mt-2'>
                {addForm.touched.name && addForm.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addForm.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>Moniker / Acronym</label>
              <input type='text' className='form-control' placeholder='Enter moniker / acronym' {...addForm.getFieldProps('moniker')} />
              <div className='text-danger mt-2'>
                {addForm.touched.moniker && addForm.errors.moniker && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addForm.errors.moniker}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className='form-label '>Tags</label>
              <Select options={metricTagsLV} isMulti name='tags' onChange={(e: any) => selectTags(e)} styles={multiSelectCustomStyles} />
              <div className='text-danger mt-2'>
                {addForm.touched.tags && addForm.errors.tags && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addForm.errors.tags}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className='form-label required'>Type</label>
              <Select
                isSearchable={false}
                className='text-primary'
                options={metricTypeLV}
                name='type'
                onChange={(e: any) => addForm.setFieldValue('type', e.value)}
                styles={selectCustomStyles}
              />
              <div className='text-danger mt-2'>
                {addForm.touched.type && addForm.errors.type && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addForm.errors.type}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <div className='d-flex justify-content-between'>
            <button type='button' className='btn btn-sm btn-secondary me-3' onClick={handleCancel}>
              Cancel
            </button>
            <button type='submit' className='btn btn-sm btn-primary' disabled={useIsMutating() > 0}>
              {useIsMutating() === 0 && 'Add Metric'}
              {useIsMutating() > 0 && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default AddEEMetric
