import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG'
import EditHissaValuationDetailsForm from './EditHissaValuationDetailsForm'
import { CompanyObject, ValuationDetailsObject } from '../../../../../generated/graphql'

type ValuationDetailProps = {
  handleClose: () => void
  showModal: boolean
  companyDetails: CompanyObject
  valuationDetails: ValuationDetailsObject
}

function EditHissaValuationDetailsModal(props: ValuationDetailProps) {
  return (
    <Modal
      onHide={props.handleClose}
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-750px'
      show={props.showModal}
    >
      <div className='col d-flex justify-content-between px-9 py-9'>
        <div className='pt-4'>
          <h2 className='card-header fw-bolder text-dark'>{'Edit Valuation Details'}</h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={props.handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>

      <div className='d-flex row pb-10 px-9 '>
        <EditHissaValuationDetailsForm
          closeModal={props.handleClose}
          companyDetails={props.companyDetails}
          valuationDetails={props.valuationDetails}
          // companyName={props.companyName}
          // companyId={props.companyCin}
          // currentValuation={props.currentValuation}
          // cId={props.cId}
          // hissaVerified={props.hissaVerified}
          // name={selectedValue.name}
          // valuationDate={selectedValue.valuationDate?.split('T')[0]}
          // valuationType={selectedValue.valuationType}
          // valuationReport={selectedValue.fileURL?.split('/').pop()}
          // methodology={selectedValue.valuationMethodology}
          // tags={selectedValue.tags}
          // valuationId={Number(selectedValue.id)}
          // sharePrice={Number(selectedValue.sharePrice)}
          // sharePricePerReport={Number(selectedValue.sharePricePerReport)}
          // sharePriceBookValue={Number(selectedValue.sharePriceBookValue)}
          // valuatorName={selectedValue.valuatorName}
          // isDefault={props.isDefault}
        />
      </div>
    </Modal>
  )
}

export default EditHissaValuationDetailsModal
