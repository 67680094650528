import { useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import {
  DeleteFundUnitMutation,
  DeleteFundUnitMutationVariables,
  FundStructure,
  FundUnit,
  GetFundUnitQuery,
  GetPartnersGroupQuery,
  PartnerGroup,
  useDeleteFundUnitMutation,
  useGetFundStructureQuery,
  useGetFundUnitQuery,
  useGetPartnersGroupQuery,
} from '../../../../generated/graphql'
import { isoToDate } from '../../../../utils/FormatDate'
import { toFix } from '../../../../utils/ToFix'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import AddFundUnitModal from './AddFunUnitModal'
import UpdateFundNavModal from './UpdateFundNavModal'

type Props = {
  data: FundUnit[]
  fundStructure: FundStructure
  investorId: number
}

function FundUnitView(props: Props) {
  const [fundUnit, setFundUnit] = useState<FundUnit[]>()
  const [selectedData, setSelectedData] = useState<any>()
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false)

  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)
  const [showAddFundUnitModal, setShowAddFundUnitModal] = useState(false)
  const [partnerData, setPartnerData] = useState<PartnerGroup[]>()

  const fundUnitData = useGetFundUnitQuery<GetFundUnitQuery, Error>(
    graphqlRequestClient,
    { input: { investorId: props.investorId, fundStructureId: props.fundStructure.id } },
    {},
    {}
  )

  const partnerGroupData = useGetPartnersGroupQuery<GetPartnersGroupQuery, Error>(
    graphqlRequestClient,
    { input: { investorId: props.investorId, fundStructureId: props.fundStructure.id } },
    {},
    {}
  )

  useEffect(() => {
    if (fundUnitData?.data?.getFundUnit) {
      setFundUnit(fundUnitData?.data?.getFundUnit as FundUnit[])
    }
  }, [fundUnitData?.data?.getFundUnit])

  useEffect(() => {
    if (partnerGroupData?.data?.getPartnersGroup) {
      setPartnerData(partnerGroupData?.data?.getPartnersGroup as PartnerGroup[])
    }
  }, [partnerGroupData?.data?.getPartnersGroup])

  const handleCloseEdit = () => {
    setShowEditModal(!showEditModal)
  }
  const handleCloseUpdate = () => {
    setShowUpdateModal(!showUpdateModal)
  }

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [props, fundUnit])

  const mutateRemoveFundUnit = useDeleteFundUnitMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: DeleteFundUnitMutation,
        _variables: DeleteFundUnitMutationVariables,
        _context: unknown
      ) => {
        const compKey = {
          input: { investorId: props.investorId },
        }
        queryClient.invalidateQueries(useGetFundStructureQuery.getKey(compKey))
        queryClient.invalidateQueries(
          useGetFundUnitQuery.getKey({
            input: { investorId: props.investorId, fundStructureId: props.fundStructure.id },
          })
        )
        Toast(data.deleteFundUnit || 'Removed Fund Unit Successfully !', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  function handleDeleteFundUnit(e: React.MouseEvent, unit: FundUnit) {
    e.stopPropagation()
    deleteBtnRef.current?.setAttribute('data-kt-indicator', 'on')
    setTimeout(() => {
      deleteBtnRef.current?.removeAttribute('data-kt-indicator')
    }, 3000)
    e.preventDefault()

    mutateRemoveFundUnit.mutate({
      input: {
        id: unit.id,
        fundStructureId: props.fundStructure.id,
        investorId: props.investorId,
      },
    })
  }

  const fundUnitOptions = ['Update NAV', 'Delete Unit', 'Edit Unit Details']
  const actionMenu = (unit: FundUnit) => {
    let newOptions = fundUnitOptions
    return newOptions.map((option) => {
      return (
        <label
          key={option}
          className='form-check-custom py-3 bg-hover-primary text-hover-white'
          onClick={(e) => {
            setSelectedData(unit)
            if (option === 'Delete Unit') {
              handleDeleteFundUnit(e, unit)
            } else if (option === 'Edit Unit Details') {
              setShowEditModal(true)
            } else if (option === 'Update NAV') {
              setShowUpdateModal(true)
            }
          }}
        >
          <span className='form-check-label fs-base fw-normal ms-9 cursor-pointer '>{option}</span>
        </label>
      )
    })
  }

  function generateTableData(fundUnit: FundUnit[]) {
    const tableData = fundUnit?.map((unit: FundUnit, index: number) => {
      return (
        <tr key={index}>
          <td>
            <p className='fs-base mb-0 fw-bold'>{unit.name}</p>
          </td>
          <td>
            <p className='fs-base mb-0 text-center'>{unit.class}</p>
          </td>
          <td>
            <p className='fs-base mb-0 text-center'>{unit.noOfUnits}</p>
          </td>
          <td>
            <p className='fs-base mb-0 text-center'>{toFix(unit.nav)}</p>
          </td>
          <td>
            <p className='fs-base mb-0 text-center'>{unit.navDate && isoToDate(unit.navDate)}</p>
          </td>
          <td>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen052.svg'
                className='svg-icon-muted svg-icon-2x'
              />
            </button>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
              data-kt-menu='true'
            >
              {actionMenu(unit)}
            </div>
          </td>
        </tr>
      )
    })
    return tableData
  }

  const headersData = generateHeaders()
  const tableData = generateTableData(fundUnit!)

  return (
    <div className='overflow-auto'>
      <div className='table-responsive'>
        <table className='table align-middle gs-0 gy-4 table-row-dashed border-gray-200'>
          <thead>
            <tr className='fw-bold text-gray-600'>{headersData}</tr>
          </thead>
          <tbody>{tableData}</tbody>
        </table>
        {fundUnit && fundUnit?.length == 0 ? (
          <p className='fs-base text-muted pt-4 text-center'>
            No Fund Unit details were found in this fund.
          </p>
        ) : (
          <></>
        )}
        {showEditModal && (
          <AddFundUnitModal
            showModal={showEditModal}
            handleClose={handleCloseEdit}
            investorId={props.investorId}
            fundStructure={props.fundStructure}
            isEdit={true}
            partnerData={partnerData!}
            data={selectedData}
          />
        )}
        {showUpdateModal && (
          <UpdateFundNavModal
            showModal={showUpdateModal}
            handleClose={handleCloseUpdate}
            investorId={props.investorId}
            fundStructure={props.fundStructure}
            data={selectedData}
          />
        )}
      </div>
    </div>
  )
}

function generateHeaders() {
  const headers: string[] = [
    'Unit Name',
    'Unit Class',
    'Number of Units',
    'Current NAV',
    'NAV Date',
  ]
  const tableHeaders = (header: string) => {
    if (header !== 'options') {
      return (
        <th
          className={`${
            header === 'Unit Name' ? ' min-w-150px text-left' : ' min-w-125px text-center'
          }`}
        >
          {header}
        </th>
      )
    }
  }
  const headersData = headers.map((key: string) => tableHeaders(key))
  return headersData
}

export default FundUnitView
