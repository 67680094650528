import { FC } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../../../modules/auth'
import { queryCache } from '../../../../queries/client'
import { Link } from 'react-router-dom'

const NoAccess: FC = () => {
  const { logout } = useAuth()
  return (
    <div className='d-flex flex-column flex-root h-100'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        <img
          src={toAbsoluteUrl('/media/illustrations/sketchy-1/15.png')}
          alt=''
          className='mw-100 mb-10 h-lg-150px'
        />
        <h1 className='fw-bold mb-10'>Oops, You don't have any access. </h1>
        <button
          className='btn btn-primary'
          onClick={() => {
            logout()
            queryCache.clear()
          }}
        >
          Sign Out
        </button>
      </div>
    </div>
  )
}

export { NoAccess }
