import * as Yup from 'yup'

export interface InvestmentModellingStepperType {
  modelId: number
  modelName: string
  leadInvestor: string
  roundData: InvestmentRoundDetails
  investmentData: AddTransactionRoundDetails[]
  currentStepIndex: number
  latestStepIndex: number
}

export interface InvestmentRoundDetails {
  roundName: string
  modelName: string
  roundId: string
  preMoneyValuation: number
  postMoneyValuation: number
  roundSize: number
  closeDate: string
  autoDiluteEsop: boolean
  postRoundEsopPercentage: number
}

export interface AddTransactionRoundDetails {
  shareholderName: string
  securityType: string
  noOfShares: number
  pricePerShare: number
  investmentAmount: number
  percentage: number
  nominalEquityShares: number
  selectedType: string
}

export const investmentRoundSchemas = Yup.object().shape({
  roundName: Yup.string().required().label('Round Name'),
  modelName: Yup.string().required().label('Model Name'),
  roundId: Yup.string().required().label('Round Identifier'),
  preMoneyValuation: Yup.number()
    .moreThan(0, 'Should be greater than 0')
    .required()
    .label('Pre-money Valuation'),
  roundSize: Yup.number().required().moreThan(0, 'Should be greater than 0').label('Round Size'),
  closeDate: Yup.string().required().label('Close Date'),
  postRoundEsopPercentage: Yup.number().when(['autoDiluteEsop'], {
    is: (autoDiluteEsop: boolean) => !autoDiluteEsop,
    then: Yup.number()
      .required()
      .moreThan(0, 'Should be greater than 0')
      .max(100, 'Should be less than or equal to 100')
      .label('Post-Round Esop'),
    otherwise: Yup.number().notRequired(),
  }),
})
