import { MenuItem } from '../../../../_metronic/layout/components/header/MenuItem'
import { CompanyTypes } from '../../../../generated/graphql'

type Props = {
  companyId: string
  companyType: CompanyTypes
}
function CapTableMenuStage4({ companyId, companyType }: Props) {
  const props: Props = {
    companyId: companyId,
    companyType: companyType,
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-title-gray-700 menu-state-title-primary w-150px p-4'
      data-kt-menu='true'
    >
      <MenuItem
        to={`${
          (companyType === CompanyTypes.Indirect) === true
            ? `/indirectCompanies/captable/${companyType}/${companyId}`
            : `/company/captable/${companyType}/${companyId}`
        }`}
        title='Dashboard'
        props={props}
      />
      <MenuItem
        to={`/company/transactions/${companyType}/${companyId}`}
        title='Transactions'
        props={props}
      />
      <MenuItem
        to={`/company/securities/${companyType}/${companyId}`}
        title='Securities'
        props={props}
      />
      <MenuItem
        to={`${
          (companyType === CompanyTypes.Indirect) === true
            ? `/indirectCompanies/rights/${companyType}/${companyId}`
            : `/company/rights/${companyType}/${companyId}`
        }`}
        title='Rights'
        props={props}
      />
      <MenuItem
        to={`/company/financials/${companyType}/${companyId}`}
        title='Financials'
        props={props}
      />
    </div>
  )
}

export function CapTableMenuStage3({ companyId, companyType }: Props) {
  const props: Props = {
    companyId: companyId,
    companyType: companyType,
  }
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-title-gray-700 menu-state-title-primary w-150px p-4'
      data-kt-menu='true'
    >
      <MenuItem
        to={`${
          (companyType === CompanyTypes.Indirect) === true
            ? `/indirectCompanies/rights/${companyType}/${companyId}`
            : `/company/rights/${companyType}/${companyId}`
        }`}
        title='Rights'
        props={props}
      />
      <MenuItem
        to={`/company/financials/${companyType}/${companyId}`}
        title='Financials'
        props={props}
      />
    </div>
  )
}

export default CapTableMenuStage4
