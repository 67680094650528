import React, { useEffect, useState } from 'react'
import { validateInvestmentModelling } from '../../../../api/investorApi'
import { useParams } from 'react-router-dom'
import ShareInvestmentModelling from './TabViews/ShareInvestmentModelling'
import {
  useGetCompanyDocuments,
  useValidateInvestmentModelling,
} from '../../../modules/auth/core/_requests'

export default function CheckJwtInvestmentModelling() {
  const { token } = useParams()
  const validateAndGetData = useValidateInvestmentModelling()
  useEffect(() => {
    if (token) validateAndGetData.mutate(token)
  }, [token])
  if (validateAndGetData.isLoading) {
    return <>Loading...</>
  }
  return (
    <>
      {validateAndGetData.data ? (
        // <ShareInvestmentModelling investmentModelling={validateAndGetData.data} />
        <></>
      ) : (
        <div>link Expired!</div>
      )}
    </>
  )
}
