import { UserPreferenceType } from '../types/UserPreferences'
import { getAuth, JwtPayload } from '../app/modules/auth'
import jwtDecode from 'jwt-decode'
import { Role, UserInvestor } from '../app/pages/store/userRoleGrants'

export function setUserId(id: number) {
  localStorage.setItem('userId', id.toString())
}

export function getUserId() {
  let userId = undefined
  const auth = getAuth()
  if (auth) {
    const jwt = jwtDecode<JwtPayload>(auth)
    userId = Number(jwt.id)
  }
  return userId
}

export function setUserPreferences(object: UserPreferenceType) {
  localStorage.setItem('userPreferences', JSON.stringify(object))
}

export function setUserRoles(key: string, value: Role[]) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function setUserInvestors(key: string, value: UserInvestor[]) {
  localStorage.setItem(key, JSON.stringify(value))
}

export function getUserInvestors(key: string) {
  const data = localStorage.getItem(key)
  if (data) {
    const roles: UserInvestor[] = JSON.parse(data)
    return roles
  } else {
    return []
  }
}

export function getUserRoles(key: string) {
  const data = localStorage.getItem(key)
  if (data) {
    const roles: Role[] = JSON.parse(data)
    return roles
  } else {
    return []
  }
}

export function setStringItem(key: string, value: string) {
  localStorage.setItem(key, value)
}

export function getStringItem(key: string) {
  return localStorage.getItem(key)
}

export function removeItem(key: string) {
  localStorage.removeItem(key)
}

export function getUserPreferences(id: number) {
  let userPref = localStorage.getItem('userPreferences')
  if (userPref) {
    let object: UserPreferenceType = JSON.parse(userPref)
    if (Number(object?.id) === id) {
      return object
    } else return undefined
  }
  return undefined
}

export function setDashboardMetricEnabled(name: string) {
  let userId = getUserId()
  let userPreferencesObj = userId ? getUserPreferences(userId) : null

  if (userPreferencesObj) {
    let dashboardMetrics = userPreferencesObj.dashboardMetrics
    if (!dashboardMetrics.includes(name)) {
      dashboardMetrics.push(name)
    } else {
      let index = dashboardMetrics.indexOf(name)
      if (index > -1) dashboardMetrics.splice(index, 1)
    }
    const newUserPref = { ...userPreferencesObj, dashboardMetrics: dashboardMetrics }
    setUserPreferences(newUserPref)
  }
}

export function isDashboardMetricEnabled(name: string) {
  let userId = getUserId()
  let userPreferencesObj = userId ? getUserPreferences(userId) : null

  if (userPreferencesObj) {
    if (userPreferencesObj.dashboardMetrics.includes(name)) {
      return true
    } else {
      return false
    }
  }
  return false
}
export function setPortfolioMetricEnabled(name: string) {
  let userId = getUserId()
  let userPreferencesObj = userId ? getUserPreferences(userId) : null

  if (userPreferencesObj) {
    let portfolioMetrics = userPreferencesObj.portfolioMetrics
    if (!portfolioMetrics.includes(name)) {
      portfolioMetrics.push(name)
    } else {
      let index = portfolioMetrics.indexOf(name)
      if (index > -1) portfolioMetrics.splice(index, 1)
    }
    const newUserPref = { ...userPreferencesObj, portfolioMetrics: portfolioMetrics }
    setUserPreferences(newUserPref)
  }
}

export function isPortfolioMetricEnabled(name: string) {
  let userId = getUserId()
  let userPreferencesObj = userId ? getUserPreferences(userId) : null

  if (userPreferencesObj) {
    if (userPreferencesObj.portfolioMetrics.includes(name)) {
      return true
    } else {
      return false
    }
  }
  return false
}
