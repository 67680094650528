import { Navigate, Routes, Route, Outlet, useLocation } from 'react-router-dom'
import { DynamicTableMainWrapper } from './DynamicTableMainWrapper'

const DynamicTableRouter = () => {
  const location = useLocation()
  const myState: any = location.state
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path='/' element={<DynamicTableMainWrapper />} />
      </Route>
      {/* <Route element={<Outlet />}>
        <Route path='/:id' element={<IndividualTablePage />} />
      </Route> */}
    </Routes>
  )
}

export default DynamicTableRouter
