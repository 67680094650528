import { useContext } from 'react'
import { InvestorObject, TransactionObject } from '../../../../generated/graphql'
import { formatNumber, getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import { toFix } from '../../../../utils/ToFix'
import { InvestorParameters } from '../utils/investmentCalculations'
import { GlobalContext } from '../../store/context/globalContext'
import { ShowInfoPopover } from '../../../../utils/InfoPopover'

type CardType = {
  value: number
  name: string
  indicator: string
  isInteger: boolean
  description?: string
  info?: string
  isCurrency?: boolean
}

export type ManualGrowthCardType = {
  irr: number
  moic: number
  unrealizedValue: number
  transactionObjects?: TransactionObject[]
}

export const ManualGrowthCard = (props: ManualGrowthCardType) => {
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  const cards = generateGrowthCards(props)

  const rowData = (cards: CardType[]) => {
    return <div className='row p-0 gy-4'>{cardData(cards)}</div>
  }

  const cardData = (cards: CardType[]) => {
    return (
      <>
        <div className='col-6 ps-0 pe-4'>
          <div className='card h-125px p-6 card-rounded d-flex justify-content-start flex-column'>
            <span
              className='ps-2 fw-bold display-8'
              data-toggle='tooltip'
              data-placement='top'
              title={Number(cards[0].value).toLocaleString(userCurrencyFormat)}
            >
              {cards[0].isCurrency && (
                <span className='fs-1'>{getSymbolOfCurrency(userCurrency) + ' '}</span>
              )}
              {formatNumber({
                value: cards[0].value,
                format: userCurrencyFormat,
              })}
              {cards[0].indicator}
            </span>
            <h6 className='text-muted ps-2 pt-0'>
              {cards[0].name}
              {cards[0].info && cards[0].info !== '' && (
                <span>{ShowInfoPopover(cards[0].info)}</span>
              )}
            </h6>
            <p className='fs-8'>{cards[0].description}</p>
          </div>
        </div>
        <div className='col-6 pe-0 ps-4'>
          <div className='card h-125px p-6 card-rounded d-flex justify-content-start flex-column'>
            <span
              className='ps-2 fw-bold display-8'
              data-toggle='tooltip'
              data-placement='top'
              title={Number(cards[1].value).toLocaleString(userCurrencyFormat)}
            >
              {formatNumber({
                value: cards[1].value,
                format: userCurrencyFormat,
              })}
              {cards[1].indicator}
            </span>
            <h6 className='text-muted ps-2 pt-0'>
              {cards[1].name}
              {cards[1].info && cards[0].info !== '' && (
                <span>{ShowInfoPopover(cards[1].info)}</span>
              )}
            </h6>
            <p className='fs-8'>{cards[1].description}</p>
          </div>
        </div>
      </>
    )
  }

  return (
    <div
      className='card min-h-325px h-auto bg-growth-card mb-md-0 mb-9'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='card-title mx-9 mt-7 pb-0'>
        <div className='d-flex flex-row'>
          <span className='text-white fs-1 pt-md-3 align-bottom'>
            {getSymbolOfCurrency(userCurrency)}
          </span>{' '}
          <span
            className='text-white fw-bold display-6 pe-6 m-0'
            data-toggle='tooltip'
            data-placement='top'
            title={Number(props.unrealizedValue).toLocaleString(userCurrencyFormat)}
          >
            {formatNumber({
              value: props.unrealizedValue,
              format: userCurrencyFormat,
            })}
          </span>
        </div>
        <div className='text-muted text-white fs-8 '>Holding Value</div>
      </div>
      <h2 className='text-white mx-9 mt-10'>Investment Growth</h2>
      <div className='row justify-content-center m-9 mt-0'>{rowData([cards[0], cards[1]])}</div>
    </div>
  )
}

function generateGrowthCards(aggregateData: ManualGrowthCardType) {
  const cardData: CardType[] = [
    {
      value: aggregateData.moic,
      name: 'MOIC',
      indicator: 'x',
      isInteger: false,
      info: 'MOIC (Multiple on Invested Capital) is a metric that measures the total value of an investment, including all returns, divided by the initial investment amount.',
      isCurrency: false,
    },
    {
      value: aggregateData.irr,
      name: 'IRR',
      indicator: '%',
      info: 'IRR (Internal Rate of Return) is a way to calculate the annualized return of an investment that has irregular cash flows over time, such as investments with varying amounts of money added or withdrawn at different times.',
      isInteger: false,
      isCurrency: false,
    },
  ]
  return cardData
}
