import { useEffect } from 'react'
import { AllTransactionsType, CompanyTypes } from '../../../../generated/graphql'
import { formatDate } from '../../../../utils/FormatDate'
import { DonutChartType, DonutChartWrapper } from '../../portfolio/components/DonutChartWrapper'
import { CompanyCardType, CompanyDataCard } from '../components/CompanyDataCard'
import { CompanyHeaderCard } from '../components/CompanyHeader/CompanyHeader'
import { CompanyStage3AGTable } from '../components/CompanyStage3AGTable'
import { useCompanyStageStore } from '../../../../store/CompanyStageStore'

type Props = {
  companyId: string
  data: AllTransactionsType
}

export function HissaCompanyCurrentCaptablePage({ companyId, data }: Props) {
  const companyDetails = data.companyData

  const companyStageStore = useCompanyStageStore()

  useEffect(() => {
    companyStageStore.setCompanyStage(data?.companyData?.companyStage!)
  }, [data])

  const companyParameters: CompanyCardType[] = [
    {
      name: 'Number of FDB Shares',
      value: Number(companyDetails.totalFdbShares),
      gainOrLoss: 8.3,
    },
    {
      name: 'Number of Rounds',
      value: Number(companyDetails.noOfRounds),
      gainOrLoss: 0.3,
    },
    {
      name: 'Current Share Price',
      value: Number(companyDetails.sharePrice),
      gainOrLoss: 0.4,
    },
    // { name: 'Revenue', value: 4500, gainOrLoss: 1.2 },
    // { name: 'Cash Balance', value: 230, gainOrLoss: 9.3 },
  ]

  if (companyDetails.latestRaise && companyDetails.latestRaise > 0) {
    companyParameters.push({
      name: 'Latest Raise',
      value: Number(companyDetails.latestRaise),
      gainOrLoss: 0.4,
    })
  }
  if (companyDetails.valuation && companyDetails.valuation > 0) {
    companyParameters.push({
      name: 'Company Valuation',
      value: Number(companyDetails.valuation),
      gainOrLoss: 8.3,
    })
  }
  if (companyDetails.latestRaiseDate) {
    companyParameters.push({
      name: 'Valuation Date',
      value: formatDate(companyDetails.latestRaiseDate, 'dd-MMM-yyyy'),
      gainOrLoss: 0,
    })
  }

  return (
    <>
      <div className='row mb-9'>
        <div className='col-12'>
          <CompanyHeaderCard
            name={companyDetails.name}
            companyDetails={companyDetails}
            hissaVerified={companyDetails.type === CompanyTypes.Hissa ? true : false}
            companyInfo={''}
            isCurrentCaptable={true}
          />
        </div>
      </div>
      <div className='row mb-9'>
        <div className='col-12'>
          <div className='row gx-9'>
            <div className='col-12 col-md-4 mb-9 mb-md-0'>
              {data.companyData.shareholders && data.companyData.shareholders.length > 0 && (
                <DonutChartWrapper
                  allTransactions={[data]}
                  header={'Shareholders'}
                  type={DonutChartType.Shareholders}
                  className={'min-h-250px mh-250px'}
                />
              )}
            </div>
            <div className='col-12 col-md-4 mb-9 mb-md-0'>
              {data.companyData.securities && data.companyData.securities.length > 0 && (
                <DonutChartWrapper
                  allTransactions={[data]}
                  header={'Security Types'}
                  type={DonutChartType.Securities}
                  className={'min-h-250px mh-250px'}
                />
              )}
            </div>
            <div className='col-12 col-md-4'>
              <CompanyDataCard data={companyParameters} className='h-375px' />
            </div>
          </div>
        </div>
      </div>
      {data && (
        <div className='row mb-9'>
          <div className='col-12'>
            <CompanyStage3AGTable data={data} />
          </div>
        </div>
      )}
    </>
  )
}
