import { Link, useLocation } from 'react-router-dom'

function DealsNavigationMenu() {
  const location = useLocation()
    return (
        <div className='d-flex overflow-auto px-10 header-bg z-index-3 w-100' data-kt-sticky='true'>
          <ul className='nav nav-stretch nav-line-tabs fs-5 fw-500 flex-nowrap border-bottom-transparent h-45px'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link me-6 ` +
                  (location.pathname === '/deals' && 'active text-primary ')
                }
                to='/deals'
              >
                My Docs
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/deals/templateViewer' && 'active text-primary')
                }
                to='/deals/templateViewer'
                data-kt-menu-trigger='hover'
                data-kt-menu-placement='bottom-start'
                data-kt-menu-flip='top-end'
              >
                Template
              </Link>
            </li> 
          </ul>
        </div>
      )
}

export default DealsNavigationMenu