import React, { ChangeEvent, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import {
  AddTagsToDocumentMutation,
  AddTagsToDocumentMutationVariables,
  CompanyObject,
  Document_Vault,
  useAddTagsToDocumentMutation,
  useGetCompanyDataQuery,
  useGetCompanyWiseDataQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import Toast, { ToastType } from '../../../../utils/toast'
import { hexToRGB } from '../../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { selectCustomStyles } from '../../../../utils/Select'

type Props = {
  showModal: boolean
  setShows: () => void
  item: any
  companyName: string
  cin: string
  investorId: number
  id?: number
  isTransaction?: boolean
  isIndirect?: boolean
  companyData?: CompanyObject
}
type SelectOptionType = { label?: string; value?: string }

export default function ManageTags(props: Props) {
  const tagsObjList: any[] = []
  const tagsList = props.item.tags
  tagsList?.map((tag: string) => {
    tagsObjList.push({ value: tag, label: tag })
  })
  const [values, setValues] = useState<any>(tagsObjList)
  const [inputText, setInputText] = useState<string>('')
  const [customTag, setCustomTag] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)
  const [isEqual, setIsEqual] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [removeBadge, setRemoveBadge] = useState<string>('')

  const [badgeList, setBadgeList] = useState<SelectOptionType[]>([
    { value: 'Add New', label: 'Add New' },
    { value: 'Round Documents', label: 'Round Documents' },
    { value: 'Financial Documents', label: 'Financial Documents' },
    { value: 'Share Certificates', label: 'Share Certificates' },
  ])

  const handleChange = (e: any) => {
    setValues(e)

    e?.map((ele: any) => {
      if (ele.value === 'Add New') {
        setValues((values: any) => {
          return values?.filter((element: any) => element?.value !== 'Add New')
        })
        setCustomTag((customTag) => {
          return !customTag
        })
      }
    })
  }
  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value)
    if (e.target.value && e.target.value.length > 0) {
      values.map((badge: any) => {
        if (badge.value === e.target.value) {
          setDisable(true)
          setErrorMessage('Multiple tags with the same name are not allowed ')
          setIsEqual(true)
        } else {
          setDisable(false)
          setErrorMessage('')
          setIsEqual(false)
        }
      })
    }
  }

  const mutateAddTagsToDocument = useAddTagsToDocumentMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: AddTagsToDocumentMutation,
        _variables: AddTagsToDocumentMutationVariables,
        _context: unknown
      ) => {
        const getcompanyDataQuery = useGetCompanyDataQuery.getKey()
        queryClient.invalidateQueries(getcompanyDataQuery)
        if (props.companyData) {
          const compKey = {
            input: {
              type: props.companyData?.type,
              hissaCompanyId: props.companyData?.hissaCompanyId || '',
              id: Number(props.companyData?.id) || 0,
            },
          }
          const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
          queryClient.invalidateQueries(getCompanyWiseQuery)
        }
        props.setShows()
        if (values.length !== 0) {
          Toast('Tags Created Successfully!', ToastType.success)
        } else {
          Toast('Tags Removed Successfully!', ToastType.success)
        }
      },
      onError: (error: any) => {},
    },
    {}
  )
  function getBadgeColor() {
    return {
      backgroundColor: hexToRGB(getCSSVariableValue('--kt-primary'), 0.1),
      color: hexToRGB(getCSSVariableValue('--kt-dark'), 0.8),
    }
  }

  useEffect(() => {
    setValues((values: any) => {
      return values?.filter((element: any) => element?.value !== removeBadge)
    })
  }, [removeBadge])

  function sendTagsToDocuments() {
    const tags: string[] = []

    values.map((element: any) => {
      tags.push(element.value)
    })
    if (props.item.source === 'company') {
      mutateAddTagsToDocument.mutate({
        input: {
          key: props.item.key,
          companyCin: props.cin,
          documentLinkId: 0,
          id: props.id ? props.id : props.investorId,
          tags: tags,
          vaultType: props.id ? Document_Vault.Employee : Document_Vault.Investor,
          source: 'COMPANY',
          companyName: props.companyName,
        },
      })
    } else if (props.item.source === 'link') {
      mutateAddTagsToDocument.mutate({
        input: {
          companyCin: props.cin,
          companyName: props.companyName,
          documentLinkId: Number(props.item.linkId),
          id: props.investorId,
          tags: tags,
          vaultType: Document_Vault.Investor,
          source: 'LINK',
        },
      })
    } else {
      mutateAddTagsToDocument.mutate({
        input: {
          key: props.isTransaction ? props.item.name : props.item.key,
          companyCin: props.cin,
          documentLinkId: 0,
          id: props.id ? props.id : props.investorId,
          tags: tags,
          vaultType: props.id ? Document_Vault.Employee : Document_Vault.Investor,
          source: props.id ? Document_Vault.Employee : Document_Vault.Investor,
          companyName: props.companyName,
          isTransaction: props.isTransaction,
          isIndirect: props.isIndirect,
        },
      })
    }
  }

  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      className=''
      dialogClassName='modal-dialog modal-dialog-centered mw-700px '
      show={props.showModal}
      onHide={() => props.setShows()}
    >
      {/* <Modal.Header className='border border-0'></Modal.Header> */}
      <Modal.Body className='p-9 border border-primary rounded'>
        <div className='col d-flex justify-content-between pb-6'>
          <div className=''>
            <h2 className='fw-bolder text-dark'>Manage Tags</h2>
            <div className='fs-6'>Select the tags for the selected document </div>
          </div>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={() => props.setShows()}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </div>
        <div className='separator mb-4'></div>

        <div>
          <div className='pb-10'>
            {props.item.source !== 'link' ? (
              <KTSVG path='/media/svg/files/pdf.svg' className='svg-icon-muted svg-icon-2x' />
            ) : (
              <KTSVG path='/media/svg/files/doc.svg' className='svg-icon-muted svg-icon-2x' />
            )}
            <>
              <span className='ps-3'>
                {props.isTransaction === true ? props.item.name.split('/').pop() : props.item.key}
              </span>
              {values?.map((badge: any) => (
                <span className='fs-9 badge ms-3' style={getBadgeColor()}>
                  {badge.value}
                  <div
                    className='ps-3 cursor-pointer'
                    onClick={() => {
                      setRemoveBadge(badge.value)
                    }}
                  >
                    <KTSVG
                      className='svg-icon-7 text-dark bg-hover-primary-light text-hover-primary'
                      path='/media/icons/duotune/arrows/arr061.svg'
                    />
                  </div>
                </span>
              ))}
            </>
          </div>

          <div className='col fv-md-row d-md-flex align-items-baseline'>
            <div>
              <label className='d-flex align-items-center fs-5 fw-medium mb-4'>
                Select Applicable Tags
              </label>
            </div>
            <div className='col-8 px-9'>
              {customTag === false ? (
                <Select
                  className=''
                  options={badgeList}
                  placeholder={'--Select--'}
                  value={values}
                  isMulti={true}
                  onChange={(e: any) => handleChange(e)}
                  styles={selectCustomStyles}
                />
              ) : (
                <div className='d-flex'>
                  <div className='me-4'>
                    <input
                      className='form-control form-control-solid me-2'
                      placeholder='Enter Tag Name'
                      onChange={handleChangeValue}
                    />
                  </div>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    type='submit'
                    disabled={disable}
                    onClick={(e) => {
                      setValues((values: any) => [
                        ...values,
                        { value: inputText, label: inputText },
                      ])
                      setCustomTag(false)
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr012.svg'
                    />
                  </button>
                  <button
                    className='btn btn-sm btn-icon btn-active-light-primary mb-9'
                    onClick={() => {
                      setCustomTag(false)
                      setErrorMessage('')
                    }}
                  >
                    <KTSVG
                      className='svg-icon-1 text-primary'
                      path='/media/icons/duotune/arrows/arr061.svg'
                    />
                  </button>
                </div>
              )}
              <div className='text-danger mt-2'>
                {isEqual && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{errorMessage}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex col justify-content-between pt-4'>
          <button className='btn btn-lg btn-secondary mt-3' onClick={() => props.setShows()}>
            <span className='indicator-label'>Cancel</span>
          </button>
          <button
            type='submit'
            onClick={() => {
              sendTagsToDocuments()
            }}
            className='btn btn-lg btn-primary mt-3'
          >
            {!mutateAddTagsToDocument.isLoading && 'Save'}
            {mutateAddTagsToDocument.isLoading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Saving...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className='border border-0'></Modal.Footer> */}
    </Modal>
  )
}
