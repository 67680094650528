import { useMutation, useQuery } from 'react-query'
import {
  changePassword,
  forgotPassword,
  login,
  registration,
  resetPassword,
} from '../../../../api/authentication'
import {
  deleteCompanyLink,
  fillTermsheet,
  getCaptableIframeToken,
  getCompanyDocuments,
  getInvestmentModellingDetails,
  postCompanyLink,
  validateInvestmentModelling,
} from '../../../../api/investorApi'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const REGISTER_URL = `${API_URL}/register`

export function useLogin() {
  return useMutation({ mutationKey: 'Login', mutationFn: login })
}
export function useForgotPassword() {
  return useMutation({ mutationKey: 'ForgotPassword', mutationFn: forgotPassword })
}

export function useResetPasswords() {
  return useMutation({ mutationKey: 'ResetPassword', mutationFn: resetPassword })
}
export function useChangePassword() {
  return useMutation({
    mutationKey: 'changePassword',
    mutationFn: changePassword,
  })
}
export function useRegistration() {
  return useMutation({
    mutationKey: 'register',
    mutationFn: registration,
  })
}

export function useGetCompanyDocuments(IframeToken: string) {
  return useQuery({
    queryFn: getCompanyDocuments,
    queryKey: ['companyDocuments', IframeToken],
  })
}

export function useGetInvestmentModelling(hissaCompanyId: string) {
  return useQuery({
    queryFn: getInvestmentModellingDetails,
    queryKey: ['getInvestmentModelling', hissaCompanyId],
  })
}

export function useValidateInvestmentModelling() {
  return useMutation({
    mutationFn: validateInvestmentModelling,
    mutationKey: 'validateLink',
  })
}

export function useGetCaptableIframeToken(companyId: string) {
  return useQuery({
    queryFn: getCaptableIframeToken,
    queryKey: ['captableIframeToken', companyId],
  })
}

export function useFillTermsheet() {
  return useMutation({
    mutationFn: fillTermsheet,
    mutationKey: 'fillTermsheet',
  })
}

export function usePostCompanyDocumentLink() {
  return useMutation({
    mutationFn: postCompanyLink,
    mutationKey: 'postCompanyDocumentLink',
  })
}

export function useDeleteCompanyDocumentLink() {
  return useMutation({
    mutationFn: deleteCompanyLink,
    mutationKey: 'deleteCompanyDocumentLink',
  })
}
