import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  CompanyDataInput,
  CompanyObject,
  CompanyTypes,
  GetAllInvestorRoundModellingDataQuery,
  InvestorRoundModellingData,
  useGetAllInvestorRoundModellingDataQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { InvestmentModellingAGTable } from '../components/InvestmentModelling/InvestmentModellingAGTable'
import { HStack } from '../../../../utils/Stack'
import InvModelSummaryCard from '../components/InvestmentModelling/InvModelSummaryCard'

type InvModelProps = {
  companyData: CompanyObject
}

function InvestmentModellingWrapper(props: InvModelProps) {
  const { companyType, id } = useParams()
  const navigate = useNavigate()

  const compType = companyType as CompanyTypes
  let hissaCompanyId = ''
  let companyId = 0
  compType === CompanyTypes.Hissa ? (hissaCompanyId = id || '') : (companyId = Number(id) || 0)

  const input: CompanyDataInput = {
    type: compType,
    hissaCompanyId: hissaCompanyId,
    id: companyId,
  }

  const { data, isFetched } = useGetAllInvestorRoundModellingDataQuery<
    GetAllInvestorRoundModellingDataQuery,
    Error
  >(
    graphqlRequestClient,
    {
      input: { hissaCompanyId: hissaCompanyId },
    },
    {
      enabled:
        (hissaCompanyId !== '' || companyId !== 0) &&
        Object.values(CompanyTypes).includes(compType),
    }
  )

  if (!companyType || !id) {
    navigate('/error/404')
  }

  return (
    <>
      <div className='row'>
        <div className='col-12 d-flex justify-content-end'>
          <Link to={`/company/newmodelling/HISSA/${hissaCompanyId}/0`}>
            <button className='px-4 rounded border border-0 border-primary bg-primary h-38px fw-normal text-white'>
              {'Create New Model'}
            </button>
          </Link>
        </div>
      </div>

      {/* Investment Modelling Cards */}
      <div>
        <HStack
          className='gap-9 d-flex flex-row overflow-auto mt-7 pt-2'
          style={{ overflowY: 'hidden', overflowX: 'auto', scrollbarWidth: 'none' }}
        >
          {data?.getAllInvestorRoundModelling?.map((model, index) => (
            <div
              key={index}
              className='cursor-pointer hover-effect'
              onClick={() =>
                navigate(`/company/newmodelling/HISSA/${model?.hissaCompanyId}/${model?.id}`)
              }
            >
              <InvModelSummaryCard modelData={model as InvestorRoundModellingData} />
            </div>
          )) || <></>}
        </HStack>
      </div>

      {/* Investment Modelling AG Table */}
      <div className='row'>
        <div className='col-12'>
          <div className='card rounded my-9'>
            <InvestmentModellingAGTable
              investmentData={data?.getAllInvestorRoundModelling as InvestorRoundModellingData[]}
              hissaCompanyId={hissaCompanyId}
              companyData={props.companyData}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default InvestmentModellingWrapper
