import { ChangeEvent, useEffect, useState } from 'react'
import { Document_Vault } from '../../../../generated/graphql'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import FileUpload from './FileUpload'
import { FilesTable } from './TabViews/FilesTable'
import { useParams } from 'react-router-dom'
import { useGetCompanyDocuments } from '../../../modules/auth/core/_requests'
import _ from 'lodash'

function DocumentViewerWrapper() {
  // const { id, vaultType, companyName, cin } = useParams()
  const { IframeToken } = useParams()

  const { data, isFetched } = useGetCompanyDocuments(IframeToken as string)

  // const mutateUploadFile = useUploadFileToCompanyMutation<Error>(
  //   graphqlRequestClient,
  //   {
  //     onSuccess: (
  //       data: UploadFileToCompanyMutation,
  //       _variables: UploadFileToCompanyMutationVariables,
  //       _context: unknown
  //     ) => {
  //       queryClient.invalidateQueries(getcompanyDataQuery)
  //       handleBreadcrumb(folder[folder.length - 1], folder.length - 1)
  //     },
  //   },
  //   {}
  // )
  // const deleteObject = useRemoveObjectMutation<Error>(
  //   graphqlRequestClient,
  //   {
  //     onSuccess: (
  //       data: RemoveObjectMutation,
  //       _variables: RemoveObjectMutationVariables,
  //       _context: unknown
  //     ) => {
  //       queryClient.invalidateQueries(getcompanyDataQuery)
  //       handleBreadcrumb(folder[folder.length - 1], folder.length - 1)
  //     },
  //   },
  //   {}
  // )
  const [folder, setFolder] = useState<any[]>(['Home'])
  let directoryData: any = []
  if (data?.data.length > 0) {
    directoryData.push(...data?.data)
  }
  const [dirData, setDirData] = useState<any>()
  const [showAddFolder, setShowAddFolder] = useState<boolean>(false)
  const [fileList, setFileList] = useState<any[]>([])
  const [dirList, setDirList] = useState<any[]>([])
  const [orgDirList, setOrgDirList] = useState<any[]>([])
  const [showModal, setShowModal] = useState(false)
  const [key, setkey] = useState<String>('')
  useEffect(() => {
    handleBreadcrumb(folder[folder.length - 1], folder.length - 1)
  }, [data])

  useEffect(() => {
    setDirList(dirData)
    dirList?.map((dir) => {
      if (dir.count === 0 || dir.count === undefined) {
        setFileList((fileList) => _.uniq([dir, ...fileList]))
      } else {
        setFileList((fileList) => _.uniq([dir, ...fileList]))
        setDirList((dirList) => _.uniq([...dirList, ...dir.items]))
      }
    })
  }, [dirData])

  if (!isFetched) {
    return (
      <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
        <span className='spinner-grow'></span>
      </div>
    )
  }

  function handleDirectoryClick(key: string) {
    const data = dirData.filter((item: { name: string }) => item.name === key)[0].items
    setDirData(data)
    setOrgDirList(data)
    setFolder([...folder, key])
  }

  function handleAddFolderClose() {
    setShowAddFolder(false)
  }

  async function handleCreateFolder(name: string) {
    setShowAddFolder(false)
    let key: string = ''
    folder.forEach((e) => {
      if (e != 'Home') {
        key += e + '/'
      }
    })
    key += name
    // mutateUploadFile.mutate({
    //   input: {
    //     name: '',
    //     companyName: companyName,
    //     file: null,
    //     folder: key,
    //     id: id,
    //   },
    // })
    // const data = await postCompanyDocuments(cin || '', [
    //   {
    //     name: key + '/',
    //     key: key + '/',
    //     size: 0,
    //     cin: cin || '',
    //     tags: [],
    //     userId: Number(id),
    //   },
    // ])
  }

  function handleBreadcrumb(item: string, endIndex: number) {
    if (item === 'Home') {
      setDirData(data?.data)
      setOrgDirList(data?.data)
      setFolder(['Home'])
    } else {
      let data = directoryData
      for (let i = 1; i < endIndex + 1; i++) {
        const index = data.findIndex((item: any) => {
          return item.key === folder[i]
        })
        if (index !== -1) {
          data = data[index].items
        }
      }
      setDirData(data)
      setOrgDirList(data)
      setFolder(folder.slice(0, endIndex + 1))
    }
  }

  function handleFileUpload() {
    let name: string = ''
    folder.forEach((e) => {
      if (e != 'Home') {
        name += e + '/'
      }
    })
    setShowModal(true)
    setkey(name)
  }

  function handleFileUploadClose() {
    setShowModal(false)
  }

  function handleDeleteItem(item: any) {
    // let directory: string = ''
    // folder.forEach((e) => {
    //   if (e != 'Home') {
    //     directory += e + '/'
    //   }
    // })
    // directory = directory.substring(0, directory.length - 1)
    // const itemName = item.name.split('/')
    // if (item.size) {
    //   deleteObject.mutate({
    //     input: {
    //       fileName: itemName[itemName.length - 1],
    //       companyName: companyName,
    //       folder: directory,
    //       id: id,
    //     },
    //   })
    // } else {
    //   deleteObject.mutate({
    //     input: {
    //       companyName: companyName,
    //       folder:
    //         directory != ''
    //           ? directory + '/' + itemName[itemName.length - 1]
    //           : itemName[itemName.length - 1],
    //       id: id,
    //     },
    //   })
    // }
    MenuComponent.hideDropdowns(undefined)
  }

  const breadCrumbs = folder.map((item, index) => {
    if (index !== folder.length - 1) {
      return (
        <li className='breadcrumb-item' key={item + index}>
          <p
            onClick={() => handleBreadcrumb(item, index)}
            className='mb-0 text-primary fw-bold fs-base cursor-pointer'
          >
            {item}
          </p>
        </li>
      )
    } else if (index === folder.length - 1) {
      return (
        <li className='breadcrumb-item fs-base fw-bold' aria-current='page' key={item + index}>
          {item}
        </li>
      )
    }
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let filteredFileList: any[] = []
    if (e.target.value.length > 0) {
      fileList.forEach((file) => {
        if (file.count === undefined) {
          let fileName = file.name.split('/') || file.name
          fileName = fileName[fileName.length - 1].toLowerCase()

          file.tags.map((tag: string) => {
            if (tag.toLowerCase().includes(e.target.value.toLowerCase())) {
              filteredFileList.push(file)
            }
          })
          if (fileName.includes(e.target.value.toLowerCase())) {
            filteredFileList.push(file)
          }
        }
      })

      setDirData(_.uniq(filteredFileList))
    } else {
      setDirData(orgDirList)
    }
  }

  return (
    <div className='row mt-2'>
      <div className='card card-body'>
        <div className='d-flex justify-content-end'>
          {folder.length > 1 && folder[folder.length - 1] === 'Private Documents' && (
            <div className='row justify-content-between ps-3'>
              <div className='col-md-12 align-items-end'>
                <button
                  className='btn btn-secondary fs-base fw-bold float-md-end me-3'
                  onClick={() => handleFileUpload()}
                  disabled={showModal}
                >
                  Add Link
                </button>
              </div>
              {
                <FileUpload
                  folder={key}
                  cin={''}
                  vaultType={Document_Vault.Company}
                  id={Number(-1)}
                  show={showModal}
                  handleClose={handleFileUploadClose}
                  investorId={-1}
                  companyName={''}
                  iframeToken={IframeToken}
                />
              }
            </div>
          )}
          <input
            type='text'
            className='form-control w-25 pb-2 form-control-sm w-80 form-control-solid me-3 fs-base'
            name='search'
            placeholder='Search...'
            onChange={(e) => {
              handleChange(e)
            }}
          />
        </div>

        <div className=''>
          <nav aria-label='breadcrumb'>
            <ol className='breadcrumb ps-5'>{breadCrumbs}</ol>
          </nav>
          <div className='row g-6 g-xl-9 mb-xl-9'>
            <div className='col-12 col-sm-12 col-xl'>
              <FilesTable
                handleDeleteItem={handleDeleteItem}
                handleCreateFolder={handleCreateFolder}
                folderData={dirData}
                handleAddFolderClose={handleAddFolderClose}
                showAddFolder={showAddFolder}
                handleDirectoryClick={handleDirectoryClick}
                companyName={'Company Name'}
                companyId={'cin'}
                id={Number(1)}
                vaultType={Document_Vault.Company}
                folderName={folder}
                isGeneric={true}
                iframeToken={IframeToken}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocumentViewerWrapper
