import { useContext } from 'react'
import { AllTransactionsType } from '../../../../generated/graphql'
import { formatNumber, getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import { GlobalContext } from '../../store/context/globalContext'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { aggregateData } from '../../portfolio/components/PortfolioHeaderCards'
import { ChartDataType } from '../../portfolio/components/DonutChartWrapper'
import { Doughnut } from 'react-chartjs-2'
import { KTSVG } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { ShowInfoPopover } from '../../../../utils/InfoPopover'
import { useCompanyNameStore } from '../../../../store/companyNameStore'

type PortfolioCardProps = {
  allTransactions: AllTransactionsType[]
}

export function PortfolioCard(props: PortfolioCardProps) {
  const aggregatedData = aggregateData(props.allTransactions)
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const companyNameStore = useCompanyNameStore()
  const userCurrencyFormat = getCurrency(userCurrency)

  function cardData(
    value: number,
    indicator: string,
    label: string,
    isCurrency: boolean,
    info: string
  ) {
    return (
      <div className=' w-100 border border-dashed border-gray-400 rounded p-4'>
        <h6
          className='m-0 fw-bold'
          data-toggle='tooltip'
          data-placement='top'
          title={Number(value).toLocaleString(userCurrencyFormat)}
        >
          {isCurrency && getSymbolOfCurrency(userCurrency)}
          {formatNumber({
            value: value,
            format: userCurrencyFormat,
          }) + indicator}
        </h6>
        <p className='fs-7 m-0 text-gray-400'>
          {label}
          {info !== '' && <span>{ShowInfoPopover(info)}</span>}
        </p>
      </div>
    )
  }
  const chartData = generateHoldingChart(props.allTransactions, companyNameStore)
  const irrInfo =
    'IRR (Internal Rate of Return) is a way to calculate the annualized return of an investment that has irregular cash flows over time, such as investments with varying amounts of money added or withdrawn at different times.'
  const moicInfo =
    'MOIC (Multiple on Invested Capital) is a metric that measures the total value of an investment, including all returns, divided by the initial investment amount.'
  const CurrentHoldingInfo =
    "Current holding value of an investment is the market value of the investment at a specific point in time. If some of the investments are in a different currency from the user-selected currency, today's exchange rate will be used for the calculations."
  return (
    <div
      className='card h-400px m-0 p-0'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <span className='px-9 pt-8'>
        <h2 className='card-label fw-bold mb-1'>Portfolio</h2>
      </span>

      <div className='card px-9 overflow-auto my-9'>
        <div className='d-flex flex-row justify-content-between gap-4 mb-2'>
          {cardData(aggregatedData.currentValue, '', 'Current Holding', true, CurrentHoldingInfo)}
          {cardData(aggregatedData.noOfHoldingCompanies, '', 'Portfolio Companies', false, '')}
        </div>
        <div className='d-flex flex-row justify-content-between gap-4 mt-2'>
          {cardData(aggregatedData.moic, 'X', 'MOIC', false, moicInfo)}
          {cardData(aggregatedData.irr, '%', 'IRR', false, irrInfo)}
        </div>
      </div>
      <div className='card'>
        <div className='row px-9'>
          <div className='col-4 min-h-125px mh-125px pt-6'>
            <Doughnut data={chartData} options={optionsSeries} />
          </div>

          <div className='col-4  px-0'>
            <p className='mb-0 fw-bold text-gray-600'>Company Name</p>
            <div className='separator my-1'></div>
            <ul className='list-unstyled text-gray-400'>
              {chartData.labels.map((label, index) => {
                return (
                  <li
                    className='text-truncate pb-4'
                    data-toggle='tooltip'
                    data-placement='top'
                    title={label?.toUpperCase()}
                  >
                    <KTSVG
                      path='/media/icons/duotune/abstract/abs050.svg'
                      className='svg-icon-1x text-start text-gray me-1'
                      textColor={chartData.datasets[0].backgroundColor[index]}
                    />
                    {chartData.link[index]}
                  </li>
                )
              })}
            </ul>
          </div>
          <div className='col-4 ps-1'>
            <p className='text-end mb-0 fw-bold text-gray-600'>Holding value</p>
            <div className='my-1 separator'></div>
            <ul className='list-unstyled text-end'>
              {chartData.datasets[0].data.map((value) => {
                return (
                  <li
                    className='fw-bold pb-4'
                    data-toggle='tooltip'
                    data-placement='top'
                    title={Number(value).toLocaleString(userCurrencyFormat)}
                  >
                    {getSymbolOfCurrency(userCurrency)}
                    {formatNumber({
                      value: Number(value),
                      format: userCurrencyFormat,
                    })}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

function generateHoldingChart(allTransactions: AllTransactionsType[], companyNameStore: any) {
  let companyWiseData: ChartDataType[] = []
  allTransactions.forEach((company) => {
    if (company.investorData) {
      const data = company.investorData
      const currentValue = data.reduce((acc, curr) => acc + (curr?.currentValue || 0), 0)
      companyWiseData.push({
        label: company.companyData.name,
        value: currentValue,
        id: company.companyData.id,
        type: company.companyData.type,
        hissaCompanyId: company.companyData.hissaCompanyId || '',
      })
    }
  })
  const bgColors: string[] = []
  for (let i = 0; i < 10; i++) {
    const color = getCSSVariableValue('--kt-donut-' + i)
    bgColors.push(color)
  }
  companyWiseData = companyWiseData.sort((a, b) => b.value - a.value).slice(0, 3)
  const chartData = {
    labels: companyWiseData.map((company) => company.label),
    link: companyWiseData.map((company) => (
      <Link
        className='fs-base fw-bold mb-0 text-gray-500 text-hover-primary '
        to={`/company/summary/${company.type}/${company.hissaCompanyId || company.id}`}
        onClick={() => {
          companyNameStore.setCompanyName(company.label || '')
        }}
      >
        {company.label}
      </Link>
    )),
    datasets: [
      {
        data: companyWiseData.map((company) => company.value),
        backgroundColor: bgColors,
        borderColor: bgColors,
        count: companyWiseData.reduce((a, b) => a + b.value, 0),
      },
    ],
  }
  return chartData
}

const optionsSeries: Object = {
  rotation: 0,
  circumference: 360,
  maintainAspectRatio: false,
  responsive: true,
  radius: '100%',
  cutout: '80%',
  plugins: {
    tooltip: { displayColors: false, enabled: false },
    legend: { display: false },
    title: { display: false },
  },
}
