import { useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { uiDateTime } from '../../../utils/FormatDate'
import AddEECompMetric from './AddEECompMetric'
import _ from 'lodash'
import { kFormatter, kFormatterRupee } from '../../../utils/CurrencyFormatter'
import { str2num } from '../../../utils/Utils'

type Props = {
  metrics: any[]
  compMetrics: any[]
}

const EECompMetrics = ({ metrics, compMetrics }: Props) => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const toggleAddModal = () => setShowAddModal(!showAddModal)
  const [compMetric, setCompMetric] = useState(null)

  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fs-3 mb-1'>Company Metrics</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{compMetrics ? compMetrics.length : 'No'} company metrics available</span>
          </h3>
          <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Click to add a metric'>
            <button
              className='btn btn-sm btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_add_compmetric'
              onClick={() => {
                setCompMetric(null)
                toggleAddModal()
              }}
            >
              <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-2' />
              Add Company Metric
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-5'>
              <thead>
                <tr>
                  <th className='p-0 min-w-125px'></th>
                  <th className='p-0 min-w-125px'></th>
                  <th className='p-0 min-w-125px'></th>
                  <th className='p-0 min-w-40px'></th>
                </tr>
              </thead>
              <tbody>
                {compMetrics &&
                  compMetrics.map((m: any, i: any) => (
                    <tr key={i}>
                      <td>
                        <p className='text-dark text-hover-primary mb-1 fs-6'>{m.name}</p>
                        <span className='text-muted fw-semibold d-block fs-7'>{m.cin}</span>
                      </td>
                      <td>
                        <span className='fw-semibold'>{m.metric.name}</span>
                      </td>
                      {['NUMBER', 'AMOUNT', 'PERCENT'].includes(m.metric.type) ? (
                        <td className='text-end'>
                          <p className='mb-1'>{kFormatter(m.value)}</p>
                          <span className='text-muted d-block fs-7'>{Number(m.value) > 999 && str2num(m.value)}</span>
                        </td>
                      ) : (
                        <td>
                          <p className='mb-1'>{m.value}</p>
                        </td>
                      )}
                      <td className='text-muted'>{uiDateTime(m.created)}</td>
                      <td className='text-end'>
                        <button
                          className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          onClick={() => {
                            setCompMetric(_.cloneDeep(m))
                            toggleAddModal()
                          }}
                        >
                          <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-2' />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AddEECompMetric showAddModal={showAddModal} toggleAddModal={toggleAddModal} metrics={metrics} compMetric={compMetric} />
    </>
  )
}

export { EECompMetrics }
