import { OverlayTrigger, Popover } from 'react-bootstrap'
import { KTSVG } from '../_metronic/helpers/components/KTSVG'

export function ShowInfoPopover(message: string) {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement='bottom'
      rootCloseEvent='mousedown'
      overlay={infoPopover(message)}
    >
      <span
        style={{
          cursor: 'pointer',
        }}
      >
        <KTSVG
          path='media/icons/duotune/general/gen045.svg'
          className='ps-1 svg-icon-1 text-start'
          svgClassName='text-gray-700'
        />
      </span>
    </OverlayTrigger>
  )
}

const infoPopover = (message: string) => (
  <Popover id='popover-basic' className='min-w-350px'>
    <Popover.Body>
      <div className='text-justify'>
        <span>{message}</span>
      </div>
    </Popover.Body>
  </Popover>
)
