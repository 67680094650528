import React, { useEffect, useState } from 'react'
import { graphqlRequestClient } from '../../../queries/client'
import {
  AllTransactionsType,
  GetAllInvestmentsQuery,
  GetAllTransactionsQuery,
  GetEeCompanyMetricsQuery,
  GetEeFiltersQuery,
  GetEeThemesQuery,
  Transaction_Type,
  useGetAllInvestmentsQuery,
  useGetAllTransactionsQuery,
  useGetEeCompanyMetricsQuery,
  useGetEeFiltersQuery,
  useGetEeThemesQuery,
} from '../../../generated/graphql'
import { kFormatter, kFormatterRupee } from '../../../utils/CurrencyFormatter'
import { KTSVG } from '../../../_metronic/helpers'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import Toast, { ToastType } from '../../../utils/toast'
import FilterSelector from './FilterSelector'
import { useNavigate } from 'react-router-dom'

const EEModelExit: React.FC = () => {
  const navigate = useNavigate()
  const [invests, setInvests] = useState<Array<any>>([])
  const [filteredInvests, setFilteredInvests] = useState<Array<any>>([])
  const [filters, setFilters] = useState<Array<String>>([])
  const [filterList, setFilterList] = useState<Array<any>>([])
  const [showFilters, setShowFilters] = useState<boolean>(false)
  const [activeGroup, setActiveGroup] = useState<String>('')
  const [activeFilter, setActiveFilter] = useState<String>('')
  const [appliedFilters, setAppliedFilters] = useState<Array<any>>([])
  const [compMetrics, setCompMetrics] = useState([])
  const [themes, setThemes] = useState<any>([])
  const [invMetric, setInvMetric] = useState<number>(0)
  const [activeTheme, setActiveTheme] = useState<String>('')
  const toggleFilters = () => setShowFilters(!showFilters)
  const [showAddFilterModal, setShowAddFilterModal] = useState<boolean>(false)
  const toggleAddFilterModal = () => setShowAddFilterModal(!showAddFilterModal)
  const [showThemeModal, setShowThemeModal] = useState<boolean>(false)
  const toggleThemeModal = () => setShowThemeModal(!showThemeModal)

  const {
    data: invData,
    isLoading: invLoading,
    error: invError,
  } = useGetAllTransactionsQuery<GetAllTransactionsQuery, Error>(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (invError) Toast(`Oops, something's broken. Error [${invError}]`, ToastType.error)
  }, [invError])
  useEffect(() => {
    if (invData && invData && invData.allTransactions) {
      setInvests(invData.allTransactions)
      setFilteredInvests(invData.allTransactions)
    }
  }, [invData])

  const eeFilters = useGetEeFiltersQuery<GetEeFiltersQuery, Error>(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (eeFilters && eeFilters.data && eeFilters.data.eeFilters) {
      setFilterList(eeFilters.data.eeFilters)
    }
    if (eeFilters && eeFilters.error)
      Toast(
        `Oops, something's broken. Error: ${eeFilters.error?.message.substring(
          0,
          eeFilters.error?.message?.indexOf(':')
        )}`,
        ToastType.error
      )
  }, [eeFilters.data, eeFilters.error])

  const eeCompMetrics = useGetEeCompanyMetricsQuery<GetEeCompanyMetricsQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )
  useEffect(() => {
    if (eeCompMetrics.data && eeCompMetrics.data.eeCompanyMetrics)
      setCompMetrics(eeCompMetrics.data.eeCompanyMetrics)
    if (eeCompMetrics && eeCompMetrics.error)
      Toast(
        `Oops, something's broken. Error: ${eeCompMetrics.error?.message.substring(
          0,
          eeCompMetrics.error?.message?.indexOf(':')
        )}`,
        ToastType.error
      )
  }, [eeCompMetrics.data, eeCompMetrics.error])

  const eeThemes = useGetEeThemesQuery<GetEeThemesQuery, Error>(graphqlRequestClient, {}, {})
  useEffect(() => {
    if (eeThemes.data && eeThemes.data.eeThemes) {
      const themes = eeThemes.data.eeThemes.reduce((themes: any, item: any) => {
        const group = themes.get(item.name) || []
        group.push(item)
        themes.set(item.name, group)
        return themes
      }, new Map())
      setThemes(themes)
    }
    if (eeThemes && eeThemes.error)
      Toast(
        `Oops, something's broken. Error: ${eeThemes.error?.message.substring(
          0,
          eeThemes.error?.message?.indexOf(':')
        )}`,
        ToastType.error
      )
  }, [eeThemes.data, eeThemes.error])

  const resetFilters = () => {
    setFilters([])
    setAppliedFilters([])
    setInvMetric(0)
  }

  const getCDKeys = (f: string) => f.split(/[*/()+-]+/).map((k) => k.substring(k.indexOf('.') + 1))
  const checkDataHasKeys = (cd: any, keys: string[]) => keys.reduce((a, b) => a && cd[b], true)
  const checkMetricsHasKeys = (keys: string[]) =>
    keys.reduce((a, b) => a && compMetrics.find((m: any) => m.metric.moniker === b)!!, true)

  const selectFilter = (f: String) => {
    if (!filters.find((a) => a === f)) {
      setFilters([...filters, f])
      const fil = getFilter(f)
      const cdKeys = getCDKeys(fil.formula)

      var filFound = false
      if (checkDataHasKeys(invests[0].companyData, cdKeys)) filFound = true
      if (f === 'Sector') filFound = true //TODO need to check; for now sector/industry is hardcoded as comp name split ' ' [2]
      if (checkMetricsHasKeys(cdKeys)) filFound = true
      if (!filFound)
        Toast('Unable to filter investments with formula, ignoring filter', ToastType.warning)
    } else {
      setFilters(filters.filter((a) => a !== f))
      setAppliedFilters(appliedFilters.filter((a) => a.fn !== f))
    }
    setActiveFilter(f)
  }

  const getFilter = (f: any) => {
    const ff = filterList.filter((a) => a.name === f)
    return ff && ff.length === 1 && ff[0]
  }
  const getAppliedFilter = (f: any) => appliedFilters.find((a) => a.fn === f)
  const calcFormulaValue = (cd: any, f: string) => {
    const cdKeys = getCDKeys(f)
    if (cdKeys.length === 1) return cd[cdKeys[0]]
    const oper = f.substring(f.split(/[*/()+-]+/)[0].length, f.split(/[*/()+-]+/)[0].length + 1)
    switch (oper) {
      case '*':
        return cd[cdKeys[0]] * cd[cdKeys[1]]
      case '+':
        return cd[cdKeys[0]] + cd[cdKeys[1]]
      case '-':
        return cd[cdKeys[0]] - cd[cdKeys[1]]
      case '/':
        return cd[cdKeys[0]] / cd[cdKeys[1]]
      case '%':
        return cd[cdKeys[0]] % cd[cdKeys[1]]
    }
  }

  const getFilterMin = (f: any) => 0
  const getFilterMax = (f: any) => {
    const formula = getFilter(f).formula
    const met: any = compMetrics.find(
      (m: any) => m.metric.moniker === formula.substring(formula.indexOf('.') + 1)
    )
    if (formula.startsWith('company.') && formula.match(/([a-z_.]+)/)) {
      const vals = invests.map((i) => calcFormulaValue(i.companyData, formula) || -1)
      return Math.max(...vals) === -1 ? 1 : Math.max(...vals)
    } else if (met) {
      const vals = compMetrics
        .filter((i: any) => i.metricId === met.metricId)
        .map((i: any) => i.value)
      return Math.max(...vals) === -1 ? 1 : Math.max(...vals)
    } else return 800
  }
  const getFilterList = (f: any) => {
    const formula = getFilter(f).formula
    if (formula.startsWith('company.') && formula.match(/([a-z_.]+)/)) {
      const vals = invests
        .map((i) => i.companyData['name'].split(' ')[1]) //TODO: change to industry after api gets values
        .filter((v, i, a) => v && a.indexOf(v) === i)
      return vals
    } else return []
  }

  const filterGroups = () =>
    filterList.map((f) => f.group).filter((v, i, array) => array.indexOf(v) === i)

  const updateFilters = (fn: string, fMin: number, fMax: number, fList: string[]) => {
    const newFilters = appliedFilters.filter((f) => f.fn !== fn)
    newFilters.push({ fn, fMin, fMax, fList })
    setAppliedFilters(newFilters)
  }

  useEffect(() => {
    // filter logic - to revisit useMemo approach?
    if (appliedFilters.length > 0) {
      var filInv: any[] = invests
      let invMet = -1
      appliedFilters.map((f) => {
        const fil = getFilter(f.fn)
        const cdKeys = getCDKeys(fil.formula)
        const fInv =
          fil.type.indexOf('LIST') >= 0
            ? filInv.filter((i) => {
                const val = i.companyData['name'].split(' ')[1] //TODO: change to industry after api gets values
                return !val || f.fList.includes(val)
              })
            : fil.formula.startsWith('metrics.')
            ? filInv.filter((i) => {
                const moniker = cdKeys[0]
                const val: any = compMetrics
                  .filter((f: any) => f.name.toUpperCase() === i.companyData['name'].toUpperCase())
                  .filter((f: any) => f.metric.moniker === moniker)
                return val[0] && val[0].value >= f.fMin && val[0].value <= f.fMax
              })
            : filInv.filter((i) => {
                const val = calcFormulaValue(i.companyData, fil.formula)
                return val && val >= f.fMin && val <= f.fMax
              })
        const iM = compMetrics.filter((f: any) => f.metric.moniker === cdKeys[0]).length
        invMet = invMet === -1 || iM < invMet ? iM : invMet
        filInv = fInv
      })
      setFilteredInvests(filInv)
      setInvMetric(invMet)
    } else {
      setFilteredInvests(invests)
      setInvMetric(0)
    }
  }, [appliedFilters])

  const applyTheme = (t: any) => {
    setShowFilters(true)
    const th = themes.get(t)
    setFilters(th.map((f: any) => filterList.find((fl) => fl.id == f.filterId).name))
    const apFil = th.map((f: any) => {
      const f2a = {
        fn: filterList.find((fl) => fl.id == f.filterId).name,
        fMin: Number(f.min),
        fMax: Number(f.max),
        fList: f.inList && f.inList.split(',').map((s: any) => s.trim().toUpperCase()),
      }
      return f2a
    })
    setAppliedFilters(apFil)
  }
  const createPortfolio = () => {
    if (appliedFilters.length === 0)
      Toast('Apply filters or pick a theme, and then create portfolio', ToastType.warning)
    else
      navigate('/exitEnabler/createPortfolio', {
        state: { props: { companies: filteredInvests, type: 'Sell' } },
      })
  }

  return (
    <div className='row'>
      {showFilters && (
        <div className='card col-3 p-0 me-4'>
          <div className='card-header border-0 pt-5'>
            <div className='card-title align-items-start justify-content-start flex-column m-0'>
              <h4>Filters</h4>
              <span className='text-muted mt-1 fw-semibold fs-7'>
                {filters.length > 0 ? filters.length : 'No'}
                {filters.length === 1 ? ' filter' : ' filters'} applied
              </span>
            </div>
            <div className='flex-column m-0'>
              <button className='btn btn-sm btn-light-primary px-3 py-1' onClick={resetFilters}>
                Clear
              </button>
            </div>
          </div>
          {filters.map((f) => (
            <Card className='card-bordered no-border-radius p-3' key={getFilter(f).name}>
              <div
                className='accordion-header cursor-pointer d-flex justify-content-between'
                onClick={(e) => setActiveFilter(activeFilter === f ? '' : f)}
              >
                <Card.Title className='card-title mb-0'>
                  {f}
                  {activeFilter === f && (
                    <span onClick={(e) => selectFilter(f)}>
                      <KTSVG
                        className='svg-icon svg-icon-4 ms-3'
                        path='/media/icons/duotune/general/gen034.svg'
                      />
                    </span>
                  )}
                </Card.Title>
                <span className='accordion-icon'>
                  <KTSVG
                    className='svg-icon svg-icon-4'
                    path={`/media/icons/duotune/arrows/${
                      activeFilter === f ? 'arr065.svg' : 'arr064.svg'
                    }`}
                  />
                </span>
              </div>
              <Card.Body className={`collapse p-0 ${activeFilter === f && 'show'}`}>
                {getFilter(f).type.indexOf('LIST') >= 0 ? (
                  <FilterSelector
                    filter={getFilter(f)}
                    list={getFilterList(f)}
                    updateFilters={updateFilters}
                    appliedFilter={getAppliedFilter(f)}
                  />
                ) : (
                  <FilterSelector
                    filter={getFilter(f)}
                    min={getFilterMin(f)}
                    max={getFilterMax(f)}
                    updateFilters={updateFilters}
                    appliedFilter={getAppliedFilter(f)}
                  />
                )}
              </Card.Body>
            </Card>
          ))}
          <div className='card-body py-3 text-center'>
            <button
              className='btn btn-sm btn-light-primary mt-15'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_add_filter'
              onClick={() => toggleAddFilterModal()}
            >
              <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
              Choose Filters
            </button>
          </div>
        </div>
      )}
      <div className='card col p-0'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fs-3 mb-1'>My Investments</span>
            {!invLoading && (
              <span className='text-muted mt-1 fw-semibold fs-7'>
                {filters.length > 0 || filteredInvests.length > 0
                  ? filteredInvests.length
                  : invests.length}{' '}
                of {invests.length} companies
                {invMetric > 0 && (
                  <span className='text-info ms-1'>
                    Only {invMetric} of {invests.length} companies have the required metrics
                  </span>
                )}
              </span>
            )}
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='How do you want to model your exit?'
          >
            <button
              className='btn btn-sm btn-light-primary mx-1'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_apply_filter'
              onClick={() => toggleFilters()}
            >
              <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-2' />
              Apply Filters
            </button>
            <button
              className='btn btn-sm btn-light-primary mx-1'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_use_theme'
              onClick={() => setShowThemeModal(true)}
            >
              <KTSVG path='/media/icons/duotune/graphs/gra012.svg' className='svg-icon-2' />
              Pick a Theme
            </button>
            <button
              className='btn btn-sm btn-light-primary mx-1'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_portfolio'
              onClick={() => createPortfolio()}
            >
              <KTSVG path='/media/icons/duotune/graphs/gra007.svg' className='svg-icon-2' />
              Create Portfolio
            </button>
          </div>
        </div>
        <div className='card-body py-3'>
          {invLoading ? (
            <div className='w-100 my-20 d-flex justify-content-center align-items-center'>
              <span className='spinner-grow'></span>
            </div>
          ) : filters.length > 0 && filteredInvests.length === 0 ? (
            <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
              <p className='text-muted'>Hmmm, we didn't find anything with these filters</p>
              <p className='text-muted'>Try adjusting filter range values or the filters itself</p>
            </div>
          ) : (
            <div className='table-responsive'>
              <table className='table align-middle table-row-dashed dataTable no-footer'>
                <thead>
                  {!showFilters ? (
                    <tr key='nofilter'>
                      <th className='min-w-300px'>Company Name</th>
                      <th className='min-w-150px'>Investor / Fund</th>
                      <th className='min-w-100px text-end'>
                        Total Investment (<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                      </th>
                      <th className='min-w-100px text-end'>Total Shares</th>
                    </tr>
                  ) : (
                    <tr key='filter'>
                      <th className='min-w-250px'>
                        <p className='mb-1'>Company Name</p>
                        <span className='text-muted d-block fs-7'>Investor / Fund</span>
                      </th>
                      <th className='min-w-100px text-end'>
                        Invested Amount
                        <br />(<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                      </th>
                      {filters.map((f, i) => {
                        const fil = getFilter(f)
                        const cdKeys = getCDKeys(fil.formula)

                        if (
                          f === 'Sector' ||
                          (fil.formula.startsWith('company.') &&
                            fil.formula.match(/([a-z_.]+)/) &&
                            invests.find((i) => checkDataHasKeys(i.companyData, cdKeys))) ||
                          (fil.formula.startsWith('metrics.') &&
                            fil.formula.match(/([a-z_.]+)/) &&
                            checkMetricsHasKeys(cdKeys))
                        ) {
                          return fil.type.indexOf('INRUPEES') >= 0 ||
                            fil.type.indexOf('INCRORES') >= 0 ? (
                            <th key={i} className='min-w-100px text-end'>
                              {f}
                              <br />(<span style={{ fontFamily: 'Georgia' }}>₹</span>)
                            </th>
                          ) : (
                            <th key={i} className='min-w-100px text-end'>
                              {f}
                            </th>
                          )
                        } else return ''
                      })}
                    </tr>
                  )}
                </thead>
                <tbody>
                  {(filters.length > 0 || filteredInvests.length > 0
                    ? filteredInvests
                    : invests
                  ).map((comp: AllTransactionsType, i: any) => {
                    const totInvAmt =
                      (comp.investorData &&
                        comp.transactionData
                          ?.filter(
                            (x) =>
                              x?.transactionType === Transaction_Type.Buy ||
                              x?.transactionType === Transaction_Type.Sell
                          )
                          .reduce((tot: number, e) => tot + Math.abs(e?.amount || 0), 0)) ||
                      0
                    const totSh =
                      (comp.investorData &&
                        comp.investorData.reduce(
                          (tot: number, e) => tot + (e?.fdbShares || 0),
                          0
                        )) ||
                      0

                    return !showFilters ? (
                      <tr key={i}>
                        <td>
                          <p className='mb-1'>{comp.companyData.name.toUpperCase()}</p>
                          <span className='text-muted d-block fs-7'>{comp.companyData.cin}</span>
                        </td>
                        <td className='fw-semibold'>
                          <p className='mb-1'>{comp.investorData && comp.investorData[0]?.name}</p>
                          {/* TODO: investor type vc/fund.. has to be retrieved from api */}
                          <span
                            className={`badge ${
                              comp.investorData &&
                              comp.investorData[0]?.name.match(
                                /(Fund|Ltd|Limited|Trust|Investments)/
                              )
                                ? 'badge-light-primary'
                                : 'badge-light-info'
                            }`}
                          >
                            {comp.investorData &&
                            comp.investorData[0]?.name.match(/(Fund|Ltd|Limited|Trust|Investments)/)
                              ? 'VC Fund'
                              : 'Individual'}
                          </span>
                        </td>
                        <td className='text-end'>
                          <p className='mb-1'>{kFormatter(totInvAmt)}</p>
                          <span className='text-muted d-block fs-7'>
                            {totInvAmt > 999 &&
                              kFormatterRupee(Number(totInvAmt.toString().split('.')[0]))}
                          </span>
                        </td>
                        <td className='text-end'>
                          <p className='mb-1'>{kFormatter(totSh)}</p>
                          <span className='text-muted d-block fs-7'>
                            {totSh > 999 && kFormatterRupee(Number(totSh.toString().split('.')[0]))}
                          </span>
                        </td>
                      </tr>
                    ) : (
                      <tr key={i}>
                        <td>
                          <p className='mb-1'>{comp.companyData.name.toUpperCase()}</p>
                          <span className='text-muted d-block fs-7'>
                            {comp.investorData && comp.investorData[0]?.name}
                          </span>
                        </td>
                        <td className='text-end'>
                          <p className='mb-1'>{kFormatter(totInvAmt)}</p>
                          <span className='text-muted d-block fs-7'>
                            {kFormatterRupee(Number(totInvAmt.toString().split('.')[0]))}
                          </span>
                        </td>
                        {filters.map((f, i) => {
                          const fil = getFilter(f)
                          const cdKeys = getCDKeys(fil.formula)
                          const met: any = compMetrics.find(
                            (m: any) =>
                              m.name.toUpperCase() === comp.companyData.name.toUpperCase() &&
                              m.metric.moniker === cdKeys[0]
                          )
                          const val =
                            fil.type.indexOf('LIST') >= 0
                              ? comp.companyData['name'].split(' ')[1]
                              : fil.formula.startsWith('metrics.') && met
                              ? met.value! || ''
                              : calcFormulaValue(comp.companyData, fil.formula)
                          return (
                            val && (
                              <td key={i} className='text-end'>
                                <p className='mb-1'>
                                  {fil.type.indexOf('LIST') >= 0
                                    ? val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()
                                    : val === 0
                                    ? 'NA'
                                    : kFormatter(val)}
                                </p>
                                {val > 999 && fil.type.indexOf('LIST') < 0 && (
                                  <span className='text-muted d-block fs-7'>
                                    {kFormatterRupee(Number(val.toString().split('.')[0]))}
                                  </span>
                                )}
                              </td>
                            )
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <Modal
        id='kt_modal_add_filter'
        aria-hidden='true'
        show={showAddFilterModal}
        onHide={toggleAddFilterModal}
      >
        <Modal.Header className='p-2'>
          <Modal.Title className='ps-5'>Add Filters</Modal.Title>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={toggleAddFilterModal}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='mh-400px scroll-y me-n7 pe-7'>
            {filterGroups().map((g) => (
              <Card className='card-bordered no-border-radius p-3' key={g}>
                <div
                  className='accordion-header cursor-pointer d-flex justify-content-between'
                  onClick={(e) => setActiveGroup(activeGroup === g ? '' : g)}
                >
                  <Card.Title className='card-title mb-0'>{g}</Card.Title>
                  <span className='accordion-icon'>
                    <KTSVG
                      className='svg-icon svg-icon-4'
                      path={`/media/icons/duotune/arrows/${
                        activeGroup === g ? 'arr065.svg' : 'arr064.svg'
                      }`}
                    />
                  </span>
                </div>
                <Card.Body className={`collapse p-0 ${activeGroup === g && 'show'}`}>
                  {filterList
                    .filter((f) => f.group === g)
                    .map((f, i) => (
                      <div
                        className='form-check form-check-sm form-check-custom form-check-solid py-1 text-primary'
                        key={i}
                      >
                        <input
                          className='form-check-input me-3'
                          type='checkbox'
                          value={f.name}
                          defaultChecked={filters.filter((a) => a === f.name).length > 0}
                          onClick={(e) => selectFilter(f.name)}
                        />
                        {f.name}
                      </div>
                    ))}
                </Card.Body>
              </Card>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <div className='d-flex justify-content-between'>
            <button
              type='button'
              className='btn btn-sm btn-secondary me-3'
              onClick={toggleAddFilterModal}
            >
              Done
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        id='kt_modal_use_theme'
        aria-hidden='true'
        show={showThemeModal}
        onHide={toggleThemeModal}
      >
        <Modal.Header className='p-2'>
          <Modal.Title className='ps-5'>Pick a Theme</Modal.Title>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={toggleThemeModal}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='mh-400px scroll-y me-n7 pe-7'>
            {Array.from(themes.keys()).map((t: any, i) => {
              const th = themes.get(t)
              return (
                <Card className='card-bordered no-border-radius p-3' key={i}>
                  <div
                    className='accordion-header cursor-pointer d-flex justify-content-between'
                    onClick={(e) => setActiveTheme(activeTheme === t ? '' : t)}
                  >
                    <Card.Title className='card-title text-primary'>{t}</Card.Title>
                    <span className='accordion-icon'>
                      <KTSVG
                        className='svg-icon svg-icon-4'
                        path={`/media/icons/duotune/arrows/${
                          activeTheme === t ? 'arr065.svg' : 'arr064.svg'
                        }`}
                      />
                    </span>
                  </div>
                  <Card.Body className={`collapse p-0 ${activeTheme === t && 'show'}`}>
                    {th.map((f: any, i: any) => {
                      const fil = filterList.find((fl) => fl.id == f.filterId)
                      return (
                        fil && (
                          <div className='me-n7 pe-7' key={i}>
                            <Row>
                              <Col>{fil.name}</Col>
                              <Col>
                                {f.min && (
                                  <span className='fw-semibold'>
                                    {f.min ? f.min + ' to ' + f.max : ''}
                                  </span>
                                )}
                                {f.inList &&
                                  f.inList.split(',').map((val: any, i: any) => (
                                    <span
                                      key={i}
                                      className='badge badge-secondary fw-semibold me-1'
                                    >
                                      {val}
                                    </span>
                                  ))}
                              </Col>
                            </Row>
                          </div>
                        )
                      )
                    })}
                    <div className='text-end'>
                      <button
                        className='btn btn-sm btn-light-primary px-3 py-1'
                        onClick={() => applyTheme(t)}
                      >
                        Apply Theme
                      </button>
                    </div>
                  </Card.Body>
                </Card>
              )
            })}
          </div>
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <div className='d-flex justify-content-between'>
            <button
              type='button'
              className='btn btn-sm btn-secondary me-3'
              onClick={toggleThemeModal}
            >
              Done
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export { EEModelExit }
