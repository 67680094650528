import { Modal } from 'react-bootstrap'
import {
  GetGlobalCompaniesQuery,
  GlobalCompanySearchType,
  useGetGlobalCompaniesQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient } from '../../../../queries/client'
import { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import _ from 'lodash'
import { CompanySearch } from './CompnaySearchBar'

type CompanyTOWatchlistProps = {
  showModal: boolean
  handleClose: () => void
  watchlistId: number
  watchlistName?: string
  existingCompanies: string[]
}
export function AddCompany(props: CompanyTOWatchlistProps) {
  const [searchOptions, setSearchOptions] = useState<boolean>(false)
  const [allCompanies, setAllCompanies] = useState<GlobalCompanySearchType[]>([])
  const [filteredCompanies, setFilteredCompanies] = useState<GlobalCompanySearchType[]>([])

  const { data: globalCompanies } = useGetGlobalCompaniesQuery<GetGlobalCompaniesQuery, Error>(
    graphqlRequestClient,
    {},
    {}
  )

  useEffect(() => {
    if (globalCompanies && globalCompanies.globalCompaniesSearch) {
      setAllCompanies(
        _.cloneDeep(
          globalCompanies.globalCompaniesSearch.filter(
            (company) => !props.existingCompanies.includes(company?.id!)
          )
        ) as GlobalCompanySearchType[]
      )
    }
  }, [globalCompanies])

  function getSearchedCompanies(search: string) {
    if (!search) {
      setFilteredCompanies([])
      return
    }

    const searchLower = search.toLowerCase()

    const startsWithCompanies = allCompanies.filter((company) => {
      const name = company.name?.toLowerCase() || ''
      const dba = company.dba?.toLowerCase() || ''
      return name.startsWith(searchLower) || dba.startsWith(searchLower)
    })

    const includesCompanies = allCompanies.filter((company) => {
      const name = company.name?.toLowerCase() || ''
      const dba = company.dba?.toLowerCase() || ''
      return (
        (name.includes(searchLower) && !name.startsWith(searchLower)) ||
        (dba.includes(searchLower) && !dba.startsWith(searchLower))
      )
    })

    const filteredResult = [...startsWithCompanies, ...includesCompanies]

    setFilteredCompanies(filteredResult)
  }

  return (
    <Modal
      id='kt_modal_add_inv'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={props.showModal}
      onHide={props.handleClose}
    >
      <div className='col d-flex justify-content-between px-9'>
        <div className='py-9'>
          <h2 className='card-header fw-bolder text-dark'>Add Company to {props.watchlistName}</h2>
        </div>
        <button
          className='btn btn-sm btn-icon btn-active-light-primary mt-6'
          type='button'
          data-bs-toggle='tooltip'
          title='Close'
          onClick={props.handleClose}
        >
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </button>
      </div>

      <Modal.Body className='p-9 pt-0'>
        <div className='fv-row mb-4 justify'>
          <span
            className={`d-flex flex-column items-center bg-white w-full border border-1 border-gray-400 ${
              filteredCompanies.length > 0 ? ' rounded shadow-sm' : 'rounded '
            }`}
            style={{
              boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
              border: '1',
              borderColor: '#F1F1F2',
              zIndex: 6,
            }}
          >
            <span className='d-flex flex-row'>
              <KTSVG
                path={'media/icons/duotune/general/gen021.svg'}
                className='ps-4 svg-icon-2x text-start fs-9 pt-3'
                svgClassName='text-gray-700'
              />
              <input
                type='search'
                placeholder='Search'
                className='form-control border-0 rounded'
                onClick={() => {
                  setSearchOptions(!searchOptions)
                }}
                onChange={(e) => {
                  getSearchedCompanies(e.target.value)
                }}
              />
            </span>
            <CompanySearch filteredCompanies={filteredCompanies} watchlistId={props.watchlistId} />
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer className='border-0 p-8'>
        <div className='d-flex col justify-content-between'>
          <button className='btn btn-lg btn-secondary' onClick={props.handleClose}>
            <span className='text-gray-800'>Cancel</span>
          </button>
          <button type='submit' className='btn btn-lg btn-primary' onClick={props.handleClose}>
            {<span className='indicator-label'>Done</span>}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
