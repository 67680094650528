import { Modal } from 'react-bootstrap'
import { useIsMutating } from 'react-query'
import { useFormik } from 'formik'
import { KTSVG } from '../../../_metronic/helpers'
import Select from 'react-select'
import * as Yup from 'yup'
import { selectCustomStyles } from '../../../utils/Select'
import { graphqlRequestClient, queryClient } from '../../../queries/client'
import { AddEeThemeMutation, useAddEeThemeMutation, useGetEeThemesQuery } from '../../../generated/graphql'
import Toast, { ToastType } from '../../../utils/toast'
import { useEffect, useState } from 'react'

type Props = {
  showAddModal: boolean
  toggleAddModal: () => void
  filters: any[]
  theme: string
}

function AddEETheme({ showAddModal, toggleAddModal, filters, theme }: Props) {
  const filtersList: any[] = []
  filters && filters.map((m) => filtersList.push({ label: m.name, value: m.id }))
  const [listType, setListType] = useState(false)

  const addForm = useFormik({
    initialValues: {
      id: -1,
      name: '',
      filterId: -1,
      min: '',
      max: '',
      inList: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required('Enter theme name').min(3, 'Name must be at least 3 characters'),
      filterId: Yup.number().required('Select filter').min(0, 'Select filter'),
      min: Yup.number().when('filterId', {
        is: (filterId: any) => filterId > 0 && filters.find((f: any) => f.id == filterId).type.indexOf('LIST') < 0,
        then: Yup.number().typeError('Enter valid number').required('Enter min value').lessThan(Yup.ref('max'), 'Must be less than max value'),
        otherwise: Yup.number().notRequired(),
      }),
      max: Yup.number().when('filterId', {
        is: (filterId: any) => filterId > 0 && filters.find((f: any) => f.id == filterId).type.indexOf('LIST') < 0,
        then: Yup.number().typeError('Enter valid number').required('Enter max value').moreThan(Yup.ref('min'), 'Must be more than min value'),
        otherwise: Yup.number().notRequired(),
      }),
      inList: Yup.string().when('filterId', {
        is: (filterId: any) => filterId > 0 && filters.find((f: any) => f.id == filterId).type.indexOf('LIST') >= 0,
        then: Yup.string()
          .trim()
          .required('Enter list of values')
          .min(3, 'List is too short')
          .matches(/^[a-zA-Z ,]+[a-zA-Z ]+$/, 'Comma separated list of values (no special chars)'),
        otherwise: Yup.string().notRequired(),
      }),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: function (values) {
      const addData = {
        name: values.name.trim(),
        filterId: values.filterId,
        min: Number.parseFloat(values.min.toString()),
        max: Number.parseFloat(values.max.toString()),
        inList: values.inList,
      }
      addMutate.mutate({ input: addData })
    },
  })

  const addMutate = useAddEeThemeMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (data: AddEeThemeMutation) => {
        queryClient.invalidateQueries(useGetEeThemesQuery.getKey())
        addForm.resetForm()
        Toast(data.addEETheme.message, ToastType.success)
        toggleAddModal()
      },
      onError: (error: any) => Toast(error.response.errors[0].message, ToastType.error),
    },
    {}
  )

  useEffect(() => {
    if (theme && theme !== '') addForm.setFieldValue('name', theme)
    else addForm.setFieldValue('name', '')
    addForm.validateForm()
  }, [theme])

  function selectFilter(e: any) {
    const fType = filters.filter((f: any) => f.id === e.value)[0].type
    if (fType.indexOf('LIST') >= 0) setListType(true)
    else setListType(false)
    addForm.setFieldValue('filterId', e.value)
  }

  function handleCancel() {
    addForm.resetForm()
    toggleAddModal()
  }

  return (
    <Modal id='kt_modal_add_theme' aria-hidden='true' show={showAddModal} onHide={toggleAddModal}>
      <form onSubmit={addForm.handleSubmit}>
        <Modal.Header className='p-2'>
          <Modal.Title className='ps-5'>{theme === null || theme === '' ? 'New Theme' : 'Add Theme Filter'}</Modal.Title>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary'
            type='button'
            data-bs-toggle='tooltip'
            title='Close'
            onClick={toggleAddModal}
          >
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='mh-400px scroll-y me-n7 pe-7'>
            <div className='fv-row mb-6 col-12 pe-4'>
              <label className='form-label required'>Name</label>
              <input
                type='text'
                className='form-control'
                placeholder='Enter name'
                {...addForm.getFieldProps('name')}
                readOnly={theme !== null && theme !== ''}
              />
              <div className='text-danger mt-2'>
                {addForm.touched.name && addForm.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addForm.errors.name}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='mb-6'>
              <label className='form-label '>Filter</label>
              <Select options={filtersList} name='filterId' onChange={selectFilter} styles={selectCustomStyles} />
              <div className='text-danger mt-2'>
                {addForm.touched.filterId && addForm.errors.filterId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{addForm.errors.filterId}</div>
                  </div>
                )}
              </div>
            </div>
            {addForm.values.filterId > 0 && !listType && (
              <div className='row mb-4'>
                <div className='col-6'>
                  <label className={listType ? 'form-label' : 'form-label required'}>Min Value</label>
                  <input type='text' className='form-control' placeholder='Enter min value' {...addForm.getFieldProps('min')} />
                  <div className='text-danger mt-2'>
                    {addForm.touched.min && addForm.errors.min && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{addForm.errors.min}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-6'>
                  <label className={listType ? 'form-label' : 'form-label required'}>Max Value</label>
                  <input type='text' className='form-control' placeholder='Enter max value' {...addForm.getFieldProps('max')} />
                  <div className='text-danger mt-2'>
                    {addForm.touched.max && addForm.errors.max && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{addForm.errors.max}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {addForm.values.filterId > 0 && listType && (
              <div className='mb-4'>
                <label className={listType ? 'form-label required' : 'form-label'}>List of Values</label>
                <input type='text' className='form-control' placeholder='Enter comma separated list' {...addForm.getFieldProps('inList')} />
                <div className='text-danger mt-2'>
                  {addForm.touched.inList && addForm.errors.inList && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{addForm.errors.inList}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className='p-2'>
          <div className='d-flex justify-content-between'>
            <button type='button' className='btn btn-sm btn-secondary me-3' onClick={handleCancel}>
              Cancel
            </button>
            <button type='submit' className='btn btn-sm btn-primary' disabled={useIsMutating() > 0}>
              {useIsMutating() === 0 && (theme === null || theme === '') ? 'New Theme' : 'Add Theme Filter'}
              {useIsMutating() > 0 && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default AddEETheme
