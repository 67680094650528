import { useContext } from 'react'
import { formatNumber, getCurrency, getSymbolOfCurrency } from '../../../../utils/CurrencyFormatter'
import { GlobalContext } from '../../store/context/globalContext'
// import { Link } from 'react-router-dom'
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { getInitials } from '../../../../utils/Utils'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import Toast, { ToastType } from '../../../../utils/toast'

type WatchlistTickerCards = {
  logo?: string
  companyName?: string
  valuation?: number
  companyId?: string
  year?: string
  index: number
}

export function WatchlistTickerCard(props: WatchlistTickerCards) {
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)

  return (
    <span
      className='fs-base fw-bold mb-0 text-gray-800 text-hover-primary'
      // to={`/company/summary/HISSA/${props.companyId}`}
      onClick={() => {
        Toast('Coming Soon!', ToastType.warning)
      }}
    >
      <div
        className='card h-75px w-250px p-6 border border-transparent border-hover-growth-card cursor-pointer hover-effect bg-hover-ticker'
        style={{
          boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
          border: '1',
          borderColor: '#F1F1F2',
        }}
      >
        <div className='d-flex flex-row align-middle'>
          <span className='col-2'>
            {props.logo && props.logo !== '' && props.logo !== 'NA' ? (
              <div className='symbol symbol-35px h-35px w-35px symbol-fixed position-relative'>
                <img src={toAbsoluteUrl(props.logo)} alt='' />
              </div>
            ) : (
              <div className='bg-secondary h-35px w-35px fw-bold d-flex justify-content-center align-items-center'>
                <p
                  className='mb-0 fs-1'
                  style={{
                    color: getCSSVariableValue('--kt-donut-' + ((props.index + 9) % 10)),
                  }}
                >
                  {getInitials(props.companyName || '')}
                </p>
              </div>
            )}
          </span>
          <span className='ps-4 col-6'>
            <p
              className='fs-7 m-0 text-truncate text-hover-primary text-black'
              data-toggle='tooltip'
              data-placement='top'
              title={props.companyName?.toUpperCase()}
            >
              {props.companyName?.toUpperCase()}
            </p>
          </span>
          <span className='col-4'>
            <span
              className='text-end fs-7 d-flex justify-content-end text-black'
              data-toggle='tooltip'
              data-placement='top'
              title={Number(props.valuation)?.toLocaleString('en-IN')}
            >
              {getSymbolOfCurrency(userCurrency) + ' '}
              {formatNumber({
                value: props.valuation,
                format: userCurrencyFormat,
              })}
            </span>
            <p className='fs-9 text-end text-gray-500 mb-0'>Valuation</p>
            <p className='fs-9 text-end text-gray-500'>{props.year}</p>
          </span>
        </div>
      </div>
    </span>
  )
}
