import { useEffect, useRef, useState } from 'react'
import { formatNumber } from '../../../../../utils/CurrencyFormatter'
import { isoToDate } from '../../../../../utils/FormatDate'
import { MenuComponent } from '../../../../../_metronic/assets/ts/components'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import Toast, { ToastType } from '../../../../../utils/toast'
import {
  CompanyTypes,
  DeleteValuationDetailsMutation,
  DeleteValuationDetailsMutationVariables,
  GetUrlForS3ObjectQuery,
  SetDefaultValuationMutation,
  SetDefaultValuationMutationVariables,
  useGetCompanyWiseDataQuery,
  ValuationDetailsObject,
  useDeleteValuationDetailsMutation,
  useGetAllInvestmentsQuery,
  useGetAllTransactionsQuery,
  useGetUrlForS3ObjectQuery,
  useSetDefaultValuationMutation,
} from '../../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../../queries/client'
import { hexToRGB } from '../../../../../utils/HexToRgba'
import { getCSSVariableValue } from '../../../../../_metronic/assets/ts/_utils'
import { PDFModalViewer } from '../TabViews/PDFModalViewer'
import EditHissaValuationDetailsModal from '../../../manual-investments/components/EditValuationDetails/EditHissaValuationDetailsModal'

export function ValuationNameRender(data: any) {
  return (
    <div className='pt-2'>
      <p className='fw-bold'> {data.data.valuationName}</p>
    </div>
  )
}

export function ValuationTypeRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.valuationType}</p>
    </div>
  )
}

export function ValuationStatusRender(data: any) {
  // const [selectedRow, setSelectedRow] = useState<any>()
  const mutateSetDefaultValuation = useSetDefaultValuationMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        responseData: SetDefaultValuationMutation,
        _variables: SetDefaultValuationMutationVariables,
        _context: unknown
      ) => {
        const compKey = {
          input: {
            type: data.data.companyDetails.type as CompanyTypes,
            hissaCompanyId: data.data.companyDetails.hissaCompanyId || '',
            id: Number(data.data.companyDetails.id) || 0,
          },
        }
        const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
        queryClient.invalidateQueries(getCompanyWiseQuery)
        const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()
        queryClient.invalidateQueries(getAllTransactionQuery)
        Toast(
          responseData.setDefaultValuation! || 'Set default Valuation details Successfully!',
          ToastType.success
        )
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  return (
    <div className='pt-2'>
      <div
        className='fs-base mb-0 text-center '
        onClick={(e) => {
          mutateSetDefaultValuation.mutate({
            input: {
              id: Number(data.data.valuation.id),
              companyId: data.data.valuation.companyId,
              companyName: data.data.companyDetails.name,
              companyType: data.data.companyDetails.type as CompanyTypes,
              hissaCompanyId: data.data.companyDetails.hissaCompanyId,
              valuationName: data.data.valuation.name,
              setDefault: !data.data.valuation.isDefault,
            },
          })
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        <span
          key={data.data.index}
          className={`badge fs-9 fw-semibold mb-1 me-2 `}
          style={
            data.data.valuation.isDefault
              ? {
                  backgroundColor: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.1),
                  color: hexToRGB(getCSSVariableValue('--kt-donut-' + 1), 0.8),
                }
              : {
                  backgroundColor: hexToRGB(getCSSVariableValue('--kt-gray-600'), 0.1),
                  color: hexToRGB(getCSSVariableValue('--kt-gray-600'), 0.8),
                }
          }
        >
          {data.data.valuation.isDefault ? 'Default' : 'Set as Default'}
          {/* {mutateSetDefaultValuation.isLoading && selectedRow?.id === data.data.valuation.id ? (
            <span className='spinner-border spinner-border-sm ms-2 '></span>
          ) : null} */}
        </span>
      </div>
    </div>
  )
}

export function MethodologyRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.methodology}</p>
    </div>
  )
}

export function ValuationReportRender(data: any) {
  const [state, setState] = useState(false)
  const [bucket, setBucket] = useState<any>()
  const [key, setKey] = useState('')
  const [showPdfModal, setShowPdfModal] = useState(false)

  const query = useGetUrlForS3ObjectQuery<GetUrlForS3ObjectQuery, Error>(
    graphqlRequestClient,
    { input: { key, bucket: bucket } },
    {
      enabled: state,
    }
  )

  if (query.isFetched && state === true) {
    if (key.toLowerCase().endsWith('pdf')) {
      setState(false)
      setShowPdfModal(true)
    } else {
      window.open(query.data?.urlForS3Object || '')
      setState(false)
    }
  }

  function handleClick(item: any) {
    setBucket(process.env.REACT_APP_S3_INVESTOR_BUCKET || '')
    setKey(item.fileUrl)
    setState(true)
  }
  const fileName = data.data.valuation.fileUrl.split('/')
  return (
    <div className='pt-2'>
      <div
        className='d-flex align-items-center'
        onClick={() => {
          data.data.valuation.fileUrl !== '' && handleClick(data.data.valuation)
        }}
      >
        <div className='symbol symbol-35px me-5' style={{ cursor: 'pointer' }}>
          {data.data.valuation.fileUrl !== '' && (
            <img
              src={
                data.data.valuation.fileUrl.toLowerCase().endsWith('.pdf')
                  ? toAbsoluteUrl('/media/svg/files/pdf.svg')
                  : toAbsoluteUrl('/media/svg/files/doc.svg')
              }
              alt=''
            />
          )}
        </div>
        <span className='text-wrap fs-base cursor-pointer'>
          <p
            className='text-truncate mb-0'
            data-toggle='tooltip'
            data-placement='top'
            title={fileName[fileName.length - 1]}
            style={{ maxWidth: '190px' }}
          >
            {fileName[fileName.length - 1]}
          </p>
        </span>
      </div>
      <PDFModalViewer
        show={showPdfModal}
        handleClose={() => setShowPdfModal(false)}
        url={query.data?.urlForS3Object || ''}
        documentName={key.split('/')[key.split('/').length - 1]}
      />
    </div>
  )
}

export function SharePriceRender(data: any) {
  return (
    <div className='pt-2'>
      <p>
        {formatNumber({
          value: data.data.sharePrice,
          format: data.data.userCurrencyFormat,
        })}
      </p>
    </div>
  )
}

export function DateOfValuationRender(data: any) {
  return (
    <div className='pt-2'>
      <p>{data.data.dateOfValuation ? isoToDate(data.data.dateOfValuation) : '-'}</p>
    </div>
  )
}

export function TagsRender(data: any) {
  return (
    <div className='pt-2'>
      {data.data.valuation.tags &&
        data.data.valuation.tags.map((tag: string, index: number) => {
          return (
            <span
              key={index}
              className={`badge fs-9 fw-semibold mb-1 me-2 `}
              style={{
                background: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 0.2),
                color: hexToRGB(getCSSVariableValue('--kt-donut-' + (index % 10)), 1),
              }}
            >
              {tag}
            </span>
          )
        })}
    </div>
  )
}

export function ValuationActionRender(data: any) {
  const [showEditModal, setShowEditModal] = useState(false)
  const deleteBtnRef = useRef<HTMLButtonElement | null>(null)
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [data])

  const mutateRemoveValuation = useDeleteValuationDetailsMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        values: DeleteValuationDetailsMutation,
        _variables: DeleteValuationDetailsMutationVariables,
        _context: unknown
      ) => {
        if (data.data.companyDetails) {
          const compKey = {
            input: {
              type: data.data.companyDetails?.type,
              hissaCompanyId: data.data.companyDetails?.hissaCompanyId || '',
              id: Number(data.data.companyDetails?.id) || 0,
            },
          }
          const getCompanyWiseQuery = useGetCompanyWiseDataQuery.getKey(compKey)
          queryClient.invalidateQueries(getCompanyWiseQuery)
        }

        const getAllTransactionQuery = useGetAllTransactionsQuery.getKey()
        queryClient.invalidateQueries(getAllTransactionQuery)
        Toast(data.deleteValuationDetails || 'Removed Valuation details !', ToastType.success)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )

  function handleRemoveCompany(
    e: React.MouseEvent,
    selectedId: number,
    companyId: number,
    investorId: number,
    hissaCompanyId: string,
    companyName: string,
    hissaVerified: boolean,
    valuationName: string,
    valuation: ValuationDetailsObject
  ) {
    if (!valuation?.isDefault) {
      e.stopPropagation()
      deleteBtnRef.current?.setAttribute('data-kt-indicator', 'on')
      setTimeout(() => {
        deleteBtnRef.current?.removeAttribute('data-kt-indicator')
      }, 3000)
      e.preventDefault()

      mutateRemoveValuation.mutate({
        input: {
          id: selectedId,
          companyId: companyId,
          investorId: investorId,
          companyName: companyName,
          hissaVerified: hissaVerified,
          hissaCompanyId: hissaCompanyId,
          valuationName: valuationName,
        },
      })
    } else {
      Toast("Can't delete Default valuation", ToastType.error)
    }
  }

  const valuationOptions = ['Delete Valuation', 'Edit Valuation']
  const actionMenu = (valuation: ValuationDetailsObject) => {
    let newOptions = valuationOptions
    return newOptions.map((option) => {
      return (
        <label
          key={option}
          className='form-check-custom py-3 bg-hover-primary text-hover-white'
          onClick={(e) => {
            if (option === 'Delete Valuation') {
              handleRemoveCompany(
                e,
                Number(valuation.id),
                Number(data.data.companyDetails.id),
                Number(data.data.investorDetails ? data.data.investorDetails[0].id : 0),
                data.data.companyDetails.hissaCompanyId,
                data.data.companyDetails.name,
                data.data.companyDetails.type === CompanyTypes.Hissa ? true : false,
                valuation.valuationName,
                valuation
              )
            } else if (option === 'Edit Valuation') {
              setShowEditModal(true)
            }
          }}
        >
          <span className='form-check-label fs-base ms-6 fw-normal cursor-pointer  '>{option}</span>
        </label>
      )
    })
  }
  return (
    <div className='px-0'>
      {data.data.schemeName !== 'Total' && data.data.dateOfInvestment !== null && (
        <button
          type='button'
          className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen052.svg'
            className='svg-icon-muted svg-icon-2x'
          />
        </button>
      )}

      {showEditModal && (
        <EditHissaValuationDetailsModal
          showModal={showEditModal}
          handleClose={() => setShowEditModal(false)}
          companyDetails={data.data.companyDetails}
          valuationDetails={data.data.valuation}
        />
      )}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
        data-kt-menu='true'
      >
        {actionMenu(data.data.valuation)}
      </div>
    </div>
  )
}
