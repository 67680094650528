import { init, setUserId, track } from '@amplitude/analytics-browser'
import jwtDecode from 'jwt-decode'
import { getAuth, JwtPayload } from '../app/modules/auth'

export const amplitudeInit = () => {
  if (process.env.REACT_APP_AMPLITUDE?.length! > 0) {
    init(process.env.REACT_APP_AMPLITUDE!, undefined, {})
  }
}

export function handleEventForTracking({
  eventName,
  eventType = 'navigation',
  success = true,
}: {
  eventName: string
  eventType?: 'navigation' | 'custom' | 'hover' | 'API'
  success?: boolean
}) {
  const screen = window.location.pathname
  const auth = getAuth()!
  const jwt = jwtDecode<JwtPayload>(auth)
  const prefixText = eventType === 'API' ? 'API: ' : ''
  const suffixText = eventType === 'API' ? (success ? ' Success' : ' Failure') : ''
  const eventNameDetail = `${prefixText}${eventName}${suffixText}`

  if (jwt) {
    setUserId(jwt.emailId ?? 'Unknown')
  }
  const width = window.innerWidth
  const height = window.innerHeight
  const deviceType = getDeviceType()

  track(
    eventNameDetail,
    {
      path: screen,
      eventType,
      clickType: deviceType,
      width,
      height,
    },
    {}
  )
}

export function handleClickForTracking(event: any) {
  if (
    (event.target as HTMLButtonElement).tagName === 'BUTTON' ||
    (event.target as HTMLElement).tagName === 'A' ||
    (event.target as HTMLElement).getAttribute('event-name') ||
    (event.target as HTMLElement).getAttribute('navigation-name')
  ) {
    const auth = getAuth()!
    const jwt = jwtDecode<JwtPayload>(auth)
    const clickType =
      (event.target as HTMLElement).tagName === 'A'
        ? 'link'
        : (event.target as HTMLButtonElement).tagName === 'BUTTON'
        ? 'button'
        : (event.target as HTMLElement).getAttribute('navigation-name')
        ? 'navigation'
        : 'custom'
    const innertext = event.target.innerText
    const eventDetail =
      (event.target as HTMLElement).getAttribute('event-name') ||
      (event.target as HTMLElement).getAttribute('navigation-name')
    const actionName = (eventDetail || event.target.innerText) ?? ''

    const screen = window.location.pathname
    const eventName = `${actionName} Clicked`

    if (jwt) {
      setUserId(jwt.emailId ?? 'Unknown')
    }
    const width = window.innerWidth
    const height = window.innerHeight
    const deviceType = getDeviceType()

    track(
      eventName,
      {
        path: screen,
        elementText: innertext,
        clickType,
        eventType: 'click',
        deviceType,
        width,
        height,
      },
      {}
    )
  }
}

export function handlePageLoadForTracking(event: any) {
  const screen = event.pathname
  const eventName = `Page Viewed: ${screen}`
  const auth = getAuth()!
  const jwt = jwtDecode<JwtPayload>(auth)
  if (jwt) {
    setUserId(jwt.emailId ?? 'Unknown')
  }
  const width = window.innerWidth
  const height = window.innerHeight
  const deviceType = getDeviceType()

  track(
    eventName,
    {
      path: screen,
      eventType: 'page view',
      deviceType,
      width,
      height,
    },
    {}
  )
}

function getDeviceType() {
  const screenWidth = window.innerWidth // Get the current screen width

  // Check against your breakpoints
  if (screenWidth >= 1536) {
    return '2xl'
  } else if (screenWidth >= 1280) {
    return 'xl'
  } else if (screenWidth >= 1024) {
    return 'lg'
  } else if (screenWidth >= 768) {
    return 'md'
  } else if (screenWidth >= 640) {
    return 'sm'
  } else {
    return 'xs' // Or any default you want for smaller screens
  }
}

function decodeAuthToken(accesstoken: string) {
  const decodedToken = jwtDecode(accesstoken) as {
    id: string
    emailId: string
  }
  return decodedToken
}
