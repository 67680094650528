import { useContext } from 'react'
import { KTSVG } from '../_metronic/helpers'
import { formatNumber, getCurrency } from './CurrencyFormatter'
import { GlobalContext } from '../app/pages/store/context/globalContext'

export function GainBadge({ badgeValue, indicator }: { badgeValue: number; indicator: string }) {
  const { state } = useContext(GlobalContext)
  const { userCurrency } = state
  const userCurrencyFormat = getCurrency(userCurrency)
  return (
    <div>
      <span
        className={`badge badge-md w-65px h-30px fs-8 fw-bold mb-1 me-2 text-success bg-success-badge justify-content-center`}
        style={{
          fontFamily: 'arial',
        }}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr003.svg' className='svg-icon-1x text-success' />
        {badgeValue}
        {indicator}
      </span>
    </div>
  )
}

export function LossBadge({ badgeValue, indicator }: { badgeValue: number; indicator: string }) {
  return (
    <div>
      <span
        className={`badge badge-md w-65px h-30px fs-8 fw-bold mb-1 me-2 text-danger bg-danger-badge justify-content-center`}
        style={{
          fontFamily: 'arial',
        }}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr004.svg' className='svg-icon-1x text-success' />
        {badgeValue}
        {indicator}
      </span>
    </div>
  )
}
