import Select from 'react-select'
import { KTSVG } from '../../../../../_metronic/helpers'
import { getInitials } from '../../../../../utils/Utils'
import { selectCustomStyles } from '../../../../../utils/Select'
import {
  CompanyDataInput,
  CompanyObject,
  CompanyTypes,
  useGetCompanyWiseDataQuery,
} from '../../../../../generated/graphql'
import { useNavigate } from 'react-router-dom'
import { queryClient } from '../../../../../queries/client'
import ManageCompanyTags from '../../../portfolio/components/ManageTags'
import { useState } from 'react'
import { ShowTags } from '../../../../../utils/Tags'

type Props = {
  name: string
  companyDetails: CompanyObject
  hissaVerified: boolean
  companyInfo: string
  isCurrentCaptable: boolean
  isIndirect?: boolean
}

export const CompanyHeaderCard = (props: Props) => {
  const navigate = useNavigate()
  const [showTagsModal, setShowTagsModal] = useState(false)
  const incorporationYear = new Date(props.companyDetails.incorporationDate!).getFullYear() || ''
  const userCompanies =
    (JSON.parse(localStorage.getItem('userCompanies')!) as CompanyDataInput[]) || []
  const companyOptions: { label: string; value: string }[] = []
  userCompanies.forEach((comp) => {
    companyOptions.push({
      label: comp.name!,
      value: `/company/summary/${comp.type}/${
        comp.type === CompanyTypes.Hissa ? comp.hissaCompanyId : comp.id
      }`,
    })
  })

  const setShowModalFunction = (state: boolean) => setShowTagsModal(state)

  const handleUserNavigation = (route: string) => {
    const getCompanyWiseDataQueryKey = useGetCompanyWiseDataQuery.getKey()
    queryClient.resetQueries(getCompanyWiseDataQueryKey)
    navigate(route)
  }
  const founders =
    props.companyDetails.shareholders
      ?.filter((inv) => inv?.type === 'Founder')
      .map((obj) => obj?.name) || []
  return (
    <div
      className='card'
      style={{
        boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
        border: '1',
        borderColor: '#F1F1F2',
      }}
    >
      <div className='card-body p-10'>
        <div className='row'>
          <div className='col-lg-9'>
            <div className='row'>
              <div className='col-md-2 col-xxl-1'>
                {props.companyDetails?.logo &&
                props.companyDetails?.logo !== '' &&
                props.companyDetails?.logo !== 'NA' ? (
                  <div className='symbol symbol-150px symbol-fixed position-relative mb-5'>
                    <img src={props.companyDetails?.logo} alt='' />
                  </div>
                ) : (
                  <div className='mb-5 rounded h-85px w-85px fw-bold d-flex justify-content-center align-items-center shadow-sm'>
                    <p className='mb-0 fs-3x text-initial'>{getInitials(props.name)}</p>
                  </div>
                )}
              </div>
              <div className='col-md-10 p-md-0'>
                <h1 className='fw-bold pt-md-4 mb-2 ps-xxl-10'>
                  {props.name}{' '}
                  {props.hissaVerified && (
                    <span>
                      <KTSVG
                        path='/media/icons/duotune/general/gen043.svg'
                        className='ps-md-2 svg-icon-2x align-bottom text-primary '
                      />
                    </span>
                  )}
                  {props.companyDetails.dba !== '' && (
                    <div className='text-muted fs-7'>{props.companyDetails.dba}</div>
                  )}
                </h1>

                <div className='py-2 ps-xxl-10'>
                  <span className='text-start'>
                    <span className=''>
                      <img src={'/media/icons/duotune/general/industry.svg'} alt='' />
                      <span className='mx-2 fs-3 text-gray-800 align-bottom pe-md-4'>
                        {props.companyDetails?.industry || 'Others'}
                      </span>
                    </span>

                    {/* {props.hissaVerified && (
                      <span className=''>
                        <KTSVG
                          path='/media/icons/duotune/general/gen064.svg'
                          className='ps-md-2 svg-icon-2x text-center text-primary '
                        />
                        <span className='mx-2 fs-3 text-gray-800 align-bottom pe-md-4'>
                          {'Exit'}
                        </span>
                      </span>
                    )} */}

                    {props.companyDetails?.jurisdiction && (
                      <span className=''>
                        <KTSVG
                          path='/media/icons/duotune/maps/map004.svg'
                          className='ps-md-2 svg-icon-2x text-center text-primary '
                        />
                        <span className='mx-2 fs-3 text-gray-800 align-bottom pe-md-4'>
                          {props.companyDetails?.jurisdiction || 'India'}
                        </span>
                      </span>
                    )}

                    {incorporationYear !== '' && (
                      <span className=''>
                        <KTSVG
                          path='/media/icons/duotune/general/gen063.svg'
                          className='ps-md-3 svg-icon-2x text-center text-primary '
                        />
                        <span className='mx-2 fs-3 text-gray-800 align-bottom'>
                          {incorporationYear}
                        </span>
                      </span>
                    )}
                  </span>
                  <span
                    onClick={() => {
                      setShowTagsModal(true)
                    }}
                  >
                    <span
                      className={` ${
                        props.companyDetails.tags && props.companyDetails.tags.length > 0
                          ? 'ps-4'
                          : ''
                      } `}
                    >
                      {ShowTags(props.companyDetails.tags as string[])}
                    </span>

                    {props.companyDetails.tags && props.companyDetails.tags.length === 0 && (
                      <span data-toggle='tooltip' data-placement='top' title={'Manage Tags'}>
                        <KTSVG
                          path='/media/icons/duotune/abstract/abs054.svg'
                          className='ps-md-3 svg-icon-2x text-start text-primary'
                        />
                      </span>
                    )}
                  </span>
                  {showTagsModal && (
                    <ManageCompanyTags
                      companyType={props.companyDetails.type}
                      isIndirect={props.isIndirect || false}
                      companyId={props.companyDetails.id}
                      hissaCompanyId={props.companyDetails.hissaCompanyId || ''}
                      companyName={props.companyDetails.name}
                      showModal={showTagsModal}
                      setShow={setShowModalFunction}
                      cin={props.companyDetails.cin || ''}
                      tags={props.companyDetails.tags as string[]}
                      currency={props.companyDetails.companyCurrency}
                      currentSharePrice={props.companyDetails.sharePrice || 1}
                      jurisdiction={props.companyDetails.jurisdiction || 'India'}
                    />
                  )}
                </div>
              </div>

              <p className='pt-4 pt-md-0 mb-0'>{props.companyInfo}</p>
              <div className='pt-2 pb-2 pb-md-0'>
                {founders.length > 0 && `Founders : ${founders.join(', ')}`}
              </div>
            </div>
          </div>
          {!props.isCurrentCaptable && (
            <div className='col-lg-3 pb-1'>
              <Select
                className=''
                options={companyOptions}
                name='company'
                onChange={(e: any) => handleUserNavigation(e.value)}
                value={{ label: props.companyDetails.name }}
                styles={selectCustomStyles}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
