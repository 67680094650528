import { useEffect, useRef, useState } from 'react'
import {
  CapitalLedgerType,
  DeleteLedgerTransactionMutation,
  DeleteLedgerTransactionMutationVariables,
  FundStructure,
  GetCapitalLedgerTransactionQuery,
  GetPartnersGroupQuery,
  LedgerTransaction,
  PartnerGroup,
  useDeleteLedgerTransactionMutation,
  useGetCapitalLedgerTransactionQuery,
  useGetFundStructureQuery,
  useGetPartnersGroupQuery,
} from '../../../../generated/graphql'
import { graphqlRequestClient, queryClient } from '../../../../queries/client'
import { isoToDate } from '../../../../utils/FormatDate'
import AddLedgerTransactionModal from './AddLedgerTransactionModal'
import { kFormatter } from '../../../../utils/CurrencyFormatter'
import { KTSVG } from '../../../../_metronic/helpers'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import Toast, { ToastType } from '../../../../utils/toast'
import { Modal } from 'react-bootstrap'

type Props = {
  fundStructureId: number
  investorId: number
  data: LedgerTransaction[]
  fundStructure: FundStructure
}

function CapitalTransactionView(props: Props) {
  const [partnersGroup, setPartnersGroup] = useState<PartnerGroup[]>()
  const [capitalTransaction, setCapitalTransaction] = useState<LedgerTransaction[]>()
  const [selectedData, setSelectedData] = useState<any>()
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const capitalTransactionData = useGetCapitalLedgerTransactionQuery<
    GetCapitalLedgerTransactionQuery,
    Error
  >(
    graphqlRequestClient,
    { input: { investorId: props.investorId, fundStructureId: props.fundStructureId } },
    {}
  )

  const getPartnerGroups = useGetPartnersGroupQuery<GetPartnersGroupQuery, Error>(
    graphqlRequestClient,
    { input: { investorId: props.investorId, fundStructureId: props.fundStructureId } },
    {}
  )

  const mutateRemoveTransaction = useDeleteLedgerTransactionMutation<Error>(
    graphqlRequestClient,
    {
      onSuccess: (
        data: DeleteLedgerTransactionMutation,
        _variables: DeleteLedgerTransactionMutationVariables,
        _context: unknown
      ) => {
        queryClient.invalidateQueries(
          useGetCapitalLedgerTransactionQuery.getKey({
            input: { investorId: props.investorId, fundStructureId: props.fundStructureId },
          })
        )
        queryClient.invalidateQueries(useGetFundStructureQuery.getKey({ input: props.investorId }))

        Toast(
          data.deleteCapitalLedgerTransaction || 'Removed Capital transaction Successfully !',
          ToastType.success
        )
        setShowDeleteModal(false)
      },
      onError: (error: any) => {
        Toast(error.response.errors[0].message, ToastType.error)
      },
    },
    {}
  )
  const loading = mutateRemoveTransaction.isLoading
  useEffect(() => {
    if (capitalTransactionData?.data?.getCapitalLedgerTransaction) {
      setCapitalTransaction(
        capitalTransactionData?.data?.getCapitalLedgerTransaction as LedgerTransaction[]
      )
    }
  }, [capitalTransactionData?.data?.getCapitalLedgerTransaction])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 50)
  }, [props, capitalTransactionData])

  useEffect(() => {
    if (getPartnerGroups?.data?.getPartnersGroup) {
      setPartnersGroup(getPartnerGroups?.data?.getPartnersGroup as PartnerGroup[])
    }
  }, [getPartnerGroups?.data?.getPartnersGroup])

  const handleCloseEdit = () => {
    setShowEditModal(!showEditModal)
  }

  function handleDeleteCapitalTransaction(transaction: LedgerTransaction) {
    mutateRemoveTransaction.mutate({
      input: {
        capitalId: transaction.id,
        fundStructureId: props.fundStructureId,
        investorId: props.investorId,
      },
    })
  }

  const fundUnitOptions = ['Delete Transaction', 'Edit Transaction']
  const actionMenu = (transaction: LedgerTransaction) => {
    let newOptions = fundUnitOptions
    return newOptions.map((option) => {
      return (
        <label
          key={option}
          className='form-check-custom py-3 bg-hover-primary text-hover-white'
          onClick={(e) => {
            setSelectedData(transaction)
            if (option === 'Delete Transaction') {
              setShowDeleteModal(true)
              // deleteTransactionModel(e, transaction)
            } else if (option === 'Edit Transaction') {
              setShowEditModal(true)
            }
          }}
        >
          <span className='form-check-label fs-base fw-normal ms-9 cursor-pointer '>{option}</span>
        </label>
      )
    })
  }

  function generateHeaders() {
    const headers: string[] = ['Transaction Type', 'Date', 'Transaction Amount']
    const tableHeaders = (header: string) => {
      if (header !== 'options') {
        return (
          <th
            className={`${
              header === 'Transaction Type' ? ' min-w-150px text-left' : ' min-w-125px text-center'
            }`}
          >
            {header}
          </th>
        )
      }
    }
    const headersData = headers.map((key: string) => tableHeaders(key))
    return headersData
  }

  function generateTableData(data: LedgerTransaction[]) {
    const tableData = data?.map((transaction: LedgerTransaction, index: number) => {
      return (
        <tr key={index}>
          <td>
            <p className='fs-base mb-0 fw-bold'>
              {transaction.type === CapitalLedgerType.CapitalCall ? 'Call' : 'Distribution'}
            </p>
          </td>
          <td>
            <p className='fs-base mb-0 ps-4 text-center'>
              {transaction.closeDate && isoToDate(transaction.closeDate)}
            </p>
          </td>
          <td>
            <p
              className='fs-base mb-0 ps-4 text-center'
              data-toggle='tooltip'
              data-placement='top'
              title={Number(transaction.finalAmount).toLocaleString('en-IN')}
            >
              {kFormatter(Number(transaction.finalAmount))}
            </p>
          </td>
          <td>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-gray-400 btn-active-secondary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen052.svg'
                className='svg-icon-muted svg-icon-2x'
              />
            </button>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3'
              data-kt-menu='true'
            >
              {actionMenu(transaction)}
            </div>
          </td>
        </tr>
      )
    })
    return tableData
  }

  const headersData = generateHeaders()
  const tableData = generateTableData(capitalTransaction!)

  return (
    <div className='overflow-auto'>
      <div className='table-responsive'>
        <table className='table align-middle gs-0 gy-4 table-row-dashed border-gray-200'>
          <thead>
            <tr className='fw-bold text-gray-600'>{headersData}</tr>
          </thead>
          <tbody>{tableData}</tbody>
        </table>
        {capitalTransaction && capitalTransaction?.length == 0 ? (
          <p className='fs-base text-muted pt-4 text-center'>
            No Transaction details were found in this fund.
          </p>
        ) : (
          <></>
        )}
        {showDeleteModal && (
          <Modal
            id='kt_modal_add_inv'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-500px '
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
          >
            <Modal.Body className=''>
              <div className='card'>
                <div className='card-body'>
                  <h2 className='fw-bolder text-gray-heading pb-4'>Remove Transaction</h2>
                  <div className='fs-3 pb-6'>Are you sure you want to remove this Transaction?</div>

                  <div className='fs-7 fw-normal pb-6'>
                    <b>Note : </b> This removes all Partner Group Contributions attached to it.
                  </div>

                  <div className='d-flex col justify-content-between'>
                    <button
                      type='button'
                      className='btn btn-lg btn-secondary'
                      onClick={() => setShowDeleteModal(!showDeleteModal)}
                    >
                      <span className='indicator-label'>Cancel</span>
                    </button>
                    <button
                      type='submit'
                      className='btn btn-lg btn-primary'
                      disabled={loading}
                      onClick={() => handleDeleteCapitalTransaction(selectedData)}
                    >
                      {!loading && <span className='indicator-label'>Remove Transaction</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Removing...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
        {showEditModal && (
          <AddLedgerTransactionModal
            showModal={showEditModal}
            handleClose={handleCloseEdit}
            investorId={props.investorId}
            fundStructureId={props.fundStructureId}
            partnerGroups={partnersGroup!}
            isEdit={true}
            data={selectedData}
            fundStructure={props.fundStructure}
          />
        )}
      </div>
    </div>
  )
}

export default CapitalTransactionView
